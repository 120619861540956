.blogcustommainpage .container .blog {
	margin-top: 30px;
	margin-bottom: 30px;
}
.catblog .realblogcatwise-inner .row {
    margin: 0;
}
.catblog .realblogcatwise-inner .row  .col-2 {
    padding-left: 0;
}
.blogcustommainpage .categoryonesettwo .iconone img,
.blogcustommainpage .categoryonesettwo .icontwo img {
	height: auto;
	border-radius: 0;
	padding: 0;
	background-color: transparent;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	padding: 0 5px;
}

.blogcustommainpage .categoryonesettwo:hover {
	border-radius: 10px;
}

.blogcustommainpage .categoryonesettwo {
	flex-direction: row;
	background: #fff;
}

.blogcustommainpage .categorytwosetfirsttest .categoryonesetwidth.categoryonesettwo:hover .icontwo img {
	filter: none !important;
}

.blogcustommainpage .categorytwosetfirsttest .categoryonesettwo.categoryonesetwidth .iconone img,
.blogcustommainpage .categorytwosetfirsttest .categoryonesettwo.categoryonesetwidth .icontwo img {
	height: 17px;
}

.blogcustommainpage .categorytwosetfirsttest .categoryonesetwidth .iconone {
	background: #fff;
	height: 42px;
	width: 42px;
	position: relative;
	margin: auto;
	border-radius: 50px;
	padding: 10px;
	margin-bottom: 3px;
}

.blogcustommainpage .categorytwosetfirsttest .categoryonesetwidth .icontwo {
	background: #fff;
	height: 42px;
	width: 42px;
	position: relative;
	margin: auto;
	border-radius: 50px;
	padding: 10px;
	margin-bottom: 10px;
}

.blogcustommainpage .categoryonesettwo .iconone img {
	height: 160px;

}

.blogcustommainpage .choose-main {
	max-width: 830px;
	margin-top: 0;
}

.blogcustommainpage .categoryonesettwo .iconone,
.blogcustommainpage .categoryonesettwo .icontwo {
	width: 100%;
	position: relative;
}

.blogcustommainpage .catstart::before {
	display: none;
}

.blogcustommainpage .categoryonesettwo:after {
	display: none;
}

.blogcustommainpage .blog h1 {
	color: #06283D;
	text-align: center;
	padding: 4px;
}

.blogcustommainpage .blog p {
	text-align: center;
}

.trendingsection,
.featuredblog {
	background: url(../../../assets/blogimages/Trending_articles_background.svg);
	padding: 19px 23px 16px 21px;
}

.trendingsection .slick-prev,
.trendingsection .slick-next,
.featuredblog .slick-prev,
.featuredblog .slick-next,
.realblogcatwise .slick-prev,
.realblogcatwise .slick-next,
.innerfeaturedblog .slick-next {
	width: 30px;
	height: 30px;
}
.realblogcatwise .slick-prev, .featuredblog .slick-prev{left:-17px;}
.realblogcatwise .slick-next, .featuredblog .slick-next{right:-17px;}
.trendingsection .slick-prev:before,
.featuredblog .slick-prev:before,
.realblogcatwise .slick-prev:before,
.latestblog .slick-prev:before{
	content: "";
	background: url(../../../assets/homeimages/slicknext.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	rotate: 180deg;
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
}

.trendingsection .slick-next:before,
.featuredblog .slick-next:before,
.realblogcatwise .slick-next:before,
.latestblog .slick-next:before {
	content: "";
	background: url(../../../assets/homeimages/slicknext.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	rotate: 0deg;
	border-radius: 3px;
	display: block;
	position: absolute;
	left: 1px;
	top: 0;
}

.latestblog {
	padding: 40px 0;
}

.trendingsectioninner {
	max-width: 1222px;
	margin: auto;
}

.showallourbesttoo.blogbtn-show {
	margin-top: 20px;
}

.blogcustommainpage .smblog p {
	padding-bottom: 5px;
}

.blogcustommainpage .smblog .blogimage img {
	border-radius: 7px;
	height: 185px;
	width: 100%;
}

.featuredblog .bloginnertext {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	min-height: 60px;
}

.featuredblog .bloginnertextfe {
	color: #000;
	font-family: "Microsoft Sans Serif";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	min-height: 50px;
}

a.blogreadmore>img {
	display: inline-block;
}

.featuredblog .blog-image>img {
	height: 225px;
	object-fit: cover;
}

.innerfeaturedblog {
	/* width: 1120px;
	margin: auto; */
}

.blogcustommainpage section.offersearch {
	background: transparent;
}

.blogcustommainpage section.offersearch .customsearchblock input {
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.35));
}

.featuredblog .basicuserdetails {
	padding: 20px 0;
}

.smbloginnerps  .bloginnertag, .basicstructure .bloginnertag {
	background: #fff;
	height: auto;
	width: max-content;
	position: absolute;
	content: "";
	bottom: 15px;
	left: 15px;
	border-radius: 5px;
	margin: auto;
	height: auto;
	color: #000;
padding:4px 10px;
font-family: 'microsoft_sans_serifregular' !important;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.Real.Estate .smblog button:before,
.innerfeaturedblog .smblog.Real.Estate button.bloginnertag:before,
.smblog .Real.Estate button.bloginnertag:before,
.realblogcatwise .Real.Estate .card-blog-text .bloginnertag:before {
	background: #00801D;
}
.Real.Estate .smbloginnerps  .bloginnertag, .Real.Estate.basicstructure .bloginnertag
{
	border-color: #00801D;
}

.Investment .smbloginnerps  .bloginnertag, .Investment.basicstructure .bloginnertag
{
	border-color: #86357A;
}
.Insurance .smbloginnerps  .bloginnertag, .Insurance.basicstructure .bloginnertag
{
	border-color: #FF5D16;
}
.Mortgage .smbloginnerps  .bloginnertag, .Mortgage.basicstructure .bloginnertag
{
	border-color: #2F596F;
}
.Banking .smbloginnerps  .bloginnertag, .Banking.basicstructure .bloginnertag
{
	border-color: #2281B8;
}

.Investment .smblog button:before,
.innerfeaturedblog .smblog.Investment button.bloginnertag:before,
.smblog .Investment button.bloginnertag:before,
.realblogcatwise .Investment .card-blog-text .bloginnertag:before {
	background: #86357A;
}

.Mortgage .smblog button:before,
.innerfeaturedblog .smblog.Mortgage button.bloginnertag:before,
.smblog .Mortgage button.bloginnertag:before,
.realblogcatwise .Mortgage .card-blog-text .bloginnertag:before {
	background: #2F596F;
}

.Insurance .smblog button:before,
.innerfeaturedblog .smblog.Insurance button.bloginnertag:before,
.smblog .Insurance button.bloginnertag:before,
.realblogcatwise .Insurance .card-blog-text .bloginnertag:before {
	background: #FF5D16;
}

.Banking .smblog button:before,
.innerfeaturedblog .smblog.Banking button.bloginnertag:before,
.smblog .Banking button.bloginnertag:before,
.realblogcatwise .Banking .card-blog-text .bloginnertag:before {
	background: #2281B8;
}

.realblogcatwise .card-blog-text .bloginnertag:before {
	background: #fff;
	height: 1px;
	width: 70%;
	position: absolute;
	content: "";
	bottom: 2px;
	left: 0;
	right: 0;
	margin: auto;
}

.Real.Estate .blogreadmore {
	color: #00801D;
}

.Investment .blogreadmore {
	color: #86357A;
}

.Mortgage .blogreadmore {
	color: #2F596F;
}

.Insurance .blogreadmore {
	color: #FF5D16;
}

.Banking .blogreadmore {
	color: #2281B8;
}

.realblogcatwise-inner .card:not(:first-child) .blog-upperstrip .upperstrip a.blogreadmore {
	color: #00801D;
	font-family: "Roboto Flex";
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	padding-right: 15px;
}

.realblogcatwise-inner .card:not(:first-child).Real.Estate .blog-upperstrip .upperstrip a.blogreadmore {
	color: #00801D;
}

.realblogcatwise-inner .card:not(:first-child).Investment .blog-upperstrip .upperstrip a.blogreadmore {
	color: #86357A;
}

.realblogcatwise-inner .card:not(:first-child).Mortgage .blog-upperstrip .upperstrip a.blogreadmore {
	color: #2F596F;
}

.realblogcatwise-inner .card:not(:first-child).Insurance .blog-upperstrip .upperstrip a.blogreadmore {
	color: #FF5D16;
}

.realblogcatwise-inner .card:not(:first-child).Banking .blog-upperstrip .upperstrip a.blogreadmore {
	color: #2281B8;
}


.realblogcatwise-inner .card:not(:first-child).Real.Estate .blogreadmore img, .Real.Estate a.blogreadmore>img {
	filter: invert(22%) sepia(99%) saturate(1808%) hue-rotate(124deg) brightness(97%) contrast(101%);
	;
}

.realblogcatwise-inner .card:not(:first-child).Investment .blogreadmore img, .Investment a.blogreadmore>img {
	filter: invert(28%) sepia(12%) saturate(5111%) hue-rotate(267deg) brightness(90%) contrast(89%);
	;
}

.realblogcatwise-inner .card:not(:first-child).Mortgage .blogreadmore img, .Mortgage a.blogreadmore>img {
	filter: invert(28%) sepia(6%) saturate(5246%) hue-rotate(157deg) brightness(91%) contrast(76%);
}

.realblogcatwise-inner .card:not(:first-child).Insurance .blogreadmore img, .Insurance a.blogreadmore>img {
	filter: invert(37%) sepia(98%) saturate(1100%) hue-rotate(349deg) brightness(102%) contrast(104%);
}

.realblogcatwise-inner .card:not(:first-child).Banking .blogreadmore img, .Banking a.blogreadmore>img {
	filter: invert(45%) sepia(28%) saturate(1241%) hue-rotate(158deg) brightness(91%) contrast(94%);
}


.blogcustommainpage .smblog .Real.Estate .blogreadmore img {
	filter: invert(22%) sepia(99%) saturate(1808%) hue-rotate(124deg) brightness(97%) contrast(101%);
	;
}

.blogcustommainpage .smblog .Investment .blogreadmore img {
	filter: invert(28%) sepia(12%) saturate(5111%) hue-rotate(267deg) brightness(90%) contrast(89%);
	;
}

.blogcustommainpage .smblog .Mortgage .blogreadmore img {
	filter: invert(28%) sepia(6%) saturate(5246%) hue-rotate(157deg) brightness(91%) contrast(76%);
}

.blogcustommainpage .smblog .Insurance .blogreadmore img {
	filter: invert(37%) sepia(98%) saturate(1100%) hue-rotate(349deg) brightness(102%) contrast(104%);
}

.blogcustommainpage .smblog .Banking .blogreadmore img {
	filter: invert(45%) sepia(28%) saturate(1241%) hue-rotate(158deg) brightness(91%) contrast(94%);
}

.trending-blogimage {
	/* background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 32.32%, #000 122%); */
	background: #000;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
	position: relative;
	border-radius: 5px;
	overflow: hidden;
}

.sliderblog .smblog {
	border-radius: 5px;
}

.blog-upperstrip {
	position: absolute;
	top: 0;
	border-radius: 5.415px 5.415px 0px 0px;
	border: 0px solid rgba(255, 255, 255, 0.00);
	background: radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
	backdrop-filter: blur(18.952499389648438px);
	width: 100%;
	padding: 8px 15px;
}

.upperstrip .basicuserdetails {
	width: 100%;
}

.d-flex.upperstrip {
	align-items: center;
}

.upperstrip a.blogreadmore {
	width: 100px;
	padding: 0;
}

.upperstrip a.blogreadmore img {
	display: inline-block;
}

.upperstrip .usertext .name,
.upperstrip .usertext .date,
.upperstrip a.blogreadmore {
	color: #fff;
}

.upperstrip a.blogreadmore img {
	filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(182deg) brightness(101%) contrast(104%);
}

.blogcustommainpage .smblog a {
	margin-bottom: 20px;
}

.trendingsection .bloginnertext {
	position: absolute;
	bottom: 8px;
	color: #FFF;
	text-shadow: 0px 3.61px 3.61px rgba(0, 0, 0, 0.25);
	max-width: 476px;
	left: 14px;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

a.blogreadmore {
	text-align: right;
	display: inline-block;
	width: 100%;
	margin: 0 !important;
	color: #2F596F;
	font-family: "Roboto Flex";
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	padding-bottom: 8px;
	padding-right: 20px;
	position: relative;
}

.blogcustommainpage .tblog {
	padding-bottom: 15px !important;
	padding-top: 15px !important;
}

.blogcustommainpage .tblog img {
	margin-bottom: 10px;
}

.blogimage {
	position: relative;
}

.usertext .name {
	color: #000;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.usertext .date {
	color: #7D7D7D;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.blogcustommainpage .smblog button {
	position: absolute;
	border: 0;
	width: 70px;
	height: 15px;
	border-radius: 2px;
	background: rgba(255, 255, 255, 0.60);
	color: #000;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 8px;
	font-style: normal;
	font-weight: 400;
	margin: 0;
	line-height: 1;
	padding: 0;
	z-index: 9;
	left: 22px;
	bottom: 7px;
}

.blogcustommainpage .trendingsection .smblog button {
	position: relative;
	display: block;
	left: auto;
}

.trending-blogimage>img {
	height: 500px;
	object-fit: cover;
	width: 500px;
	opacity: 0.7;
}

.basicstructure .bloginnertext {
	color: #000;
	font-family: 'microsoft_sans_serifregular' !important;
	padding: 0 8px;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	min-height: 54px;
	margin-bottom: 10px;
	margin-top: 10px;
	text-align: left;
}
.basicstructure .nblogin{padding: 0;}
.userimage {
	width: 45px;
	height: 45px;
}

.basicuserdetails {
	padding: 0 8px;
	align-items: center;
	text-align: left;
}

.basicuserdetails .usertext {
	margin-left: 7px;
}

.blogcustommainpage hr {
	margin: 10px;
	width: 93%;
}

button:hover #cross:before,
button:hover #cross:after {
	background: #024b78;
}

.basicstructure {
	border-radius: 6px;
	background: #FFF;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.featuredblog .smblog .smbloginner {
	border-radius: 6px;
	border: 1px solid #E4E4E4;
	background: #FFF;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
	padding: 10px;
}

a.blogreadmore>img {
	display: inline-block;
}

.innerfeaturedblog {
	max-width: 1120px;
	width: 100%;
	margin: auto;
}

.featuredblog .smblog {
	padding: 0 10px;
}

.featuredblog .blog-image {
	position: relative;
}

.blogcustommainpage .herosectionoffer {
	background: url(../../../assets/blogimages/Article_banner.svg);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 40px 0
}

.herosectionoffer .trendingsection {
	background: transparent;
	width: 500px;
	border-radius: 10px;
	border: 1.057px solid rgba(255, 255, 255, 0.40);
	background: linear-gradient(114deg, rgba(255, 255, 255, 0.40) 6.22%, rgba(255, 255, 255, 0.20) 98.63%);
	box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
	margin-left: 20px;
}

.herosectionoffer .trendingsection .slick-prev:before {
	left: -12px;
}

.herosectionoffer .trendingsection .slick-next:before {
	right: -12px;
	left: auto;
}

.herosectionoffer .trendingsection .blog-upperstrip {
	bottom: 0;
	top: auto;
	background: transparent;
	backdrop-filter: none;
}

.herosectionoffer .trendingsection .bloginnertext {
	bottom: 78px;
}

.blogcustommainpage .askbutton {
	margin-top: 40px;
}

.blogtext .bannerheading {
	color: #000;
	font-size: 45px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.blogtext {
	text-align: right;
	padding-right: 70px;
}

.blogtext .bannergredientheading {
	background: linear-gradient(183deg, #FFB3D6 2.28%, #B163D6 78.08%);
	background-clip: text;
	margin-bottom: 40px;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 45px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.blogtext .bannertext {
	color: #000;
	text-align: right;

	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 20px;
}

.herosectionoffer .row {
	align-items: center;
}

.realblogcatwise-inner {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

.realblogcatwise-inner .card:first-child {
	grid-row-start: 1;
	grid-row-end: 3;
	flex-direction: column;
}

.realblogcatwise-inner .card:first-child .card-img-top,
.realblogcatwise-inner .card:first-child .card-blog-text {
	flex: 0 0 auto;
	width: 100%;
}

.realblogcatwise .card-blog-text .bloginnertag {
	position: absolute;
	left: 6px;
	bottom: 10px;
	text-align: center;
	border: 0;
	width: 70px;
	height: 15px;
	border-radius: 2px;
	background: rgba(255, 255, 255, 0.60);
	color: #000;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 8px;
	font-style: normal;
	font-weight: 400;
	margin: 0;
	line-height: 1;
	padding: 0;
	z-index: 9;

}

.blogcustommainpage .card {
	flex-direction: row;
	overflow: hidden;
}


/* .realblogcatwise-inner .card:first-child .card-blog-text {
	position: absolute;
	bottom: 0;
	left: 22px;
} */

.realblogcatwise {
	padding-top: 20px;
}


.realblogcatwise-inner .card .row {
    align-items: center;
}

.realblogcatwise-inner .card:first-child .col-lg-5.col-sm-5 {
    width: 50%;
}

.realblogcatwise-inner .card:first-child .col-lg-7.col-sm-7 {
    width: 50%;
}

.realblogcatwise-inner .card:first-child .smbloginnerps .blog-image img {
    height: 470px;
}

.realblogcatwise-inner .card:first-child .bloginnertext {

	color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



.realblogcatwise-inner .card:not(:first-child) .blog-upperstrip .upperstrip .usertext .date {
	color: #7D7D7D;
	font-family: "Microsoft Sans Serif";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.realblogcatwise-inner .card:not(:first-child) .blog-upperstrip .upperstrip .usertext .name,
.realblogcatwise-inner .card:not(:first-child) .blog-upperstrip.upperstrip a.blogreadmore {
	color: #000;
	font-family: "Microsoft Sans Serif";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.realblogcatwise-inner .card:not(:first-child) .d-flex.upperstrip {
	display: block !important;
	max-width: 350px;
	width: 350px;
}

.realblogcatwise-inner .card:not(:first-child) .bloginnertext {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}


.realblogcatwise-inner .card:not(:first-child) .upperstrip a.blogreadmore {
	width: 100%;
}

.realblogcatwise-inner .card:not(:first-child) .card-img-top>img {
	height: 235px;
	object-fit: cover;
}

.realblogcatwise-inner .card:first-child .card-img-top>img {
	height: 492px;
	object-fit: cover;
}

.realblogcatwise-inner .card:first-child .card-blog-text .bloginnertag {
	bottom: 10px;
	top: -17px;
}

section.catblog {
	padding: 40px 0;
	padding-top: 0;
}

h4.text-heading {
	color: #000;
	text-align: center;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

h4.text-subheading {
	color: #000;
	text-align: center;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.blog-upperstrip.blog-upperstriptrending{position: relative; padding: 10px 0px;}
.blog-upperstrip.blog-upperstriptrending .basicuserdetails {
    padding: 0 0px;
    align-items: center;
}
.blog-upperstrip .upperstrip .usertext .name, .blog-upperstrip .upperstrip .usertext .date, .blog-upperstrip .upperstrip a.blogreadmore {
    color: #000;
}
.blogtitle{
    color: #000;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.blogdescription{
    color: #000;
font-family: "Microsoft Sans Serif";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.basicstructure  .blogimage img{
	height: 185px;
    object-fit: cover;
    border-radius: 0;
    width: 100%;
    border: none;
    position: relative;
    top: auto;
    margin: auto;
    left: 0;
    right: 0;
}
.newblogdesign .smbloginnerps{    border-radius: 6px;
    border: 1px solid #e4e4e4;
    padding: 10px;
    background-color: #fff !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
padding: 0;
margin: 0;}
.newblogdesign .smbloginnerps{
	margin-bottom: 3px;
}
.newblogdesign .smbloginnerps .col-2{flex: 0 0 auto;
	width: 41.66666667%;}
.newblogdesign .smbloginnerps .col-10{
	flex: 0 0 auto;
	width: 58.33333333%;
}
.newblogdesign .smbloginnerps .blog-image img{
	height: 225px;
    object-fit: cover;
	border-radius: 0;
	padding: 0;
	margin: 0;
	width: 100%;
}
.newblogdesign .realblogcatwise-inner .smbloginnerps .blog-image img {
    height: 220px;
    object-fit: cover;
}
.newblogdesign .realblogcatwise-inner .smbloginnerps .blog-image {
    padding: 0;
}
.newblogdesign .smbloginnerps .blog-image{padding: 10px 0;}
.newblogdesign .smbloginnerps .aublog, .newblogdesign .smbloginnerps .audate{display: none;}
.newblogdesign .bloginnertextfe{
	font-family: "Microsoft Sans Serif" !important;
	font-size: 13px;
    font-weight: 400;
    color: #000;
    line-height: 22px;
    min-height: 60px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

}
.newblogdesign .bloginnertext {
	margin-top: 0 !important;
}
.trendingsection .smbloginnerps  .bloginnertext{
    color: #000;
    font-style: normal;
    line-height: normal;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    max-width: 100%;
    bottom: auto;
    left: 0;
    text-shadow: none;
}
.trendingsection  .sliderblog {
    padding: 10px;
}
.newblogdesign .realblogcatwise-inner .card:first-child .smbloginnerps{
	border:none;
	box-shadow: none;
}

/* .blogdetailsexpertppv .bloginnertag, .homecustom .searchresultsection .bloginnertag , .homecustom .searchresultsection a.blogreadmore {
    display: none;
} */
.homecustom.homecustomsearchfull .searchresultsection a.blogreadmore{display: none;}
section.container-rimib.newblogdesign .d-flex.upperstrip.d-block.d-lg-none.d-sm-none.d-md-none.upperstripmobile
{display: none  !important;}
.homecustom.homecustomsearchfull .smbloginnerps  .row{align-items: center;}
.categorysectionsix .minheightrealestate a {
    width: 100%;
}
.newblogdesign .col.smblog:nth-child(6n+1) {
    /* display: none; */
}
.newblogdesign .col.smblog {
    display: flex;
    width: 20%;
}
.newblogdesign .regular.slider{display: flex;}
.fmblog{display: none;}
@media(max-width:767px){
	
	section.container-rimib.newblogdesign .container{
		padding: 0;
	}
	section.container-rimib.newblogdesign .blogcustommainpage .herosectionoffer .container{padding: 0 15px;}
	section.container-rimib.newblogdesign p.text-center.text-subheading {
		padding: 0 30px;
	}
	section.container-rimib.newblogdesign .latestblog .smbloginnerps .blog-image, .realblogcatwise .smbloginnerps .blog-image, .featuredblog .smbloginnerps .blog-image, section.trendingsection .smbloginnerps .blog-image
	{padding: 0;}
	section.container-rimib.newblogdesign .upperstripmobile{display: none !important;}
	.catblog p.bloginnertextfe{display: none;}
	.featuredblog .bloginnertextfe, .featuredblog .blogreadmore, section.trendingsection .bloginnertextfe , section.trendingsection .blogreadmore{display: none;}
	.featuredblog .fmblog .bloginnertextfe, .featuredblog .fmblog .blogreadmore,  section.trendingsection .fmblog .bloginnertextfe , section.trendingsection .fmblog .blogreadmore{display: block;}
	.featuredblog .fmblog, section.trendingsection .fmblog{display: block;}
	.blogcustommainpage .herosectionoffer .row{
		flex-direction: column-reverse;
	}

	.blogtext .bannerheading{
		font-size: 24px;
	}
	.blogtext{text-align: left;}
	.blogtext .bannergredientheading{font-size: 30px; margin-bottom: 0px;}
	.blogtext .bannertext{font-size: 12px; font-family: 'microsoft_sans_serifregular' !important; text-align:left; margin-bottom: 10px;}
	.realblogcatwise-inner{display: flex; flex-wrap: wrap;}
	.blogcustommainpage .askbutton {
		margin-top: 0px;
		margin-bottom: 10px;
		margin-left: 0;
	}
	.trending-blogimage>img {
		height: 200px;
		object-fit: contain;
		width: 100%;
		opacity: 0.7;
	}
	.herosectionoffer .trendingsection {
		background: transparent;
		width: 100%;
		padding: 10px;
		margin-left: 0px;
	}
	.herosectionoffer .trendingsection .bloginnertext
	{
		font-size: 12px;
	}
	.userimage {
		width: 20px;
		height: 20px;
	}
	.herosectionoffer .blog-upperstrip .upperstrip .usertext .name, .herosectionoffer .blog-upperstrip .upperstrip .usertext .date, .herosectionoffer .blog-upperstrip .upperstrip a.blogreadmore
	{
		color: #fff;
	}
	.upperstrip a.blogreadmore {
		width: 109px;
	}
	.trendingsection .slick-prev:before, .featuredblog .slick-prev:before, .trendingsection .slick-next:before, .featuredblog .slick-next:before, .realblogcatwise .slick-next:before{width: 30px; height: 30px;}
	.realblogcatwise-inner .card:first-child .trendingblogimage img {
		height: 300px;
	}
	.blogcustommainpage .smblog .blogimage img {
		border-radius: 7px;
		height: 100px;
		width: 100%;
	}
	.realblogcatwise-inner .trendingblogimage img {
		height: 247px;
		object-fit: cover;
	}
	.blogcustommainpage .categoryonesettwo .iconone img {
		height: 80px;
	}
	.blogcustommainpag	.choose.categoryonesettwo {
        height: 50px;
        width: 20%;
    }
	.newblogdesign .bloginnertext{min-height:45px;}
	.newblogdesign .bloginnertext, .trendingsection .smbloginnerps .bloginnertext{font-size: 12px; color: #000; line-height: 1;}
	.blogcustommainpage .smblog p, .newblogdesign .smbloginnerps{min-height: auto; font-size: 10px; line-height: 1;}
	.blogcustommainpage .choose.categoryonesettwo {
        height: 70px;
        width: 20%;
    }
	.blog-upperstrip .upperstrip .usertext .name, .blog-upperstrip .upperstrip .usertext .date, .blog-upperstrip .upperstrip a.blogreadmore{font-size: 10px;}
	.newblogdesign .smbloginnerps .blog-image img {
		height: 150px;
	}
	section.trendingsection .fmblog .bloginnertextfe, .featuredblog .fmblog .bloginnertextfe{padding: 10px 5px;}
	.blogcustommainpage .categoryonesettwo .iconone img, .blogcustommainpage .categoryonesettwo .icontwo img
	{
		padding: 0 1px;
	}
	.blogcustommainpage h4.text-heading{
		font-size: 18px;
	}
	.blogcustommainpage .realblogcatwise p.text-center.text-subheading, .blogcustommainpage p.text-center.text-subheading{
		font-size: 12px; line-height: 1.3;
	}
	.newblogdesign .smbloginnerps .col-2 {
		padding-right: 0;
	}
	.trendingsection, .featuredblog{
		padding: 10px 0;
	}
	.realblogcatwise{padding: 0 20px;}
	section.featuredblog{padding-left: 20px; padding-right: 20px;}
	.latestblog, section.trendingsection{padding:20px 20px;}
}