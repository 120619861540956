.ppvproduction_inner {
    box-shadow: 0px 0px 30px 0px #00000040;
    margin: 40px 0;
    padding: 19px 0 20px 0;
  }
  .ppvproduction_inner .heading {
    text-align: center;
    margin-bottom: 40px;
  }
  .ppvproduction_inner .heading h2 {
    font-family: "Montserrat",sans-serif !important;
    font-size: 30px;
    font-weight: 700;
    line-height: 36.57px;
    text-align: center;
    color: #000000;
  }
  .ppvproduction_inner .heading h5 {
    font-family: "Montserrat",sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
  }
  .ppvproduction_inner .heading h6 {
    font-family: "Montserrat",sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: center;
    color: #000000;
  }
.subcategoryTab {
    cursor: pointer;
    margin: 5px 31px;
    display: inline-flex;
    flex-direction: column;
    border-bottom: 1px solid #000000;
}
.subcategoryTab:last-child{
    border-bottom: none;
}
.mortgage.active {
    border-bottom: none;
    color: white;
    display: inline-block;
    background-color: #2F596F;
    border-radius: 8px;
    margin: 0;
    padding: 0px 31px 0px 31px;
    position: relative;
}
.mortgage.active:before {
    position: absolute;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #2F596F;
    content: "";
    top: 23px;
    right: -19px;
}
.insurance.active {
    color: white;
    display: inline-block;
    background-color:#FF4C00 ;
    border-radius: 8px;
    margin: 0;
    padding: 0px 31px 0px 31px;
    border-bottom: none;
    position: relative;

}
.insurance.active:before {
    position: absolute;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #FF4C00;
    content: "";
    top: 23px;
    right: -19px;
}
.banking.active {
    color: white;
    display: inline-block;
    background-color:#2281B8 ;
    border-radius: 8px;
    margin: 0;
    padding: 0px 31px 0px 31px;
    border-bottom: none;
    position: relative;

}
.banking.active:before {
    position: absolute;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #2281B8;
    content: "";
    top: 23px;
    right: -19px;
}
.active.real-estate {
  color: white;
  display: inline-block;
  background-color:#00831d ;
  border-radius: 8px;
  margin: 0;
  padding: 0px 31px 0px 31px;
  border-bottom: none;
  position: relative;

}
.active.real-estate:before {
  position: absolute;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 20px solid #00831d;
  content: "";
  top: 23px;
  right: -19px;
}
.investment.active {
    color: white;
    display: inline-block;
    background-color:#86357A ;
    border-radius: 8px;
    margin: 0;
    padding: 0px 31px 0px 31px;
    border-bottom: none;
    position: relative;

}
.realEstate.active {
    color: white;
    display: inline-block;
    background-color:#00831D ;
    border-radius: 8px;
    margin: 0;
    padding: 0px 31px 0px 31px;
    border-bottom: none;
    position: relative;

}
.investment.active:before {
    position: absolute;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #86357A;
    content: "";
    top: 23px;
    right: -19px;
}
.realEstate.active:before {
    position: absolute;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #00831D;
    content: "";
    top: 23px;
    right: -19px;
}
.categoryTab.insurance.active {
    background-color: transparent; 
    color:#000000;
    border: 1px solid #000000;

  }
.categoryTab.mortgage.active {
    background-color:transparent; 
    color:#000000;
}
.categoryTab.investment.active {
      background-color:transparent;
      color:#000000;
  }
.categoryTab.banking.active {
    background-color:  transparent; 
    color: #000000;
  }
.categoryTab.realEstate.active {
    background-color:  transparent; 
    color: #000000;
  }
.product_tab_inner{
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    padding: 0;
}
.subcategory-tab .active{
    display: inline-block;
}
.subcategoriesTabs{
    display: flex;
    background-color: white;
    box-shadow: 0px 0px 10px 0px #00000040;
    flex-direction: column;
    border-radius: 8px;
}
.subcategoriesTabs_main {
    display: flex;
    padding: 22px 25px;
    border: 1px solid #000000;
    border-radius: 12px;
}
.categoryTab {
    padding: 10px;
  }
  .categoryText {
    font-family: "Montserrat",sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    color: #000000;
   
    border-radius: 15px 15px 0 0;
    cursor: pointer;
  }
  .subcategory_name {
    height: 78px;
    width: 260px;
    display: flex;
    align-items: center;
}
  
.categoryTab.active {

  border: 1px solid #000000;
  margin-bottom: -1px;
  clip-path: polygon(20% 0, 80% 0, 100% 100%, 0 100%);
  border-radius: 0; 
  outline: 1px solid #000000; 
  padding: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
  border-bottom: none;
}


.categoryTab.active::after {
  content: '';
  position: absolute;
  bottom: 29px;
  left: -7%;
  width: 74px; 
  height: 1px;
  rotate: 126deg;
  background-color: #000000;
}
.categoryTab.active::before {
  content: '';
  position: absolute;
  bottom: 30px;
  right: -7%;
  width: 74px; 
  height: 1px;
  rotate: 54deg;
  background-color: #000000;
}

 .categoryTab.bgclrbgclr {
    
    background-color: yellow;
}
.subcategory_text{
    font-family: "Montserrat",sans-serif;
font-size: 18px;
font-weight: 700;
line-height: 21.94px;
text-align: left;
padding: 27px 31px 29px 0px;

}
.subcategoryTab:has( + .subcategoryTab.active) {border: none;}
.categorytab_name:has( + .categorytab_name.active) {border-right: none !important;}
.categorytab_name.Investmentborder:has( + .categorytab_name.Investmentborder.active) {border: none !important;}
.categorytab_name.Mortgageborder:has( + .categorytab_name.Mortgageborder.active) {border: none !important;}
.categorytab_name.Bankingborder:has( + .categorytab_name.Bankingborder.active) {border: none !important;}
.categorytab_name.Real.Estateborder:has( + .categorytab_name.Real.Estateborder.active) {border: none !important;}
.categorytab_name {
    padding: 0px;
    font-size: 16px;
    margin-bottom: -1px;
    margin-right: 0px;
    width: 211px;
    text-align: center;
  }
  .categorytab_name >div{padding: 5px 0;}
  .categorytab_name:last-child{
    border-right: none;
  }
  .myExpertNav{
      padding: 0 93px;
  }
  .mortgageBackground {
    background-color: red;
  }
  
  .realEstateBackground {
    background-color: yellow;
  }
  .Mortgage-backgroundColor {
    background: red;
}
.categorytab_name{position: relative;}
.categorytab_name:before{position: absolute;
  content: "";
  height: 24px;
  width: 1px;
  right: 8px;
  top: 12px;
}
/* .categorytab_name.active.Mortgagebg{
  background-color: #E9EEF9;
} */
/* .categorytab_name.active.parentInstitutionCategoryId-id3{
  background-color: #E9EEF9;
} */

/* .Mortgagebg {
  background-color: #E9EEF9;
}

.Banking {
  background-color: red;
}

.Investment {
  background-color: yellow;
}

.Insurance {
  background-color: green;
} */


.categoryItem.active {
  background-color: #3498db;
}

.active.parentInstitutionCategory-id3 {
  background-color: #CED5E5;
}
.active.parentInstitutionCategory-id1 {
  background-color: #FFB08E;
}
.active.parentInstitutionCategory-id2 {
  background-color: #F5B9ED;
}
.active.parentInstitutionCategory-id4 {
  background-color: #63CCFF;
}

/* .categoryText.categoryText::before{
  border-left: 2px solid red;
  position: relative;
  content: "";
}
.categoryText::before{
  border-right: 2px solid red;
  content: '';
  position: absolute;
  
} */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
  .ppvproduction_inner{margin-top: 0;}
  .myExpertNav{padding: 0;}
  .categorytab_name{width: 170px;}
  .product-tabs-container ul .active:before, .product-tabs-container ul .parentInstitutionCategory-id4.active:before{
    width:170px
  }
  .subcategoryTab{ margin: 5px 10px;}
}
@media (max-width:767px){
  .active.real-estate:before {
    display: none !important;
   }
  .myExpertNav{padding: 0;}
  .ppvproduction_inner .heading h2{
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
  }
  .ppvproduction_inner .heading h5, .ppvproduction_inner .heading h6{
    font-size: 12px; line-height: 1.2;
  }
  .categorytab_name{
    width: 25%;
    height: 70px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }
  .categoryText{
    color: #969696;
text-align: center;
font-family: "Fjalla One";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .categorytab_name::before{
    display: none;
  }
  .subcategoriesTabs_main{
    display: flex;
    padding: 22px 0;
    border: none;
    border-radius: 0;
  }
  .subcategoriesTabs{
    display: flex   ;
        background-color: white;
        box-shadow: none;
        flex-direction: row;
        border-radius: 2px;
        flex-wrap: wrap;
        justify-content: center;
  }
  .subcategoryTab{width: 85px; height: 85px; border-bottom: 0px; padding: 0 !important; display: flex; justify-content: center; border-radius: 11.256px; border: 4px solid transparent;}
.subcategoryTab:nth-child(1) {background: #cbeaff;}
.subcategoryTab:nth-child(2) {background: #FFE8A4;}
.subcategoryTab:nth-child(3) {background: #F6E4FF;}
.subcategoryTab:nth-child(4) { background: #FFE0E0;}
.subcategoryTab:nth-child(5) { background: #C0FFC7;}
.subcategoryTab:nth-child(6) { background: #DAF0FF;}



.subcategoryTab:nth-child(1):hover, .subcategoryTab.active:nth-child(1) {border-color: #cbeaff; background:transparent; border-bottom: 4px solid #cbeaff;}
.subcategoryTab:nth-child(2):hover, .subcategoryTab.active:nth-child(2) {border-color: #FFE8A4; background:transparent; border-bottom: 4px solid #ffe8a4; }
.subcategoryTab:nth-child(3):hover, .subcategoryTab.active:nth-child(3) {border-color: #F6E4FF; background:transparent; border-bottom: 4px solid #f6e4ff;}
.subcategoryTab:nth-child(4):hover, .subcategoryTab.active:nth-child(4) { border-color: #FFE0E0; background:transparent; border-bottom: 4px solid #FFE0E0;}
.subcategoryTab:nth-child(5):hover, .subcategoryTab.active:nth-child(5) { border-color: #C0FFC7; background:transparent; border-bottom: 4px solid #C0FFC7;}
.subcategoryTab:nth-child(5):hover, .subcategoryTab.active:nth-child(6) { border-color: #DAF0FF; background:transparent; border-bottom: 4px solid #DAF0FF;} 
.subcategory_text{
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
  }
  .subcategoryTab{margin: 0 5px; margin-bottom: 5px;}
  .subcategory_name{
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banking.productSection_active{
    padding: 0;
  }
  .ppvproduction_inner .heading{
    margin-bottom: 5px;
  }
  .subcategoriesTabs_main{display: block; padding-bottom: 0;}
  .subcategoriesTabs_main .showallourbesttwo.mt-3.text-center{margin-top: 0 !important;}
  .ppvproduction_inner{margin-bottom: 0; margin-top: 0;}
  .banking.active:before, .investment.active:before, .insurance.active::before, .mortgage.active:before, .realEstate.active:before{
    display: none;
  }
  .mortgage.active, .insurance.active, .banking.active, .investment.active, .realEstate.active
  {color: #000;}
  .subcategoryTab.active{align-items: center; display: flex;}
  .subcategoryTab.active:nth-child(1) .pstab  {background: #cbeaff;}
 .subcategoryTab.active:nth-child(2) .pstab {background: #ffe8a4;}
 .subcategoryTab.active:nth-child(3) .pstab {background: #f6e4ff;}
 .subcategoryTab.active:nth-child(4) .pstab { background: #c0ffc7;}
.subcategoryTab.active:nth-child(5) .pstab { background: #daf0ff;}

}