.showallourbesttoo:hover {
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #EF000B;
	background: #EF000B;
	color: #FFF !important; 
}

.showallourbesttoo {
	background-color: white;
	border: 1px solid #EF000B;
	padding: 8px 19px;
	border-radius: 5px;
	display: inline-block !important;
	width: 150px !important;
	height: 40px;
	color: #EF000B;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif;
	margin-left: auto;
	margin-right: auto;
}
@media (max-width:767px){
	.showallourbesttoo {
		padding: 0px;
        height: 25px;
        font-weight: 500;
        line-height: 1.9;
        letter-spacing: 1.2px;
        text-align: center;
        width: 90px !important;
        font-size: 12px;
        border-radius: 2px;
        border-width: 1px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
