.pagination-section{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 40px;
}

.newpagination .pagination-section .active-page{
    border-radius: 10px;
border: 1px solid #C30202;
background: #C30202;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
color: #FFF;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 51px;
width: 51px;
height: 51px;
}
.newpagination .pagination-section button {
    border-radius: 10px;
    border: 1px solid #C30202;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset, 3px 33px 33px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 3px 3px 33px 0px rgba(0, 0, 0, 0.25);
    
    color: #000;
    margin: 0 9px;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 51px;
font-size: 0;
background: #FFF;
position: relative;
width: 51px;
height: 51px;
}

.newpagination .pagination-section button[disabled] {
    background: url(../../../assets/images/left-arrow-pagination.svg) #ccc;
    border: 1px solid #ccc;
    background-position: center;
    background-size: 22px 22px;
    background-repeat: no-repeat;
}
.newpagination .pagination-section button.next[disabled]{ 
    background: url(../../../assets/images/right-arrow-pagination.svg) #ccc;
    border: 1px solid #ccc;
    background-position: center;
    background-size: 22px 22px;
    background-repeat: no-repeat;}

.newpagination button.prev:before
{
    background: url(../../../assets/images/left-arrow-pagination.svg);
    content: "";
    position: absolute;
    height: 21px;
    width: 22px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 14px;
    left: 0;
    right: 0;
    margin: auto;
}

.newpagination button.next:before
{
    background: url(../../../assets/images/right-arrow-pagination.svg);
    content: "";
    position: absolute;
    height: 21px;
    width: 22px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 14px;
    left: 0;
    right: 0;
    margin: auto;
}
@media (max-width:767px){
    .pagination-section{

        margin-top: 0px;
        margin-bottom: 20px;
    }
    
    .newpagination .pagination-section .active-page {
        border-radius: 2px;
        border: 1px solid #C30202;
        background: #C30202;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        width: 30px;
        height: 30px;
    }
    .newpagination .pagination-section button {
        border-radius: 6px;
        border: 1px solid #676767;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #000;
        margin: 0 9px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        font-size: 0;
        background: #FFF;
        position: relative;
        width: 30px;
        height: 30px;
    }
    .newpagination .pagination-section button[disabled] {
        border: 1px solid #2c2222;
        background-position: center;
        background-size: 14px 14px;
        background-repeat: no-repeat;
        border-radius: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .newpagination button.next:before {
        content: "";
        position: absolute;
        height: 16px;
        width: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        top: 6px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .newpagination button.prev:before {
        content: "";
        position: absolute;
        height: 14px;
        width: 15px;
        background-size: contain;
        background-repeat: no-repeat;
        top: 7px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .newpagination .pagination-section button.next[disabled]{
        background-size: 14px 14px; border: 1px solid #2c2222;
    }
}
