
@media (min-width: 769px) and (max-width: 1024px) {
  .dashboardrightpannerinnerleft {
    padding-top: 10px;
  }

  .myexpertnav.myaccountnav ul li:first-child.active {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 144px;
    width: 144px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
  }

  .myexpertnav > ul > li.active {
    background: none;
    background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
  }

  .myexpertnav > ul > li:first-child.active {
    background: none;
    background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
  }

  .myexpertnav.myaccountnav > ul > li:last-child.active {
    background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
  }

  .myexpertnav.myaccountnav.qnanav ul li {
    margin-right: 50px;
  }

  .myexpertnav > ul li {
    background: none;
  }

  .myexpertnav > ul > li:last-child {
    background: none;
  }

  .myexpertnav > ul > li:first-child {
    background: none;
  }

  .myexpertnav.myaccountnav.qnanav ul li {
    width: 144px;
    height: 144px;
    background-color: #90E0EF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #004E77;
  }

  .myexpertnav > ul {
    justify-content: start;
  }

  section.entiredashboard.maincustomerdash .customermain {
    margin-bottom: 50px;
  }

  section.entiredashboard.maincustomerdash .dashboard h2 {
    font-size: 40px;
    color: #000000;
    font-weight: 700;
  }

  section.entiredashboard.maincustomerdash .dashboard h5 {
    font-size: 20px;
    font-weight: 500;
    color: #06293F;
  }

  .customer-dashboard section.entiredashboard.maincustomerdash .customermain {
    justify-content: start;
    border: none;
  }

  section.entiredashboard.maincustomerdash .dashboarditext {
    font-size: 12px;
    font-weight: 400;
  
    color: #06293F;font-family: "SF Pro Display"!important;
  }

  .myexpertnav.myaccountnav.qnanav.martop40.customermain .active img {
    filter: invert(100%) sepia(13%) saturate(0) hue-rotate(21deg) brightness(114%) contrast(101%);
  }

  .expertquestiondetailscategory span {
    color: #000;
font-family: 'microsoft_sans_serifregular' !important;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }

  .expertquestiondetailscategory {
    border-radius: 0px;
    text-align: center;
}

  .expName span {
    font-size: 18px;
    font-weight: 500;
    color: #303030;
    font-size: 'microsoft_sans_serifregular' !important;

  }

  .expertquestiondetails .questiondate {

    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.008em;
    color: #7D7D7D;
    margin-top: 0px;font-size: 'microsoft_sans_serifregular' !important;
  }

  .questioninnerinbottom {
    margin-bottom: 0px;
  }

  .questiontext {
    margin-bottom: 34px;
  }

  section.entiredashboard.maincustomerdash .questiontext.questiontext span {
    font-weight: 700;
    font-size: 18px;
    font-family: 'Poppins' !important;
    color: #303030;
  }

  .questiontext.questiontext b {
    font-size: 16px;
    font-weight: 500;
    color: #3B3B3B;
    font-family: 'microsoft_sans_serifregular' !important;
  }

  section.entiredashboard.maincustomerdash .dashboarditext h4 {
    font-size: 20px;
    font-weight: 400;
    font-style: italic;
    color: #06293F;
  }

  .customer-dashboard .myexpertnav.myaccountnav.qnanav.martop40.customermain .active {
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
    width: 144px;
    height: 144px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
  }

  .searchincustomer .heroleftpanel h2 {
    font-weight: 500;
    font-size: 32px;
    color: #303030;
    font-family: "Poppins" !important;
  }

  .searchincustomer .heroleftpanel h4 {
    font-weight: 400;
    font-size: 20px;
    color: #303030;
    line-height: 30px;
    margin-bottom: 0;
    font-family: "Poppins" !important;
  }

  .searchincustomer .searchmaininnerhero.heroleftpanel h2.h2_style {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 48px;
    color: #303030;
  }

  .searchincustomer .form-group .form-control {
    padding: 0;
  }

  .searchincustomer select.input.form-control.form-select:not(:focus):valid {
    font-size: 20px;
    font-weight: 500;
    color: #303030;
    height: 50px;
    border-radius: 6px;
    padding: 0 24px;
    background-color: #E5F3FF;
    font-family: 'Poppins' !important;
  }

 

  .searchincustomer .myexpertformarea {
    background: none;
    box-shadow: none;
  }



  .searchincustomer .searchmainara button.ctaonebutton {
    width: auto;
  }

  .searchincustomer .searchiconbutton {
    height: 50px;
    width: 50px;
    background-color: #E5F3FF;
    border-radius: 6px;
  }



  .searchincustomer .ctaonebutton {
    border: 1px solid transparent;
  }

  .searchincustomer .ctaonebutton:hover {
    background: #E5F3FF;
  }

  .swal-overlay--show-modal .swal-modal {
    background-color: #FFFFFF;
    border-radius: 6px;
    width: 600px;
    height: auto;
  }
  /* ask */
  .categorytext h2 {
      color: #000;
      margin-bottom: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
  }
  .categorytext span {
      color: #036518;
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
  }
  /* ask */
}
@media (max-width:767px){
  .swal-footer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.swal-footer .swal-button-container:nth-child(1) {
    order: 2;
}
.customer-dashboard .searchincustomer {
  margin-top: 0;
}
  section.entiredashboard.maincustomerdash{z-index: 9999; position: relative; overflow: visible;}
  .customer-dashboard.main-myaccount-dashboard .myaccountsection button.ctaonebutton.widthhalf
  {
    width: 95px;
    height: 30px;
    font-size: 12px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav.myaccountnav.qnanav{padding-left: 10px !important;margin-top: 0 !important;}
  section.entiredashboard.maincustomerdash.customer-dashboard .applybutton:hover{
    margin: 0 !important;
  }
  section.entiredashboard.maincustomerdash .dashboarditext {
    font-size: 12px;
    font-weight: 500;
  font-style: normal;
  line-height: 1;
    color: #06293F;font-family: "SF Pro Display"!important;
  }
  .allcustans .questionstext span, .questioninnerin span button{padding-left: 0px; color: #000;}

}
@media (min-width: 481px) and (max-width: 768px) {
  .dashboardrightpannerinnerleft {
    padding-top: 10px;
  }

  .myexpertnav.myaccountnav ul li:first-child.active {
    height: 144px;
    width: 144px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
  }

  .myexpertnav > ul > li.active {
    background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
  }

  .myexpertnav > ul > li:first-child.active {
    background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
  }

  .myexpertnav.myaccountnav > ul > li:last-child.active {
    background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
  }

  .myexpertnav.myaccountnav.qnanav ul li {
    margin-right: 50px;
  }

  .myexpertnav > ul li,
  .myexpertnav > ul > li:last-child,
  .myexpertnav > ul > li:first-child {
    background: none;
  }

  .myexpertnav.myaccountnav.qnanav ul li {
    width: 144px;
    height: 144px;
    background-color: #90E0EF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #004E77;
  }

  .myexpertnav > ul {
    justify-content: start;
  }

  section.entiredashboard.maincustomerdash .customermain {
    margin-bottom: 50px;
  }

  section.entiredashboard.maincustomerdash .dashboard h2 {
    font-size: 36px;
    color: #000000;
    font-weight: 700;
  }

  section.entiredashboard.maincustomerdash .dashboard h5 {
    font-size: 18px;
    font-weight: 500;
    color: #06293F;
  }

  .customer-dashboard section.entiredashboard.maincustomerdash .customermain {
    justify-content: start;
    border: none;
  }



  .myexpertnav.myaccountnav.qnanav.martop40.customermain .active img {
    filter: invert(100%) sepia(13%) saturate(0) hue-rotate(21deg) brightness(114%) contrast(101%);
  }

  .expertquestiondetailscategory span {
    background: none;
    border-radius: 0px;
    padding: 0;
    color: #000;

    font-size: 12px;
    font-weight: 500;
    display: inline-block;
  }

  .expertquestiondetailscategory {
    border-radius: 0px;
    text-align: center;
}

  .expName span {
    font-size: 16px;
    font-weight: 500;
    color: #303030;

  }

  .expertquestiondetails .questiondate {

    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.008em;
    color: #7D7D7D;
    margin-top: 0px;
  }

  .questioninnerinbottom {
    margin-bottom: 0px;
  }

  .questiontext {
    margin-bottom: 34px;
    color: #303030;
font-family: "Microsoft Sans Serif";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }

  section.entiredashboard.maincustomerdash .questiontext.questiontext span {
    font-weight: 700;
    font-size: 18px;
    font-family: 'Poppins' !important;
    color: #303030;
  }

  .questiontext.questiontext b {
    font-size: 18px;
    font-weight: 500;
    color: #3B3B3B;
    font-family: 'Poppins' !important;
  }

  section.entiredashboard.maincustomerdash .dashboarditext h4 {
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    color: #06293F;
  }

  .customer-dashboard .myexpertnav.myaccountnav.qnanav.martop40.customermain .active {
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
    width: 144px;
    height: 144px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
  }

  .searchincustomer .heroleftpanel h2 {
    font-weight: 500;
    font-size: 18px;
    color: #303030;
    font-family: "Poppins" !important;
  }

  .searchincustomer .heroleftpanel h4 {
    font-weight: 400;
    font-size: 18px;
    color: #303030;
    line-height: 30px;
    margin-bottom: 0;
    font-family: "Poppins" !important;
  }

  .searchincustomer .searchmaininnerhero.heroleftpanel h2.h2_style {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    color: #303030;
  }

  .searchincustomer .form-group .form-control {
    padding: 0;
  }

  .searchincustomer select.input.form-control.form-select:not(:focus):valid {
    font-size: 18px;
    font-weight: 500;
    color: #303030;
    height: 50px;
    border-radius: 6px;
    padding: 0 24px;
    background-color: #E5F3FF;
    font-family: 'Poppins' !important;
  }



  .searchincustomer .myexpertformarea {
    background: none;
    box-shadow: none;
  }

  

 

  .searchincustomer .searchiconbutton {
    height: 50px;
    width: 50px;
    background-color: #E5F3FF;
    border-radius: 6px;
  }


  .searchincustomer .ctaonebutton {
    border: 1px solid transparent;
  }

  .searchincustomer .ctaonebutton:hover {
    background: #E5F3FF;
  }


  .swal-overlay--show-modal .swal-modal {
    background-color: #FFFFFF;
    border-radius: 6px;
    width: 600px;
    height: auto;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .dashboardrightpannerinnerleft {
    padding-top: 8px;
  }

  .myexpertnav.myaccountnav ul li:first-child.active {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 20px 0px #00000040;
  }

  .myexpertnav > ul > li.active {
    background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
    box-shadow: 2px 2px 20px 0px #00000040;
  }

  .myexpertnav > ul > li:first-child.active {
    background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
    box-shadow: 2px 2px 20px 0px #00000040;
  }

  .myexpertnav.myaccountnav > ul > li:last-child.active {
    background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
    box-shadow: 2px 2px 20px 0px #00000040;
  }

  .myexpertnav.myaccountnav.qnanav ul li {
    margin-right: 30px;
  }

  .myexpertnav > ul li,
  .myexpertnav > ul > li:last-child,
  .myexpertnav > ul > li:first-child {
    background: none;
  }

  .myexpertnav.myaccountnav.qnanav ul li {
    width: 120px;
    height: 120px;
    background-color: #90E0EF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #004E77;
  }

  .myexpertnav > ul {
    justify-content: start;
  }

  section.entiredashboard.maincustomerdash .customermain {
    margin-bottom: 40px;
  }

  section.entiredashboard.maincustomerdash .dashboard h2 {
    color: #000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  section.entiredashboard.maincustomerdash .dashboard h5 {
    color:  #06293F;

font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }

  .customer-dashboard section.entiredashboard.maincustomerdash .customermain {
    justify-content: start;
    border: none;
  }


  section.entiredashboard.maincustomerdash .customermain{margin: 0 !important;}
  .myexpertnav.myaccountnav.qnanav.martop40.customermain .active img {
    filter: invert(100%) sepia(13%) saturate(0) hue-rotate(21deg) brightness(114%) contrast(101%);
  }

  .expertquestiondetailscategory span {
    background: none;
    border-radius: 0px;
    padding: 0;
    color: #000;

    font-size: 12px;
    font-weight: 500;
    display: inline-block;
  }
  

  .expertquestiondetailscategory {
    border-radius: 0px;
    text-align: center;
}

  .expName span {
    font-size: 14px;
    font-weight: 500;
    color: #303030;

  }

  .expertquestiondetails .questiondate {

    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.008em;
    color: #7D7D7D;
    margin-top: 0px;
  }
  section.entiredashboard.maincustomerdash .expertquestiondetails .widthfull{margin-top: 8px;}

  .questioninnerinbottom {
    margin-bottom: 0px;
  }

  .questiontext {
    margin-bottom: 24px;
  }

  section.entiredashboard.maincustomerdash .questiontext.questiontext span {
    font-weight: 700;
    font-size: 16px;
    font-family: 'Poppins' !important;
    color: #303030;
  }

  .questiontext.questiontext b {
    font-size: 16px;
    font-weight: 500;
    color: #3B3B3B;
    font-family: 'Poppins' !important;
  }

  section.entiredashboard.maincustomerdash .dashboarditext h4 {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    color: #06293F;
  }

  .customer-dashboard .myexpertnav.myaccountnav.qnanav.martop40.customermain .active {
    box-shadow: 2px 2px 20px 0px #00000040;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 50%;
  }

  .searchincustomer .heroleftpanel h2 {
    font-weight: 500;
    font-size: 18px;
    color: #303030;
    font-family: "Poppins" !important;
  }

  .searchincustomer .heroleftpanel h4 {
    font-weight: 400;
    font-size: 16px;
    color: #303030;
    line-height: 28px;
    margin-bottom: 0;
    font-family: "Poppins" !important;
  }

  .searchincustomer .searchmaininnerhero.heroleftpanel h2.h2_style {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 48px;
    color: #303030;
  }

  .searchincustomer .form-group .form-control {
    padding: 0;
  }

  .searchincustomer select.input.form-control.form-select:not(:focus):valid {
    font-size: 16px;
    font-weight: 500;
    color: #303030;
    height: 40px;
    border-radius: 6px;
    padding: 0 20px;
    background-color: #E5F3FF;
    font-family: 'Poppins' !important;
  }



  .searchincustomer .myexpertformarea {
    background: none;
    box-shadow: none;
  }

  
  .searchincustomer .searchmainara button.ctaonebutton {
    width: auto;
  }

  .searchincustomer .searchiconbutton {
    height: 40px;
    width: 40px;
    background-color: #E5F3FF;
    border-radius: 6px;
  }



  





  .swal-overlay--show-modal .swal-modal {
    background-color: #FFFFFF;
    border-radius: 6px;
    width: 320px;
    height: auto;
    padding: 30px 0;
  }
}
