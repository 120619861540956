
.question {
	background-image: url("../../../assets/homeimages/questionbg.png");
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 20px 0;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
	background-position: bottom;
	background-position: bottom;
}
.question .textquestion{padding-left:55px}
.question h2 {
	font-size: 30px;
	font-weight: 500;
	line-height: 36.57px;
	text-align: left;
	margin-bottom: 0;
}

.question h2 b {
	color: #000;
	font-weight: 800;
}

.question p {
	color: #000000;
	margin-bottom: 30px;
	font-size: 16px;
	font-weight: 400;
	text-align: left;
	margin-bottom: 0;
	margin-right: 35px;
	font-weight: 500;
}
.question p b{font-weight: 800;}


.askbutton:hover {
	cursor: pointer;
	border: 5px solid #FFFFFF;
	box-shadow: 0px 0px 20px 0px #EF000B;

}
.askbutton > img{width: 53px;}

.askbutton {
	background: #EF000B;
	width: 100%;
	padding: 10px;
	display: flex;
	border: 5px solid transparent;
	align-items: center;
	justify-content: center;
	margin: 0 0 0 auto;
	max-width: 531px;
}

.askbutton p {
	font-family: "Roboto Flex", sans-serif !important;
	font-size: 46px;
	font-weight: 700;
	line-height: 58.59px;
	text-align: left;
	color: #fff;
	margin-bottom: 0;
	margin-right: 35px;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
	.question .textquestion{padding-left:0px}
	.askbutton p{
		font-family: "Roboto Flex", sans-serif !important;
		font-size: 32px;
		font-weight: 700;
		line-height: 58.59px;
		text-align: left;
		color: #fff;
		margin-bottom: 0;
		margin-right: 7px;
	}
}
@media (max-width:767px){
	.question p b{ font-weight: 500;}
	.askbutton p{font-size: 25px;line-height: 20px; margin-right: 10px;}
	.askbutton img{width: 22px;}
	.askbutton{margin: auto;}
	.question .textquestion{padding-left: 0; }
	.question .textquestion h2, .question .textquestion p{
		text-align: center;
		margin: 0;
		line-height: 1.3;
	}
	.question{
		margin-bottom: 0; 
		
		margin-top: -7px;
		background: #fff;
		background-position:center bottom; 
		background-size: cover;
	}
	.question .textquestion h2{font-size: 18px; font-weight: 800;}
	.question .textquestion  p{
		font-size: 12px;
		max-width: 260px;
	}
	.askbutton p{font-size: 18px;}
	.askbutton{width: 200px; height: 40px;margin-top: 20px; padding: 0;}
}
 