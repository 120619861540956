
.newofferdesign .herosectionoffer{
    background: url(../../../assets/offersimage/offers_page_banner.svg);
    padding: 40px 0 40px 0;
background-size: cover;
height: 460px;
background-repeat: no-repeat;}
.offersearch h2{
color: #003962;
text-align: center;

font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 29px;
margin-top: 20px;
}
.offersearch h4{
    color: #000;
    text-align: center;
   margin-bottom: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.newofferdesign .limitedoffer{margin: 40px 0 0 0;}
.newofferdesign .limitedoffer .offerslider.martop40.mb-5{margin-bottom: 0 !important;}
ul.slick-dots li {
    background: #D6D0D0;
    border-radius: 50px;
    width: 10px;
    height: 10px;
}
li.slick-active {
    transform: scale(1.2);
}
.slick-dots{
    bottom: 0;
}
.offersearch{background: url(../../../assets/offersimage/searchback.png) no-repeat; 
padding: 30px 0;
height: 180px;
background-position: center center;}
.newofferdesign .offerbannerslider {
    border-radius: 20px;
border: 0.248px solid rgba(255, 255, 255, 0.40);
background: linear-gradient(114deg, rgba(0, 0, 0, 0.20) -26.83%, rgba(0, 0, 0, 0.20) 98.63%);
   position: relative;
    width: 95%;
    right: auto;
    left: auto;
    top: auto;
    bottom: auto;padding: 17px 25px 33px 25px;
}
.newofferdesign .herosectionoffer .row{align-items: center;}
.newofferdesign .herosectionoffer .offerherobannertext h2{
    color: #000;
font-size: 50px;
font-style: normal;
font-weight: 700;
line-height: 61px;
}
.newofferdesign .herosectionoffer .offerherobannertext h2 span{
color: #000;

font-size: 45px;
font-style: normal;
font-weight: 700;
line-height: 55px;
}
.newofferdesign .herosectionoffer .offerherobannertext h5{
color: #000;

font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 24px;
}
.newofferdesign .limitedofferinner {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
}
.newofferdesign .sliderContent.slider-image img {
    /* padding: 14px 25px 34px 25px; */
}
.newofferdesign  .offerbannerslider ul.slick-dots {
    bottom: -20px;
}
.newofferdesign .limitedofferinner .sliderContent{
border-radius: 15px;
background: #FFF;
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
/* padding: 15px; */
margin-bottom: 40px;
min-height: auto;
overflow: hidden;
}
.newofferdesign .limitedofferinner .sliderContent img.d-block.w-100 {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35); margin-bottom: 10px;height: 280px;
    object-fit: contain;
}
.newofferdesign  .descriptionppvpop {
    border-radius: 0;
    background: #fff;
    padding: 00px;
    position: relative;
    width: 100%;
    bottom: 0;
    border: none;
    box-shadow: none;
    padding: 15px 15px 0;
}
.descriptionppvpop .ppvcatds .ctnam{color: #000;
    font-family: 'microsoft_sans_serifregular' !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
position: relative;}
.sliderContent .descriptionppvpop .ppvcatds .ctnam:after, .sliderContent .descriptionppvpop .pscat:after{
    content: '';
    position: absolute;
    width: 60px;
    height: 1px;
    background: #00831D;
    left:0;
    bottom: -3px;
}
.sliderContent.Insurance .descriptionppvpop .ppvcatds .ctnam:after, .sliderContent.Insurance .descriptionppvpop .pscat:after
{background: #FF4C00;}
.sliderContent.Investment .descriptionppvpop .ppvcatds .ctnam:after, .sliderContent.Investment .descriptionppvpop .pscat:after
{background:#86357A}
.sliderContent.Mortgage .descriptionppvpop .ppvcatds .ctnam:after, .sliderContent.Mortgage .descriptionppvpop .pscat:after
{background:#2F596F}
.sliderContent.Banking .descriptionppvpop .ppvcatds .ctnam:after, .sliderContent.Banking .descriptionppvpop .pscat:after
{background:#2280B8}
.sliderContent .descriptionppvpop .pscat:after{width: 135px;}
.newofferdesign .offerslider .sliderContent h3, .newofferdesign .offerslider .sliderContent .ppvcat h3{
color: var(--green);
font-family: "Poppins", sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 1;

}
.newofferdesign .offerdspop{margin: 20px 0;}
.newofferdesign .offerdspop .ppvcat h5{color: #000;
   
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
text-transform: capitalize;}
.pscat span{position: relative;
    margin-right: 5px;}
.sliderContent  .descriptionppvpop .pscat{color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
position: relative;}
/* .newofferdesign .offerslider .sliderContent h5, .newofferdesign .offerdspop .ppvcat h5{
color: #000;
font-family: "Poppins", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 1;
} */
.newofferdesign  .sliderContent button.ctaonebutton.widthhalf {
margin-top: 0px;
margin-bottom: 18px;
border-radius: 6px;
border: 1px solid var(--green);
background: #E6FFEA;
color: var(--green);
text-align: center;
font-family: "Roboto Flex";
font-size: 18px;
font-weight: 600;
line-height: 21px;
width: auto;
margin-left: 15px;
padding: 9px 18px;
}
.newofferdesign  .sliderContent.Banking button.ctaonebutton.widthhalf{
border: 1px solid var(--Bluetwo);
color: var(--Bluetwo);
background: #D8EDFF;
}
.newofferdesign  .sliderContent.Mortgage button.ctaonebutton.widthhalf{
border: 1px solid var(--Blueone);
background: #D8DEE9;
color: var(--Blueone);
}
.newofferdesign  .sliderContent.Insurance button.ctaonebutton.widthhalf{
border: 1px solid #FF4C00;
color: #FF4C00;
background: #FFE3D7;
}
.newofferdesign  .sliderContent.Investment button.ctaonebutton.widthhalf{
    border: 1px solid var(--purple);
    color: var(--purple);
    background: #FFE1FB;
    }

.newofferdesign .offerslider .sliderContent h5{margin-bottom: 0; min-height: 60px;}
.newofferdesign .offerslider .sliderContent .offerdspop .ppvcat h5{min-height: auto;}
.newofferdesign .offerslider .sliderContent.Banking h3, .newofferdesign .offerslider .sliderContent.Banking .ppvcat h3
{color: var(--Bluetwo);}
.newofferdesign .offerslider .sliderContent.Investment h3, .newofferdesign .offerslider .sliderContent.investment .ppvcat h3
{    color: var(--purple);}
.newofferdesign .offerslider .sliderContent.Mortgage h3, .newofferdesign .offerslider.Mortgage .sliderContent .ppvcat h3
{color: var(--Blueone);}
.newofferdesign .offerslider .sliderContent.Insurance h3, .newofferdesign .offerslider .sliderContent.Insurance .ppvcat h3
{    color: #FF4C00;}
.newofferdesign .offerslider .col-lg-6.col-sm-6 {
    padding: 0 11px;
}
b.offer-bold {
    font-size: 24px;
    font-weight: 400;
}
.newofferdesign  .mainofferdetails h2{
    color: #000;
    text-align: center;

    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.54px;
letter-spacing: 0.54px;
}
.new-offer-design .limited-offers h4.offer-subtext {
    font-weight: 600;
    font-size: 24px;
    color: #303030;
}
.new-offer-design  .limited-offers  h4.foffer-description {
    color: #747474;
    font-size: 13px;
    font-weight: 400;
    min-height: 35px;
    font-family: 'microsoft_sans_serifregular' !important;
    margin-bottom: 0;
    margin-top: 10px;
    line-height: 15px;
}
.new-offer-design .mainofferdetails h5{
    color: #505050;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}
.new-offer-design .mainofferdetails h5 b{color: #303030;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;}
.descriptionppvpop .d-flex {
    justify-content: space-between;
}

.offerslider .col-lg-6.col-sm-6 {
    padding: 0 25px;
}

.selectallinnercheckbox>ul>li {
    list-style: none;
    border-radius: 10px;
    border: 1px solid #048520;
background: linear-gradient(180deg, var(--green) 0%, #75CA75 100%);
    padding: 10px;
    width: 250px;
    margin-right: 20px;
}
.selectallinnercheckbox>ul>li.Investment {
    border: 1px solid var(--purple);

    background: linear-gradient(0deg, #EF97E2 0%, #8C3B80 100%);    
}
.selectallinnercheckbox>ul>li.Banking {
   
    border: 1px solid #FFF;
    background: linear-gradient(0deg, #9FD4F4 0%, var(--Bluetwo) 100%);
   
}
.selectallinnercheckbox>ul>li.Insurance {
   
    border: 1px solid #FF601B;
    background: linear-gradient(0deg, #FF9B70 0%, #FF5108 100%);
}
.selectallinnercheckbox>ul>li.Mortgage {
    border: 1px solid var(--Blueone);
    background: linear-gradient(0deg, #7E9DAD 0%, #355E74 100%);
}
.selectallinnercheckbox>ul>li[disabled] {
    list-style: none;
    border-radius: 10px;
    border: 1px solid #676767;
    background: linear-gradient(0deg, #FFF 0%, #D6D6D6 100%);
    padding: 10px;
    width: 250px;
    margin-right: 10px;
}
.selectallinnercheckbox>ul {
    display: flex;
    padding: 0;
}
li.selectinner .dropdown:after {
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 15px solid #fff;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        position: absolute;
        right: 0;
        top: 7px;
        bottom: 0;
        margin: auto;
        display: none;
    }

li.selectinner .dropdown {
    position: relative;
}
.selectinner  .mainchecklabel{
color: #fff;
font-family: "Fjalla One", sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 25px;
}
span.maincheckboxicon {
    margin-right: 10px;
}
span.maincheckboxicon img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.selectallinnercheckbox ul li ul.dropdown-menu {border-radius: 10px;
    opacity: 0.9;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 2px 2px 18px 0px rgba(0, 0, 0, 0.25);
    inset: unset !important;
    margin: 0px !important;
    transform: translate(0px, 11px) !important;
width: 100%;
top: 10px !important;}
.selectallinnercheckbox .form-check {display: flex; align-items: center;}
.selectallinnercheckbox  li.selectinner.Real.Estate .dropdown-item:hover, .selectallinnercheckbox  li.selectinner.Real.Estate  .dropdown-item:focus{background: #D7FFD7;}
.selectallinnercheckbox  li.selectinner.Investment .dropdown-item:hover, .selectallinnercheckbox  li.selectinner.Investment  .dropdown-item:focus{background: #FFE1FB;}
.selectallinnercheckbox  li.selectinner.Mortgage .dropdown-item:hover, .selectallinnercheckbox  li.selectinner.Mortgage  .dropdown-item:focus{background: #D8DEE9;}
.selectallinnercheckbox  li.selectinner.Insurance .dropdown-item:hover, .selectallinnercheckbox  li.selectinner.Insurance  .dropdown-item:focus{background: #FFE3D7;}
.selectallinnercheckbox  li.selectinner.Banking .dropdown-item:hover, .selectallinnercheckbox  li.selectinner.Banking  .dropdown-item:focus{background: #D8EDFF;}
.selectallinnercheckbox ul.dropdown-menu label.form-check-label{
    width: 126px;
    color: #000;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 1;
padding-left: 10px;
}
input#Checkall {
    width: 25px;
    height: 25px;
    border-color: var(--green);
}
span.subcatimage {
    width: 30.023px;
    height: 30.023px;
    background: var(--green);
    border-radius: 30px;
    text-align: center;
}
ul.dropdown-menu  input[type="checkbox"]:enabled:checked {
    background-color: var(--green) ;
    color: white;
}
input#Checkall :enabled:checked{background-color: var(--green) ;}
.selectallinnercheckbox  li.selectinner.Banking span.subcatimage, .selectallinnercheckbox  li.selectinner.Banking ul.dropdown-menu input[type="checkbox"]:enabled:checked{background-color: var(--Bluetwo);}
.selectallinnercheckbox  li.selectinner.Banking ul.dropdown-menu .form-check-input:checked{border-color: var(--Bluetwo);}
.selectallinnercheckbox  li.selectinner.Insurance span.subcatimage, .selectallinnercheckbox  li.selectinner.Insurance ul.dropdown-menu input[type="checkbox"]:enabled:checked{background-color: #FF601B;}
.selectallinnercheckbox  li.selectinner.Mortgage span.subcatimage, .selectallinnercheckbox  li.selectinner.Mortgage ul.dropdown-menu input[type="checkbox"]:enabled:checked{background-color: var(--Blueone);}
.selectallinnercheckbox  li.selectinner.Investment span.subcatimage, .selectallinnercheckbox  li.selectinner.Investment ul.dropdown-menu input[type="checkbox"]:enabled:checked{background-color: var(--purple);}
span.subcatimage img {
    filter: invert(1);
    margin: auto;
    padding: 5px;
    padding: 5px;
}
.selectinner .dropdown .form-check{display: flex;
    align-items: center;}
    .form-checkselect {
        border-radius: 6px;
        border: 2px solid var(--green);
        background: #FFF;
        width: 184px;
        display: flex;
        align-items: center;
        padding: 10px;
    }
    
    .selectallcheckbox label.form-check-label.mainchecklabel {
        color: var(--green);
        font-family: "Fjalla One", sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
    }
    .selectallcheckbox span.maincheckboxicon {
        margin-right: 10px;
        margin-left: 10px;
    }
    .selectallcheckbox {
        margin-bottom: 20px;
    }
    .vectorone {
        position: absolute;
        left: -34px;
        top: 50px;
        width: 66px;
    }
    .newofferdesign .vector-img{
        position: absolute;
        left: -50px;
        top: 50px;
        width: 107px;
        display: none;
    }
    .newofferdesign .vector-img-too{
        position: absolute;
        right: -52px;
        bottom: -81px;
        z-index: 9;
        width: 103px;
        display: none;
    }
    .vectortwo {
        position: absolute;
        right: -52px;
        bottom: -81px;
        z-index: 9;
        width: 103px;
    }
    .selectallinnercheckbox>ul>li[disabled] .mainchecklabel{color: #676767;}
    .selectallinnercheckbox>ul>li[disabled] span.maincheckboxicon img { filter:invert(44%) sepia(0%) saturate(166%) hue-rotate(174deg) brightness(90%) contrast(93%)}
    .selectallinnercheckbox>ul>li.selectinner[disabled] .dropdown:after {
       
        border-top: 15px solid #000;
        
    }
    .offerbannerslider 	.slick-next:before{
        content: "";
        background: url(../../../assets/homeimages/slicknext2.svg);
        width: 40px;
        height: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        rotate: 0deg;
        display: block;
        position: absolute;
        left: 40px;
        top:0;
        filter:invert(1);
    }
    .offerbannerslider  .slick-prev:before {
        filter:invert(1);
        content: "";
        background: url(../../../assets/homeimages/slicknext2.svg);
        width: 40px;
        height: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(180deg);
        display: block;
        position: absolute;
        right: 40px;
        top:0;
    }
   
    .offerbannerslider .slick-prev, .offerbannerslider .slick-next{width: 30px; height: 30px; z-index: 999;}
    .offerbannerslider .sliderContent{    position: relative;
              padding-top: 50%; margin: 0; box-shadow: none; background: transparent;}
      .offerbannerslider .sliderContent a  {
            display: flex;
            justify-content: center;
            align-items: baseline;
            width: 100%;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }
         .sliderContent.slider-image img{
            height: 100%;
            object-fit: contain;
            width: 100%;
            background: #fff;
         }
       .newofferdesign  .homepageofferblock.carddecoration, .container-rimib  .homepageofferblock.carddecoration{
            display: flex        ;
            align-items: center;
            margin: 10px auto;
            justify-content: center;
            background: #fff;
            position: relative;
            padding-top: 50%;
            overflow: hidden;
        }
        .newofferdesign .homepageofferblock.carddecoration > a, .container-rimib  .homepageofferblock.carddecoration > a{
            display: flex;
    justify-content: center;
    align-items: baseline;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0 0px;
    margin-bottom: 10px;
}
.newofferdesign .homepageofferblock.carddecoration > a > div img,  .container-rimib  .homepageofferblock.carddecoration >a > div img {
    display: block;
    height: 300px;
    object-fit: contain;
    width: 100%;
}       