 @media screen and (min-device-width: 1200px) and (max-device-width: 5000px) {
    /* .realEstateBanner {height: calc(100vh - 0px);} */
    /* .realEstateBanner {height: 702px);} */
 }
 @media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .categorybanner .askbutton p.askres {
        font-size: 30px;
        color: white;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0;
        line-height: 35px;
    }
    .categorybanner .askbutton img {
        width: 30px;
        margin: 0 0 0 10px;
    }
    .categorybanner .askbutton
    {
        width: 300px;
        height: 50px;
    }
    .bannerfont p {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
    }
    .bannerfont h6 {
        color: #000000;
        font-family: "Montserrat", sans-serif !important;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 20px;
    }
    .categorybanner .bn-image img {
        height: 308px;
    }
    .categorybanner {
        background-position: bottom !important;
        height: 458px;
    }
    .categorysectionthree .row{ flex-direction: column-reverse;}
    .categorysectiontwo .categoryproductsliderinner{margin: 0 30px;}
    .categorysectiontwo .slick-prev{left: 0;}
    .categorysectiontwo .slick-next{right: 0;}
    .bannerfont h2:after{width: 100%;}
 }
@media (max-width: 767px) {
    .categorysectionfour  .p.bestdealsbox.bestexperts {
        margin-top: 0px;
    }
    .categorysectionone .applybutton:hover {margin: 10px 0 !important;}
    /* .container-rimib .categorysectionone .homepageofferblock.carddecoration>a>div{
        height: 209px;
        overflow: hidden;
    } */
    .modalspecial.productdetail-modal .listofoffermainsection button.gototop{
        right: 25px !important;
    bottom: 16px !important;
    background: rgba(70, 70, 70, 0.40) !important;
    width: 35px !important;
    height: 35px !important;
    border-radius: 5px !important;
    }
    .modalspecial.productdetail-modal .category-2 .detailedtext .ctaonebutton,
    .modalspecial.productdetail-modal .category-1 .detailedtext .ctaonebutton,
    .modalspecial.productdetail-modal .category-3 .detailedtext .ctaonebutton,
    .modalspecial.productdetail-modal .category-4 .detailedtext .ctaonebutton,
    .modalspecial.productdetail-modal .category-5 .detailedtext .ctaonebutton
    {
        margin-left: auto;
        margin-right: auto;
        width: 95px !important;
        height: 25px !important;
        font-size: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
    .modalspecial.productdetail-modal >div {
        width: 100%;
        border-radius: 20px;
        background: #FFF;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.40);
        max-width: 95%;
        padding: 0;
    }
    .modalspecial.productdetail-modal .detailedview{
        padding: 0px;
        margin-bottom: 10px;
       
    }
    .modalspecial.productdetail-modal .detailedview .detailedimage{
         margin-bottom: 10px;
    }
    .modalspecial.productdetail-modal .detailedview .detailedimage img{border-radius: 6px 6px 0px 0px;border:none;}
    .modalspecial.productdetail-modal .listofoffermainsection .sidetext {
        padding-left: 0;
    }
    .categorybanner{
        background: url(../../../assets/realEstateimages/realestatemobilebanner.svg);
        background-position: bottom !important;
    height: 560px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    overflow-x: visible;
    }
    .categorysectionthree .slick-prev:before{
        left:-40px;
        position: absolute;
    }
    .categorysectionthree .slick-next:before{
        right:-40px;
        left: auto;
        position: absolute;
    }
    .bannerfont h2{font-size: 28px; margin-bottom: 20px; line-height: 1;}
    .realEstateBanner {
        height: 500px;
        background-size: cover;
        background-position: center;
        display: flex;
    }
    .bannerfont h2:after{width: 200px;}
    .bannerfont p{
        font-size: 10px;
        margin-bottom: 10px;
        line-height: 12px;
        font-family: "SF Pro Display" !important;
    }
    .categorysectionone {
        margin: 20px 0;
    }
    .bannerfont h6{font-size: 16px; line-height: 1.1; margin-bottom: 10px;}
    .bannerfont .bannersubtext{font-size: 12px;}
    .categorybanner .askbutton p.askres {
        font-size: 18px;
        line-height: 1;
    }
    .categorybanner .askbutton img {
        width: 25px;
    }
    .categorybanner .askbutton {
        background: #EF000B;
        max-width: 100%;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid transparent;
        margin: 0;
        width: 200px;
        height: 40px;
    }
    .offersss .slick-slide img {
        display: block;
        height: auto;
        object-fit: contain;
        width: 100%;
    }
    .categorysectionthree .colinstititeinnerimage img {
        position: absolute;
        width: 75%;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        bottom: 0;
    }
    .container-fluid.makethecontainer{
        padding: 0;
    }
    .realEbuttonmain img {
        width: 50px;
    }
    .askbuttoncomm {
        width: 65%;
    }    
    img.borderimg {
        width: 204px;
    }
    .realestatefont h2 {
        font-size: 40px;
    }
    .makethecontainer .realEinner{
        width: 60%;
    margin: 0 0 0 auto;
    }
    .realestatefont .realEinner.borderbottom img {
        margin: 0 auto;
        width: 42px;
    }
    b.realEbold {
        font-size: 30px;
    }
    .realestatefont h6 {
        font-size: 20px;
        line-height: 30px;
        max-width: 100%;
    }
    .realestatefont p {
        font-size: 18px;
        max-width: 100%;
    }
    .realestatefont.borderbottom span {
        font-size: 18px;
    }
    .realEbuttonmain {
        padding: 10px;
        justify-content: center;
    }
    .categorysectionfive .realEbuttonmain p.realEbutton {
        font-size: 18px;
        font-family: "Roboto Flex", sans-serif !important;
        color: white;
        line-height: 1;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0;
        text-align: left;
    }
    .categorysectionfive .realEbuttonmain img {
        width: 25px;
        margin: 0 0 0 10px;
    }
    .categorysectionthree .slick-next {
        right: -0px;
        transform: none;
        width: 20px;
        height: 20px;
    }
    .categorysectionone .slick-next:before, .categorysectiontwo .slick-next:before, .categorysectionthree .slick-next:before,
    .categorysectionone .slick-prev:before, .categorysectiontwo .slick-prev:before, .categorysectionthree .slick-prev:before
    {
        width: 30px;
        height: 30px;
    }
    .categorysectionone .slick-next, .categorysectiontwo .slick-next, .categorysectionfour .slick-next {
        right: 7px;
        width: 30px;
        height: 30px;
    }
    .categorysectionone .carddecoration{padding-left: 30px; padding-right: 30px;}
    .categorysectionone .slick-prev, .categorysectiontwo .slick-prev, .categorysectionfour .slick-prev, .categorysectionthree .slick-prev {
        left: 4px;
        width: 30px;
        height: 30px;
    }
    .categorysectiontwo .slick-prev, .categorysectiontwo .slick-next{top:70%;}
    .categorysectionfive{
        background: url(../../../assets/realEstateimages/asktheexpetbgmobile.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .realEstate-bgimg{
        background: url(../../../assets/realEstateimages/Realestatebackgroundmobile.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .categorysectionsix{
        background: url(../../../assets/realEstateimages/Realestatearticlesbg.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .categorysectionfour .slick-prev:before{width: 30px; height: 30px;}
    .categorysectionfour .slick-prev{left:-5px;}
    .categorysectionfour .slick-next{right:-5px;}
    .categorysectionfour .slick-next:before{width: 30px; height: 30px;}
.categorybanner .row{
    flex-direction: column-reverse;
}
.categorybanner .bn-image img {
    height: 235px;
    float: right;
}
.investmentnewui .categorybanner .bn-image img {
    height: 210px !important;
    margin-top: 10px !important;
}
.mortgagenewui .categorybanner .bn-image img {
    height: 255px !important;
}
.insurancenewui .categorybanner .row {
    flex-direction: column;
}
section.categorysectionsix .blogreadmore{display: block !important;     width: 100%;
    text-align: center;
    margin-top: 10px !important;}
    .categorysectionthree  .p0{
        padding: 0 15px !important;
    }
.investmentnewui .categorybanner .row{
    flex-direction: column;
}
.categorybanner .bn-image{text-align: right;}
.categorybannertext {
    text-align: left;
    padding-top: 15px;
}
    .container-fluid.letestcontainer {
        padding: 0 20px 10px 20px;
    }
    .homepageproductoffer .letest-inner h2 {
        font-size: 32px;
        margin-bottom: 15px;
    }

    .letest-inner h6 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .letest-inner span {
        font-size: 18px;
    }

    .showallbtn {
        max-width: 100%;
        justify-content: center;
        text-align: center;
    }
    .posbuttontf.d-flex a{
        font-size: 13px;
    }
    .viewallbutton{
        padding: 6px 37px;
    font-size: 20px;
    }
    .TopRealEstateProducts-inner h2 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .TopRealEstateProducts-inner h6 {
        font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 1; margin-bottom: 10px; font-family: "SF Pro Text" !important; max-width: 90%; margin-left: auto; margin-right: auto;
    }
.categorysectionthree  .row{
    flex-direction: column-reverse;
}
.categorysectionthree .toprealErighttext h2{
    font-size: 18px; line-height: 1.3; text-align: center; max-width: 228px; margin: auto;
}
.categorysectionthree .toprealErighttext p{margin-bottom: 0;}
.categorysectionthree .toprealErighttext p .topsubtext, .categorysectionfour .expertsub2, .topsubtextfive, .categorysectionfour h5.expertsub1{ max-width:90%; margin-left:auto; margin-right:auto;font-family: "SF Pro Text" !important; font-size: 10px;line-height: 1; text-align: center; display: block;}
    .TopRealEstateProducts-inner span {
        font-size: 18px;
    }
    .categorysectionfive .realEbuttonmain{
        width: 200px;
        height: 40px;
        padding: 0;
    }   
    .homepageproductoffer .realeheading {
        font-size: 14px;
        line-height: 20px;
    }

    .realEblogbg .realEbloginner h2 {
        font-size: 28px;
        line-height: 36px;
    }
    .ToprealEimgslider {
        height: 283px;
        max-width: 100%;
    }

    .toprealErighttext {
        margin-left: 0px;
        margin-top: 20px;
        text-align: center;
    }

    .toprealErighttext h2, .categorysectionfour .expertheading {
        font-size: 18px;
        max-width: 100%;
    }
    .categorysectionthree .mt-5.text-center
    {
        margin-top: 10px !important;
    }
    .toprealErighttext p {
        font-size: 16px;
        max-width: 100%;
    }
    .toprealEbutton{
        padding: 8px 21px;
    font-size: 17px;
    }
    .ToprealEimgbox {
        margin-bottom: 20px;
    }
    .ToprealEimgbox a{
        font-size: 11px;
    }
    img.toprealEtext {
        width: 176px;
    }
    span.canada {
        font-size: 50px;
    }
    img.toprealEborder {
        width: 250px;
    }
  
    .ToprealE{
        max-height: 500px;
    }
    .homepageproductoffer .expertheading {
        font-size: 29px;
        margin: 0;
    }
    
    h5.expertsub1 {
        font-size: 15px;
        margin: 0;
    }

    .expertsub2 {
        font-size: 15px;
        margin: 0;
    }
    .realEquestion .realEquestiontext h4 {
        font-size: 14px;
        line-height: 1.1;
        color: #FFFFFF;
        font-weight: 600;
        margin: 0;
        font-family: "SF Pro Text" !important;
    }
    .realEquestionbanner .realEquestiontext h2 {
        font-size: 17px;
        color: #FFFFFF;
        font-weight: 500;
        margin: 0;
    }
    .realEquestionbanner .realEquestiontext span {
        font-size: 20px;
        color: #FFFFFF;
        font-weight: 800;
        text-transform: uppercase;
    }
    .realEquestiontext h3 {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 20px;
    }
    .realEblogbg.realEbloginner h2 {
        font-size: 36px;
    }
    
    .realEbloginner p.realEblogtext, .categorysectionfive .realEquestiontext h2, .categorysectionsix .realEbloginner h2 {
        font-size: 18px;
        max-width: 100%;
        line-height: 1.1;
    }
    .categorysectionfive .realEquestiontext h2{margin: 10px 0;}
    .categorysectionfive .realEquestiontext h3 {
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 40px;
        font-family: "SF Pro Text" !important;
        margin-bottom: 20px;
    }
    section.categorysectionsix .blogreadmore img{
        display: none;
    }
    section.categorysectionsix .blogreadmore{
        width: 90px !important;
        margin-left:auto !important ;
        margin-right: auto !important;
        height: 25px;
        font-family: "Roboto Flex";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0px;
        border: 1.603px solid #00831D;
        color: #00831D;
        background: #D2FFD2;
        line-height: 1.5;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
    .categorysectionfive{margin-bottom: 20px;}
    .categorysectionsix .bloginnertext{font-size: 12px;
        line-height: 1.1;}
    .realEbloginner p {
        font-size: 18px;
        max-width: 100%;
    }
    .modalspecial.productdetail-modal h1.h2_style.font-weight-bold{
        font-size: 14px;
line-height: 1;
font-weight: 700;
    }
    .modalspecial.productdetail-modal h5.h5_style, .modalspecial.productdetail-modal .detailedtext p, .modalspecial.productdetail-modal .listofoffermainsection .sidetext p{font-size: 12px; font-family: "SF Pro Display" !important;}
    .modalspecial.productdetail-modal .detailedtext p
    {padding: 0; padding-left: 10px;}
    .modalspecial.productdetail-modal .listofoffermainsection .sidetext p{padding-left: 10px;}
    .leftsightbackdetails.listofofferleft h1.h2_style.font-weight-bold {
        margin-top: 20px !important;
    }
    .modalspecial.productdetail-modal .close_btn{
        width: 24px;
height: 24px;
line-height: 20px;
background-image: url(../../../assets/images/white_cross.svg) !important;
background-size: 12px !important;
background-repeat: no-repeat !important;
background-position: center center !important;
    }
    .modalspecial.productdetail-modal .close_btn img{display: none;}
    .modalspecial.productdetail-modal .detailedofferheading{
        font-size: 12px;
    }
    .modalspecial.productdetail-modal .category-3 .detailedtext .ctaonebutton {
        background: #2f596f;
        margin-left: auto;
        margin-bottom: 30px;
    }
    .modalspecial.productdetail-modal .detailedview .row{
        flex-direction: column-reverse;
    }
    section.categorysectionone .container {
        padding: 0;
    }
    section.categorysectionone .offersss .col-lg-12.col-md-12.col-sm-12.col-xs-12,    section.categorysectionone .container
    {
        padding: 0;
    }
    .container-rimib section.categorysectionone  .homepageofferblock.carddecoration >a > div img{
height: 178px;
    }
    section.categorysectionone .slick-slider.slick-initialized
    {
        padding:0
    }
    .categorysectiontwo .slick-slider.slick-initialized{
        padding: 0;
    }
    .categorysectiontwo .categoryproductsliderinner{padding: 0;margin: 0; padding-bottom: 10px; margin-top: 0 !important;}
    .categorysectionone .slick-prev:before, .categorysectionone .slick-next:before{top:78px; position: absolute;}
    .categorysectionthree{margin: 20px 0;}
    .bestdeals{padding-bottom: 20px;}
    .topsubtextfive, .categorysectionfour .expertsub2, .categorysectionfour h5.expertsub1, .categorysectionsix .realEbloginner p.realEblogtext, .categorysectionsix .realEbloginner p{text-align: center; font-size: 10px; display: block; line-height: 1.1;}
}
@media (max-width:530px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 300px;
    }
}

@media (max-width:480px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 270px;
    }
}
@media (max-width:440px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 240px;
    }
}
@media (max-width:420px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 220px;
    }
}
@media (max-width:380px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 200px;
    }
}
@media (max-width:350px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 178px;
    }
}

/*@media (max-width:520px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 100%;
    }
}

 @media (max-width:480px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 100%;
    }
}
@media (max-width:440px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 100%;
    }
}
@media (max-width:420px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 100%;
    }
}
@media (max-width:380px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 100%;
    }
}
@media (max-width:350px){
    .container-rimib section.categorysectionone .homepageofferblock.carddecoration >a > div img {
        height: 100%;
    }
} */
