
.articlepart{
    padding:  0% 15%;
}

.articleTitle{ 
    font-weight: 700;  
    color:  #2F596F; 
    margin: 25px 0px 15px 0px;
}

.articleflow{
    font-weight: 400;  
    color:  #303030; 
    margin: 25px 0px 15px 0px;
    font-size: 30px;
}

.articleflowsub{
    font-weight: 400;  
    color:  #2F596F;
    margin: 25px 0px 15px 0px;
    font-size: 30px;
}

.articlesubheading{
    font-weight: 600;  
    color:  #2F596F;
    margin: 25px 0px 0px 0px;
    font-size: 35px;  
}
.subunderlines{
    width: 92px;
    height: 6px; 
    background: #2F596F;
    margin-left:14px;
}

.articlepublishdate
{ 
    font-size: 13px;
    font-weight: 600;  
    color:   #2280B8;
    text-align: end;
}

.articleimage{  
    margin: 0px 0px 25px 0px;
}

.articledetails{  
    margin: 0px 0px 80px 0px;
}