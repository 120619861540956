@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Namdhinggo:wght@400;500;600;700;800&display=swap');


:root{
    --offersbg: url(../../../assets/Instituteppvimages/offers-bg.svg);
}

.institutenewdesignppvinner .publicviewprofiledetails
{
	background: url('../../../assets/Instituteppvimages/institute-bg.svg');
	margin-bottom: 0;
    padding: 50px 0px 50px 0px;
    background-position: center;
    background-size: cover;
    
}

.institutenewdesignppvinner .publicviewprofiledetails .white-top-strip{
    margin-bottom: -25px;
}
.publicmainpopdispp {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 30px;
    padding: 30px;
    max-width: 100%;
    position: relative;
    width: 90%;
    margin: auto;
    display: flex;
}
.institutenewdesignppvinner .publicviewprofilede h2.h2_style{
    color: #393939;
    font-family: "Namdhinggo", serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}
    .institutenewdesignppvinner .publicviewprofilede   .Ppvlocation a{
        color: #000;
        font-size: 15px;
        font-style: italic;
        font-weight: 500;
        line-height: normal;
    }

    .institutenewdesignppvinner .publicviewprofilede{
        width: 70%;
        border-right: 4px solid gray;
    }

    .institutenewdesignppvinner .publicviewprofilede   .Ppvlocation img{
        width: 18px;
    }

    .institutenewdesignppvinner ul.deone {
        border: none;
        padding: 0;
        justify-content: left;
        margin-top: 20px;
    }
    .institutenewdesignppvinner ul.deone li {
        margin-right: 30px;
    }
    .institutenewdesignppvinner  ul.deone:before{
        display: none;
    }
   .institutenewdesignppvinner ul.deone li {
        position: relative;
        padding: 12px 30px 12px 50px;
        border-radius: 10px;
        background-color: #BAE8C1;
        width: auto;
        line-height: 1;
    }
    .institutenewdesignppvinner ul.deone li a  {
        color: #000;
        font-family: Montserrat;
        font-size: 16px;
        text-shadow: 1px 1px 3px gray;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .institutenewdesignppvinner ul.deone li span {
        position: absolute;
        left: 0;
        top: 0px;
        border-radius: 10px;
        border: 1px solid white;
        background: #368b43;
        width: 39px;
        height: 39px;
        text-align: center;
        line-height: 35px;
        transform: scale(1.3);
    }

    .institutenewdesignppvinner ul.deone li span img{
        width: 17px;
    }
 
    
    .institutenewdesignppvinner .socialpublic ul {
        /* display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: -14%;
        margin-bottom: 14%; */
    }
    .institutenewdesignppvinner .socialpublic {
        position: absolute;
        right: 38px;
        margin: auto;
        width: 40px;
        top: 0;
        bottom: 0;
        height: 170px;
    }
    .institutenewdesignppvinner .socialpublic ul {
        margin: 0;
        padding: 0;
    }
    .institutenewdesignppvinner .socialpublic ul li {
        background: #fff;
        border-radius: 30px;
        margin-bottom: 15px;
    }
    
    .institutenewdesignppvinner .socialpublic ul li span img {
        width: 15px;
    } 
    .socialpublic li:first-child span img{
        width: 10px !important;
    }

    .institutenewdesignppvinner .socialpublic ul li span {
        position: relative !important;
        top: 0 !important;
        right: 0 !important;
    }

    .institutenewdesignppvinner  .publicviewprofiledetailslogoupload {
        width: 150px;
        position: relative;
        margin: auto;
    }

   .publicviewprofiledetails .publicpersonaldetails{
        background: var(--green);
        padding: 10px;
        width: 100%;
        border-radius: 7px;
        text-align: center;
       
        position: absolute;
        max-width: 320px;
        right: 123px;
        bottom: 26px;
    }
    .publicpersonaldetails a{
        color: #fff;
    }

    .institutenewdesignppvinner  .publicmainpopdispp .ppvcategorybuttons{
        background-color: #fff;
        border-radius: 50px;
        border: 2px solid #9C9C9C;
        padding: 5px 15px;
        font-size: small;
        font-weight: 600;
    }
    .insticategory .cstlist p {
        display: inline-block;
        margin-bottom: 0;
        padding: 0 26px;
        font-family: "Fjalla One",sans-serif !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .insticategory .cstlist img {filter: invert(0);}
    .institutenewdesignppvinner .insticategory {
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        text-align: center;
        color: #000;
        font-family: Montserrat;
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 20px 0;
    }
    .institutenewdesignppv #ppvOfferSection .descriptionppvpop {
        background: #C4FFC6;
        border: none;
        text-align: center;
        box-shadow: none;
    }
   .institutenewdesignppv #ppvOfferSection img.d-block.w-100 {
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    }
    .institutenewdesignppv #ppvOfferSection .descriptionppvpop h5 {
        color: #034B13;
        text-align: center;
        font-family: Montserrat;
        font-size: 30.878px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .institutenewdesignppv #ppvOfferSection .ppvcat h3 {
        color: #00831D;
        text-align: center;
        font-family: Montserrat;
        font-size: 22.706px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
    }
    
    .institutenewdesignppv #ppvOfferSection  .ppvcat h3 span {
        color: #000;
        font-family: Montserrat;
        font-size: 22.706px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .institutenewdesignppv #ppvOfferSection .desApplynow {
        width: 142px;
        height: 45px;
        flex-shrink: 0;
        border-radius: 6.675px;
        background: #00801D;
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: inline-block;
        line-height: 45px;
        margin-top: 6px;
    }
    .institutenewdesignppv #ppvOfferSection .expdate {
        position: absolute;
        right: 10px;
        bottom: 0;
    }
    
   .institutenewdesignppv .institutenewdesignppv #ppvOfferSection .expdate h3 {
        color: #00831D;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
   .institutenewdesignppv .expdate h3 span {
        color: #000;
        font-family: Montserrat;
        font-size: 25.667px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .institutenewdesignppv section#ppvOfferSection .ppvofferinnersection h2 {
        color: #004710;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Montserrat;
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }
    .institutenewdesignppv section#ppvOfferSection .offerslider{
        background-image: url('../../../assets/Instituteppvimages/offers-bg.svg');
        padding: 30px;
        margin-top: 30px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    }
    
    .institutenewdesignppv section#ppvOfferSection {
        padding: 20px 0;
    }
    
    .institutenewdesignppv section#ppvOfferSection .ppvofferinnersection h5 {
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
    }
    .institutenewdesignppv section#ppvAdsSection {
        border-radius: 14.002px;
        background: #FFF;
        
    }

    .limitedofferinner.ppvofferinnersection{margin: 40px 0;}
    
section.service-offer {
    background: #fff;
    padding-bottom: 40px;
  }
  .service-offer .heading{position: relative; z-index: 1;}
  .service-offer .heading h2{
    color: #000;
    text-align: center;
   
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    padding-top:40px;
  }
  .service-offer .heading img {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
 .ppvexperttitle  h4{
    color: #000;
    text-align: center;
    margin-bottom: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
 }
 .ppvexperttitle h2{
    color: #004710;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
 }
 .ppvexpertsecnew{margin-bottom: 40px;}
  
  section.service-offer h3{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000;
    position: relative;
    margin: 0;
  }
  
  section.service-offer h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #fff ;
  }
  
  .serviceoffer-inner li {
    list-style: none;
    /* background: #fff; */
    box-shadow: 3.475px 3.475px 38.225px 0px rgba(0, 0, 0, 0.15);;
    text-align: center;
    width: 160px;
    height: 160px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .serviceoffer-inner {
    max-width: min-content;
    margin: 20px auto;
    position: relative;
    z-index: 1;
  }
  .serviceoffer-inner .disgrid{border-radius: 10px; background: #fff;}
  .serviceoffer-inner li h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #000;
  }
  
  .serviceoffer-inner li img {
    width: 50px;
    margin: auto;
    height: auto;
  }


section .expertformppi .expertformppiinner-git .expertformppiinnerdis-git{
 display: flex;
 gap: 10px;
}

#getname::placeholder, #gettel::placeholder, #getemail::placeholder, #gettext::placeholder{
color: #000 !important;
}
section .expertformppi .input-container-git input, section .expertformppi .input-container-git select, section .expertformppi .input-container-git textarea {
border-radius: 7px;
border: 3px solid #000;
box-shadow: 0px 0px 8px 0px #FFF;
width: 350px;
height: 50px;
background-color: transparent  ;
color: #000;
padding: 10px;


}

section .expertformppi .input-container-git textarea{
    height: 80px;
    resize: none;
}
span.formfieldicon {
    position: absolute;
    right: 10px;
    top: 9px;filter: invert(1);
}
/* span.formfieldicon img{
    filter: invert(1);
} */

.form-group-git{position: relative;}
.sliderContent.category-id1:hover, .sliderContent.category-id2:hover, .sliderContent.category-id3:hover, .sliderContent.category-id4:hover, .sliderContent.category-id5:hover{
    box-shadow: 2.1px 2.1px 23.104px 0px rgba(0, 0, 0, 0.25);
}
section .expertformppi .form-select{width: 350px;}
.form-select option{color: #000;}
.ctatwobutton-git{
    color: #000;
text-align: center;
font-family: "Roboto Flex";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.8px;
border-radius: 5px;
background: #71D076;
width: 150px;
height: 40px;
border: none;
border:1px solid #71D076;

}
.parentInstitutionCategory-id5  .active.parentInstitutionCategory-idn5 {
    color: white;
    display: inline-block;
    background-color:#00831d ;
    border-radius: 8px;
    margin: 0;
    padding: 0px 31px 0px 31px;
    border-bottom: none;
    position: relative;
  
  }
  .active.Real.Estatebg .Real.Estatep:before {
    position: absolute;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #00831d;
    content: "";
    top: 23px;
    right: -19px;
  }
  section.listofoffermainsection .offerlistpadding {
    padding: 15px 0px 20px 0px;
}
section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration {
    display: flex;
    align-items: center;
    margin: 10px auto;
    justify-content: center;
    background: #fff;
    position: relative;
    padding-top: 50%;
    overflow: hidden;
}
section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration > a {
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0 0px;
    margin-bottom: 10px;
}
section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration >a > div img {
    display: block;
    height: 300px;
    object-fit: contain;
    width: 100%;
}
.ctatwobutton-git:hover{background: transparent; color: #71D076;}
section .expertformppi .input-container-git textarea{
    width: 100%;
}
section.limitedtimeoffer{
    background-image: var(--offersbg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 40px;
}
.categorybadge-3{color:var(--Blueone); padding-right: 20px; position: relative;}

.categorybadge-1{color:var(--orange); padding-right: 20px; position: relative;}
.categorybadge-2{color:var(--purple); padding-right: 20px; position: relative;}
.categorybadge-4{color:var(--Bluetwo); padding-right: 20px; position: relative;}
.categorybadge-5{color:var(--green); padding-right: 20px; position: relative;}
.categorybadge-3:after, .categorybadge-2:after, .categorybadge-1:after, .categorybadge-4:after, .categorybadge-5:after{
    content: "";
    position: absolute;
    right: 9px;
    width: 1px;
    height: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #ccc;
}
.categorybadge-3:last-child:after, .categorybadge-2:last-child:after, .categorybadge-1:last-child:after, .categorybadge-4:last-child:after, .categorybadge-5:last-child:after
{
    display: none;
}
.adsHeading h2, .institutenewdesignppv section#ppvOfferSection h2{color: #004710;}
.limitedofferinner.ppvofferinnersection .applybutton {
    padding: 10px 21px;
    border-width: 1px;
    border-style: solid;
   border-radius: 6px;
   margin-left: 20px;
   font-size: 18px;
   font-style: normal;
   font-weight: 600;
   line-height: 21.09px;
   font-family: "Roboto Flex", sans-serif;
}
.limitedofferinner.ppvofferinnersection .sliderContent{text-align: center;}
.expertformppiinner-git .recaptcha_css{ justify-content: left; margin-top: 1rem !important; height: 100px;} 
.expertformppi.Premium{display: none;}
/* .expppvofferinnersection .smbloginnerps .bloginnertag{display: none;} */
.expppvofferinnersection .blog-upperstrip{background: transparent;}
.expppvofferinnersection .bloginnertext{margin-top: 0;}
.bgmortgage {display: none;}
.bgrealestate {display: none;}
.bginvestment {display: none;}
.bginsurance {display: none;}
.bgbanking {display: none;}
.listofoffermainsection .parentcatname, .listofoffermainsection .sliouter .productcatname{display: none !important;}

section.institutenewdesignppv.Banking section.limitedtimeoffer{
    background: url(../../../assets/Instituteppvimages/BankingwebbannerBG.svg);
    background-size: cover;
    background-position: center center;
}
section.institutenewdesignppv.Real.Estate section.limitedtimeoffer{
    background: url(../../../assets/Instituteppvimages/RealEstatewebbannerBG.svg);
    background-size: cover;
    background-position: center center;
}
section.institutenewdesignppv.Investment section.limitedtimeoffer{
    background: url(../../../assets/Instituteppvimages/InvestmentwebbannerBG.svg);
    background-size: cover;
    background-position: center center;
}
section.institutenewdesignppv.Insurance section.limitedtimeoffer{
    background: url(../../../assets/Instituteppvimages/InsurancewebbannerBG.svg);
    background-size: cover;
    background-position: center center;
}
section.institutenewdesignppv.Mortgage section.limitedtimeoffer{
    background: url(../../../assets/Instituteppvimages/MortgagewebbannerBG.svg);
    background-size: cover;
    background-position: center center;
}
section.institutenewdesignppv.Mortgage 	.institutenewdesignppvinner .publicviewprofiledetails{
    background: url(../../../assets/Instituteppvimages/MortgagewebbannerBG.svg);
    background-size: cover;
    background-position: center center;
}
section.institutenewdesignppv.Real.Estate 	.institutenewdesignppvinner .publicviewprofiledetails{
    background: url(../../../assets/Instituteppvimages/RealEstatewebbannerBG.svg);
    background-size: cover;
    background-position: center center;
}
section.institutenewdesignppv.Insurance .institutenewdesignppvinner .publicviewprofiledetails{
    background: url(../../../assets/Instituteppvimages/InsurancewebbannerBG.svg);
    background-size: cover;
    background-position: center center;
}
section.institutenewdesignppv.Investment .institutenewdesignppvinner .publicviewprofiledetails{
    background: url(../../../assets/Instituteppvimages/InvestmentwebbannerBG.svg);
    background-size: cover;
    background-position: center center;
}
section.institutenewdesignppv.Banking 	.institutenewdesignppvinner .publicviewprofiledetails{
    background: url(../../../assets/Instituteppvimages/BankingwebbannerBG.svg);
    background-size: cover;
    background-position: center center;
}





section.institutenewdesignppv.Mortgage 	.institutenewdesignppvinner ul.deone li span{
    border-radius: 4.441px;
    background: #2F596F;
}
section.institutenewdesignppv.Real.Estate .institutenewdesignppvinner ul.deone li span{
    border-radius: 4.441px;
background: #00831D;
}
section.institutenewdesignppv.Insurance .institutenewdesignppvinner ul.deone li span{
    border-radius: 4.441px;
    background: #FF4C00;
}
section.institutenewdesignppv.Investment .institutenewdesignppvinner ul.deone li span{
    border-radius: 4.441px;
    background: #86357A;
}
section.institutenewdesignppv.Banking .institutenewdesignppvinner ul.deone li span{
    border-radius: 4.441px;
    background: #2280B8;
}





section.institutenewdesignppv.Mortgage .institutenewdesignppvinner ul.deone li	{
    border-radius: 4.441px;
    background: #E9EEF9;
}
section.institutenewdesignppv.Real.Estate .institutenewdesignppvinner ul.deone li{
    border-radius: 4.441px;
background: #B9E8C0;
}
section.institutenewdesignppv.Insurance .institutenewdesignppvinner ul.deone li {
    border-radius: 4.441px;
    background: #FFE3D0;
}
section.institutenewdesignppv.Investment .institutenewdesignppvinner ul.deone li{
    border-radius: 4.441px;
    background: #FFE0FF;
}
section.institutenewdesignppv.Banking .institutenewdesignppvinner ul.deone li{
    border-radius: 4.441px;
    background: #DAF3FF;
}



section.institutenewdesignppv.Mortgage  .publicviewprofiledetails .publicpersonaldetails	{
    border-radius: 7px;
    background: #2F596F;
}
section.institutenewdesignppv.Real.Estate .publicviewprofiledetails .publicpersonaldetails {
    border-radius: 7px;
background: #00831D;
}
section.institutenewdesignppv.Insurance .publicviewprofiledetails .publicpersonaldetails {
    border-radius: 7px;
    background: #FF4C00;
}
section.institutenewdesignppv.Investment .publicviewprofiledetails .publicpersonaldetails {
    border-radius: 7px;
    background: #86357A;
}
section.institutenewdesignppv.Banking .publicviewprofiledetails .publicpersonaldetails {
    border-radius: 7px;
    background: #2280B8;
}

.bgmortgagedesktop {display: none;}
.bgrealestatedesktop {display: none;}
.bginvestmentdesktop {display: none;}
.bginsurancedesktop {display: none;}
.bgbankingdesktop {display: none;}

section.institutenewdesignppv.Mortgage	.bgmortgagedesktop {display: block;}
section.institutenewdesignppv.Real.Estate .bgrealestatedesktop {display: block;}
section.institutenewdesignppv.Investment .bginvestmentdesktop {display: block;}
section.institutenewdesignppv.Insurance .bginsurancedesktop {display: block;}
section.institutenewdesignppv.Banking .bgbankingdesktop {display: block;}



