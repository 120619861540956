@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

.newproductdesign .herosectionoffer {
    background: url(../../../assets/productimage/productbanner.svg);
    padding: 40px 0 40px;
    background-size: cover;
    background-repeat: no-repeat;
}
.newproductdesign .offerbannerslider ul.slick-dots {
    bottom: -22px;
}
.newproductdesign .offerbannerslider {
    border-radius: 10px;
border: 0.248px solid rgba(255, 255, 255, 0.40);
background: linear-gradient(114deg, rgba(0, 0, 0, 0.20) -26.83%, rgba(0, 0, 0, 0.20) 98.63%);
    position: relative;
    width: 95%;
    right: auto;
    left: auto;
    top: auto;
    bottom: auto;
    padding: 17px 25px 33px 25px;
}

.newproductdesign .herosectionoffer .row {
    align-items: center;
}

.newproductdesign .herosectionoffer .offerherobannertext h2 {
    color: #000;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
    max-width: 290px;

}

.newproductdesign .herosectionoffer .offerherobannertext h2 span {
    color: #000;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
}

.newproductdesign .herosectionoffer .offerherobannertext h5 {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    max-width: 400px;
}

.newproductdesign .limitedofferinner {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
}

.newproductdesign .sliderContent button.ctaonebutton.widthhalf {
    margin-top: 0px;
    margin-bottom: 10px;
    border-radius: 6px;
    border: 1px solid #00831D;
    background: #E6FFEA;
    color: #00831D;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    padding: 13px;
    width: 100%;
    max-width: 470px;
}

.mainofferdetails h2 {
    color: #000;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.54px;

}

.mainofferdetails h5 {
    color: #505050;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
.mainofferdetails b.subtitle_subtitle {
    font-weight: 600;
}
.descriptionppvpop .d-flex {
    justify-content: space-between;
}

.offerslider .col-lg-6.col-sm-6 {
    padding: 0 25px;
}

/*---start of checkbox--*/
.selectallinnercheckbox>ul>li {
    list-style: none;
    border-radius: 10px;
    border: 1px solid #048520;
    background: linear-gradient(180deg, #00831D 0%, #75CA75 100%);
    padding: 10px;
    width: 250px;
    margin-right: 20px;
}

.selectallinnercheckbox>ul>li.Investment {
    border: 1px solid #86357A;

    background: linear-gradient(0deg, #EF97E2 0%, #8C3B80 100%);
}

.selectallinnercheckbox>ul>li.Banking {
  
    border: 1px solid #FFF;
    background: linear-gradient(0deg, #9FD4F4 0%, #2280B8 100%);

}

.selectallinnercheckbox>ul>li.Insurance {
    
    border: 1px solid #FF601B;
    background: linear-gradient(0deg, #FF9B70 0%, #FF5108 100%);
}

.selectallinnercheckbox>ul>li.Mortgage {
    border: 1px solid #2F596F;
    background: linear-gradient(0deg, #7E9DAD 0%, #355E74 100%);
}

.selectallinnercheckbox>ul>li[disabled]{
    list-style: none;
    border-radius: 10px;
    border: 1px solid #676767;
    background: linear-gradient(0deg, #FFF 0%, #D6D6D6 100%);
    padding: 10px;
    width: 250px;
    margin-right: 10px;
}
.selectallcheckbox.false .form-checkselect input#Checkall{border: 1px solid #676767;}
.selectallcheckbox.false .form-checkselect {border: 1px solid #676767;}

.selectallinnercheckbox>ul {
    display: flex;
    padding: 0;
}

li.selectinner .dropdown-toggle:after {
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 15px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    right: 0;
    top: 7px;
    bottom: 0;
    margin: auto;
    display: block;
}

li.selectinner .dropdown-toggle {
    position: relative;
}

.selectinner .mainchecklabel {
    color: #fff;
    font-family: "Fjalla One", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

span.maincheckboxicon {
    margin-right: 10px;
}

span.maincheckboxicon img {
    width: 30px;
    height: 30px;
    object-fit: contain;

}

.selectinner span.maincheckboxicon img {
    filter: invert(100%) sepia(13%) saturate(0%) hue-rotate(21deg) brightness(114%) contrast(101%);
}

.selectallinnercheckbox ul li ul.dropdown-menu {
    border-radius: 10px;
    opacity: 0.9;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 2px 2px 18px 0px rgba(0, 0, 0, 0.25);

    width: 100%;
    top: 10px !important;
    inset: unset !important;
    margin: 0px !important;
    transform: translate(0px, 11px) !important;
}

.selectallinnercheckbox .form-check {
    display: flex;
    align-items: center;
}

.selectallinnercheckbox li.selectinner.Real.Estate .dropdown-item:hover, .selectallinnercheckbox li.selectinner.Real.Estate .dropdown-item:focus {
    background: #D7FFD7;
}

.selectallinnercheckbox li.selectinner.Investment .dropdown-item:hover, .selectallinnercheckbox li.selectinner.Investment .dropdown-item:focus {
    background: #FFE1FB;
}

.selectallinnercheckbox li.selectinner.Mortgage .dropdown-item:hover, .selectallinnercheckbox li.selectinner.Mortgage .dropdown-item:focus {
    background: #D8DEE9;
}

.selectallinnercheckbox li.selectinner.Insurance .dropdown-item:hover, .selectallinnercheckbox li.selectinner.Insurance .dropdown-item:focus {
    background: #FFE3D7;
}

.selectallinnercheckbox li.selectinner.Banking .dropdown-item:hover, .selectallinnercheckbox li.selectinner.Banking .dropdown-item:focus {
    background: #D8EDFF;
}

.selectallinnercheckbox ul.dropdown-menu label.form-check-label {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    padding-left: 10px;
    width: 126px;
}

span.subcatimage {
    width: 30.023px;
    height: 30.023px;
    background: #00831D;
    border-radius: 30px;
    text-align: center;
}

ul.dropdown-menu input[type="checkbox"]:enabled:checked {
    background-color: #00831D;
    color: white;
}

.selectallcheckbox .form-check-input:checked[type=checkbox] {
    background-color: white;
    background-image: url(../../../assets/offersimage/realestatetick.svg);
}

.selectallinnercheckbox li.selectinner.Banking .dropdown div.cstick .form-check-input:checked[type=checkbox] {
    background-color: white;
    background-image: url(../../../assets/offersimage/bankingtick.svg);
    border-color: white;
}

.selectallinnercheckbox li.selectinner.Investment .dropdown div.cstick .form-check-input:checked[type=checkbox] {
    background-color: white;
    background-image: url(../../../assets/offersimage/investmenttick.svg);
    border-color: white;
}

.selectallinnercheckbox li.selectinner.Mortgage .dropdown div.cstick .form-check-input:checked[type=checkbox] {
    background-color: white;
    background-image: url(../../../assets/offersimage/mortgagetick.svg);
    border-color: white;
}

.selectallinnercheckbox li.selectinner.Estate .dropdown div.cstick .form-check-input:checked[type=checkbox] {
    background-color: white;
    background-image: url(../../../assets/offersimage/realestatetick.svg);
    border-color: white;
}

.selectallinnercheckbox li.selectinner .form-check-input {
    width: 25px;
    height: 25px;
    margin-left: -3px;
    z-index: 9;
}

.selectallinnercheckbox li.selectinner.Insurance .dropdown div.cstick .form-check-input:checked[type=checkbox] {
    background-color: white;
    background-image: url(../../../assets/offersimage/insurancetick.svg);
    border-color: white;
}

.selectallinnercheckbox li.selectinner .dropdown-menu .form-check-input {
    width: 18px;
    height: 18px;
    margin-left: -30px;
}

.selectallinnercheckbox li.selectinner.Banking span.subcatimage, .selectallinnercheckbox li.selectinner.Banking ul.dropdown-menu input[type="checkbox"]:enabled:checked {
    background-color: #2280B8;
}

.selectallinnercheckbox li.selectinner.Banking ul.dropdown-menu .form-check-input {
    border-color: #2280B8;
}

.selectallinnercheckbox li.selectinner.Insurance span.subcatimage, .selectallinnercheckbox li.selectinner.Insurance ul.dropdown-menu input[type="checkbox"]:enabled:checked {
    background-color: #FF601B;
}

.selectallinnercheckbox li.selectinner.Insurance ul.dropdown-menu .form-check-input {
    border-color: #FF601B;
}

.selectallinnercheckbox li.selectinner.Mortgage span.subcatimage, .selectallinnercheckbox li.selectinner.Mortgage ul.dropdown-menu input[type="checkbox"]:enabled:checked {
    background-color: #2F596F;
}

.selectallinnercheckbox li.selectinner.Mortgage ul.dropdown-menu .form-check-input {
    border-color: #2F596F;
}

.selectallinnercheckbox li.selectinner.Investment span.subcatimage, .selectallinnercheckbox li.selectinner.Investment ul.dropdown-menu input[type="checkbox"]:enabled:checked {
    background-color: #86357A;
}

.selectallinnercheckbox li.selectinner.Investment ul.dropdown-menu .form-check-input {
    border-color: #86357A;
}

span.subcatimage img {
    filter: invert(1);
    margin: auto;
    padding: 5px;
    padding: 5px;
    width: 26px;
}

.selectinner .dropdown .form-check {
    display: flex;
    align-items: center;
}

.form-checkselect {
    border-radius: 6px;
    border: 2px solid #00831D;
    background: #FFF;
    width: 185px;
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 33px;
}

.form-check-input:focus {
    box-shadow: none !important;
}

.selectallcheckbox label.form-check-label.mainchecklabel {
    color: #00831D;
    font-family: "Fjalla One", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.selectallcheckbox span.maincheckboxicon {
    margin-right: 10px;
    margin-left: 10px;
}

.selectallcheckbox {
    margin-bottom: 20px;
    margin-top: 64px;
}

.vectorone {
    position: absolute;
    left: -34px;
    top: 50px;
    width: 40px;
}

.vectortwo {
    position: absolute;
    right: -25px;
    bottom: -93px;
    z-index: 9;
    width: 103px;
}

.selectallinnercheckbox>ul>li[disabled] .mainchecklabel, .selectallcheckbox.false label.form-check-label.mainchecklabel{
    color: #676767;
}
.container-rimib .selectallinnercheckbox>ul>li{margin-right: 25px;}
.selectallinnercheckbox>ul>li[disabled] span.maincheckboxicon img,  .selectallcheckbox.false span.maincheckboxicon img {
    filter: invert(44%) sepia(0%) saturate(166%) hue-rotate(174deg) brightness(90%) contrast(93%)
}

.selectallinnercheckbox>ul>li.selectinner[disabled] .dropdown:after {

    border-top: 15px solid #000;

}

/*---End of checkbox--*/


.sliouter .Real.Estate {
    border: 4px solid #FFF;
    background: linear-gradient(180deg, #F2FFF2 0.05%, #F3FFF3 20.7%, #CFFFCF 72.86%, #F3FFF3 89.21%, #F2FFF2 100%);
}

.sliouter .Investment {
    border: 4px solid #FFF;
    background: linear-gradient(180deg, #FFF2FE 0.05%, #F3FFF3 20.7%, #FFDDFB 72.86%, #FFF0FD 89.21%, #FFF2FE 100%);
}

.sliouter .Mortgage {
    border: 4px solid #FFF;
    background: linear-gradient(180deg, #EFF2F8 0.05%, #EFF2F8 20.7%, #E0E6F2 72.86%, #EFF2F8 89.21%, #EFF2F8 100%);
}

.sliouter .Insurance {
    border: 4px solid #FFF;
    background: linear-gradient(180deg, #FFFAF8 0.05%, #FFF4F0 20.7%, #FFE1D4 72.86%, #FFF4F0 89.21%, #FFFAF8 100%);
}

.sliouter .Banking {
    border: 4px solid #FFF;
    background: linear-gradient(180deg, #F6FBFF 0.05%, #ECF6FF 20.7%, #D8EEFF 72.86%, #ECF6FF 89.21%, #F6FBFF 100%);
}

.sliouter .p_slider h5 {
    color: #00831D;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sliouter .Investment .p_slider h5 {
    color: #86357A;
}

.sliouter .Mortgage .p_slider h5 {
    color: #2F596F;
}

.sliouter .Insurance .p_slider h5 {
    color: #FF4C00;
}

.sliouter .Banking .p_slider h5 {
    color: #2280B8;
}

.sliouter button.ctaonebutton.ctanewtwobutton {
    border-radius: 6px;
    background: #D2FFD2;
    width: 200px;
    height: 45px;
    color: #00831D;
    text-align: center;
    font-family: "Roboto Flex";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    border: 1.603px solid #00831D;
}

.sliouter.Investment .Investment .p_slider button.ctaonebutton.ctanewtwobutton {
    color: #86357A;
    border: 1.603px solid #86357A;
    background: #FFE0FF;
}

.sliouter.Mortgage .Mortgage .p_slider button.ctaonebutton.ctanewtwobutton {
    color: #2F596F;
    border: 1.603px solid #2F596F;
    background: #DBE1EE;
}

.sliouter.Insurance .Insurance .p_slider button.ctaonebutton.ctanewtwobutton {
    color: #FF4C00;
    border: 1.603px solid #FF4C00;
    background: #FFE3D7;
}

.sliouter.Banking .Banking .p_slider button.ctaonebutton.ctanewtwobutton {
    color: #2280B8;
    border: 1.603px solid #2280B8;
    background: #D8EDFF;
}

.sliouter .slbtn .d-flex {
    justify-content: center;
}

.sliouter .p_slider {
    background: transparent;
    border-radius: 0;
    padding: 0;
}

.sliouter .sldeimage {
    border: 7px solid #fff;
    filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.15));
}

.sliouter .p_sliderouter {
    filter: none;
    margin: 30px 0;
    padding: 14px 25px;
}

.parentcatname {
    color: #00831D;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-radius: 3.673px;
    background: #FFF;
    width: 200px;
    padding: 5px;
    margin: auto;
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.15));
    margin-top: -5px;

}

.sliouter .Investment .parentcatname {
    color: #86357A;
}

.sliouter .Mortgage .parentcatname {
    color: #2F596F;
}

.sliouter .Insurance .parentcatname {
    color: #FF4C00;
}

.sliouter .Banking .parentcatname {
    color: #2280B8;
}

.sliouter .slbtn {
    margin: 20px 0;
}

.productslider .p_slider h5 {
    margin-bottom: 0;
    margin-top: 15px;
}

.newproductdesign .mainofferdetails {
    padding-top: 40px;
}

.newproductdesign .filtersectionglobal {
    margin-top: 64px;
}

section.sortingdiv ul {
    padding: 0;
    list-style: none;
    display: flex;
    margin-bottom: 0;
    justify-content: center;
}

section.sortingdiv .container {
    display: flex;
    align-items: center;
}

section.sortingdiv {
    margin-top: 40px;
}

span.shortby {
    color: #000;
    font-family: "Fjalla One", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 100px;
}

section.sortingdiv ul li a {
    color: #979797;
    font-family: "Fjalla One", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

section.sortingdiv ul li {
    padding: 0 50px;
}

img.descending {
    filter: invert(69%) sepia(6%) saturate(12%) hue-rotate(314deg) brightness(87%) contrast(82%);
}

section.sortingdiv .container {
    position: relative;
    padding-bottom: 10px;
}

section.sortingdiv .container:before {
    content: "";
    position: absolute;
    background: #d9d9d9;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

section.sortingdiv ul li {
    position: relative;
}

section.sortingdiv ul li:before {
    content: "";
    position: absolute;
    background: #d9d9d9;
    width: 2px;
    height: 127%;
    right: 0px;
}

section.sortingdiv ul li:last-child:before {
    display: none;
}

section.sortingdiv ul li.active a {
    color: #00801D;
    position: relative;
}

section.sortingdiv ul li.active a:before {
    content: "";
    position: absolute;
    background: #00801D;
    border-radius: 8px;
    height: 5px;
    width: 100%;
    bottom: -13px;
}

.selected .form-checkselect {
    background: #00831D;

}

.offerbannerslider .slick-dots .slick-active {
    width: 20px;
    height: 20px;
}

.offerbannerslider .slick-dots li {
    width: 14px;
    height: 14px;
}

.offerbannerslider .slick-dots li {
    fill: #FFF;
    stroke-width: 0.76px;
    stroke: #EDE1E1;
    box-shadow: 0px 3.04px 3.04px 0px rgba(0, 0, 0, 0.25) inset;
    filter: drop-shadow(0px 3.04px 3.04px rgba(0, 0, 0, 0.25));
}

.offerbannerslider ul.slick-dots {
    bottom: -12px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.selectallcheckbox.selected label.form-check-label.mainchecklabel {
    color: #fff;
}

.selectallcheckbox.selected span.maincheckboxicon img {
    filter: invert(100%) sepia(13%) saturate(0%) hue-rotate(21deg) brightness(114%) contrast(101%);
}

section.sortingdiv ul li.active img.descending {
    filter: invert(24%) sepia(79%) saturate(2197%) hue-rotate(125deg) brightness(94%) contrast(102%);
}

.sliouter .productcatname {
    font-size: 13px;
    color: #000000;
    line-height: 15px;
    font-weight: 400;
    margin-block: 10px;
    text-align: center;
    font-family: 'microsoft_sans_serifregular' !important;
    margin-top:30px
}

section.sortingdiv ul li img {
    width: 22px;
}

li.selectinner.Real.Estate {
    order: 1;
}

li.selectinner.Investment {
    order: 2;
}

li.selectinner.Mortgage {
    order: 3;
}

li.selectinner.Insurance {
    order: 4;
}

li.selectinner.Banking {
    order: 5;
}

.Investment-text {
    color: #86357A;
}

.Mortgage-text {
    color: #2F596F;
}

.Insurance-text {
    color: #FF4C00;
}

.Banking-text {
    color: #2280B8;
}
.Real.Estate-text{
    color: #00831D;
}


.viewbutton{
    border-radius: 6px;
    background: #D2FFD2;
    width: 140px;

    height: 45px;
    color: #00831D;
    text-align: center;
    font-family: "Roboto Flex";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    border: 1.603px solid #00831D;
display: flex;
align-items: center;
justify-content: center;

}

.Investment-viewbutton {
    color: #86357A;
    border: 1.603px solid #86357A;
    background: #FFE0FF;
}

.Mortgage-viewbutton {
    color: #2F596F;
    border: 1.603px solid #2F596F;
    background: #dbe1ee;
}

.Insurance-viewbutton {
    color: #FF4C00;
    border: 1.603px solid  #FF4C00;
    background: #ffe3d7;
}

.Banking-viewbutton {
    color: #2280B8;
    border: 1.603px solid #2280B8;
    background: #d8edff;
}
.modalspecial.productdetail-modal .category-4 .plaindetailsleft h2{
    color: #2280B8;
}
.modalspecial.productdetail-modal .category-5 .plaindetailsleft h2{
    color: #00831D;
}
.modalspecial.productdetail-modal .category-1 .plaindetailsleft h2{
    color: #FF4C00;
}
.modalspecial.productdetail-modal .category-2 .plaindetailsleft h2{
    color: #86357A;
}
.modalspecial.productdetail-modal .category-3 .plaindetailsleft h2{
    color: #2f596f;
}


