.create-product-banner.row {
  margin-left: 30px;
  margin-right: 20px;
}


.product-padding {
  padding-left: 48px;
}



.product-category {
  font-size: 24px;
  font-weight: 700;
  margin-left: 20px;
  padding-top: 20px;
}

.mortgage {
  color: #2F596F;

}

.investment {
  color: #86357A;

}

.real-estate {
  color: #00831D;
}

.insurance {
  color: #FF4C00;
}

.banking {
  color: #2281B8;

}

.mortgage-bg {
  background: #2F596F;
}

.investment-bg {
  background: #86357A;

}

.real-estate-bg {
  background: #00831D;
}

.insurance-bg {
  background: #FF4C00;
}

.banking-bg {
  background: #2281B8;
}

.mortgage-filter {
  filter: invert(1) sepia(1) saturate(255) hue-rotate(552deg);
}

.investment-filter {
  filter: invert(135) sepia(154) saturate(123) hue-rotate(284deg);
}

.real-estate-filter {
  filter: invert(79) sepia(121) saturate(66) hue-rotate(80deg);
}

.insurance-filter {
  filter: invert(255) sepia(68) saturate(51) hue-rotate(0deg);
}

.banking-filter {
  filter: sepia(1) saturate(6) hue-rotate(190deg) brightness(0.8);
}

.product-subcategory {
  font-size: 20px;
  font-weight: 500;
  display: flex;
}

.myproduct-open {
  font-size: 23px;
  font-weight: 500;
  display: flex;
  padding: 15px 15px 0px 15px ;
}

.count-number {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-right: 2%;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-self: center;
}



p.product-subcategory {
  padding: 15px 15px 0px 15px;
}




.subcategory-headings ul {
  padding: 0;
  list-style: none;
}

.subcategory-headings>ul>li {
  background: #fcfcfc;
  border-radius: 10px;
  transition: 0.3s all ease-in-out;

}

.subcategory-headings ul li.accclose:before {
  background: url(../../../../assets/images/minus.svg);
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  
  right: 15px;
}

.subcategory-headings ul li.accplus:before {
  background: url(../../../../assets/images/plus.svg);
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 15px;
}

.subcategory-headings ul li img.plus {
  position: absolute;
  cursor: pointer;
}

.subcategory-headings ul li img.minus {
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.subcategory-headings ul li {
  transition: opacity 0.3s ease-in-out;
  position: relative;
  margin: 0px 15px;
  padding: 0px 23;
}

.subcategory-headings ul li:before {
  content: "";
  position: absolute;
  left: 0;
  background: #dff6ff;
  width: 0;
  height: 6px;
  top: 0px;
  opacity: 0;
  transition: all 0.35s ease;
  
}


.product-details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-details img { 
  height: 300px; 
  box-shadow: 0px 0px 9.44px 0px #00831D99;
  border: 7px solid white;
  margin-top: 20px;
}

.myproduct-div {
  background: #D2FFD2;
  padding: 0px 5px 5px 5px; 
  margin-top: 30px;
}


section.product-details-section {
  background: #D2FFD2; 
  border: 1px solid #F8F8F8;
  box-shadow: 3px 3px 8px 0px #00000040;
  border-radius: 10px;
  border: 1px;
  padding: 0px 30px 30px 30px;
}


.product-details-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  padding: 20px 0px 0px 0px; 
line-height: 29.26px; 

}

.product-details-category {
  color: #000;
  font-size: 18px;
  font-weight: 500; 
  line-height: 21.94px; 
}

button.detail-edit-btn {
  width: 150px;
  height: 39px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
  border: 1px solid #00831D;
  background: #00831D; 
  margin-bottom:10px;
}

.detail-edit-img { 
  padding-left: 15px;  
}

.detail-delet-img { 
  height: 35px;
  width: 35px;
  padding-left: 15px;  
}

.detail-delet-btn {
  width: 150px;
  height: 39px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #FFF;
  border: 1px solid #00831D;
  background: #00831D; 
}

.mybtn.col-3.button-section {
  margin-top: 30px; 
  position: absolute;
  right: 0px; 
}

.product-details-categorytag { 
  border-radius: 8px;
  color: #00831D;
  background: #fFF;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px 20px 15px 0; 
  border: 1px solid #00831D;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.09px;
  text-align: center; 
  padding: 10px 20px 10px 20px;
}

.details-tag {
  display: flex;
}

.detail-intreast {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #000;
}

.detail-description-heading {
  font-size: 20px;
  font-weight: 700;
  height: 25px;
  color: #000;
}

.detail-short-description {
  font-size: 18px;
  font-weight: 600;
  height: 20px;
  color: #000;
}

.detail-description {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #000;
}

.detail-divider {
  width: 3px; 
  border: 2px solid #000000;
  height: 80%;

}

.divider-div {
  display: flex;
  justify-content: center;
  align-items: center;
}


.producttitle {
  margin: 10px 0 5px auto;
  font-weight: 700;
  font-size: 30px;
  color: #000;
}

.productsubtitle {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 15px;

}
.product-subtitle{font-size: 18px;
  font-weight: 500;
  color: #000;
  line-height: 15px;
  width: 70%;}
.craete-offer-containt{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.craete-offer-containt-left
{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.productsubtitles {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 0px;
}
.paybtncraete-ads-button{
  color: #FFF;
    background: #86357A;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border-radius: 6px;
    border: white;
    height: 40px;
    width: 220px;
    margin-top: 7px;
}
 


.guide-product-img {
  height: 16px;
  width: 16px;
  margin-left: 25px;
  margin-top: 3px;
}

.bottom-margin{
  margin-bottom: 20px;
  padding: 0px 23px 0px 23px;
}

.bottom-margins{
  margin-bottom: 20px;
  padding: 0px 23px 0px 23px;
}

.close-img{
  position: absolute;
  top: 75px;
  right: 34px;
  width: 30px;
  height: 30px;
}

.right-pading{
  padding-right: 100px;
}
section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain .form-group .form-control textarea,section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain .form-group .form-control.descriptiontextarea{
  height: 75px;

  
}
section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain .col-lg-6 .input-caption-border
{height: 75px;
  background: transparent;}
section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain .input-caption label.placeholder {
  margin-top: 0px;
}
section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain  .uploadexpert .upload-image img{filter: invert(1); width: 30px; margin-right: 10px;}
section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain .uploadexpert {
  background: #D2FFD2;
  height: 212px;
  overflow: hidden;
  margin-bottom: 53px;
}
section.entiredashboard.maincustomerdash.institute_dashboard .expertformmainheaderright .myadbtn{
  background: #86357A;
}
section.entiredashboard.maincustomerdash.institute_dashboard  .expertformmainheaderleft h3{color:  #0e0b0b;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;}
  section.entiredashboard.maincustomerdash.institute_dashboard  .product-details-section .mybtn::before{display: none;}
section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain  .uploadexpert .divider-text{background: #D2FFD2;}