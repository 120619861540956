.ourexpertuser {

	box-shadow: 0 4px 8px  rgba(0, 0, 0, 0.25);
	text-align: center;
	border-radius: 18px;
	margin: 10px;
	position: relative;
}

.ourexpertuser .ourexpertinner h3 {
	font-size: 16px;
    font-weight: 700;
	color: #000000;
	line-height: 19.5px;
	margin: 10px 0;
	padding: 0 0 10px 0;
	min-height: 48px;
	text-transform: capitalize;
}

.ourexpertuser .ourexpertinner p {
	color: #000;
text-align: center;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 14.71px;
	margin: 10px 0;
	min-height: 104px;
	font-family: 'microsoft_sans_serifregular' !important;
}
/* .ourexpertuser:hover {
    box-shadow: 0px 0px 11px 0px #00801D;
    cursor: pointer;
	border-radius: 18px;
}
.ourexpertuser:hover .ourexpertinnerimg img{
	box-shadow: 0px 0px 20px 0px #00801D;
	
    cursor: pointer;
} */
.ourexpertinner img {
	position: absolute;
	top: -25%;
	left: 0;
	right: 0;
	margin: auto;
}

.ourexpertinner {
	padding: 30px 10px 0 10px;
	margin: 100px 0 0 0;
	position: relative;
}
.ourexpertinnerimg {
	position: absolute;
	top: -23px;
	right: 0;
	left: 0;
	margin: auto;
	border-radius: 200px;
	width: 80px;
	height: 80px;
}

.ourexpertinnerimg img {
	border-radius: 80px;
	width: 70px;
	height: 70px;
	border: 4px solid #BCF5BC;
}
.ourexpertinner h3 {
	position: relative;
}

.ourexpertinner h3:after {
	content: "";
	position: absolute;
	bottom: 0;
	background: rgba(144, 231, 144, 1);
	;
	width: 100px;
	height: 3px;
	left: 0;
	right: 0;
	margin: auto;
}

.ourexperticoninner {
	display: flex;
	align-items: center;
	border-radius: 0px 0px 18px 18px;
	padding: 10px;
	background-color: #90E790;
	justify-content: center;
	height: 75px;
}

.ourexperticoninner span {
	font-size: 1.25rem;
	font-weight: 700;
}

.ourexperticoninner p {
	color: #000;
	font-family: "Fjalla One", sans-serif !important;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 25.14px;
	position: relative;
}

.nopadright {
	padding-right: 0;
}

.ourexperticoninner p:first-letter {
	
}
.ourexperticoninner img {
	width: 55px;
}

.ourexperticoninner p {
	position: relative;
	padding-left: 40px;
}

.ourexperticoninner p:before {
	content: "";
	position: absolute;
	height: 54px;
	width: 54px;
	background-repeat: no-repeat !important;
	background-size: contain !important;
	background: url(../../../assets/homeimages/ourbesticon1.png);
	top: -17px;
	left: -31px;
}
.Expertsbuttoni {
    display: flex;
    flex-direction: column;
    padding: 0 0px;
}
.ExpertCategory{
    
}

.Expertsbutton {
    border-radius: 0px 0px 18px 18px;
    background-color: #BCF5BC;
    padding: 17px 0 0 0;
}
.messagemebuttoncomm {
    background-color: #00831D;
    font-family: "Roboto Flex", sans-serif !important;
    padding: 10px 40px;
    border-radius: 10px;
    color: white;
    margin: 0 auto 15px;
    text-decoration: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.9px;
    width: 200px;
    border: 1px solid transparent;

}
.messagemebuttoncomm:hover{
    background-color: #FFFFFF;
    color: #00831D;
    cursor: pointer;
    border: 1px solid #00831D;
}
@media (max-width:767px)
{
	.ourexpertuser .ourexpertinner p{min-height:72px ; font-size: 12px;}
	.ourexperticoninner p:before{width: 30px; height: 30px;top: -2px;
		left: -22px;}
	.ourexperticoninner p{font-size: 14px; padding-left: 20px;}
	.ourexperticoninner{height:40px ;}
	.ourexpertinner{    margin: 68px 0 0 0;}
	.ourexpertuser .ourexpertinner h3 {
		font-size: 14px;
		font-weight: 700;
		color: #000000;
		line-height: 19.5px;
		margin: 10px 0;
		padding: 0 0 10px 0;
		min-height: auto;
	}
	.messagemebuttoncomm{
		width: 90px;
		height: 25px;
		padding: 0;
    border-radius: 2px;
    color: white;
    margin: 0 auto 15px;
    text-decoration: none;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	display: flex;
	justify-content: center;
	align-items: center;
}
	
}
