
  .item {
    width: 100px;
    height: 100px;
    position: absolute;
    }
    
    .item-1 {
    background-color: #fcba2c;
    top: 0;
    left: 0;
    z-index: 1;
    animation: item-1_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
    }
    
    .item-2 {
    background-color: #143b58;
    top: 0;
    right: 0;
    animation: item-2_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
    }
    
    .item-3 {
    background-color: #fcba2c;
    bottom: 0;
    right: 0;
    z-index: 1;
    animation: item-3_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
    }
    
    .item-4 {
    background-color: #143b58;
    bottom: 0;
    left: 0;
    animation: item-4_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
    }
    
    @keyframes item-1_move {
    0%,
    100% {
    transform: translate(0, 0);
    }
    25% {
    transform: translate(0, 100px);
    }
    50% {
    transform: translate(100px, 100px);
    }
    75% {
    transform: translate(100px, 0);
    }
    }
    
    @keyframes item-2_move {
    0%,
    100% {
    transform: translate(0, 0);
    }
    25% {
    transform: translate(-100px, 0);
    }
    50% {
    transform: translate(-100px, 100px);
    }
    75% {
    transform: translate(0, 100px);
    }
    }
    
    @keyframes item-3_move {
    0%,
    100% {
    transform: translate(0, 0);
    }
    25% {
    transform: translate(0, -100px);
    }
    50% {
    transform: translate(-100px, -100px);
    }
    75% {
    transform: translate(-100px, 0);
    }
    }
    
    @keyframes item-4_move {
    0%,
    100% {
    transform: translate(0, 0);
    }
    25% {
    transform: translate(100px, 0);
    }
    50% {
    transform: translate(100px, -100px);
    }
    75% {
    transform: translate(0, -100px);
    }
    }
    .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #222;
    z-index: 999;
    opacity: 0.9;
    }
    
    .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    }
    
    .overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    }
    @keyframes bouncing-loader {
        to {
          opacity: 0.1;
          transform: translate3d(0, -16px, 0);
        }
      }
      
      .bouncing-loader {
        display: flex;
        /* justify-content: center; */
        /* margin-left: 40vw; */
        /* top: 0; */
        /* margin-top: 40vh; */
      }
      
      .bouncing-loader > div {
        width: 16px;
        height: 16px;
        margin: 3rem 0.2rem;
        background:green;
        border-radius: 50%;
        animation: bouncing-loader 0.6s infinite alternate;
      }
      
      .bouncing-loader > div:nth-child(2) {
        animation-delay: 0.2s;
      }
      
      .bouncing-loader > div:nth-child(3) {
        animation-delay: 0.4s;
      }