@media (max-width: 1024px) {
    .container-rimib {
        max-width: 100% !important;
        margin: 0 auto;
        width: 100%;
    }
    footer {
        overflow-x: hidden;
        z-index: 9;
        position: relative;
    }
    .rimib-block-letter {
        font-size: 168px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        line-height: 168px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
   
    a.findbtn, a.findbtn-too, a.findbtn-three, a.findbtn-four, a.findbtn-five {
        color: #FFF;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: auto;
        
        max-width: 300px;
        height: auto;
        padding: 5px 10px;
        display: inline-block;
        margin-top: 12px;
        min-height: 25px;
        border-radius: 2px;
    }
    a.realbtn, a.realbtn-too, a.realbtn-three, a.realbtn-four, a.realbtn-five  {font-size: 20px;}
    a.realbtn.realbtn-three img, a.realbtn.realbtnfour img{width: 30px;}

}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .hover-content h3, .hover-content-too h3,.hover-content-three h3, .hover-content-five h3, .hover-content-four h3{
        font-size: 15px;
        margin-top: 10px;
    }
    .rimib-block-inner-three, .hover-content-inner-four, .hover-content-inner-five{margin-bottom: 10px;}
    .hover-content p, .hover-content-four p, .hover-content-five p, .hover-content-too p, .hover-content-three p {
        color: #000000;
        font-size: 10px;
        font-weight: 400;
        text-align: center;
        max-width: 100%;
        margin: 0 auto;
        color: #000;
        text-align: center;
        line-height: 12px;
    }
    a.realbtnfour{font-size: 15px;}
    a.realbtnfour img, a.realbtn-three img {
        width: 25px;
    }
    a.realbtn-five img {
        width: 25px;
    }
    .rimib-block-letter-image{margin-top: 35px;}
    .rimib-block-letter-text{margin-top: 15px;}
    .topproduct .d-block.d-sm-none.smmore{color:#fff}
    a.realbtn-too img, a.realbtn img {
        width: 19px;
    }
    .ourbest .col-lg-2 {
        width: 100%;
        padding: 0;
    }
    a.realbtn, a.realbtn-too, a.realbtn-three, a.realbtn-four, a.realbtn-five{width: 140px;}
    .d-block.d-sm-none.smmore {
        display: block !important;
    }
    .whatareyou .whatareyoucat {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 92px;
    overflow: hidden;
    }
    ul.navbar-nav.navbar-navright {
        justify-content: right;
        align-items: center;
        flex-flow: row;
    }
    li.headersignup a{font-size: 12px !important;}
    .what-res.active:after, .what-res:hover:after{display: none;}
    .whatareyoucat.showEnable {
        height: auto;
    }
    .whatarecatbox {margin-bottom: 10px;}
    .what-res p {
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 5px;
        margin-bottom: 0;
        font-size: 12px;
        padding: 0 1px;
        line-height: 14.07px;
        text-align: center;
    }
    section.herosection.p-0.overflow-hidden.herosectionhid.d-none.d-sm-block {
        height: 350px;
        overflow: hidden !important;
    }
    section.search.mt-5 {
        background-color: rgb(232, 253, 172);
        padding-top: 20px;
    }
    .main-search, .searchtop-text, .buttonsearchmaim {
        margin-left: 0;
    }
    .item1 {
        grid-template-columns: 1fr 1fr;    }
        section.searchtop{padding-top: 20px;}
        .Ourintitutions{max-height: 100%;}
        .Ourintitutions .row.flexflow {
            flex-direction: column-reverse;
        }
        .ourbestimgslider {
            max-width: 370px;
            padding-left: 0;
            margin-top: 35px;
            margin-left: auto;
            margin-right: auto;
        }
}
@media (max-width: 767px) {
   
    .homecustom .ourbest .container {
        padding: 0px 20px;
    }
    .category-id1 .expertButtonContainer{background: #FFE3D0;}
    .category-id2 .expertButtonContainer{background: #FFE0FF;}
    .category-id3 .expertButtonContainer{background: #E9EEF9;}
    .category-id4 .expertButtonContainer{background: #DAF3FF;}
    .category-id5 .expertButtonContainer{background: #D2FFD2;}
    .Ourintitutions h2, .Ourintitutions p{color: #000;}
    .whatarecatbox.what-res:first-child img {
        width: 28px;
    }
    section.homecustom.homecustomsearchfull .bloginnertext{font-size: 12px; line-height: 1; min-height: auto; color: #000;     font-family: "microsoft_sans_serifregular" !important;}
    section.homecustom.homecustomsearchfull .bloginnertextfe{font-size: 12px; line-height: 1; min-height: auto; color: #000;     font-family: "microsoft_sans_serifregular" !important; margin-bottom: 10px;}
    section.homecustom.homecustomsearchfull  .blog-image {
        width: 70px;
    }

    .exclusive
    {
        background: url(../../../assets/homeimages/HomepageOfferBGmobile.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.35));
    }
    .topproduct
    {
        background: url(../../../assets/homeimages/HomepageProductsBGmobile.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.35));
    }
    .category-id2 .expertButtonContainer p:before {
        filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
    }
    
    .category-id3 .expertButtonContainer p:before {
        filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
    }
    
    .category-id1 .expertButtonContainer p:before {
        filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
    }
    
    .category-id4 .expertButtonContainer p:before {
        filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
    }
    
    .category-id5 .expertButtonContainer p:before {
        filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.25));
    }
    .Ourintitutions{ background: url(../../../assets/homeimages/Ourbestinstitutionbackgroundmobile.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;}
    .search .container {
        padding: 0 25px;
    }
    .hover-content-five h3, .hover-content h3, .hover-content-too h3, .hover-content-three h3, .hover-content-four h3{font-size: 18px;}
    .applybutton:hover{border-radius: 2px;        line-height: 1;
        margin: 10px auto !important;
    }
    .exclusive .slick-next:before, .exclusive .slick-prev:before, .ourbestimgslider .slick-next:before, .ourbestimgslider .slick-prev:before, section.herosection.p-0.overflow-hidden.herosectionhid.d-block.d-sm-none .slick-next:before, section.herosection.p-0.overflow-hidden.herosectionhid.d-block.d-sm-none .slick-prev:before {
        box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, .15);
        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .3));
        border-radius: 30px;
    }
    .homecustom .herosection .slick-slide {
        opacity: 1;
    }
.offerlistpadding{
    width:100%;
}
    .bloglist {border-radius: 4px;}
    .exclusive .slick-next:before, .topproduct .slick-prev:before,   .exclusive .slick-prev:before, .topproduct .slick-next:before, .ourbestimgslider .slick-prev:before, .ourbestimgslider .slick-next:before{width: 30px; height: 30px;}
    .exclusive .slick-next {
        right: 41%;
        left: auto;
    }

    .exclusive .slick-prev {
        left: 41%;
        right: auto;
    }.homecustom section.topproduct .slick-prev {
        left: 40% ;
        right: auto;
    }
      .homecustom section.topproduct .slick-next{right: 40%;
        left: auto;}
    .topproduct .slick-prev, .topproduct .slick-next, .exclusive .slick-prev, .exclusive .slick-next,.ourbestimgslider .slick-prev, .ourbestimgslider .slick-next{width: 30px; height: 30px; opacity: 1;}
    .homecustom .latestbutton.margintop, .homecustom .topproduct .container > .text-center{margin-top: 30px;}
    .exclusive h6{font-size: 12px;  font-family: "SF Pro Display" !important;}
    .topproduct .slick-prev, .topproduct .slick-next, .exclusive .slick-prev, .exclusive .slick-next{
        top:auto;
        bottom: -41px;
    }
   .homecustom .offersss .col-lg-12.col-md-12.col-sm-12.col-xs-12{padding:0}
   .homecustom .topproduct .slick-prev:before, .homecustom .topproduct .slick-next:before{bottom:0}
    .topproduct .slick-next:before, .topproduct .slick-prev:before{opacity: 1;}
    .homecustom .offersss .slick-slide img{height: auto;}
    .ourbestrighttext h2:before {
        content: "";
        position: absolute;
        bottom: -11px;
        left: 0;
        width: 73%;
        background: #036518;
        height: 3px;
        right: 0;
        margin: auto;
    }
    .exclusive{padding: 10px 0;}
    .topproductcat .topproductcatbox img{height: 18px;}
    .question .textquestion {
        padding-left: 0;
    }.question p{
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
    text-align: center;
    margin-right: 0;}
    .exclusive .exclusive-text h2{font-size: 18px;}
    .askbutton p {
        font-size: 18px;
        font-weight: 700;        margin-right: 7px;
    }
    .askbutton {
        max-width: 200px;
        padding: 6px 5px 7px 5px;
        margin: 0 auto;
        height: 40px;
        margin-top: 10px;
    }
    
    .item1 .itemtts:first-child a .item-img {
        margin-bottom: 0;
    }
    .realbtn.realbtnfour{background-color: #FF4D00;}
    .askbutton img {
        width: 25px;
    }
    .showallourbesttoo:hover, a.topproductshowallbtn:hover, a.showallbtn:hover{font-size: 10px;
      }
      section.herosection.p-0.overflow-hidden.herosectionhid.d-block.d-sm-none .slick-prev{left:0; font-size: 0 !important;}
      section.herosection.p-0.overflow-hidden.herosectionhid.d-block.d-sm-none .slick-next{right:0 ;font-size: 0 !important;}
      section.herosection.p-0.overflow-hidden.herosectionhid.d-block.d-sm-none .slick-prev:before{
        background: url(../../../assets/homeimages/slicknext.svg);
        width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	transform: rotate(180deg);
	display: block;
	position: absolute;
	right: 0;
	left: 13px !important;
	top:0;
    font-size: 0;
    } 
    section.herosection.p-0.overflow-hidden.herosectionhid.d-block.d-sm-none .slick-next:before{
        background: url(../../../assets/homeimages/slicknext.svg);
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: contain; 
        font-size: 0;
        right: 13px;
        position: absolute;
        top: 0;
    }
    .itemtts p {
        font-size: 10px;
        line-height: 1;
    }

    .whatarecatbox:nth-child(4n):before,  .topproductcatbox:nth-child(4n):before {
        display: none;
    }
    section.whatareyou.mt-5, .whatareyoucat.mt-5 {
        margin-top: 30px !important;
    }
    section.exclusive.mt-5.pb-3.pt-4 {
        margin-top: 5px !important;
    }
    .item1 {
        display: block;
    }
    a.showallbtn, a.topproductshowallbtn {
        width: 90px;
        height: 25px;
        padding: 4px;
        border-radius: 2px;
        font-family: "Roboto Flex";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.2px;
    }
    .topproductcat.mb-3 {
        justify-content: space-between;
        margin-top: 10px !important;
    }
    .ourbest {
        margin: 20px 0;
    }
   .topproduct .whatareyou .container{
        padding: 0;
    }
    .sldeimage.product_image {
        margin-top: 0px;
    }
    .topproduct .sldeimage.product_image{padding: 0;}
    .topproduct .smmore {
        color: #000;
        font-weight: 700;
    }
    .p_slider{padding: 0;}
    .exclusive .exclusive-text p{margin-bottom: 10px;}
    .p_slider.product_inner .sldeimage.product_image img {
        border-right: 3px solid #fff;
        border-bottom: 6px solid #fff;
        border-left: 3px solid #fff;
        border-top: 6px solid #fff;
    
    }
    .what-res.active:after, .what-res:hover:after{display: none !important;}
   
    .item1 .itemtts:first-child a .item-text h5{margin-bottom: 0rem;}
    .item1 .itemtts:first-child {
        grid-row-start: auto;
        grid-row-end: auto;
        background-color: #daf0ff;
        min-height: auto;
        display: block;
    }
    .itemtts p{margin-bottom: 0;}
    
    .col-lg-6.padleft30 {
        padding-left: 15px;
    }

    .ourbestimg {
        display: inline-block;
        width: 100%;
        margin: auto;
        text-align: center;
    }
    .ourbest .showallourbesttwo.text-center.mb-3.mb-5 {
        margin-top: 0 !important;
    }
    .homecustom .ourbest .showallourbesttwo.text-center.mb-3.mb-5 {
        margin-top: 15px !important;
    }
    .ourbestimgbox{margin-bottom: 0;}
    .ourbestimgbox {
        max-width: 80px;
        height: 80px;
        margin: 0px 6px 12px;
    background-color: white;
    padding: 7px;
    border-radius: 7px;
    }
    .ourbestimgslider {
        max-width: 230px;
        padding-left: 0;
        margin: auto;
        margin-top: 20px;
    }
    .ourbestimgslider .slick-dots .slick-active {
        width: 10px;
        height: 10px;
    }
    .ourbestimgslider .slick-dots li {
        width: 8px;
        height: 8px;
        margin: 0 3px;
    }
    .itemtts .item-img {
        width: 85px;
        border-radius: 100px;
        height: 85px;
        display: flex;
        align-items: center;
    }
    .item1 .itemtts:first-child a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        
        flex-wrap: nowrap;
        padding: 15px 5px;
    }
    .ourexpertinnerimg{    top: -15px;
    }
    .question{box-shadow: 0px 2.09px 5.22px 0px rgba(0, 0, 0, 0.25);
    }
    .itemtts{margin-bottom: 10px;}
    .itemtts a{    padding: 15px;}
    .question h2 {
        margin-bottom: 0;
        font-size: 18px;
        text-align: center;
        line-height: 1;
    }
    .hover-con-pos p{color: #000;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-family: "SF Pro Display" !important;
    }
    section.whatareyou {
        margin-bottom: 15px;
    }
    .hover-content-five p, .hover-content-three p, .hover-content-four p, .hover-content p, .hover-con-pos p{max-width: 320px;}
    .herosection.herosectionhid .slick-prev, .herosection.herosectionhid .slick-next{width:30px; height: 30px;}
   
    .searchbackcs.d-block.d-sm-none img {
        width: 63%;
    }
    .main-search {
        margin-top: 20px;
        margin-bottom: 0;
        padding-bottom: 0;
        position: relative;
        z-index: 99;
    }
   
    
    .searchbackcs.d-block.d-sm-none {
        position: absolute;
        bottom: -13px;
    }
    a.showallourbesttoo, .showallourbesttoo {
        padding: 0px;
        height: 25px;
        font-weight: 500;
        line-height: 2;
        letter-spacing: 1.2px;
        text-align: center;
        width: 90px !important;
        font-size: 12px;
        border-radius: 2px;
        border-width: 1px;
    }
    .homecustom .offersss {
        margin-bottom: 0px;
    }
  .homecustom .topproduct .whatareyou  h2{display: none;}
    h3.slidertitle{
        margin-top: 15px;
    }

    button.showallourbesttoo.mt-0.mb-4 {
        margin-bottom: 0 !important;
    }
    .Ourintitutions .showallourbesttwo.text-center.mt-3.mb-5 {
        margin-bottom: 0 !important;
    }
    section.searchtop.mb-5 {
        margin-top: 20px;
    }
    .quotes-on-btn a {
        font-size: 16px !important;
    }
    
    .quotes-on-btn a img {
        width: 31px;
    }
    .showallourbesttwo.text-center.mt-5.mb-3.mb-5 {
        margin: 0 !important;
        margin-bottom: 20px !important;
    }
    section.search {
        position: relative;
        padding-bottom: 64px;
        background-color: #C9F7D4;

        margin-top: 0 !important;
    }
    .ourexperticoninner{justify-content: center;}
    .ourexpertuser{margin: 0px 15px !important;}
    .textquestion {
        margin: 0 auto;
    }
    .showallourbesttwo.text-center.mb-3.mb-5 {
        margin-top: 20px;
        margin-bottom: 0 !important;
    }
    section.ourbest.mt-5.mb-5 {
        margin-bottom: 30px !important;
    }
    .item1 .itemtts:first-child a .item-text{width: 70%;}
    .itemtts h5 {
        font-size: 12px;
        line-height: 1.2;
        margin-bottom: 10px;
    }
    .item1 .itemtts{border-radius: 5px;}
    .ourexpertuser .ourexpertinner p{min-height: auto;}
    .ourbest .col-lg-2 {
        width: 100%;
    }
    .ourbest .col-2, .heroinner .col-2 {
        flex: 0 0 auto;
        width: 100%;
    }
    .realbtn{width: 250px !important; padding: 4px !important;}
    .hover-con-pos {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        /* height: 461px; */
        overflow: hidden;
    }
    .question{padding: 20px 0;}
  
  
   
    a.realbtn {
        display: inline-block !important;
        margin-top: 15px;
        font-size: 25px;
        line-height: 44px;
        width: 160px !important;
        height: 42px;
        padding: 0 !important;
        position: relative;
        padding-left: 38px !important
    }
    a.realbtn.realbtn-too{padding-left: 30px !important;}
    .homecustom .p_sliderouter.product_main.exclusivepadding {
        padding: 0 0px;
        margin-bottom: 10px;
    }
    .topproduct .p_slider, .homecustom .topprbtn{margin-top: 0;}.topproduct .p_slider {
        margin-bottom: 0;
    }
    .homecustom .homepageofferblock.carddecoration{margin-top: 0;}
    a.realbtn img{           
        width: 21px;
        line-height: 24px;
        position: absolute;
        left: 14px;
        top: 4px;}
        a.realbtn.realbtn-too img {
            width: 18px;
            top: 1px;
        }
        a.realbtn.realbtn-three img{        width: 36px;
            top: 6px;
            left: 10px;}
        .col-md-6.d-flex.align-items-center{justify-content: center;}
        a.realbtn.realbtn-five img{
            width: 28px;
        line-height: 24px;
        position: absolute;
        left: 14px;
        top: 3px;
        }
    .hover-content-inner, .hover-content-inner-too, .hover-content-inner-three, .hover-content-inner-four, .hover-content-inner-five  {

        padding-bottom: 14px;
        margin-bottom: 0px;
    }
  
    a.realbtn img {
        display: inline-block;
    }
  
    .whatareyou h2, .whatareyou span {
        font-size: 18px;
    }
    .whatareyou h2{padding-top: 10px;}
    .showallbtn{
        padding: 7px 16px;
        font-size: 10px;
    }
 
    .exclusive .exclusive-text p {
        font-size: 12px;
        line-height: 1;
    }
    .p_sliderouter.main_offers.exclusivepadding {
        padding-bottom: 0px;
    }
    
   
    .topproductcat {
        flex-wrap: wrap;
    }
    .topproductshowallbtn{
        padding: 7px 16px;
        font-size: 10px;
    }
    
    .topproduct .topproduct-text p {
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
    }
    .p_sliderouter.product_main.exclusivepadding {
        padding-bottom: 0px;
    }
 
    .ourbest .ourbexttext h2 {
        font-size: 18px;
    }
    .ourbest .ourbexttext p,.articles .articles-text p{font-size: 12px; line-height: 1; font-family: "SF Pro Display" !important; color: #000;max-width: 320px; margin-left: auto;margin-right: auto;
    }
    .homecustom .blogimage img{border-radius: 100px;}
    .topproduct{padding-bottom: 0;}
    .ourbexttext {
        margin-bottom: 0;
    }
    p.firstlatter{font-size: 14px; font-weight: 500;}
    .ourexperticoninner p{margin: 0 !important;}
    a.viewallbtn {
        padding: 7px 16px;
        font-size: 10px;
    }
    .ourexperticoninner{height: 32px;}
    .ourexpertuser .ourexpertinner p{font-size: 10px;}
    .ourexpertinnerimg img {
        border-radius: 80px;
        width: 58px;
        height: 58px;
    }
    .main-search {
        margin-left: 0px;
        padding: 0px;
    }
    .main-search h2 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .main-search p {
        color: #000;
        font-size: 12px;
        font-weight: 500;
        margin: 0;
        font-family: "SF Pro Display" !important;
        max-width: 100%;
    }
    .search .col-lg-6.col-9 {
        width: 100%;
    }
    .buttonsearch.mt-4 {
        margin-top: 20px !important;
        margin-bottom: 0 !important;
    }
    a.searchnow {
        width: 90px;
        height: 25px;
        background-color: white;
        border: 1px solid #EF000B;
        color: #EF000B;
        text-align: center;
        font-family: "Roboto Flex", sans-serif;
        font-size: 12px;
       
        line-height: 2;
        letter-spacing: 1.2px;
        border-radius: 2px;
        margin-bottom: 20px !important;
        font-weight: 500;
    }
    section.searchtop a.searchnow{
        margin-bottom: 30px;
    }
    .buttonsearch {
        margin-bottom: 10px;
    }
    .quotes{
        margin: 0px 0;
    }
    .quotes .quotes-inner{
        margin: 50px 0;
        padding: 15px;
    }
    .quotes .quotes-main h2 {
        font-size: 18px;
    }
    .quotes-text h5 {
        font-size: 12px;
        text-align: center;
        max-width: 160px;
        margin: 0 auto;
        color: #004F12;
        margin-bottom: 10px;
    }
    .quotes-text:before {
        display: none;
    }
    .quotes-text::after {
        display: none;
    }
    .quotes-text p {
        font-size: 12.19px;
        text-align: center;
    }
    p.weoffer {
        font-size: 10px;
        text-align: center;
    }
    a.quotesinsurancebtn {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
    a.quotesmortgagebtn {
        margin-left: 10px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .quotes {
        margin-bottom: 0;
    }
    a.showmebtn {
        font-size: 10px;
        display: inline-block;
        padding: 7px 90px;
    }
    .inputextsize input {
        font-size: 10px;
    }
    .articles {
        margin-top: 20px;
        margin-bottom: 0;
    }
    .articles .articles-text p {
        font-size: 12px;
        line-height: 1;
    }
    .articles .articles-text h2 {
        font-size: 18px;
    }
   
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
   
    .itemtts p {
        font-size: 10px;
    }
    .itemtts .item-img img {
        border-radius: 100px;
        width: 80px;
        height: 80px;
        object-fit: cover;
    }
    .itemtts img {
        margin: 0;
    }
    .item1 img:nth-child(1) {
        margin: 0px 0 0px 0;
    }
    a.viewalltwo {
        font-size: 10px;
        padding: 7px 16px;
    }
    .Ourintitutions {
        margin-bottom: 0;
        margin-top: 20px;
        padding: 0;
    }
   
    .Ourintitutions .flexflow {
        flex-flow: column-reverse;
        padding: 10px;
        height: 100%;
    }

    .ourbestrighttext h2 {
        color: #FFF;
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
    }
    .ourbestrighttext {
        margin-left: 0;
    }
    .ourbestrighttext img {
        width: 50%;
        display: flex;
        margin: 10px auto;
    }
    a.showallourbest {
        font-size: 10px;
        padding: 7px 16px;
    }
    .buttonsearchnowtoo {
        margin: 0;
    }
    .buttonsearchmaim {
        margin-left: 0;
    }
    .searchtop-text {
        margin-left: 0px;
    }
    .searchtop-text p {
        color: #000;
text-align: center;
max-width: 100%;
font-size: 12px;
font-style: normal;
font-weight: 500;
padding: 0 20px;
line-height: normal;
font-family: "SF Pro Display" !important;
    }
    .searchtop-text h2 {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 20px;
    }
    a.searchnowtoo {
        font-size: 10px;
        padding: 7px 16px;
    }
    .searchtop{margin: 20px 0;}
    .whatareyou .whatareyoucat, .topproductcat  {
        display: flex;
        justify-content: center !important;
        flex-wrap: wrap;
        height: 75px;
        overflow: hidden;
    }
    .whatareyou .whatareyoucat{padding-top: 10px;}
    .exclusive h2{font-size: 18px;}
    .ourbestimgslider .slick-prev {
        left: -10px;
    }
    .slick-next {
        right: -10px;
    }
    .blogtext{padding-right: 0 !important;}
    .whatareyou .whatareyoucat.showEnable {
        height: auto;
    }
    section.search{
        margin-top: 10px !important;
    }
    .topproductcat.showEnable {
        height: auto;
    }
    .Ourintitutions .mt-5.text-center{margin-top: 10px !important;}
    .what-res{
        width: 25%;
        height: 67px;
        margin-bottom: 13px;
    }
    .what-res img,  .topproduct .what-res img {
        width: 18px;
        margin-bottom: 0;
    }
    .what-res p{
        font-weight:500;
        text-transform: capitalize;
        margin-top: 2px;
        margin-bottom: 0;
        font-size: 12px;
        padding: 0 2px;
        line-height: 1;
        text-align: center;
    }
    .topproduct .whatareyou .whatareyoucat .whatarecatbox.active p {
        color: #fff;
    }
    
    .topproduct .topproduct-text h2{font-size: 18px;}
    .topproduct{margin: 20px 0;
        padding-top: 10px;}
    
    .sldeimage.product_image {
        width: auto;
    }
    .exclusive-slider{width: 100%;}
    .ourexpertinner {
        padding: 20px 10px 0 10px;
        margin: 36px 0 0 0;
        position: relative;
    }
    .searchbackcs.d-block {
        text-align: right;
    }
    .searchtop .row {
        padding: 0 ;
    }
    section.search {
        height: 340px;
        margin-top: 10px;
        overflow: visible;
    }
    .searchtop .row.paddingzero{
        background: #E5F3FF !important;
    }
    .searchtop{text-align: center;}
    .searchtop-images {
        width: 100%;
    }
    .buttonsearchmaim {
        display: flex;
        justify-content: center;
    }
    section.herosection.p-0.overflow-hidden.herosectionhid.d-block.d-sm-none .slick-prev:before, .exclusive .slick-prev:before{
        left: 0;
    }
    .ourbestimgslider .slick-prev:before, 
    .ourbestimgslider .slick-prev:before{
        left:-45px;
    }
    .ourbestimgslider .slick-next:before{
        right:-45px;
        position: absolute;
        left: auto;
    }
    section.herosection.p-0.overflow-hidden.herosectionhid.d-block.d-sm-none .slick-next:before{
        /* background: url(../../../assets/images/right_arrow_mobile.svg); */
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.30));
border-radius: 30px;
    }
    .ourbestimgslider .slick-next:before, .exclusive .slick-next:before{
        /* background: url(../../../assets/images/right_arrow_mobile.svg); */
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.30));
border-radius: 30px;
opacity: 1;
    }
    .smmore{font-size: 12px; text-decoration: underline; font-weight: 700;}
}
@media (max-width:490px){
    .searchbackcs.d-block.d-sm-none img {
        width: 68%;
    }
   
}
@media (max-width:450px){
    .searchbackcs.d-block.d-sm-none img {
        width: 78%;
    }
   
}
@media (max-width:420px){
    .searchbackcs.d-block.d-sm-none img {
        width: 80%;
    }
   
}
@media (max-width:375px){
    .searchbackcs.d-block.d-sm-none img {
        width: 90%;
    }
   
}
@media (max-width:350px){
    .searchbackcs.d-block.d-sm-none img {
        width: 100%;
    }
   
}
@media screen and (max-width: 1366px) and (max-height: 768px) {
                html {
                    zoom: 0.9 !important;
                  }
              }
