@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .newofferdesign .herosectionoffer{height: auto;}
    .newofferdesign .homepageofferblock.carddecoration > a > div img, .container-rimib .homepageofferblock.carddecoration >a > div img {
        display: block;
        height: 100%;
        object-fit: contain;
        width: 100%;
    }
}
@media (max-width:767px){
    .herosectionoffer .container{padding: 0;}
    .newofferdesign .herosectionoffer .col-lg-7.col-sm-7, .newofferdesign .herosectionoffer .col-lg-5.col-sm-5
    {
        padding: 0;
    }
    .newofferdesign .applybutton:hover{margin: 10px 0 !important;}
    .newofferdesign .pagination-section{
        margin-top: 0;
    }
    .pscatbottom:after{bottom: -3px;}
    .offerbannerslider .slick-prev, .offerbannerslider .slick-next {
        width: 14px;
        height: 8px;
        z-index: 999;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .offerbannerslider .slick-prev{}
    .offerbannerslider .slick-next
   .newofferdesign .herosectionoffer .col-lg-7.col-sm-7, .newofferdesign .herosectionoffer .col-lg-5.col-sm-5 {
        padding: 0;
    }
    .offerbannerslider .slick-prev {
        left: 10px;
        right: auto;
    }
    .offerbannerslider .slick-next {
        right: 10px;
        left: auto;
    }
    .offersearch{height: auto; padding: 10px 0;}
    .newofferdesign .herosectionoffer .offerherobannertext h2 span{font-size: 24px; line-height: 1;}
    .newofferdesign .herosectionoffer .offerherobannertext h2{font-size: 30px; line-height: 1; padding-left: 10px;}
    .newofferdesign .herosectionoffer .offerherobannertext h5{
        font-size: 16px;
        font-weight: 500; 
        line-height: 1;
        margin-bottom: 25px;padding-left: 10px;
    }
        .newofferdesign .offerbannerslider {
            width: 100%;
            padding: 20px 0;
            margin: auto;
            border-radius: 2px;
        }
        .newofferdesign .herosectionoffer{height: auto; padding: 20px 0; 
            background: url(.././../../assets/offersimage/offer_background_mobile.svg);}
        .offerbannerslider .slick-prev:before {
            background: url(../../../assets/productimage/leftarrowmobile.svg);
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        filter: invert(0);transform: rotate(0deg);
        left: 0;
           
        }
        .offerbannerslider .slick-next:before {
            background: url(../../../assets/productimage/leftarrowmobile.svg);
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        filter: invert(0);transform: rotate(180deg);
        right: 0;
        left: auto;
            
        }
        .newofferdesign .vector-img {
            position: absolute;
            left: 0;
            top: -16px;
            width: 55px;
        }
        .offersearch h2{
            font-size: 12px;
line-height: 1;
font-weight: 700;
margin-bottom: 0;
        }
        .newofferdesign .limitedoffer{margin: 20px 0 0 0;}
        .offersearch h4{
            font-size: 12px;
        font-family: "SF Pro Display" !important;
        line-height: 1;
        font-weight: 500;
        margin-top: 5px;
        max-width: 220px;
        margin-left: auto;
        margin-right: auto;
        }
        .pscatbottom:nth-child(2):after {
            width: 145px !important;
        }
        .customsearchblock .headersearch{
            width: 80px !important;
            font-size: 12px !important;
font-style: normal;
font-weight: 600;
padding: 3px !important;

        }
        .open-active.filter-wrap:after {
            background: rgba(0, 0, 0, 0.3);
            content: "";
            position: absolute;
            height: 1500px;
            width: 1021px;
            left: -374px;
            right: 0;
            top: 33px;
            bottom: 0;
            z-index: 99;
        }
        .mobile-filter .container{
            padding: 0;
        }
        .customsearchblock input   {
            padding-left: 44px !important;
            padding-right: 94px !important;
            padding: 10px !important;
            padding-left: 50px !important;
            font-size: 11px !important;
        }
        .newofferdesign .offerslider.martop40.mb-5 {
            margin-bottom: 0 !important; margin-top: 10px !important;
        }
        .newofferdesign .mainofferdetails h2{font-size: 18px; line-height: 1;}
        .new-offer-design .mainofferdetails h5, .new-offer-design .mainofferdetails h5 b{
            font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 1;
margin-bottom: 0;font-family: "SF Pro Display" !important; color: #000;
        }
        .newofferdesign .homepageofferblock.carddecoration > a > div img, .container-rimib .homepageofferblock.carddecoration >a > div img {
            display: block;
            /* height: 208px; */
            height: 100%;
            box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
        }
        .newofferdesign .limitedoffer .container{
            padding-left: 0;
            padding-right: 0;
        }
        .newofferdesign .limitedoffer  .sortingdiv .container
        {
            padding-left: 15px;
            padding-right: 15px;
        }
        .newofferdesign .homepageofferblock.carddecoration > a, .container-rimib .homepageofferblock.carddecoration > a
        {padding: 0;}
        .newofferdesign .Mortgage.homepageofferblock.carddecoration, .container-rimib .Mortgage.homepageofferblock.carddecoration
        {
            background: var(--lightBlueone);
        }
        .container-rimib .Mortgage.homepageofferblock.carddecoration{background: #EDF0F6; border-bottom: 1px solid var(--Blueone); border-radius: 0;}
        .newofferdesign .Mortgage.homepageofferblock.carddecoration{
            border-bottom: 1px solid var(--Blueone);  border-radius: 0;margin-bottom: 30px;
        }
        .newofferdesign .Banking.homepageofferblock.carddecoration, .container-rimib .Banking.homepageofferblock.carddecoration
        {
            background: var(--lightBluetwo);
        }
        .container-rimib .Banking.homepageofferblock.carddecoration{background: #EDF9FF; border-bottom: 1px solid var(--Bluetwo); border-radius: 0;}
        .newofferdesign .Banking.homepageofferblock.carddecoration{border-bottom: 1px solid var(--Bluetwo); border-radius: 0; margin-bottom: 30px;}
        .newofferdesign .Real.Estate.homepageofferblock.carddecoration, .container-rimib .Real.Estate.homepageofferblock.carddecoration
        {
            background-color: var(--lightgreen);
        }
        .container-rimib .Real.Estate.homepageofferblock.carddecoration{background: #F1FFF1;border-bottom: 1px solid var(--green); border-radius: 0;}
        .newofferdesign .Real.Estate.homepageofferblock.carddecoration{
            border-bottom: 2px solid var(--green);
            border-radius: 0;margin-bottom: 30px;
        }
        .newofferdesign .Investment.homepageofferblock.carddecoration, .container-rimib .Investment.homepageofferblock.carddecoration
        {
            background: var(--lightpurple);
        }
        .container-rimib .Investment.homepageofferblock.carddecoration{background: #FFEFFF;border-bottom: 1px solid var(--purple); border-radius: 0;}
        .newofferdesign  .Investment.homepageofferblock.carddecoration{
            border-bottom: 2px solid var(--purple);border-radius: 0;margin-bottom: 30px;
        }
        .newofferdesign .Insurance.homepageofferblock.carddecoration, .container-rimib .Insurance.homepageofferblock.carddecoration
        {
            background: var(--lightorange);
        }
        .container-rimib .Insurance.homepageofferblock.carddecoration{background: #FFF2EA; border-bottom: 1px solid var(--orange); border-radius: 0;}
        .newofferdesign .Insurance.homepageofferblock.carddecoration{
            border-bottom: 2px solid var(--orange);border-radius: 0;margin-bottom: 30px;
        }
        .mobile-filter.sortingdiv .container {
            padding: 0 !important;
        }
       .newofferdesign .mobiledrop .filtersectionglobal {
            margin-top: 0;
        }
        button#dropdownFilter, button#dropdownShortBy {
            color: #000;
            font-family: "Fjalla One";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: none;
            border: none;
        }
        .d-flex.mobiledrop {
            justify-content: center;
            border-top: 1px solid #ACACAC;
            border-bottom: 1px solid #acacac;
            margin-top: 20px;
        }
        .newofferdesign .mobiledrop .filtersectionglobal {
            margin-top: 0;
            text-align: left;
        }
        .newofferdesign .mobiledrop .selectinner .mainchecklabel{font-size: 14px;}
        .newofferdesign .mobiledrop .selectinner  span.maincheckboxicon img {
            width: 23px;
            height: 23px;
            object-fit: contain;
        }
        .newofferdesign .mobiledrop  .selectallinnercheckbox li.selectinner .form-check-input,.newofferdesign .mobiledrop input#Checkall {
            width: 18px;
            height: 18px;
            margin-left: 3px;
            z-index: 9;
        }
        .newofferdesign .mobiledrop .selectallinnercheckbox li.selectinner .dropdown-item .form-check-input
        {
            margin-left: -25px;
        }
        .newofferdesign .mobiledrop .selectallcheckbox label.form-check-label.mainchecklabel{font-size: 14px;}
        .newofferdesign .mobiledrop .form-checkselect{padding-left: 5px; padding:5px}
        .newofferdesign .mobiledrop  .selectinner .dropshortby a.dropdown-item{padding: 0;}
        .newofferdesign .mobiledrop  .selectinner .dropshortby  a.dropdown-item .form-check-input
        {
            margin-left: -20px;
        }
        .newofferdesign .mobiledrop .selectallinnercheckbox ul.dropdown-menu label.form-check-label{
            color: #000;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 11px;
            padding-left: 4px;
            width: 126px;
        }
        .show>.btn-secondary.dropdown-toggle:focus{
            box-shadow: none !important;
        }
        .dropshortby{position: relative;}
        .mobiledrop > .dropdown:before, .dropshortby:before {
            content: "";
        position: absolute;
        right: -12px;
        width: 1px;
        height: 24px;
        background: #ccc;
        top: 4px;
        }
        .mobiledrop  .dropdown-menu.show {
            display: block;
        width: 282px;
        padding: 0;
        left: auto !important;
        right: -145px;
        }
        .mobiledrop .dropfilter  span.subcatimage {
            display: inline-block;
            margin-left: 10px;
            margin-right: 10px;
        }
        .mobiledrop .dropfilter  span.subcatimage img{
            filter: invert(0);
        }
        .mobiledrop .dropfilter .form-check .form-check-input{
            margin-left: -10px;margin-top: 0;
        }
        .mobiledrop .dropfilter  input[type="checkbox"]:enabled:checked{
            background-color: #00831D;
            color: white;
        }
        .mobiledrop > .dropdown:last-child:before, .mobiledrop > .dropshortby:last-child:before{display: none;}
        .mobiledrop > .dropdown, .mobiledrop > .dropshortby {
            margin: 0 15px;
            position: relative;
            width: 50%;
            text-align: center;
        }
        .newofferdesign .mobiledrop .dropdown-menu ul{padding-left: 0; 
            background: #fff; 
            margin-bottom: 0;
            fill: #FFF;
            filter: drop-shadow(2px 2px 18px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
        position: relative;
        z-index: 9;
        padding: 10px ;
    }
    .mobiledrop .dropdown-menu-open ul {
        padding-left: 0;
        background: #fff;
        margin-bottom: 0;
        fill: #FFF;
        filter: drop-shadow(2px 2px 18px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
        position: relative;
        z-index: 999;
        padding: 10px;
    }
    .mobiledrop .dropdown-menu-open{display: block;
    width: 282px;
    padding: 0;
    left: auto !important;
    right: -155px;
    position: absolute;
    }
    .mobile-filter .dropdown-menu-open:before {
        background: rgba(0, 0, 0, 0.2);
        content: "";
        position: absolute;
        height: 1200px;
        width: 1021px;
        left: -374px;
        right: 0;
        top: 0px;
        bottom: 0;
        z-index: 99;
    }
    .mobiledrop .dropdown-menu-open ul li {
        position: relative;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #ccc;
    }
    .mobiledrop .dropdown-menu-open ul li:last-child{
        border:none;
    }
    .newofferdesign .mobiledrop .dropdown-menu ul li:last-child{border: none; padding-bottom: 0; margin-bottom: 0;}
    .newofferdesign .mobiledrop .dropdown-menu ul li{position: relative; padding-bottom: 5px; margin-bottom: 5px; border-bottom: 1px solid #ccc;}
        .newofferdesign .mobiledrop  li.selectinner .dropdown-toggle:after {
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 9px solid #fff;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            position: absolute;
            right: 8px;
            top: 9px;
            bottom: 0;
            margin: auto;
            display: block;
        }
        .form-checkselect{width:251px}
        .newofferdesign .mobiledrop .selectallinnercheckbox>ul>li{width: 250px; padding: 5px;}
        .selectallcheckbox  span.maincheckboxicon img{margin-left: 12px;}
        .filtersectionglobal {
            position: absolute;
            z-index: 999;
            left: -158px;
            right: auto;
            top:43px;
            width: 282px;
            margin: auto;
            background: rgba(255, 255, 255, 0.6);
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 2px 2px 18px 0px rgba(0, 0, 0, 0.25);
        
        }
        .viewbutton{width: 90px;
            height: 25px;
            font-family: "Roboto Flex";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        padding:5px;}
        button#dropdownFilter:after, button#dropdownShortBy:after {
       
            content: "";
            border-top: 7px solid #000000;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            position: absolute;
            right: 20px;
            top: 13px;
            bottom: 0;
            margin: auto;
            display: block;
        } 
        .newofferdesign .mobiledrop li.selectinner ul.dropdown-menu.show{width: auto !important; opacity: 1;}

        .filter-wrap {
            display: none;
        }
        
        .open-active.filter-wrap {
            display: block;
        }
        .filtersectionglobal:after{
            position: absolute;
        top: -9px;
        bottom: auto;
        left: 85%;
        margin-left: -10px;
        content: "";
        display: block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
        border-top: none;
        }
}
@media (max-width:600px){
    .newofferdesign .homepageofferblock.carddecoration > a > div img, .container-rimib .homepageofferblock.carddecoration >a > div img {
        height: 300px;
    }
}
@media (max-width:530px){
    .newofferdesign .homepageofferblock.carddecoration > a > div img, .container-rimib .homepageofferblock.carddecoration >a > div img {
        height: 300px;
    }
}

@media (max-width:480px){
    .newofferdesign .homepageofferblock.carddecoration > a > div img, .container-rimib .homepageofferblock.carddecoration >a > div img {
        height: 270px;
    }
}
@media (max-width:440px){
    .newofferdesign .homepageofferblock.carddecoration > a > div img, .container-rimib .homepageofferblock.carddecoration >a > div img {
        height: 240px;
    }
}

@media (max-width:430px){
    .newofferdesign .homepageofferblock.carddecoration > a > div img, .container-rimib .homepageofferblock.carddecoration >a > div img {
        height: 220px;
    }
}
@media (max-width:414px){
    .newofferdesign .homepageofferblock.carddecoration > a > div img, .container-rimib .homepageofferblock.carddecoration >a > div img {
        height: 210px;
    }
}
@media (max-width:380px){
    .newofferdesign .homepageofferblock.carddecoration > a > div img, .container-rimib .homepageofferblock.carddecoration >a > div img {
        height: 200px;
    }
}
@media (max-width:375px){
    .newofferdesign .homepageofferblock.carddecoration > a > div img , .container-rimib .homepageofferblock.carddecoration >a > div img{
        height: 190px;
    }
}
@media (max-width:350px){
   .newofferdesign .homepageofferblock.carddecoration > a > div img, .container-rimib .homepageofferblock.carddecoration >a > div img {
        height: 178px;
    }
}

@media (max-width:390px){
  
}