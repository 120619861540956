.craete-ad-containt {
  background-image: url("../../../../assets/Instituteppvimages/Institution_Dashboard_image.svg");
  background-Repeat: no-repeat;
  border-radius: 10px;
  padding: 10px;
  height: 100px;
  padding-left: calc(var(--bs-gutter-x)* 1);
  display: flex;    
  justify-content: space-between;
  align-items: center;
}
.craete-ad-containt .row{
  justify-content: space-around;
  align-items: center;
}
.subtitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 5px;
  text-align: left;
  color: #000;
  padding-left: 5px;
}

button.col-4.craete-ads-button {
  color: #FFF;
  background: #86357A; 
  backdrop-filter: blur(4px); 
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
  border: white;
  height: 40px;
  width: 220px; 
  margin-top: 7px;
}

.adstitle {
  margin: 10px 0 5px auto;
  font-weight: 700;
  font-size: 30px;  
  padding-top: 20px;
}

.adsubtitle {
  font-size: 16px;
  font-weight: 500;   
  color: #06293F; 
  line-height: 9px;
}
 

.adsbg {
  position: relative;
}

.adsimg {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 3%;
  left: 65.8%;
  display: flex;
  justify-content: end;
  align-items: end;
  color: white;
  z-index: 50;
  text-align: center;
}

.craete-ads {
  /* background: linear-gradient(0deg, #00B4D8, #00B4D8),
    linear-gradient(0deg, #00B4D8, #00B4D8),
    linear-gradient(0deg, #00B4D8, #00B4D8); */
  border-radius: 30px 30px 0px 0px;
}
section.entiredashboard.maincustomerdash.expert-dashboard .craete-ads .showallourbesttoo {
  margin-right: 0;
}

.ads-title {
  padding: 24px 0 0 72px;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.008em;
  text-align: left;
  color: #FFF;
}



.uploadlogo {
  padding-top: 10px;
  border: 1px solid #EF000B;
  background: #fff;
  width: 109px;
  height: 30px;
  border-radius: 2.67px;
  line-height: 8.02px;
  letter-spacing: 0.008em;
  color: #EF000B;
  font-size: 12.97px;
  font-weight: 700;
  margin: auto;
}

.uploadlogo.chnageupload {
  padding-top: 10px;
  background: #fff;
  width: 125px;
  height: 30px;
  border-radius: 2.67px;
  line-height: 8.02px;
  letter-spacing: 0.008em;
  color: #062D46;
  font-size: 12.97px;
  font-weight: 700;
  margin: 2% 29%;
  position: relative;
  z-index: 99;
}

.uploadlogo input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 105%;
  cursor: pointer;
  opacity: 0;
}

.uploadlogo img {
  width: 100%;
  height: 100%;
}

.uploadexpert.mb-4 {
  margin: 0 78px;
  position: sticky;
  background: linear-gradient(90.46deg, #024E7C 0%, #06293F 100%);
  border-radius: 6.48px;
  z-index: 55;
  
}
.adsbg .uploadexpert{
  padding: 0;
  height: 200px;
}
.adsbg .d-flex.border-div {
  justify-content: center;
  flex-wrap: wrap;
}

.adsbg .upload-image {
  width: 30px;

  position: absolute;
  left: 52px;
  top: 90px;
 
}
.adsbg .upload-image img{filter: invert(1);}
.uploadlogo label div img {
  object-fit: contain;
}


.uploadlogo label img {
  height: auto;
}

.outerlogo {
  border-radius: 3px;
  text-align: center;
  height: auto;
  display: inline-block;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
  display: block;

}

.uplod-title {

  font-weight: 500;
  line-height: 25.28px;
  letter-spacing: 0.008em;
  text-align: center;
  color: #FFFFFF;
  height: 40px;
  border-bottom: 3px solid #000;
  position: relative;

}


* Divider Styles */ .divider {
  position: relative;
  margin: 50px 0;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider::before,
.divider::after {
  content: "";
  position: absolute;
  width: 14%;
  margin-top: 11px;
  height: 2.59px;
  background-color: #ddd;
  margin-left: 26%;
  margin-right: 26%;
}

.divider::before {
  left: -7%;
}

.divider::after {
  right: 20%;
}

.divider-text {
  padding: 0 10px;
    font-weight: 300;
    font-size: 13px;
    background: #DAF3FF;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -12px;
    width: 82px;
    margin: auto;
}

/* Content Styles */
.content {
  margin: 0px 0;
}



.upload-image {
  align-items: flex-end;
  display: flex;
  align-items: end;
  justify-content: end;
  margin-bottom: 35px;
}

.col-8.brows-part {
  padding-right: 20%;
}

.border-div {
  border-width: 2px;
  border-color: #FFF;
  border-style: dashed;
  padding: 40px 0 20px 0;
}

.row.border-div {
  margin: 2px;
}

img.select-img {
  width: 100%;
  height: 200px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.uploadexpert {
  background: #DAF3FF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.close-ads {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border-radius: 5px;
  border: none;
  width: 30px;
  height: 30px;
  transition: 0.3s all ease-in;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.input-form-div {
  background: #90E0EF;
  padding-top: 30px;
}

.row.input-form-div {
  margin-right: calc(-0.0* var(--bs-gutter-x));
  margin-left: calc(-0.0* var(--bs-gutter-x));
}



.textarea-cation {
  height: 340px;
}


/*Switch-active checkbox*/
.switch-active {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 35px;
  margin-bottom: 0;
}

.switch-active input {
  opacity: 0;
  width: 0;
  height: 0;
}



.switch-active input:checked+.slider {
  background: #86357A;
}

.switch-active input:focus+.slider {
  box-shadow: 0 0 1px #86357A;
}

.switch-active input:checked+.slider:before {
  -webkit-transform: translateX(65px);
  -ms-transform: translateX(65px);
  transform: translateX(65px);
}

.switch-active input:checked+.slider:after {
  content: "Active";
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  left: 20px;
  top: 7px;
  color: #FFF;
}

.switch-active .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e5e5;
  -webkit-transition: 0.4s;
  transition: 0.4s;

}

.switch-active .slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 7px;
  bottom: 5px;
  background: #fff;
  box-shadow: 0px 3px 3px 0px #00000026;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-active .slider:after {
  content: "Inactive";
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 75px;
  right: 20px;
  color: #06293f;

}

.switch-active input:checked+.slider {
  background-color: #86357A;
}

.switch-active input:focus+.slider {
  box-shadow: 0 0 1px #86357A;
}

.switch-active input:checked+.slider:before {
  -webkit-transform: translateX(82px);
  -ms-transform: translateX(82px);
  transform: translateX(82px);
}

.switch-active .slider.round {
  border-radius: 17px;
}

.switch-active .slider.round:before {
  border-radius: 50%;
}




.myadpostbanner {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 10px 0 0 0;
}

.mybtn .myadbtn:nth-child(1) {
  margin-right: 10px;
}

.row.ad-main {
  width: 93%;
  margin-left: 28px;
}

.adsblog {
  margin-top: 20px; 
  padding-top:20px ;
}


.textarea-caption {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #212529;
  border-radius: 30px;
  height: 381px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  padding: 20px;
}

.textarea-captio:hover {
  border-color: #817F7F;
  box-shadow: none;
  border: none;
}

textarea.textarea-caption::placeholder, .input-caption::placeholder {
  color: #817F7F;
  left: 20px;
  line-height: 24px;
  cursor: initial;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
  background: transparent;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.008em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  z-index: 9;
}

.textarea-caption:focus {
  border: none;
}

.textarea-caption:focus::placeholder {
  color: #aaa;
}



input.input-caption, TagsInput.input-caption {
  box-sizing: border-box;
  width: 100%;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #212529;
  border: none;
  resize: none;
  outline: none;
  width: 185px;
  height: 66px;
  border-radius: 10px;
  position: relative;
}

span.input-icon-add {
  position: absolute;
  right: 5px;
  top: 18px;
  width: 29px;
  height: 29px;
}

.guide-img {
  height: 16px;
  width: 16px;
  margin-left: 5px;
}

.guide-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #053755;

} 
section.entiredashboard.maincustomerdash.main.institute_dashboard span.rti--tag
{
  background-color: #D2FFD2;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard span.rti--tag button{
  background: #00831D;
  color: #fff;
}
@media (max-width:767px){
  button.col-4.craete-ads-button{
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    border-radius: 2px;
    border: white;
    height: 40px;
    width: 220px;
    margin-top: 7px;
    padding: 5px;
  }
  .adsubtitle {
    font-size: 12px;
    font-weight: 500;
    color: #06293F;
    line-height: 14px;
    margin-bottom: 5px;
}
.adstitle {
  margin: 0px 0 5px auto;
  font-weight: 700;
  font-size: 14px;
  padding-top: 10px;
}
.adsbg .upload-image {
  position: relative;
  left: auto;
  top: 0;
  width: 20px;
}
}