@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.latestdeailsexpertppvspecialize {
		padding: 0px 10px;
		margin-left: 10px;
	}

	.entireexpertppv .categoryonesetwidthinner {
		flex-wrap: wrap;
	}

	.dp img {
		height: 250px;
		width: 100%;
		object-fit: contain;
	}

	.backpap {
		border-radius: 0px 20px 20px 0px;
		padding: 10px 0px;
		position: relative;
	}

	section.expertformppinew .backgroundgrey {
		background: #E9EEF9;
		margin-right: 0px;
		width: 49.5%;
		border-radius: 0px 20px 20px 0px;
	}
}

@media (max-width: 767px) {
	.newexpertppvdesign ul.deone li a {
		font-family: "SF Pro Display" !important;
	}

	.csadress ul {
		padding-left: 0;
	}

	.blogexpert .blogtext p {
		font-size: 12px;
	}

	.blogexpert .bloglist:nth-child(1) .blogtext,
	.blogexpert .bloglist .blogtext {
		padding-left: 10px;
	}

	.blogexpert .bloglist .blogimage img {
		height: auto;
	}

	.blogexpert .blogexpertinnerps.mt-5.mb-5 {
		margin-top: 16px !important;
	}

	.specialisationexpertppv {
		padding: 15px 0;
	}

	.specialisationexpertppv .container {
		padding: 0;
	}

	.expertprofilelogouploadinner {
		/* border-radius: 273px;
        border: 8px solid #FFF;
        width: 203px;
        height: 203px;
        overflow: hidden;
        margin: auto; */
		border-radius: 0;
		border: none;
		width: 100%;
		height: 100%;
		overflow: hidden;
		margin: auto;
		position: relative;
	}

	.expertprofilelogouploadinner::before {
		/* background: url(../../../assets/expertppvimage/photodown.png); */
        border: 0px solid rgba(255, 255, 255, 0.00);

background: radial-gradient(231% 135.8% at 0.9% 2.98%, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);

filter: blur(27.950000762939453px);
backdrop-filter: blur(21px);
		position: absolute;
		content: "";
		height: 60%;
        width: 100%;
        background-size: cover;
        opacity: 1;
        background-position: bottom;
        bottom: -50px;
		display: none;
	}
    .expertprofilelogouploadinner::after{
        background: url(../../../assets/expertppvimage/photoup.png);
		position: absolute;
		content: "";
		height: 63%;
        width: 100%;
        background-size: cover;
        opacity: 0.5;
        background-position: bottom;
        top: 0;
    }
	.entireexpertppv.Mortgage .aboutexpertppv {
		background: url(../../../assets/expertppvimage/MortgageAboutmeBG.svg);
		background-size: cover;
		background-position: center center;
	}

	.entireexpertppv.Real.Estate .aboutexpertppv {
		background: url(../../../assets/expertppvimage/RealEstateAboutmeBG.svg);
		background-size: cover;
		background-position: center center;
	}

	.entireexpertppv.Investment .aboutexpertppv {
		background: url(../../../assets/expertppvimage/InvestmentAboutmeBG.svg);
		background-size: cover;
		background-position: center center;
	}

	.entireexpertppv.Insurance .aboutexpertppv {
		background: url(../../../assets/expertppvimage/InsuranceAboutmeBG.svg);
		background-size: cover;
		background-position: center center;
	}

	.entireexpertppv.Banking .aboutexpertppv {
		background: url(../../../assets/expertppvimage/BankingAboutmeBG.svg);
		background-size: cover;
		background-position: center center;
	}
    .entireexpertppv.Banking .newexpertppvdesign ul.deone li:first-child, .entireexpertppv.Banking .newexpertppvdesign ul.deone li{

        border: 1px solid rgba(34, 128, 184, 0.00);
        background: radial-gradient(231% 135.8% at 0.9% 2.98%, #FFF 0%, rgba(255, 255, 255, 0.50) 100%);

    }
    .entireexpertppv.Real.Estate .newexpertppvdesign ul.deone li:first-child, .entireexpertppv.Real.Estate .newexpertppvdesign ul.deone li{
        border: 1px solid rgba(0, 131, 29, 0.00);
        background: radial-gradient(231% 135.8% at 0.9% 2.98%, #FFF 0%, rgba(255, 255, 255, 0.50) 100%);
    }
    .entireexpertppv.Insurance .newexpertppvdesign ul.deone li:first-child, .entireexpertppv.Insurance .newexpertppvdesign ul.deone li{
        border: 1px solid rgba(255, 89, 18, 0.00);
        background: radial-gradient(231% 135.8% at 0.9% 2.98%, #FFF 0%, rgba(255, 255, 255, 0.50) 100%);
        }
    .entireexpertppv.Investment .newexpertppvdesign ul.deone li:first-child, .entireexpertppv.Investment .newexpertppvdesign ul.deone li{
        border: 1px solid rgba(134, 53, 122, 0.00);
        background: radial-gradient(231% 135.8% at 0.9% 2.98%, #FFF 0%, rgba(255, 255, 255, 0.50) 100%);
    }
    .entireexpertppv.Mortgage .newexpertppvdesign ul.deone li:first-child, .entireexpertppv.Mortgage .newexpertppvdesign ul.deone li{
       
        border: 1px solid rgba(52, 98, 122, 0.00);
        background: radial-gradient(231% 135.8% at 0.9% 2.98%, #FFF 0%, rgba(255, 255, 255, 0.50) 100%);
     
    }
	.newexpertppvdesign .socialpublic li span img {
		width: 12px;
	}
    .socialpublic li:first-child span img{width: 7px;}
	.entireexpertppv .category-id3,
	.entireexpertppv .category-id1,
	.entireexpertppv .category-id2,
	.entireexpertppv .category-id4,
	.entireexpertppv .category-id5 {
		order: initial
	}
	.newexpertppvdesignwrap
	{
		padding-top: 125%;
		position: relative;
		
	}

	.newexpertppvdesignwrap:before {
		content: '';
		background: url(../../../assets/expertppvimage/expertppvvpathc.png);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background-position: center bottom -150px;
		background-size: cover;
		background-repeat: no-repeat;
		pointer-events: none;
	}
	.newexpertppvdesign
	{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(45deg, #ccc, transparent);
	}

	.expertprofilelogoupload {
		max-width: 100%;
        padding: 0;
	}

	.expertpublicviewprofiledetails .container {
		padding: 0;
	}

	.entireexpertppv .expertprofilelogouploadinner>img {
		width: 100%;
	}

	.entireexpertppv .form-group .form-control,
	.entireexpertppv .input-container .placeholder {
		background: transparent;
		color: #000;

	}

	.entireexpertppv .form-group .form-control {
		box-shadow: 0px 0px 4px 0px #FFF;
		border: 1px solid #000;
        color: #000;
        font-family: "microsoft_sans_serifregular" !important;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
	}

	.entireexpertppv .mb-4.input-container.form-group:nth-child(3) span.inputimage img {
		filter: invert(0);
	}

	.entireexpertppv span.inputimage img {
		filter: none;
		width: 20px;
		filter: invert(1);
	}

	.ovtercslist,
	.csadress {
		flex-wrap: wrap;
	}

	.csadress li {
		padding-right: 10px;
		font-size: 12px;
		text-align: left;
	}

	.newexpertppvdesign ul.deone li:first-child,
	.newexpertppvdesign ul.deone li {
		margin-right: 0px;
		width: 100%;
		margin-bottom: 10px;
		text-align: left;
	}

	.aboutexpertppvback {
		background: none;
	}

	.aboutexpertppv {
		background: url(../../../assets/expertppvimage/InvestmentAboutmeBG.svg);
		background-size: cover;
		background-position: center center;
	}
	

	.aboutexpertppv .row {
		flex-direction: column-reverse;
	}

	.aboutexpertppv .heading h2,
	.about-me-ppv h3,
	.about-me-ppv p {
		color: #000;
	}

	section.blogexpertppvsection h2,
	.entireexpertppv section.awards heading {
		font-size: 18px;
	}

	section.blogexpertppvsection h4 {
		font-size: 12px;
		margin-top: 0 !important;
		font-family: 'microsoft_sans_serifregular' !important;
	}

	.aboutexpertppv .col-lg-3.no-pad-right {
		padding-right: 15px;
	}

	.about-me-ppv {
		padding: 0 15px;
		text-align: left;
	}

	.newexpertppvdesign .socialpublic {
		position: absolute;
		left: 0;
		width: 100%;
		margin: auto;
		top: auto;
		right: 0;
		bottom: 10px;
		z-index: 9;

	}

	.newexpertppvdesign .socialpublic ul {
		padding: 0;
		display: flex;
		justify-content: center;
		margin-bottom: 0;
	}

	.entireexpertppv .expppvofferinnersection h2,
	section.specialisationexpertppv h2 {
		font-size: 18px;
	}

	.entireexpertppv .expppvofferinnersection h5.h5_style,
	section.specialisationexpertppv h5 {
		font-size: 12px;
		line-height: 1;
		font-family: "SF Pro Display" !important;
	}

	.detailsbank .categoryonesetwidthinner .iconone {
		width: 40px;
		height: 40px;
	}

	.detailsbank .categoryonesetwidthinner .iconone img {
		width: 25px;
	}

	.expertpublicviewprofiledetails {
		padding: 0px;
		background: transparent;
		background-size: cover;
		background-position: center center;
	}

	.newexpertppvdesign .cstlist {
		color: #FFF;
		font-size: 12px;
		font-style: normal;
		font-family: "Montserrat", sans-serif !important;
		font-weight: 500;
		border-radius: 60px;
		border: 1px solid #FFF;
		padding: 3px 10px 3px 15px;
		line-height: 22px;
		display: inline-block;
		margin-right: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: max-content;
		height: 25px;
		margin-bottom: 10px;
		border-radius: 60px;
		border: 1px solid #FFF;
		background: rgba(0, 0, 0, 0.20);
	}

	.newexpertppvdesign .socialpublic ul li {
		margin-right: 10px;
	}

	.specialisationexpertppv {
		box-shadow: none;
	}

	.ovtercslist {
		max-width: 280px;
		margin-left: auto;
		margin-right: auto;
		padding: 0;
	}

	.newexpertppvdesign ul.deone li span {
		background: #fff
	}

	.newexpertppvdesign ul.deone li span img {
		filter: invert(0);

	}

	.entireexpertppv .expppvofferinnersection .slick-prev {
		left: 5px;
		top: 59%;
	}

	.entireexpertppv .expppvofferinnersection .slick-next {
		right: 7px;
		top: 59%;
	}

	.entireexpertppv .expppvofferinnersection .applybutton {
		margin-left: 0;
		width: 90px;
		height: 25px;
		border-radius: 2px;
		font-size: 12px;
		display: inline-block;
		padding: 2px;

	}

	.entireexpertppv a.showallbtn.mt-4.mb-3 {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.entireexpertppv .aboutexpertppv {
		margin-top: 20px;
		padding-top: 20px;
	}

	.entireexpertppv {
		text-align: center;
	}

	.entireexpertppv .dp img {
		height: auto;
		width: 100%;
		object-fit: cover;
		object-position: top;
		margin: auto;
		text-align: center;
		margin-bottom: 20px;
		object-fit: contain;
	}

	.ppvplan.Premium:before {
		left: 15px;
		top: 9px;
	}

	.entireexpertppv #ppvAdsSection .container .sliderContent {
		margin: 10px 0;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.40)
	}

	.newexpertppvdesign ul.deone li:first-child,
	.newexpertppvdesign ul.deone li {
		border-radius: 4px;
		border: 1px solid rgba(52, 98, 122, 0.00);
		background: radial-gradient(231% 135.8% at 0.9% 2.98%, #FFF 0%, rgba(255, 255, 255, 0.50) 100%);
		/* backdrop-filter: blur(21px); */

	}

	.newexpertppvdesign ul.deone li a {
		color: #000;
	}

	.newexpertppvdesign .socialpublic ul li {
		background: #ffffff;
		width: 25px;
		height: 25px;
		margin-right: 5px;
		display: inline-block;
		text-align: center;
		border-radius: 70px;
		line-height: 22px;
        border: 1px solid #BCBCBC;
		margin-bottom: 0px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}

	#ppvAdsSection .expppvofferinnersection h5.h5_style {
		width: 80%;
		margin: 2px auto;
		font-family: "SF Pro Display" !important;
	}

	.cstlist img {
		filter: invert(1);
		width: 15px !important;
		height: 15px !important;
		object-fit: contain;
		margin-right: 5px;
	}

	section.expertformppinew .backgroundgrey {
		width: 100%;
	}

	.entireexpertppv section.awards ul.cetificatesection,
	.entireexpertppv section.awards ul.awardssection {
		justify-content: center;
		flex-wrap: wrap;
		color: #000;
	}

	.expertformppinew .col-lg-6.col-sm-6.nopadright {
		display: none;
	}

	.entireexpertppv section.awards ul.cetificatesection li span,
	.entireexpertppv section.awards ul.awardssection li span {
		position: absolute;
		left: 20px;
		width: 40px;
		height: 40px;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.entireexpertppv section.awards ul.cetificatesection li,
	.entireexpertppv section.awards ul.awardssection li {
		border-radius: 2px;
		border: 1px solid #000;
		width: max-content;
		margin-left: 10px;
		color: #000;
		font-family: "Poppins", sans-serif;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		position: relative;
		margin-right: 0;
		padding: 10px;
		padding-left: 50px;
		margin-bottom: 10px;
		background: rgba(0, 0, 0, 0.10);
	}

	section.expertformppinew .container {
		border-radius: 0;
		border: none;
		background: transparent;
		box-shadow: none;
		margin: 0px 0;
		overflow: hidden;
	}

	.entireexpertppv section.awards {
		padding: 20px 0;
		margin: 20px 0;
	}

	.entireexpertppv section.awards ul.cetificatesection li span,
	.entireexpertppv section.awards ul.awardssection li span {
		position: absolute;
		left: 6px;
		width: 31px;
		height: 30px;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.entireexpertppv section.awards ul.cetificatesection li span img,
	.entireexpertppv section.awards ul.awardssection li span img {
		height: 30px;
		filter: invert(1);
	}

	.backpap {
		padding: 10px;
	}

	.globalexpertspecification {
		flex-wrap: wrap;
	}

	.latestdeailsexpertppvspecialize {
		padding: 0 10px;
		margin: 0 10px;
	}

	.entireexpertppv .categoryonesetwidthinner {
		flex-wrap: wrap;
		justify-content: center;
	}

	.Expertbasicdeatail h2.h2_style,
	.backpap h2,
	.about-me-ppv h3 {
		font-size: 18px;
	}

	.Expertbasicdeatail h2.h2_style {
		margin-bottom: 10px;
		text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	}

	.backpap h2 {
		color: #000;
		margin-bottom: 10px;
	}

	.expertformppiinnernew.backpap h4 {
		font-size: 12px;
		color: #000;
		font-family: "SF Pro Display" !important;
	}

	section.expertformppinew {
		background: url(../../../assets/expertppvimage/mobilegetintouchimage.svg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

	}

	.backpap .ctatwobutton {
		border-radius: 2.5px;
		border: 0.5px solid #EF000B;

		background: #FFF;
		color: #EF000B;
		text-align: center;
		font-size: 12px;
		font-style: normal;
		letter-spacing: 1.2px;
		font-family: "Roboto Flex", sans-serif;
		font-weight: 500;
		line-height: normal;
		width: 90px;
		line-height: 1.3;
		height: 25px;
	}

	.expertformppiinnerdisexperttouch textarea.input:not(:placeholder-shown)~.placeholder {
		color: #214860;
		background: transparent;
		top: 14px;
		left: 0;
		right: auto;
	}

	.entireexpertppv section.awards {
		background: url(../../../assets/expertppvimage/mycertificationBGmobile.svg) #ccc;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;


	}

	.blogexpert .blogtext h5 {
		font-size: 12px;
		margin-top: 0;
		font-family: "microsoft_sans_serifregular" !important;
	}

	section.blogexpert h2 b {
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
	}

	section.blogexpert h6 {
		font-size: 12px;
	}

	.blogexpert .bloglist a {
		padding: 10px;
	}

	.newexpertppvdesign .ppvplan {
		width: 120px;
		left: auto;
		right: 20px;
	}

	.ppvplan.Elite:before {
		left: 24px;
	}

	.aboutexpertppvback {
		min-height: auto;
	}

	.entireexpertppv .input-container .placeholder {
		left: 11px;
	}

	.expertformppiinnernew span.text-danger {
		position: absolute;
		font-size: 10px;
		left: 0;
		line-height: 12px;
		bottom: -15px;

	}

	.expertformppiinnerdisexperttouch .input-container.form-group,
	.entireexpertppv .mb-4.input-container.form-group {
		margin-bottom: 20px !important;
	}

	.expertformppiinnernew select.input.form-control.form-select:not(:placeholder-shown)~.placeholder {
		transform: translateY(-14px) translateX(2px) scale(1);
		font-size: 12px;
	}

	section.expertformppinew .backgroundgrey {
		background: transparent;
		margin-right: 5px;
		width: 100%;
		border-radius: 0;
	}

	.expicontab img {
		height: 30px !important;
		width: auto !important;
		margin-bottom: 3px;
		filter: invert(57%) sepia(71%) saturate(7%) hue-rotate(34deg) brightness(91%) contrast(94%);
	}

	.globalexpertspecification ul li {
		background: #FFF;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
	}

	/* .globalexpertspecification ul li.category-id1{
        background: linear-gradient(0deg, #FFF 0%, #FFBA9C 112.66%);
    }
    .globalexpertspecification ul li.category-id2{
        background: linear-gradient(0deg, #FFF -0.12%, #FFD0F8 102.5%);
    }
    .globalexpertspecification ul li.category-id3{
        background: linear-gradient(180deg, #C7D1E3 0%, #FFF 100%) ;
    }
    .globalexpertspecification ul li.category-id4{
        background: linear-gradient(180deg, #B8DEFF 0%, #FFF 100%);
    }
    .globalexpertspecification ul li.category-id5{
        background: linear-gradient(180deg, #ACFFBF 0%, #FFF 100%);
    } */
	.globalexpertspecification .expiconletter {
		font-size: 0;
	}

	.globalexpertspecification .expiconletter:first-letter {

		text-align: center;
		text-shadow: 0.347px 0.924px 1.965px rgba(0, 0, 0, 0.25);
		font-family: "Red Hat Display";
		font-size: 30px;
		font-style: normal;
		font-weight: 700;
		line-height: 1;
	}

	.globalexpertspecification ul li span {
		color: #969696;
		text-align: center;
		font-family: "Fjalla One";
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: block;
		width: 100%;
	}

	/* .globalexpertspecification ul li.category-id1 span, .globalexpertspecification ul li.category-id1 .expiconletter:first-letter{
        color: #FF4C00;
    }
    .globalexpertspecification ul li.category-id2 span, .globalexpertspecification ul li.category-id2 .expiconletter:first-letter{
        color: #86357A;
    }
    .globalexpertspecification ul li.category-id3 span, .globalexpertspecification ul li.category-id3 .expiconletter:first-letter{
        color: #2F596F;
    }
    .globalexpertspecification ul li.category-id4 span, .globalexpertspecification ul li.category-id4 .expiconletter:first-letter{
        color: #2281B8;
    }
    .globalexpertspecification ul li.category-id5 span, .globalexpertspecification ul li.category-id5 .expiconletter:first-letter{
        color: #00831D;
    } */


	/* .globalexpertspecification ul li.category-id1 img{
        filter: invert(37%) sepia(36%) saturate(6250%) hue-rotate(1deg) brightness(103%) contrast(105%);
    }
    .globalexpertspecification ul li.category-id2 img{
        filter: invert(25%) sepia(89%) saturate(454%) hue-rotate(258deg) brightness(97%) contrast(96%);
    }
    .globalexpertspecification ul li.category-id3 img{
        filter: invert(30%) sepia(31%) saturate(775%) hue-rotate(156deg) brightness(90%) contrast(87%);
    }
    .globalexpertspecification ul li.category-id4 img{
        filter: invert(38%) sepia(99%) saturate(398%) hue-rotate(158deg) brightness(94%) contrast(93%);
    }
    .globalexpertspecification ul li.category-id5 img{
        filter: invert(25%) sepia(100%) saturate(1736%) hue-rotate(123deg) brightness(89%) contrast(101%);
    } */


	.globalexpertspecification ul li.category-id1.active {
		background: linear-gradient(180deg, #FF5912 0%, #E3A285 100%);
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
	}

	.globalexpertspecification ul li.category-id2.active {
		background: linear-gradient(180deg, #86357A 0%, #FFABF3 100%);
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
	}

	.globalexpertspecification ul li.active.Mortgage.category-id3 {
		background: linear-gradient(180deg, #2F596F 0%, #A7B7D2 100%);

		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
	}

	.globalexpertspecification ul li.active.Real.Estate {
		background: linear-gradient(180deg, #00801D 0%, #93EF94 100%);
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;

	}

	.globalexpertspecification ul li.category-id4.active {
		background: linear-gradient(180deg, #2481B9 0%, #AAD7FF 100%);
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
	}

	.globalexpertspecification ul li.category-id5.active {
		background: linear-gradient(180deg, #00801D 0%, #93EF94 100%);
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
	}

	.globalexpertspecification ul li.category-id1.active img {
		filter: invert(1);
		color: #fff;
	}

	.globalexpertspecification ul li.category-id2.active img {
		filter: invert(1);
		color: #fff;
	}

	.globalexpertspecification ul li.category-id3.active img {
		filter: invert(1);
		color: #fff;
	}

	.globalexpertspecification ul li.category-id4.active img {
		filter: invert(1);
		color: #fff;
	}

	.globalexpertspecification ul li.category-id5.active img {
		filter: invert(1);
		color: #fff;
	}

	.globalexpertspecification ul li.category-id1.active span,
	.globalexpertspecification ul li.category-id1.active .expiconletter:first-letter {
		color: #fff;
	}

	.globalexpertspecification ul li.category-id2.active span,
	.globalexpertspecification ul li.category-id2.active .expiconletter:first-letter {

		color: #fff;
	}

	.globalexpertspecification ul li.category-id3.active span,
	.globalexpertspecification ul li.category-id3.active .expiconletter:first-letter {

		color: #fff;
	}

	.globalexpertspecification ul li.category-id4.active span,
	.globalexpertspecification ul li.category-id4.active .expiconletter:first-letter {

		color: #fff;
	}

	.globalexpertspecification ul li.category-id5.active span,
	.globalexpertspecification ul li.category-id5.active .expiconletter:first-letter {

		color: #fff;
	}

	.globalexpertspecification ul li.active:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 0;
		height: 0;
		border-left: 8px solid transparent !important;
		border-right: 8px solid transparent;
		bottom: -17px;
		top: auto
	}

	.globalexpertspecification ul li.category-id1.active:before {
		border-top: 11px solid #DF9C7F !important;
	}

	.globalexpertspecification ul li.category-id2.active:before {
		border-top: 11px solid #EE9FE3 !important;
	}

	.globalexpertspecification ul li.active.Mortgage.category-id3:before {
		border-top: 11px solid #A7B7D2 !important;


		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
		;
	}

	.globalexpertspecification ul li.category-id4.active:before {
		border-top: 11px solid #AAD7FF !important;
	}

	.globalexpertspecification ul li.category-id5.active:before {
		border-top: 11px solid #95F095;
		top: 100%;
	}

	section.specialisationexpertppv .bg-white-new-exp .detailsbank h5 {
		font-size: 12px;
	}

	.entireexpertppv .categoryonesetwidthexpert span {
		font-size: 10px;
		width: 85px;
		height: 85px;
		border-radius: 10px;
		padding: 1px 4px;
		margin-left: 5px;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	}

	.bg-white-new-exp .detailsbank h5:before {
		top: 1px;
	}

	.entireexpertppv .slick-slide>div {
		padding: 0 0px;
	}

	section .expertformppi .input-container-git input,
	section .expertformppi .input-container-git select,
	section .expertformppi .input-container-git textarea {
		box-shadow: none;
		border-radius: 5px;
		font-size: 12px;
	}

	.entireexpertppv section.awards heading {
		margin-bottom: 10px;
		color: #000;
	}

	.entireexpertppv #ppvAdsSection,
	section.blogexpertppvsection {
		margin: 10px 0;
	}

	.basicdetails {
		position: relative;
	}

	.explitmoile {
		position: absolute;
		bottom: -10%;
		left: 0;
		right: 0;
		margin: auto;
		width: 90%;
		z-index: 9;
	}

}