:root{
    --ppvexpertbluebg: url(../../../assets/Instituteppvimages/ppvexpertbluebg.svg);
    --ppvexpertbluetwobg: url(../../../assets/Instituteppvimages/ppvexpertbluetwobg.svg);
    --ppvexpertgreenbg: url(../../../assets/Instituteppvimages/ppvexpertgreenbg.svg);
    --ppvexpertorangebg: url(../../../assets/Instituteppvimages/ppvexpertorangebg.svg);
    --ppvexpertpurplebg: url(../../../assets/Instituteppvimages/ppvexpertpurplebg.svg);
}

/* Elite card style start here  */

.ppvExpertCardPremium{order: 2;}

.ppvExpertCardElite {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 550px;
    border-radius: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px 0px;
    position: relative;
}


.ppvExpertCardPremium.cardbg-id1, .ppvExpertCardPremium.cardbg-id2, .ppvExpertCardPremium.cardbg-id3,
.ppvExpertCardPremium.cardbg-id4, .ppvExpertCardPremium.cardbg-id5{background: #fff;}
.ppvExpertCardPremium.cardbg-id1 .category{background: var(--lightorange);}
.ppvExpertCardPremium.cardbg-id2 .category{background: var(--lightpurple);}
.ppvExpertCardPremium.cardbg-id3 .category{background: var(--lightBlueone);}
.ppvExpertCardPremium.cardbg-id4 .category{background: var(--lightBluetwo);}
.ppvExpertCardPremium.cardbg-id5 .category{background: var(--lightgreen);}


.cardbg-id1 .Eplanname{background: var(--lightorange); border: 2px solid var(--orange);}
.cardbg-id2 .Eplanname{background: var(--lightpurple); border: 2px solid var(--purple);}
.cardbg-id3 .Eplanname{background: var(--lightBlueone); border: 2px solid var(--Blueone);}
.cardbg-id4 .Eplanname{background: var(--lightBluetwo); border: 2px solid var(--Bluetwo);}
.cardbg-id5 .Eplanname{background: var(--lightgreen); border: 2px solid var(--green);}


.ppvExpertCardPremium.cardbg-id1 .ppvExpertCardEliteDetails .contact .contactImg{
    border: 1px solid var(--orange);
}
.ppvExpertCardPremium.cardbg-id2 .ppvExpertCardEliteDetails .contact .contactImg{
    border: 1px solid var(--purple);
}
.ppvExpertCardPremium.cardbg-id3 .ppvExpertCardEliteDetails .contact .contactImg{
    border: 1px solid var(--Blueone);
}
.ppvExpertCardPremium.cardbg-id4 .ppvExpertCardEliteDetails .contact .contactImg{
    border: 1px solid var(--Bluetwo);
}
.ppvExpertCardPremium.cardbg-id5 .ppvExpertCardEliteDetails .contact .contactImg{
    border: 1px solid var(--green);
}


.ppvExpertCardElite.cardbg-id1{border-radius: 20px;
    background: #FFF2EA;
    box-shadow: 0px 0px 8px 0px rgba(255, 76, 0, 0.35);
    border: 5px solid var(--orange);
}
.ppvExpertCardElite.cardbg-id2 {border-radius: 20px;
    background: #FFEFFF;
    box-shadow: 0px 0px 8px 0px rgba(134, 53, 122, 0.35);
    border: 5px solid var(--purple);
}
.ppvExpertCardElite.cardbg-id3 {border-radius: 20px;
    background: #EEF1F8;
    box-shadow: 0px 0px 8px 0px rgba(47, 89, 111, 0.35);
    border: 5px solid var(--Blueone);
}
.ppvExpertCardElite.cardbg-id4 {border-radius: 20px;
    background: #EDF9FF;
    box-shadow: 0px 0px 8px 0px rgba(34, 128, 184, 0.35);
    border: 5px solid var(--Bluetwo);
}
.ppvExpertCardElite.cardbg-id5 {
    border-radius: 20px;
    background: #EAFFEA;
    box-shadow: 0px 0px 8px 0px rgba(0, 128, 62, 0.35);
    border: 5px solid var(--green);
}

.ppvExpertCardPremium.cardbg-id1 {
    border: 5px solid var(--orange);
} 
.ppvExpertCardPremium.cardbg-id2 {
    border: 5px solid var(--purple);

}
.ppvExpertCardPremium.cardbg-id3 {
    border: 5px solid var(--Blueone);

}
.ppvExpertCardPremium.cardbg-id4 {
    border: 5px solid var(--Bluetwo);
}
.ppvExpertCardPremium.cardbg-id5 {    
    border: 5px solid var(--green);

} 

/*
.cardbg-id1 {
    background-image: var(--ppvexpertorangebg);
    border: 5px solid var(--orange);
} 
.cardbg-id2 {
    background-image: var(--ppvexpertpurplebg);
    border: 5px solid var(--purple);

}
.cardbg-id3 {
    background-image: var(--ppvexpertbluetwobg);
    border: 5px solid var(--Blueone);

}
.cardbg-id4 {
    background-image: var(--ppvexpertbluebg);
    border: 5px solid var(--Bluetwo);
}
.cardbg-id5 {
    background-image: var(--ppvexpertgreenbg);
    border: 5px solid var(--green);

} */
.ppvExpertCardEliteDetails h2{
    text-transform: capitalize;
    font-size: 25px;
    color: #000;
    font-weight: 500;
}
.ppvExpertCardPremium .ppvExpertCardEliteDetails h2{font-size: 20px;}
.ppvExpertCardEliteDetails .category{
    background: white;
    padding: 5px 40px;
    font-weight: 600;
    font-family: "Fjalla One";
}

.category-id1{
    color: var(--orange);
}
.category-id2 {
    color: var(--purple);
}
 .category-id5 {
    color: var(--green);
}
 .category-id4 {
    color: var(--Bluetwo);
}
 .category-id3 {
    color: var(--Blueone);
}





.pcategory-id1 {
    color: var(--orange);
    font-weight: 600;
    background: #F7D7B6;
}
.pcategory-id2 {
    color: var(--purple);
    font-weight: 600;
    background: #FDC3F5;
}
 .pcategory-id5 {
    color: var(--green);
    font-weight: 600;
    background: #D2FFD2;
}
 .pcategory-id4 {
    color: var(--Bluetwo);
    font-weight: 600;
    background: #DAF3FF;
}
 .pcategory-id3 {
    color: var(--Blueone);
    font-weight: 600;
    background: #DAF3FF;
}



.ppvExpertCardEliteDetails .insuranceText {
    color: var(--orange);
    font-weight: 600;
}
.ppvExpertCardEliteDetails .investmentText {
    color: var(--purple);
    font-weight: 600;
}
.ppvExpertCardEliteDetails .realEstateText {
    color: var(--green);
    font-weight: 600;
}
.ppvExpertCardEliteDetails .bankingText {
    color: var(--Bluetwo);
    font-weight: 600;
}
.ppvExpertCardEliteDetails .mortgageText {
    color: var(--Blueone);
    font-weight: 600;
}

 .ppvExpertCardEliteDetails .name, .contact{
   padding: 0px 40px;

}



.pName, .Pcontact, .Vcontact, .Uncontact {
    padding: 0px 20px;
    padding-right: 20%;
}

.Pcontact .email{
    word-break: break-all
}
.Vcontact .email{
    word-break: break-all
}
.Uncontact .email{
    word-break: break-all
}

 .ppvExpertCardEliteDetails .contact .location, .phone, .email{
    display: flex;
    align-items: center;
    font-weight: 500;
    color: black;
}

.Pcontact .location, .phone, .email{
    display: flex;
    align-items: center;
    font-weight: 500;
    color: black;
}

.Vcontact .location, .phone, .email{
    display: flex;
    align-items: center;
    font-weight: 500;
    color: black;
}

.Uncontact .location, .phone, .email{
    display: flex;
    align-items: center;
    font-weight: 500;
    color: black;
}

.ppvExpertCardEliteDetails .contact, .Pcontact, .Vcontact{
    margin-top: 10px;
}

.ppvExpertCardEliteDetails .contact .contactImg{
    width: 40px;
    height: 40px;
    background: white;
    padding: 8px;
    margin: 5px 10px 5px 0px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
}
.pContactImg{
    border: 2px solid black;
    width: 40px;
    height: 40px;
    background: white;
    padding: 8px;
    margin: 5px 10px 5px 0px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
}

.ppvExpertCardElitebtn a.bdbtn {
    width: 200px;
    text-align: center;
    background: white;
    border-radius: 10px;
    padding: 5px 20px;
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    margin-left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ppvExpertCardElitebtn a.bdbtn:nth-child(2){
    margin-left: 10px;
}
.ppvExpertCardElitebtn a.bdbtn span img{ width: 15px; margin-right: 10px;}

.cardBtn-id1{
   
}
.cardBtn-id1 a.bdbtn span img{
    filter: invert(36%) sepia(92%) saturate(3306%) hue-rotate(1deg) brightness(103%) contrast(105%);
}
.cardBtn-id2 a.bdbtn span img{
    filter: invert(25%) sepia(18%) saturate(3678%) hue-rotate(269deg) brightness(97%) contrast(87%);
}
.cardBtn-id3 a.bdbtn span img{
    filter: invert(31%) sepia(57%) saturate(451%) hue-rotate(156deg) brightness(83%) contrast(86%);
}
.cardBtn-id4 a.bdbtn span img{
    filter: invert(39%) sepia(78%) saturate(554%) hue-rotate(159deg) brightness(95%) contrast(87%);
}
.cardBtn-id5 a.bdbtn span img{
    filter: invert(26%) sepia(97%) saturate(1287%) hue-rotate(112deg) brightness(97%) contrast(101%);
}
.cardBtn-id1 a{
    color: var(--orange); border: 3px solid var(--orange);
}

.cardBtn-id2{
    
}
.cardBtn-id2 a{
    color: var(--purple);border: 3px solid var(--purple);
}

.cardBtn-id5{
    
}
.cardBtn-id5 a{
    color: var(--green);border: 3px solid var(--green);
}

.cardBtn-id4{
    
}
.cardBtn-id4 a{
    color: var(--Bluetwo);border: 3px solid var(--Bluetwo);
}

.cardBtn-id3{
    
}
.cardBtn-id3 a{
    color: var(--Blueone);border: 3px solid var(--Blueone);
}



 .ppvExpertCardEliteUser{
    position: absolute;
    bottom: 0px;
    right: -78px;
    text-align: center;
}

 .ppvExpertCardEliteUser .userImage{
    border-radius: 100%;
    border: 5px solid white;
    width: 150px;
    box-shadow: 0px 0px 12px -5px black;
    height: 150px;
    object-fit: cover;

}


 .ppvExpertCardEliteUser .Eplanname{
    display: inline-block;
    padding: 3px 0px;
    /* background: #DAF3FF;
    border: 2px solid var(--Bluetwo); */
    border-radius: 50px;
    text-align: center;
    width: 100px;
    position: relative;
    padding-left: 20px;
    height: 30px;
    color: #000;
font-family: "Roboto Flex";
font-size: 13px;
font-weight: 600;
line-height: 22px;

}
.ppvExpertCardEliteUser .Eplanname:before{
    content: "";
	position: absolute;
	background: url(../../../assets/expertppvimage/elite_star.svg);
	width: 20px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 22px;
    top: 2px;
}
 .ppvExpertCardPremium .Eplanname:before{
    content: "";
	position: absolute;
	background: url(../../../assets/expertppvimage/diamond.svg);
	width: 17px;
    height: 17px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 12px;
    top: 6px;
}


/* Premium card style start here  */


.ppvExpertCardPremium {
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 410px;
    border-radius: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px 0px;
  
    position: relative;
}
.ppvExpertCardPremium:nth-child(odd){margin-right: 90px; margin-left: auto;}
.ppvExpertCardPremium:nth-child(even){margin-left: 0; margin-right: auto}

.ppvExpertCardPremium .ppvExpertCardEliteUser .userImage{
    border-radius: 100%;
    border: 5px solid white;
    width: 100px;
    box-shadow: 0px 0px 12px -5px black;
height: 100px;
object-fit: cover;
}

.PinsuranceBg {
    border: 5px solid var(--orange);
}

.PinvestmentBg {
    border: 5px solid var(--purple);

}

 .PrealEstateBg {
    border: 5px solid var(--green);

}

 .PbankingBg {
    border: 5px solid var(--Bluetwo);

}

.PmortgageBg {
    border: 5px solid var(--Blueone);

}

.ppvExpertCardPremium .ppvExpertCardEliteUser{
    position: absolute;
    text-align: center;
    bottom: 41px;
    right: -51px;
}

.PuserImageImg {
    border-radius: 100%;
    border: 5px solid white;
    width: 125px;
    box-shadow: 0px 0px 12px -5px black;
}

 .PplanName{
    display: inline;
    padding: 3px 15px;
    background: #DAF3FF;
    border: 2px solid var(--Bluetwo);
    border-radius: 50px;
    text-align: center;
    font-size: 14px;

}


.ppvExpertCardVerified {
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 350px;
    border: 5px solid #676767;
    border-radius: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px 0px;
    padding-bottom: 70px;
}

.hidebtn{
    display: none;
}



.ppvExpertCardVerifiedUser{
    position: absolute;
    margin-top: 80px;
    margin-left: 275px;
    text-align: center;
}

.VuserImageImg {
    border-radius: 100%;
    border: 5px solid white;
    width: 125px;
    box-shadow: 0px 0px 12px -5px black;
}

 .VplanName{
    display: inline;
    padding: 3px 15px;
    background: #fff;
    border: 2px solid #676767;
    border-radius: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0px 0px 10px -4px #676767 inset;

}


.VCategoryClass{
    background: #D9D9D9;
    color: #676767;
    padding: 5px 20px;
    font-weight: 600;
}

.ppvExpertCardUnverified {
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 350px;
    border: 5px solid #676767;
    border-radius: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px 0px;
}


 .ppvExpertCardUnverifiedUser{
    position: absolute;
    margin-top: 100px;
    margin-left: 275px;
    text-align: center;
}

.UnuserImageImg {
    border-radius: 100%;
    border: 5px solid white;
    width: 125px;
    box-shadow: 0px 0px 12px -5px black;
}

 .UnplanName{
    display: inline;
    padding: 3px 15px;
    background: #fff;
    border: 2px solid #676767;
    border-radius: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0px 0px 10px -4px #676767 inset;

}

.categoryhide{
    display: none;
}

.caution {
   padding: 10px 40px 20px 20px;
   

}
.caution p {
    font-size: 14px;
    color: red;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .ppvExpertCardElite, .ppvExpertCardPremium{margin: auto; overflow:visible;}
    .ppvExpertCardPremium:nth-child(odd){margin-left: auto;
        margin-right: auto;}
    
}
@media (max-width:767px){
    .cardbg-id1 .ppvExpertCardEliteUser .userImage{border:1px solid #FF4C00;}
    .cardbg-id2 .ppvExpertCardEliteUser .userImage{border:1px solid #86357A;}
    .cardbg-id3 .ppvExpertCardEliteUser .userImage{border:1px solid #006074;}
    .cardbg-id4 .ppvExpertCardEliteUser .userImage{border:1px solid #2280B8;}
    .cardbg-id5 .ppvExpertCardEliteUser .userImage{border:1px solid var(--green);}

    .ppvExpertCardPremium.cardbg-id1, .ppvExpertCardElite.cardbg-id1{box-shadow: 0px 2px 13.1px 0px #FF4C00;}
.ppvExpertCardPremium.cardbg-id2, .ppvExpertCardElite.cardbg-id2 {box-shadow: 0px 2px 13.1px 0px #86357A;}
.ppvExpertCardPremium.cardbg-id3, .ppvExpertCardElite.cardbg-id3 {box-shadow: 0px 2px 13.1px 0px #006074;}
.ppvExpertCardPremium.cardbg-id4, .ppvExpertCardElite.cardbg-id4 {box-shadow: 0px 2px 13.1px 0px #2280B8;}
.ppvExpertCardPremium.cardbg-id5, .ppvExpertCardElite.cardbg-id5 {
    box-shadow: 0px 2px 13.1px 0px rgba(0, 128, 62, 0.60);
}
.ppvExpertCardElite.cardbg-id1{border-radius: 20px;
    background: #FFF2EA;
    box-shadow: 0px 0px 8px 0px rgba(255, 76, 0, 0.35);
    border: none;}
.ppvExpertCardElite.cardbg-id2 {border-radius: 20px;
    background: #FFEFFF;
    box-shadow: 0px 0px 8px 0px rgba(134, 53, 122, 0.35);
    border: none;}
.ppvExpertCardElite.cardbg-id3 {border-radius: 20px;
    background: #EEF1F8;
    box-shadow: 0px 0px 8px 0px rgba(47, 89, 111, 0.35);
    border: none;}
.ppvExpertCardElite.cardbg-id4 {border-radius: 20px;
    background: #EDF9FF;
    box-shadow: 0px 0px 8px 0px rgba(34, 128, 184, 0.35);
    border: none;}
.ppvExpertCardElite.cardbg-id5 {
    border-radius: 20px;
    background: #EAFFEA;
    box-shadow: 0px 0px 8px 0px rgba(0, 128, 62, 0.35);
    border: none;
}

    .cardbg-id1 {
        background: #FFE3D0;
        border: none;
    
    }
    .cardbg-id2 {
        background: #FFD6F9;
        border: none;
    
    }
    .cardbg-id3 {
        background: #a8cde0;
        border: none;
    
    }
    .cardbg-id4 {
        background: #BFE7FF;
        border: none;
    }
    .cardbg-id5 {
        background: #D2FFD2;;
        border: none;
    
    }
    .ppvExpertCardPremium.cardbg-id1 {
        border: 5px solid var(--orange);
    }
   .ppvExpertCardPremium.cardbg-id2 {
        border: 5px solid var(--purple);
    }
    .ppvExpertCardPremium.cardbg-id3 {
        border: 5px solid var(--Blueone);
    }
    .ppvExpertCardPremium.cardbg-id4 {
        border: 5px solid var(--Bluetwo);
    }
    .ppvExpertCardPremium.cardbg-id5 {
        border: 5px solid var(--green);
    
    }
    .ppvExpertCardElite, .ppvExpertCardPremium{width: 100%; overflow: visible; margin: 100px 0;}
    .serviceoffer-inner .disgrid.grid-8{
        grid-template-columns: 1fr 1fr;
    }
 .ppvExpertCardEliteUser, .ppvExpertCardPremium .ppvExpertCardEliteUser {
        position: absolute;
        bottom: auto;
        text-align: center;
        top: -133px;
        left: 0;
        right: 0;
        margin: auto;
        width: 320px;
    }
    .ppvExpertCardPremium:nth-child(odd){margin-right: 0;}
    .ppvExpertCardEliteDetails{ margin-top: 40px;}
    .ppvExpertCardPremium .ppvExpertCardEliteUser .userImage,  .ppvExpertCardEliteUser .userImage{
        width: 65px;
        box-shadow: 0px 0px 12px -5px black;
        height: 65px;
   
    }
    .ppvExpertCardPremium .ppvExpertCardEliteUser, .ppvExpertCardEliteUser{
        top:-37px;
    }
    .ppvExpertCardEliteDetails .name, .contact{
        padding:0 10px;
    }
    .ppvExpertCardElite, .ppvExpertCardPremium{
        margin: 30px 0;
    }
    .ppvExpertCardEliteDetails h2, .ppvExpertCardPremium .ppvExpertCardEliteDetails h2{font-size: 16px;}
    .ppvExpertCardEliteDetails .category{padding: 5px 10px;        font-size: 12px;background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);}
    .contact {font-size: 13px; font-family: 'microsoft_sans_serifregular' !important;}
    .ppvExpertCardElitebtn{
 
        margin-top: 20px;
        margin-left: 17px;
     
    }
    .ppvExpertCardEliteDetails{margin-top: 14px;}
    .ppvExpertCardEliteUser .Eplanname{
        position: absolute;
        top: 23px;
        right: 54px;
        width: 75px;
        height: 25px;
        font-family: "Roboto Flex";
        font-size: 12px;
        padding-left: 4px;
        line-height: 18px;
    }
    .ppvExpertCardEliteUser .Eplanname:before{
        left: 7px;
        top: 4px;
        width: 13px;
        height: 14px;
    }
    .ppvExpertCardPremium .Eplanname{
        top: 21px;
        right: 54px;
        padding-left: 14px;
    }
    .ppvExpertCardPremium .Eplanname:before{
        left: 4px;
    }
    .ppvExpertCardEliteDetails .contact .contactImg{
        width: 20px;
        height: 20px;
        background: transparent;
        padding: 0px;
        margin: 5px 10px 5px 0px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
    }
    .cardbg-id1 .ppvExpertCardEliteDetails .contact .contactImg img{filter: invert(37%) sepia(96%) saturate(4102%) hue-rotate(5deg) brightness(107%) contrast(104%);}
    .cardbg-id2 .ppvExpertCardEliteDetails  .contact .contactImg img{filter: invert(27%) sepia(20%) saturate(3394%) hue-rotate(270deg) brightness(89%) contrast(87%);}
    .cardbg-id3 .ppvExpertCardEliteDetails .contact .contactImg img{filter: invert(28%) sepia(27%) saturate(883%) hue-rotate(156deg) brightness(98%) contrast(87%);}
    .cardbg-id4 .ppvExpertCardEliteDetails .contact .contactImg img{filter: invert(46%) sepia(17%) saturate(1968%) hue-rotate(159deg) brightness(91%) contrast(93%);}
    .cardbg-id5 .ppvExpertCardEliteDetails  .contact .contactImg img{filter: invert(20%) sepia(53%) saturate(4222%) hue-rotate(134deg) brightness(102%) contrast(101%);}
   .ppvExpertCardPremium .ppvExpertCardEliteDetails .contact .contactImg{
        width: 40px;
        height: 40px;
        background: white;
        padding: 8px;
        margin: 5px 10px 5px 0px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
    }
   .ppvExpertCardElitebtn a.bdbtn{
padding: 0;
        font-family: "Roboto Flex";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 2.5px;
        border: 0.5px solid #86357A;
        width: 95px;
        height: 25px;
        background: #FFF;}
     .cardBtn-id1 a.bdbtn{
        border-color: var(--orange); color:  var(--orange);
    }
    
     .cardBtn-id2 a.bdbtn{
        border-color:  var(--purple); color: var(--purple);
    }
    
    .cardBtn-id5 a.bdbtn{
        color: var(--green);border-color: var(--green);
    }
   
    .ppvExpertCardElite .cardBtn-id4 a.bdbtn{
        border-color: var(--Bluetwo);color: var(--Bluetwo);
    }
    .cardBtn-id3 a.bdbtn{
        border-color: var(--Blueone);    color: var(--Blueone);
    }
    .ppvExpertCardElitebtn a.bdbtn span img{ width: 12px; margin-right: 5px;}
    .ppvExpertCardElitebtn a.bdbtn{
        margin-left: 0px;
        margin-top: 0;
    }
}

