.main.entiredashboard.maincustomerdash .col-lg-3.col-sm-3:before {
  background-color: #449FD6;
}

.main.entiredashboard.maincustomerdash>.container>.row>.col-lg-3.col-sm-3 {
  background: #2280B8;
  margin: 0;
}


.dashboard-data-exp {
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;

}

.col-lg-9.col-sm-9.dashboard-data-exp {
  padding: 0 59px 0px 59px;
  display: block;
}

.dashboard-decoration {
  background: #E4EFFF;
  border-radius: 25px;
  box-shadow: 0px 0px 15.7px 0px #2280B880;
}

.add-expert {
  background: #FFE0FF;
  border-radius: 10px;
  padding-left: 20px;

}

.add-expert-data {
  padding-top: 22px;
  padding-bottom: 22px;
}

.add-expert-img {
  margin: 0;
}

.add-expert-img img {
  width: 100%;
  height: 100%;
  margin: 0px 0px 0px 12px;
}

.add-expert-title {
  font-size: 26px;
  font-weight: 700;
  color: #000;
  padding: 5px 0px;
}


.add-expert-subtitle {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.add-expert-limit {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 0px 20px 0px;
  height: 1px;
}

.add-expert-button {
  height: 44px; 
  border-radius: 3.32px;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  border: 1px solid transparent;
  width: 220px;
  backdrop-filter: blur(4px);
  background: #86357A;
}

.myexpert-nav {
  border: 2px solid #FFE0FF;
  border-radius: 60px;
  border: 1px 0px 0px 0px;
  background: #FFF;
  height: 60px;
  box-shadow: 0px 0px 5px 0px #00000040; 

}

.myexpert-nav.row {
  margin-top: 40px;
}

.registered-experts {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  background: #FFE0FF;
  border-radius: 60px 60px 60px 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partially-registered {
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.expert-section {  
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  position: relative;
}

.expert-profile {
  width: 140px;
  height: 140px;
  top: 819px;
  left: 536px;
  border: 5px; 
  border: 5px solid #034E7B4D;
  border-radius: 80px;
  align-content: center;
  margin: 0 auto;
  margin-top: -70px;
}

.expert-profile img {
  border-radius: 80px;
  width: 100%;
  height: 100%;
  border-radius: 50%;

}

.expert-name {
  color: #2280B8;
  font-size: 24px;
  font-weight: 700;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}


.expert-category {
  color: #06293F;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  height: 10px;
  margin-bottom: 20px;
}

ul li {
  list-style-type: none;
}

.expert-location {

  font-size: 16px;
  font-weight: 600;
  color: #06293F;

  margin: 10px -15px;
}

.expert-call {
  font-size: 13px;
  font-weight: 600;
  color: #06293F;
  margin: 10px -15px;
}

.expert-email {
  font-size: 13px;
  font-weight: 600;
  color: #06293F;
  margin: 10px 0px 25px -15px;
}

.view-profile-button {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
  background: #2280B8;
  border-radius: 6px;
  border: none;
  padding: 7px 60px;
}

.deactivate-button {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #2280B8;
  background: #FFFFFF;
  border-radius: 6px;
  border: 1px solid #2280B8;
  padding: 7px 60px;
  margin: 15px 0px 30px 0px;
  box-shadow: 3px 3px 33px 0px #00000040; 
}

.plan-name {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 16px;
  background: #2280B8;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 60px;
  margin-top: -164px;
  padding: 4px 35px;
}

.free-button {
  color: #06293F;
  font-weight: 500;
  font-size: 14px;
  background: #C9DAEA;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 107px;
  margin-left: 56%;
  padding: 4px 15px;
  display: table;
}
 
.expert-section.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
  gap: 50px;
  margin-top: 80px;
}
 
.expert-div {
  box-shadow: 3px 3px 33px 0px #00000040;
  border-radius: 20px;
  padding: 15px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-top: 65px;
  align-items: center;
  margin-bottom: 25px;
}
 
.expert-category, ul {
  width: 100%;
}