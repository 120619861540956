
section.homecustom.homecustomsearchfull .blog-upperstrip.blog-upperstriptrending, section.entireexpertppv.listofoffermainsection .blog-upperstrip.blog-upperstriptrending{
    display: flex;
    flex-wrap: wrap;
}
section.homecustom.homecustomsearchfull .bloginnertext, section.entireexpertppv.listofoffermainsection .bloginnertext {
    margin-top: 0;
    width: 100%;
    order: 2;
    margin-bottom: 0;
    min-height: auto;
}
section.homecustom.homecustomsearchfull .bloginnertag, section.entireexpertppv.listofoffermainsection .bloginnertag{display: none;}
section.homecustom.homecustomsearchfull .d-flex.upperstrip{order: 1; margin-bottom: 10px;}
.bloginnertagnew{display: none;}
section.homecustom.homecustomsearchfull .smbloginnerps, section.entireexpertppv.listofoffermainsection .smbloginnerps {
    position: relative;
}
a.pathpop {
    margin: 0 !important;
}
p.bloginnertextfe{
    color: #000;
} 
.categorysectionsix .bloginnertag{display: none;}
section.homecustom.homecustomsearchfull .bloginnertagnew, section.entireexpertppv.listofoffermainsection .bloginnertagnew {
    position: absolute;
    right: 20px;
    top: 10px;
    width: max-content;
    color: #000;
    font-family: "Microsoft Sans Serif";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #000;
    padding-bottom: 4px;
}
section.homecustom.homecustomsearchfull  .Mortgage .bloginnertagnew, section.entireexpertppv.listofoffermainsection  .Mortgage .bloginnertagnew{border-bottom: 1px solid #5C8092;}
section.homecustom.homecustomsearchfull  .Insurance .bloginnertagnew, section.entireexpertppv.listofoffermainsection  .Insurance .bloginnertagnew{border-bottom: 1px solid #FF550D;}
section.homecustom.homecustomsearchfull  .Real.Estate .bloginnertagnew, section.entireexpertppv.listofoffermainsection  .Real.Estate .bloginnertagnew{border-bottom: 1px solid #00831D;}
section.homecustom.homecustomsearchfull  .Investment .bloginnertagnew, section.entireexpertppv.listofoffermainsection  .Investment .bloginnertagnew{border-bottom: 1px solid #86357A;}
section.homecustom.homecustomsearchfull  .Banking .bloginnertagnew, section.entireexpertppv.listofoffermainsection  .Banking .bloginnertagnew{border-bottom: 1px solid #2884BB;}
section.homecustom.homecustomsearchfull .bloginnertagnew, section.entireexpertppv.listofoffermainsection  .bloginnertagnew {display: block;}

@media (min-width: 767px) {
    section.homecustom.homecustomsearchfull  .expertsection.martop30.mb-5 {
        margin-top: 0;
    }
.articlehading {
    text-align: center;
    padding-top: 30px; 
    padding-bottom: 10px; 
}
 .articlehading h2{
    color: #000;
text-align: center;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
 }

.articletitle{
    width: 95%;
    font-size: 24px;
    font-weight: 600; 
}

.articlesubtitle{
    width: 95%;
    font-size: 16px; 
}
}


@media (max-width: 767px) {
    section.homecustom.homecustomsearchfull .blog-upperstrip.blog-upperstriptrending .d-flex.upperstrip,
    section.entireexpertppv.listofoffermainsection .blog-upperstrip.blog-upperstriptrending .d-flex.upperstrip
    {display: none !important;
    }
    section.homecustom.homecustomsearchfull .col-2 {
        flex: 0 0 auto;
        width: 23.7777%;
    }
    section.homecustom.homecustomsearchfull .col-10 {
        flex: 0 0 auto;
        width: 73.333333%;
    }
    section.homecustom.homecustomsearchfull .blog-upperstrip.blog-upperstriptrending, section.entireexpertppv.listofoffermainsection .blog-upperstrip.blog-upperstriptrending
    {margin-top: 0; padding-top: 0;}
    section.homecustom.homecustomsearchfull  .smbloginnerps, section.entireexpertppv.listofoffermainsection  .smbloginnerps {padding:10px;}
    .upperstrip.upperstripmobile{margin-bottom: 10px;}
    .upperstrip.upperstripmobile .usertext .name, .upperstrip.upperstripmobile .usertext .date{color: #000;}
    .upperstrip.upperstripmobile .userimage{height: 32px; width: 32px;}
    section.homecustom.homecustomsearchfull .blog-image img {
        width: 85px;
        height: 120px !important;
        border-radius: 0;
        height: auto;
        object-fit: cover;
        box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
    }
    .articlehading {
        text-align: center;
        padding-top: 15px; 
        padding-bottom: 20px; 
    }
     
    
    .articlecard { 
        border-radius: 20px;
        padding: 10px 0px 10px 0px;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        margin: 20px 0px 20px 0px;  
        cursor: pointer;
    } 

    .articlecardimg img {
        border-radius: 100px;
        height: 70px;
        width: 70px;
        object-fit: cover; 
        display: flex;
        align-items: center;
        margin: 0 auto;
        justify-content: center;
    }
     
    
    .articletitle{
        width: 95%;
        font-size: 13px;
        font-weight: 600; 
    }
    
    .subtitle{
        width: 95%;
        font-size: 8px; 
        
    }
    .articlehading {
        text-align: center;
        padding-top: 0;
        padding-bottom: 0px;
    }
    .articlehading h2{color: #000;
        text-align: center;
      
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;}
    }
    