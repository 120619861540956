 

.gridblock {
    padding: 0 5px; 
    
}

.carddecoration {
    box-shadow: 0px 2px 10px 0px #0000001A;
    border-radius:  0px 15.55px  15.55px 0px;
}

.textalign {
    display: flex ;
    flex-direction: column;
    align-items: center;
   
}

.textalign h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #0a0a0a;  
    font-family: "Montserrat", sans-serif !important;
    margin-bottom: 0;
}
 .textalign h6 {
    font-size: 16px;
    font-weight: 500;
    color: #0a0a0a; 
    text-align: center;
    margin-bottom: 20px;
    line-height: 20px;
    font-family: "Montserrat", sans-serif !important;
}
.margintop{
    margin-top: 20px;
}
@media (max-width:767px){
    .textalign h2
    {
        font-size: 18px;
font-style: normal;
font-weight: 700;
    }
    .textalign h6{
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        font-family: "SF Pro Display" !important;
        max-width: 90%;
        margin: auto;
        margin-top: 5px;
    }
    .textalign{
        display: block;
        flex-direction: row;
        align-items: center;
        text-align: center;
    }
}