 .offer-inner {
    margin: 0 0 10px;
}
 .offer-inner {
    padding: 0;
}
.offer-inner {
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, .25);
    border-radius: 10px;
    padding: 10px;
}
 .slick-slide img {
    display: block;
    border-radius: 10px;
    height: 300px;
    object-fit: contain;
    width: 100%;
}
.offer-inner .categotyinpop {
    padding: 9px 11px;
    width: 100%;
    position: relative;
    z-index: 9;
}
.offer-inner .categotyinpopfl {
    justify-content: space-between;
}
 .list-des.offerdes, .offer-inner .categotyinpopfl .pscat {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.71px;
    font-family: "microsoft_sans_serifregular";
}

.offer-inner .categotyinpopfl .pscat {
    color: #000;
    font-family: Roboto;
    margin-bottom: 18px;
    position: relative;
}
 .slick-slide .pscat span img {
    display: inline-block !important;
    width: 18px !important;
    height: auto !important;
}
.list-des.offerdes, .offer-inner .categotyinpopfl .pscat {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.71px;
    font-family: "microsoft_sans_serifregular";
}

.categotyinpopfl .pscat span img {
    display: inline-block ;
    width: 18px ;
    height: auto ;
}
/* .d-flex{
        display: flex !important

} */
 .offer-inner .list-name.disfontsmall {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0;
    background: transparent;
    border-radius: 0;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
 .list-des.offerdes {
    color: #747474;
    min-height: 50px;
    background: transparent;
    padding: 0;
    border-radius: 0;
    margin: 5px 0 0;
}
 .list-des.offerdes, .offer-inner .categotyinpopfl .pscat {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.71px;
    font-family: "microsoft_sans_serifregular";
}
 .posbuttontf {
    padding: 0;
    margin: 15px 0 0;
    justify-content: left;
}
.applynowbuttoncomm {
    border: 1.06px solid;
    /* padding: 10px 21px; */
    border-radius: 5px;
    text-decoration: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.09px;
    font-family: "Roboto Flex", sans-serif;
    width: 140px;
height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.Real.Estate .categotyinpopfl .pscat:after {
    background: #00831d;
}
.Investment .categotyinpopfl .pscat:after {
    background: var(--purple);
}
.Mortgage .categotyinpopfl .pscat:after {
    background: var(--Blueone);
}
.Insurance .categotyinpopfl .pscat:after {
    background: var(--orange);
}
.Banking .categotyinpopfl .pscat:after {
    background: var(--Bluetwo);
}
@media (max-width:767px){
    .categotyinpopfl .pscat span img{width: 13px;}
    .offer-inner .list-name.disfontsmall{font-size: 12px;} 
    .list-des.offerdes, .list-des.offerdes{font-size: 12px;}  
    .offer-inner .categotyinpopfl .pscat{font-size: 12px; font-family: "Montserrat", sans-serif !important;}
    .applynowbuttoncomm{
        text-align: center;
font-family: "Roboto Flex";
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 1.2px;
width: 90px;
height: 25px;
border-radius: 2px;
display: flex;
justify-content: center;
align-items: center;
    }
}
