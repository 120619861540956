
.bankingnewui .categorybanner{background: url(../../../assets/bankingimges/Banking_back.svg);}
.bankingnewui .categorybanner .bn-image img {
    height: 431px;
    margin-top: 20px;
}
.bankingnewui  .bannerfont h2, .bankingnewui .applynowbuttoncomm {
    color: var(--Bluetwo);}
.bankingnewui .applynowbuttoncomm{border-color: var(--Bluetwo); background: #DAF2FF;}
.bankingnewui .categorysectionone .offer-inner .categotyinpopfl .pscat:after{ background: var(--Bluetwo);}
.bankingnewui .categorysectiontwo, .bankingnewui .categorysectionthree{
    background: url(../../../assets/bankingimges/Productsbg.png);
    
}
.bankingnewui .viewdetailsbuttoncomm{background: var(--Bluetwo)}
.bankingnewui  .bannerfont h2:after{background: var(--Bluetwo)}
.bankingnewui .Expertsbutton{background: #DAF2FF;}
.bankingnewui .viewdetailsbuttoncomm:hover {
    color: var(--Bluetwo);
    background-color: #DAF2FF;
    cursor: pointer;
    border-color: var(--Bluetwo);
}
.bankingnewui  .bannerfont.borderbottom span {
    color: var(--Bluetwo);}
/* .bankingnewui .categorysectionthree span.canada{color: var(--Bluetwo);} */
.bankingnewui .categorysectionfour .Expertstop img{border-color: #DAF2FF;}
.bankingnewui .messagemebuttoncomm{background: var(--Bluetwo);}
.bankingnewui .categorysectionone .slick-prev:before, .bankingnewui .categorysectionone .slick-next:before, .bankingnewui .categorysectiontwo .slick-prev:before, .bankingnewui .categorysectiontwo .slick-next:before, .bankingnewui .categorysectionthree .slick-prev:before, .bankingnewui .categorysectionthree .slick-next:before, .bankingnewui .categorysectionfour .slick-prev:before, .bankingnewui .categorysectionfour .slick-next:before
{color: var(--Bluetwo);}
.bankingnewui .categorysectionsix.blogdetailsexpertppv{background: url(../../../assets/bankingimges/blogsbg.png);}

.banking-bgimg{
    background: url(../../../assets/bankingimges/Productsbg.png);
}