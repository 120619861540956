.product-tabs-container {
  display: flex;
  flex-direction: row;
}

/* .active.Real.Estatebg .Real.Estateps {
  color: white;
  display: inline-block;
  background-color:#00831d ;
  border-radius: 8px;
  margin: 0;
  padding: 0px 31px 0px 31px;
  border-bottom: none;
  position: relative;

}
.active.Real.Estatebg .Real.Estatep:before {
  position: absolute;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 20px solid #00831d;
  content: "";
  top: 23px;
  right: -19px;
} */
.categories-tabs {
  width: 200px;
  background-color: #f5f5f5;
  padding: 10px;
}

.category-tab {
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
}

.category-tab.active {
  background-color: #007bff;
  color: white;
}

.subcategories-tabs {
  width: 200px;
  background-color: #f0f0f0;
  padding: 10px;
}

.subcategory-tab {
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
}

.subcategory-tab.active {
  background-color: #007bff;
  color: white;
}

.products-list {
  flex-grow: 1;
  padding: 0;}

.tabmain .products-list{
  padding-left: 30px;
  width: 70%;
}
.product-item {
  margin-bottom: 20px;
}

.product-item img {
  max-width: 100px;
  margin-top: 10px;
}
 .Mortgageborder:before{
  background:   #CED5E5;

}

.Bankingborder:before {
  background:  #63CCFF;
}
.Bankingborder:last-child:before{
  display: none;
}
.Investmentborder:before {
  background:  #F5B9ED;
}
.Investmentborder:last-child:before{
  display: none;
}
.Insuranceborder:before  {
  background:  #FFB08E;
}
.Insuranceborder:last-child:before  {
  background:  #FFB08E;
}
.realestateborder:before  {
  background:  #00831D;
}
.realestateborder:last-child:before  {
  background:  #FFB08E;
}


.product-tabs-container ul .active {

  /*border: 1px solid #000000;
  margin-bottom: -1px;
   clip-path: polygon(20% 0, 80% 0, 100% 100%, 0 100%); 
  border-radius: 5px 5px 0 0 ; 
  /* outline: 1px solid #000000;  */
  padding: 0px;
  text-align: center;
  display: inline-block;
  position: relative;
  border-bottom: none;
}
.tabmain .categoryproductsliderinner {
  /* margin: 0 !important;
  width: 48%;
  display: inline-block;
  margin-bottom: 20px !important;
  margin-left: 5px !important;
  margin-right: 5px !important; */

}
.tabmain .sticky-top {
  position: sticky;
  width: 30%;
  z-index: 1;
  top: 80px;
}
.sticky-offset{
  top:60px;
  margin-bottom: -250px;
}
.tabmain .categoryproductsliderinner {background: #fff; }
.tabmain .categoryproductsliderinner  .categoryproductsliderinnerimage{
  border: none;
  filter: none;
  background: #fff;
  margin: 0;
}
.product-tabs-container ul .active .catext{color: #fff !important; position: relative ; z-index: 9;}
.product-tabs-container ul .active>div{
background: url(../../../assets/Instituteppvimages/image_specialization.svg);
background-size: cover; 
background-repeat: no-repeat;
position: relative;
padding: 5px 0;
}
.product-tabs-container ul .active:before{
  position: absolute;
  background: url(../../../assets/Instituteppvimages/image_specialization.svg);
  content: "";
  width: 211px;
    height: 47px;
    left: -1px;
    right: 0px;
    margin: auto;
    top: 4px;
    bottom: 6px;
    background-size: cover;
    background-repeat: no-repeat;
}
.product-tabs-container ul .parentInstitutionCategory-id4.active:before{width: 213px;}
.product-tabs-container ul .active.parentInstitutionCategory-id3>div
{
  filter: invert(92%) sepia(10%) saturate(266%) hue-rotate(190deg) brightness(101%) contrast(95%);
  border: none;
}
.product-tabs-container ul .active.parentInstitutionCategory-id1>div
{
  filter: invert(84%) sepia(19%) saturate(381%) hue-rotate(324deg) brightness(108%) contrast(104%);border: none;
}
.product-tabs-container ul .active.parentInstitutionCategory-id2>div
{
  filter: invert(88%) sepia(19%) saturate(367%) hue-rotate(282deg) brightness(104%) contrast(105%);border: none;
}
.product-tabs-container ul .active.parentInstitutionCategory-id4>div
{
  filter: invert(95%) sepia(94%) saturate(2647%) hue-rotate(176deg) brightness(106%) contrast(103%);border: none;
}
.product-tabs-container ul .active.parentInstitutionCategory-id5>div
{
  filter: invert(92%) sepia(3%) saturate(2874%) hue-rotate(67deg) brightness(106%) contrast(101%);border: none;
}
.tabmain.parentInstitutionCategory-id1 .viewbutton:hover{
  background-color:var(--orange);
  color: #fff;
}
.tabmain.parentInstitutionCategory-id2 .viewbutton:hover{
  background-color:var(--purple);
  color: #fff;
}
.tabmain.parentInstitutionCategory-id3 .viewbutton:hover{
  background-color:var(--Blueone);
  color: #fff;
}
.tabmain.parentInstitutionCategory-id4 .viewbutton:hover{
  background-color:var(--Bluetwo);
  color: #fff;
}
.tabmain.parentInstitutionCategory-id5 .viewbutton:hover{
  background-color:var(--green);
  color: #fff;
}
.tabmain.parentInstitutionCategory-id3   {
  background-color: var(--lightBlueone);
}
.tabmain.parentInstitutionCategory-id1 {
  background-color: var(--lightorange);
}
.tabmain.parentInstitutionCategory-id2 {
  background-color: var(--lightpurple);
}
.tabmain.parentInstitutionCategory-id4 {
  background-color: var(--lightBluetwo);
}
.tabmain.parentInstitutionCategory-id5 {
  background-color: var(--lightgreen);
}
.tabmain .viewbutton{margin-bottom: 20px;}
.tabmain.parentInstitutionCategory-id1 .viewbutton {
  background-color: var(--lightorange);
  border-color: var(--orange);
  color:  var(--orange);
}
.tabmain.parentInstitutionCategory-id2 .viewbutton {
  background-color: var(--lightpurple);
  border-color: var(--purple);
  color:  var(--purple);
}
.tabmain.parentInstitutionCategory-id3 .viewbutton {
  background-color: var(--lightBlueone);
  border-color: var(--Blueone);
  color:  var(--Blueone);
}
.tabmain.parentInstitutionCategory-id4 .viewbutton {
  background-color: var(--lightBluetwo);
  border-color: var(--Bluetwo);
  color:  var(--Bluetwo);
}
.tabmain.parentInstitutionCategory-id5 .viewbutton {
  background-color: var(--lightgreen);
  border-color: var(--green);
  color:  var(--green);
}
.categoryproductsliderinner{}
.Insuranceborder:has( + .Insuranceborder.active):after {display: none !important;}
.Investmentborder:has( + .Investmentborder.active):after {display: none !important;}
.Mortgageborder:has( + .Mortgageborder.active):after {display: none !important;}
.Bankingborder:has( + .Bankingborder.active):after {display: none !important;}
.Real.Estateborder:has( + .Real.Estateborder.active):after {display: none !important;}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
  .categorytab_name{width: 180px;}
  .product-tabs-container ul .active:before, .product-tabs-container ul .parentInstitutionCategory-id4.active:before{
    width:170px
  }
  .product-tabs-container ul .active>div{
    background-size: contain;
  }
  .product-tabs-container ul .active:before{background-size: contain;}
  .tabmain .sticky-top {
    position: relative;
    width: 30%;
    z-index: 1;
    top: 00px;
}
.sticky-offset{margin-bottom: 0; height: 100% !important;}
}
@media (max-width:767px){
  .product-tabs-container ul .active>div{background: none;}
  .categorytab_name{
    width: 76px;
    height: 70px;
    background: #C7C7C7;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }
  .product-tabs-container ul .active:before{
    background: none;
  }
  .tabmain.parentInstitutionCategory-id3   {
    background-color: transparent;
  }
  .tabmain.parentInstitutionCategory-id1 {
    background-color: transparent;
  }
  .tabmain.parentInstitutionCategory-id2 {
    background-color: transparent;
  }
  .tabmain.parentInstitutionCategory-id4 {
    background-color: transparent;
  }
  .tabmain.parentInstitutionCategory-id5 {
    background-color: transparent;
  }
  .tabmain .sticky-top {
    position: relative;
    width: 100%;
    z-index: 1;
    top: 00px;
    height:auto !important;
}
.tabmain .products-list {
  padding-left: 0px;
  width: 100%;
}
.sticky-offset{margin-bottom: 0;}




.parentInstitutionCategory-id3 .categoryproductsliderinner   {
  border-bottom: 1px solid #2F596F;
  background: #F2F4F9;
  box-shadow: 0px 0px 9.44px 0px rgba(0, 0, 0, 0.25);
}
.parentInstitutionCategory-id1 .categoryproductsliderinner {
  border-bottom: 1px solid #FF4C00;
  background: #FFF5F1;
  box-shadow: 0px 0px 9.44px 0px rgba(0, 0, 0, 0.25);
}
.parentInstitutionCategory-id2 .categoryproductsliderinner {
  border-bottom: 1px solid #86357A;
background: #FFF4FE;
box-shadow: 0px 0px 9.44px 0px rgba(0, 0, 0, 0.25);
}
.parentInstitutionCategory-id4 .categoryproductsliderinner {
  border-bottom: 1px solid #2280B8;
background: #EFF9FF;
box-shadow: 0px 0px 9.44px 0px rgba(0, 0, 0, 0.25);
}
.parentInstitutionCategory-id5 .categoryproductsliderinner {
  border-bottom: 1px solid #00831D;
background: #F5FFF5;
box-shadow: 0px 0px 9.44px 0px rgba(0, 0, 0, 0.25);
}
.product-tabs-container ul .active.parentInstitutionCategory-id4>div,
.product-tabs-container ul .active.parentInstitutionCategory-id5>div,
.product-tabs-container ul .active.parentInstitutionCategory-id3>div,
.product-tabs-container ul .active.parentInstitutionCategory-id2>div,
.product-tabs-container ul .active.parentInstitutionCategory-id1>div{
  filter: invert(0);
}
.caticonpoptext{font-size: 0;        position: relative;
  z-index: 9;}
.caticonpoptext::first-letter{
  color: #FFF;
text-align: center;
text-shadow: 0.347px 0.924px 1.965px rgba(0, 0, 0, 0.25);
font-family: "Red Hat Display";
font-size: 40px;
font-weight: 700;
line-height: 1;
}
span.caticonpop {
  position: relative;
  top: 0;
  bottom: 0;
  opacity: 1;
  left: 0;
  right: 0;
  margin: auto;
}
span.caticonpop img {
  filter: invert(70%) sepia(0%) saturate(5526%) hue-rotate(315deg) brightness(86%) contrast(93%);height: 35px;
}
.active.ppvproducttab span.caticonpop img{filter: invert(1);}
.active.ppvproducttab:after{content: '';
position: absolute;
left: 0;
right: 0;
margin: 0 auto;
width: 0;
height: 0;
border-top: 11px solid #2F596F;
border-left: 8px solid transparent !important;
border-right: 8px solid transparent;
bottom: -9px;
top: auto;
}
.active.parentInstitutionCategory-id4.ppvproducttab
{background: linear-gradient(180deg, #2280B8 0%, #A3DCFF 100%);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;}
  .active.parentInstitutionCategory-id4.ppvproducttab:after{border-top: 11px solid #2280B8;}
  .active.parentInstitutionCategory-id1.ppvproducttab:after{border-top: 11px solid #FF4C00;}
  .active.parentInstitutionCategory-id2.ppvproducttab:after{border-top: 11px solid #86357A;}
  .active.parentInstitutionCategory-id3.ppvproducttab:after{border-top: 11px solid #2F596F;}
  .active.parentInstitutionCategory-id5.ppvproducttab:after{border-top: 11px solid #00831D;}
  
.active.parentInstitutionCategory-id1.ppvproducttab{background: linear-gradient(180deg, #FF4C00 0%, #FFA37C 100%);
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;}
.active.parentInstitutionCategory-id3.ppvproducttab{background: linear-gradient(180deg, #2F596F 0%, #99ADB8 100%);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;}
.active.parentInstitutionCategory-id2.ppvproducttab{background: linear-gradient(180deg, #86357A 0%, #FFABF3 100%);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;}
.active.parentInstitutionCategory-id5.ppvproducttab{
  background: linear-gradient(0deg, #5BC272 0%, #00831D 100%);
          box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;}
          .tabmain .iconone {
            background: #fff;
            width: 35px;
            height: 35px;
            border-radius: 100px;
            margin: 3px auto;
            position: relative;
        }
        .tabmain .iconone img {
          width: 20px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
      }
      .ss-cat{
        display: block;
        width: 100%;
      }.tabmain .categoryproductsliderinner { margin-bottom: 0 !important;}
      .showallourbesttwo .showallourbesttwo a.showallbtn.mt-4.mb-3 {
        margin-top: 0 !important;
    }
    .showallourbesttwo.text-center.mt-0.mb-5{margin-bottom: 0 !important;}
}