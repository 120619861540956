/* .investmentnewui .categorybannertext {
    position: absolute;
    left: auto;
    bottom: 0;
    width: 50%;
    text-align: left;
    height: 606px;
    top: 0;
    margin: auto;
    right: auto;
} */
.investmentnewui .categorybanner{background: url(../../../assets/investmentimages/Investment_back.svg);}
.investmentnewui .categorybanner .bn-image img {
    height: 400px;
    margin-top: 40px;
}
.investmentnewui .bannerfont h2, .investmentnewui .applynowbuttoncomm {
    color: var(--purple);}
    .investmentnewui  .bannerfont p{max-width: 100%;}
.investmentnewui .applynowbuttoncomm{border-color: var(--purple); background: #FFEAFF;}
.investmentnewui .categorysectionone .offer-inner .categotyinpopfl .pscat:after{ background: var(--purple);}
.investmentnewui .categorysectiontwo, .investmentnewui .categorysectionthree{
    background: url(../../../assets/investmentimages/Productsbg.png);
    
}
.investmentnewui .viewdetailsbuttoncomm{background: var(--purple)}
.investmentnewui  .bannerfont h2:after{background: var(--purple)}
.investmentnewui .Expertsbutton{background: #FFD1F8;}
.investmentnewui .viewdetailsbuttoncomm:hover {
    color: var(--purple);
    background-color: #FFEAFF;
    cursor: pointer;
    border-color: var(--purple);
}
.investmentnewui  .bannerfont.borderbottom span {
    color: var(--purple);}
/* .investmentnewui .categorysectionthree span.canada{color: var(--purple);} */
.investmentnewui .categorysectionfour .Expertstop img{border-color: #FFD1F8;}
.investmentnewui .messagemebuttoncomm{background: var(--purple);}
.investmentnewui .categorysectionone .slick-prev:before, .investmentnewui .categorysectionone .slick-next:before, .investmentnewui .categorysectiontwo .slick-prev:before, .investmentnewui .categorysectiontwo .slick-next:before, .investmentnewui .categorysectionthree .slick-prev:before, .investmentnewui .categorysectionthree .slick-next:before, .investmentnewui .categorysectionfour .slick-prev:before, .investmentnewui .categorysectionfour .slick-next:before
{color: var(--purple);}
.investmentnewui .categorysectionsix.blogdetailsexpertppv{background: url(../../../assets/investmentimages/blogsbg.png);}
.Investment .applynowbuttoncomm {
    color: var(--purple);
    border-color: var(--purple);
    background: #ffeaff;
}

.investment-bgimg {
    background: url(../../../assets/investmentimages/Productsbg.png);
}