@media (max-width:767px){
    .expertsearchmain .herotext {
        left: 0;
        right: auto;
        text-align: left;
        bottom: 0;
    }
    .searchcustom .Expertsbuttoni{display: flex; justify-content: center;}
   .searchcustom .exclusive .slick-prev, .searchcustom .exclusive .slick-next {
        top: auto;
        bottom: -32px;
    }
    .searchcustom  .Expertsbuttoni{flex-direction: row; margin-bottom: 10px;}
 
    .searchcustom .topproduct .categoryproductsliderinner{margin-bottom: 50px !important;}
    .herotext h3 {
        color: #005C70;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 1;
    }
    .hero_subtext2 h2 , .hero_subtext2 strong{
        color: #000000;
        margin-top: 10px;
        font-size: 14px;
    }
    .hero_subtext3 strong {
        color: #000000;
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
    .hero_subtext3 h5{ font-size: 12px;}
    .hero_subtext h2, .hero_subtext strong{font-size: 20px !important;
        line-height: 1 !important;
    max-width: 100% !important;
    align-items: start !important;
}
.hero_subtext2 h3:after{width: 100% !important;}
}