.dahsboardTitle {
  margin: 20px 0 5px auto;
  font-weight: 700;
  font-size: 30px;
  color: #000;
}


.dahsboardSubTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000;
  margin: 0px 0 0px auto;
}
.main_institute_dashboard .dashboardrightpanel section.offer-list .dasof
{
  display: none;
}.dashBoardLogout {
  font-weight: 700;
  font-size: 13px;
  color: #FFFFFF;
}


.logoutbutton {
  border-radius: 8px;
  background: #2280B8;
  width: 65px;
  height: 70px;
  margin: 32px 0 0 auto;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column; 
}


.logoutImg {
  padding-top: 15%;
  padding-bottom: 7%;
  width: 42%;
  filter: brightness(100);
}

.dashboard-decoration {
  background: #E4EFFF;
  border-radius: 25px;
  box-shadow: 0px 0px 15.7px 0px #2280B880;
}


.dashboard-data-inst {
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column; 

}

.col-lg-9.col-sm-9.dashboard-data-inst {
  padding: 0 55px;
  display: block;
}


.dashboardsliderdata {
  background: linear-gradient(180deg, #2280B8 0%, #1B96DF 17.24%, #2280B8 36.05%, #1B96DF 54.4%, #2280B8 77.2%, #1B96DF 100%);
  box-shadow: 5px 5px 25px rgb(6 40 61 / 20%);
  border-radius: 20px;
  padding: 10px 0 0 70px;
}

.dashboars-liveoffer {
  font-weight: 700;
  font-size: 32px;
  color: #FFFFFF;
  height: 40px;
}

.imagecontainer {
  width: 300px;
  height: 551px;
}




/* .dashboardsliderdata .awssld__wrapper .awssld__controls__arrow-right{
  opacity: 0;
} */
.dashboardsliderdata .awssld__content {
  align-items: unset
}


.descriptionTitle {
  font-size: 17px;
  font-weight: 700;
  color: #FFFFFF;
  margin: 0 auto;
}

.description-card {
  padding-top: 3px;
}

.descriptionoffer {
  font-size: 17px;
  font-weight: 400;
  color: #FFFFFF;
}

.offer-name {
  padding: 5px 30px;
  margin: 20px 0;
  font-weight: 700;
  font-size: 32px;
  background: #E4EFFF;
  border-radius: 35px 0 0 35px;
  height: 61px;
}

.related-products p {
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF;
  border-radius: 90px;
  border: 1px solid #FFFFFF;
  background: #003757;
  text-align: center;
  width: 286px;
  height: 47px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 33px 0px #00000040;

}

.related-products {
  padding-bottom: 8px;
}

.valid-products p {
  font-size: 20px;
  font-weight: 600;
  color: #003757;
  border-radius: 90px;
  border: 1px solid #003757;
  background: #FFFFFF;
  text-align: center;
  width: 286px;
  height: 47px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 33px 0px #00000040;
  margin-bottom: 22px;
}

.expertData p {
  color: #FFFFFF;
}

.expertData img {
  filter: brightness(100);
}

.dashboardblog {
  box-shadow: var(--sds-size-depth-0) 0px 7px 0px #2280B8;
  background: #D2FFD2;
  border-radius: 24px;
  padding: 5px 20px 20px 20px;
  color: #FFFFFF;
  
}

.dashboardexpert{
  margin-top: 33px;
  margin-bottom: 33px;
}
 

.expert-all-blog {
  /* border: 8px solid #449FD6; */
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.expert-blog {
  background: #FFFFFF; 
  width: 50%;
  margin: 0;
  padding: 0;
  border-radius: 10px;
}


.expert-blog-img img {
  width: 120px;
  height: 120px;
  border-radius: 150px;
  display: flex;
  margin: 15px auto;
  align-items: center;
  justify-content: center;
  resize: inherit;
}


.expert-blog-title {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin: 0 20px;
}

.expert-blog-description {
  font-size: 11px;
  font-weight: 500;
  color: #000;
  text-align: center;
  margin: 10px 20px;
}

.expert-blog-catgeory {
  background: #2280B8;
  width: 100px;
  height: 30px;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  border-radius: 6px;
  display: inline-block;
  display: flex;
  margin: 18px auto 30px auto;
  align-items: center;
  justify-content: center;
  color: #FFF;
}


.pagination-center {
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  resize: inherit;
}


.dashboardsliderdata .awssld__content img {
  height: -webkit-fill-available;
  padding-bottom: 0;
}
 

.main.entiredashboard.maincustomerdash .col-lg-3.col-sm-3:before {
  background-color: #449FD6;
}

.main.entiredashboard.maincustomerdash>.container>.row>.col-lg-3.col-sm-3 {
  background: #2280B8;
}


.main .slick-prev:before {
	content: "";
	background: url(../../../../assets/homeimages/slicknext.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	rotate: 180deg;
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
}

.main .slick-prev:hover:before {
	opacity: 1;

}

.main .slick-next:before {
	content: "";
	background: url(../../../../assets/homeimages/slicknext.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	rotate: 0deg;
	border-radius: 3px;
	display: block;
	position: absolute;
	left: 1px;
	top: 0;
	opacity: 0.3;
}


.main .slick-next:hover:before {
	opacity: 1;

}

.dashboardsliderdata {
  width: 55em;
}

.dashboard-data-inst {
  padding: 0px 73;
}

.empthy-blog{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
}

.empthy-blog img
{
  filter: invert(0%) sepia(100%) saturate(1) hue-rotate(128deg) brightness(100%) contrast(100%);
  margin-bottom: 15px;
}

section.entiredashboard.maincustomerdash.main {
  position: relative;
  margin-top: 25px;
}

section.offer-list{
margin: 10px 100px;
}
 
.blog-margin{
  padding: 8px;
}