.p_slider.product_inner .sldeimage.product_image img:hover {
	box-shadow: 0 0 12px 2px #FFFFFF;
	cursor: pointer;
}

.p_slider.product_inner .sldeimage.product_image img, .p_slider.offer_inner .sldeimage.offer_image img {
	border: 7px solid #fff;
	height: 100%;
	object-fit: contain;
	width: 100%;
	background: #fff;
}


.exclusive-slider .p_slider {
	padding: 0 10px;
}

.p_slider.product_inner, .p_slider.offer_inner {
	display: flex;
	align-items: center;
	margin: 0 auto;
	justify-content: center;
}

.p_slider.product_inner img {
	border: 11px solid #fff;
}

.topproduct .p_slider, .exclusive .p_slider {
	background: transparent;
	position: relative;
	padding-top: 50%;
}

.slbtn.slidertext-pp.offertext {
	margin-top: 0;
	margin-bottom: 0;
	max-width: 609px;
	min-height: 0px;
}

.ctaonebutton {
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
}

.ctaonebutton:hover {
	background-color: #0056b3;
}

.ctanewtwobutton {
	background-color: #28a745;
}

.ctanewtwobutton:hover {
	background-color: #218838;
}

.sliouter .productcatname {
	font-size: 12px;
	color: #000000;
	font-weight: 400;
	margin-block: 10px;
	text-align: center;
	font-family: 'microsoft_sans_serifregular' !important;
}


.sliouter .p_slider h5 {
	color: #00831D;
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.sliouter .Investment .p_slider h5 {
	color: #86357A;
}

.sliouter .Mortgage .p_slider h5 {
	color: #2F596F;
}

.sliouter .Insurance .p_slider h5 {
	color: #FF4C00;
}

.sliouter .Banking .p_slider h5 {
	color: #2280B8;
}

.sliouter button.ctaonebutton.ctanewtwobutton {
	border-radius: 6px;
	background: #D2FFD2;
	width: 200px;
	height: 45px;
	color: #00831D;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	border: 1.603px solid #00831D;
}

.sliouter.Investment .Investment .p_slider button.ctaonebutton.ctanewtwobutton {
	color: #86357A;
	border: 1.603px solid #86357A;
	background: #FFE0FF;
}

.sliouter.Mortgage .Mortgage .p_slider button.ctaonebutton.ctanewtwobutton {
	color: #2F596F;
	border: 1.603px solid #2F596F;
	background: #DBE1EE;
}

.sliouter.Insurance .Insurance .p_slider button.ctaonebutton.ctanewtwobutton {
	color: #FF4C00;
	border: 1.603px solid #FF4C00;
	background: #FFE3D7;
}

.sliouter.Banking .Banking .p_slider button.ctaonebutton.ctanewtwobutton {
	color: #2280B8;
	border: 1.603px solid #2280B8;
	background: #D8EDFF;
}

.sliouter .slbtn .d-flex {
	justify-content: center;
}

.sliouter .p_slider {
	background: transparent;
	border-radius: 0;
	padding: 0;
}

.sliouter .sldeimage {
	border: 7px solid #fff;
	filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.15));
}

.sliouter .p_sliderouter {
	filter: none;
	margin: 30px 0;
	padding: 14px 25px;
}

.parentcatname {
	color: #00831D;
	text-align: center;
	font-family: Montserrat;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	border-radius: 3.673px;
	background: #FFF;
	width: 200px;
	padding: 5px;
	margin: auto;
	position: absolute;
	top: 18px;
	left: 0;
	right: 0;
	filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.15));
	 
}

/* .sliouter .Investment .parentcatname{color: #86357A;}
.sliouter .Mortgage .parentcatname{color: #2F596F;}
.sliouter .Insurance .parentcatname{color: #FF4C00;}
.sliouter .Banking .parentcatname{color: #2280B8;}
.sliouter .slbtn{margin: 20px 0;} */

.Investment {
	color: #86357A;
	border: 1.603px solid #86357A;
	background: #FFE0FF;
}

.Mortgage {
	color: #2F596F;
	border: 1.603px solid #2F596F;
	background: #DBE1EE;
}

.Insurance {
	color: #FF4C00;
	border: 1.603px solid #FF4C00;
	background: #FFE3D7;
}

.Banking {
	color: #2280B8;
	border: 1.603px solid #2280B8;
	background: #D8EDFF;
}


.instititeproduct {
	box-shadow: 1.83px 1.83px 20.12px 0px #00000040;
	background: #FFFFFF;
	border-radius: 10px; 
	margin:0px 5px 40px 10px;
}

.instititeimg {
	box-shadow: 0px 4px 4px 0px #00000040;
	border-radius: 10px;

}

.productnamecenter {
	font-size: 20px;
	font-weight: 700;
	color: #000000;
	margin: 20px 0px 0px 0px;
	display: flex;
	justify-content: center;
}

.applynowbuttoncomm {
	border: 1px solid;
	padding: 10px 21px;
	border-radius: 5px;
	text-decoration: none;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px;
	font-family: "Roboto Flex", sans-serif;
	margin: 25px auto;
	display: flex;
	justify-content: center;
}

.subcategoryproduct{
	margin-top: 50px;
}
 
.sybcategotysection{
	margin-top: 50px;
}
 

.image-decoration
{
	border: 7px solid #fff;
	filter: drop-shadow(0 7px 5px rgba(0, 0, 0, .15));
    background: #fff;  
	margin:0px 15px 10px 15px; 
}  

.productsName{
	min-height: 45px !important;
    font-size: 18.88px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 23.01px;
	color: #000;
	padding:0px 20px ;
}

.viewbutton{ 
	display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width:767px){
	.productsName{font-size: 14px;}
}

