.service-title-div {
  margin-bottom: 60px;
}

.myservice-div {
  border: 0.89px solid rgba(226, 226, 234, 1);
  border-radius: 20px;
  padding: 20px 20px 5px 20px;
  margin: 40px 0px;
}


.product-open {
  font-size: 23px;
  font-weight: 500;
  display: flex;
  padding: 15px;
}

p.offer-subcategory {
  padding: 25px 0px 10px 0px;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

.subcategory-heading ul {
  list-style: none;
}

.subcategory-heading>ul>li { 
  border-radius: 18px;
  position: relative;
  transition: 0.3s all ease-in-out;
}

.subcategory-heading ul li.accclose:before {
  background: url(../../../../assets/images/minus.svg);
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 15px;
}

.subcategory-heading ul li.accplus:before {
  background: url(../../../../assets/images/plus.svg);
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 15px;
}

.subcategory-heading ul li img.plus {
  position: absolute;
  right: 0px;
  top: 25px;
  cursor: pointer;
}

.subcategory-heading ul li img.minus {
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.subcategory-heading ul li {
  transition: opacity 0.3s ease-in-out;
  position: relative;
  margin: 0px 15px;
}

.subcategory-heading ul li:before {
  content: "";
  position: absolute;
  left: 0;
  background: #dff6ff;
  width: 0;
  height: 6px;
  top: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}

.subcategory-heading ul li img.plus:hover {

}


.category-count-number {
  width: 30px;
  height: 30px;
  border-radius: 8.93px;
  margin-right: 5%;
  margin-left: 2%;
  font-size: 14.29px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.08929134160280228px;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-self: center;
}




p.category-count-number.mortgage-counter {
  border: 1.5px solid #2F596F;
  color: #2F596F;
}

p.category-count-number.investment-counter {
  border: 1.5px solid #86357A;
  color: #86357A;
}

p.category-count-number.real-estate-counter {
  border: 1.5px solid #00831D;
  color: #00831D;
}

p.category-count-number.insurance-counter {
  border: 1.5px solid #FF4C00;
  color: #FF4C00;
}

p.category-count-number.banking-counter {

  color: #2281B8;
  border: 1.5px solid #2281B8;
}



.service-title-div{
    margin-bottom: 60px;
}
 
.myservice-div{
    border: 0.89px solid rgba(226, 226, 234, 1);
    border-radius: 20px;
    padding: 20px 20px 5px 20px;
    margin: 40px 0px;
  }
  
 
  .product-open{
    font-size: 23px;
    font-weight: 500; 
    display:flex;   
    padding: 15px;
  }
    
  p.offer-subcategory {
    padding: 25px 0px 10px 0px;
    font-size: 20px;
    font-weight: 600; 
    display:flex;       
  }
  
  .subcategory-heading ul {
   
    list-style: none;
  }
  
  .subcategory-heading > ul > li {
    background: #fcfcfc;
    border-radius: 18px;
    position: relative; 
    transition: 0.3s all ease-in-out;
  }
  
  .subcategory-heading ul li.accclose:before {
    background: url(../../../../assets/images/minus.svg);
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    right: 15px;
  }
  
  .subcategory-heading ul li.accplus:before {
    background: url(../../../../assets/images/plus.svg);
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    right: 15px;
  }
  
  .subcategory-heading ul li img.plus {
    position: absolute;
    right: 0px;
    top: 25px;
    cursor: pointer;    
  }
  
  .subcategory-heading ul li img.minus {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  
  .subcategory-heading ul li {
    transition: opacity 0.3s ease-in-out;
    position: relative;
    margin: 0px 15px; 
  }
  .subcategory-heading ul li:before {
    content: "";
    position: absolute;
    left: 0;
    background: #dff6ff;
    width: 0;
    height: 6px;
    top: 0px;
    opacity: 0;
    transition: all 0.35s ease;
  }
  .subcategory-heading ul li img.plus:hover {
    transform: rotate(60deg); 
  }


  .category-count-number{
    width: 30px;
    height: 30px;
    border-radius: 8.93px;
    margin-right: 5%; 
    font-size: 14.29px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.08929134160280228px; 
    color: #FFF;
    display: flex;
    justify-content: center;
    align-self: center; 
  }


  

p.category-count-number.mortgage-counter{
    border: 1.5px solid #2F596F;
    color: #2F596F;
  }
  
  p.category-count-number.investment-counter{
    border: 1.5px solid #86357A; 
    color: #86357A; 
  }
  
  p.category-count-number.real_estate-counter{
    border: 1.5px solid #00831D;
    color: #00831D;
  }
  
  p.category-count-number.insurance-counter{
    border: 1.5px solid #FF4C00;
    color: #FF4C00;
  }
  
  p.category-count-number.banking-counter{
  
    color:#2281B8;  
    border: 1.5px solid #2281B8;
  }


  
table.myservice-inner {
  background: #fff;
  width: 100%;
  padding: 20px;
}

table.myservice-inner tr th {
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-left: none;
  border-top: none;
}

table.myservice-inner tr td {
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-left: none;
  border-bottom: none;
}

table.myservice-inner tr td:last-child,
table.myservice-inner tr th:last-child {
  border-right: none;
  text-align: center;
}



.myservice-inner .container-redio .checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #eee;
  right: 0;
  margin: auto;
  border-radius: 3px;
}

.myservice-inner .container-redio input:checked~.checkmark {
  border-radius: 3px;
}


.offer-open {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  padding: 15px;
}

.subcategory-div {
  background: #D2FFD2;
  border-radius: 20px;
  padding: 20px 20px 40px 20px;
}

.subcategory-inner {
  padding: 7px 50px 7px 50px;
  background: #FFF;
  border-radius: 16px;
}

.table-heading {
  font-size: 24px;
  font-weight: 600;
}

.table-text {
  font-size: 15px;
  font-weight: 500;
  height: 50px;
}

.redio-outer {
  width: 24px;
  height: 24px;
  border: 1px solid #03B135;
  border-radius: 1px;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;

}
.checkmark_stem {
  position: absolute;
  width: 2px;
  height: 21px;
  background-color: #03B135;
  left: 13px;
  top: 0px;
  transform: rotate(45deg);
}

.checkmark_kick {
  position: absolute;
  width: 10px;
  height: 2px;
  background-color: #03B135;
  left: 1px;
  top: 11px;
  transform: rotate(77deg);
}
.redio-inner {
  margin-top: 4px;
  width: 14px;
  height: 14px;
  background: #03B135;
  border-radius: 100px;
}


button.add-service { 
  width: 220px;
  height: 40px;
  border-radius: 5px;
  background: #86357A; 
  font-size: 18px;
  font-weight: 500; 
  letter-spacing: 0.1em;
  text-align: center; 
  color: #FFF;
  margin: 30px;
}

.redio-off-outer {
  width: 24px;
  height: 24px;
  border: 3px solid #BFBFBF;
  border-radius: 0px;
  box-shadow: 0px 4px 4px 0px #00000040;

}

.redio-on-outer {
  width: 24px;
  height: 24px;
  border: 3px solid #119136;
  border-radius: 0px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.redio-on-inner {
  /* margin-top: 3px;
  width: 14px;
  height: 14px;
  background: #03B135;
  border-radius: 100px; */
  position: relative;
}
.redio-on-inner:before{position: absolute;
  width: 2px;
  height: 21px;
  background-color: #03B135;
  left: 13px;
  content: "";
  top: 0px;
  transform: rotate(45deg);}
  .redio-on-inner:after{
    position: absolute;
    width: 10px;
    height: 2px;
    content: "";
    background-color: #03B135;
    left: 1px;
    top: 11px;
    transform: rotate(77deg);
  }

.servicetitle {
  margin: 0px 0 5px auto;
  font-weight: 700;
  font-size: 30px;
  color: #000;
}

.servicesubtitle {
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.service-div {
  background: #D2FFD2;
  border-radius: 20px;
  padding: 23px;
}
