@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

body {
  font-family: "Montserrat", sans-serif !important;
}
@font-face {
  font-family: 'microsoft_sans_serifregular';
  src: url('../../assets/fonts/micross-webfont.woff') format('woff'),
       url('../../assets/fonts/micross-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 300;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Light.ttf");
}
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Regular.ttf");
}
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Medium.ttf");
}
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-SemiBold.ttf");
}
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Bold.ttf");
}
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 900;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProText-Heavy.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 100;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Ultralight.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 200;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Thin.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Light.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Regular.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Medium.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-SemiBold.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Bold.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 800;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Black.ttf");
}
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 900;
  src: url("https://raw.githubusercontent.com/blaisck/sfwin/master/SFPro/TrueType/SFProDisplay-Heavy.ttf");
}
html {
  scroll-behavior: smooth;
}
a:not([href]):not([class]), a:not([href]):not([class]):hover{cursor: pointer;}
:root {
	--green: #00831D;
	--purple: #86357A;
	--Blueone: #2F596F;
	--orange: #FF4C00;
	--Bluetwo: #2280B8;
  --lightgreen: #d2ffd2;
  --lightpurple: #ffeaff;
	--lightBlueone: #e9eef9;
	--lightorange: #ffeadc;
	--lightBluetwo: #daf2ff;

  }
  .Real.Estate .applybutton, .category-id5 .applybutton{
    color: var(--green);
    background-color: var(--lightgreen); 
    border-color:var(--green); ;}
    .Real.Estate .applybutton:hover, .category-id5 .applybutton:hover, .Real.Estate .viewbutton:hover{
      background-color:var(--green);
      color:#fff;
    }
.Investment .applybutton, .category-id2 .applybutton {
    color: var(--purple);
    border-color: var(--purple);
    background: var(--lightpurple);
}
.Investment .applybutton:hover, .category-id2 .applybutton:hover, .Investment .viewbutton:hover{
  background-color: var(--purple);
  color:#fff; 
  box-shadow: 0px 0px 6px 0px var(--purple);
}
.Mortgage .applybutton,  .category-id3 .applybutton {
    color: var(--Blueone);
    border-color: var(--Blueone);
    background: var(--lightBlueone);
}
.Mortgage .applybutton:hover, .category-id3 .applybutton:hover, .Mortgage .viewbutton:hover{
  background-color: var(--Blueone);
  color:#fff; 
  box-shadow: 0px 0px 6px 0px var(--Blueone);
}
.Insurance .applybutton, .category-id1 .applybutton {
    color: var(--orange);
    border-color: var(--orange);
    background: var(--lightorange);
}
.Insurance .applybutton:hover, .category-id1 .applybutton:hover, .Insurance .viewbutton:hover{
  background-color: var(--orange);
  color:#fff; 
  box-shadow: 0px 0px 6px 0px var(--orange);
}
.Banking .applybutton, .category-id4 .applybutton {
    color: var(--Bluetwo);
    border-color: var(--Bluetwo);
    background: var(--lightBluetwo);
}
.Banking .applybutton:hover, .category-id4 .applybutton:hover, .Banking .viewbutton:hover{
  background-color: var(--Bluetwo);
  color:#fff; 
  box-shadow: 0px 0px 6px 0px var(--Bluetwo);
}
.pscatbottom{position: relative;}
.pscatbottom:after {
  content: "";
  position: absolute;
  width: 68px;
  height: 1px;

  left: 0;
  bottom: -3px;
}
.pscatbottom:nth-child(2):after {
  width: 150px;
}
.Real.Estate .pscatbottom:after {
  background: var(--green);
}
.Investment .pscatbottom:after {
  background: var(--purple);
}
.Mortgage .pscatbottom:after {
  background: var(--Blueone);
}
.Insurance .categotyinpopfl .pscat:after {
  background: var(--orange);
}
.Insurance .pscatbottom:after {
  background: var(--orange);
}
.Banking .pscatbottom:after {
  background: var(--Bluetwo);
}
button.swal-button.swal-button--cancel:hover {
  background: #EF000B;
  color: #fff;
}
button.swal-button.swal-button--confirm.swal-button--danger:hover{
  background: #000 !important;
  color: #fff !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 0px 0px 10px;
  color: #272727;
  
}

h4 {
  font-size: 16px;

  font-weight: 700;
  line-height: 22px;
}

h3 {
  font-size: 26px;
}

a {
  color: #06293f;
  background: none;
  outline: none;
  text-decoration: none;
}

.loginscreeninstitute a {
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  color: #06293f;
  text-decoration: none;
  outline: none;
}
.main-inner-footer p.footer-text {
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
  margin-bottom: 0;
  line-height: 14.63px;
}
footer a:active, footer a:focus, footer a:hover {
  color: #edf3f7;
  text-decoration: none;
  outline: none;
}
::selection {
  background: #06293f;
  color: #fff;
}

::-moz-selection {
  background: #06293f;
  color: #fff;
}

img {
  max-width: 100%;
}

.martop60 {
  margin-top: 60px;
}

.martop40 {
  margin-top: 40px;
}

.martop30 {
  margin-top: 30px;
}

.martop20 {
  margin-top: 20px;
}

.marbot60 {
  margin-bottom: 60px;
}

.marbot40 {
  margin-bottom: 40px;
}

.marbot30 {
  margin-bottom: 30px;
}

.marbot20 {
  margin-bottom: 20px;
}

.paddingbot60 {
  padding-bottom: 60px;
}

.padtop60 {
  padding-top: 60px;
}

.no-pad-left {
  padding-left: 0 !important;
}

/*-----------------------------------------------------------------*/
/* Common Elements
/*-----------------------------------------------------------------*/

.clear {
  clear: both;
}
.opsIcon{
  height: 40px;
  width: 40px;
}
.search-block{
  background-color: white;
  width: 100%;
  border: readius 10px;
  height:3.5rem;
  padding: 0 15px;
  box-shadow: 0px 0px 8px #ddd;
  align-items: center;
  margin-top: 30px;
  border: 2px solid #06293f;
}
.inputSearch{
  background-color: transparent;
  border:none;
  height:100%;
  font-size: 1.25rem;
  margin-left: 5px;
}
.inputSearch:focus{
  outline: none;
}
.product-tab{
  padding: 10px;

}
.slick-prev:before, .slick-next:before{display: block;}
.ctaonebutton {
  height: 50px;
  background: #034B77;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #f6fdff;
  transition: 0.3s all ease-in-out;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  color: #fff;
  position: relative;
  transition: 0.3s all ease-in-out;
  border: 1px solid transparent;
}
.ctaonebutton:hover{background: transparent; color:#06293f ; border: 1px solid #06293f;}
.ctaonebutton:hover {
  background: linear-gradient(90.46deg, #06293f 0%, #024e7c 100%);
  color: #fff;
  transition: 0.3s all ease-in;
}
.ctaonebutton:before {
  border: 2px solid transparent;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  position: absolute;
  content: "";
}

.ctaonebutton:after {
  border: 2px solid transparent;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  content: "";
}


.ctatwobutton {
  height: 50px;
  background: #dff6ff;
  border: 1px solid #c3c6c9;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #024e7c;
  transition: 0.3s all ease-in-out;
  display: block;
  width: 100%;
}

.font-we-400 {
  font-weight: 400;
}

/*---Input---*/
.form-control {
  box-sizing: border-box;
  width: 100%;
  padding: 13px 13px 0;
  border: 1px solid #ccc;
  resize: none;
  outline: none;
}

.form-control[required]:focus + label[placeholder]:before {
  color: #666;
}

.form-control[required] + label[placeholder] {
  display: block;
  pointer-events: none;
  line-height: initial;
  margin-top: calc(-42px - 2px);
  margin-bottom: calc((58px - 1em) + 2px);
  color: #214860 !important;
  font-size: 14px;
}

.form-control[required]:focus + label[placeholder]:before,
.form-control[required]:valid + label[placeholder]:before {
  transition-duration: 0.2s;
  transform: translate(0, -18px) scale(0.9, 0.9);
  margin-left: 6px;
}

.form-control[required]:invalid + label[placeholder][alt]:before,
.form-control[name]:invalid + label[placeholder][alt]:before {
  content: attr(alt);
}

.form-control.social-control[required] + label[placeholder] {
  margin-left: 25px;
}

.form-group.social-control .placeholder {
  padding: 0rem 27px;
}

.formscicon {
  position: absolute;
  top: 23px;
  left: 14px;
  color: #06293f;
}

.form-group {
  position: relative;
}

.form-control[required] + label[placeholder]:before,
.form-control[name] + label[placeholder]:before {
  content: attr(placeholder);
  display: inline-block;
  margin: 0 calc(10px + 2px);
  padding: 0 2px;
  color: #214860;
  white-space: nowrap;
  transition: 0.3s ease-in-out;
  background-image: linear-gradient(to bottom, #fff, #fff);
  background-size: 100% 5px;
  background-repeat: no-repeat;
  background-position: center;
}

.form-group .form-control:focus {
  box-shadow: none;
}

select.form-control.form-select {
  font-size: 14px;
}

select.form-control.form-select option {
  padding: 10px;
  background: #ffffff;
  border: 1px solid #c3c6c9;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 14px;
}
.myexpertformarea.searcharea.searchmainara select.form-control.form-select option{font-size: 20px;}

select.form-control.form-select option:hover {
  background: #024e7c;
}

select.form-control.form-select:focus {
  font-size: 14px;
}

select.form-control.form-select:not(:focus):valid {
  font-size: 14px;
}

select.form-control.form-select:focus:valid {
  font-size: 14px;
}

select.form-control.form-select:not(:focus):invalid {
  font-size: 0px;
}

/*----Multiselect----*/
label.multiplaceholer {
  color: #214860;
  line-height: 14px;
  cursor: initial;
  pointer-events: none;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;

  background: transparent;
  font-size: 14px;
  opacity: 1;
  
  z-index: 9;
}

.css-t3ipsp-control label.multiplaceholer {
  padding-left: 14px;
}

label.multiplaceholer.active {
  position: absolute;
  top: 6px;
  font-size: 12px;
  left: 0;
}

.css-13cymwt-control .css-1hb7zxy-IndicatorsContainer {
  top: -13px;
  position: relative;
}

.form-container.form-group .css-1fdsijx-ValueContainer, .form-container.form-group .css-hlgwow {
  display: block;
  padding: 0;
  overflow: visible;
}

div#react-select-3-placeholder {
  font-size: 0;
}

label.multiplaceholer:focus {
  transform: translateY(-9px) translateX(-1px) scale(1);
  font-size: 12px;
}
.DatePicker_calenderContainer{z-index: 9999; position: relative;}
.selectbox.css-b62m3t-container .css-13cymwt-control {
  height: 50px;
}

.selectbox .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer {
  top: 0;
}

.selectbox.css-b62m3t-container .css-t3ipsp-control {
  height: 65px;
  box-shadow: none;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.selectbox.css-b62m3t-container .css-t3ipsp-control .placeholder {
  transform: translateY(-13px) translateX(-1px) scale(1);
  font-size: 12px;
}

.form-container.form-group.multiselect .css-13cymwt-control {
  border: 1px solid #c3c6c9;
  border-radius: 4px;
  height: 65px;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  padding: 17px 13px 0;
}

#react-select-5-listbox, #react-select-8-listbox {
  z-index: 999;
}

.css-1dimb5e-singleValue {
  font-size: 14px;

}

.css-13cymwt-control .css-3w2yfm-ValueContainer, .css-13cymwt-control .css-1dyz3mf{
  top: -13px;
  position: relative;
  padding: 0;
}

/*----End Multiselect----*/

/*transition input---*/
.cut {
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

/* eye toggle css start */

.input-icon-button{
  position: absolute;
  right: 30px;
  top: 33%;
  transform: translate(-50%);
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 2;
  outline: none;
  padding: 0; 
}

.input-icon-button:focus,
.input-icon:focus,
.input-icon-button:hover,
.input-icon:hover {
  outline: none;
  border: none;
}

.input-icon {
  width:16px;
  height: 16px;
  outline: none;
  pointer-events: none;
}
/* eye toggle css end */

.cut-short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.input-container .placeholder {
  color: #666666;
  left: 14px;
  line-height: 14px;
  cursor: initial;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 25px;
  background: transparent;
  font-size: 20px;
  font-weight: 500;
  opacity: 1;
  
  z-index: 9;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-13px) translateX(-1px) scale(1);
  font-size: 12px;
}

select.input.form-control.form-select:focus ~ .placeholder {
  transform: translateY(-9px) translateX(-1px) scale(1);
  font-size: 12px;
}

select.input.form-control.form-select:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-17px) translateX(-1px) scale(1);
  font-size: 12px;
}

select.input.form-control.form-select {
  font-size: 14px;
}

select.input.form-control.form-select:focus {
  font-size: 14px;
}

select.input.form-control.form-select:not(:focus):valid {
  font-size: 14px;
}

select.input.form-control.form-select:focus:valid {
  font-size: 14px;
}

select.input.form-control.form-select:not(:focus):invalid {
  font-size: 0px;
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #214860;
}

.input:focus ~ .placeholder {
  color: #214860;
  font-family: "Poppins", sans-serif !important;
}

/*---End input---*/
/*----Loader----*/
.loaderwrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #dff6ff;
  border-radius: 50%;
  display: block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(272.02deg, #062a40 0%, #024b78 100%);
  width: 16px;
  height: 16px;
  transform: translate(-50%, 50%);
  border-radius: 50%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

span.btnloader img {
  width: 50px;
}

button.loadingbutton {
  font-size: 0;
}


button.loadingbutton {
  position: relative;
  cursor: wait;
}

button.loadingbutton:after {
  position: absolute;
  content: "";
  background: url(../images/loader1.svg);
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
}

/*----End Loader -----*/
/*----header -----*/

/*----End header -----*/
.form-group .form-control {
  border: 1px solid #c3c6c9;
  border-radius: 4px;
  height: 65px;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  padding: 17px 13px 0;
}

button.btn.btn-submit {
  background: #dff6ff;
  width: 100%;
  border: 2px solid #c9daea;
  border-radius: 4px;
  font-size: 18px;
  height: 50px;
  transition: 0.3s all ease-in;
  font-weight: 500;
  color: #06293f;
}

section.loginscreeninstitute {
  position: relative;

  display: flex;
  align-items: flex-start;
}

button.btn.btn-register {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border-radius: 4px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  height: 50px;
  transition: 0.3s all ease-in;
}

section.loginscreeninstitute.registrationinstitute {
  position: relative;
  height: auto;
}



.active .loginscreenimage:before {
  width: 100%;
}

.active .loginscreenimage figcaption h3,
.active .loginscreenimage figcaption p {
  color: #fff;
}

section.loginscreeninstitute:before {
  content: "";
  position: absolute;
  background: #dff6ff;
  top: 0;
  left: 0;
  height: 100%;
  width: 45%;
}
section.loginscreeninstitute:after {
  content: "";
  position: absolute;
  background: #f6fdff;
  top: 0;
  right: 0;
  height: 100%;
  width: 55%;
  box-shadow: -5px -5px 25px rgba(3, 78, 124, 0.25);
}

section.loginscreeninstitute .container {
  position: relative;
  z-index: 1;
}

.form-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5d5f63;
}

.loginscreenimage {
  background: #fff;
  width: 80%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  transition: 0.3s all ease-in;
}

p.smalltext {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #5d5f63;
}

#forgetpass {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #024e7c;
  display: block;
  cursor: pointer;
  width: max-content;
}

.checkspam {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #06293f;
  text-decoration: none;
}

section.loginscreeninstitute .row {
  align-items: center;
}

.institutelogintext {
  width: 80%;
  margin-left: auto;
}

div#navbarSupportedContent {
  justify-content: end;
}

button.btn.btn-submit:hover {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border-radius: 4px;
  color: #fff;
  filter: drop-shadow(10px 10px 25px rgba(3, 77, 122, 0.37));
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}



.login_label {
  text-align: left;
  font: normal normal 300 14px/16px Gilroy;
  letter-spacing: 0px;
  color: #9e9e9e;
  opacity: 1;
}

.loginHeading {
  text-align: left;
  font: normal normal 600 30px/34px Gilroy;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}

#btnResend {
  background-color: orange;
  color: white;
}

.loginscreenimage figure {
  margin: 10px;
  flex: 0 0 35%;
  width: 35%;
  position: relative;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}

.loginscreenimage figcaption h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #06293f;
  position: relative;
}

.loginscreenimage figcaption p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5d5f63;
  
  position: relative;
}

.loginscreenimage:before {
  content: "";
  position: absolute;
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  left: 0;
  height: 100%;
  top: 0;
  bottom: 0;
  transition: 0.3s all ease-in;
  border-radius: 5px;
  width: 0;
}

.loginscreenimage:hover:before {
  width: 100%;
}

.loginscreenimage:hover figcaption h3,
.loginscreenimage:hover figcaption p {
  color: #fff;
}

/*----institute Registration---*/

section.loginscreeninstitute.registrationinstitute
  .institutelogintext
  p.text-center.smalltext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  /* Prussian Blue */
  color: #06293f;
}

section.loginscreeninstitute.registrationinstitute .institutelogintext h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #06293f;
}

.button_outer {
  background: linear-gradient(93.34deg, #024d7b 0%, #062a40 99.85%);
  border-radius: 3px;
  text-align: center;
  height: 55px;
  display: inline-block;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
  width: 210px;
}

.btn_upload {
  padding: 17px 30px 12px;
  color: #fff;
  text-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 3;
  white-space: nowrap;
}

.btn_upload input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 105%;
  cursor: pointer;
  opacity: 0;
}

.file_uploading {
  width: 100%;
  height: 10px;
  margin-top: 20px;
  background: #ccc;
}

.file_uploading .btn_upload {
  display: none;
}

main.main_full {
  text-align: center;
  margin-top: 40px;
  width: 70%;
}

.file_uploaded {
  margin-top: 0;
  width: 50px;
  background: #83ccd3;
  height: 50px;
}

.error_msg {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #06293f;
  width: 200px;
  margin: auto;
}

.smtext {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #212121;
  text-align: center;
  margin-bottom: 10px;
}

.disgrid {
  display: grid;
}

.grid-2 {
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  width: 100%;
  position: relative;
}

.grid-3 {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  width: 100%;
  z-index: 99;
  position: relative;
}

.grid-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  width: 100%;
}

.grid-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  width: 100%;
}
.grid-8 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  width: 100%;
}

/*----End Registration---*/
/*---tooltip----*/
.custooltip {
  position: relative;
  margin-bottom: 5px;
  cursor: pointer;
  pointer-events: all;
}

.custooltip:hover:after {
  content: attr(title);
  padding: 13px;
  background: #c9daea;
  border: 1px solid #06293f;
  box-shadow: 0px 4px 10px rgb(153 153 153 / 20%);
  border-radius: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  color: #06293f;
  text-transform: capitalize;
  position: absolute;
  width: auto;
  left: 30px;
  top: -9px;
  
  z-index: 9;
  width: 300px;
  z-index: 9;
}

.detailsleft h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #06293f;
}

.uploadinslogo {
  background: #024e7c;
  border-radius: 10px;
  height: 255px;
  width: 255px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #dff6ff;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  margin-top: 60px;
}

.back_btn {
  background: linear-gradient(135deg, #024e7d 0%, #062a40 100%);
  border-radius: 4px;
  width: 50px;
  height: 38px;
  color: #fff;
  padding: 0px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}

.leftsightbackdetails {
  display: flex;
  justify-content: space-between;
}

.detailsleft {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

section.loginscreeninstitute.registrationinstitute .row {
  align-items: flex-start;
}

input.DatePicker__input {
  border: 1px solid #c3c6c9;
  border-radius: 4px;
  height: 65px;
  font-size: 14px;
}

.DatePicker.input-container.form-group {
  width: 100%;
}

/*-----password authorization(2/2/23)----*/
.h1_style {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 36px;
  color: #06293f;
  margin-bottom: 18px;
}

.h2_style {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #06293f;
}

h2.h2_style span {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #024e7c;
}

h2.h2_style span.noblock {
  display: block;
}

.h3_style {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.h6_style {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06293f;
}

.h5_style {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #06293f;
}

.h4_style {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #06293f;
}

.otptime {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #024e7c;
  margin-top: 10px;
  text-decoration-line: underline;
  cursor: pointer;
}

.color2a {
  color: #2a2a2a;
}

.color214860 {
  color: #214860;
}

.otptime a {
  padding-left: 45px;
}

#logochangeModal .modal-footer {
  justify-content: space-evenly;
}

#logochangeModal .modal-footer .ctaonebutton,
#logochangeModal .modal-footer .ctatwobutton {
  width: 200px;
}

#logochangeModal .modal-body.text-center {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

#logochangeModal .uploadinslogo {
  justify-content: center;
  margin: auto;
}

#logochangeModal .modal-header {
  border: none;
  justify-content: center;
  text-align: center;
}

#logochangeModal .modal-title.text-center.h2_style {
  width: 100%;
}

#logochangeModal .modal-footer {
  border: none;
}

.modal-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: #f6fdff;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.selectboxmain .css-1fdsijx-ValueContainer {
  overflow: visible;
}

.modal-close:hover {
  color: black;
}

.btn_uploadlogo {
  padding: 17px 30px 12px;
  color: #fff;
  text-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 3;
  white-space: nowrap;
}

.button_outerlogo {
  border-radius: 3px;
  text-align: center;
  height: auto;
  display: inline-block;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
  display: block;
}

.btn_uploadlogo input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 105%;
  cursor: pointer;
  opacity: 0;
}

.modal-body-inner {
  background: #ffffff;
  border: 0.5px solid #8d8d8d;
  border-radius: 5px;
  width: 61%;
  margin: 30px auto;
  padding: 10px;
}

span.text-danger {
  position: absolute;
  font-size: 12px;
  left: 0;
  line-height: 12px;
  bottom: -18px;
}

.inlinecard span.text-danger {
  bottom: -37px;
}
.categoryaskecpertconfirminner.subcatchal h6 {
  display: block;
  width: 100%;
}
/*--Plane(2/6/23)--*/
.mainplan {
  background: #f6fdff;
  border-radius: 8px;
  padding: 10px;
}

.planesall {
  background: #c9daea;
  border: 1px solid #c3c6c9;
  border-radius: 8px;
}

.planesinner,
.planesinnerright {
  padding: 15px 5px;
}

.planesinner ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #414446;
  border-bottom: 1px solid #dff6ff;
  height: 56px;
  line-height: 1.2;
}

.planesinnerright ul {
  padding: 0;
  margin-bottom: 0;
}

.planesinnerright ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.2;
  text-align: center;
  color: #214860;
  list-style: none;
  border-bottom: 1px solid #dff6ff;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.planesinner ul li:last-child,
.planesinnerright ul li:last-child,
.planesinnerright.broadtp ul li:last-child {
  border: none;
}

.planesinnerright.broadtp ul li {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
  border-bottom: 1px solid #c9daea;
  font-size: 13px;
  height: 56px;
}
.logoform .form-fieldinner img {
  object-fit: contain;
}
.myexpertnav.myaccountnav.qnanav.martop40 ul li a {
  text-decoration: none;
  color: #024e7c;
}
.acc-home .bloginnerimage img {
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
}

.row.acc-home a {
  text-decoration: none;
}
.myexpertnav.myaccountnav.qnanav.martop40 ul li.active a {
  text-decoration: none;
  color: #fff;
}
.planesinner ul {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

.planesinner ul li {
  position: relative;
  padding-left: 20px;
  height: 56px;
  display: flex;
  align-items: center;
}

span.dateim {
  position: absolute;
  right: 7px;
  top: 12px;
}

.planesinner ul li:before {
  position: absolute;
  left: 7px;
  content: "";
  top: 21px;
  width: 6px;
  height: 6px;
  background: #414446;
  border-radius: 20px;
}

.broadtp {
  background: #dff6ff;
  border: 1px solid #024e7c;
  box-shadow: 4px 4px 10px rgba(6, 40, 61, 0.25);
  border-radius: 8px;
}

.planesinner .h3_style {
  color: #214860;
}

.planesinner .h3_style span {
  font-weight: 500;
  display: block;
  font-style: normal;
}

section.loginscreeninstitute .planesallbottom .row {
  align-items: center !important;
  display: flex;
}

.planbutton {
  width: 200px;
  display: inline-block;
  vertical-align: top;
}

.planbutton span {
  display: block;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #f6fdff;
  margin-top: -8px;
}

.signout {
  background: #dff6ff;
  height: 57px;
  width: 50px;
  padding: 5px;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #06283d;
  position: absolute;
  right: 15px;
  top: 60px;
  text-align: center;
}

.planoutmainsub {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
}

.planbuttonouter {
  padding-right: 65px;
}

.plaindetailsleft {
  justify-content: left;
  padding-left: 40px;
}

.institutelogintext .mb-4,
.registrationinstitute .mb-4 {
  margin-bottom: 30px !important;
}

.institutelogintext .mt-4,
.registrationinstitute .mt-4 {
  margin-top: 30px !important;
}

.mb-4 .css-1p3m7a8-multiValue {
  background: #c9daea;
  border-radius: 14px;
  margin-top: 28px;
  font-size: 11px;
}

.css-1n6sfyn-MenuList {
  font-size: 14px;
}

.form-container.form-group .css-t3ipsp-control {
  border-color: #86b7fe;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  padding: 10px;
}
.form-container.form-group .css-t3ipsp-control > div{overflow: visible;}
.form-container.form-group.multiselect .css-t3ipsp-control {
  padding: 0;
}

.css-1jqq78o-placeholder {
  display: none;
}

.selectbox.css-b62m3t-container .css-t3ipsp-control .placeholder.active {
  transform: translateY(-13px) translateX(-1px) scale(1);
  font-size: 12px;
}

.mb-4.input-container.form-group [data-value] {
  position: relative;
  top: 20px;
}

.form-group .css-1xc3v61-indicatorContainer svg {
  fill: #343a40;
}

.form-group .css-12a83d4-MultiValueRemove svg {
  fill: #fff;
}

.form-group .css-12a83d4-MultiValueRemove {
  background: #000;
  border-radius: 37px;
}

.passtext ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06283d;
  padding-left: 20px;
  position: relative;
}

.passtext ul li:before {
  content: "";
  left: 0;
  top: 8px;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-color: #06283d;
  position: absolute;
}

.passtext ul {
  padding-left: 0;
  list-style: none;
}

p.h4_style {
  color: #024e7c;
}

.p-text {
  color: #06283d;
}

span.vissibletooltip {
  padding: 13px;
  background: #c9daea;
  border: 1px solid #06293f;
  box-shadow: 0px 4px 10px rgb(153 153 153 / 20%);
  border-radius: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  color: #06293f;
  text-transform: capitalize;
  position: absolute;
  width: auto;
  right: 0;
  top: -9px;
  
  z-index: 9;
  display: none;
}

span.plantooltip.custooltipone:hover .vissibletooltip {
  display: block;
}

.social-control input.input.form-control {
  padding-left: 41px;
}

#react-select-3-listbox {
  background: #ffffff;
  border: 1px solid #c3c6c9;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 4px;
  font-size: 13px;
  z-index: 999;
}

#react-select-9-listbox,
#react-select-11-listbox,#react-select-8-listbox,
#react-select-12-listbox {
  z-index: 999;
  font-size: 13px;
}

.planesinner h5.h3_style {
  font-weight: 400;
}
.selectbox.css-b62m3t-container .css-t3ipsp-control{height: auto;}

.selectboxmain.selecttransition .placeholder {
  transform: translateY(-13px) translateX(-1px) scale(1);
  font-size: 12px;
}

/*----checkboxcustom-----*/
/* The container */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background: #d9d9d9;
  border: 1px solid #5d5f63;
  border-radius: 8px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

/* Create the checkmark/indicator (hidden when not checked) */
.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* The container */
.container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.container-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 30px;
  background-color: #eee;
  right: 0;
  margin: auto;
}

.planesinnerright .form-check.text-center {
  padding: 0;
}

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background: #ffefef;
  border: 1px solid #5d5f63;
  border-radius: 8px;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container-checkbox .checkmark:after {
  left: 13px;
  top: -7px;
  width: 10px;
  height: 23px;
  border: solid #24ff00;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/*----End checkboxcustom-----*/
.form-container .css-1u9des2-indicatorSeparator {
  width: 0;
}

span.plantooltip.custooltip.righttool:hover:after {
  right: 0;
  left: auto;
}

.passwordauthorization {
  width: 60%;
  margin-right: auto;
}

section.loginscreeninstitute.registrationinstitute.passw {
  height: 100vh;
}

.color-light-blue {
  color: #024e7c;
}

.ft-inner .mb-4 {
  margin-bottom: 30px !important;
}

.forgetpassinner .ft-inner .mt-4 {
  margin-top: 30px !important;
}

.ft-inner button.ctaonebutton {
  width: 150px;
}

.ft-inner button.ctatwobutton {
  width: 150px;
  border: 1px solid #2a2a2a;
  border-radius: 4px;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 500;
}

.savestate {
  display: flex;
  justify-content: space-evenly;
}

.otpauthentication.ft-inner button.ctaonebutton {
  width: 100%;
}

input.promo-input.form-control {
  background: #f6fdff;
  border-radius: 10px 10px 10px 10px;
  width: 100%;
  padding: 15px 20px;
  position: relative;
}

input.promo-button.form-control {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border: 0.5px solid #024e7c;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #f6fdff;
  width: auto;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 0;
}

.promosection {
  position: relative;
}

input.promo-button.form-control {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border: 0.5px solid #024e7c;
  border-radius: 0px 10px 10px 0px;
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #f6fdff;
  width: 100px;
  right: 0px;
  top: 0;
  height: 38px;
  padding: 0;
}

.plansummerypop {
  background: #c9daea;
  /* Light Blue card effect */
  box-shadow: 4px 4px 6px rgba(6, 40, 61, 0.25);
  border-radius: 10px;
  padding: 15px;
}

.plansummerypop ul {
  list-style: none;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06283d;
  margin-bottom: 0;
}

.plansummerypop ul li {
  line-height: 41px;
  display: flex;
  justify-content: space-between;
}

.plansummerypop {
  justify-content: space-between;
  width: 100%;
  flex: 0 0 100%;
}

.passtext ul.plansummeryul li {
  font-weight: 400;
  font-size: 16px;
  /* identical to box height */
  color: #06283d;
  line-height: 44px;
  padding: 0;
}

.passtext ul.plansummeryul li:before {
  display: none;
}

.addcard {
  background: #dff6ff;
  border-radius: 20px;
  box-shadow: 4px 4px 6px rgb(6 40 61 / 25%);
  padding: 50px;
}

.inlinecard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
}

span.validth {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #06283d;
  position: absolute;
  top: -12px;
}

.previewcardinner {
  background: #dff6ff;
  border-radius: 5px;
  padding: 40px;
  filter: drop-shadow(5px 5px 20px rgba(6, 40, 61, 0.2));
  position: relative;
}

.previewcard {
  width: 77%;
}

.namecardholder {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
}

.cardnumber {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 39px;
  color: #212121;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardde {
  border: 0.6px solid #7c7c7c;
  border-radius: 4px;
  background: #f8c924;
  width: 47px;
  height: 33px;
}

.carddate {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
}

.approved {
  text-align: right;
  top: -25px;
  position: absolute;
  right: 33px;
}

.font-weight-600 {
  font-weight: 600;
}

.paymentsummery {
  background: #c9daea;
  /* USAFA Blue */
  border: 1px solid #024e7c;
  margin-top: 60px;
  list-style: none;
  padding: 15px;
}

.paymentsummery li {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 20px;
  line-height: 37px;
  color: #06283d;
}

.paymentsummery li:last-child {
  justify-content: center;
  margin-top: 20px;
}

.paymentsummery li:nth-child(4) {
  font-weight: 700;
  font-size: 20px;
  margin-top: 30px;
}

span.plantooltip.custooltip:hover [title] {
  display: none;
}

.no-pad-bottom {
  padding-bottom: 0;
}

.no-pad-top {
  padding-top: 0;
}

.no-border-bottom {
  border-bottom: none;
}

.planesall hr {
  margin-bottom: 0;
}

.no-border-radius-bottom {
  border-radius: 8px 8px 0 0;
}

.no-border-radius-top {
  border-radius: 0px 0px 8px 8px;
}

.no-border-top {
  border-top: none;
}

.NEWPASSWORD button.btn-secondary.btn.mt-4.ctatwobutton {
  width: 100%;
}

/*-----Dashboard-----*/
section.entiredashboard {
  position: relative;
}

/* section.entiredashboard:before {
  background: #dff6ff;
  box-shadow: 2px 2px 5px rgba(3, 78, 124, 0.25);
  content: "";
  position: absolute;
  width: 28%;
  height: 100%;
} */

.dashboardlink ul {
  list-style: none;
  padding: 0;
}

.dashboardlink ul li a {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 50px;
  color: #06293f;
  text-decoration: none;
  transition: 0.3s all ease-in;
  position: relative;
  width: 100%;
  display: block;
}

.dashboardlink ul li {
  line-height: 62px;
  margin-bottom: 20px;
  position: relative;
  padding: 5px 0 0 20px;
}


.dashboardlink ul li:hover:before {
  width: 100%;
}

.dashboardlink ul li:hover img {
  filter: brightness(100);
}
section.product-offer.service-offer.aboutppv {
  padding-top: 0;
}

section.product-offer.service-offer.aboutppv .heading {
  margin-bottom: 40px;
}


.userdetails .responsename {
  width: 200px;
}

.userdetails {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-right: 20px;
}

.dashboardlink ul li img {
  margin-right: 32px;
}

.dashboardslider {
  background: #dff6ff;
  box-shadow: 5px 5px 25px rgb(6 40 61 / 20%);
  border-radius: 10px;
  padding: 40px;
}

span.dashboardtooltip {
  position: relative;
}

.dashboardtooltip span.dashboardtooltipinner {
  background: #c9daea;
  border: 1px solid #06293f;
  box-shadow: 0px 4px 10px rgba(153, 153, 153, 0.2);
  border-radius: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 150%;
  width: 200px;
  color: #06293f;
  display: none;
  position: absolute;
  right: 0;
  opacity: 0;
  z-index: 99;
}

span.dashboardtooltip:hover .dashboardtooltipinner {
  visibility: visible;
  opacity: 1;
  display: block;
}

.expertblog {
  background: #c9daea;
  box-shadow: 4px 4px 6px rgba(6, 40, 61, 0.25);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.expertblogimage img {
  height: 140px;
  object-fit: cover;
  border-radius: 8px;
  width: 100%;
}

.expertblogimage {
  width: 25%;
  margin-right: 10px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.expertblogtext {
  width: 70%;
}

.blog-text {
  width: 100%;
}

.expertblogtextin {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.expername {
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.008em;
  color: #06293f;
}

.blogdates {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.008em;
  color: #214860;
}

.blog-text p {
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: #000000;
}

ul.tag {
  list-style: none;
  padding: 0;
}

ul.tag li {
  background: #dff6ff;
  border-radius: 2px;
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.008em;
  color: #06293f;
}

a.viewall {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.008em;
  color: #212121;
  margin: 40px 0 0 0;
  text-decoration: none;
}

.sectionheader {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.dashboardrightpannerinnerright .signout {
  position: relative;
  top: auto;
  right: auto;
}

.dashboardrightpannerinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#logout .modal-body-inner {
  border: none;
  background: transparent;
}

#logout .modal-footer {
  display: flex;
  justify-content: space-evenly;
}

#logout .modal-footer .ctaonebutton,
#logout .modal-footer .ctatwobutton {
  width: 200px;
}

.dashboardlink ul li.active:before {
  width: 100%;
}

.dashboardlink ul li.active a {
  color: #fff;
}

.dashboardlink ul li.active img {
  filter: brightness(100);
}

.forgotpass button.ctatwobutton {
  width: 100%;
}

.ctatwobutton.planbutton span {
  color: #024e7c;
}

.tooltipcontent {
  padding: 5px;
  background: #c9daea;
  border: 1px solid #06293f;
  box-shadow: 0px 4px 10px rgb(153 153 153 / 20%);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #06293f;
  position: absolute;
  width: auto;
  left: 30px;
  top: -9px;
  z-index: 9;
  width: 300px;
  z-index: 9;
  visibility: hidden;
  margin-top: 19px;
}

span.plantooltip.custooltip:hover .tooltipcontent {
  visibility: visible;
  left: 25px;
  top: -40px;
}

span.plantooltip.custooltip.righttool .tooltipcontent {
  right: 22px;
  left: auto;
  width: 439px;
}

.userguide span.plantooltip.custooltip:hover .tooltipcontent {
  visibility: visible;
  left: 80px;
  top: 23px;
}

.pcardtext {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #024e7c;
}

.otpauthentication span.text-danger,
.passwordauthorization span.text-danger {
  bottom: -22px;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: none !important;
}

ul.tcon .custooltipdrop li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.41);
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.004em;
  color: #000000;
}

ul.tcon .custooltipdrop li:last-child {
  border: none;
}

li.firstdrop {
  border-bottom: 1px solid rgba(0, 0, 0, 0.41);
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.004em;
  color: #000000;
}

li.firstdrop:last-child {
  border-bottom: 0;
}

span.plantooltip.custooltip:hover:after {
  display: none;
}

.previewcardinner .approved {
  visibility: hidden;
  transition: 0.3s all ease-in;
}

.card-selected .approved {
  visibility: visible;
}

.dashboardslider .carousel-caption {
  background: #dff6ff;
  border-radius: 10px;
  left: 16px;
  width: 42%;
  padding: 10px;
  text-align: left;
  z-index: 1;
}

.dashboardslider .carousel-caption p {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: rgba(0, 0, 0, 0.65);
}

.dashboardslider .carousel-caption h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.018em;
  color: #000000;
  word-wrap: break-word;
}

.dashboardslider .carousel-control-prev,
.dashboardslider .carousel-control-next {
  display: none;
}

.dashboardslider ol.carousel-indicators li {
  background: #c9daea;
  border-radius: 15px;
  height: 10px;
  width: 10px;
  opacity: 1;
  border: none;
}

.dashboardslider ol.carousel-indicators li.active {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
}

/*----My expert----*/

.addexpert {

  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addexpertleft p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.008em;
  color: #2a2a2a;
}

.addexpertleft {
  width: 74%;
}

.myexpertformarea .input-container.form-group {
  border: none;
  display: inline-block;
  margin: 0;
  width: 20%;
  vertical-align: middle;
}

.myexpertformarea .input-container.form-group .form-control {
  border: none;
}

.myexpertformarea {
  background: #f6fdff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.expertinner {
  background: #dff6ff;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  filter: drop-shadow(4px 4px 8px rgba(3, 78, 123, 0.25));
  margin-bottom: 20px;
}

.expertpremium {
  position: absolute;
  right: 0;
  top: 0;
  background: #c9daea;
  border-radius: 0px 5px;
  padding: 10px;
  font-size: 16px;
  color: #024e7c;
}

.experdetails ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.offer-insurence-inner .offer-name.disfontsmall {
  font-size: 18px;
  min-height: 60px;
}
.expertinner ul li {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.008em;
  color: #06293f;
}
.posbuttontf.d-flex a {
  margin: 5px;
}

.expertinner .expertuser {
  width: 15%;
}

.experdetails {
  width: 63%;
  margin-right: auto;
}

.experdetails h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.018em;
  color: #06293f;
}

.experdetails h3 span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.008em;
  color: #06293f;
}

.viewprofile {
  margin-top: 40px;
}

.addexpertleft p {
  margin-bottom: 0;
}

.plansummerytop {
  background: #dff6ff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: -43px;
}

.plansummerytop ul {
  list-style: none;
  padding: 0;
  margin-bottom: 18px;
}

.plansummerytop ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06283d;
  display: flex;
  justify-content: space-between;
  line-height: 42px;
}

/*---My Expert--*/
.myexpertnav > ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  position: relative;
}

.myexpertnav > ul li {
  padding: 10px 20px;
  border-radius: 7px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #06293f;
  background: url(../images/expertnav.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 58px;
  width: 190px;
  background-position: center;
  cursor: pointer;
}
.myexpertnav > ul > li:nth-child(n + 1) {
  border-left: none;
  border-right: none;
  margin-right: -35px;
}
.myexpertnav.expnewdesign > ul li {
  width: 295px;
}
.myexpertnav.expnewdesign > ul > li:last-child {
  background: url(../images/expertnormal.svg);
}
.myexpertnav.expnewdesign > ul > li:first-child {
  background: url(../images/expfirstnom.svg);}
.myexpertnav.expnewdesign > ul > li:first-child.active {
    background: url(../images/ecpfirsthov.svg);
  }
  .myexpertnav.expnewdesign > ul > li:last-child.active {
    background: url(../images/explasthov.svg);
  }
  .myexpertnav > ul > li:last-child.active
.myexpertnav > ul > li:nth-child(n + 1) {
  border-left: none;
  border-right: none;
  margin-right: -35px;
}

.myexpertnav > ul > li:first-child {
  background: url(../images/firstnav.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.myexpertnav > ul > li:last-child {
  background: url(../images/lastnav.svg);
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0;
  background-position: center;
}
.myexpertnav > ul > li.active {
  background: url(../images/hover_nav.svg);
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 9;
}

.myexpertnav > ul > li:first-child.active {
  background: url(../images/firstnavhover.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.myexpertnav > ul > li:last-child.active {
  background: url(../images/lastnavhover.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.myadpostinner {
  background: #f6fdff;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 30px;
}

.myadpostinnerimage img {
  border-radius: 5px;
  height: 130px;
  object-fit: contain;
}

button.myadbtn {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border-radius: 5px;
  border: none;
  width: 30px;
  height: 30px;
  transition: 0.3s all ease-in;
  position: relative;
}
button.myadbtn:hover {
  background: transparent;
}

button.myadbtn:hover img {
  filter: invert(1);
}

button.myadbtn:before {
  border: 2px solid transparent;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  position: absolute;
  content: "";
}

button.myadbtn:after {
  border: 2px solid transparent;
  width: 0;
  height: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  content: "";
}
button.myadbtn:hover:after {
  width: 100%;
  height: 100%;
  border-bottom-color: #024e7c;
  border-right-color: #024e7c;
  transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
    height 0.25s ease-out 0.75s;

  border-radius: 5px;
}
button.myadbtn:hover:before {
  width: 100%;
  height: 100%;
  border-top-color: #024e7c;
  border-left-color: #024e7c;
  border-radius: 5px;
  transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
}
.myadpostbanner {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 10px 0 0 0;
}

.mybtn .myadbtn:nth-child(1) {
  margin-right: 10px;
}

.expertinnerformmain {
  background: #dff6ff;
  box-shadow: 5px 5px 6px rgb(6 30 64 / 20%);
  border-radius: 10px;
  padding: 30px;
}

textarea.form-control.textareaform {
  height: 340px;
}

.uploadexpert {
  background: #ffffff;
  border-radius: 5px;
  padding: 50px;
}

.radioform {
  display: flex;
  justify-content: right;
  align-items: center;
}

.radioform label.radiocontrol input[type="radio"] {
  margin-right: 5px;
}

.radioform label.radiocontrol {
  padding-left: 20px;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #5d5d5d;
  display: flex;
}

.expertformmainheader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.expertformmainheaderleft h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #06293f;
}

textarea.input.form-control.descriptiontextarea {
  height: 100px;
}

.expertinnerformmain .mb-4 {
  margin-bottom: 30px !important;
}

.uploadpd {
  width: 210px;
  height: 188px;
  background: rgba(2, 78, 124, 0.4);
  margin-bottom: 30px;
  border-radius: 8px;
}

.myproductmaininner p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.004em;
  color: #06293f;
}

.customaccordian ul {
  padding: 0;
  list-style: none;
}

.customaccordian > ul > li {
  background: #dff6ff;
  border-radius: 4px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  transition: 0.3s all ease-in-out;
}

.customaccordian ul li .h3_style {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #06293f;
  margin: 0;
}

.customaccordian ul li.accclose:before {
  background: url(../images/minus.svg);
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 15px;
}

.customaccordian ul li.accplus:before {
  background: url(../images/plus.svg);
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 15px;
}

.pname {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #06293f;
}

.inneraccimmain {
  background: #f6fdff;
  border-radius: 4px;
  padding: 10px;
  filter: drop-shadow(2px 2px 10px rgba(6, 40, 61, 0.25));
  margin-top: 30px;
}

.customaccordian ul li img.plus {
  position: absolute;
  right: 15px;
  top: 23px;
  cursor: pointer;
}

.customaccordian ul li img.minus {
  position: absolute;
  right: 15px;
  top: 33px;
  cursor: pointer;
}

.productlistdetails-in {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.productlistdetails-in .heading h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #06293f;
}

ul.categoryproduct li {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06293f;
  padding: 0;
  padding-right: 19px;
  background: transparent;
}

ul.categoryproduct {
  padding: 0;
  display: flex;
  margin: 15px 0;
}

ul.metaproduct {
  display: flex;
}

section.productdetailslist ul li {
  background: transparent;
}

section.productdetailslist ul.metaproduct li {
  background: #dff6ff;
  border-radius: 20px;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #06293f;
  padding: 7px 20px;
  margin-right: 10px;
}

section.productdetailslist {
  background: #fff;
  padding: 15px;
  border-radius: 10px;
}
.productlistdetails-in .mybtn {
  width: 75px;
}
.productlistdetails-in .headeing p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #06293f;
}

.productlistdetails-in .headeing p span {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #06293f;
}

.lowerproductdescription h3 {
  background: #dff6ff;
  border-radius: 0px 20px 20px 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #06293f;
  padding: 10px;
  width: max-content;
  padding-right: 40px;
}

.left-block ul li,
.right-block ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06293f;
  padding-bottom: 20px;
  list-style-type: disc;
  margin-left: 18px;
}

.lowerproductdescription ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06293f;
  padding-bottom: 20px;
  list-style: disc;
  margin-left: 23px;
}

.entiredashboard > .container > .row > .col-lg-3.col-sm-3 {
  background: #dff6ff;
  box-shadow: 2px 2px 3px rgb(3 78 124 / 25%);
}

.dashboardrightpanel {
  padding-left: 40px;
}

.myexpertformarea .input-container.form-group:nth-child(2) {
  width: 39%;
}

.myexpertformarea .input-container.form-group:first-child {
  width: 39%;
  border-right: 1px solid #ccc;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}

.pagination-section {
  justify-content: center;
}

.pagination-section button {
  background: #06293f;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #f6fdff;
  text-transform: capitalize;
  width: 65px;
}

button[disabled] {
  background: #ccc;
  border: 1px solid #ccc;
}
button[disabled]:hover{
  color: #fff !important;
  border: 1px solid #ccc !important;
  background-color: #ccc !important;
}
.pagination-section .active-page {
  background: #f6fdff;
  border-radius: 4px;
  width: 35px;
  height: 42px;
  border: none;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #06293f;
}

.userguide ul.dropdown-menu.show {
  background: #c9daea;
  border: 1px solid #06293f;
  border-radius: 9px;
  width: 300px;
}

.userguide ul.dropdown-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.41);
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.004em;
  color: #000000;
  white-space: break-spaces;
}

.userguide ul.dropdown-menu li:last-child {
  border: none;
}

.userguide ul.dropdown-menu li ul {
  background: #c9daea;
  border: 1px solid #06293f;
  border-radius: 8px;
  width: 400px;
}

ul.dropdown-menu1.userdropdown-menu {
  display: none;
}

ul.tcon .tconitem .custooltipdrop {
  position: absolute;
  width: 100%;
  left: 100%;
  padding: 13px;
  background: #c9daea;
  border: 1px solid #06293f;
  box-shadow: 0px 4px 10px rgb(153 153 153 / 20%);
  border-radius: 8px;
  list-style: none;
  display: none;
  top: 0;
}

ul.tcon .tconitem:hover .custooltipdrop {
  display: block;
}

.dropdown-toggle1.userdropdown-toggle:hover
  ul.dropdown-menu1.userdropdown-menu {
  display: block;
}

ul.dropdown-menu1.dropdown-submenu1 {
  display: none;
}

li.dropdown-item:hover ul.dropdown-menu1.dropdown-submenu1 {
  display: block;
  position: absolute;
  left: 211px;
}

li.dropdown-item {
  position: relative;
}

/*Switch checkbox*/
.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 34px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e5e5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background: linear-gradient(103.6deg, #024e7c 13.98%, #06293f 93.41%);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:after {
  content: "Inactive";
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  right: 10px;
  top: 7px;
  color: #06293f;
}

.switch input:checked + .slider {
  background-color: #dff6ff;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #dff6ff;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(65px);
  -ms-transform: translateX(65px);
  transform: translateX(65px);
}

.switch input:checked + .slider:after {
  content: "Active";
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  left: 5px;
  top: 7px;
  color: #06293f;
}

/* Rounded sliders */
.switch .slider.round {
  border-radius: 17px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

div#dropdownMenu2:hover ul.dropdown-menu.userdropdown-menu {
  display: block !important;
}

ul.tcon {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.tcon li {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  color: #000000;
}

ul.tcon ol li {
  list-style: none;
  position: relative;
}

ul.tcon ol li:before {
  content: ">";
}

.dashboardrightpannerinnerleft {
  padding-right: 20px;
}

.userguide span.plantooltip.custooltip {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.004em;
  text-decoration-line: underline;
  color: #024e7c;
}

.userguide .tooltipcontent {
  top: 30px;
  left: 100px;
  z-index: 99;
}

textarea.input.form-control.descriptiontextarea {
  padding: 32px 13px 0;
}

.rti--container {
  border: none;
  border-radius: 0px;
  min-height: 65px;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  padding: 0 10px !important;
}
input.rti--input.input.form-control {
  width: 100%;
}
.input-container.form-group.specialtag {
  border: 1px solid #c3c6c9;
  border-radius: 4px;
  min-height: 65px;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
}
.homepageproductoffer .pofferinner h5 {
  min-height: 82px;
}

.my-classfocus:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-13px) translateX(-1px) scale(1);
  font-size: 12px;
}
.normal {
  position: relative;
}
section.loginscreeninstitute.registrationinstitute.planss {
  height: 100vh;
}
input.rti--input.input.form-control {
  border: none;
}

.listingde h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 15px;
  /* identical to box height, or 62% */
  color: #2a2a2a;
}

.listingde {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.listingdetails ul {
  list-style: none;
  padding: 10px 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.listingdetails ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #2a2a2a;
  padding-bottom: 11px;
  position: relative;
  padding-left: 28px;
}

.listingdetails ul li span.icon {
  position: absolute;
  left: 0;
}

.createlisting {
  background: #dff6ff;
  box-shadow: 5px 5px 25px rgba(6, 40, 61, 0.2);
  border-radius: 10px;
  padding: 50px 50px;
}

.createlisting h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #2a2a2a;
}

.listinginner {
  background: #f6fdff;
  border-radius: 4px;
  padding: 15px;
  filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.12));
  margin-bottom: 30px;
}

.mls {
  width: 60%;
  margin: 47px auto 0;
}

.listingde .container-checkbox .checkmark {
  top: -10px;
}

table.myserviceinner {
  background: #fff;
  width: 100%;
}

table.myserviceinner tr td,
table.myserviceinner tr th {
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-left: none;
}

table.myserviceinner tr td:last-child,
table.myserviceinner tr th:last-child {
  border-right: none;
  text-align: center;
}

li.serviceinactive {
  opacity: 0.4;
}

.customaccordian button.ctaonebutton.mt-5 {
  width: 200px;
  margin: auto;
}

.myserviceinner .container-checkbox .checkmark {
  position: absolute;
  top: -5px;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #eee;
  right: 0;
  margin: auto;
  border-radius: 3px;
}

.myserviceinner .container-checkbox input:checked ~ .checkmark {
  border-radius: 3px;
}

.rti--input {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  width: 100%;
  order: 1;
}

.rti--input:focus ~ .placeholder,
.rti--input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-13px) translateX(-1px) scale(1);
  font-size: 12px;
}

.myaccountsection {
  background: #dff6ff;
  box-shadow: 5px 5px 25px rgba(6, 40, 61, 0.2);
  border-radius: 10px;
  padding: 20px;
}

.myaccountsectionheading {
  display: flex;
  justify-content: space-between;
}

.fieldans {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #06293f;
}

.form-fieldinner label {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #6d6d6d;
}

.multiselect input[type="checkbox"] {
  margin-right: 10px;
}

.multiselect [data-value] {
  position: relative;
  top: 20px;
  left: 10px;
}

.form-fieldinner {
  margin-bottom: 20px;
}

.myaccountsectionheading h3.h3_style {
  margin-bottom: 40px;
}

.form-fieldinner.sociallink .fieldans {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  word-break: break-all;
  width: 309px;
}

.form-fieldinner.sociallink {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 0;
}

.form-fieldinner.sociallink label {
  background: #ffffff;
  border: 1px solid #06293f;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  margin-bottom: 0;
}

.logoupload {
  background: #ffffff;
  border: 1.5px solid #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.logoform .form-fieldinner {
  display: flex;
}

.uploaddetails {
  padding-left: 20px;
}

.dashboardrightpannerinnerright {
  display: flex;
}

.dashboardrightpannerinnerright .signout {
  margin-left: 10px;
}

span.title {
  display: block;
}

.myaccountsection.institutedetails p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #212121;
  margin-bottom: 5px;
}

.myaccountpaymentsection {
  padding: 60px;
}

.widthhalf {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.inpass {
  width: 50%;
  margin: auto;
}

.validpass {
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #06293f;
}

ul.smclass {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #06293f;
}

.manageuser h3.h3_style {
  margin-bottom: 10px;
}

.manageuser p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.004em;
  color: #2a2a2a;
}

.userlist {
 
  border-radius: 4px;
 
  margin-bottom: 20px;
}

.username {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.004em;
  color: #06293f;
}

.useremail {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.004em;
  color: #06293f;
}

.usertel {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.004em;
  color: #06293f;
}

.userlistinner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.userguide {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.manageuser {
  display: block;
}

.acctext {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.004em;
  color: #06293f;
  margin: 20px 0;
}

.wholepad20 {
  padding: 20px;
}

.manageuserwhole {
  display: flex;
  justify-content: space-between;
}

.myexpertnav.myaccountnav ul li:first-child.active {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.myexpertnav.myaccountnav ul li {
  background-size: cover;
  background-repeat: no-repeat;
  height: 58px;
  width: 209px;
  background-position: top;
}

.myexpertnav.myaccountnav ul li:nth-child(n + 1) {
  border-left: none;
  border-right: none;
  margin-right: -20px;
}

section.our_topexpert {
  background: #f5f5f5;
  padding-bottom: 70px;
}

h2.ppvheadingh2 {
  background: #024e7c;
  border-radius: 0px 0px 20px 20px;
  width: max-content;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #dff6ff;
  padding: 10px 20px;
  margin: auto;
}

h4.ppvheadingh4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #212121;
}

.expcat {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06293f;
  margin-bottom: 10px;
}

section.expertformppi {
  background-image: url('../Instituteppvimages/getintouch-bg.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 0;
}

.expertformppiinner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expertformppiinnerdis {
  width: 23%;
}

section.expertformppi h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 48px;
  text-align: left;
  color: #000;
  text-transform: uppercase;
}

section.expertformppi h4 {
  color: #000;
  font-weight: 600;
  font-size: 20px;
}

.limitedofferinner {
  background: #dff6ff;
  box-shadow: 5px 5px 25px rgba(6, 40, 61, 0.2);
  border-radius: 10px;
  padding: 0 50px 50px;
  margin-bottom: 50px;
}

ul.serviceoffer-inner-accordian {
  margin: 0;
  padding: 0;
}

ul.serviceoffer-inner-accordian li {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-bottom: 20px;
  list-style: none;
  position: relative;
  padding: 10px 20px;
}

img.minus {
  position: absolute;
  right: 15px;
  top: 27px;
}

img.plus {
  position: absolute;
  right: 15px;
  top: 15px;
}

.publicdeinner {
  background: #ffffff;
  box-shadow: 5px 5px 25px rgba(6, 40, 61, 0.2);
  border-radius: 10px;
  padding: 20px;
  z-index: 9;
  position: relative;
  top: 108px;
}

.publicviewprofilede .cstlist {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-decoration-line: underline;
  color: #024e7c;
}

.publicviewprofilelogoupload {
  background: #ffffff;
  border: 1.5px solid #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
  top: -55px;
  text-align: center;
  overflow: hidden;
}

.ppvawesomesliderproducttype p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #06293f;
  margin-right: 20px;
}
.ppvawesomesliderreleatedproduct {
  margin-right: 20px;
}
.publicviewprofilede {
  display: inline-block;
  vertical-align: top;
  padding-left: 20px;
}
.publicmainpopdis {position: relative;width: 66%;}
.institutepublicprofile .publicpersonaldetails {
  position: relative;
  padding-left: 30px;
  margin-left: 30px;
  border-left: 1px solid #ccc;
  width:40%;
}
.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicpersonaldetails{width: auto; margin-top: 127px; border: none;}
.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicpersonaldetails heading{
  color: #024E7C;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
border-radius: 20px;
border: 1px solid #000;
padding: 2px 10px;
margin-bottom: 20px;
display: block;
}
.institutepublicprofile .publicviewprofilede {
  vertical-align: top;
  padding: 10px;
  background: #024E7C;
  stroke-width: 1px;
  stroke: #000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: absolute;
  right: 0;
  z-index: 1;
  top: 25%;
  border-radius: 20px;
  padding-left: 80px;
  width: 78%;
}
.institutepublicprofile ul.deone, .institutepublicprofile ul.dethree, .institutepublicprofile ul.detwo{
  padding:0; display: block; border: none;}
  .institutepublicprofile ul.deone li, .institutepublicprofile ul.dethree li, .institutepublicprofile ul.detwo li
  {padding-left: 30px; padding-bottom: 10px;}
  .institutepublicprofile ul.deone li span, .institutepublicprofile ul.dethree li span, .institutepublicprofile ul.detwo li span{
    position: absolute;
    left: 0;
    width: 30px;
}
.institutepublicprofile .socialpublic {
  background: #024E7C;
  position: absolute;
  right: 25px;
  bottom: 0px;
  width: 85%;
  padding: 10px;
}
.institutepublicprofile .socialpublic.d-flex{justify-content: center;}
.institutepublicprofile .ppvplan {
  background: #024E7C;
  border-radius: 0;
  padding: 5px 10px;
  position: absolute;
  right: 0;
  top: 0%;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 21px;
  color: #fff;
}
.institutepublicprofile .ppvplan:before{
  content: "";
  position: absolute;
  background: #024E7C;
  width: 31px;
  height: 99%;
  top: 0;
  left: -15px;
  transform: skew(27deg, 0deg);
}

.institutepublicprofile .socialpublic li {
  list-style: none;
  padding: 0px 5px;
  background: transparent;
}
.institutepublicprofile ul.deone:before, .institutepublicprofile ul.detwo:before, .institutepublicprofile ul.dethree:before{display:none;}
.institutepublicprofile ul.detwo li:nth-child(2){padding-left:30px;}
.institutepublicprofile .publicviewprofilelogoupload {
  background: transparent;
    border: 0;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 300px;
    width: 250px;
    height: 250px;
    display: inline-block;
    position: relative;
    top: 5px;
    text-align: center;
    overflow: hidden;
    z-index: 2;
    bottom: 0;
    margin: auto;
    margin-left: -40px;
}
.institutepublicprofile .publicviewprofilelogoupload:before{
  position: absolute;
  content: "";
  background: rgba(2, 78, 124, 0.9);
  width: 100%;
  height: 57.7%;
  left: 0;
  right: 0;
  top: 0;
}
.institutepublicprofile .publicviewprofilelogoupload:after{
  position: absolute;
    content: "";
    background: #fff;
    width: 100%;
    height: 40%;
    left: 0;
    right: 0;
    bottom: 0;
}
.institutepublicprofile .socialpublic:after {
  content: "";
  position: absolute;
  background: #024E7C;
  width: 31px;
  height: 100%;
  top: 0;
  right: -15px;
  transform: skew(27deg, 0deg);
}
.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede h2.h2_style{
  color:#fff; 
  margin-bottom: 0; 
  line-height: 35px; 
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  overflow:hidden;}
  .institutepublicprofile .publicviewprofilede h2.h2_style{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    color:#fff; 
    margin-bottom: 0; 
    line-height: 35px; }
.institutepublicprofile .publicviewprofilede .cstlist{color:#fff; font-size: 16px; text-decoration: none;}
.institutepublicprofile .publicdeinner{
  display: flex;
  padding: 0; 
  align-items: center; 
  border-radius: 450px 10px 10px 450px;
  margin-left: 40px;
}
.publicpersonaldetails ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

ul.deone {
  border-bottom: 0.5px solid #d9d9d9;
  display: flex;
  border-top: 0.5px solid #d9d9d9;
  padding: 10px 0;
  position: relative;
}

ul.deone li a,
ul.detwo li a {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #06283d;
  text-decoration: none;
}

ul.deone:before {
  content: "";
  position: absolute;
  background: #d9d9d9;
  height: 100%;
  width: 1px;
  right: 0;
  top: 0;
  margin: auto;
  left: 0;
}

ul.deone li {
  width: 50%;
}

ul.deone li:nth-child(2) {
  padding-left: 35px;
}
.institutepublicprofile ul.deone li:nth-child(2) {
  padding-left: 30px;
}

ul.detwo {
  display: flex;
  padding: 10px 0;
  border-bottom: 0.5px solid #d9d9d9;
  position: relative;
}
.logemail.mainsecpos ul.deone li{width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;}
ul.detwo:before {
  content: "";
  position: absolute;
  background: #d9d9d9;
  height: 100%;
  width: 1px;
  top: 0;
  margin: auto;
  left: 20%;
}

ul.dethree:before {
  content: "";
  position: absolute;
  background: #d9d9d9;
  height: 100%;
  width: 1px;
  top: 0;
  margin: auto;
  left: 40%;
}

ul.detwo li:nth-child(2) {
  padding-left: 200px;
}

ul.dethree {
  display: flex;
  padding: 10px 0;
  border-bottom: 0.5px solid #d9d9d9;
  justify-content: space-between;
  position: relative;
}

.ppvplan img {
  padding-right: 6px;
}
.institutepublicprofile .ppvplan img {padding-right: 10px;
    z-index: 3;
    position: relative;
}
.publicviewprofiledetails {
  background: url(../images/BG.svg);
  background-size: cover;
  position: relative;
  margin-bottom: 200px;
}
.publicviewprofilelogouploadinner {
  background: #fff;
  z-index: 9;
  position: absolute;
  width: 230px;
  height: 230px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  border-radius: 300px;
}

.authorised{ background: #DFF6FF; padding: 10px 0; font-weight: 600;
  font-size: 24px;
  line-height: 30px;  
  text-align: center;
  letter-spacing: 0.008em;  
  color: #000000;} 

.socialpublic li:first-child span img {
  width: 10px;
}
.institutepublicprofile .socialpublic li:first-child span img {
  width: auto;
}
section.greview {
  background: #f6fdff;
  padding-bottom: 60px;
}

.reviewinner {
  background: #ffffff;
  box-shadow: 5px 5px 25px rgba(6, 40, 61, 0.2);
  border-radius: 10px;
  padding: 20px;
}

.reviewheading {
  display: flex;
  justify-content: space-between;
}

section.greview h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #dff6ff;
  background: #024e7c;
  border-radius: 0px 0px 20px 20px;
  width: max-content;
  margin: auto;
  padding: 6px 20px;
}

.reviewinin {
  display: flex;
  justify-content: space-around;
}

.reviewinname {
  width: 200px;
}

.listofadinnerpop {
  background: #ffffff;
  padding: 15px;
}
.listofoffermainsection .listofproduct .listofadinner.mb-4 {
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, .15);
}
.listofadbutton {
  display: flex;
  justify-content: space-between;
}

.listofadbutton .ctatwobutton {
  width: 250px;
}

.listofadtext h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  color: #000000;
}

section.listofoffermainsection {
  background: #f6fdff;
}

.listofofferleft {
  justify-content: left;
}

.detailedview {
  background: #ffffff;
  box-shadow: 0px 4px 40px 1px rgba(0, 0, 0, 0.15);
  padding: 30px;
}

.detailedofferheading {
  background: #dff6ff;
  border-radius: 0px 20px 20px 0px;
  width: max-content;
  padding: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #06293f;
  margin-bottom: 10px;
}

.detailedtext p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #06293f;
}

.detailedtext .pname {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #06293f;
}

.detailedtext .ctaonebutton {
  margin-left: 0;
}

textarea.input.form-control.textareaform {
  padding: 37px 13px 0;
}

.btn_uploadlogo label div img {
  object-fit: contain;
}

span.rti--tag {
  position: relative;
  font-size: 15px;
  background: #dff6ff;
  border-radius: 20px;
  padding: 2px 5px;
  order: 2;
  position: relative;
  margin-bottom: 5px;
}

span.rti--tag span {
  padding-right: 7px;
}

span.rti--tag button {
  background: #7d7d7d;
  width: 13px;
  height: 13px;
  display: inline-block;
  font-size: 10px;
  padding: 0;
}

.subscribtionsummery {
  background: #dff6ff;
  box-shadow: 5px 5px 20px rgba(6, 40, 61, 0.2);
  border-radius: 5px;
}

.cardsubscriptionleft h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #2a2a2a;
}

.cardsubscriptionleft p {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  /* identical to box height, or 214% */
  color: #2a2a2a;
}

.cardsubscriptionleft .previewcardinner {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border-radius: 10px;
  padding: 30px 20px;
}

.cardsubscriptionright h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */
  color: #2a2a2a;
}

.cardsubscriptionright p {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #2a2a2a;
}

.subscribtionsummeryheading {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border-radius: 5px;
}

.subscribtionsummeryheading h4 {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  text-align: center;
  padding: 10px;
}

.cardsubscriptionright {
  margin-left: 25px;
  width: 60%;
}

.subscriptiondetails {
  justify-content: space-between;
  padding: 20px;
}

.cardsubscriptionleft {
  width: 40%;
}

.substartdates {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2a2a2a;
}

.substartdatesdetail {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #2a2a2a;
}

.text-white {
  color: #fff;
}

footer {
  background: #06283d;
  padding: 23px 0 0 0;
}

footer h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  color: #fcfcfc;
  width: max-content;
  padding-bottom: 10px;
  position: relative;
}
footer h3:before {
  position: absolute;
  background: linear-gradient(180deg, #47b5ff 0%, #1363df 100%);
  bottom: 0;
  content: "";
  width: 50px;
  height: 3px;
  left: 0;
  border-radius: 3px;
}

footer h1 {
  background: linear-gradient(105.66deg, #47b5ff 0%, #1363df 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
}

footer ul {
  padding: 0;
  list-style: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF ;
  margin-bottom: 0;
}

footer ul li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  text-decoration: none;
 
}

[type=search]{outline: none;}

.footertwo ul li{    line-height: 1;}
.footerthree ul.conde li {
  font-size: 14px;
  font-weight: 500;
}

ul.paymentstructure li:nth-child(1) {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border-radius: 10px 10px 0px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #e6e6e6;
  padding: 11px;
}

.footerone ul li {
  position: relative;
  margin-right: 14px;
}

ul.conde li {
  padding-left: 50px;
  padding-bottom: 5px;
}
footer .container{position: relative;}
ul.socialin.d-flex.mb-3 {
  margin-top: 30px;
  margin-bottom: 0 !important;

}
ul.socialin.d-flex.mb-3 li {
  margin-right: 10px;
}
.footerone ul li .footericon {
  position: absolute;
  left: 0;
  top:0px;
}

ul.paymentstructure {
  padding: 0;
  list-style: none;
}

.dropdefaulttext h5 {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.004em;
  color: #043c5e;
}

ul.paymentstructure li {
  display: flex;
  justify-content: space-between;
  background: #dff6ff;
  border-radius: 4px;
  margin-bottom: 15px;
  align-items: center;
  padding: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #2a2a2a;
}

.currentplan h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #2a2a2a;
}

.currentplan h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #2a2a2a;
}

ul.paymentstructure li span {
  width: 40%;
  text-align: center;
}

ul.paymentstructure li .ctaonebutton {
  width: 150px;
  margin: auto;
  line-height: 1;
}

.replace {
  opacity: 0.6;
}

.cardas {
  position: relative;
}

.replacelogo {
  position: absolute;
  opacity: 1;
  z-index: 9;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 50px;
  text-decoration-line: underline;
  color: #ffffff;
  top: 46%;
}

.sectioncardadd {
  background: #dff6ff;
  box-shadow: 5px 5px 20px rgba(6, 40, 61, 0.2);
  border-radius: 5px;
  padding: 40px;
}

.sectioncardadd h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.sectioncardadd h4 {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.detailscardenter {
  background: #b6dbe9;
  border-radius: 5px;
  padding: 30px;
  width: 60%;
  margin: 20px auto 0;
}

.dashboardrightpanel .mt-4 {
  margin-top: 30px !important;
}

.dashboardrightpanel .mb-4 {
  margin-bottom: 30px !important;
}

.replace .namecardholder,
.replace .cardnumber,
.replace .carddate {
  opacity: 0.1;
}

.paymentchange .previewcardinner {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border-radius: 10px;
  padding: 30px 20px;
  width: 80%;
  margin: auto;
}

.paymentchange {
  width: 60%;
  margin: auto;
}

.expertinnerformmain .mb-4 {
  margin-bottom: 30px;
}

.myaccountsection button.ctaonebutton.widthhalf {
  margin-right: 0;
}

.signout a {
  text-decoration: none;
}

.Paymentsummeryinner {
  width: 70%;
  margin: auto;
}

.Paymentsummeryinner .paymentsummery {
  background: #fff;
}

.planesall .planesinnerright {
  border: 1px solid #c3c6c9;
  border-radius: 8px;
}

.btn_uploadlogo label img {
  height: auto;
}

.rti--input {
  background: transparent;
}

.myadpostinnerimage {
  text-align: center;
}

.addexpertright {
  width: 30%;
}

.css-t3ipsp-control .placeholder {
  transform: translateY(-13px) translateX(-1px) scale(1);
  font-size: 12px;
}

.css-wsp0cs-MultiValueGeneric {
  font-size: 12px;
}

.selectboxmain.form-group:focus .placeholder {
  transform: translateY(-13px) translateX(-1px) scale(1);
  font-size: 12px;
}

.detailsleft .uploadinslogo img {
  height: 200px;
  object-fit: contain;
  display: flex;
}

.detailsleft .uploadinslogo {
  flex-wrap: wrap;
}

.rti--input::placeholder {
  color: #214860;
}

.userguide .dropdown-toggle:after {
  display: none;
}

.dropdown-toggle :hover ul.dropdown-menu.userdropdown-menu {
  display: block !important;
}

.uploadexpert {
  text-align: center;
}

img.downminus {
  position: absolute;
  right: 15px;
  top: 16px;
}

a.dropdown-item {
  white-space: break-spaces;
}

.ppvplan {
  background: #c9daea;
  border-radius: 10px 0px 0px 10px;
  padding: 5px 10px;
  position: absolute;
  right: 0;
  top: 27%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #06293f;
}

ul.dethree li span {
  background: #dff6ff;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
}

ul.dethree li span img {
 padding-right: 10px;
}
ul.dethree .socialpublic li span img {
  padding-right: 0px;
 }
ul.dethree.ppvthree li heading {
  width: 168px;
  display: inline-block;
  position: relative;
}
ul.dethree.ppvthree li heading:before{
  content: "";
    position: absolute;
    background: #d9d9d9;
    height: 209%;
    width: 1px;
    top: -13px;
    right: 9px;
}
ul.detwo.ppvtwo li heading {
  position: relative;
}
ul.detwo.ppvtwo li heading:before{
    content: "";
    position: absolute;
    background: #d9d9d9;
    height: 209%;
    width: 1px;
    top: -13px;
    right: 9px;
}

.experdetails h3 span {
  display: inline-block;
  margin-left: 0px;
}
.experdetails h3 span:nth-child(1){margin-left: 15px;}
.expertformppiinnerdis textarea.input.form-control {
  height: 148px;
}

.anotherdetails ul {
  display: block;
}

.our_topexpert .experdetails {
  width: 34%;
}

.institutelogintext .signout {
  display: none;
}
.publicdeinnerts.institutepublicprofile .publicviewprofilede {
  vertical-align: top;
  padding: 10px;
  background: #024E7C;
  stroke-width: 1px;
  stroke: #000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: absolute;
  right: 0;
  z-index: 1;
  top: 5%;
  border-radius: 20px;
  padding-left: 129px;
  width: 80%;
}
.logemail.mainsecpos {
  position: absolute;
  top: 45%;
  right: 0;
  width:55%;
}
.socialpublic.d-flex ul {
  margin: 0;
}
.socialpublic.d-flex ul:empty{display: none;}
ul.deone li {
  list-style: none;
}
/*------Home Page-----*/
.heroleftpanel h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
  color: #06283d;
}

.heroleftpanel h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #505050;
}

.heroleftpanel h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #555555;
}

.herosection {


  padding: 50px 0;
}

.homepageproductoffer h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: #06283d;
  margin-bottom: 0;
}

.testileftpanel {
  padding-left: 30px;
  position: relative;
}

.homepageproductoffer h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #505050;
}

.pofferinner h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 15px;
  color: #555555;
}

.pofferinner {
  background: #fcfcfc;
  border-radius: 40px;
  filter: drop-shadow(0px 4px 40px rgba(42, 42, 42, 0.1));
  padding: 30px;
  max-width: 100%;
  margin: auto;
  min-height: 300px;
}
.adscreenone {
  background: #FCFCFC;
  border: 5px solid #E2E2E2;
  border-radius: 20px;
  display: flex !important;
  justify-content: center;
  height: 304px;
  align-items: center;
  overflow:hidden;
}
.publicviewprofilelogoupload img {
  height: 100px;
  width: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: scale-down;
}
.institutepublicprofile .publicviewprofilelogoupload img {
  height: 240px;
  width: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  z-index: 9;
  position: relative;
  padding:10px;
  border-radius: 300px;
  background: #fff;
}
.latestratesflex{justify-content: space-between; align-items: center;}
.heroleftbutton button {
  width: auto;
  padding: 10px 40px;
  margin-right: 16px;
}

.border-radius-50 {
  border-radius: 50px;
}

.herorightpanel {
  text-align: right;
}

img.testiconone {
  position: absolute;
  top: -65px;
  left: 0;
}

img.testicontwo {
  position: absolute;
  bottom: -52px;
  right: 30px;
}

.testileftpanel h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #505050;
  text-align: left;
}

.testileftpanel h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #505050;
}

.rightform .mb-4 {
  margin-bottom: 30px !important;
}

.homepageexpertinner {
  background: radial-gradient(
      117.5% 117.5% at 27.25% -5%,
      #06283d 51.65%,
      #47b5ff 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  box-shadow: 0px 10px 20px rgba(69, 69, 69, 0.15);
  border-radius: 30px;
  padding: 20px 15px;
  text-align: center;
  min-height: 372px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.homepageexpertinnername {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #dff6ff;
  text-transform: capitalize;
}

.homepageexpertinnername:first-letter {
  font-size: 30px;
}
.homepageexpertinner.backan .homepageexpertinnername:first-letter {
  font-size: 30px;
  
}
.homepageexpertinner.backan .homepageexpertinnername{margin-bottom: 20px;}
.homepageexpertinnerusername {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  color: #ebe4e4;
  min-height: 35px;
}

button.prifileview {
  background: radial-gradient(
      117.5% 117.5% at 27.25% -5%,
      #dff6ff 51.65%,
      #47b5ff 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  transition: 0.3s all ease-in;
  box-shadow: 0px 10px 20px rgba(69, 69, 69, 0.15);
  border-radius: 30px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #06283d;
  padding: 5px 20px;
}
button.prifileview:hover {
  background: #fff;
}

.topexpert h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
  text-align: center;
  color: #06283d;
}

.topexpert h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #505050;
}

.topexpert {
  background: #dff6ff;
  padding: 60px 0 60px;
  margin-top: 90px;
}

section.latestrates {
  background: #dff6ff;
  padding: 50px 0;
}

section.latestrates h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 63px;
  /* identical to box height */
  color: #06283d;
}

section.latestrates h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #505050;
}

.latestratesinner {
  background: #fcfcfc;
  box-shadow: 0px 4px 40px rgba(42, 42, 42, 0.1);
  border-radius: 20px;
}

.latestratesinnerheading {
  background: #06283d;
  border-radius: 20px 20px 0px 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  /* identical to box height */
  color: #ffffff;
  padding: 10px;
}

ul.logohd {
  list-style: none;
  padding: 15px;
}

ul.logohd li {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  color: #505050;
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(6, 40, 61, 0.3);
  margin-bottom: 10px;
}

span.ratesicontext {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  text-align: center;
  color: #505050;
}

span.smalltext {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #505050;
}

button.viewall {
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #47b5ff;
  text-align: center;
  display: block;
  width: 100%;
  margin: 20px 0 0;
}

ul.logheading {
  padding: 0;
  list-style: none;
}

ul.logheading li {
  display: flex;
  justify-content: space-around;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #06283d;
  padding: 10px;
  background: #fcfcfc;
  border-bottom: 2px solid rgba(6, 40, 61, 0.3);
  border-radius: 0px;
}

.experthomepagebutton {
  justify-content: center;
}

.experthomepagebutton button {
  width: auto;
  padding: 0 41px;
  margin-right: 10px;
}

ul.calculatehomepage {
  list-style: none;
  justify-content: center;
}

ul.calculatehomepage button {
  width: 259px;
  margin-left: auto;
  margin-right: auto;
}

.latestrates .widthhalf {
  width: auto;
  padding: 0 30px;
}

.bloginnertag {
  filter: drop-shadow(0px 0px 20px rgba(42, 42, 42, 0.2));
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #47b5ff;
  border: 1px solid #47b5ff;
  border-radius: 12px;
  padding: 10px;
  width: max-content;
}

.bloginnertext {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #505050;
}

.bloginnerdate {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #a0a0a0;
}

.bloginner {
  background: #fff;
  padding: 10px;
  cursor: pointer;
}
.blog-modal .mybtn button {
  position: absolute;
  right: 10px;
  top: 10px;
}
.acc-home {
  background: #fff;
}

.homeblogsection.nav-tabs .nav-link {
  padding: 10px 20px;
  border-radius: 7px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #06293f;
  background: url(../images/expertnav.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 58px;
  width: 190px;
  background-position: center;
  cursor: pointer;
}

nav.homeblogsection {
  position: relative;
  display: flex;
  justify-content: center;
}

nav.homeblogsection:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  bottom: -1px;
  height: 10px;
  width: 100%;
  margin: auto;
  z-index: 99;
}

.homeblogsection.nav-tabs .nav-link:nth-child(n + 1) {
  border-left: none;
  border-right: none;
  margin-right: -35px;
}

.homeblogsection.nav-tabs .nav-link.active {
  background: url(../images/hover_nav.svg);
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 9;
}

.homeblogsection.nav-tabs .nav-link:first-child.active {
  background: url(../images/firstnavhover.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  color: #fff;
}

.homeblogsection.nav-tabs .nav-link:last-child.active {
  background: url(../images/lastnavhover.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  color: #fff;
}

img.rimibimagetwo {
  display: none;
  margin-left: 0;
  transition: 0.3s all ease-in;
  
}

.rimibimage {
  transition: 0.3s all ease-in-out;
  border-bottom: 7px solid transparent;
  cursor: pointer;
  min-height: 180px;
    align-items: stretch;
    display: flex;
}

.rimibimage:hover .rimibimageone {
  display: none;
}

.rimibimage:hover .rimibimagetwo {
  display: block;
}

.rimibimage:hover {
  border-bottom: 7px solid #c9daea;
}

.copyright {
  background: #dff6ff;
  padding: 5px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #1363df;
}

.row.acc-home {
  margin: 0;
}

.uploaddetails .button_outer {
  width: 100%;
}

.uploaddetails .button_outer label {
  font-size: 18px;
  color: #fff;
}

.width100 {
  width: 100%;
}

.downcta {
  line-height: 45px;
}



.swal-button--cancel {
  color: #EF000B;

text-align: center;
font-family: "Roboto Flex";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.8px;
background: transparent;
  border: 2px solid #EF000B;
}
button.swal-button.swal-button--confirm {
  color: #000 !important;

text-align: center;
font-family: "Roboto Flex";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.8px;
background: transparent !important;
  border: 2px solid #000;

}

.swal-icon{
  /* background-image: url("../../assets/images/logout.svg"); */
  background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  width: 0px;
  height: 00px;
  border-radius: 0;
  border: none;
  display: none;
}
.swal-title:not(:last-child) {
  margin-bottom: 0 !important;
}
.swal-footer {
  text-align: center;
  padding-top: 0;
  margin-top: 0;
}
.swal-icon--warning__body,
.swal-icon--warning__dot {
  position: absolute;
  left: 50%;
  background-color: #024e7c !important;
  display: none;
}

.swal-title {
  font-size: 16px !important;
  font-weight: 600;
  color: #303030;
  line-height: 24px;
  font-family: "Poppins",sans-serif !important;
}

.listofadbutton .width100 {
  width: 100%;
}

.viewprofilebtn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.004em;
  text-decoration-line: underline;
  color: #024e7c;
  border: none;
  background: transparent;
  display: block;
}

.myaccountsection .form-fieldinner .input-container.form-group .placeholder {
  transform: translateY(-13px) translateX(-1px) scale(1);
  font-size: 12px;
}

a.navbar-brand img {
  width: 180px;
}

.myexpertformarea.d-flex {
  align-items: center;
}

.dashboardrightpannerinnerright .signout {
  display: none;
}

.form-fieldinner.Category-fieldinner .fieldans {
  display: inline-block;
  padding-right: 15px;
}

.form-fieldinner.Category-fieldinner label {
  display: block;
}

.bluetab {
  background: #c9daea;
  /* Grey light */
  border: 1px solid #c3c6c9;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  height: 65px;
}

.bluetab .form-fieldinner {
  margin-bottom: 0;
}

.bluetab .fieldans {
  display: inline-block;
}

.bluetab {
  position: relative;
  display: flex;
  align-items: center;
}

.downarrow {
  position: absolute;
  right: 15px;
  top: 20px;
}

.bluetab .fieldans span {
  background: #c9daea;
  border-radius: 30px;
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.25));
  padding: 3px 10px;
  margin-right: 0px;
  margin-left: 20px;
}

.myexpertnav.myaccountnav > ul > li:last-child.active {
  background: url(../images/lastnavhover.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.accountchangepass
  section.loginscreeninstitute.registrationinstitute.passw
  .col-lg-5.col-sm-5,
.accountchangepass
  section.loginscreeninstitute.registrationinstitute.passw
  .col-lg-7.col-sm-7 {
  width: 100%;
}

.accountchangepass .institutelogintext {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}
.accountchangepass
  .institutelogintext
  .forgetpassinner
  .ft-inner
  .mt-4.ctatwobutton {
  margin-top: 0 !important;
}
.accountchangepass section.loginscreeninstitute.registrationinstitute {
  height: auto;
  background: #dff6ff;
  border-radius: 4px;
}

.accountchangepass section.loginscreeninstitute.registrationinstitute .row {
  align-items: flex-start;
  flex-wrap: wrap-reverse;
}

.accountchangepass .martop60 {
  margin-top: 0;
}

.accountchangepass section.loginscreeninstitute:before,
.accountchangepass section.loginscreeninstitute:after {
  display: none;
}

.accountchangepass
  section.loginscreeninstitute.registrationinstitute.passw
  .back_btn,
.accountchangepass
  section.loginscreeninstitute.registrationinstitute.passw
  .detailsleft.plaindetailsleft
  h1 {
  display: none;
}

.specialinfoinner {
  background: #dff6ff;
  box-shadow: 5px 5px 25px rgba(6, 40, 61, 0.2);
  border-radius: 10px;
  padding: 35px;
}

.expertfileupload {
  background: #ffffff;
  border-radius: 6px;
  padding: 50px;
  height: 321px;
}

.specialinfoinner .modal-window > div {
  background: #f6fdff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 69%;
  position: absolute;
  padding: 2em;
  background: #f6fdff;
  left: 0;
  right: 0;
  margin: auto;
  transform: initial;
  top: 0;
}

.specialinfoinner .modal-body-inner {
  background: none;
  border: none;
  border-radius: 5px;
  width: 70%;
  margin: 30px auto;
  padding: 10px;
}

.modalspecial {
  overflow: auto;
}

label.form-check-label {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #024e7c;
}

.specialinfoinner .form-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #214860;
}

.specialinfoinner .form-field {
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
}

.specialinfoinner .disgrid .form-fieldinner {
  margin: 0;
}

.specialinfoinner .form-field .form-fieldinner .fieldans span {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #06293f;
  background: #c9daea;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 20px;
  display: block;
}

.fieldans.d-flex {
  flex-wrap: wrap;
}

.specialinfoinner textarea.input.form-control.descriptiontextarea {
  height: 321px;
}

.specialinfoinner .expertfileupload h5.text-center.h5_style {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}

.specialinfoinner a {
  text-decoration: none;
}

.specialinfoinner .expertfileupload p.text-center {
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
}

textarea.input:not(:placeholder-shown) ~ .placeholder {
  color: #214860;
  background: #fff;
  top: 14px;
  left: 3px;
  right: 0;
  padding: 12px 11px 4px;
}

.uploadpd img {
  padding: 20px;
  height: 188px;
  object-fit: contain;
}

.specialform .disgrid .form-group {
  margin-bottom: 0;
}

.specialform .mb-4 {
  margin-bottom: 30px !important;
}

#specificationaddedmodal.modal-window {
  background: rgba(223, 246, 255, 0.8);
}

nav.awssld__bullets button {
  background: #c9daea;
  width: 13px;
  height: 13px;
}

.awssld .awssld__bullets {
  position: absolute;
  bottom: -75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.limitedofferinner .awssld__bullets .awssld__bullets--active {
  transform: scale(1.4);
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
}

.limitedofferinner .awssld__content h3 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 10px 30px;
  margin: auto;
  bottom: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  opacity: 0;
}

.offerslider {
  position: relative;
}

.offerslider .ppvawesomesliderdescription {
  position: absolute;
  bottom: 71px;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
}

.ppvawesomesliderproducttype,
.ppvawesomesliderreleatedproduct,
.ppvawesomesliderproductoffer {
  background: #c9daea;
  border-radius: 10px;
  padding: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #06293f;
}

.ppvawesomesliderproducttype {
  width: 60%;
  margin-right: 20px;
}
.text-center.ppvimp {
  margin: 10px 0;
}
.awssld__content h3 {
  text-align: center;
}
.awssld__controls button .awssld__controls__arrow-right {
  opacity: 1;
}
.awssld__wrapper .awssld__controls__arrow-left,
.awssld__wrapper .awssld__controls__arrow-right {
  width: 100%;
  height: 15px;
}

.awssld__wrapper .awssld__controls button {
  background: #c9daea;
  height: 40px;
  width: 40px;
  border-radius: 44px;
}

.awssld__wrapper .awssld__controls__arrow-right {
  position: absolute;
  left: -7px;
}

.awssld__wrapper .awssld__controls__arrow-left {
  position: absolute;
  right: -7px;
}

.listofadbutton a.ctatwobutton {
  display: block;
  text-align: center;
  padding: 7px;
  text-decoration: none;
  line-height: 1.8;
}

.myexpertformarea.myexpertformareaproduct
  .input-container.form-group:first-child {
  width: 71%;
  border-right: none;
}

.myexpertformarea.myexpertformareaproduct
  .input-container.form-group:nth-child(2) {
  width: 168px;
}

.myexpertformarea.myexpertformareaproduct {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.modalspecial {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}
.modalspecial > div {
  background-color: #FFF;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 69%;
  position: absolute;
  padding: 2em;
  
  left: 0;
  right: 0;
  margin: auto;
  transform: translate(-50%, -50%);
  transform: initial;
  top: 80px;
}

.specialinfoinner .modal-body-inner {
  background: none;
  border: none;
  border-radius: 5px;
  width: 70%;
  margin: 30px auto;
  padding: 10px;
}

.modalspecial {
  overflow: auto;
}

.modalspecial .right-institute .myadbtn {
  right: 0;
  position: absolute;
  top: 7px;
}

.modalspecial .right-institute {
  width: 80%;
  margin: auto;
}

.modalspecial .plaindetailsleft {
  padding: 0;
}

.mt-100 {
  margin-top: 100px;
}

.specialinfoinner .col-12 {
  position: relative;
}

button.myadbtn.absoluteup {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 999;
}

button.myadbtn.absolutedown {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

button.productppnbtn,
a.productppnbtn {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* or 150% */
  text-align: center;
  color: #fff;
  padding: 10px;
  margin: 5px;
  text-decoration: none;
}
.offerbannerslider .slick-current{transform: scale(1);}
.specialinfoinner .form-field {
  position: relative;
}

.limitedoffer .awssld__content {
  overflow: hidden;
  display: flex;
  /* align-items: flex-start; */
  justify-content: center;
}

.limitedofferinner .awssld__content img {height:600px;}
.topexpert .p_sliderouter{padding-bottom: 0;}
/*Ask the expert*/
@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }

  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }

  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

.waveWrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  margin: auto;
  height: 200px;
}

.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
}

.bgTop {
  z-index: 15;
  opacity: 0.5;
}

.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}

.bgBottom {
  z-index: 5;
}

.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}

.waveTop {
  background-size: 50% 100px;
  background: url(../images/wave1.svg);
}

.waveAnimation .waveTop {
  animation: move-wave 3s;
  -webkit-animation: move-wave 3s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.waveMiddle {
  background-size: 50% 120px;
  background: url(../images/wave2.svg);
}

.waveAnimation .waveMiddle {
  animation: move_wave 10s linear infinite;
}

.waveBottom {
  background-size: 50% 100px;
  background: url(../images/wave1.svg);
}

.waveAnimation .waveBottom {
  animation: move_wave 15s linear infinite;
}

.heroasktheexpert {
  position: relative;
  padding-bottom: 200px;
}

.categoryonesettwo {
  width: 160px;
  height: 150px;
  background: #c9daea;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
.categoryonesetone p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #214860;
  margin-bottom: 0;
}

.categoryonesetone h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #06283d;
}

p.categoryonesetonetext {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  color: #06293f;
}

.iconone {
  transition: 0.3s all ease-in-out;
}

.iconone:after {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
}

div#g-recaptcha {
  transform: scale(0.77);
  display: flex;
  justify-content: left;
}

.categoryonesettwo .icontwo {
  display: none;
}

.categorytwosetfirst.categorytwoset {
  width: 40%;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-left: 0;
  clear: both;
  float: left;
  animation: fadeIn 6s;
}

.categorythreeset {
  width: 39%;
  float: right;
}
.categorythreeset .categoryonesetone {
  padding-right: 155px;
}
.footerlogo {
  margin-bottom: 20px;
}
section.termscondition ol li {
  list-style: none;
}

section.termscondition ol {
  padding: 0;
}

section.termscondition ol h3, .terminner h3 {
  margin-top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #505050;
}
.terminner h4{margin-top: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 36px;
  color: #505050;}
section.termscondition h2, .terminner h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: #06283d;
}
.asktheexpertcategorysectioninner {
  margin: auto;
}
.faqsection .accordion-item {
  background: #ffffff;
  border: 1px solid #c3c6c9;
  border-radius: 4px;
  margin-bottom: 40px;
}
.categoryoneset {
  width: 42%;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  float: right;
  animation: fadeIn 2s;
}
@keyframes slide-left {
  from {
    margin-left: 100%;
  }
  to {
    margin-left: 0%;
  }
}

.categoryonesettwo h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  position: relative;
  z-index: 9;

  color: #06283d;
}

section.faqsection {
  background: #dff6ff;
  padding: 100px 0;
  margin-top: 0px;
}

.asktheexpertcategorysectioninner .grid-3,
.asktheexpertcategorysectioninner .grid-4 {
  grid-gap: 10px;
}

.categoryfourset {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  justify-content: space-between;
  align-items: center;
}

.categoryfourset .categoryonesetone {
  width: 30%;
}
.categoryfourset .awssld__container, .categorythreeset .awssld__container {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100px;
}
.categoryfourtwo {
  background: #dff6ff;
  box-shadow: 0px 4px 6px rgba(6, 40, 61, 0.4);
  border-radius: 10px;
  padding: 20px;
}

.slidercategory {
  background: #ffffff;
  border: 0.6px solid #06293f;
  border-radius: 4px;
  padding: 20px;
}

.categoryonesetwidth {
  width: 110px;
  height: 120px;
  margin-bottom: 10px;
}

.categoryonesetwidth h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #06283d;
  padding: 0 5px;
}

.textarecs {
  background: #dff6ff;
  box-shadow: 0px 4px 6px rgba(6, 40, 61, 0.4);
  border-radius: 10px;
  padding: 30px;
  border-top: 2px solid #06283d;
}

.textarecs textarea.input.form-control.textareaform {
  background: #ffffff;
  border: 1px solid #c3c6c9;
  border-radius: 3px;
  height: 103px;
}
.mainexpform {
  background: #fff;
}
.textarecs .form-group {
  margin-bottom: 0;
}

.mainexpform h4 {
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  /* or 188% */
  text-align: center;
  color: #000000;
}

.mainexpform h5 {
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  /* or 231% */
  text-align: center;
  color: #000000;
}

.mainexpforminner {
  background: #dff6ff;
  box-shadow: 0px 4px 6px rgba(6, 40, 61, 0.4);
  padding: 30px;
}

.mainexpforminner .form-group {
  margin-bottom: 0;
}

.asktheexpertcategorysectioninnerline {
  position: relative;
}
.categoryoneset:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background: #06293f;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}
.categorytwoset:after,
.categorythreeset:after,
.categoryfourset:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background: #06293f;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  top: 0;
}
.categoryfourset:after {
  height: 150%;
}
.categoryonesettwo:after {
  content: "";
  position: absolute;
  left: 0;
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  width: 0;
  height: 100%;
  top: 0px;
  opacity: 0;
  transition: all 0.35s ease;
  z-index: 1;
  border-radius: 10px;
}
.categoryonesettwo:hover:after {
  width: 100%;
  opacity: 1;
}
.categoryonesettwo:hover .iconone {
  display: none;
}
.categoryonesettwo:hover .icontwo {
  display: block;
  position: relative;
  z-index: 9;
}
.categoryonesettwo:hover h5 {
  color: #fff;
}
.catstart {
  position: relative;
}
.catstart::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: url(../images/categoryspin.svg);
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
}

.logochangemodal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s;
}

.logochangemodal:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.logochangemodal > div {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: #f6fdff;
}
.logochangemodal .modal-header {
  justify-content: center;
}
.logochangemodal .modal-footer {
  justify-content: space-around;
}
.logochangemoda .ctatwobutton {
  height: 57px;
}
.width100 {
  width: 100px;
}
.productlistdetails-in .headeing h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #06293f;
}
.customaccordian ul li {
  transition: opacity 0.3s ease-in-out;
  position: relative;
}
.customaccordian ul li:before {
  content: "";
  position: absolute;
  left: 0;
  background: #dff6ff;
  width: 0;
  height: 6px;
  top: 0px;
  opacity: 0;
  transition: all 0.35s ease;
}
.customaccordian ul li img.plus:hover {
  transform: rotate(60deg);
}

.offerslider .awssld__container {
  padding-bottom: 650px;
}
.listofimage img {
  width: 100%;
}
.myplandashboard .mainplan {
  background: transparent;
  border-radius: 0;
  padding: 0;
}

.listofimage {
  background: #fff;
}
.myplandashboard .planesinner ul li {
  font-size: 15px;
}
.dashboardrightpanel .myplandashboard .planesinner ul li{font-size: 13px;    height: 56px;
  display: flex;
  align-items: center;}
.dashboardrightpanel .myplandashboard .tooltipcontent{padding: 5px;font-size: 12px;
  line-height: 115%;}
button[disabled]::after,
button[disabled]::before {
  display: none;
}

.replacelogo2{cursor: pointer;}
.tagin {
  position: absolute;
  right: 15px;
  top: 20px;
}

.tagin .tooltipcontent {
  left: -200px;
  top: -40px;
  right: 0;
  width: 217px;
}
span.plantooltip.custooltip.tagin:hover .tooltipcontent {
  visibility: visible;
  left: -200px;
  top: -40px;
  right: 0;
  width: 217px;
}
.categoryaskecpertconfirminner {
  width: 160px;
  height: 150px;
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  padding: 10px;
}
.categoryaskecpertconfirm h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #06283d;
  text-align: center;
}

.categoryaskecpertconfirmsub h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #06283d;
  text-align: center;
}

.categoryaskecpertconfirminner h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #dff6ff;
  padding: 5px 10px;
}

.categoryaskecpertconfirm {
  width: 200px;
  margin: auto;
  position: relative;
  top: 50px;
}
.categoryaskecpertconfirmsub {
  width: 200px;
  margin: auto;
  position: relative;
  top: 50px;
}
.faqname {
  background: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 15px;
}
.herobutton button.ctatwobutton {
  width: 200px;
  margin-right: 17px;
}
.herosection a.ctaonebutton , .heroleftbutton.experthomepagebutton a.ctaonebutton{
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #f6fdff;
  transition: 0.3s all ease-in-out;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: auto;
  color: #fff;
  position: relative;
  transition: 0.3s all ease-in-out;
  padding: 10px 50px;
  border: 1px solid #024e7c;
}
.herosection a.ctatwobutton, .heroleftbutton.experthomepagebutton a.ctatwobutton{
  background: #dff6ff;
  border: 1px solid #c3c6c9;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #024e7c;
  transition: 0.3s all ease-in-out;
  display: block;
  width: auto;
  padding: 10px 50px;
  text-align: center;
  margin-left: 20px;
  line-height: initial;
}
section.latestrates {
  background: #dff6ff;
  padding: 50px 0;
}
.herobutton button.ctaonebutton {
  width: 300px;
}
section.latestrates .heading h2 {
  margin-top: -23px;
}
.herobutton.mt-4.d-flex {
  justify-content: center;
}
.subcatchal {
  width: 160px;
  height: min-content;
}
.heroconfirmexpert {
  padding: 100px 0 185px;
}

.heroconfirmexpert .waveWrapper {
  bottom: -427px;
}
.confirmtion {
  width: 50%;
  margin: auto;
}
section.searchpageherosection {
  background: #dff6ff;
  padding: 50px 0;
}

.searchinnerhero {
  width: 58%;
}
.myexpertformarea.searcharea .input-container.form-group:first-child {
  width: auto;
  border-right: 1px solid #ccc;
}
.myexpertformarea.searcharea .input-container.form-group:nth-child(2) {
  width: auto;
  border-right: 1px solid #ccc;
}
.myexpertformarea.searcharea .input-container.form-group:nth-child(3) {
  border-right: 1px solid #ccc;
}
.myexpertformarea.searcharea {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.myexpertformarea.searcharea form {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.myexpertformarea.searcharea .input-container.form-group {
  border: none;
  display: block;
  margin: 0;
  width: 20%;
  vertical-align: middle;
}
.heroasktheexpert .row {
  align-items: center;
}
.categoryfourset nav.awssld__bullets, .categorythreeset nav.awssld__bullets {
  display: none;
}
.categoryfourset .awssld, .categorythreeset .awssld {
  position: relative;
  width: 38%;
  display: block;
  background: #dff6ff;
  box-shadow: 0px 4px 6px rgba(6, 40, 61, 0.4);
  border-radius: 10px;
  padding: 10px;
}
.categorythreeset .awssld{width: 100%; margin-top: 30px;}
.categoryfourset .categoryonesetone {
  padding-left: 90px;
}
.catstart .awssld__content > div {
  background: #ffffff;
  border: 0.6px solid #06293f;
  border-radius: 4px;
  padding: 10px;
  width: 70%;
}
.categoryonesetfirst {
  width: 42%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}
.rti--container:focus-within {
  border: none;
  outline: none;
  box-shadow: none;
}
.wtag .my-classfocus .rti--input {
  padding-top: 30px;
}
.wtag .rti--tag {
  margin-top: 20px;
}
.categorytwosetfirst {
  width: 40%;
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  clear: both;
  animation: fadeIn 5s;
}
.questioninnerin {
  
  box-shadow: 4px 4px 6px rgba(6, 40, 61, 0.25);
  border-radius: 10px;
  padding: 10px;
}

.questioninnerin p {
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.008em;
  color: #000000;
}

.questioninnerinbottom button.ctaonebutton {
  width: 200px;
}

.questioninnerinbottom {
  justify-content: space-between;
  align-items: center;
}

.expertquestiondetails {
  display: flex;
  align-items: center;
}

.expertquestiondetailscategory span {
  background: #c9daea;
  border-radius: 2px;
  padding: 5px 10px;
  font-family: 'Poppins' ;
  font-size: 20px;
  font-weight: 500;
}

.expertquestiondetails .expertuser {
  width: 60px;
  margin-right: 10px;
}

.expertquestiondetails .questiondate {
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */
  letter-spacing: 0.008em;

  /* Text H */
  color: #214860;
}

.questionid {
  font-size: 13px;
  line-height: 15px;
  /* identical to box height, or 115% */
  letter-spacing: 0.008em;

  /* Prussian Blue */
  color: #06293f;
}
.qbtn button.ctaonebutton {
  width: 100px;
  display: flex;
  margin-left: 10px;
}

.qbtn {
  display: flex;
}
.myexpertnav.myaccountnav.qnanav ul li {
  width: 272px;
  padding: 10px;
}
.myexpertnav.myaccountnav.qnanav ul li span {
  background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
  border-radius: 10px;
  padding: 3px 8px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.myexpertnav.myaccountnav.qnanav ul li.active span {
  background: #f6fdff;
  border-radius: 10px;
  padding: 3px 8px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #043d5f;
}
.sldeimage img {
  width: 100%;
  object-fit: contain;
}
.productslider .slick-slide {
  padding: 27px;
  text-align: center;
}
.p .slick-slide {
  /* padding: 20px; */
}

.slbtn {
  margin-top: 30px;
  transition: 0.3s all ease-in;
  animation: fadeIn 5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
 

.slbtn .ctaonebutton:first-child {
  margin-right: 10px;
}


.slick-current .slbtn {
  visibility: visible;
  display: block;
}



/*-----video----*/
.wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-main {
  position: relative;
  display: inline-block;
}

.video {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background: transparent;
  color: #fff;
  display: inline-block;
  background: #000000;
  z-index: 999;
}

@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.fa-play:before {
  content: "\f04b";
}

.waves {
  position: absolute;
  width: 150px;
  height: 150px;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -50px;
  bottom: -50px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.col-lg-7 section.listofoffermainsection {
  background: transparent;
}

.col-lg-7
  section.listofoffermainsection
  .leftsightbackdetails.listofofferleft.padtop60 {
  display: none;
}

.col-lg-7 section.listofoffermainsection .specialinfoinner.mt-5.mb-5 {
  background: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  margin-top: 0 !important;
}
button.ctaonebutton.border-radius-50::after,
button.ctaonebutton.border-radius-50::before {
  display: none;
}
.specialisationppv .heading h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #dff6ff;
  background: #024e7c;
  border-radius: 0px 0px 20px 20px;
  width: max-content;
  margin: auto;
  padding: 6px 20px;
}
section.specialisationppv {
  background: #dff6ff;
}
section.specialisationppv .heading h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  color: #000000;
}
.userdetails img {
  width: auto;
  object-fit: contain;
  margin: auto;
}
.p_slider .useblog .userdetails img{width: 40px;}
.useblog.d-flex {
  align-items: center;
  justify-content: left;
  margin: 18px 0;
}

.userpop h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  /* text */
  color: #2a2a2a;
}

.userpop .date {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;

  /* Text default */
  color: #777b7e;
}

.userpop {
  text-align: left;
}

.slidertext h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.004em;

  /* text */
  color: #2a2a2a;
  text-align: left;
}

.slidertext p {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;

  /* text */
  color: #2a2a2a;
}
.myblogppv.topexpert {
  background: #f6fdff;
}
.backwhite {
  background: #fff;
  padding: 20px;
}
.aboutppv {
  padding: 60px 0;
}
.aboutppvnav ul {
  margin: 0;
  padding: 0;
}
.specialisationppv section.latestrates .container {
  background: rgba(2, 78, 124, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 4px 4px;
  padding: 23px;
  border-radius: 30px;
}

.specialisationppv section.latestrates .detailsbank.bg-white-new h4, .specialisationppv section.latestrates .bg-white-new .detailsbank h4 {
  color: #307096;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.specialisationppv section.latestrates .detailsbank.bg-white-new h5 ul{list-style: none; padding: 0;}
.specialisationppv section.latestrates .detailsbank.bg-white-new h5 ul li {
  border-radius: 12px;
  border: 1px solid #306F96;
  display: flex;
  width: 100%;
  padding: 0 10px;
  margin-right: 15px;
  flex-wrap: wrap;
}
.specialisationppv section.latestrates .bg-white-new h3, .specialisationppv section.latestrates .bg-white-new .detailsbank h3
{
color: #000;
margin-bottom: 30px;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
}

.specialisationppv section.latestrates .detailsbank.bg-white-new h5, .specialisationppv section.latestrates .bg-white-new .detailsbank h5{
  color: #000;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.specialisationppv section.latestrates .detailsbank h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #c9daea;
}
.bloginnerimage img {
  width: 100%;
}
.detailsbank h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  color: #ffffff;
}
.socialpublic ul li {
  background: #dff6ff;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
}
.basicdetails.d-flex {
  align-items: center;
}
ul.dethree.ppvthree li span {
  background: none;
  border-radius: 0;
  width: auto;
  height: auto;
  display: inline-block;
  text-align: left;
}
.faqsection .accordion-button:not(.collapsed) {
  background: transparent;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #214860;
}
.faqsection .accordion-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #214860;
}
.accordion-button:focus {
  box-shadow: 0 !important;
}
.p_sliderouter {
  filter: drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.15));
  padding-bottom: 60px;
}

.center-slider .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  transition: all 0.4s ease-in-out;
}
.center-slider .slick-slide,
.center-slider
  .slick-slide[aria-hidden="true"]:not(.slick-cloned)
  ~ .slick-cloned[aria-hidden="true"] {
  transform: scale(1);
  transition: all 0.4s ease-in-out;
}

/* Active center slide (You can change anything here for cenetr slide)*/
.center-slider .slick-center,
.center-slider
  .slick-slide[aria-hidden="true"]:not([tabindex="-1"])
  + .slick-cloned[aria-hidden="true"] {
  transform: scale(1.1);
}
.center-slider .slick-current.slick-active {
  transform: scale(1.1);
}
.homecustom button {
  transition: 0.3s all ease-in;
  
}
.homecustom button:hover {

  transition: 0.3s all ease-in;
}
.homecustom button.ctatwobutton:hover {
  background: linear-gradient(90.46deg, #06293f 0%, #024e7c 100%);
  color: #fff;
  transition: 0.3s all ease-in;
}
.homecustom .ctaonebutton{border:1px solid transparent; padding: 10px 20px;}
.herosection a.ctaonebutton:hover, 
.heroleftbutton.experthomepagebutton a.ctatwobutton:hover, 
.heroleftbutton.experthomepagebutton a.ctaonebutton:hover
 {
  /* animation: pulse 1.5s; */
  background: transparent;
  color: #024e7c ;
  transition: 0.3s all ease-in;
}
.herosection a.ctatwobutton:hover{background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%); color:#fff;}
.herosection a.ctaonebutton:after,
.herosection a.ctaonebutton:before, .heroleftbutton.experthomepagebutton a.ctaonebutton:after, .heroleftbutton.experthomepagebutton a.ctaonebutton:before {
  display: none;
}
@keyframes pulse {
  0% {
    transform: scale(0.85);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}

.slick-slide .adscreenone img{margin: auto;}
.faqsection .accordion-button::after{background: url(../images/accordianarrow.svg);
    background-size: contain !important;
    width: 30px;
    height: 30px;
    padding: 5px;
    background-repeat: no-repeat;}
	.faqsection .accordion-item .show{box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);}
	.faqsection .accordion-button:not(.collapsed)::after{background: url(../images/accordianarrow.svg);
		background-size: contain !important;
		width: 30px;
		height: 30px;
		padding: 5px;
		background-repeat: no-repeat;}
		/* .faqsection .accordion, .faqsection .accordion-item, .faqsection .accordion-body{border-width:0 !important; border:none; border-color: transparent;} */
    .slidertext-pp{text-align: left;     min-height: 170px;}
    .faqsection .accordion-item:not(:first-of-type) {
      border-top: 1px solid #c3c6c9;
  }
  button.prifileview.mt-3:hover {
    background: #fff;
}
ul.deone li {
  position: relative;
  padding-left: 30px;
}

ul.deone li span {
  position: absolute;
  left: 5px;
  top: 3px;
}
.viewprofile button.ctaonebutton {
  padding: 10px 20px;
}
.viewprofile button.ctaonebutton {
  padding: 10px 20px;
}

.searchresultsection .expertuser img {
  border-radius: 64px;
  width: 100px;
  height: 100px;
  border: 6px solid #C9DAEA;
}
.categoryonesetone.anotherexp {
  grid-column: 1 / 3;
}
.right-institute .modal-header.text-center {
  justify-content: center;
}
.modalspecial .back_btn {
  position: absolute;
  left: 0;
}

.right-institute {
  position: relative;
}
.myexpertformarea.searcharea.searchmainara .input-container.form-group:first-child {
  width: 70%;
  border: none;
}
.searchmainara {
  width: 70%;
  margin: 20px auto;
}
.searchpageherosection  .searchmainara {
  width: 50%;}
.searchmainara 
button.ctaonebutton {
  width: 200px;
  margin-left: auto;
}
section.backwhite{background: #fff; border-radius: 0 0 20px 20px; padding: 0 20px 20px 20px;}
.adscreenheight {
  height: 150px;
}
.categoryonesettwo.active:after {
  width: 100%;
  opacity: 1;
}
.categoryonesettwo.active h5{color:#fff;}
.categoryonesettwo.active .icontwo{display: block; z-index: 9;}
.categoryonesettwo.active .iconone{display: none;}
.modalspecial ul li {
  word-break: break-all;
  list-style: none;
  padding:15px;
  font-size: 13px;
}
.modalspecial ul li:hover{
  background: linear-gradient(135deg, #024e7d 0%, #062a40 100%);
  color: #fff;
}
.modalspecial ul {
  background: #fff;
  padding: 0px;
  margin-top: -15px;
  border-radius: 5px;
  height: 200px;
  overflow-y: scroll;
}
.fieldans {
  word-break: break-all;
}
.offer-insurence-inner {
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;
}
.offer-insurence-inner .offer-name {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0.018em;
  color: #000000;
}
a.ctatwobutton {
  text-decoration: none;
  line-height: 45px;
  color: #000;
}

.backan{
background: #DFF6FF;
box-shadow: 0px 10px 20px rgba(69, 69, 69, 0.15);
}
.backan .homepageexpertinnername {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.018em;
  color: #06283D;
}
.backan .prifileview {
  background: radial-gradient(117.5% 117.5% at 27.25% -5%, #06283D 51.65%, #47B5FF 100%);
  box-shadow: 0px 10px 20px rgba(69, 69, 69, 0.15);
  border-radius: 30px;
  color: #fff;
}
.colinstititeinnername {
  font-weight: 500;
  font-size: 25px;
  line-height: 60px;
  color: #000000;
  margin-top: 20px;
}

ul.detwo.ppvtwo:before{display: none;}
ul.dethree.ppvthree:before{display: none;}

ul.detwo.ppvtwo li span img {
  padding-right: 5px;
}
.expertinnsearch {
  background: #DFF6FF;
  border-radius: 15px;
}

.expertinnsearchname {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
/* identical to box height */
  letter-spacing: 1.8px;
  color: #000000;
  padding: 15px;
}
.publicdeinnerq {
  position: relative;
  z-index: 9;
}
.publicviewprofiledetails.publicviewprofiledetailsblog:before{height: 100%;}
section.publicviewprofiledetails.padtop60.paddingbot60.publicviewprofiledetailsblog {
  margin-bottom: 0;
}
.blogdetailsexpertppv .p_slider {
  background: #FCFCFC;
  box-shadow: 0px 4px 40px rgba(42, 42, 42, 0.1);
  border-radius: 15px;
  margin-bottom: 30px;
}
.expertinnsearchimage img {
  width: 100%;
}


.catspecial span:nth-child(2) {
  padding-left: 30px;
}

.catspecial button {
  margin-left: 10px;
  width: 150px !important;
}
.myblogppv .slick-prev:before{width:25px; height: 25px;}
.myblogppv .slick-next:before{width:25px; height: 25px;}
.myblogppv .slick-current {
  transform: scale(1.1);
  margin-left: 60px;
  margin-right: 60px;
}
.myblogppv .slick-track {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
}
.awardsectionmyaccount .form-fieldinner ul li {
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.25));
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
/* identical to box height, or 140% */
  color: #DFF6FF;
  background: linear-gradient(93.34deg, #024d7b 0%, #062a40 99.85%);
  padding: 6px 20px;
  border-radius: 30px;
}

.awardsectionmyaccount .form-fieldinner ul {
  padding: 0;
  display: inline-block;
  margin-right: 10px;
}

.awardsectionmyaccount .form-fieldinner label {
  display: block;
}

.dashboardrightpanel section.listofoffermainsection {
  background: #fff;
  margin-bottom: 30px;
}

.dashboardrightpanel section.listofoffermainsection .plaindetailsleft {
  padding: 0;
}

.dashboardrightpanel section.listofoffermainsection .specialinfoinner.mt-5.mb-5 {
  background: transparent;
  padding: 0;
}
.dashboardrightpanel section.listofoffermainsection .specialinfoinner.mt-5.mb-5 button.myadbtn.absoluteup
{bottom: 15px; top: auto;}
.dashboardrightpanel section.listofoffermainsection .specialinfoinner.mt-5.mb-5  button.myadbtn.absolutedown
{top: 15px; bottom: auto; z-index: 99;}
.homepageproductoffer h2.h2_style{font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;  
  text-align: center;
  margin-top:70px;
  color: #06293F;
margin-bottom: 30px;}
.slick-prev, .slick-next{z-index: 99;}
.advertisehome .slick-current{transform: none;}
.p_title{
  text-align: left;
  font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
color: #000000;
margin-bottom: 10px;
min-height: 52px;
}
.p_slider .slbtn.slidertext-pp h5 {
  min-height: 52px;
  text-align: center;
}
.homepageexpertinneruser {
  width: 100px;
  margin: auto;
}
.publicdeinnerts .publicmainpopdis.basicdetails {
  width: 50%;
}
.rateinterest {
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 10px;
  height: 158px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.004em;

/* Prussian Blue */
  color: #06293F;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.rateinterest-percentage {
  width: 100%;
}

.rateinterest-sec {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -11px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  background: #DFF6FF;
  width: max-content;
  padding: 0 10px;
  box-shadow: none;
}
.padbottom100{padding-bottom: 100px;}
.innerpagesform .form-group label {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
/* identical to box height, or 125% */
  letter-spacing: 0.018em;
  color: #06293F;
  width: 50%;
}

.innerpagesform .form-group input, .innerpagesform .form-group select {
  background: #FFFFFF;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  width: 40%;
}

.innerpagesform {
  width: 90%;
  margin: auto;
}
.innerpagesform label.container-checkbox {
  width: 100%;
  font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 24px;
text-align: center;
letter-spacing: 0.008em;

color: #06293F;
}
.innerpagesform  .container-checkbox .checkmark{left: 0; right: auto;}
.form-grouptravel {width: 50%; margin: auto;}
header ul.navbar-nav li.active a:before{
content: "";
    position: absolute;
    left: 0;
    background: #dff6ff;
    width: 100%;
    height: 6px;
    top: -10px;
    opacity: 1;
    transition: all 0.35s ease;}
    header ul.navbar-nav li.active a:after {
      content: "";
      position: absolute;
      right: 0;
      background: #dff6ff;
      width: 100%;
      height: 6px;
      bottom: -16px;
      opacity: 1;
      transition: all 0.35s ease;
  }
  a.ctaonebutton.widthhalf.mt-4 {
    width: 200px;
}
table.searchdetailsdb tr th {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  color: #E6E6E6;
  padding: 10px;
 
}
table.searchdetailsdb tr:first-child{ background: linear-gradient(90.46deg, #024E7C 0%, #06293F 100%);
  border-radius: 10px 10px 0px 0px;}
  .tabletextbox{width: 60px;}
  table.searchdetailsdb tr td  {
    font-weight: 500;
font-size: 14px;
line-height: 19px;
color: #2A2A2A;
padding: 10px;
  }
  .entiredashboard.admindashboard > .container > .row > .col-lg-3.col-sm-3{box-shadow: none;}
  table.searchdetailsdb tr{
  background: #DFF6FF;
border-radius: 4px; margin-bottom: 10px;}
table.searchdetailsdb {
  width: 100%;
  margin: 30px 0;
}
table.searchdetailsdb button.ctaonebutton {
  padding: 5px 10px;
  height: auto;
  width: auto;
  font-size: 14px;
  display: inline-block;
}
table.searchdetailsdb .container-checkbox{display: inline-block;}
.searchresultsection .expertinner ul li:first-child {
  width: 20%;
}
.searchresultsection .expertinner ul li:nth-child(2) {
  width: 30%;
}
.searchresultsection .experdetails ul{justify-content: start;}
.categoryaskecpertconfirm .categoryaskecpertconfirminner img {
  filter: brightness(0) invert(1);
}
.width100border{width:100% !important; border:none !important;}
.width100{width:100%;}
.blogdetailsexpertppv .bloginnerimage img {
  height: 200px;
  object-fit: cover;
  border-radius: 20px;
}

.bloginner {
  text-decoration: none;
}

section.homepageproductoffer.blogdetailsexpertppv a {
  text-decoration: none;
}
a.prifileview.mt-3 {
  display: inline-block;
  padding: 5px 14px;
  text-decoration: none;
}

.ppvawesomesliderdescription {
  transition: 0.3s all ease-in;
}

.sliderContent:hover .ppvawesomesliderdescription {
  opacity: 1;
}

.ppv section.entiredashboard:before {
  display: none;
}

  .martop0{margin-top: 0;}
  .searchmaininnerhero.heroleftpanel h2.h2_style{font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    color: #06293f;}
    .myexpertformarea.insnewsearch.searcharea.martop40 > div {
      flex: 1;
  }
  .modalspecial ul:empty {
    height: auto;
}

.productslider  .dropdefaultimage img {
  margin: auto;
}
.p_slider .ctaonebutton{font-size: 15px;}

.p .slick-current{transform: scale(1);}
strong.adminnot {
  background: linear-gradient(90.46deg, #024E7C 0%, #06293F 100%);
  color: #fff;
  font-size: 13px;
  font-weight: 200;
  position: relative;
  display: block;
  padding: 5px;
  min-height: 35px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  line-height: 23px;
}
.categoryonesettwo .iconone img, .categoryonesettwo .icontwo img {
  height: 45px;
}

.qmodal > div{height: 600px;
  overflow-y: scroll;}
  .qmodal .mybtn{position: absolute; right: 0px; top:0;}
  
section.ppv .myexpertformarea {
    position: relative;
    margin-top: 70px;
}

section.ppv .myexpertformarea:before {
    content: 'Our Experts';
    position: absolute;
    top: -60px;
    left: 0;
    z-index: 99;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 26px;
}
section#ask {
    padding: 50px 0;
}
.managein{ position: relative; cursor: pointer;}
.adminnot{position: relative; cursor: pointer;}
.adminnot:after {
  position: absolute;
  background: url(../images/files.png);
  content: "";
  height: 15px;
  width: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;

}

.myaccountsection.institutedetails .grid-4 .form-fieldinner  span.text-danger{bottom: -28px;}
.myexpertnav.myaccountnav.palnnav ul li span {
  display: block;
  font-size: 10px;
  margin-top: -10px;
}
.footertwo {
  margin-left: 90px;
}
.myexpertformarea.searcharea.searchmainara.searchmainarainner .input-container.form-group:first-child {
  width: 49%;
  border-right: 1px solid #ccc;
}
.myexpertformarea.searcharea.searchmainarainner .input-container.form-group:nth-child(2) {
  width: 49%;
  border-right: none;
}
/*Serarch*/
#sb-search .sb-search-icon.float {
  position: fixed;
  display: flex;
  border: 3px #fff solid !important;
  box-shadow: 0 4px 12px rgba(0,0,0,.1);
  margin-right: 0;
  right: 15px !important;
  top: 22px !important;
  width: 40px;
  height: 40px;
  left: auto !important;
  background: #06283d !important;
}
#sb-search .sb-icon {
  font-size: 18px !important;
  padding: 2px 7px !important;
  cursor: pointer !important;
  width: 30px !important;
  height: 30px !important;
}
#sb-search .sb-search-icon.float svg {
  width: 20px;
}
.topexpert .slick-current .p_sliderouter {
  padding-bottom: 23px;
}
#sb-search .sb-content .sb-results .sb-brand{display: none !important;}
#react-select-2-listbox {
  z-index: 999999999;
  position: relative;
}
.limitedofferinner nav.awssld__bullets {
  display: none;
}
.backdeepblue {
  background: #BCCDDE;
}


#react-select-2-listbox {
    z-index: 999999999;
    position: relative;
}
.alldashboard .dashboardrightpanel {
  padding: 0;
}

.offerslider:hover .awssld__content .ppvawesomesliderdescription {
  opacity: 1;
}
div#sb-call-to-action {
  display: none !important;
}
.sb-modal.sb-animate-bottom .sb-search-icon svg {
  width: 16px;
}
.entiredashboard section.herosection.heroasktheexpert {
  display: none;
}

.entiredashboard section#faq {
  display: none;
}
.entiredashboard .categorytwosetfirst{float: right;}
.entiredashboard .categorythreeset .categoryonesetone {
  padding-right: 0;
  padding-top: 50px;
}
.entiredashboard .categoryfourset .categoryonesetone {
  padding-left: 0; float: right;
}
.entiredashboard .categoryfourset {
  display: block;
  float: right;
}
.entiredashboard .categoryfourset .awssld, .entiredashboard .categoryonesetfirst, .entiredashboard .categorytwosetfirst, .entiredashboard .categorythreeset, .entiredashboard .categoryfourset .categoryonesetone{width: 70%;}
.entiredashboard .categoryoneset:after, .entiredashboard .catstart::before, .entiredashboard .categorytwoset:after, .entiredashboard .categorythreeset:after, .entiredashboard .categoryfourset:after{right:auto}
.entiredashboard .categoryoneset:after,  .entiredashboard .categorytwoset:after, .entiredashboard .categorythreeset:after, .entiredashboard .categoryfourset:after{left:17px;}
.entiredashboard .categoryfourset .awssld{ float: right;  padding-top: 50px;}
button#mobilemenu:hover, button#mobilemenu:focus, button#mobilemenu {
  color: transparent;
}
.textbold {
  font-size: 83px;
  color: transparent;
  font-weight: 800;
  -webkit-text-stroke: 3px #024e7c;
}
.colsec {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: auto  !important;
}

.colsec a {
  text-decoration: none;
}
.ppvawesomesliderproducttype, .ppvawesomesliderreleatedproduct, .ppvawesomesliderproductoffer{
  background: rgba(6, 40, 61,0.7);
    border-radius: 10px;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #fff;
    backdrop-filter: blur(11px);
}
.ppvawesomesliderproducttype h4, .ppvawesomesliderreleatedproduct h4, .ppvawesomesliderproductoffer h4{
  color: #fff;
  font-size: 20px;
}
i.sb-icon.icon-ic_search svg path{fill:#fff !important}
i.sb-icon.sb-back-icon.icon-ic_back svg path {
  fill: #06283d !important;
}
#sb-search .sb-search-icon{background-color: #06283d !important;}

.slick-slider {
  touch-action: auto !important;
}
#cross {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 99999;
  left: 0;
  right: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  text-align: center;
}
#cross:before, #cross:after {
  content: "";
  position: absolute;
  z-index: -1;
  background: #fff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transform: rotate(45deg);
}
.homecustom button:hover #cross:before, .homecustom button:hover #cross:after{background: #024b78;}
#cross:before {
  width: 2px;
  height: 16px;
}

#cross:after {
  height: 2px;
  width: 16px;
}
section.loginscreeninstitute.loginmainds{position: relative;
  display: flex;
  align-items: center;
  /* height: 100vh; */
  padding: 200px 0;
}
  .productslider .p_slider h5 {
    min-height: 45px;
    font-size: 18.88px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 23.01px;
}
section.topexpert.topexpertproduct {
  padding: 30px 0;
  margin-top: 50px;
}

section.topexpert.topexpertproduct h2 {
  margin-top: 0 !important;
}
#sb-search .sb-icon svg, .sb-darkmode .sb-content .sb-results .sb-brand img, .sb-darkmode .sb-icon.sb-back-icon svg
{filter: none !important;}
.paymentdis {
  border-radius: 5px;
  background: #DFF6FF;
  padding: 30px;
}
button.ctaplanbutton {
  border-radius: 4px;
  border: 1px solid #414446;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 40px;
}
.paymentdis button.ctaonebutton {
  width: auto;
  padding: 10px 50px;
}

/*---Offer---*/
.offerslider .slick-current{transform: scale(1);}
 .offerslider .sliderContent .ppvcat h3,  .offerslider .sliderContent .ppvdate h3 {
  opacity: 1;
  color: #06293f;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.offerslider .sliderContent h3 {
  opacity: 1;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #06293f;
}
.offerslider .ppvslidercontentoverlay{height: 78%;}
.mobile_description{opacity: 0; display: none;}
.sliderContent button.ctaonebutton.widthhalf {
  margin-top: 40px;
}
.sliderContent {
  position: relative;
}
section.herosectionoffer {
  position: relative;
}

.offerbannerslider {
  position: absolute;
  right: 14%;
  top: 30%;
  left: auto;
  width: 43%;
  background: #fff;
  padding: 10px 10px 0;
}
.productbannerslider{
  position: absolute;
  left: 14% ;
  right: auto;
  top:27%;
  left: auto;
  width: 43%;
  background: #fff;
  padding: 10px;
}

.expertinner .expertuser img {
    border-radius: 100px;
    height: 100px;
    width: 100px;
    object-fit: cover;
}
.offerbannerslider .sliderContent h5 {
  text-align: center;
  margin: 10px 0;
}
.adscreenone.adscreenheight{display: block !important;}
.viewprofile a.ctaonebutton {
  padding: 10px 20px;
}


.paddingleft0{padding-left:0;}


.categorysectiondis  a.ctaonebutton {
  position: absolute;
  left: 7%;
  width: 250px;
  bottom: 90px;
  background: #c9daea;
  color: #333;
  font-weight: 700;
}
.expertinnsearch .expertinnsearchimage {
  height: 290px;
  overflow: hidden;
}

.ppvcat, .ppvdate {
  margin-bottom: 10px;
  color: #06293f;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ppvofferinnersection .offerslider .sliderContent .ppvcat h3, .ppvofferinnersection .offerslider .sliderContent .ppvdate h3 {
  color: #06293f;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 1 !important;
}
.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .logemail.mainsecpos ul.deone li{
  width: auto; 
  margin-right: 30px;
  position: relative;
  padding-left: 40px;
  overflow:visible;
  z-index: 9;
}
.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .logemail.mainsecpos ul.deone li span{
  position: absolute;
  left: 0;
  width: 36px;
  top: -6px;
}

section.latestrates.specialexppv {
  padding: 0;
}
.scrolled-down .sticky-top {
  position: fixed;
  top: 0;
  z-index: 99999;
  left: 0;
  right: 0;
}
.descriptionppvpop {
  border-radius: 0;
  background: #fff;
  padding: 30px;
  position: relative;
  width: 100%;
  bottom: 0;
  border: 2px solid #06283D;
  box-shadow: 0px 12px 10px -1px rgba(0, 0, 0, 0.25);
}
.limitedofferinner .descriptionppvpop h5{
  color: #06293f;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;}
  .ppvofferinnersection .slick-list{padding-bottom: 150px;}
.ppvslidercontentoverlay {
  background: linear-gradient(269deg, rgba(0, 0, 0, 0.00) 0.45%, #000 159.55%);
  height: 96%;
  width: 85%;
  content: "";
  position: absolute;}
  .ppvslidercontentoverlay {
    opacity: 0.4;
}
.offerdspop {
  margin-top: 20px;
}
.sliderContent:hover .ppvslidercontentoverlay{opacity: 0.9;}
.sliderContent:hover .offerdspop{opacity: 1;}
  .ppvofferinnersection .offerslider .sliderContent h3{opacity: 1;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #06293f;}
  .myexpertnav.myaccountnav.myexpertblog.qnanav ul li {
    width: 278px;
    padding: 10px 23px 10px 0;
}
.myexpertnav.myaccountnav.myexpertblog.qnanav ul li:nth-child(2) {
  width: 278px;
  padding: 10px 10px 10px 30px;
}
.listofoffermainsection .specialinfoinner .row {
  justify-content: center;
}
.dashboardrightpanel  .expertsection .experdetails ul{justify-content: start;}
.dashboardrightpanel  .expertsection .expertinner ul li:nth-child(1) {
  width: 20%;
}

.dashboardrightpanel  .expertsection .expertinner ul li:nth-child(2) {
  width: 30%;
}
section#homeblog {
  padding-top: 75px;
}
.ppvexpertdetailspop.institutepublicprofile .publicdeinner {
  display: flex;
  padding: 40px 20px 40px 40px;
  align-items: center;
  border-radius: 20px;
  margin-left: 0;
  overflow:hidden;
}
.ppvexpertdetailspop.institutepublicprofile.publicdeinnerts .publicmainpopdis.basicdetails{
  width: 80%; 
  position: relative;
  display: flex;
}
.ppvexpertdetailspop .publicviewprofilelogouploadinner {
  background: #fff;
  z-index: 9;
  position: relative;
  width: 230px;
  height: 230px;
  border-radius: 20px;
}
.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .logemail.mainsecpos {
    position: absolute;
    top: auto;
    bottom: -10px;
    right: 0;
    width: 60%;
    left: 20%;
}
.ppvexpertdetailspop.institutepublicprofile .socialpublic:after{display: none;}
.ppvexpertdetailspop.institutepublicprofile .socialpublic {
  background: #024E7C;
  position: absolute;
  right: 25px;
  bottom: 20px;
  width: 60px;
  padding: 10px;
  top: auto;
  height: 72%;
  border-radius: 40px;
  margin: auto;
}
.ppvexpertdetailspop.institutepublicprofile .socialpublic ul{    padding: 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
align-items: center;}
.ppvexpertdetailspop.institutepublicprofile .socialpublic ul li{margin-right: 0; padding: 0;}
.institutepublicprofile.ppvexpertdetailspop .publicviewprofilelogoupload {
  background: transparent;
  border: 0;
  box-shadow: none;
  border-radius: 20px;
  width: 250px;
  height: 250px;
  display: inline-block;
  position: relative;
  top: 5px;
  text-align: center;
  overflow: hidden;
  z-index: 2;
  bottom: 0;
  margin: auto;
  margin-left: 00px;
  margin-right: 0;
}
.ppvexpertdetailspop.institutepublicprofile.publicdeinnerts .publicmainpopdis.basicdetails:before {
  content: "";
    position: absolute;
    background: #024E7C;
    height: 139%;
    width: 60px;
    top: -40px;
    left: 88px;

}
.ppvexpertdetailspop.institutepublicprofile.publicdeinnerts .publicmainpopdis.basicdetails:after {
  content: "";
    position: absolute;
    background: #024E7C;
    height: 50px;
    width: 150px;
    top: 5%;
    left: -45px;
}
.institutepublicprofile.ppvexpertdetailspop .publicviewprofilelogoupload img
{
  border-radius: 0;
}
.bg-white-new {
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 0px 32px 2px rgba(24, 75, 105, 0.10);
  padding: 20px;
  margin-bottom: 25px;
}
.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .logemail.mainsecpos ul.deone{display: flex;}
.institutepublicprofile.ppvexpertdetailspop .publicviewprofilelogoupload:before, .institutepublicprofile.ppvexpertdetailspop .publicviewprofilelogoupload:after{display: none;}
section.entiredashboard.admindashboard:before{
  width:28%;
}

.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede .cstlist {
  color: #024E7C;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 198%;
  position: absolute;
  margin-top: 17px;
}
.ppvexpertdetailspop.institutepublicprofile .ppvplan {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.30);
  right: 27px;
  top: 20px;
  color: #024E7C;
}
.ppvexpertdetailspop.institutepublicprofile .ppvplan:before{display: none;}
.colinstititeinner {
  cursor: pointer;
}
.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede{padding-left: 113px;}
.newserachfield .myexpertformarea.searcharea .input-container.form-group:nth-child(2) {
  width: 40%;
  border-right: none;
}
.newserachfield .myexpertformarea.searcharea .input-container.form-group:first-child {
  width: 40%;
  border-right: 1px solid #ccc;
}
.smblog .card-img-top img {
  height: 200px;
  object-fit: cover;
}
.smblog .card-img-top {
  margin-bottom: 10px;
}
.d-flex.questioninnerinbottom.mt-3 {
  flex-wrap: wrap;
}

.fbold{font-weight: bold;}
.sectionheader.blogsectionpos{    width: 100%;
  margin-left: 0;}
  .modalspecial .selectboxmain.form-group {
    z-index: 99;
}
.modalspecial .mb-4 .css-1p3m7a8-multiValue{font-size: 10px;}
.socialpublic.d-flex li img {
  width: 21px;
}
div[id^="react-select-"]{z-index: 999;}
.multiselect div[class$="-ValueContainer"]{flex-wrap: nowrap;  max-width: 100%;}
.multiselect div.multicustomselect__value-container{flex-wrap: nowrap;  max-width: 100%;}
section.loginscreeninstitute.registrationinstitute .multiselect div.multicustomselect__value-container
{overflow: visible;}
.loadingResult {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  margin: auto;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.5);
}
.loadingResult h4 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  height: 100px;
  bottom: 0;
  margin: auto;
}
.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede .cstlist span {
  padding-left: 0px;
  text-decoration-line: underline;
}
.expertquestiondetails .expertuser img {
  border-radius: 100px;
  height: 45px;
  width: 45px;
}
section.loginscreeninstitute.registrationinstitute .disgrid.grid-2 .social-control:nth-child(1) .placeholder {
  padding-left: 20px !important;
}
section.loginscreeninstitute.registrationinstitute .disgrid.grid-2 .social-control:nth-child(3) .placeholder{
  padding-left: 23px !important;
}
.dropdefaultimage {
  display: none;
}

.homepageproductoffer a.ctaonebutton.text-center {
  padding: 0;
}
.opacity0{opacity: 0;}
.experdetails li span img {
  margin-right: 5px;
}
.listofadbutton .ctaonebutton{width:250px}
.listofadbutton .ctanewtwobutton{
  background: #55BCFF ;
  color: #fff ; 
transition: 0.3s all ease-in-out;}
.listofadbutton .ctanewtwobutton:hover{
  background: linear-gradient(90.46deg , #55BCFF 0%, #B5ECFF 100%) ;

}
.slbtn .ctanewtwobutton{background: #55BCFF;}
.headermainsearch{background: #DFF6FF;}
.row.rimibicons {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
}

.colps {
  display: flex;
  width: auto !important;
}

    .recaptcha_css{
      display: flex;
      justify-content: center;
  }
  .addexpertright button.ctaonebutton {
    font-size: 16px;
}
button.myadbtn svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 20px;
}
.expcat.needcoma span {
  position: relative;
  padding-left: 10px;
}

.expcat.needcoma span:before {
  content: ",";
  position: absolute;
  right: -4px;
}

.expcat.needcoma span:first-child {
  padding: 0;
}

.expcat.needcoma span:last-child:before {
  display: none;
}
.licencenumber {
  color: #fff;
}
.posbuttontf .ctatwobutton.ctanewtwobutton, .offer-insurence-inner .ctatwobutton{background: #55BCFF !important;
  color: #fff !important;}
  .Toastify__toast-container {
    z-index: 999999;
  }
  .posbuttontf .ctatwobutton.ctanewtwobutton:hover, .offer-insurence-inner .ctatwobutton:hover{    background: linear-gradient(90.46deg, #55BCFF 0%, #B5ECFF 100%)}

  .categoryaskecpertconfirminner.subcatchal img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(341deg) brightness(98%) contrast(113%);
    max-height: 80px;
  }
  .searchareaquestion textarea.form-control.textareaform {
    height: auto;
}
.entiredashboard .subcatchal {
  width: 160px;
  min-height: 220px;
}
.entiredashboard .categoryaskecpertconfirminner{min-height: 220px;}
.entiredashboard  .categoryaskecpertconfirminner.subcatchal h6{line-height: 20px; font-weight: 500;}
.searchareaquestion.searchareaquestionadmin .input-container.form-group {
  width: 40% !important;
}
.searchdetalsen{    margin-left: 46px;}
.myexpertnav.martop60 ul li.active a {
  color: white;
  text-decoration: none;
}
.myexpertformarea.searcharea.seachadmincustom .input-container.form-group:nth-child(2){width:25%;}
.confy {
  align-items: center;
  display: flex;
  height: 100vh;
}
.confirmbutton a.ctatwobutton, .confirmbutton a.ctaonebutton {
  text-align: center;
}
.myexpertformarea.searcharea.seachadmincustom .input-container.form-group:nth-child(3){width: 25%;}
.seachadmincustom textarea.input.form-control.textareaform {
  height: 70px;
}
.confirmbutton a.ctatwobutton{margin-right: 17px;}
#accordionSidebar button.accordion-button, #accordionSidebar .accordion-item {
  background: transparent;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 50px;
  color: #06293f;
}
#accordionSidebar button.accordion-button{box-shadow: none;}
#accordionSidebar button.accordion-button h2{    font-weight: 500;
  font-size: 16px;
  line-height: 50px;
  color: #06293f;}
  .selectboxmain.null .placeholder{top: 19px; left: 9px;}
/* Customer Login start */
/* sidebar start*/
section.entiredashboard.maincustomerdash  .sidebar{
  background-color: #2F596F;
  color: #ffffff;
  border-radius: 25px;
}
section.entiredashboard.maincustomerdash  .responsename {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF;
  margin-bottom: 50px;
  margin-left: 90px;
  padding: 0 6px 0 0;
  line-height: 24.38px;
}
section.entiredashboard.maincustomerdash  .sidebar .userdetails {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
}
section.entiredashboard.maincustomerdash  .siderbartwoomain {
  display: flex;
}
section.entiredashboard.maincustomerdash  .siderbartwoimg {
  background-color: #004E77;
}


section.entiredashboard.maincustomerdash  .image-column {

  background-color: #004E77;
  padding: 159px 20px 20px 20px;
  border-radius: 25px 25px 0 0;
}
section.entiredashboard.maincustomerdash  .sliderimg img {
  margin-bottom: 69px;
  padding-left: 15px;
  
}
/* .text-column {
  padding: 50px 0px;
} */
section.entiredashboard.maincustomerdash  .dashboardlogo img {
  padding: 10px;
}
section.entiredashboard.maincustomerdash  .userdetails .dashboardlogo img {
  border-radius: 50%;
}

section.entiredashboard.maincustomerdash  .dashboardlink ul {
  list-style: none;
  padding: 0;
}

section.entiredashboard.maincustomerdash  .dashboardlink ul li { 
  border-radius: 25px 0 0 25px;
  z-index: 1;
}

section.entiredashboard.maincustomerdash  .dashboardlink ul li a {
  display: flex;
  align-items: baseline;
}
section.entiredashboard.maincustomerdash  .martop40 {
  margin-top: 40px;
}

section.entiredashboard.maincustomerdash  .dashboardlink.dashboardtext .activetext {
  color: #005589;
  background-color: #E4EFFF;
  border-radius: 50px 0 0 50px;
  padding: 0 22px;
  width: 100%;
  min-height: 69px;
  display: flex;
  align-items: center;
  text-align: start;
  
}

section.entiredashboard.maincustomerdash  .sliderimg.activeimg img {
  border-left: 7px solid white;
    padding: 10px;
}

/* sidebar end*/
section.entiredashboard.maincustomerdash  .maincustomerdashboard .dashboardleftpanel {
  position: relative;
}

section.entiredashboard.maincustomerdash  .maincustomerdashboard .dashboardlogo img {
  width: 90px;
  height: 90px;
  border-radius: 50px;
}

section.entiredashboard.maincustomerdash  .maincustomerdash.dashboardcolor{
  box-shadow: 0px 0px 15.7px 0px #2280B880;

}
section.entiredashboard.maincustomerdash  .dashboard h2 {
  font-size: 40px;
  color: #000000;
  font-weight: 700;
}
section.entiredashboard.maincustomerdash  .dashboard h5 {
  font-size: 20px;
  font-weight: 500;
  color: #06293F;
  font-family: 'Poppins' !important;
}
section.entiredashboard.maincustomerdash  .dashboarditext {
  font-size: 20px;
  font-weight: 400;
  font-style: italic;
  color: #06293F;
}
section.entiredashboard.maincustomerdash  .maincustomerdash ul li.active {
  background: linear-gradient(180deg, #0077B6 0%, #003450 100%);

  border-radius: 100px;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
}
section.entiredashboard.maincustomerdash  .maincustomerdash .customermain ul li {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 20px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #90E0EF;
}
section.entiredashboard.maincustomerdash  .maincustomerdash .myexpertnav > ul li{
width: 198px;
height: 198px;
padding: 69px;
display:flex;
flex-direction: column;
}
section.entiredashboard.maincustomerdash  .maincustomerdash.dashboardcolor {
  background-color: #D1D9E9;
}
section.entiredashboard.maincustomerdash  .customermain {
  margin-bottom: 100px;
}
section.entiredashboard.maincustomerdash  .questiontext.questiontext span {
  font-weight: 700;
  font-size: 18px;
  color: #303030;
}
.questiontext.questiontext b {
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins' !important;
  color: #3B3B3B;
}
.allcustans .questionstext span {
  font-size: 18px;
  font-weight: 700;
  color: #3B3B3B;
}
.allcustans.questionstext b {
  font-size: 18px;
  font-weight: 500;
  color: #3B3B3B;
}
section.alldashboard {
  background-color: #fff;
  padding: 20px 22px;
  padding-bottom: 0;
  border-radius: 20px 20px 0 0;
  margin-bottom: 0px;
}
.mrg{
  padding-top: 30px;
}
.myexpertnav.myaccountnav.qnanav.martop40.customermain .active {
  color: white;
}
.dashboardtext li.activetext p {
  color: #005589;
}
.bg-color-customer{
  background: rgba(209, 217, 233, 1);
  border-radius: 20px 20px 0 0;
}
section.entiredashboard.maincustomerdash:before{display: none;}
section.entiredashboard.maincustomerdash  > .container > .row > .col-lg-3.col-sm-3{background-color: #2F596F;
  border-radius: 25px 25px 0 0; box-shadow: none;     padding: 0;
  }
  .dashboardlink.dashboardtext .activetext {position: relative;}

.dashboardlink.dashboardtext .activetext:before {
    content: "";
    position: absolute;
    background: #fff;
    width: 7px;
    height: 80%;
    left: -66px;
    border-radius: 10px;
}

  .text-column {
    position: relative;
}
/* section.entiredashboard.maincustomerdash  .col-lg-3.col-sm-3:before{
  position: absolute;
  top: 0;
  border-radius: 25px 25px  0 0;
  background-color: #004E77;
  width: 73px;
  height: 100%;
  content: "";
} */
.mydash ul.dashboardinner p {
  font-size: 22px;
  color: white;
  font-weight: 700;
  padding: 0 17px;
}
.text-column.mydash.dashboardlink ul li{
  margin: 0px;
}
.catstart.clearfix.blogsasktheexpert {
  background-color: #FFFFFF;
  border-radius: 10px 10px 0 0;
  padding: 50px 0;
  margin: 0 30px;
}
.mainblogsask{
  background: #D1D9E9;
  box-shadow: 0px 0px 15.7px 0px #2280B880;

 
  
}
.customask{
  margin-left: 40px;
}
.customask h2 {
  font-size: 40px;
  color: #000000;
  font-weight: 700;
  margin: 10px 0 15px 0;
}
.customask h5 {
  color: #06293F;
  font-size: 20px;
  font-weight: 500;
  max-width: 759px;
}

.customertASK .wcustomerask.null {
  width: 100%;
}
.wcustomerask.categoryoneset {
  width: 100%;
}
.categoryfourset.catcustomer {
    display: flex;
    justify-content: center;
}

.maincustosection{
  flex-direction: column;
}
.textcenter h2{
  text-align: center;
}

.main-question.questionslider.mainquestioncust {
  margin: 15px auto;
}
.questioncustomer h2{
  text-align: center;
}
.questioncustomer p{
  text-align: center;
}
.questioncustomer p.question-right-text-too {
  max-width: 583px;
  margin: auto auto auto 206px;
  text-align: justify;
}

.maincustomertextarea {
  height: 262px;
}
.questioncust .nextbtn{
  text-align: center;
}
.maincustomerdash .queastio-main:before{background: transparent; display: none;}
.maincustomerdash .catstart
{  margin: 0 30px;}
.maincustomerdash  div#askQus{margin-top: 0; padding-top: 30px; background: #fff;}
.maincustomerdash .textarecs .input-question textarea.input.form-control.textareaform{padding-top: 60px; height: 200px;}
  .maincustomerdash textarea.input:not(:placeholder-shown) ~ .placeholder{    top: 33px;}
  .maincustomerdash .register-main{background: #fff; padding: 20px 10px; margin: 0 30px;}
  .maincustomerdash .register-main-text h2{text-align: center;}
  .maincustomerdash .input-container.form-group.input-group-reg.col-md-6.mb-5{width: 100%;}
/* Customer Login end */


/*Customer Blog start */
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a.active:nth-child(1){
background-color: #00831D;
box-shadow: 4px 4px 12.2px 0px #00000040;
color: white;
}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a.active:nth-child(2){
background-color: #86357A;
box-shadow: 4px 4px 12.2px 0px #00000040;
color: white;
}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a.active:nth-child(3){
background-color: #2F596F;
box-shadow: 4px 4px 12.2px 0px #00000040;
color: white;
}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a.active:nth-child(4){
background-color: #FF7000;
box-shadow: 4px 4px 12.2px 0px #00000040;
color: white;
}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a.active:nth-child(5){
background-color: #2280B8;
box-shadow: 4px 4px 12.2px 0px #00000040;
color: white;
}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a:nth-child(1){
background:#DDFFDD;
width: 144px;
height: 144px;
border-radius: 200px;
font-size: 20px;
font-weight: 600;
line-height: 30px;
text-align: center;
color: #00831D;
display: flex;
align-items: center;
}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a:nth-child(2){
background-color: #FFE5FB;
width: 144px;
height: 144px;
border-radius: 200px;
color: #86357A;
font-size: 20px;
font-weight: 600;
line-height: 30px;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a:nth-child(3){
  background-color: #E2ECFF;
  width: 144px;
  height: 144px;
  border-radius: 200px;
  color: #2F596F;
font-size: 20px;
font-weight: 600;
line-height: 30px;
display: flex;
    align-items: center;
justify-content: center;
text-align: center;
}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a:nth-child(4){
  background-color: #FFD5C3;
  width: 144px;
  height: 144px;
  border-radius: 200px;
font-size: 20px;
color: #FF7000;
font-weight: 600;
display: flex;
    align-items: center;
    justify-content: center;
line-height: 30px;
text-align: center;
}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a:nth-child(5){
  background-color: #E3F2FF;
  width: 144px;
  height: 144px;
  border-radius: 200px;
font-size: 20px;
font-weight: 600;
color: #2280B8;
line-height: 30px;
display: flex;
    align-items: center;
    justify-content: center;
text-align: center;
}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a.active{
  box-shadow: 4px 4px 12.2px 0px rgba(0, 0, 0, 0.25);

box-shadow: 4px 4px 12.2px 0px rgba(0, 0, 0, 0.25);

}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a.input-container.form-group:nth-child(2){
  background: rgba(255, 229, 251, 1);

}
.blogsinner nav.myexpertnav.myaccountnav.qnanav.martop40.customermain.nav.nav-tabs a.input-container.form-group:nth-child(3){
  background: rgba(226, 236, 255, 1);
}
section.entiredashboard.maincustomerdash  .blogs-main .main-blogs{
  background: #D1D9E9;
  box-shadow: 0px 0px 15.7px 0px #2280B880;
  box-shadow: 2.84px 2.84px 31px 0px #00000040;
  border-radius: 0 25px 0 0;
  margin-top: 40px;
}
section.entiredashboard.maincustomerdash  .blogs-main .round-box {
  margin: 50px 10px 50px 10px;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
section.entiredashboard.maincustomerdash  .main-blog-section{
  background-color: #ffffff;
  border-radius: 20px;
  padding: 68px 40px;
  margin: 0 30px 30px 30px;
}

/* section.entiredashboard.maincustomerdash  .main-blog-section .blog-design {
  box-shadow: 10px 10px 16px 0px #00000040;
  border: 1px solid #00801D;
  border-top:none ;
  min-height: 252px;
  padding: 0;
  border-radius: 0 0 6px 6px;
  margin: 0 0px 45px 0px;
} */

section.entiredashboard.maincustomerdash  .main-blog-section .blog-design-Mortgage {
  box-shadow: 10px 10px 16px 0px #00000040;
  border: 1px solid #2F596F;
  min-height: 252px;
  border-top:none ;

  padding: 0;
  border-radius: 0 0 6px 6px;
  margin: 0 0px 45px 0px;
}
section.entiredashboard.maincustomerdash  .main-blog-section .blog-design-Insurance {
  box-shadow: 10px 10px 16px 0px #00000040;
  border: 1px solid #FF7000;
  min-height: 252px;
  border-top:none ;

  padding: 0;
  border-radius: 0 0 6px 6px;
  margin: 0 0px 45px 0px;
}
section.entiredashboard.maincustomerdash  .main-blog-section .blog-design-Banking {
  box-shadow: 10px 10px 16px 0px #00000040;
  border: 1px solid #2280B8;
  min-height: 252px;
  border-top:none ;

  padding: 0;
  border-radius: 0 0 6px 6px;
  margin: 0 0px 45px 0px;
}

section.entiredashboard.maincustomerdash  .bloginnerimage img{
  border-radius: 6px 6px 0 0 ;
}

/* section.entiredashboard.maincustomerdash  .blog-button {
  background-color: #00801D;
  font-size: 14px;
  font-weight: 600;
  width: 150px;
  margin: 0 15px;
  height: 40px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 10px 10px 16px 0px #00000040;
} */
section.entiredashboard.maincustomerdash  .blog-text{
  margin: 0 15px;
  max-width: 229px;
  padding: 10px 0;
}
section.entiredashboard.maincustomerdash  .blog-view{
  margin: 0 15px;
}
section.entiredashboard.maincustomerdash  .blog-button-investment{
  background-color: #86357A;
  font-size: 14px;
  font-weight: 600;
  width: 150px;
  margin: 0 15px;
  height: 40px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 10px 10px 16px 0px #00000040;
}
section.entiredashboard.maincustomerdash  .bloginnertext-investment{
  margin: 0 15px;
  max-width: 229px;
  color: #303030;
}
section.entiredashboard.maincustomerdash  .blog-view-investment{
  margin: 0 15px;
}
section.entiredashboard.maincustomerdash  .blog-button-Mortgage{
  background-color: #2F596F;
  font-size: 14px;
  font-weight: 600;
  width: 150px;
  margin: 0 15px;
  height: 40px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 10px 10px 16px 0px #00000040;
}
section.entiredashboard.maincustomerdash  .bloginnertext-Mortgage{
  margin: 0 15px;
  max-width: 229px;
  color: #303030;
}
section.entiredashboard.maincustomerdash  .blog-view-Mortgage{
  margin: 0 15px;
}
section.entiredashboard.maincustomerdash .blog-button-Insurance{
  background-color: #FF7000;
  font-size: 14px;
  font-weight: 600;
  width: 150px;
  margin: 0 15px;
  height: 40px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 10px 10px 16px 0px #00000040;
}
section.entiredashboard.maincustomerdash  .bloginnertext-Insurance{
  margin: 0 15px;
  max-width: 229px;
  color: #303030;
}
section.entiredashboard.maincustomerdash  .blog-view-Insurance{
  margin: 0 15px;
}
section.entiredashboard.maincustomerdash  section.entiredashboard.maincustomerdash  .blog-button-Banking{
  background-color: #2280B8;
  font-size: 14px;
  font-weight: 600;
  width: 150px;
  margin: 0 15px;
  height: 40px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 10px 10px 16px 0px #00000040;
}
section.entiredashboard.maincustomerdash  .blog-design .bloginnertext-Banking{
  margin: 0 15px;
  max-width: 229px;
  color: #303030;
}
section.entiredashboard.maincustomerdash  .blog-design  .blog-view-Banking{
  margin: 0 15px;
}
section.entiredashboard.maincustomerdash  .blogblogmain .bloginnerimage img{
  border-radius: 6px 6px 0 0;
}
section.entiredashboard.maincustomerdash  .mainblog-rimib{
  margin: 0 13px;
}




/*---go to top---*/
button.gototop {
  width: 30px;
  padding: 2px !important;
  background: #00801D !important;
  height: 30px;
  z-index: 999;
}

button.gototop img {
  transform: rotate(90deg);
}
section.entiredashboard.maincustomerdash > .container-fluid > .row > .col-lg-4.col-sm-4 {
  background-color: #2F596F;
  border-radius: 25px 25px 0 0;
  box-shadow: none;
  padding: 0;
  max-width: 400px;
}
section.main_customer_dashboard {
  max-width: 1366px;
  margin: 0 auto;
  width: 100%;
}
section.entiredashboard.maincustomerdash .col-lg-4.col-sm-4:before {
  position: absolute;
  top: 0;
  border-radius: 25px 25px 0 0;
  background-color: #004E77;
  width: 73px;
  height: 100%;
  content: "";
  left: 35px;
}
.dashboardleftpanel {
  max-width: 370px;
  margin: 0 0 0 auto;
}



.limitedoffer .limitedofferinner .offerslider .slick-prev:before, .limitedoffer .limitedofferinner .offerslider .slick-prev:before, .slick-next:before {

}

/* .p_slider.product_inner .sldeimage.product_image img:hover {
  box-shadow: 0 0 12px 2px #FFFFFF;
  cursor: pointer;
} */


.regular.slider {
  display: contents;
}

.modalspecial.asktheexpertconfirmpopup.popupouter.customerpopupmn {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  top: 82px;
  right: 0%;
  bottom: 0;
  left: auto;
  z-index: 9999;
  max-width: 66.6%;
  height: 550px;
  width: 66.6%;
}
.logoutbutton.loginrightmargin {
  display: none;
}
.entiredashboard .dashboardrightpanel .row.title-section .col-2 {
  display: none;
}
.swal-icon--success__ring{border: none;}
.swal-icon--success:before {
  border-radius: 120px 0 0 120px;
  top: -14px;
  left: -27px;
}
.swal-icon--success__line--tip {
  width: 25px;
  left: 14px;
  top: 50px;
}
.swal-icon--success__line--long {
  width: 47px;
  right: 5px;
  top: 41px;
}
