@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

.slick-slider * {
	min-height: 0;
	min-width: 0;
}

:root {
	--green: #00831D;
	--purple: var(--purple);
	--Blueone: var(--Blueone);
	--orange: #FF4D00;
	--Bluetwo: #2281B8;
	/* --primary: #{process.env.REACT_APP_PRIMARY_COLOR}; */


}

.exclusive h2 {
	text-align: left;
	font-weight: 700;
	margin-bottom: 0;
}

.exclusive h6 {
	text-align: left;
}

.exclusive .homedisplaynone {
	display: none;
}

.exclusive .homepageofferblock {
	box-shadow: none;
	border-radius: 0;
}

section.homecustom {
	max-width: 1366px;
	margin: 0 auto;
	width: 100%;
}

section.homecustom {
	max-width: 1366px;
	margin: 0 auto;
	width: 100%;
}

.homecustom h1,
.homecustom h2,
.homecustom h3,
.homecustom h4,
.homecustom h5,
.homecustom P {
	font-family: "Montserrat", sans-serif;
}

a.realbtnfour {
	background-color: var(--orange);
	color: white;
	padding: 7px;
	text-align: center;
	border-radius: 8px;
	text-decoration: none;
	width: 200px;
	height: 55px;
	font-family: "Fjalla One", sans-serif;
	font-size: 32px;
	font-weight: 400;
}

a.realbtnfour:hover {
	cursor: pointer;
	color: var(--orange);
	background-color: #FFFFFF;
	box-shadow: 0px 0px 10px 0px var(--orange);
}

a.findbtn-four {
	padding: 10px;
	background-color: var(--orange);
	border-radius: 5px;
	color: white;
	font-size: 11.25px;
	font-weight: 700;
	line-height: 14px;
	text-align: center;
	display: inline-block;
	width: 200px;
}

section.herosection.p-0.overflow-hidden.herosectionhid.d-none.d-sm-block {
	height: 430px;
	overflow: hidden !important;
}

section.herosection.p-0.overflow-hidden.herosectionhid.d-none.d-sm-block .container-fluid.p-0 {
	max-width: 1500px;
}

.herosection.herosectionhid {
	width: 100%;
	background-color: #f8f9fa;
}

.rimiboneslide {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	/* Adjust based on your design */
	box-sizing: border-box;
}

a {
	text-decoration: none !important;
	transition: 0.3s all ease-in;
}

.rimiboneslide img {
	max-width: 100%;
	height: auto;
}

.herosection .heroinner {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.herosection .heroinner .rimib-block {
	flex: 1 1 20%;
	max-width: 500px;
	box-sizing: border-box;
}

/* .herosection .heroinner .rimib-block img {
    height: auto;
    display: block;
    margin: 0 auto;
  } */
/* div#rbg{
      background-image: url("../../../assets/homeimages/rimib\(r\)bg.png");
      background-repeat: no-repeat;
  }
  div#rbg2{
      background-image: url("../../../assets/homeimages/rimib\(i\)bg.png");
      background-repeat: no-repeat;
  }
  div#rbg3{
      background-image: url("../../../assets/homeimages/rimib(m)bg.png");
      background-repeat: no-repeat;
  }
  div#rbg4{
      background-image: url("../../../assets/homeimages/rimib(ins)bg.png");
      background-repeat: no-repeat;
  }
  div#rbg5{
      background-image: url("../../../assets/homeimages/rimib(b)bg.png");
      background-repeat: no-repeat;
  } */
div#rbg,
div#rbg2,
div#rbg3,
div#rbg4,
div#rbg5 {
	background-repeat: no-repeat;
	background-size: cover;
	height: 400px;
	/* display: flex; */
	flex-direction: column;
	/* justify-content: center;
  align-items: center; */
	width: 100%;
}

.rimib-block {
	position: relative;
	display: inline-block;
	width: 200px;
	/* Adjust according to your design */
	height: 200px;
	/* Adjust according to your design */
	overflow: hidden;
	/* Ensures content doesn't overflow the block */
}

.hover-content-inner {
	background-color: #E7FFEA;
	padding-bottom: 29px;
	margin-bottom: 10px;
}

a.realbtn {
	display: inline-block;
	background-color: var(--green);
	color: white;
	padding: 7px;
	text-align: center;
	border-radius: 8px;
	text-decoration: none;
	width: 200px;
	height: 55px;
	font-family: "Fjalla One", sans-serif;
	font-size: 32px;
	font-weight: 400;
}

a.realbtn:hover {
	cursor: pointer;
	color: var(--green);
	background-color: #FFFFFF;
	box-shadow: 0px 0px 10px 0px var(--green);
}

a.realbtn:hover img {
	filter: invert(25%) sepia(100%) saturate(1736%) hue-rotate(123deg) brightness(89%) contrast(101%);
}

a.realbtn-too:hover {
	cursor: pointer;
	color: var(--purple);
	background-color: #FFFFFF;
	box-shadow: 0px 0px 10px 0px var(--purple);
}

a.realbtn-too:hover img {
	filter: invert(25%) sepia(89%) saturate(454%) hue-rotate(258deg) brightness(97%) contrast(96%);
}

a.realbtn-three:hover {
	cursor: pointer;
	color: var(--Blueone);
	background-color: #FFFFFF;
	box-shadow: 0px 0px 10px 0px var(--Blueone);
}

a.realbtn-three:hover img {
	filter: invert(30%) sepia(31%) saturate(775%) hue-rotate(156deg) brightness(90%) contrast(87%);
}

a.realbtn-realbtnfour:hover {
	cursor: pointer;
	color: var(--orange);
	background-color: #FFFFFF;
	box-shadow: 0px 0px 10px 0px var(--orange);
}

a.realbtnfour:hover img {
	filter: invert(37%) sepia(36%) saturate(6250%) hue-rotate(1deg) brightness(103%) contrast(105%);
}

a.realbtn-five:hover {
	cursor: pointer;
	color: var(--Bluetwo);
	background-color: #FFFFFF;
	box-shadow: 0px 0px 10px 0px var(--Bluetwo);
}

a.realbtn-five:hover img {
	filter: invert(38%) sepia(99%) saturate(398%) hue-rotate(158deg) brightness(94%) contrast(93%);
}

a.realbtn img,
a.realbtn-five img,
a.realbtn-realbtnfour img,
a.realbtn-three img,
a.realbtn-too img,
a.realbtnfour img {
	filter: invert(100%) sepia(13%) saturate(0%) hue-rotate(21deg) brightness(114%) contrast(101%);
	width: 27px;
}

a.realbtnfour img,
a.realbtn-three img {
	width: auto;
}

a.realbtn-five img {
	width: 33px;
}

a.realbtn-too img {
	width: 21px;
}

.secbuttonhover img {
	display: block;
	max-width: 100%;
	height: auto;
	margin-bottom: 10px;
	border-radius: 4px;
	transition: transform 0.3s ease;
}


a.findbtn {
	background-color: var(--green);
	padding: 10px;
	border-radius: 5px;
	color: white;
	font-size: 11.25px;
	font-weight: 700;
	line-height: 14px;
	text-align: center;
	width: 200px;
	display: inline-block;

}

/* .hover-content-inner-four a.findbtn,
.realbtnfour {
	background: rgba(255, 76, 0, 1) !important;
} */

/* .hover-content {
    position: absolute;
    top: -100%; 
    left: 0;
    width: 100%;
    padding: 10px;
    color: white; 
    z-index: 10; 
} */
/* div#rbg:hover .hover-content {
    top: 0; 
    display: block;
    height: 100vh;
    cursor: pointer;
} */
div#rbg2:hover .hover-content {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

.hover-content h3 {
	color: var(--green);
	font-size: 1.417rem;
	font-weight: 700;
	text-align: center;
	max-width: 300px;
	margin: 0 auto;
}

.hover-content span {
	color: var(--green);
	font-size: 1.417rem;
	font-weight: 900;
}

.hover-content p {
	color: #000000;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	max-width: 287px;
	margin: 0 auto;
	color: #000;
	text-align: center;
	line-height: 20px;

}

/* 2 */
/* .hover-content-too {
    position: absolute;
    top: -100%; 
    left: 0;
    width: 100%;
    padding: 10px;
    background-color:#FFECFD;
    color: white; 
    z-index: 10; 
} */
.hover-content-inner-too {
	background-color: #FFECFD;
	padding-bottom: 29px;
	margin-bottom: 10px;
}

div#rbg2:hover .hover-content-too {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

div#rbg3:hover .hover-content-three {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

div#rbg4:hover .hover-content-four {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

div#rbg5:hover .hover-content-five {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

.hover-content-too h3 {
	color: var(--purple);
	font-size: 1.417rem;
	font-weight: 700;
	text-align: center;
	max-width: 300px;
	margin: 0 auto;
}

.hover-content-too span {
	color: var(--purple);
	font-size: 1.417rem;
	font-weight: 900;
}

.hover-content-too p {
	color: #000000;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	max-width: 287px;
	margin: 0 auto;
	color: #000;
	text-align: center;
	font-family: "Roboto Flex", sans-serif !important;
}

a.findbtn-too {
	background-color: var(--purple);
	padding: 10px;
	border-radius: 5px;
	color: white;
	color: #FFF;
	text-align: center;
	display: inline-block;
	font-size: 11.251px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	width: 200px;
}

a.realbtn-too {
	display: inline-block;
	background-color: var(--purple);
	color: white;
	padding: 7px;
	text-align: center;
	text-decoration: none;
	border-radius: 8px;
	transition: background-color 0.3s ease, transform 0.3s ease;
	width: 200px;
	height: 55px;
	font-family: "Fjalla One", sans-serif;
	font-size: 32px;
	font-weight: 400;
}

/* 3 */
.hover-content-inner-three {
	background-color: #E3E8F1;
	padding-bottom: 29px;
	margin-bottom: 10px;
}

/* .hover-content-three {
    position: absolute;
    top: -100%; 
    left: 0;
    width: 100%;
    padding: 10px;
    background-color:#E3E8F1;
    color: white; 
    z-index: 10; 
} */
div#rbg:hover .hover-content-three {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

div#rbg2:hover .hover-content-three {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

.hover-content-three h3 {
	color: var(--Blueone);
	font-size: 1.417rem;
	font-weight: 700;
	text-align: center;
	max-width: 300px;
	margin: 0 auto;
}

.hover-content-three span {
	color: var(--Blueone);
	font-size: 1.417rem;
	font-weight: 900;
}

.hover-content-three p {
	color: #000000;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	max-width: 287px;
	margin: 0 auto;
	color: #000;
	text-align: center;
	font-family: "Roboto Flex", sans-serif !important;
}

a.findbtn-three {
	background-color: var(--Blueone);
	padding: 10px;
	border-radius: 5px;
	color: white;
	color: #FFF;
	text-align: center;
	display: inline-block;
	font-size: 11.251px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	display: inline-block;
	width: 200px;
}

a.realbtn-three {
	display: inline-block;
	background-color: var(--Blueone);
	color: white;
	padding: 7px;
	text-align: center;
	text-decoration: none;
	border-radius: 8px;
	transition: background-color 0.3s ease, transform 0.3s ease;
	width: 200px;
	height: 55px;
	font-family: "Fjalla One", sans-serif;
	font-size: 32px;
	font-weight: 400;
}

/* 4 */
.hover-content-inner-four {
	background-color: #FFF2E4;
	padding-bottom: 29px;
	margin-bottom: 10px;
}

/* .hover-content-four {
    position: absolute;
    top: -100%; 
    left: 0;
    width: 100%;
    padding: 10px;
    background-color:#FFF2E4;
    color: white; 
    z-index: 10; 
} */
div#rbg:hover .hover-content-four {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

div#rbg2:hover .hover-content-four {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

.hover-content-four h3 {
	color: var(--orange);
	font-size: 1.417rem;
	font-weight: 700;
	text-align: center;
	max-width: 300px;
	margin: 0 auto;
}

.hover-content-four span {
	color: var(--orange);
	font-size: 1.417rem;
	font-weight: 900;
}

.hover-content-four p {
	color: #000000;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	max-width: 287px;
	margin: 0 auto;
	color: #000;
	text-align: center;
	font-family: "Roboto Flex", sans-serif !important;
}

/* 5 */
.hover-content-inner-five {
	background-color: #E4F3FF;
	padding-bottom: 29px;
	margin-bottom: 10px;
}

/* .hover-content-five {
    position: absolute;
    top: -100%; 
    left: 0;
    width: 100%;
    padding: 10px;
    background-color:#FFF2E4;
    color: var(--Bluetwo); 
    z-index: 10; 
} */
div#rbg:hover .hover-content-five {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

div#rbg2:hover .hover-content-five {
	top: 0;
	display: block;
	height: 100vh;
	cursor: pointer;
}

.hover-content-five h3 {
	color: var(--Bluetwo);
	font-size: 1.417rem;
	font-weight: 700;
	text-align: center;
	max-width: 300px;
	margin: 0 auto;
}

.hover-content-five span {
	color: var(--Bluetwo);
	font-size: 1.417rem;
	font-weight: 900;
}

.hover-content-five p {
	color: #000000;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	max-width: 287px;
	margin: 0 auto;
	color: #000;
	text-align: center;
	font-family: "Roboto Flex", sans-serif !important;
}

a.realbtn-five {
	display: inline-block;
	background-color: var(--Bluetwo);
	color: white;
	padding: 7px;
	text-decoration: none;
	text-align: center;
	border-radius: 8px;
	transition: background-color 0.3s ease, transform 0.3s ease;
	width: 200px;
	height: 55px;
	font-family: "Fjalla One", sans-serif;
	font-size: 32px;
	font-weight: 400;
}

a.findbtn-five {
	background-color: var(--Bluetwo);
	padding: 10px;
	border-radius: 5px;
	color: white;
	color: #FFF;
	text-align: center;
	display: inline-block;
	font-size: 11.251px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	width: 200px;
}

/* .p_slider.product_inner .sldeimage.product_image img:hover {
	box-shadow: 0 0 12px 2px #FFFFFF;
	cursor: pointer;
} */

/* .whatarecatbox.what-res p {
    color: black;
} */
.whatarecatbox.what-res .active p {
	color: white;
}

.askbutton:hover {
	cursor: pointer;
	border: 5px solid #FFFFFF;
	box-shadow: 0px 0px 20px 0px #EF000B;

}

.askbutton>img {
	width: 53px;
}

.askbutton {
	background: #EF000B;
	width: 100%;
	padding: 10px;
	display: flex;
	border: 5px solid transparent;
	align-items: center;
	justify-content: center;
	margin: 0 0 0 auto;
	max-width: 531px;
}

.askbutton p {
	font-family: "Roboto Flex", sans-serif !important;
	font-size: 50px;
	font-weight: 700;
	line-height: 60px;
	text-align: left;
	color: #fff;
	margin-bottom: 0;
	margin-right: 35px;
}

/* .question img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 0 0 auto;
  } */
.whatareyou h2 {
	color: #241B6B;
	text-align: center;
	font-size: 30px;
	font-weight: 700;
	padding-top: 20px;
}

.whatareyou span {
	color: #009F23;
	text-align: center;
	font-size: 30px;
	font-weight: 700;
}

.whatareyou .whatareyoucat {
	display: flex;
	justify-content: center;

}

.whatarecatbox:hover {
	cursor: pointer;
	background-color: #009F23;
	color: white;
}

.whatareyoucat {
	margin: 0px 0 10px 0;
}

.whatarecatbox:hover:after {
	position: absolute;
	bottom: -10px;
	left: 50%;
	margin-left: -10px;
	content: "";
	display: block;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #009F23;
}

.what-res p {
	font-weight: 600;
	text-transform: uppercase;
	margin-top: 5px;
	margin-bottom: 0;
	font-size: 14px;
	padding: 0 5px;
	line-height: 17.07px;
	text-align: center;
}

.what-res {
	width: 128px;
	height: 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}

.what-res.active,
.what-res:hover {
	cursor: pointer;
	background-color: #009F23;

}

.what-res.active:first-child img {
	filter: invert(1);
}

.what-res.active p,
.what-res:hover p {
	color: #fff;
}

.what-res.active img,
.what-res:hover img {
	filter: invert(1);
}

.what-res.active:after,
.what-res:hover:after {
	position: absolute;
	bottom: -8px;
	left: 50%;
	margin-left: -10px;
	content: "";
	display: block;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 10px solid #009F23;
}

.what-res.active:before {
	display: none;
}

.what-res:before {
	content: "";
	position: absolute;
	top: 0px;
	right: 0;
	background: #BDBDBD;
	height: 100%;
	width: 1px;
	bottom: 0;
	margin: auto;

}

.what-res:last-child:before {
	display: none;
}

.what-res:hover:before {
	display: none;
}

.whatareyou .whatareyoucat .what-resimg {

	width: auto;
	margin-bottom: 0;
}

.topproduct .what-res:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	background: #BDBDBD;
	height: 100%;
	width: 1px;
	bottom: 0;
	margin: auto;
}

.topproduct .what-res.active {
	background-color: #00831D;
	cursor: pointer;
}

.topproduct .what-res:first-child img {
	filter: invert(1);
}

.topproduct .what-res img {
	width: auto;
	/* filter: invert(1); */
}

.Ourintitutions h2,
.Ourintitutions p {
	color: #000;
}

.topproduct .what-res.active img,
.topproduct .what-res:hover img {
	filter: invert(1);
}

.topproduct .what-res.active:first-child img,
.topproduct .what-res.active:hover img {
	filter: invert(0);
}
.topproduct .what-res.active:hover img{filter: invert(1);}
.topproduct .what-res.active:first-child img{filter: invert(0);}
.topproduct .what-res:hover p,
.topproduct .what-res.active p {
	color: #fff;
}

.topproduct .what-res:hover {
	background-color: #00831D;
	cursor: pointer;
	color: #fff;
}
.topproduct .whatareyou .whatareyoucat  p{padding-left: 0;}
.topproduct .what-res p {
	color: #fff;
}

.topproduct .what-res.active:after,
.topproduct .what-res:hover:after {
	position: absolute;
	top: -9px;
	bottom: auto;
	left: 50%;
	margin-left: -10px;
	content: "";
	display: block;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid #00831D;
	border-top: none;

}

.p_slider.product_inner .sldeimage.product_image img,
.p_slider.offer_inner .sldeimage.offer_image img {
	border: 7px solid #fff;
	height: 100%;
	object-fit: contain;
	width: 100%;
	background: #fff;

}

.homecustom .homepageofferblock.carddecoration {
	display: flex;
	align-items: center;
	margin: 10px auto;
	justify-content: center;
	background: #fff;
	position: relative;
	padding-top: 49.5%;
	overflow: hidden;
}

.homecustom .homepageofferblock.carddecoration>a {
	display: flex;
	justify-content: center;
	align-items: baseline;
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 0;
	margin-bottom: 10px;
}

.homecustom .homepageofferblock.carddecoration>a>div img {
	border: 7px solid #fff;
	height: 100%;
	object-fit: contain;
	width: 100%;
	background: #fff;

}

.topproduct .sldeimage.product_image,
.exclusive .sldeimage.offer_image {
	display: flex;
	justify-content: center;
	align-items: baseline;
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 0px 0px;
	margin-bottom: 0px;
}

.homecustom .p_sliderouter.product_main.exclusivepadding {
	padding: 0 10px;
}

.homecustom .topprbtn {
	margin-top: 10px;
}

.whatareyou .whatareyoucat .what-resp {
	/* color: #000000; */
	font-weight: 600;
	text-transform: uppercase;
	margin-top: 5px;
	margin-bottom: 0;
	font-size: 14px;
	padding: 0 5px;
	line-height: 17.07px;
	text-align: center;
}

.p_sliderouter.main_offers.exclusivepadding,
.p_sliderouter.product_main.exclusivepadding {
	padding-bottom: 0px;
}

.whatareyou .whatareyoucat .whatarecatbox:last-child {
	border-right: none;
}

.exclusive {
	background-image: url("../../../assets/homeimages/exclusivebanner.svg");
	background-repeat: no-repeat;
	width: 100%;
	background-size: cover;
	background-position: bottom;
	padding: 30px 0 20px 0;
}

.exclusive .exclusive-text h2 {
	font-size: 30px;
	font-weight: 700;
	color: #000000;
	text-align: start;
	margin-bottom: 0;
}

.exclusive-slider {
	width: 97%;
	margin: auto;
	margin-bottom: 0px;

}

.exclusive .exclusive-text p {
	font-size: 16px;
	font-weight: 500;
	color: #000000;
	text-align: start;
	margin-bottom: 20px;
	line-height: 20px;
}

.exclusive-slider .p_slider {
	padding: 0 10px;
}

.exclusivemain {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	padding: 31px;
	max-width: 900px;
	margin: 0 auto;
}

.exclusivemain .exclusivetext h3 {
	color: var(--green);
	font-size: 2.151rem;
	font-weight: 700;
}

.exclusivemain .exclusivetext span {
	color: var(--green);
	font-size: 2.151rem;
	font-weight: 900;
}

.exclusivemain .exclusivetext p {
	color: #000000;
	font-size: 1.195;
	font-weight: 400;
	text-align: start;
}

.exclusivemain .exclusivetext {
	max-width: 373px;
}

.exclusiveimg img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.exclusivemain .exclusivetext .sliderbtn {
	background: var(--green);
	border-radius: 4px;
	font-style: normal;
	font-weight: 700;
	font-size: 0.843rem;
	display: inline-block;
	padding: 10px;
	color: white;
}

a.showallbtn {
	background-color: white;
	border: 1px solid #EF000B;
	color: #EF000B !important;
	padding: 8px 19px;
	border-radius: 5px;
	font-size: 20px;
	font-weight: 700;
	/* box-shadow: 2px 2px 10px 0px #EF000B; */
	display: inline-block;
	width: 150px;
	height: 40px;
	color: #EF000B;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif;

}

.p_sliderouter.mainofferslider .offerbg {
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.slbtn.slidertext-pp.offertext {
	margin-top: 0;
	margin-bottom: 0;
	max-width: 609px;
	min-height: 0px;
}

.p_sliderouter.mainofferslider {
	margin-bottom: 0;
}

.topproduct {
	background-image: url("../../../assets/homeimages/topproductbg.svg");
	background-repeat: no-repeat;
	width: 100%;
	background-size: cover;
	margin: 40px 0;
	padding-top: 28px;
	padding-bottom: 10px;
}

.topproduct .topproduct-text h2 {
	font-size: 30px;
	font-weight: 700;
	color: #FFFFFF;
	text-align: start;
	margin-bottom: 0;
}


.topproductcat .topproductcatbox:first-child img {
	filter: invert(0);
}

.topproductcat .topproductcatbox:first-child:hover img {
	filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
}

.topproduct .topproduct-text p {
	font-size: 16px;
	font-weight: 500;
	color: #FFFFFF;
	text-align: start;
	line-height: 20px;
}

.topproductmain {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #E3E3E3;
}

.topproductmain .topproducttext h3 {
	color: #000000;
	font-size: 2.151rem;
	font-weight: 700;
}

.topproductmain .topproducttext span {
	color: #000000;
	font-size: 2.151rem;
	font-weight: 900;
}

.topproductmain .topproducttext p {
	color: #000000;
	font-size: 1.195;
	font-weight: 400;
	text-align: start;

}

.topproductmain .topproducttext {
	max-width: 373px;
}

.topproductimg img {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}

.topproductmain .topproducttext .topproductsliderbtn {
	background: #000000;
	border-radius: 4px;
	font-style: normal;
	font-weight: 700;
	font-size: 0.843rem;
	display: inline-block;
	padding: 10px;
	color: white;
}

a.topproductshowallbtn {
	background-color: white;
	border: 1px solid #EF000B;
	color: #EF000B !important;
	padding: 8px 19px;
	border-radius: 5px;
	font-size: 20px;
	font-weight: 700;
	/* box-shadow: 2px 2px 10px 0px #EF000B; */
	display: inline-block;
	width: 150px;
	height: 40px;
	color: #EF000B;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif;

}

.topbgcolor {
	background-color: white;
	padding: 35px;
	max-width: 900px;
	margin: 0 auto;
}

.topproductcat {
	align-items: center;
	justify-content: center;
	display: flex;
	position: relative;
	margin-top: 40px;
}

.topproductcatbox p {
	color: #FFFFFF;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 0;
	font-size: 14px;
	padding: 0 5px;
	text-align: center;
	line-height: 17px;
}

.topproductcat .topproductcatbox img {
	width: auto;
	filter: invert(1);
}

.topproductcat .topproductcatbox.active {
	background-color: white;
	cursor: pointer;
}

.topproductcat .topproductcatbox.active img {
	filter: invert(29%) sepia(98%) saturate(1911%) hue-rotate(122deg) brightness(97%) contrast(101%);
}

.topproductcat .topproductcatbox.active:first-child img {
	filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
}

.topproductcat .topproductcatbox.active p {
	filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
}

.topproductcatbox.active:after {
	position: absolute;
	top: -9px;
	left: 50%;
	margin-left: -10px;
	content: "";
	display: block;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid white;
}

.topproductcat .topproductcatbox:hover img {
	filter: invert(29%) sepia(98%) saturate(1911%) hue-rotate(122deg) brightness(97%) contrast(101%);
}

.topproductcat .topproductcatbox:hover p {
	filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
}

.topproductcatbox:hover {
	background-color: white;
	cursor: pointer;

}

.topproductcatbox {
	width: 100px;
	height: 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	transition: 0.3s all ease-out;
}

.topproductcatbox:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	background: #BDBDBD;
	height: 100%;
	width: 1px;
	bottom: 0;
	margin: auto;
}

.topproductcatbox:hover:before {
	display: none;
}

.topproductcatbox:hover:after {
	position: absolute;
	top: -10px;
	left: 50%;
	margin-left: -10px;
	content: "";
	display: block;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid white;
}

.topproductcatbox:last-child:before {
	display: none;
}


.main_offers .offer_text {
	min-height: 0;
}

.p_slider.product_inner,
.p_slider.offer_inner {
	display: flex;
	align-items: center;
	margin: 10px auto;
	justify-content: center;
}


.ctaonebutton {
	/* Style for the primary button */
	background-color: #007bff;
	color: #fff;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	text-decoration: none;
}

.ctaonebutton:hover {
	background-color: #0056b3;
}

.ctanewtwobutton {
	/* Additional styles for secondary button */
	background-color: #28a745;
}

.ctanewtwobutton:hover {
	background-color: #218838;
}

.ourbest {
	margin: 40px 0;
}

.ourbest .ourbexttext h2 {
	color: #000000;
	font-size: 30px;
	text-align: center;
	font-weight: 700;
}

.ourbest .ourbexttext p {
	color: #505050;
	font-size: 16px;
	text-align: center;
	font-weight: 500;
	line-height: 20px;
}

.ourexpertuser {

	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
	text-align: center;
	border-radius: 18px;
	margin: 10px;
	position: relative;
}

.ourexpertuser .ourexpertinner h3 {
	font-size: 16px;
	font-weight: 700;
	color: #000000;
	line-height: 20px;
	margin: 10px 0;
	padding: 0 0 10px 0;
}

.ourexpertuser .ourexpertinner p {
	color: #000;
	text-align: center;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	margin: 10px 0;
	min-height: 100px;
	font-family: 'microsoft_sans_serifregular' !important;
}

.ourexperticoninner {
	display: flex;
	align-items: center;
	border-radius: 0px 0px 18px 18px;
	padding: 10px;
	background-color: #90E790;
	justify-content: center;
	height: 75px;
}

.ourexperticoninner span {
	font-size: 1.25rem;
	font-weight: 700;
}

.ourexperticoninner p {
	color: #000;
	font-family: "Fjalla One", sans-serif !important;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 25px;
	position: relative;
}

.nopadright {
	padding-right: 0;
}

.ourexpertinner img {
	position: absolute;
	top: -25%;
	left: 0;
	right: 0;
	margin: auto;
}

.ourexpertinner {
	padding: 30px 10px 0 10px;
	margin: 100px 0 0 0;
	position: relative;
}

.nopad {
	padding: 0 2px !important;
}

.ourexperticoninner img {
	width: 55px;
}


a.searchnow {
	background-color: var(--green);
	color: white;
	color: #FFF;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	width: 200px;
	display: flex;
	text-align: center;
	height: 60px;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
}

.homecustom .latestbutton.margintop {
	margin-top: 10px;
}

/*----Expert Dynamic css---*/
.homecustom .ourbest .category-id5:hover,
.homecustom .ourbest .category-id4:hover,
.homecustom .ourbest .category-id3:hover,
.homecustom .ourbest .category-id2:hover,
.homecustom .ourbest .category-id1:hover {
	box-shadow: none;
}

.expertoutershadow:hover {
	box-shadow: 0px 0px 11px 0px var(--green);
	cursor: pointer;
	border-radius: 18px;
}

.expertoutershadow:hover .ourexpertinnerimgborder img {
	box-shadow: 0px 0px 20px 0px var(--green);

	cursor: pointer;
}

.category-id5 .expertoutershadow:hover {
	box-shadow: 0px 0px 11px 0px var(--green);
	cursor: pointer;
	border-radius: 18px;
}

.category-id1 .expertoutershadow:hover,
.category-id1:hover {
	box-shadow: 0px 0px 11px 0px var(--orange);
}

.category-id2 .expertoutershadow:hover,
.category-id2:hover {
	box-shadow: 0px 0px 11px 0px var(--purple);
}

.category-id5 .expertoutershadow:hover,
.category-id5:hover {
	box-shadow: 0px 0px 11px 0px var(--green);
}

.category-id3 .expertoutershadow:hover,
.category-id3:hover {
	box-shadow: 0px 0px 11px 0px var(--Blueone);
}

.category-id4 .expertoutershadow:hover,
.category-id4:hover {
	box-shadow: 0px 0px 11px 0px var(--Bluetwo);
}

.category-id1 .expertbuttonlist {
	background: var(--orange);
}

.category-id2 .expertbuttonlist {
	background: var(--purple);
}

.category-id3 .expertbuttonlist {
	background: var(--Blueone);
}

.category-id4 .expertbuttonlist {
	background: var(--Bluetwo);
}

.category-id5 .expertbuttonlist {
	background: var(--green);
}

.category-id1:hover .expertbuttonlist {
	border-color: var(--orange);
	color: var(--orange);
	background: #fff;
}

.category-id2:hover .expertbuttonlist {
	border-color: var(--purple);
	color: var(--purple);
	background: #fff;
}

.category-id3:hover .expertbuttonlist {
	border-color: var(--Blueone);
	color: var(--Blueone);
	background: #fff;
}

.category-id4:hover .expertbuttonlist {
	border-color: var(--Bluetwo);
	color: var(--Bluetwo);
	background: #fff;
}

.category-id5:hover .expertbuttonlist {
	border-color: var(--green);
	color: var(--green);
	background: #fff;
}

.category-id1:hover .ourexpertinnerimgborder img {
	border-color: var(--orange);
}

.category-id2 .ourexpertinnerimgborder img {
	border-color: var(--purple);
}

.category-id1 .ourexpertinnerimgborder img {
	border-color: var(--orange);
}

.category-id3 .ourexpertinnerimgborder img {
	border-color: var(--Blueone);
}

.category-id4 .ourexpertinnerimgborder img {
	border-color: var(--Bluetwo);
}

.category-id5 .ourexpertinnerimgborder img {
	border-color: var(--green);
}

.category-id1 .expertallouter:hover .ourexpertinnerimgborder img,
.category-id1.expertallouter:hover .ourexpertinnerimgborder img {
	box-shadow: 0px 0px 11px 0px var(--orange);
	cursor: pointer;

}

.category-id2 .expertallouter:hover .ourexpertinnerimgborder img,
.category-id2.expertallouter:hover .ourexpertinnerimgborder img {
	box-shadow: 0px 0px 11px 0px var(--purple);
	cursor: pointer;

}

.category-id3 .expertallouter:hover .ourexpertinnerimgborder img,
.category-id3.expertallouter:hover .ourexpertinnerimgborder img {
	box-shadow: 0px 0px 11px 0px var(--Blueone);
	cursor: pointer;

}

.category-id4 .expertallouter:hover .ourexpertinnerimgborder img,
.category-id4.expertallouter:hover .ourexpertinnerimgborder img {
	box-shadow: 0px 0px 11px 0px var(--Bluetwo);
	cursor: pointer;

}

.category-id5 .expertallouter:hover .ourexpertinnerimgborder img,
.category-id5.expertallouter:hover .ourexpertinnerimgborder img {
	box-shadow: 0px 0px 11px 0px var(--green);
	cursor: pointer;

}

.category-id1 .ourexpertuser:hover {
	box-shadow: 0px 0px 11px 0px var(--orange);
	cursor: pointer;
	border-radius: 18px;
}

.category-id2 .ourexpertuser:hover {
	box-shadow: 0px 0px 11px 0px var(--purple);
	cursor: pointer;
	border-radius: 18px;
}


.category-id3 .ourexpertuser:hover {
	box-shadow: 0px 0px 11px 0px var(--Blueone);
	cursor: pointer;
	border-radius: 18px;
}

.category-id5 .ourexpertuser:hover {
	box-shadow: 0px 0px 11px 0px var(--green);
	cursor: pointer;
	border-radius: 18px;
}

.category-id4 .ourexpertuser:hover {
	box-shadow: 0px 0px 11px 0px var(--Bluetwo);
	cursor: pointer;
	border-radius: 18px;
}

/*----Expert Dynamic css End---*/
.main-search {
	margin-left: 90px;
}

.main-search h2 {
	font-size: 30px;
	font-weight: 700;
	color: #000000;
	text-align: start;

}

.main-search p {
	font-size: 16px;
	font-weight: 500;
	color: #000000;
	text-align: start;
	max-width: 437px;
	margin: 30px 0 50px;
	line-height: 20px;
}

.articles {
	margin: 40px 0;
}

.item1 img:nth-child(1) {
	margin: 0 0 20px 0;
}

.col-lg-6.padleft30 {
	padding-left: 55px;
}

.articles .articles-text h2 {
	font-size: 30px;
	font-weight: 700;
	color: #000000;
	text-align: center;
}

.articles .articles-text p {
	font-size: 16px;
	font-weight: 500;
	color: #505050;
	text-align: center;
	margin-bottom: 20px;
}

.quotes {
	margin: 30px 0;
	display: none;
}

.quotes .quotes-inner {
	box-shadow: 3px 3px 33px 0px #00000040;
	padding: 50px 60px;
}

.quotes .quotes-main h2 {
	font-size: 3rem;
	font-weight: 700;
	text-align: center;
	margin: 20px 0;
	color: #000000;
}

a.explorebtn {
	color: #000000;
	font-size: 2.188rem;
	font-weight: 600;
	font-family: "Montserrat", sans-serif !important;
}

a.explorebtn:hover {
	cursor: pointer;
}

.quotes-text h5,
.quotes-text h2 {
	font-size: 35px;
	font-weight: 600;
	text-align: start;
	margin-bottom: 40px;
	color: #000000;
	max-width: 566px;

}

.quotes-inner .row {
	align-items: center;
}

.quotes-text p {
	font-size: 35px;
	font-weight: 600;
	text-align: start;
	color: #06283D;
	margin-bottom: 20px;
	line-height: 43px;
}

p.weoffer {
	font-size: 1.5rem;
	font-weight: 600;
	text-align: start;
	font-style: italic;
	color: #505050;
}

.quotes-text {
	position: relative;
	padding: 0 10px 0 25px;
}

.quotes-text:before {
	position: absolute;
	top: 52px;
	width: 64px;
	height: 40px;
	content: "";
	background-image: url("../../../assets/homeimages/quoteicon1.png");
	background-repeat: no-repeat;
	left: -46px;

}

.quotes-text:after {
	position: absolute;
	right: 0px;
	width: 64px;
	height: 40px;
	bottom: 85px;
	top: 180px;
	content: "";
	background-image: url("../../../assets/homeimages/quoteicon2.svg");
	background-repeat: no-repeat;
}

.mortgagequot.quotes-text:after {
	bottom: 170px;
}

a.quotesmortgagebtn {
	/* background-color: var(--orange); */
	color: white;
	padding: 5px 39px;
	border-radius: 5px;
	font-size: 1.6rem;
	display: flex;
	align-items: center;
	font-weight: 400;
	/* box-shadow: 3px 33px 33px 0px #00000040; */
}

a.quotesinsurancebtn {
	/* background-color: #68A2BF; */
	color: white;
	display: flex;
	align-items: center;
	padding: 5px 39px;
	border-radius: 5px;
	font-size: 1.6rem;
	font-weight: 400;
	/* box-shadow: 3px 33px 33px 0px #00000040; */

}

.quotesmortgagebtn.active,
.quotesinsurancebtn.active {
	background-color: white;
	color: white;
}


a.showmebtn,
button.showmebtn {
	background-color: #E34400;
	color: white;
	padding: 8px 39px;
	border-radius: 5px;
	font-size: 1.125rem;
	font-weight: 700;
	display: block;
	width: 100%;
}

a.showmebtn:hover,
button.showmebtn:hover {
	background-color: #B9ADA8;
	color: white;
	cursor: pointer;
}

.quotes-text-right {
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	align-items: center;
	border-radius: 8px;
}

.quotes-bg-color {
	margin-bottom: 37px;
}

.quotes-form .inputextsize input {
	box-shadow: 3px 3px 10px 0px #E34400;
}

.quotes-main-right .quotes-bg-color .quotes-text-right .quotes-on-btn {
	position: relative;
}

.quotes-main-right .quotes-bg-color .quotes-text-right .quotes-on-btn a {
	font-family: "Fjalla One", sans-serif;
	font-size: 40px;
	font-weight: 400;
	line-height: 50px;
	text-align: left;
}

.quotes-main-right .quotes-bg-color .quotes-text-right .quotes-on-btn a.quotesmortgagebtn.active:after {
	content: "";
	position: absolute;
	bottom: -20px;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid rgba(47, 89, 111, 1);
	box-shadow: 3px 3px 33px 0px rgba(0, 0, 0, 0.25);

	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

	clear: both;
	left: 44%;
}

.quotes-main-right .quotes-bg-color .quotes-text-right .quotes-on-btn a.quotesinsurancebtn.active:after {
	content: "";
	position: absolute;
	bottom: -20px;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid rgba(227, 68, 0, 1);
	box-shadow: 3px 3px 33px 0px rgba(0, 0, 0, 0.25);

	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

	clear: both;
	left: 44%;
}

.inputextsize.mortgageshadow input {
	box-shadow: 3px 3px 10px 0px var(--Blueone);
}

a.showmebtn.mortgagebuttoncolo,
button.showmebtn.mortgagebuttoncolor {
	background-color: var(--Blueone);
	color: white;
}

a.showmebtn.mortgagebuttoncolor:hover,
button.showmebtn.mortgagebuttoncolor:hover {
	background-color: #B9ADA8;
	color: white;
}

.insuranceonclickform {
	background-image: url("../../../assets//homeimages/insuranceformbg.png");
	background-repeat: no-repeat;
	width: 100%;
	background-size: cover;
	padding: 30px;
}

.morgageonclickform {
	background-image: url("../../../assets//homeimages/mortgageformbg.png");
	background-repeat: no-repeat;
	width: 100%;
	padding: 30px;
	background-size: cover;
}

a.quotesmortgagebtn {
	color: var(--Blueone);
}

a.quotesinsurancebtn {
	color: #E34400;
}

.quotes-form input {
	padding: 30px;
}

.grid-container>div {

	border-radius: 30px;

	padding: 1em;
	color: #d9480f;
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 50px;
	grid-auto-rows: minmax(100px, auto);
}

.item1 {
	display: grid;
	align-items: center;
	justify-content: center;
	grid-template-columns: 350px 1fr 1fr;
	grid-gap: 20px;
	grid-column-start: auto;
}

.item1 .itemtts {
	align-self: stretch;
	align-items: center;
	display: flex;
}

.item1 .itemtts:first-child {
	grid-row-start: 1;
	grid-row-end: 3;
	background-color: #daf0ff;

	display: flex;
	justify-content: center;
}

.item2 {
	grid-column: 2 / 2;
	grid-row: 1 / 3;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #EEECFB;
}

.item1 .itemtts:nth-child(2),
.item1 .itemtts:nth-child(7) {
	background-color: #eeecfb;
}

.item1 .itemtts:nth-child(3),
.item1 .itemtts:nth-child(8) {
	background-color: #daf0ff;
}

.item1 .itemtts:nth-child(4),
.item1 .itemtts:nth-child(9) {
	background-color: #ffe8a4;
}

.item1 .itemtts:nth-child(5),
.item1 .itemtts:nth-child(6) {
	background-color: #eeecfb;
}

.item1 .itemtts:first-child a {
	display: block;
	width: 100%;
	text-align: center;
	margin: auto;
}

.item1 .itemtts:first-child a .item-img {
	margin-left: auto;
	margin-right: auto;
}

.item1 .itemtts:first-child a .item-text {
	margin-left: 0;
	text-align: center;
	margin: auto;
	width: 100%;
	padding: 0 20px;
}

.item-text h5 {
	color: #000;
	text-align: start;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	text-align: left;
	line-height: 18px;
	margin-bottom: 20px;
}

.item-text p {
	font-size: 12px;
	font-weight: 400;
	text-align: left;
	margin-bottom: 0;
	color: #000;
	line-height: 14px;
	font-family: 'microsoft_sans_serifregular' !important;
}


.itemtts .item-img {
	width: 100px;
	border-radius: 100px;
	height: 100px;
}

.itemtts .item-img img {
	border-radius: 100px;
	width: 100px;
	height: 100px;
	object-fit: cover;
}

.itemtts a {
	display: flex;
	flex-wrap: nowrap;
	padding: 40px 20px;
	align-items: center;
	cursor: pointer;
}

.item1 .itemtts:first-child a .item-img {
	margin-bottom: 40px;
}

.item1 .itemtts:first-child a .item-text h5 {
	margin-bottom: 20px;
}

.itemtts .item-text {
	width: 70%;
}

.itemtts {
	border-radius: 30px;

}

.item3 {
	grid-column: 3/3;
	grid-row: 1 / 3;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #DAF0FF;
}

.item4 {
	grid-column: 2;
	grid-row: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #FFE8A4;
}

.item5 {
	grid-column: 4/3;
	grid-row: 3;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #EEECFB;
}

.grid-container h5 {
	color: #000000;
	font-size: 1.25rem;
	font-weight: 600;
	text-align: start;

}

.grid-container p {
	color: #000000;
	font-size: 0.813rem;
	font-weight: 400;
	font-style: italic;
	text-align: start;

}

.grid-container img {
	width: 138px;
}

.item-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	margin-left: 20px;
}

a.viewalltwo {
	background-color: var(--green);
	color: white;
	padding: 15px 39px;
	border-radius: 5px;
	font-size: 1.5rem;
	font-weight: 700;
}

.searchtop {
	margin: 40px 0;
	background: #EEF7FF;
}

.searchtop .container {
	padding: 0;
}


.buttonsearchnowtoo {
	margin: 50px 0;
}

a.showallourbest {
	background: #FFFFFF;
	border-radius: 5px;
	font-weight: 700;
	font-size: 1.5rem;
	display: inline-block;
	color: #000000;
	padding: 10px 35px;
}

.ourbestimgslider .slick-prev:before,
.ourbestimgslider .slick-next:before {
	font-size: 0px;
}

.ourbestimgslider .slick-dots li {
	fill: #FFF;
	stroke-width: 0.76px;
	stroke: #EDE1E1;
	box-shadow: 0px 3.04px 3.04px 0px rgba(0, 0, 0, 0.25) inset;
	filter: drop-shadow(0px 3.04px 3.04px rgba(0, 0, 0, 0.25));
}

.ourbestimgslider .slick-dots li {
	width: 14px;
	height: 14px;
}

.ourbestimgslider .slick-dots .slick-active {
	width: 20px;
	height: 20px;
}

.mainsliderdescription {
	min-height: 40px;
}


a.searchnowtoo {
	background: var(--green);
	border-radius: 5px;
	font-weight: 700;
	font-size: 1.5rem;
	display: inline-block;
	color: white;
	padding: 10px 35px;
	border: 1px solid transparent;
	text-decoration: none;
}

.buttonsearchmaim {
	margin-left: 90px;
}

.itemtts:hover {
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.41));
	cursor: pointer;
}

.searchtop-text {
	margin-left: 90px;
}

.searchtop-text h2 {
	font-weight: 700;
	color: #000000;
	font-size: 30px;
	margin-bottom: 20px;
}

.searchtop-text p {
	font-weight: 500;
	color: #000000;
	font-size: 16px;
	text-align: start;
	max-width: 520px;
	line-height: 20px;
}

a.searchnowtoo:hover {
	cursor: pointer;
	background-color: transparent;
	border: 1px solid black;
}

a.showallourbest:hover {
	cursor: pointer;
	background-color: transparent;
	border: 1px solid white;
	color: white;
}

a.viewalltwo:hover {
	cursor: pointer;
	background-color: transparent;
	border: 1px solid black;
	color: black;
}

a.searchnow:hover {
	background: #FFFFFF;
	color: var(--green);
	border: 2px solid var(--green);
	/* box-shadow: 0px 0px 10px 0px var(--green); */
}

a.topproductshowallbtn:hover {
	cursor: pointer;
	background-color: transparent;
	border: 1px solid black;
	color: white;
	/* box-shadow: 2px 2px 10px 0px #EF000B; */

}

a.showallbtn:hover {
	cursor: pointer;
	background-color: transparent;

	color: black;
	/* box-shadow: 2px 2px 10px 0px #EF000B; */

}

.ourbest .col-2,
.heroinner .col-2 {
	flex: 0 0 auto;
	width: 19.666667%;
}

.ourbest .col-2 {
	padding: 0;
}

.topproductcatbox:hover img {
	filter: invert(1);
}

.whatarecatbox:hover img {
	filter: invert(1);
}

/* .whatarecatbox:hover p {
	filter: invert(1);
} */

.padleft30 {
	padding-right: 1px;
}

.exclusive .slick-prev:before {
	content: "";
	background: url(../../../assets/homeimages/slicknext.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	rotate: 180deg;
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
}

.exclusive .slick-prev:hover:before {
	opacity: 1;

}

.exclusive .slick-next:before {
	content: "";
	background: url(../../../assets/homeimages/slicknext.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	rotate: 0deg;
	border-radius: 3px;
	display: block;
	position: absolute;
	left: 1px;
	top: 0;
	opacity: 1;
}

.exclusive .slick-next:hover:before {

	opacity: 1;
}

.topproduct .slick-prev,
.topproduct .slick-next,
.exclusive .slick-prev,
.exclusive .slick-next {
	width: 30px;
	height: 30px;
}

.topproduct .slick-prev:before {
	content: "";
	background: url(../../../assets/homeimages/slicknext.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	transform: rotate(180deg);
	display: block;
	right: 0;
	left: 0;
	opacity: 1;
	position: absolute;

}

.applybutton {
	/* color: #00831D;
    font-family: "Roboto Flex", sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    border-radius: 8.014px;
    border: 1.603px solid #00831D;
    background: #D2FFD2;
    width: 150px;
    height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    padding: 10px;
    transition: 0.3s all ease-in-out; */
}

.applybutton:hover {
	border-radius: 8.014px;
	border: 1.603px solid #00831D;
	background: #FFF;
	color: #00831D;
	box-shadow: 0px 0px 6px 0px #00831D;
}

/* .applybutton:hover{border-radius: 8.014px;
	border: 1.603px solid #00831D;
	background: #FFF;
	color: #00831D;
	box-shadow: 0px 0px 6px 0px #00831D;} */
.topproduct .slick-prev:hover:before {


	opacity: 1;
}

.topproduct .slick-next:before {
	content: "";
	background: url(../../../assets/homeimages/slicknext.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	rotate: 0deg;
	display: block;
	right: 0;
	left: 0;
	opacity: 1;
	position: absolute;
}

.topproduct .slick-next:hover:before {
	opacity: 1;


}

.ourbestimgslider .slick-next:before {
	content: "";
	background: url(../../../assets/homeimages/slicknext2.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	rotate: 0deg;
	display: block;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	filter: invert(1);
}

.ourbestimgslider .slick-prev:before {
	content: "";
	background: url(../../../assets/homeimages/slicknext2.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	transform: rotate(180deg);
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
	filter: invert(1);
}

.ourbestimgslider .slick-prev {
	left: -35px;
}

.ourbestimgslider .slick-prev,
.ourbestimgslider .slick-next {
	height: 30px;
	width: 30px;
}

.p_slider.product_inner img {
	border: 11px solid #fff;
}


/* .topproduct .product-top {
    height: 424px;
    display: flex;
    justify-content: center;
    align-items: baseline;
} */
.topproduct .product-top img {
	border: 15px solid #fff;
	width: 100%;

}

.exclusive .exclusived img:hover {
	cursor: pointer;
	box-shadow: 0px 0px 15px 0px var(--green);
}

.topproduct .product-top img:hover {
	cursor: pointer;
	box-shadow: 0 0 15px 0 #FFFFFF;
}

.topproduct .p_slider,
.exclusive .p_slider {
	background: transparent;
	position: relative;
	padding-top: 51%;
}

.topproduct .p_slider {
	margin-bottom: 10px;
}

.homecustom .slick-current {
	transform: scale(1);
}

.ourbest .col-lg-2 {
	width: 19.666667%;
	padding: 0;
}


.exclusive .slick-next {
	right: -15px;
}

.exclusive .slick-prev {
	left: -15px;
}

.p_sliderouter.main_offers.exclusivepadding .sldeimage.offer_images img {
	border: 15px solid #fff;
	cursor: pointer;
}

.category-id5 .ourexpertuser {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
	/* margin-left: 0; */
}

.category-id4 .ourexpertuser {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
	margin-right: 6px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}


.slick-dots-container {
	width: 5.5rem;
	overflow: hidden;
	display: block;
	padding: 0;
	margin: 0.625rem auto;
	height: 0.875rem;
	position: relative;
}

.slick-dots-container>ul {
	padding: 0;
	display: flex;
	transition: all 0.25s;
	position: relative;
	margin: 0;
	list-style: none;
	transform: translateX(0);
	align-items: center;
	bottom: unset;
	height: 100%;
}

.slick-dots-container>ul li {
	width: 0.625rem;
	height: 0.625rem;
	margin: 0 0.25rem;
	background-color: #efd5d5;
	border: none;
	border-radius: 50%;
}

.slick-dots-container>ul li button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 1.25rem;
	height: 1.25rem;
	padding: 0.3125rem;
	cursor: pointer;
	color: transparent;
	border: 0;
	outline: 0;
	background: 0 0;
}

.slick-dots-container>ul li.p-small-1,
.slick-dots-container>ul li.n-small-1 {
	transform: scale(0.8);
}

.slick-dots-container>ul li.slick-active {
	transform: scale(1.3);
	transform-origin: center;
	background: #3498db;
}

/* .slick-dots li button:before {
	display: none;
} */

header {
	background-image: url("../../../assets/homeimages/questionbg.png");
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
}

header .custom.nav {
	background: transparent !important;
}

.smmore {
	text-align: right;
}

.ourexpertinnerimg {
	position: absolute;
	top: -37px;
	right: 0;
	left: 0;
	margin: auto;
	border-radius: 200px;
	width: 80px;
	height: 80px;
}

.ourexpertinnerimg img {
	border-radius: 80px;
	width: 80px;
	height: 80px;
	border: 4px solid #BCF5BC;
}

.category-id5 {
	order: 1
}

.category-id2 {
	order: 2
}

.category-id3 {
	order: 3
}

.category-id1 {
	order: 4
}

.category-id4 {
	order: 5
}

.category-id2 .expertButtonContainer {
	background: rgba(240, 173, 231, 1);
}

.category-id3 .expertButtonContainer {
	background: rgba(179, 193, 221, 1);
}

.category-id1 .expertButtonContainer {
	background: rgba(255, 180, 148, 1);
}

.category-id4 .expertButtonContainer {
	background: rgba(158, 219, 255, 1);
}

.category-id5 .expertButtonContainer {
	background: #90E790;
}

.ourexperticoninner p {
	position: relative;
	padding-left: 40px;
}

.ourexperticoninner p:before {
	content: "";
	position: absolute;
	height: 54px;
	width: 54px;
	background-repeat: no-repeat !important;
	background-size: contain !important;
	background: url(../../../assets/homeimages/ourbesticon1.png);
	top: -17px;
	left: -31px;
}

.category-id2 .expertButtonContainer p:before {
	background: url(../../../assets/homeimages/ourbesticon2.png);
	
}

.category-id3 .expertButtonContainer p:before {
	background: url(../../../assets/homeimages/ourbesticon33.png);
}

.category-id1 .expertButtonContainer p:before {
	background: url(../../../assets/homeimages/ourbesticon4.png);
}

.category-id4 .expertButtonContainer p:before {
	background: url(../../../assets/homeimages/ourbesticon5.png);
}

.category-id5 .expertButtonContainer p:before {
	background: url(../../../assets/homeimages/ourbesticon1.png);
}

.ourexpertinner h3 {
	position: relative;
}

.ourexpertinnerborder h3:after {
	content: "";
	position: absolute;
	bottom: 0;
	background: rgba(144, 231, 144, 1);
	width: 100px;
	height: 3px;
	left: 0;
	right: 0;
	margin: auto;
}

.category-id2 h3.ourexpertinnerborder:after {
	background: rgba(240, 173, 231, 1);
}

.category-id3 h3.ourexpertinnerborder:after {
	background: rgba(179, 193, 221, 1);
}

.category-id1 h3.ourexpertinnerborder:after {
	background: rgba(255, 180, 148, 1);
}

.category-id4 h3.ourexpertinnerborder:after {
	background: rgba(158, 219, 255, 1);
}

.category-id5 h3.ourexpertinnerborder:after {
	background: rgba(144, 231, 144, 1);
}

.showallourbesttoo:hover,
a.topproductshowallbtn:hover,
a.showallbtn:hover {
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #EF000B;
	background: #EF000B;
	color: #FFF !important;

	/* box-shadow: 2px 2px 10px 0px #EF000B; */
}


.exclusive-slider .slick-dots li.slick-active button {
	background: #000;
	width: 8px;
	height: 8px;
}

.exclusive-slider .slick-dots li {
	display: none;
}

/* Show the 5 dots around the active dot */
.exclusive-slider .slick-dots li.slick-active,
.exclusive-slider .slick-dots li.slick-active~li:nth-child(-n+2),
/* Show next two dots */
.exclusive-slider .slick-dots li.slick-active~li:nth-last-child(-n+2)

/* Show previous two dots */
	{
	display: inline-block;
}

/* Ensure all dots have consistent styling */
.exclusive-slider .slick-dots li button {
	background-color: gray;
	border: none;
	border-radius: 50%;
	width: 10px;
	height: 10px;
}

.exclusive-slider .slick-dots li {
	width: 10px;
	height: 10px;
}

/* Highlight the active dot */
.exclusive-slider .slick-dots li.slick-active button {
	background-color: black;
}

.prodectsslider .slick-dots li.slick-active button {
	background-color: white;
}


@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');

.rimib-block {
	background: linear-gradient(180deg, #B8FFB8 0%, #FFF 100%);
	text-align: center;
}

.rimib-block-two {
	background: linear-gradient(0deg, #FFF -0.12%, #FFD0F8 102.5%);
}

.rimib-block-two .rimib-block-letter {
	color: var(--purple);
	text-shadow: 3px 8px 17px rgba(0, 0, 0, 0.25);
}

.rimib-block-two .rimib-block-letter-text {
	color: var(--purple);
}

.rimib-block-letter {
	color: var(--green);
	text-shadow: 3px 8px 17px rgba(0, 0, 0, 0.25);
	font-family: "Red Hat Display", sans-serif;
	font-size: 280px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	line-height: 208px;
	padding-top: 50px;
	padding-bottom: 20px;
}

.rimib-block-letter-image {
	margin-top: 30px;
	margin-bottom: 35px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.rimib-block-letter-image img {
	height: 50px;
	margin: auto;
}

.impspace:first-child .rimib-block-letter-image img {
	filter: invert(26%) sepia(98%) saturate(1479%) hue-rotate(118deg) brightness(92%) contrast(104%);
}

.impspace:nth-child(2) .rimib-block-letter-image img {
	filter: invert(25%) sepia(22%) saturate(3439%) hue-rotate(272deg) brightness(92%) contrast(85%);
}

.impspace:nth-child(3) .rimib-block-letter-image img {
	filter: invert(33%) sepia(15%) saturate(1473%) hue-rotate(156deg) brightness(88%) contrast(91%);
}

.impspace:nth-child(4) .rimib-block-letter-image img {
	filter: invert(46%) sepia(50%) saturate(6009%) hue-rotate(358deg) brightness(98%) contrast(111%);
}

.impspace:last-child .rimib-block-letter-image img {
	filter: invert(40%) sepia(100%) saturate(450%) hue-rotate(158deg) brightness(90%) contrast(85%);
}

.rimib-block-letter-text {
	color: var(--green);
	font-family: "Fjalla One", sans-serif;
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.rimib-block-three {
	background: linear-gradient(180deg, #D1D9E9 0%, #FFF 100%);
}

.rimib-block-three .rimib-block-letter {
	color: var(--Blueone);
	text-shadow: 3px 8px 17px rgba(0, 0, 0, 0.25);
}

.rimib-block-three .rimib-block-letter-text {
	color: var(--Blueone);
}

.rimib-block-four {
	background: linear-gradient(180deg, #FFCE9D 0%, #FFF 100%);
}

.rimib-block-four .rimib-block-letter {
	color: var(--orange);

	text-shadow: 3px 8px 17px rgba(0, 0, 0, 0.25);
}

.rimib-block-four .rimib-block-letter-text {
	color: var(--orange);
}

.rimib-block-five {
	background: linear-gradient(180deg, #C3E3FF 0%, #FFF 100%);
}

.rimib-block-five .rimib-block-letter {
	color: var(--Bluetwo);

	text-shadow: 3px 8px 17px rgba(0, 0, 0, 0.25);
}

.rimib-block-five .rimib-block-letter-text {
	color: var(--Bluetwo);
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before,
.exclusive .slick-prev.slick-disabled:before,
.exclusive .slick-next.slick-disabled:before {
	opacity: .25 !important;
}

/* .impspace .slick-slide>div {
	
	transition: transform .3s cubic-bezier(.4, 0, .2, 1);
  }
  .impspace .slider-track {
	-webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
	transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
  }
  .slideimpspaceshow  img {
	width: 100%;
	-webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
	transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
	-webkit-transform: scale(1.2);
			transform: scale(1.2);
  }
  .impspace .slick-active img {
	-webkit-transform: scale(1);
			transform: scale(1);
	-webkit-animation: cssAnimation 8s 1 ease-in-out;
	animation: cssAnimation 8s 1 ease-in-out;
  }
   */


/* .product_image img:hover {
	box-shadow: 0 0 12px 2px #FFFFFF;
	cursor: pointer;
} */

.p_slider.product_inner .sldeimage.product_image img,
.p_slider.offer_inner .sldeimage.offer_image img {
	border: 7px solid #fff;
	height: 100%;
	object-fit: contain;
	width: 100%;
	background: #fff;
}

.Ourintitutions {
	background-image: url("../../../assets/homeimages/Ourintitutions.svg");
	background-repeat: no-repeat;
	width: 100%;
	max-height: 560px;
	background-size: cover;
	margin-bottom: 0;
	padding: 40px 0 20px;
}

.flexflow {
	justify-content: center;
	align-items: center;
}

.ourbestimgslider {
	max-width: 680px;
	padding-left: 30px;
}


.ourbestimgslider ul.slick-dots {
	bottom: -24px;
	display: flex !important;
	justify-content: center;
	align-items: center;
}


.p_sliderouter.main_offers.exclusivepadding .sldeimage.offer_images img {
	border: 15px solid #fff;
	cursor: pointer;
}


.p_sliderouter.main_offers.exclusivepadding,
.p_sliderouter .product_main .exclusivepadding {
	padding-bottom: 0px;
	margin-bottom: 8px;
	margin-top: 8px;
}

.p_sliderouter.mainofferslider .offerbg {
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}


.p_sliderouter.mainofferslider {
	margin-bottom: 0;
}


.p_sliderouter.main_offers.exclusivepadding,
.p_sliderouter.product_main.exclusivepadding {
	padding-bottom: 0px;
}

.showallourbesttoo:hover {
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #EF000B;
	background: #EF000B;
	color: #FFF !important;
}

.showallourbesttoo {
	background-color: white;
	border: 1px solid #EF000B;
	padding: 8px 19px;
	border-radius: 5px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	width: 150px !important;
	height: 40px;
	color: #EF000B;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif;
	margin-left: auto;
	margin-right: auto;
}

.exclusive .carddecoration {

	box-shadow: 0px 2px 10px 0px #0000001A;
}

.offerlistpadding {
	padding: 15px 78px 20px 78px;
	width: 1366px;
	margin: 10px auto
}

.item1 .bloglist .basicuserdetails {
	display: none !important;
}


.homecustom .herosection .slick-slide {
	opacity: 0;
}

.homecustom .herosection .slick-slider .slick-slide.slick-active.slick-current {
	opacity: 1;
}

.categorysectionfour .bestexperts .slick-track {
	justify-content: center;
	display: flex;
	margin: auto;
}

/* .categorysectionfour  .bestexperts .slick-track .slick-slide {
	justify-content: center;
	display: flex;
	margin: auto;
  } */
.trendingsection ul.slick-dots {
	display: none !important;
}

.slick-dots li button:before {
	display: none;
}

.homecustom section.exclusive h2,
.homecustom section.exclusive h6,
.homecustom section.topproduct h2,
.homecustom section.topproduct p {
	padding-left: 7px;
}

.homecustom section.topproduct h2,
.homecustom section.topproduct p {
	color: #000;
}

.homecustom section.topproduct .slick-prev {
	left: -29px;
}

.homecustom section.topproduct .slick-next {
	right: -29px;
}

section.categorysectionsix .d-flex.basicuserdetails,
section.categorysectionsix .blogreadmore {
	display: none !important;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: none;
}


.bannerimagmobile {
	position: relative;
	text-align: center;
}

button.mobileabso {
	position: absolute;
	border-radius: 4px;
	border: 1px solid #00801D;
	width: 125px;
	height: 35px;
	color: #00801D;
	font-family: "Fjalla One";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	left: 0;
	right: 0;
	margin: auto;
	top: 5px;
	text-align: center;
	line-height: 32px;
}

button.mobileabso img {
	width: 13px;
	margin-left: 5px;
}

.tabrealestate button.mobileabso {}

.tabrealestate button.mobileabso img {
	filter: invert(26%) sepia(98%) saturate(1479%) hue-rotate(118deg) brightness(92%) contrast(104%);
}

.tabinvesment button.mobileabso {
	color: #86357A;
	border: 1px solid #86357A;
}

.tabmortgage button.mobileabso {
	color: rgb(47, 89, 111);
	border: 1px solid rgb(47, 89, 111);
}
.tabmortgage button.mobileabso img{width: 25px;}
.tabinsurance button.mobileabso img{width: 22px;}
.tabbanking button.mobileabso img{width: 20px;}
.tabinsurance button.mobileabso {
	color: #FF4C00;
	border: 1px solid #FF4C00;
}

.tabbanking button.mobileabso {
	color: #2280B8;
	border: 1px solid #2280B8;
}

.tabinvesment button.mobileabso img {
	filter: invert(25%) sepia(22%) saturate(3439%) hue-rotate(272deg) brightness(92%) contrast(85%);
}

.tabmortgage button.mobileabso img {
	filter: invert(33%) sepia(15%) saturate(1473%) hue-rotate(156deg) brightness(88%) contrast(91%);
}

.tabinsurance button.mobileabso img {
	filter: invert(46%) sepia(50%) saturate(6009%) hue-rotate(358deg) brightness(98%) contrast(111%);
}

.tabbanking button.mobileabso img {
	filter: invert(40%) sepia(100%) saturate(450%) hue-rotate(158deg) brightness(90%) contrast(85%);
}

#rimib-tab-tabrealestate {
	background: linear-gradient(180deg, #D2FFD2 0%, #FFF 100%);
	color: #00801D;
	font-family: "Fjalla One";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	width: 20%;
	height: 109px;
	text-align: center;
	position: relative;
	padding: 0;
	padding-top: 62px;
	border-radius: 0;
}

#rimib-tab-tabrealestate.active {
	background: url(../../../assets/homeimages/RealEstateActiveState.svg);
	background-position: bottom;
height:120px;
height:120px;
    color: #fff;
    background-size: cover;
}

#rimib-tab-tabrealestate.active:before,
#rimib-tab-tabinvesment.active:before,
#rimib-tab-tabmortgage.active:before,
#rimib-tab-tabinsurance.active:before,
#rimib-tab-tabbanking.active:before {
	color: #fff;
}

#rimib-tab-tabrealestate.active:after,
#rimib-tab-tabinvesment.active:after,
#rimib-tab-tabmortgage.active:after,
#rimib-tab-tabinsurance.active:after,
#rimib-tab-tabbanking.active:after {
	filter: invert(1);
	bottom: 22px;
}

#rimib-tab-tabrealestate:before {
	color: #00801D;
	text-align: center;
	text-shadow: 0.643px 1.716px 3.646px rgba(0, 0, 0, 0.25);
	font-family: "Red Hat Display";
	font-size: 59.907px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	position: absolute;
	content: "R";
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
}

#rimib-tab-tabrealestate::after {
	color: #00801D;
	text-align: center;
	text-shadow: 0.643px 1.716px 3.646px rgba(0, 0, 0, 0.25);
	position: absolute;
	content: '';
	background: url(../../../assets/homeimages/RealEstateblack.svg);
	bottom: 13px;
	left: 0;
	right: 0;
	margin: auto;
	background-size: contain;
	background-repeat: no-repeat;
	width: 10px;
	height: 14px;
	filter: invert(26%) sepia(98%) saturate(1479%) hue-rotate(118deg) brightness(92%) contrast(104%);
}

#rimib-tab-tabinvesment {
	background: linear-gradient(0deg, #FFF -0.12%, #FFD0F8 102.5%);
	width: 20%;
	height: 109px;
	text-align: center;
	color: #86357A;
	font-family: "Fjalla One";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	position: relative;
	border-radius: 0;
	padding: 0;
	padding-top: 62px;
}

#rimib-tab-tabinvesment.active {
	background: url(../../../assets/homeimages/InvestmentActivestate.svg);
	background-position: bottom;
height:120px;
    color: #fff;
    background-size: cover;
}

#rimib-tab-tabinvesment:before {
	text-align: center;
	fill: #86357A;
	filter: drop-shadow(0.643px 1.716px 3.646px rgba(0, 0, 0, 0.25));
	font-family: "Red Hat Display";
	font-size: 59.907px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	position: absolute;
	content: "I";
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
}

#rimib-tab-tabinvesment::after {

	position: absolute;
	content: '';
	background: url('../../../assets/homeimages/Investmentblack.svg');
	bottom: 13px;
	left: 0;
	right: 0;
	margin: auto;
	background-size: contain;
	background-repeat: no-repeat;
	width: 10px;
	height: 14px;
	filter: invert(25%) sepia(22%) saturate(3439%) hue-rotate(272deg) brightness(92%) contrast(85%);
}

#rimib-tab-tabmortgage {
	background: linear-gradient(180deg, #D1D9E9 0%, #FFF 100%);
	padding: 0;
	padding-top: 62px;
	width: 20%;
	height: 109px;
	text-align: center;
	color: #2F596F;
	font-family: "Fjalla One";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	position: relative;
	border-radius: 0;
	padding: 0;
	padding-top: 62px;
}

#rimib-tab-tabmortgage.active {
	background: url(../../../assets/homeimages/MotgageActivestate.svg);
	background-position: bottom;
height:120px;
    color: #fff;
    background-size: cover;
}

#rimib-tab-tabinsurance {
	background: linear-gradient(180deg, #FFCE9D 0%, #FFF 100%);
	width: 20%;
	height: 109px;
	text-align: center;
	color: #FF4C00;
	font-family: "Fjalla One";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	position: relative;
	border-radius: 0;
	padding: 0;
	padding-top: 62px;
}

#rimib-tab-tabinsurance.active {
	background: url(../../../assets/homeimages/InsuranceActivestate.svg);
	background-position: bottom;
height:120px;
    color: #fff;
    background-size: cover;
	
}

#rimib-tab-tabinsurance:before {
	text-align: center;
	fill: #FF4C00;
	filter: drop-shadow(0.643px 1.716px 3.646px rgba(0, 0, 0, 0.25));
	font-family: "Red Hat Display";
	font-size: 59.907px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	position: absolute;
	content: "I";
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
}

#rimib-tab-tabinsurance::after {

	position: absolute;
	content: '';
	background: url('../../../assets/homeimages/Insuranceblack.svg');
	bottom: 13px;
	left: 0;
	right: 0;
	margin: auto;
	background-size: contain;
	background-repeat: no-repeat;
	width: 15px;
	height: 15px;
	filter: invert(46%) sepia(50%) saturate(6009%) hue-rotate(358deg) brightness(98%) contrast(111%);
}

#rimib-tab-tabmortgage:before {
	text-align: center;
	fill: #2F596F;
	filter: drop-shadow(0.643px 1.716px 3.646px rgba(0, 0, 0, 0.25));
	font-family: "Red Hat Display";
	font-size: 59.907px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	position: absolute;
	content: "M";
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
}

#rimib-tab-tabmortgage::after {

	position: absolute;
	content: '';
	background: url('../../../assets/homeimages/Mortgageblack.svg');
	bottom: 13px;
	left: 0;
	right: 0;
	margin: auto;
	background-size: contain;
	background-repeat: no-repeat;
	width: 15px;
	height: 14px;
	filter: invert(33%) sepia(15%) saturate(1473%) hue-rotate(156deg) brightness(88%) contrast(91%);
}


#rimib-tab-tabbanking {
	background: linear-gradient(180deg, #C3E3FF 0%, #FFF 100%);
	width: 20%;
	height: 109px;
	text-align: center;
	color: #2280B8;
	font-family: "Fjalla One";
	border-radius: 0;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	position: relative;
	padding: 0;
	padding-top: 62px;
}

#rimib-tab-tabbanking.active {
	background: url(../../../assets/homeimages/BankingActiveState.svg);
	background-position: bottom;
height:120px;
height:120px;
    color: #fff;
    background-size: cover;
}

#rimib-tab-tabbanking:before {
	text-align: center;
	fill: #2280B8;
	filter: drop-shadow(0.643px 1.716px 3.646px rgba(0, 0, 0, 0.25));
	font-family: "Red Hat Display";
	font-size: 59.907px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	position: absolute;
	content: "B";
	top: 0;
	left: 0;
	right: 0;
	margin: auto;
}

#rimib-tab-tabbanking::after {

	position: absolute;
	content: '';
	background: url('../../../assets/homeimages/Bankingblack.svg');
	bottom: 13px;
	left: 0;
	right: 0;
	margin: auto;
	background-size: contain;
	background-repeat: no-repeat;
	width: 10px;
	height: 14px;
	filter: invert(40%) sepia(100%) saturate(450%) hue-rotate(158deg) brightness(90%) contrast(85%);
}

nav.rimibblock.nav.nav-tabs {
	margin-bottom: 20px;
	border: none;
}

.newblock {
	background: url(../../../assets/homeimages/HomepageBGmobile.svg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	color: #000;
	text-align: center;
	font-family: "SF Pro Display";
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 20px 50px;
}

.bannerimagmobile img {
	width: 100%;
}
nav.rimibblock.nav.nav-tabs a, nav.rimibblock.nav.nav-tabs a.active {
    border: none;
}