.expertsearchmain .institutesearchmainherosection{text-align:center;}
.expertsearchmain  .hero_subtext2 h3:after{left:0; right: auto;}
.expertsearchmain .herotext{left:0; right:auto; text-align: left;}
.expertsearchmain .herotext h3:nth-child(3):after {left:0; right:auto}
.expertsearchmain .instilogo{border-radius:200px; top:-104px;}
.expertsearchmain .instilogo .instilogoimage img{border-radius:200px;    height: 141px;
    object-fit: cover;}
.expertsearchmain .plainde {
    right: -217px;
    margin: auto;
    line-height: 1.8;
    filter: drop-shadow(0px 0px 3.563px #EF000B);
    bottom: 18px;
}
.expertsearchmain .institutesearchmainlistingsectionps{    margin: 121px 0;}
 .expertsearchmain .Real.Estate .instilogo .instilogoimage img{border: 2px solid #00801D;}
 .expertsearchmain .Banking .instilogo .instilogoimage img{border: 2px solid #2280B8;}
 .expertsearchmain .Mortgage .instilogo .instilogoimage img{border: 2px solid #2F596F;}
 .expertsearchmain .Insurance .instilogo .instilogoimage img{    border: 2px solid #FF7509;}
 .expertsearchmain .Investment  .instilogo .instilogoimage img{border: 2px solid #86357A;}
 .expertsearchmain .instilogo{
     height: 140px; width: 140px;
 }

 section.expertsearchmain {
    max-width: 1366px;
    margin: 0 auto;
}
.hero_subtext strong {
    
    font-size: 45px;
    font-weight: 900;
    line-height: 54.86px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #005C70;
    margin: 0 0 0 -72px;
}
.hero_subtext2 strong {
    
    font-size: 30px;
    font-weight: 700;
    line-height: 36.57px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}
.hero_subtext2 h2 {
    color: #000000;
    margin-top: 10px;
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.herotext h3 {
    color: #005C70;
font-size: 30px;
font-style: normal;
font-weight: 800;
line-height: 1;
}
.hero_subtext3 h5 {
    
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}
.hero_subtext3 strong {
    color: #000000;
    
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}
.Banking .imagefilter{filter: invert(39%) sepia(78%) saturate(554%) hue-rotate(159deg) brightness(95%) contrast(87%);}
.Real.Estate .imagefilter{filter: invert(26%) sepia(97%) saturate(1287%) hue-rotate(112deg) brightness(97%) contrast(101%);}
.Investment .imagefilter{filter: invert(25%) sepia(18%) saturate(3678%) hue-rotate(269deg) brightness(97%) contrast(87%);}
.Insurance .imagefilter{filter: invert(36%) sepia(92%) saturate(3306%) hue-rotate(1deg) brightness(103%) contrast(105%);}
.Mortgage .imagefilter{filter: invert(31%) sepia(57%) saturate(451%) hue-rotate(156deg) brightness(83%) contrast(86%);}
.institutesearchmainlistingsection .Elite.Banking{
background: #EDF9FF;
box-shadow: 0px 0px 8px 0px rgba(34, 128, 184, 0.35);
border: 3px solid #2280b8;}
.institutesearchmainlistingsection .Elite.Mortgage{
background: #EEF1F8;
box-shadow: 0px 0px 8px 0px rgba(47, 89, 111, 0.35);
border: 3px solid #2F596F;
}
.institutesearchmainlistingsection .Elite.Insurance{
background: #FFF2EA;
box-shadow: 0px 0px 8px 0px rgba(255, 76, 0, 0.35);
border: 3px solid #FF4C00;
}
.institutesearchmainlistingsection .Elite.Investment{
background: #FFEFFF;
box-shadow: 0px 0px 8px 0px rgba(134, 53, 122, 0.35);
border: 3px solid #86357A;
}
.institutesearchmainlistingsection .Elite.Real.Estate{   
background: #EAFFEA;
box-shadow: 0px 0px 8px 0px rgba(0, 128, 62, 0.35);
border: 3px solid #00831D;
}

.Elite.Banking a.bdeatailglobal{background: var(--Bluetwo); color: #fff;box-shadow:none; border:1px solid var(--Bluetwo);}
.Elite.Mortgage a.bdeatailglobal{background: var(--Blueone); color: #fff;box-shadow:none;  border:1px solid var(--Blueone)}
.Elite.Insurance a.bdeatailglobal{background: var(--orange); color: #fff;box-shadow:none;  border:1px solid var(--orange)}
.Elite.Investment a.bdeatailglobal{background:var(--purple); color: #fff;box-shadow:none;  border:1px solid var(--purple)}
.Elite.Real.Estate a.bdeatailglobal{background: var(--green); color: #fff;box-shadow:none;  border:1px solid var(--green)}


.Elite.Banking a.bdeatailglobal:hover{background: var(--lightBluetwo); color: var(--Bluetwo);box-shadow:none; border:1px solid var(--Bluetwo);}
.Elite.Mortgage a.bdeatailglobal:hover{background: var(--lightBlueone); color: var(--Blueone);box-shadow:none;  border:1px solid var(--Blueone)}
.Elite.Insurance a.bdeatailglobal:hover{background: var(--lightorange); color: var(--orange);box-shadow:none;  border:1px solid var(--orange)}
.Elite.Investment a.bdeatailglobal:hover{background:var(--lightpurple); color: var(--purple);box-shadow:none;  border:1px solid var(--purple)}
.Elite.Real.Estate a.bdeatailglobal:hover{background: var(--lightgreen); color: var(--green);box-shadow:none;  border:1px solid var(--green)}



.Elite.Banking a.bdeatailglobal:hover span img{    filter: invert(39%) sepia(78%) saturate(554%) hue-rotate(159deg) brightness(95%) contrast(87%);}
.Elite.Mortgage a.bdeatailglobal:hover span img{    filter: invert(31%) sepia(57%) saturate(451%) hue-rotate(156deg) brightness(83%) contrast(86%);}
.Elite.Insurance a.bdeatailglobal:hover span img{    filter: invert(36%) sepia(92%) saturate(3306%) hue-rotate(1deg) brightness(103%) contrast(105%);}
.Elite.Investment a.bdeatailglobal:hover span img{    filter: invert(25%) sepia(18%) saturate(3678%) hue-rotate(269deg) brightness(97%) contrast(87%);}
.Elite.Real.Estate a.bdeatailglobal:hover span img{filter: invert(26%) sepia(97%) saturate(1287%) hue-rotate(112deg) brightness(97%) contrast(101%);}



.Banking a.bdeatailglobal{ border:1px solid var(--Bluetwo);}
.Mortgage a.bdeatailglobal{ border:1px solid var(--Blueone);}
.Insurance a.bdeatailglobal{ border:1px solid var(--orange);}
.Investment a.bdeatailglobal{ border:1px solid var(--purple);}
.Real.Estate a.bdeatailglobal{ border:1px solid var(--green);}

.Elite.Banking a.bdeatailglobal span img , .Elite.Mortgage a.bdeatailglobal span img, .Elite.Investment a.bdeatailglobal span img, .Elite.Insurance a.bdeatailglobal span img , .Elite.Real.Estate a.bdeatailglobal span img   
{filter: invert(100%) sepia(34%) saturate(2%) hue-rotate(147deg) brightness(111%) contrast(101%);}
.institutesearchmainlistingsection .Real.Estate{ 
    box-shadow: 0px 2px 10px 0px rgba(0, 159, 36, 0.60);}
.institutesearchmainlistingsection .Banking{  
    box-shadow: 0px 2px 10px 0px #2280B8;}
.institutesearchmainlistingsection .Mortgage{
    box-shadow: 0px 2px 10px 0px rgba(47, 89, 111, 0.60);}
.institutesearchmainlistingsection .Investment{
box-shadow: 0px 2px 10px 0px #86357A;
}
.institutesearchmainlistingsection .Insurance{
box-shadow: 0px 2px 10px 0px rgba(255, 76, 0, 0.60);
}
.institutesearchmainlistingsection .Insurance.statusverified, .institutesearchmainlistingsection .Mortgage.statusverified, .institutesearchmainlistingsection .Banking.statusverified, .institutesearchmainlistingsection .Investment.statusverified, .institutesearchmainlistingsection .Real.Estate.statusverified,
.institutesearchmainlistingsection .Insurance.statusunverified, .institutesearchmainlistingsection .Mortgage.statusunverified, .institutesearchmainlistingsection .Banking.statusunverified, .institutesearchmainlistingsection .Investment.statusunverified, .institutesearchmainlistingsection .Real.Estate.statusunverified
{

box-shadow: 0px 2px 13.1px 0px #676767;
margin: 40px 0;
background: transparent;
}
.Insurance.statusverified .imagefilter, .Investment.statusverified .imagefilter, .Real.Estate.statusverified .imagefilter, .Mortgage.statusverified .imagefilter, .Banking.statusverified .imagefilter,
.Insurance.statusunverified .imagefilter, .Investment.statusunverified .imagefilter, .Real.Estate.statusunverified .imagefilter, .Mortgage.statusunverified .imagefilter, .Banking.statusunverified .imagefilter
{
    filter: invert(39%) sepia(0%) saturate(4268%) hue-rotate(194deg) brightness(93%) contrast(71%);
}
.institutesearchmainlistingsection .statusverified ul li.Insurance,
.institutesearchmainlistingsection .statusverified ul li.Investment,
.institutesearchmainlistingsection .statusverified ul li.Banking,
.institutesearchmainlistingsection .statusverified ul li.Mortgage,
.institutesearchmainlistingsection .statusverified ul li.Real.Estate,
.institutesearchmainlistingsection .statusunverified ul li.Insurance,
.institutesearchmainlistingsection .statusunverified ul li.Investment,
.institutesearchmainlistingsection .statusunverified ul li.Banking,
.institutesearchmainlistingsection .statusunverified ul li.Mortgage,
.institutesearchmainlistingsection .statusunverified ul li.Real.Estate
{
    color: #676767 !important;
}
.statusverified .statuslogo, .statusunverified .statuslogo{
    color: #000;

font-family: "Roboto Flex";
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 2.3;
    background: #fff;
    box-shadow: 3px 3px 20px 0 rgba(0, 0, 0, .25);
    width: 100px;
    height: 30px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -39px;
    overflow: hidden;
    text-align: center;
    border-radius: 188px;
}
.unverifiedinfo{color: #EF000B;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
margin-top: 20px;
display: inline-block;}

.Real.Estate .expertoutershadow{ 
    box-shadow: 0px 2px 10px 0px var(--green);border: 1px solid var(--green);}
.Banking .expertoutershadow{  
    box-shadow: 0px 2px 10px 0px  var(--Bluetwo);border: 1px solid var(--Bluetwo);}
.Mortgage .expertoutershadow{
    box-shadow: 0px 2px 10px 0px var(--Blueone);
    border: 1px solid var(--Blueone);}
.Investment .expertoutershadow{
box-shadow: 0px 2px 10px 0px var(--purple);
border: 1px solid var(--purple);
}
.Insurance .expertoutershadow{
box-shadow: 0px 2px 10px 0px rgba(255, 76, 0, 0.60);
border: 1px solid var(--orange);
}
.Real.Estate .imageborder img{border: 2px solid var(--green);}
.Mortgage .imageborder img{border: 2px solid var(--Blueone);}
.Banking .imageborder img{border: 2px solid var(--Bluetwo);}
.Insurance .imageborder img{border: 2px solid var(--orange);}
.Investment .imageborder img{border: 2px solid var(--purple);}
.expertoutershadow{position: relative;}
.expertoutershadow.Premium:before{
content: "";
position: absolute;
background: url(../../../../assets/expertppvimage/diamond.svg);
width: 18px;
height: 18px;
background-size: contain;
background-repeat: no-repeat;
left: 5px;
top: 5px;
}
.expertoutershadow.Elite:before{
    content: "";
    position: absolute;
    background: url(../../../../assets/expertppvimage/elite_star.svg);
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    left:9px;
    top: 2px;
    }
.expertoutershadow.Premium{padding-left: 13px;}
.expertoutershadow.Elite{padding-left: 9px;}
.institutesearchmainlistingsection ul li.Banking{
    box-shadow: none;  
    color: var(--Bluetwo);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;}
.institutesearchmainlistingsection ul li.Mortgage{
    box-shadow: none;  
    color: var(--Blueone);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;}
.institutesearchmainlistingsection ul li.Insurance{
    box-shadow: none;  
    color: var(--orange);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;}
.institutesearchmainlistingsection ul li.Investment{
    box-shadow: none; 
    color: var(--purple);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;}
.institutesearchmainlistingsection ul li.Real.Estate{
    box-shadow: none; 
    color: var(--green);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;}
    .statusverified .statuslogo .imageborder,  .statusunverified .statuslogo .imageborder {
        position: relative;
        padding-left: 18px;
    }
    .statusverified .statuslogo .imageborder:before, .statusunverified .statuslogo .imageborder:before{
        content: "";
        left: 6px;
        top: 5px;
        background: url(../../../../assets/search/unverified.svg);
        position: absolute;
        width: 20px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .statusverified .statuslogo .imageborder:before{    width: 20px;
        height: 20px;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background: url(../../../../assets/search/verifiedtag.svg); top:6px; left:9px}
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    
        .hero_subtext2 strong , .hero_subtext2 h2{
            font-size: 20px;
            font-weight: 700;
            line-height: 25px;
        }
        .hero_subtext strong{margin: 0;}
        .expertsearchmain .hero_subtext2 h3:after {
            left: 0;
            right: auto;
            width: 50%;
        }
        .herotext h3 {
            color: #005C70;
            font-size: 21px;}
    }
@media(max-width:767px)
{
    .expertsearchmain .bdeatailglobal:nth-child(1) span img{width: 19px;}
    .expertsearchmain .statusactive, .expertsearchmain .statusunverified, .expertsearchmain .statusverified, .expertsearchmain .statusverified, .expertsearchmain .statuspartially-registered-s
   
        {
            margin-bottom: 20px !important;
        }
    
    div#searchexpert {
        padding: 0;
    }
    .statusverified .statuslogo .imageborder, .statusunverified .statuslogo .imageborder{
   
        border: 1px solid #676767;
        background: #FFF;
        box-shadow:none;
width: 100%;
height: 30px;
    }
    .statusverified .statuslogo, .statusunverified .statuslogo{line-height: 2;}
    .statusverified .statuslogo, .statusunverified .statuslogo{        border: 1px solid #676767;
        box-shadow: 0px 0px 5px 0px #676767 inset;}
    span.unverifiedinfo img {
        position: absolute;
        left: 0;
        top: 1px;
    }
    .unverifiedinfo {
        color: #EF000B;
        font-family: Montserrat;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0;
        display: inline-block;
        position: relative;
        padding-left: 16px;
    }
    .institutesearchmainlistingsection .Premium.Insurance a.bdeatailglobal{color: #FF4C00;}
    .institutesearchmainlistingsection .Premium.Real.Estate a.bdeatailglobal{color: #00831d;}
    .institutesearchmainlistingsection  .Premium.Mortgage a.bdeatailglobal{color: #2F596F;}
    .institutesearchmainlistingsection .Premium.Investment a.bdeatailglobal{color: #86357A;}
    .institutesearchmainlistingsection .Premium.Banking a.bdeatailglobal{color: #2280B8;}

    .Premium.statusactive .instisegricatetwops {
        background: #EFF2F8 !important;
    }
    .statusverified .statuslogo, .statusunverified .statuslogo{top: -24px;}
    .statusverified .statuslogo .imageborder, .statusunverified .statuslogo .imageborder {
        position: relative;
        padding-left: 26px;
    }
    .statusunverified .statuslogo .imageborder{padding-left: 18px;}
    .statusverified .statuslogo .imageborder{padding-left: 18px;}
    .statusverified .instisegricatetwops, .statusunverified .instisegricatetwops{
        background: #EFF2F8 !important;
    }
    .institutesearchmainlistingsection .Premium.Insurance a.bdeatailglobal img{filter: invert(35%) sepia(22%) saturate(6616%) hue-rotate(359deg) brightness(103%) contrast(109%);}
    .institutesearchmainlistingsection .Premium.Real.Estate a.bdeatailglobal img{filter: invert(17%) sepia(99%) saturate(4847%) hue-rotate(145deg) brightness(97%) contrast(101%);}
    .institutesearchmainlistingsection  .Premium.Mortgage a.bdeatailglobal img{filter: invert(32%) sepia(27%) saturate(804%) hue-rotate(156deg) brightness(89%) contrast(91%);}
    .institutesearchmainlistingsection .Premium.Investment a.bdeatailglobal img{filter: invert(28%) sepia(48%) saturate(993%) hue-rotate(261deg) brightness(89%) contrast(92%);}
    .institutesearchmainlistingsection .Premium.Banking a.bdeatailglobal img{filter: invert(40%) sepia(63%) saturate(618%) hue-rotate(158deg) brightness(95%) contrast(89%);}
    .institutesearchmainlistingsection ul li{font-family: "Fjalla One";
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: normal;}
       .institutesearchmain .statusactive, .institutesearchmain .statusunverified, .institutesearchmain .statusverified{margin-bottom: 20px !important;}
       .expertsearchmain .statusactive, .expertsearchmain .statusunverified, .expertsearchmain .statusverified, .expertsearchmain .statusverified, .expertsearchmain .statuspartially-registered-s{margin-bottom: 20px !important;}
       
    section.institutesearchmainherosection .container
    {
        position: initial !important;
    }
    section.institutesearchmainherosection{position: relative;}
    .expertsearchmain .instilogo {
        height: 100px;
        width: 100px;
    }
    .expertsearchmain .instilogo .instilogoimage img{height: auto;}
    .expertsearchmain .instilogo{top:-78px;}
    .expertsearchmain .plainde {
        right: -187px;
        margin: auto;
        line-height: 1.8;
        filter: drop-shadow(0px 0px 3.563px #EF000B);
        bottom: 4px;
    }

        .expertsearchmain .herotext {
            left: 15px;
            right: auto;
            text-align: left;
            bottom: auto; line-height: 1; top:50px;
            width: 90%;
        }
        .herotext h3 {
            color: #005C70;
            font-size: 18px;
            font-style: normal;
            font-weight: 800;
            line-height: 1;
            display: none;
        }
        .hero_subtext2 h2 , .hero_subtext2 strong{
            color: #000;
         text-align: left;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .hero_subtext h2, .hero_subtext strong{

            font-size: 30px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 30px; 
        display: inline-block;}
        .hero_subtext3 strong {
            color: #000000;
            font-size: 12px;
            font-weight: 600;
            line-height: 1.3;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
        }
        .expertsearchmain .hero_subtext h2, .expertsearchmain .hero_subtext h2 strong{
            display: block;
        }
        .instisearchheading {
            margin-top: 20px !important;
        }
        .hero_subtext3 h5{ font-size: 12px; line-height: 1.5;}
        .hero_subtext strong{margin: 0;}
        .instisearchheading h3{font-family: "SF Pro Display";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;}




        .expertsearchmain .mobiledrop .filtersectionglobal {
            margin-top: 0;
        }
        button#dropdownFilter, button#dropdownShortBy {
            color: #000;
            font-family: "Fjalla One";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: none;
            border: none;
        }
        .d-flex.mobiledrop {
            justify-content: center;
            border-top: 1px solid #ACACAC;
            border-bottom: 1px solid #acacac;
            margin-top: 20px;
        }
        .expertsearchmain .mobiledrop .filtersectionglobal {
            margin-top: 0;
            text-align: left;
        }
        .expertsearchmain .mobiledrop .selectinner .mainchecklabel{font-size: 14px;}
        .expertsearchmain .mobiledrop .selectinner  span.maincheckboxicon img {
            width: 23px;
            height: 23px;
            object-fit: contain;
        }
        .expertsearchmain .mobiledrop  .selectallinnercheckbox li.selectinner .form-check-input,.expertsearchmain .mobiledrop input#Checkall {
            width: 18px;
            height: 18px;
            margin-left: 3px;
            z-index: 9;
        }
        .expertsearchmain .mobiledrop .selectallinnercheckbox li.selectinner .dropdown-item .form-check-input
        {
            margin-left: -25px;
        }
        .expertsearchmain .mobiledrop .selectallcheckbox label.form-check-label.mainchecklabel{font-size: 14px;}
        .expertsearchmain .mobiledrop .form-checkselect{padding-left: 5px; padding:5px}
        .expertsearchmain .mobiledrop  .selectinner .dropshortby a.dropdown-item{padding: 0;}
        .expertsearchmain .mobiledrop  .selectinner .dropshortby  a.dropdown-item .form-check-input
        {
            margin-left: -20px;
        }
        .expertsearchmain .mobiledrop .selectallinnercheckbox ul.dropdown-menu label.form-check-label{
            color: #000;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 11px;
            padding-left: 4px;
            width: 126px;
        }
        .show>.btn-secondary.dropdown-toggle:focus{
            box-shadow: none !important;
        }
        .dropshortby{position: relative;}
        .mobiledrop > .dropdown:before, .dropshortby:before {
            content: "";
        position: absolute;
        right: -12px;
        width: 1px;
        height: 24px;
        background: #ccc;
        top: 4px;
        }
        .mobiledrop  .dropdown-menu.show {
            display: block;
        width: 282px;
        padding: 0;
        left: auto !important;
        right: -145px;
        }
        .mobiledrop .dropfilter  span.subcatimage {
            display: inline-block;
            margin-left: 10px;
        }
        .mobiledrop .dropfilter  span.subcatimage img{
            filter: invert(0);
        }
        .mobiledrop .dropfilter .form-check .form-check-input{
            margin-left: -10px;margin-top: 0;
        }
        .mobiledrop .dropfilter  input[type="checkbox"]:enabled:checked{
            background-color: #00831D;
            color: white;
        }
        .mobiledrop > .dropdown:last-child:before, .mobiledrop > .dropshortby:last-child:before{display: none;}
        .mobiledrop > .dropdown, .mobiledrop > .dropshortby {
            margin: 0 15px;
            position: relative;
            width: 50%;
            text-align: center;
        }
        .expertsearchmain .mobiledrop .dropdown-menu ul{padding-left: 0; 
            background: #fff; 
            margin-bottom: 0;
            fill: #FFF;
            filter: drop-shadow(2px 2px 18px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
        position: relative;
        z-index: 9;
        padding: 10px ;
    }
    .expertsearchmain .mobiledrop .dropdown-menu ul li:last-child{border: none; padding-bottom: 0; margin-bottom: 0;}
    .expertsearchmain .mobiledrop .dropdown-menu ul li{position: relative; padding-bottom: 5px; margin-bottom: 5px; border-bottom: 1px solid #ccc;}
        .expertsearchmain .mobiledrop  li.selectinner .dropdown-toggle:after {
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 9px solid #fff;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            position: absolute;
            right: 8px;
            top: 9px;
            bottom: 0;
            margin: auto;
            display: block;
        }
        .form-checkselect{width:251px}
        .expertsearchmain .mobiledrop .selectallinnercheckbox>ul>li{width: 250px; padding: 5px;}
        .selectallcheckbox  span.maincheckboxicon img{margin-left: 12px;}
        .filtersectionglobal {
            position: absolute;
            z-index: 999;
            left: -158px;
            right: auto;
            top:43px;
            width: 282px;
            margin: auto;
            background: rgba(255, 255, 255, 0.6);
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 2px 2px 18px 0px rgba(0, 0, 0, 0.25);
        
        }
        .viewbutton{width: 90px;
            height: 25px;
            font-family: "Roboto Flex";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        padding:5px;}
        button#dropdownFilter:after, button#dropdownShortBy:after {
       
            content: "";
            border-top: 7px solid #000000;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            position: absolute;
            right: 20px;
            top: 13px;
            bottom: 0;
            margin: auto;
            display: block;
        } 
        .expertsearchmain .mobiledrop li.selectinner ul.dropdown-menu.show{width: auto !important; opacity: 1;}

        .filter-wrap {
            display: none;
        }
        
        .open-active.filter-wrap {
            display: block;
        }
        .filtersectionglobal:after{
            position: absolute;
        top: -9px;
        bottom: auto;
        left: 85%;
        margin-left: -10px;
        content: "";
        display: block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
        border-top: none;
        }
        .expertsearchmain .hero_subtext3{max-width: 300px;}
        .expertsearchmain .hero_subtext2 h2{font-size: 0;}
        .expertsearchmain .hero_subtext2 h2 strong{font-size:14px; display:block !important;max-width: 300px;}
        .instisearchheading {
            margin-top: 20px;
        }


        .institutesearchmainlistingsection .Elite.Banking{
            border-radius: 20px;
background: #EDF9FF;
box-shadow: 0px 0px 8px 0px rgba(34, 128, 184, 0.35);
border: none;
        }
.institutesearchmainlistingsection .Elite.Mortgage{
    border-radius: 20px;
background: #EEF1F8;
box-shadow: 0px 0px 8px 0px rgba(47, 89, 111, 0.35);
border: none;
}
.institutesearchmainlistingsection .Elite.Insurance{
    border-radius: 20px;
background: #FFF2EA;
box-shadow: 0px 0px 8px 0px rgba(255, 76, 0, 0.35);
border: none;
}
.institutesearchmainlistingsection .Elite.Investment{
    border-radius: 20px;
background: #FFEFFF;
box-shadow: 0px 0px 8px 0px rgba(134, 53, 122, 0.35);
border: none;
}
.institutesearchmainlistingsection .Elite.Real.Estate{
    border-radius: 20px;
background: #EAFFEA;
box-shadow: 0px 0px 8px 0px rgba(0, 128, 62, 0.35);
border: none;
}
.Insurance .expertoutershadow, .Investment .expertoutershadow, .Banking .expertoutershadow, .Real.Estate .expertoutershadow, .Mortgage .expertoutershadow{box-shadow: none;}
.Elite.Insurance a.bdeatailglobal{
    color: var(--orange);
    background: #fff;
    box-shadow: none;
    border: 1px solid var(--orange);
}
.Elite.Banking a.bdeatailglobal{
    color: var(--Bluetwo);
    background: #fff;
    box-shadow: none;
    border: 1px solid var(--Bluetwo);
}
.Elite.Mortgage a.bdeatailglobal{
    color: var(--Blueone);
    background: #fff;
    box-shadow: none;
    border: 1px solid var(--Blueone);
}
.Elite.Investment a.bdeatailglobal{
    color: var(--purple);
    background: #fff;
    box-shadow: none;
    border: 1px solid var(--purple);
}
.Elite.Real.Estate a.bdeatailglobal{
    color: var(--green);
    background: #fff;
    box-shadow: none;
    border: 1px solid var(--green);
}
.Elite.Insurance a.bdeatailglobal img{filter: invert(39%) sepia(78%) saturate(554%) hue-rotate(159deg) brightness(95%) contrast(87%) !important;}
.Elite.Real.Estate a.bdeatailglobal img{filter: invert(26%) sepia(97%) saturate(1287%) hue-rotate(112deg) brightness(97%) contrast(101%) !important;}
.Elite.Investment a.bdeatailglobal img{filter: invert(25%) sepia(18%) saturate(3678%) hue-rotate(269deg) brightness(97%) contrast(87%) !important;}
.Elite.Insurance a.bdeatailglobal img{filter: invert(36%) sepia(92%) saturate(3306%) hue-rotate(1deg) brightness(103%) contrast(105%) !important;}
.Elite.Mortgage a.bdeatailglobal img{filter: invert(31%) sepia(57%) saturate(451%) hue-rotate(156deg) brightness(83%) contrast(86%) !important;}
.institutesearchmainlistingsection .Insurance.statusverified, .institutesearchmainlistingsection .Mortgage.statusverified, .institutesearchmainlistingsection .Banking.statusverified, .institutesearchmainlistingsection .Investment.statusverified, .institutesearchmainlistingsection .Real.Estate.statusverified, .institutesearchmainlistingsection .Insurance.statusunverified, .institutesearchmainlistingsection .Mortgage.statusunverified, .institutesearchmainlistingsection .Banking.statusunverified, .institutesearchmainlistingsection .Investment.statusunverified, .institutesearchmainlistingsection .Real.Estate.statusunverified
{border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.35);}
    .statusunverified .statuslogo .imageborder:before
    {
        top: 3px;
    }
    .statusverified .statuslogo .imageborder:before
    {
        top:4px;
    }
    .expertsearchmain .Premium .bdeatailglobal:nth-child(1) span img{width: 15px;}


    .institutesearchmainlistingsection .Premium.Insurance {
      
        box-shadow: 0px 0px 8px 0px rgba(255, 76, 0, 0.35);
    }
    .institutesearchmainlistingsection .Premium.Banking {
        box-shadow:  0px 0px 8px 0px rgba(34, 128, 184, 0.35)
    }
    .institutesearchmainlistingsection .Premium.Mortgage {
      box-shadow: 0px 0px 8px 0px rgba(47, 89, 111, 0.35);
    }
    .institutesearchmainlistingsection .Premium.Real.Estate {
        box-shadow: 0px 0px 8px 0px rgba(0, 128, 62, 0.35);
    }
    .institutesearchmainlistingsection .Premium.Investment {
      
        box-shadow: 0px 0px 8px 0px rgba(134, 53, 122, 0.35);
    }
}