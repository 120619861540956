.mortgagenewui .categorybanner{ background: url(../../../assets/mortgageimges/Mortgage.svg);}
.mortgagenewui .bannerfont h2, .mortgagenewui .applynowbuttoncomm {
    color: var(--Blueone);}
.mortgagenewui .applynowbuttoncomm{border-color: var(--Blueone); background: #E9EEF9;}
.mortgagenewui .categorysectionone .offer-inner .categotyinpopfl .pscat:after{ background: var(--Blueone);}
.mortgagenewui .categorysectiontwo, .mortgagenewui .categorysectionthree{
    background: url(../../../assets/bankingimges/Productsbg.png);
    
}
.mortgagenewui .categorybanner .bn-image img {
    height: 431px;
}
.mortgagenewui .viewdetailsbuttoncomm{background: var(--Blueone)}
.mortgagenewui  .bannerfont h2:after{background: var(--Blueone)}
.mortgagenewui .Expertsbutton{background: #E9EEF9;}
.mortgagenewui .viewdetailsbuttoncomm:hover {
    color: var(--Blueone);
    background-color: #E9EEF9;
    cursor: pointer;
    border-color: var(--Blueone);
}
.mortgagenewui  .bannerfont.borderbottom span {
    color: var(--Blueone);}
/* .mortgagenewui .categorysectionthree span.canada{color: var(--Blueone);} */
.mortgagenewui .categorysectionfour .Expertstop img{border-color: #E9EEF9;}
.mortgagenewui .messagemebuttoncomm{background: var(--Blueone);}
.mortgagenewui .categorysectionsix.blogdetailsexpertppv{background: url(../../../assets/bankingimges/blogsbg.png);}
.mortgagenewui .categorysectionone .slick-prev:before, .mortgagenewui .categorysectionone .slick-next:before, .mortgagenewui .categorysectiontwo .slick-prev:before, .mortgagenewui .categorysectiontwo .slick-next:before, .mortgagenewui .categorysectionthree .slick-prev:before, .mortgagenewui .categorysectionthree .slick-next:before, .mortgagenewui .categorysectionfour .slick-prev:before, .mortgagenewui .categorysectionfour .slick-next:before
{color: var(--Blueone);}
.mortgage-bgimg{
    background: url(../../../assets/bankingimges/Productsbg.png);
    background-position: top;
}
.mortgagenewui .mortgage_hero{
    align-items: center;
}