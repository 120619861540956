.institutesearchmainlistingsectionps {
    border-radius: 20px;
    background: #fff;
     position: relative;
    padding: 20px;
    /* margin: 100px 0; */
    margin-top: 100px;
}
.instilogoouter
{position: relative;}
.instilogo {
    border-radius: 20px;
    background: #fff;
    box-shadow: 3px 3px 20px 0 rgba(0, 0, 0, .25);
    width: 155px;
    height: 155px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -104px;
    overflow: hidden;
    text-align: center;
}
.instilogoimage img {
    height: 160px;
    width: 160px;
    object-fit: cover;
}
.instisegricateone h4 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    margin-bottom: 30px;
    text-transform: capitalize;
}
.instisegricateone h4:before {
    content: "";
    position: absolute;
    background: #cacaca;
    left: 0;
    bottom: -22px;
    width: 200px;
    height: 3px;
}

a.bdetail {
    border-radius: 8px; 
    width: 160px;
    height: 36px;
    display: inline-block;
    color: #000;
    font-family: "Roboto Flex", sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.9;
    text-align: center;
    margin-right: 30px;
    margin-top: 22px;
}
.Banking .instisegricateone .condetails ul span img{filter: invert(39%) sepia(78%) saturate(554%) hue-rotate(159deg) brightness(95%) contrast(87%);}
.Real.Estate .instisegricateone .condetails ul span img{filter: invert(26%) sepia(97%) saturate(1287%) hue-rotate(112deg) brightness(97%) contrast(101%);}
.Investment .instisegricateone .condetails ul span img{filter: invert(25%) sepia(18%) saturate(3678%) hue-rotate(269deg) brightness(97%) contrast(87%);}
.Insurance .instisegricateone .condetails ul span img{filter: invert(36%) sepia(92%) saturate(3306%) hue-rotate(1deg) brightness(103%) contrast(105%);}
.Mortgage .instisegricateone .condetails ul span img{filter: invert(31%) sepia(57%) saturate(451%) hue-rotate(156deg) brightness(83%) contrast(86%);}
.Banking  a.bdetail{border-color: #2280B8;}
.Real.Estate a.bdetail{border-color: #00801D;}
.Investment a.bdetail{border-color: #86357A;}
.Mortgage a.bdetail{border-color: #2F596F;}
.Insurance a.bdetail{border-color: #FF4C00;}

.instisegricateouter {
    justify-content: space-between;
    display: flex;
}
.instisegricateone .condetails ul {
    list-style: none;
    display: flex;
    padding: 12px 0 0;
    margin-bottom: 0;
}
.instisegricateone .condetails ul li {
    padding-right: 70px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.instisegricateone .condetails ul li span {
    padding-right: 10px;
}
.plainde {
    border-radius: 63.629px;
    background: #fff;
    color: #000;
    z-index: 99;
    font-size: 15.271px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 99.152px;
    height: 30.462px;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 1.8;
    bottom: -65px;
    font-family: "Roboto Flex", sans-serif !important;
}
.instisegricateone .condetails ul li a {
    color: #000;
}
.instisegricateone .condetails ul span img {
    width: 16px;
}
a.bdetail span img {
    width: 20px;
    padding-right: 5px;
}
.instisegricatetwo{display: block;}
.instisegricatetwo ul li{
    list-style: disc;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .instisegricateone .condetails ul li{
        padding-right: 11px;font-size: 13px;
    }
    .instisegricateone .condetails ul{flex-wrap: wrap;}
    .instisegricateone .condetails ul li span{
        padding-right: 5px;
    }
    .instisegricateone h4{        
        margin-top: 75px;
        font-size: 20px;
    margin-bottom: 0;}
    .instisegricateone h4::before{
        display: none;
    }
    a.bdetail{margin-top: 10px;}
}
@media(max-width:767px){
    .showallourbesttoo {
        background-color: white;
        border: 1px solid #EF000B;
        padding: 1px;
        border-radius: 5px;
        display: inline-block !important;
        width: 95px !important;
        height: 25px;
        color: #EF000B;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 1.8px;
        font-family: "Roboto Flex", sans-serif;
        margin-left: auto;
        margin-right: auto;
    }
   .institutesearchmainlistingsectionps{padding: 10px 0; margin: 70px 0; }
    .instisegricateone h4{font-size: 16px;padding-left: 10px; padding-right: 10px;       margin-bottom: 0px; margin-top: 30px; text-transform: capitalize;}
    .instilogoimage, .instilogo, .instilogoimage img{height: 70px; width: 70px;}
    .instilogo{    top: -52px;}
    .instisegricateouter, .instisegricateone .condetails ul{flex-wrap: wrap;}
    .instisegricateone .condetails ul{padding-left: 10px;}
    .instisegricateone .condetails ul li span{padding-right: 3px;}
    .instisegricateone .condetails ul li{padding-right: 20px;}
 
    .instisegricateone h4:before{
        bottom: -10px;
        display: none;
    }
    .instisegricatetwo ul{
        padding-left: 15px;
        margin-bottom: 0;
    }
    .plainde{ right: -172px;
        bottom: -4px;}
    .instisegricateouter{
        flex-direction: column-reverse;
    }
    .instisegricatetwo ul{        padding: 0;
       display: flex;}
        .instisegricatetwo ul li{list-style: none;font-weight: 600;
            font-family: "Fjalla One";
        font-size: 10px;
    padding: 5px 7px; position: relative;}
    .instisegricatetwo{margin-top: 10px; background: #FFF; box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);}
    .basicdetails{padding-left: 10px;}
    .instisegricatetwo ul li:after{
        content: "";
    position: absolute;
    right: 0px;
    width: 2px;
    height: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #ccc;
    }
    .instisegricatetwo ul li:last-child::after{display: none;}
    .instisegricateone .condetails ul li{width: 100%; padding-left: 0;font-size: 10px;
        font-style: normal;
        font-weight: 400;
    font-family: 'microsoft_sans_serifregular' !important; margin-bottom: 10px;}
    a.bdetail {
        width: 95px;
        height: 25px;
        font-size: 12px;
        line-height: 2;
        text-align: center;
        margin-right: 10px;
        margin-top: 5px;
        border-radius: 2px;
        margin-bottom: 5px;
        font-family: "Roboto Flex", sans-serif !important;
    }
    a.bdetail span img {
        width: 17px;
        padding-right: 5px;
    }
    .instisegricateone .condetails ul li a{font-size: 12px;}
    .instilogo{border-radius: 5px;}
}
