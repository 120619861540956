.craete-button {
	color: #006EA9;
	background: #FFF;

	font-size: 20px;
	font-weight: 700;
	text-align: center;
	border-radius: 6px;
	border: white;
}


.banner-description {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: #FFFFFF;
	padding: 17px 24px;
}

.create-product-banner {
	background-image: url("../../../assets/Instituteppvimages/productheading.svg");
	background-repeat: no-repeat;
	background-size: auto;
	height: 129px;
	border-radius: 20px;
}

button.craete-button {
	margin: 13px 20px;
	padding: 6px 12px;
}

.banner-gride {
	font-size: 24px;
	font-weight: 700;
	color: #FFF;
	padding-top: 8px;
}

.page-title {
	font-size: 32px;
	font-weight: 600;
}

.page-subtitle {
	font-size: 18px;
	font-weight: 300;
}

.prodcut-div {
	padding-bottom: 20px;
	padding-right: 30px;
}


.subcategory-heading {
	background: #FFFFFF;
	border-radius: 18px;
}


.single-blog {
	border: 1px solid #F8F8F8;
	box-shadow: 3px 3px 8px 0px #00000040;
	background: #F3F8FF;
	border-radius: 6px;
	border: 1px;
	padding: 0px 20px 0px 20px;
	margin: 46px 10px 10px;
}

.single-blog-img img {
	background: #C3C6C9;
	box-shadow: 10px 10px 16px 0px #00000040;
	border-radius: 6px;
	border: 0px 0px 2px 0px;
	margin-top: -46px;
	height: 160px;
}

.blog-name {
	font-size: 16px;
	font-weight: 500;
	padding-top: 20px;
}

.blog-description {
	font-size: 13px;
	font-weight: 400;
	padding-bottom: 20px;
}

.main_institute_dashboard {
	max-width: 1366px;
	margin: 0 auto;
	width: 100%;
}

section.entiredashboard.maincustomerdash.institute_dashboard>.container-fluid>.row>.col-lg-4.col-sm-4 {

	border-radius: 25px 25px 0 0;
	box-shadow: none;
	padding: 0;
	max-width: 400px;
	background-color: #D2FFD2;

}

section.entiredashboard.maincustomerdash.institute_dashboard .col-lg-4.col-sm-4:before {
	position: absolute;
	top: 0;
	border-radius: 25px 25px 0 0;
	background-color: #FFF;
	width: 73px;
	height: 100%;
	content: "";
	left: 35px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .dashboard-decoration {
	border-radius: 25px;
	background: #FFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

section.entiredashboard.maincustomerdash.institute_dashboard .userdetails {
	margin-left: 90px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .maincustomerdashboard .dashboardlogo {
	width: 124px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .maincustomerdashboard .dashboardlogo img {
	width: 100px;
	height: 100px;
	border-radius: 100px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .responsename {
	margin-left: 0;
	flex-wrap: wrap;
	margin-bottom: 0;
	color: #000;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	flex-wrap: wrap;
}

section.entiredashboard.maincustomerdash.institute_dashboard .dashboardlink.dashboardtext .activetext {
	color: #86357A;
	background-color: #fff;
	border-radius: 50px 0 0 50px;
	padding: 0 22px;
	width: 100%;
	min-height: 69px;
	display: flex;
	align-items: center;
	text-align: start;
}

.institute_dashboard .dashboardlink.dashboardtext .active:before {
	position: absolute;
	content: "";
	background: #000;
	height: 100%;
	width: 7px;
	border-radius: 3px;
	left: -73px;
}

.institute_dashboard .myworkspacenav>ul>li.active {
	background: #86357A;
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.35));
}

.institute_dashboard .myworkspacenav>ul>li {
	background: #D2FFD2;
}

.institute_dashboard .myworkspacenav>ul li {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: column;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24.155px;
}

.institute_dashboard .myworkspacenav img {
	filter: brightness(0.5);
}

section.entiredashboard.maincustomerdash.institute_dashboard .input-form-div {
	border-radius: 10px;
	background: #FFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

section.entiredashboard.maincustomerdash.institute_dashboard .col-8.brows-part {
	padding-right: 0%;
}

section.entiredashboard.maincustomerdash.institute_dashboard .upload-image {
	align-items: flex-end;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.institute_dashboard .divider::before {
	left: 0;
	background: #000;
}

section.entiredashboard.maincustomerdash.institute_dashboard .divider::after {
	right: 0;
	background: #000;
	margin-right: 22%;
}

section.entiredashboard.maincustomerdash.institute_dashboard .uplod-title {
	color: #000;
}

section.entiredashboard.maincustomerdash.institute_dashboard .uploadlogo {
	margin: auto;
}

section.entiredashboard.maincustomerdash.institute_dashboard .border-div {
	padding: 40px 0 40px 0;
}

section.entiredashboard.maincustomerdash.institute_dashboard .uploadexpert.mb-4 {
	margin: 0;
}

section.entiredashboard.maincustomerdash.institute_dashboard .ads-title {
	color: #2A2A2A;
	padding: 0;
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
	/* 107.143% */
}

section.entiredashboard.maincustomerdash.institute_dashboard .uploadexpert.mb-4 {
	background: #D2FFD2;
}

section.entiredashboard.maincustomerdash.institute_dashboard .col-4.upload-image img {
	filter: invert(1);
}

section.entiredashboard.maincustomerdash.institute_dashboard .input-form-div {

	border-radius: 10px;
	background: #FFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

section.entiredashboard.maincustomerdash.institute_dashboard .paymenthistory ul.paymentstructure li span {
	text-align: left;
}

section.entiredashboard.maincustomerdash.institute_dashboard .textarea-caption textarea {
	border: 1px solid #00831D;
	padding: 5px;
	height: 118px;
	border-radius: 3px;
	color: #303030;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.institute_dashboard .divider-text {
	color: #000;
	background: #D2FFD2;
}

section.entiredashboard.maincustomerdash.institute_dashboard .textarea-caption {
	color: #303030;
	height: auto;
	padding: 0;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.institute_dashboard .adsimg {
	display: none;
}

section.entiredashboard.maincustomerdash.institute_dashboard .radioform {
	justify-content: left;
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.institute_dashboard input.input-caption,
TagsInput.input-caption {
	padding: 0;
	color: #303030;
	font-family: 'microsoft_sans_serifregular' !important;
	;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.institute_dashboard .form-group .form-control.form-select {
	border-radius: 3px;
	border: 1px solid #00831D;
	padding: 5px;
	height: 50px;

}

section.entiredashboard.maincustomerdash.institute_dashboard select.form-select,
section.entiredashboard.maincustomerdash.institute_dashboard .input-caption-border {
	background-color: #D2FFD2;
}

section.entiredashboard.maincustomerdash.institute_dashboard .radioform label.radiocontrol {
	padding-left: 0;
	padding-right: 10px;
	margin-bottom: 2px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .placeholder {
	background: transparent;
	opacity: 1;
}

.input-caption .input-caption {


	width: 100%;
}

::placeholder {
	color: #303030;
	font-family: 'microsoft_sans_serifregular' !important;
	;
	font-size: 12px !important;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.institute_dashboard .input-caption-border {
	border: 1px solid #00831D;
	border-radius: 3px;
}

section.entiredashboard.maincustomerdash.institute_dashboard span.input-icon-add {
	position: absolute;
	right: 5px;
	top: 44px;
	width: 29px;
	height: 29px;
}

section.entiredashboard.maincustomerdash.institute_dashboard label.placeholder {
	color: #6D6D6D;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 11px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .craete-ads .input-container .placeholder {
	margin-top: 11px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .input-container .placeholder {
	position: relative;
	left: 0;
	top: 0;
	margin-top: 0;
}

section.entiredashboard.maincustomerdash.institute_dashboard label.placeholder {
	color: #6D6D6D;

	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 11px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .placeholder {
	background: transparent;
	opacity: 1;
}

section.entiredashboard.maincustomerdash.institute_dashboard span.input-icon-add {
	position: absolute;
	right: 5px;
	top: 44px;
	width: 29px;
	height: 29px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .input-caption input.input.form-control {
	border: 1px solid #00831D;
	border-radius: 3px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .dashboardrightpanel .topexpertproduct .productslider .sliouter.col-4 {
	width: 50%;
}

section.entiredashboard.maincustomerdash.institute_dashboard .dashboardrightpanel .topexpertproduct .productslider .row {
	justify-content: center;
}

section.entiredashboard.maincustomerdash.institute_dashboard .col-lg-6 .input-caption-border {
	height: 50px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .close-ads {
	position: absolute;
	top: 15px;
	right: 15px;
	background: #86357A;
}

section.entiredashboard.maincustomerdash.institute_dashboard .craete-ads {
	position: relative;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails .myadbtn {
	background: #86357A;
}

section.entiredashboard.maincustomerdash.institute_dashboard .placeholder {
	transform: none !important;
	color: #3B3737;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	position: relative;
	top: 0;
	left: 0;
	padding: 0;
	background: none;
	opacity: 1;
}

section.entiredashboard.maincustomerdash.institute_dashboard .form-group .form-control {
	border-radius: 3px;
	border: 1px solid #00831D;
	color: #303030;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	height: 40px;
	padding: 5px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .selectbox>div,
section.entiredashboard.maincustomerdash.institute_dashboard .selectbox {
	height: 42px;
	border-radius: 3px;
	border: 1px solid #00831D;
	color: #303030;
}

section.entiredashboard.maincustomerdash.institute_dashboard .selectbox>div {
	border: none;
	height: auto;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails:nth-child(2) {
	position: relative;
	z-index: 8;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails:nth-child(3) {
	position: relative;
	z-index: 7;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails:nth-child(4) {
	position: relative;
	z-index: 6;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails:nth-child(5) {
	position: relative;
	z-index: 5;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails:nth-child(6) {
	position: relative;
	z-index: 4;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails:nth-child(7) {
	position: relative;
	z-index: 3;
}

section.entiredashboard.maincustomerdash.institute_dashboard.addexpertformsec .myaccountsection.institutedetails .form-fieldinner.sociallink label {
	background: #ffffff;
	border: none;
	border-radius: 4px;
	width: 60px;
	height: 60px;
	text-align: center;
	line-height: 28px;
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.institute_dashboard .sociallink {
	position: relative;
	margin-bottom: 20px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .sociallink label {
	background: #ffffff;
	border: none;
	border-radius: 4px;
	width: 60px;
	height: 60px;
	text-align: center;
	line-height: 28px;
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails .sociallink .fieldans {
	color: #212121;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails .form-group.social-control .form-control {
	padding: 5px;
	padding-left: 36px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .formscicon {
	position: absolute;
	top: -2px;
	left: -16px;
	color: #06293f;
	width: 45px;
	height: 45px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .ss {
	margin-left: 20px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails .grid-2 {
	grid-template-columns: 1fr 1fr;
	grid-gap: 0 20px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails button.ctaonebutton.widthhalf {
	background-color: white;
	border: 1px solid #EF000B;
	color: #EF000B !important;
	padding: 8px 19px;
	border-radius: 5px;
	font-size: 20px;
	font-weight: 700;
	/* display: inline-block; */
	width: 150px;
	height: 40px;
	color: #EF000B;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif;
}

section.entiredashboard.maincustomerdash.institute_dashboard .planesall {
	border-radius: 6px;
	background: #D2FFD2;
	box-shadow: 10px 10px 16px 0px rgba(0, 0, 0, 0.25);
}

section.entiredashboard.maincustomerdash.institute_dashboard .broadtp {
	background: #fff;
}

section.entiredashboard.maincustomerdash.institute_dashboard .planesinnerright.broadtp ul li {
	color: #000;
	text-align: center;

	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.institute_dashboard .planesinner ul li:before {
	top: 24px
}

section.entiredashboard.maincustomerdash.institute_dashboard h2.text-center.h3_style.borname {
	position: absolute;
	top: -69px;
	width: 90%;
	background: #86357A;
	filter: drop-shadow(10px 10px 16px rgba(0, 0, 0, 0.25));
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 20px;
	border-radius: 5px 5px 0 0;
}

section.entiredashboard.maincustomerdash.institute_dashboard .planesinnerright.broadtp hr {
	margin-top: 42px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .accountchangepass .institutelogintext {
	width: 100%;
	margin-left: 0;
	margin-right: auto;
	padding-top: 30px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .forgetpassinner h2.h2_style {
	color: #000;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
}

section.entiredashboard.maincustomerdash.institute_dashboard .right-institute .forgetpassinner h5 {
	color: #000;
	font-family: "Montserrat", sans-serif !important;
	font-size: 20px;
	margin-bottom: 0;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.institute_dashboard .right-institute .forgetpassinner h6 {
	color: #000;
	margin-bottom: 0;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	font-family: "Montserrat", sans-serif !important;
}

section.entiredashboard.maincustomerdash.institute_dashboard .ft-inner.otpauthentication {
	width: 70%;
	margin: auto;
}

section.entiredashboard.maincustomerdash.institute_dashboard .forgetpassinner .ft-inner.otpauthentication input {
	height: 40px;
	border-radius: 5px;
	border: 1px solid #00831D;
	padding: 5px;
}

section.entiredashboard.maincustomerdash .input-icon-button {
	position: absolute;

	top: 30px;
}

section.entiredashboard.maincustomerdash .accountchangepass section.loginscreeninstitute.registrationinstitute {
	background: #fff;
}

section.entiredashboard.maincustomerdash.institute_dashboard .offer-list .container {
	width: 100%;
	max-width: 650px !important;
}

section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain {
	border-radius: 10px;
	background: #FFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

section.entiredashboard.maincustomerdash .offersss .showallourbesttoo {
	display: inline-block !important;
}

section.entiredashboard.maincustomerdash.institute_dashboard h2.text-center.h3_style.borname {
	position: absolute;
	top: -69px;
	width: 90%;
	background: #86357A;
	filter: drop-shadow(10px 10px 16px rgba(0, 0, 0, 0.25));
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 20px;
	border-radius: 5px 5px 0 0;
}

.featureheading {
	max-width: 273px;
	color: #303030;
	font-family: Montserrat;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .planesinnerright.premium hr {
	margin-top: 42px;
}

section.entiredashboard .dashboardrightpanel .planesall.dashbook {
	border: none;
}

section.entiredashboard .dashboardrightpanel .planesall .planesinnerright {
	border-radius: 6px;
	border: 1px solid #D2FFD2;
	background: #D7FFD2;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

section.entiredashboard .dashboardrightpanel .planesall .planesinnerright ul li {
	color: #000;
	text-align: center;

	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .psop.planesinnerright.broadtp ul li {
	color: #000;
	text-align: center;

	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard .dashboardrightpanel .planesall .planesinnerright.premium ul li:nth-last-child(-n + 4) {
	background: #86357A;
	color: #fff;

}

.backbl {
	border-radius: 0px 0px 10px 10px;
	background: #D2FFD2;
}

section.entiredashboard .planesallbottom h5.h3_style.fst-italic {
	color: #000;

	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard .planesallbottom h5.h3_style.fst-italic span {
	font-weight: bold;
}

section.entiredashboard .backbl {
	padding: 10px;
}

section.entiredashboard .backbl .planesinnerright .ctatwobutton {
	border-radius: 5px;
	background: #86357A;
	color: #FFF;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
}

section.entiredashboard .backbl .planesinnerright .ctaonebutton {
	border-radius: 5px;
	background: #fff;
	color: #86357A;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
}

section.entiredashboard .backbl .planesinnerright label.form-check-label {
	color: #000;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

section.entiredashboard .backbl .planesinnerright label.form-check-label a {
	color: #000;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-decoration-line: underline;

}

.manageuser-backdiv {
	background: #D2FFD2;
	;
	padding: 20px;
}

section.entiredashboard .addexpert {
	/* background: #fff; */
}
p.acctext.manageuser-backdiv{
	margin: 0;
	padding-bottom: 0;
}
section.entiredashboard .addexpert .addexpertleft p {
	color: #303030;
	font-family: Montserrat;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

section.entiredashboard .addexpert .addexpertright button.ctaonebutton {
	border-radius: 5px;
	background: #86357A;
	color: #FFF;
	text-align: center;
	font-family: Montserrat;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard .addexpert p.acctext {
	color: #303030;
	font-family: Montserrat;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.072px;
}

section.entiredashboard .userlist {
	/* color: #fff; */
	padding-top: 0;
}

.userlistinner .mybtn .myadbtn {
	background: #86357A;
}

section.entiredashboard .userlist {
	/* background: transparent; */
}

section.entiredashboard .userlist .userlistinner {
	background: #fff;
	margin-bottom: 10px;
}
section.entiredashboard .userlist .userlistinner.psi
{
    background: transparent;
}
section.entiredashboard .userlist .userlistinner .username{
    color: #303030;
font-family: Montserrat;
font-size: 16px;
font-style: normal;

line-height: 30px; /* 187.5% */
letter-spacing: 0.064px;
}
section.entiredashboard .userguide span.plantooltip.custooltip:hover .tooltipcontent {
    visibility: visible;
    left: 80px;
    top: 10px;
}
section.entiredashboard  ul.tcon li{
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard .unver .statusundefined{
	border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
}
section.entiredashboard.maincustomerdash.main.institute_dashboard .unver .statusundefined .expertoutershadow{display: none;}
section.entiredashboard.maincustomerdash.institute_dashboard .accountchangepass section.loginscreeninstitute.registrationinstitute {
    background: #FFF;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
    margin-top: 40px;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard .userguide ul.dropdown-menu.show, section.entiredashboard.maincustomerdash.main.institute_dashboard .userguide ul.dropdown-menu.show ul
{
	border-radius: 8px;
background: #D2FFD2;
border: none;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard .manageuser-backdiv .tooltipcontent
{
	border-radius: 8px;
background: #D2FFD2;
padding:15px;
border: none;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard .userguide ul
{

	counter-reset: my-sec-counter;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard .userguide ul li{
	position: relative;
	padding-left:20px;
	padding-bottom: 10px;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard .userguide ul li::before{
    position: absolute;
    counter-increment: my-sec-counter;
    content: " " counter(my-sec-counter) ". ";
    left: 10px;
	top:5px;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard .userguide ul li ol li::before
{display: none;}
section.entiredashboard.maincustomerdash.main.institute_dashboard .userguide ul .dropdown-item:hover, section.entiredashboard.maincustomerdash.main.institute_dashboard .userguide ul .dropdown-item:focus{
	background: transparent;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard .userguide ul.dropdown-menu.show li, section.entiredashboard.maincustomerdash.main.institute_dashboard .userguide ul.dropdown-menu.show li a
{
border: none;
color: #000;

font-size: 12px;
font-style: italic;
font-weight: 600;
line-height: normal;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard ul.tcon li {
    position: relative;
	padding-left: 20px;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard ul.tcon {
    list-style: none;
    padding: 0;
    margin: 0;
    counter-reset: my-sec-counter;
}
section.entiredashboard.maincustomerdash.main.institute_dashboard ul.tcon  li.tconitem:before {
    content: "";
    position: absolute;
    counter-increment: my-sec-counter;
    content: " " counter(my-sec-counter) ". ";
	left: 0;
}
section.entiredashboard.maincustomerdash.institute_dashboard .subscribtionsummery
{
	border-radius: 5px;
background: #D2FFD2;
box-shadow: 5px 5px 20px 0px rgba(6, 40, 61, 0.20);
}
section.entiredashboard.maincustomerdash.institute_dashboard .paymenthistory ul.paymentstructure li
{background: #D2FFD2;}
section.entiredashboard.maincustomerdash.institute_dashboard  .myaccountsection.institutedetails.my-profile label.change_button {
    background: white;
    border: 1px solid #EF000B;
    padding: 16px 22px;
    border-radius: 5px;
    display: inline-block !important;
    margin-left: auto;
    margin-right: auto;
    height: auto;
	color: #EF000B;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 1px;
    font-family: "Roboto Flex", sans-serif !important;
}
section.entiredashboard.maincustomerdash.institute_dashboard  .myaccountsection.institutedetails.my-profile label.change_button img {
    width: 21px !important;
    height: 21px !important;
    margin: 0 !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
}
section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails button.ctaonebutton.widthhalf:hover{
background: #EF000B !important;
color: #fff !important;
}
.rti--container:focus-within{
	border: none !important;
	box-shadow: none;
}
.userlist.wholepad20.psbackwhite {
    background: #FFF;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
    margin-top: 30px;
}
section.entiredashboard.maincustomerdash.institute_dashboard .bluetab1 .selctbox.form-control[disabled]{
	color: #303030;
    font-family: 'microsoft_sans_serifregular' !important;
    font-size: 12px;
    font-style: normal;
	background: #e9ecef !important;}
	section.entiredashboard.maincustomerdash.institute_dashboard .bluetab1 .selctbox.form-control .fieldans
	{
		color: #303030;
    font-family: 'microsoft_sans_serifregular' !important;
    font-size: 12px;
    font-style: normal;
	line-height: 26px;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails .form-fieldinner .input-container.form-group
	
	{
margin-bottom: 0;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails .form-fieldinner .DatePicker.form-group span.dateim
	{
		position: absolute;
		right: 7px;
		top: 6px;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .bluetab1 .selctbox.form-control	.downarrow {
		position: absolute;
		right: 15px;
		top: 33px;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .bluetab1.bluetab2 .selctbox.form-control .fieldans {
		background: #D2FFD2;
		padding: 5px 5px;
		border-radius: 28px;
		margin-right: 4px;
		line-height: 20px;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .bluetab1.bluetab2 .selctbox.form-control{
		display: flex;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsection.institutedetails.form-group .form-control{height: 48px;}
	section.entiredashboard.maincustomerdash.institute_dashboard .mybtn{
		position: relative;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .mybtn::before {
		border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    content: "";
    width: 36px;
    height: 37px;
    position: absolute;
    left: -3px;
    right: 0;
    top: -4px;
	}
	.form-fieldinner.Category-fieldinner .fieldans {
		border-radius: 8px;
		background: #D2FFD2;
		text-align: center;
		margin-right: 10px;
		padding: 5px;
		padding-right: 5px !important;
		color: #303030;
		font-family: "Microsoft Sans Serif";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .form-fieldinner label {
		color: #000;
		font-family: 'microsoft_sans_serifregular' !important;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-bottom: 0;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .fieldans {
		color: #303030;
		font-family: 'microsoft_sans_serifregular' !important;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .selectbox>div>div>div
	{
		color: #303030;
		font-family: 'microsoft_sans_serifregular' !important;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .logoform .form-fieldinner
	{
		background-color: #D2FFD2;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsectionheading.manageuser{
		padding-top: 1px;
	}
	section.entiredashboard.maincustomerdash.institute_dashboard .myaccountsectionheading.manageuser h3.h3_style
	{
		color: #2A2A2A;

font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 30px; 
margin-top: 30px !important;
	}