.ourinstituteinnerimg {
    top: -48px;
    right: 0;
    left: 0;
    margin: auto;
    border-radius: 200px;
    width: 200px;
    height: 160px;
    position: absolute;
}
.ourinstituteinnerimg img {
    width: 100px;
}

.ourinstituteinnerimg img {
    border: 1px solid #000;
    border-radius: 8px;
}
@media (max-width:767px){
    .ourinstituteinnerimg img {
        width: 70px;
        border:none;    box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.25);
    } 
    .ourinstituteinnerimg{width: 70px; height: 70px; top:-28px;}  
}