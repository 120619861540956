@media screen and (min-device-width: 768px) and (max-device-width: 1024px){section.offersearch {
    overflow-x: visible;
}
.selectinner .mainchecklabel{font-size: 13px;margin-left: -7px;}
.selectallinnercheckbox li.selectinner .form-check-input {
    width: 15px;
    height: 15px;
    margin-left: -3px;
    z-index: 9;
    position: absolute;
    top: 5px;
}
.newofferdesign .herosectionoffer .offerherobannertext h2 span {
    color: #FFF;
    font-size: 30px;
}
.newofferdesign .herosectionoffer .offerherobannertext h2 {
    color: #FFF;
    font-size: 33px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
}
.newofferdesign .offerbannerslider {
    border-radius: 20px;
   
    padding: 10px;
}
.selectallinnercheckbox li.selectinner  .form-check{padding-left: 14px !important;}
li.selectinner .dropdown-toggle:after {
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 5px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    position: absolute;
    right: -6px;
    top: 13px;
    bottom: 0;
    margin: auto;
    display: block;
}
}
@media (max-width:767px){
    .detailedtext.mt-4 {
        padding-bottom: 15px;
    }
    .container-rimib .selectallinnercheckbox>ul>li{margin-right: 0px;}
    .newproductdesign .mainofferdetails {
        padding-top: 10px;
    }
    .sliouter .Investment, .sliouter .Mortgage, .sliouter .Real.Estate, .sliouter.Insurance, .sliouter.Banking{
        border:none
    }
    .newproductdesign .sliouter{
        box-shadow: 4px 3px 3px 0px rgba(0, 0, 0, 0.25);
    
    }
    .newproductdesign .sliouter  .categoryproductsliderinnerimage{
box-shadow: 0px 0px 9.44px 0px rgba(0, 0, 0, 0.25);
    }
    .sliouter .slbtn {
        margin: 20px 0 0;
    }
    .newproductdesign .herosectionoffer .offerherobannertext h2{
        font-size: 30px;
        line-height: 1;
        padding-left: 10px;
    }
    .entireofferfiltercustomised .sliouter{margin-bottom: 15px;}
    .entireofferfiltercustomised .sliouter .categoryproductsliderinner{padding-bottom: 10px; margin-bottom: 0 !important;}
    .newproductdesign .mt-4.mb-4.categoryproductsliderinner {
        margin-bottom: 0 !important;
    }
    .newproductdesign .herosectionoffer{padding: 20px 0;
    background:url(../../../assets/productimage/product_background_mobile.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;}
   .newproductdesign .modalspecial.productdetail-modal .detailedimage img {
        width: 97%;
        margin: 10px;
        border: 0px solid #fff;
    }
    .newproductdesign .herosectionoffer .offerherobannertext h5{
        font-size: 16px;
        line-height: 1; 
        padding-left: 10px;
    }
    .newproductdesign .herosectionoffer .col-lg-7.col-sm-7, .newproductdesign .herosectionoffer .col-lg-5.col-sm-5 {
        padding: 0;
    }
    .newofferdesign .herosectionoffer .row{
        align-items: center;
        justify-content: center;
        margin: auto;
    }
    .newproductdesign .offerbannerslider.productbannerslider{
        width: 100%;
        padding: 20px 0;
        border-radius: 0px;
    }
    .newproductdesign .herosectionoffer .row {
        align-items: center;
        justify-content: center;
        margin: auto;
    }
    .offerbannerslider.productbannerslider .slick-prev:before{
        background: url(../../../assets/productimage/leftarrowmobile.svg);
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        filter: invert(0);transform: rotate(0deg);
    }
    .offerbannerslider.productbannerslider .slick-next:before{
        background: url(../../../assets/productimage/leftarrowmobile.svg);
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        filter: invert(0);transform: rotate(180deg);
        
    }

    /* .offerbannerslider.productbannerslider .slick-prev:before{right: 48px;}
    .offerbannerslider.productbannerslider .slick-next:before{left: 48px;} */
    section.sortingdiv ul li {
        padding: 0 10px;
    }
    section.sortingdiv ul li a{
        font-size: 18px;
    }
    .mobile-filter .dropshortby .dropdown-menu.show:before{display: none;}
    .dropfilter .form-check {
        display: flex    ;
        align-items: center;
        padding-left: 16px;
    }
    .mobile-filter .dropdown-menu.show:before
    {
        background: rgba(0, 0, 0, 0.2);
        content: "";
        position: absolute;
        height: 1200px;
        width: 1021px;
        left: -374px;
        right: 0;
        top: 0px;
        bottom: 0;
        
    }
    .selectallcheckbox {
        margin-bottom: 10px;
        margin-top: 10px;  
        display: flex   ;
        justify-content: center;
    }
  
    .selectallinnercheckbox>ul {
        display: flex    ;
        padding: 0;
        justify-content: center;
        margin-bottom: 0;
    }
    .selectallinnercheckbox>ul>li {
        margin-right: 0px;
        border-radius: 2px;
    }
    .mainofferdetails h2{        
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 6px;}
        .mainofferdetails b.subtitle_subtitle{font-weight: 400;margin-bottom: 0;font-family: "SF Pro Display" !important; color: #000;}
        .mainofferdetails h5{
            font-size: 12px;
font-style: normal;
font-weight: 400;
font-family: "SF Pro Display" !important; color: #000;
line-height: 1;
margin-bottom: 0;
max-width: 320px;
margin-left: auto;
margin-right: auto;
        }
        .sliouter{
            border-bottom: 1px solid transparent;
        }
        .categoryproductsliderinner{position: relative;}
        .entireofferfiltercustomised .container{
            padding-left: 0;
            padding-right: 0;
        }
        .categoryproductsliderinner .categoryproductsliderinnerimage{
            margin:0;
        }
        .parentcatname{
            top: -15px;
        margin-top: 0;
        z-index: 9;
        width: 76px;
        font-size: 12px;
        height: 25px;
        line-height: 1;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
        
        .sliouter .productcatname{
            border-radius: 0px 0px 8px 8px;
        border: 1px solid #00831D;
        margin-top: 0;
        width: max-content;
        padding: 10px;
        margin: auto;
        border-top: none;
        }
        .sliouter.Banking .productcatname, .sliouter.Banking
        {
            border-color: #2280B8;
        }
        .sliouter.Mortgage .productcatname, .sliouter.Mortgage 
        {
            border-color: #2F596F;
        }
        .sliouter.Insurance .productcatname , .sliouter.Insurance 
        {
            border-color: #FF4C00;
        }
        .sliouter.Investment .productcatname,.sliouter.Investment
        {
            border-color: #86357A;
        }
        .sliouter.Real.Estate .productcatname, .sliouter.Real.Estate
        {
            border-color: #00831D;
        }
        .entireofferfiltercustomised .sliouter .categoryproductsliderinner{padding-top: 30px;}
        button#dropdownFilter, button#dropdownShortBy {
            color: #000;
            font-family: "Fjalla One";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: none;
            border: none;
            position: relative;
        }
        .d-flex.mobiledrop {
            justify-content: center;
            border-top: 1px solid #ACACAC;
            border-bottom: 1px solid #acacac;
            margin-top: 20px;
        }
        .newproductdesign .mobiledrop .filtersectionglobal {
            margin-top: 0;
            text-align: left;
        }
        .newproductdesign .mobiledrop .selectinner .mainchecklabel{font-size: 14px;}
        .newproductdesign .mobiledrop .selectinner  span.maincheckboxicon img {
            width: 23px;
            height: 23px;
            object-fit: contain;
        }
        .newproductdesign .mobiledrop  .selectallinnercheckbox li.selectinner .form-check-input,.newproductdesign .mobiledrop input#Checkall {
            width: 18px;
            height: 18px;
            margin-left: 3px;
            z-index: 9;
        }
        .newproductdesign .mobiledrop .selectallinnercheckbox li.selectinner .dropdown-item .form-check-input
        {
            margin-left: -25px;
        }
        .newproductdesign .mobiledrop .selectallcheckbox label.form-check-label.mainchecklabel{font-size: 14px;}
        .newproductdesign .mobiledrop .form-checkselect{padding-left: 5px; padding:5px}
        .newproductdesign .mobiledrop  .selectinner .dropshortby a.dropdown-item{padding: 0;}
        .newproductdesign .mobiledrop  .selectinner .dropshortby  a.dropdown-item .form-check-input
        {
            margin-left: -20px;
        }
        .newproductdesign .mobiledrop .selectallinnercheckbox ul.dropdown-menu label.form-check-label{
            color: #000;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 11px;
            padding-left: 4px;
            width: 126px;
        }
        .show>.btn-secondary.dropdown-toggle:focus{
            box-shadow: none !important;
        }
        .dropshortby{position: relative;}
        .mobiledrop > .dropdown:before, .dropshortby:before {
            content: "";
        position: absolute;
        right: -12px;
        width: 1px;
        height: 24px;
        background: #ccc;
        top: 4px;
        }
        .mobiledrop  .dropdown-menu.show {
            display: block;
        width: 282px;
        padding: 0;
        left: auto !important;
        right: -132px;
        }
        .mobiledrop .dropfilter  span.subcatimage {
            display: inline-block;
            margin-left: 10px; position: relative;
        }
        .mobiledrop .dropfilter  span.subcatimage img{
            filter: invert(0);    position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 0;
            bottom: 0;
           
        }
        .mobiledrop .dropfilter .form-check .form-check-input{
            margin-left: -10px;margin-top: 0;
        }
        .mobiledrop .dropfilter  input[type="checkbox"]:enabled:checked{
            background-color: #00831D;
            color: white;
        }
        .mobiledrop > .dropdown:last-child:before, .mobiledrop > .dropshortby:last-child:before{display: none;}
        .mobiledrop > .dropdown, .mobiledrop > .dropshortby {
            margin: 0 15px;
            position: relative;
            width: 50%;
            text-align: center;
        }
        .newproductdesign .mobiledrop .dropdown-menu ul{padding-left: 0; 
            background: #fff; 
            margin-bottom: 0;
            fill: #FFF;
            filter: drop-shadow(2px 2px 18px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
        position: relative;
        z-index: 9;
        padding: 10px ;
    }
    .newproductdesign .mobiledrop .dropdown-menu ul li:last-child{border: none; padding-bottom: 0; margin-bottom: 0;}
    .newproductdesign .mobiledrop .dropdown-menu ul li{position: relative; padding-bottom: 5px; margin-bottom: 5px; border-bottom: 1px solid #ccc;}
        .newproductdesign .mobiledrop  li.selectinner .dropdown-toggle:after {
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 9px solid #fff;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            position: absolute;
            right: 8px;
            top: 9px;
            bottom: 0;
            margin: auto;
            display: block;
        }
        .form-checkselect{width:251px}
        .newproductdesign .mobiledrop .selectallinnercheckbox>ul>li{width: 250px; padding: 5px;border-radius: 2px; }
        .selectallcheckbox  span.maincheckboxicon img{margin-left: 12px;}
        .filtersectionglobal {
            position: absolute;
            z-index: 999;
            left: -158px;
            right: auto;
            top:43px;
            width: 282px;
            margin: auto;
            background: rgba(255, 255, 255, 0.6);
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 2px 2px 18px 0px rgba(0, 0, 0, 0.25);
        
        }
        .viewbutton{width: 90px;
            height: 25px;
            font-family: "Roboto Flex";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            padding: 0;
    }
        button#dropdownFilter:after, button#dropdownShortBy:after {
       
            content: "";
            border-top: 7px solid #000000;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            position: absolute;
            right: -4px;
            top: 13px;
            bottom: 0;
            margin: auto;
            display: block;
            left: auto;
        } 
        .selectallinnercheckbox>ul>li[disabled]{
            list-style: none;
            border-radius: 5px;
            border: 1px solid #676767;
            background: linear-gradient(0deg, #FFF 0%, #D6D6D6 100%);
            padding: 10px;
            width: 250px;
            margin-right: 0px;
        }
         .mobiledrop .selectallinnercheckbox>ul>li[disabled].selectinner .dropdown-toggle:after{
            border-top: 9px solid #000000;
        }
        .newproductdesign .mobiledrop li.selectinner ul.dropdown-menu.show{width: auto !important; opacity: 1;}

        .filter-wrap {
            display: none;
        }
        
        .open-active.filter-wrap {
            display: block;
        }
        .filtersectionglobal:after{
            position: absolute;
        top: -9px;
        bottom: auto;
        left: 85%;
        margin-left: -10px;
        content: "";
        display: block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
        border-top: none;
        }
        .sliouter .Banking{
            border: none;
        }
        .sliouter.Banking, .sliouter.Investment, .sliouter.Mortgage, .sliouter.Real.Estate, .sliouter.Insurance  {
            border: none;
        }
        
}