:root {
	--Customerdashboard-RealEstate: #D2FFD2;
	--Customerdashboard-Investment: #FFE0FF;
	--Customerdashboard-Insurance: #FFE3D0;
	--Customerdashboard-Mortgage: #E9EEF9;
	--Customerdashboard-Banking: #DAF3FF;
	--Customerdashboard-Button-RealEstate: #00831D;
	--Customerdashboard-Button-Investment: #86357A;
	--Customerdashboard-Button-Insurance: #FF4C00;
	--Customerdashboard-Button-Mortgage: #2F596F;
	--Customerdashboard-Button-Banking: #2281B8;
}

.modalspecial.asktheexpertconfirmpopup.popupouter.customerpopupmnl {
	position: fixed;
	background-color: rgba(255, 255, 255, 0.6);
	top: 0;
	right: 0;
	bottom: 0;
	left: auto;
	z-index: 9999;
	max-width: 1366px;
}
img.logoclass {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
	text-align: center;
	line-height: 1.6;
}
section.entiredashboard.maincustomerdash.customer-dashboard>.container-fluid>.row>.col-lg-4.col-sm-4 {
	background-color: #FFE3D0;
	border-radius: 25px 25px 0 0;
	box-shadow: none;
	padding: 0;
	max-width: 400px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .col-lg-4.col-sm-4:before {
	position: absolute;
	top: 0;
	border-radius: 25px 25px 0 0;
	background-color: #fff;
	width: 73px;
	height: 100%;
	content: "";
	left: 35px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .dashboardrightpanel {
	background: #fff;
}

section.entiredashboard.maincustomerdash.customer-dashboard .menusideimage img {}

section.entiredashboard.maincustomerdash.customer-dashboard .dashboardlink.dashboardtext .activetext {
	color: #2F596F;
	background-color: #fff;
	border-radius: 50px 0 0 50px;
	padding: 0 22px;
	width: 100%;
	min-height: 69px;
	display: flex;

	margin-bottom: 0;
	align-items: center;
	text-align: start;
}

.dashboardrightpannerinnerleft {
	padding-top: 20px;

}

.myexpertnav.myaccountnav ul li:first-child.active {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
	height: 144px;
	width: 144px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 2.84px 2.84px 31.27px 0px #00000040;


}

.myexpertnav>ul>li.active {
	background: none;
	background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
	box-shadow: 2.84px 2.84px 31.27px 0px #00000040;

}

.myexpertnav>ul>li:first-child.active {
	background: none;
	background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
	box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
}

.myexpertnav.myaccountnav>ul>li:last-child.active {
	background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
	box-shadow: 2.84px 2.84px 31.27px 0px #00000040;

}

section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav.myaccountnav.qnanav ul li {
	margin-right: 20px;
}

.myexpertnav>ul li {
	background: none;
}

.myexpertnav>ul>li:last-child {
	background: none;

}

.latest-offers .offerslider {
	margin-bottom: 40px;
}

section.latest-offers {
	border-radius: 20px;
	padding: 0;
}

.myexpertnav>ul>li:first-child {
	background: none;
}

section.entiredashboard.maincustomerdash.customer-dashboard section.limitedoffer {
	padding: 20px 50px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav.myaccountnav.qnanav ul li {
	width: 140px;
	height: 140px;
	background-color: #FFE3D0;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	color: #000;
	margin-left: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav.myaccountnav.qnanav ul li.active {
	color: #fff;
}

.myexpertnav>ul {
	justify-content: start;
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash .customermain {
	margin-bottom: 50px;
}

.mydash ul.dashboardinner p {
	font-size: 20px;
	color: #000;
	font-weight: 700;
	padding: 0 17px;
	cursor: pointer;
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash .dashboard h2 {
	font-size: 30px;
	color: #000000;
	font-weight: 700;
	font-family: "Montserrat", sans-serif;
}

section.entiredashboard.maincustomerdash .dashboard h5 {
	font-family: "Poppins", sans-serif;
	font-size: 18px;
	font-weight: 500;
	color: #06293F;
	line-height: 30px;
	margin: 0;
}

.customer-dashboard section.entiredashboard.maincustomerdash .customermain {
	justify-content: start;
	border: none;
}

section.entiredashboard.maincustomerdash .dashboarditext {
	font-size: 20px;
	font-weight: 400;
	font-style: italic;
	color: #06293F;
	line-height: 30px;
	font-family: "Poppins", sans-serif !important;

}

.myexpertnav.myaccountnav.qnanav.martop40.customermain .active img {}

.expertquestiondetailscategory span {
	color: #000;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	background: none;
	padding: 0;
}

.expName span {
	font-size: 16px;
	font-weight: 500;
	color: #303030;

	line-height: 24px;

}

.expertquestiondetails .questiondate {
	font-family: 'Poppins';
	display: block;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.008em;
	color: #7D7D7D;
}

.questioninnerinbottom {
	margin-bottom: 20px;
}

.dashboardrightpanelinner .questioninner .RealEstate {
	background-color: var(--Customerdashboard-RealEstate);
}

.dashboardrightpanelinner .questioninner .Investment {
	background-color: var(--Customerdashboard-Investment);
}

.dashboardrightpanelinner .questioninner .Insurance {
	background-color: var(--Customerdashboard-Insurance);
}

.dashboardrightpanelinner .questioninner .Mortgage {
	background-color: var(--Customerdashboard-Mortgage);
}

.dashboardrightpanelinner .questioninner .Banking {
	background-color: var(--Customerdashboard-Banking);
}

.Banking .expertquestiondetailscategory {

	border-bottom: 1px solid var(--Customerdashboard-Button-Banking);
}

.RealEstate .expertquestiondetailscategory {
	border-bottom: 1px solid var(--Customerdashboard-Button-RealEstate);
}

.Insurance .expertquestiondetailscategory {
	border-bottom: 1px solid var(--Customerdashboard-Button-Insurance);
}

.Mortgage .expertquestiondetailscategory {
	border-bottom: 1px solid var(--Customerdashboard-Button-Mortgage);
}

.Investment .expertquestiondetailscategory {
	border-bottom: 1px solid var(--Customerdashboard-Button-Investment);
}

.alldashboard .backdeepblueBanking {
	background-color: var(--Customerdashboard-Banking);
}

.alldashboard .backdeepblueRealEstate {
	background-color: var(--Customerdashboard-RealEstate);
}

.alldashboard .backdeepblueInvestment {
	background-color: var(--Customerdashboard-Investment);
}

.alldashboard .backdeepblueMortgage {
	background-color: var(--Customerdashboard-Mortgage);
}

.alldashboard .backdeepblueInsurance {
	background-color: var(--Customerdashboard-Insurance);
}

.backdeepblueRealEstate .expertquestiondetailscategory {
	background-color: var(--Customerdashboard-Button-RealEstate);
}

.backdeepblueInvestment .expertquestiondetailscategory {
	background-color: var(--Customerdashboard-Button-Investment);
}

.backdeepblueMortgage .expertquestiondetailscategory {
	background-color: var(--Customerdashboard-Button-Mortgage);
}

.backdeepblueInsurance .expertquestiondetailscategory {
	background-color: var(--Customerdashboard-Button-Insurance);
}

.backdeepblueBanking .expertquestiondetailscategory {
	background-color: var(--Customerdashboard-Button-Banking);
}

.dashboardrightpanelinner .questioninnerin {
	min-height: 220px;
}

.questioninnerin {
	min-height: 220px;
}

.questiontext {
	margin-bottom: 5px;
}

section.entiredashboard.maincustomerdash .questiontext.questiontext span {
	font-weight: 700;
	font-size: 16px;
	font-family: 'Poppins', sans-serif !important;
	line-height: 19.2px;
	color: #303030;
}

.questiontext.questiontext b {
	font-size: 16px;
	font-weight: 500;
	color: #3B3B3B;
	line-height: 21.6px;
	font-family: 'microsoft_sans_serifregular' !important;

}

section.entiredashboard.maincustomerdash .dashboarditext h4 {
	font-size: 20px;
	font-weight: 400;
	font-style: italic;
	color: #06293F;
}

.customer-dashboard .myexpertnav.myaccountnav.qnanav.martop40.customermain .active {
	box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
	background: #2F596F;
	color: #fff;
	width: 144px;
	height: 144px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border-radius: 50%;
	font-size: 16px;
	line-height: 24.16px;
	font-family: "Poppins" !important;
}

.customer-dashboard .searchincustomer .heroleftpanel h2 {
	color: #303030;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 0;
}

.customer-dashboard .searchincustomer .heroleftpanel h4 {
	color: #303030;
	text-align: center;
	margin-bottom: 0;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.customer-dashboard .searchincustomer .searchmaininnerhero.heroleftpanel h2.h2_style {
	color: #303030;
	text-align: center;
	font-family: Montserrat;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.customer-dashboard .searchincustomer select.input.form-control.form-select:not(:focus):valid {
	background-color: #FFE3D0;
}

.customer-dashboard .searchincustomer .heroleftpanel h4 {
	font-weight: 400;
	font-size: 16px;
	color: #303030;
	line-height: 24px;
	margin-bottom: 0;
	font-family: "Poppins" !important;
}

.searchincustomer .searchmaininnerhero.heroleftpanel h2.h2_style {
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #303030;
}

.searchincustomer .form-group .form-control {
	padding: 0;
}

.searchincustomer select.input.form-control.form-select:not(:focus):valid {
	font-size: 20px;
	font-weight: 500;
	color: #303030;
	height: 50px;
	border-radius: 6px;
	padding: 0 24px;
	background-color: #E5F3FF;
	font-family: 'Poppins' !important;
}
.searchincustomer .myexpertformarea.searcharea.searchmainara select.input.form-control.form-select:not(:focus):valid {
    font-size: 20px;
    font-weight: 500;
    color: #303030;
    height: 50px;
    border-radius: 6px;
    padding: 0 18px;
   
    font-family: 'Poppins' !important;
    border-right: 1px solid #000 !important;
    border-radius: 0;
    margin-right: 10px !important;
}
/* 
.searchincustomer .myexpertformarea.searcharea.searchmainara .input-container.form-group:first-child {
	background-color: #E5F3FF;
	border-radius: 5px;
} */

.searchincustomer .myexpertformarea {
	background: none;
	box-shadow: none;
}

.searchincustomer .myexpertformarea.searcharea {
	background: #FFE3D0;
    position: relative;
    padding: 0;
}

.searchincustomer .searchmainara button.ctaonebutton {
	width: auto;
}
.searchincustomer .searchmainara button.ctaonebutton.searchiconbutton{
	width: 150px;
    height: 40px;
    color: #EF000B;
    text-align: center;
    font-family: "Roboto Flex";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.8px;
    border-radius: 5px;
    border: 1px solid #EF000B;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
	margin: 10px;
}
.myexpertformarea.searcharea.searchmainara select.input.form-control.form-select{background-color: #FFE3D0; border: none;margin: 0;}
.myexpertformarea.searcharea.searchmainara select.input.form-control.form-select:not(:focus):valid{background-color: #FFE3D0; border: none;}
.myexpertformarea.searcharea.searchmainara select.input.form-control.form-select:focus{background-color: #FFE3D0; border: none;}
.searchincustomer .searchiconbutton {
	height: 50px;
	width: 50px;
	background-color: #E5F3FF;
	border-radius: 6px;
}

.searchincustomer .myexpertformarea.searcharea .input-container.form-group:nth-child(2) {
	border-right: none;
}

.searchincustomer .ctaonebutton {

	border: 1px solid transparent;
}

.searchincustomer .ctaonebutton:hover {
	background: #E5F3FF;

}

.searchincustomer select.input.form-control.form-select:focus:valid {
	font-size: 20px;
    font-weight: 500;
    color: #303030;
    height: 50px;
    border-radius: 6px;
    padding: 0 18px;
    font-family: 'Poppins' !important;
    border-right: 1px solid #000 !important;
    border-radius: 0;
    margin-right: 10px !important;

}

.swal-overlay--show-modal .swal-modal {
	background-color: #fff;
	border-radius: 6px;
	width: 600px;
	height: auto;
}

.swal-text {
	color: #000;
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 37px;
	text-align: center;
}

section .cust-offers {
	border-radius: 20px;
	padding: 22px;
}

section .cust-product {
	border-radius: 20px;
}

section .cust-adds {
	padding: 22px;
	border-radius: 20px;

}

section .account-setting {
	border-radius: 20px;
}

.dashboardrightpannerinnerleft h5 {
	font-size: 16px;
	color: #06293F;
	font-weight: 500;
	line-height: 24px;
	font-family: "Poppins", sans-serif;
}

.category_icon {
	position: absolute;
	left: auto;
	bottom: 0px;
	/* z-index: -1; */
	display: flex;
	margin: 0 0 0 auto;
	justify-content: center;
	align-items: center;
	right: 44px;
}

.category_icon img {
	width: 100%;
	height: 100%;
}

.dashboardrightpanelinner .questioninnerin {

	position: relative;
}

.dashboardrightpanelinner .questioninner .Banking .category_icon img {
	opacity: 0.1;
	filter: invert(38%) sepia(99%) saturate(398%) hue-rotate(158deg) brightness(94%) contrast(93%);
}

.dashboardrightpanelinner .questioninner .Mortgage .category_icon img {
	opacity: 0.1;
	filter: invert(30%) sepia(31%) saturate(775%) hue-rotate(156deg) brightness(90%) contrast(87%);
}

.dashboardrightpanelinner .questioninner .Insurance .category_icon img {
	opacity: 0.1;
	filter: invert(37%) sepia(36%) saturate(6250%) hue-rotate(1deg) brightness(103%) contrast(105%);
}

.dashboardrightpanelinner .questioninner .Investment .category_icon img {
	opacity: 0.1;
	filter: invert(25%) sepia(89%) saturate(454%) hue-rotate(258deg) brightness(97%) contrast(96%);
}

.dashboardrightpanelinner .questioninner .RealEstate .category_icon img {
	opacity: 0.1;
	filter: invert(25%) sepia(100%) saturate(1736%) hue-rotate(123deg) brightness(89%) contrast(101%);
}

.dashboardrightpanelinner .questioninner .Mortgage .category_icon img {
	height: 140px;
	width: auto;
}

.dashboardrightpanelinner .questioninner .Banking .category_icon img {
	height: 140px;
	width: auto;
}

.dashboardrightpanelinner .questioninner .Investment .category_icon img {
	height: 140px;
	width: auto;
}

.dashboardrightpanelinner .questioninner .Insurance .category_icon img {
	height: 140px;
	width: auto;
}

.dashboardrightpanelinner .questioninner .RealEstate .category_icon img {
	height: 140px;
	width: auto;
}

.customer-dashboard.main-myaccount-dashboard .form-group .form-control {
	border: 1px solid #FFE3D0;
	padding: 5px;

}

.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef !important;
	opacity: 1;
}

.customer-dashboard.main-myaccount-dashboard .selectbox>div {
	border: 1px solid #FFE3D0;
}

.customer-dashboard.main-myaccount-dashboard .selectbox.css-b62m3t-container .css-t3ipsp-control {}

.customer-dashboard.main-myaccount-dashboard button.edit-button {
	border-radius: 5px;
	border: 1px solid #2F596F;
	background: #2F596F;
	transition: 0.3s all ease-in;
}

.customer-dashboard.main-myaccount-dashboard button.edit-button:hover {
	background: transparent;
	color: #2F596F;
}

.customer-dashboard.main-myaccount-dashboard button.edit-button:hover img {
	filter: invert(1);
}

.customer-dashboard.main-myaccount-dashboard .personal-details .form-fieldinner label,
.customer-dashboard.main-myaccount-dashboard .my-account .form-fieldinner label,
.customer-dashboard.main-myaccount-dashboard .occupation .form-fieldinner label {
	margin-bottom: 0px;
}

.customer-dashboard.main-myaccount-dashboard .myaccountsection.institutedetails .myaccountsectionheading h3 {
	color: #2A2A2A;
	margin-bottom: 20px;
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
	/* 107.143% */
}

.customer-dashboard.main-myaccount-dashboard .myaccountsection button.ctaonebutton.widthhalf {
	width: 150px;
	height: 40px;
	backdrop-filter: blur(2px);
	border: 1px solid #EF000B;
	color: #EF000B;
	background: transparent;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif;
}

.customer-dashboard.main-myaccount-dashboard .myaccountsection button.ctaonebutton.widthhalf:hover {
	background: #EF000B !important;
	color: #fff;
}

.customer-dashboard.main-myaccount-dashboard .form-fieldinner {
	margin-bottom: 9px;
}

.customer-dashboard.main-myaccount-dashboard .myaccountsection.institutedetails {
	border-radius: 10px;
	background: #FFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.customer-dashboard.main-myaccount-dashboard .myaccountsection.institutedetails .fieldans {
	padding: 0;
}

.customer-dashboard.main-myaccount-dashboard .form-field .grid-2 {
	gap: 0 20px;
}

.customer-dashboard.main-myaccount-dashboard .myaccountsection .form-fieldinner .input-container.form-group .placeholder {
	transform: translateY(5px) translateX(-1px) scale(1);
	font-size: 12px;
}

.customer-dashboard.main-myaccount-dashboard .myaccountsection .form-group {
	margin-bottom: 0;
}

.customer-dashboard .dashboardlink ul li {
	line-height: 59px;
	margin: 20px 0;
	position: relative;
	padding: 5px 0 0 20px;
}

.customer-dashboard .dashboard-ps-offer {
	border-radius: 10px;
	background: #FFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
	padding: 20px 10px;
}

.customer-dashboard.main-myaccount-dashboard .form-group .selectbox>div {
	background-color: #FFE3D0 !important;
}

.customer-dashboard section.limitedoffer {
	background: transparent;
}

.customer-dashboard .searchincustomer {
	margin-top: 30px;
}

.customer-dashboard .container-fluid {
	border-radius: 25px;
	background: #FFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

}

.customer-dashboard .forgetpassinner h2 {
	color: #303030;
	text-align: center;
	margin-bottom: 0;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.customer-dashboard.main-myaccount-dashboard .accountchangepass .institutelogintext {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

.customer-dashboard .right-institute .forgetpassinner h5,
.customer-dashboard .right-institute .forgetpassinner h6 {
	text-align: center;
	margin-bottom: 0;
}

.customer-dashboard .ft-inner.otpauthentication {
	margin-top: 64px;
	clear: both;
}


.customer-dashboard .dashboardrightpanel .mb-4 {
	margin-bottom: 15px !important;
}

.customer-dashboard .otpauthentication button.contiue-button {
	background-color: white;
	border: 1px solid #EF000B;
	padding: 8px 19px;
	border-radius: 5px;

	width: 150px !important;
	height: 40px;
	color: #EF000B;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif;
	margin-left: auto;
	margin-right: auto;
}

.customer-dashboard .otpauthentication button.contiue-button:hover {
	background-color: #EF000B;
	color: #fff;
}

.customer-dashboard button.contiue-button[disabled] {
	background: #ccc;
	border: 1px solid #ccc;
}

.customer-dashboard .dashboardlink.dashboardtext .activetext:before {
	position: absolute;
	content: "";
	background: #000;
	height: 100%;
	width: 7px;
	border-radius: 3px;
	left: -73px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .main-blog-section {
	padding: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .expertquestiondetails {
	flex-wrap: wrap;
	width: 90%;
}

section.entiredashboard.maincustomerdash .questioninnerinbottom {
	justify-content: space-between;
	align-items: flex-start;
	z-index: 99;
	position: relative;
}

section.entiredashboard.maincustomerdash .expertquestiondetails .widthfull {
	width: 100%;
	margin-top: 25px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .subcategoryinneraskexpert {
	width: 100%;
}

section.entiredashboard.maincustomerdash.customer-dashboard .support-button {
	width: 220px;
	height: 40px;
	flex-shrink: 0;
	background: #2F596F;
	color: #FFF;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: 1.8px;
	display: flex;
	justify-content: center;
	margin: 20px auto;
	line-height: 2.1;
	border-radius: 5px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .categoryonesetfirst {
	width: 100%;
}

section.entiredashboard.maincustomerdash.customer-dashboard .categorytwosetfirsttest .categoryonesetwidth {
	width: 100px;
	height: 115px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .selectcategory li.selectinner .dropdown-toggle:after {
	display: none;
}

section.entiredashboard.maincustomerdash.customer-dashboard .selectallinnercheckbox>ul>li {
	width: 145px
}

section.entiredashboard.maincustomerdash.customer-dashboard .selectinner .mainchecklabel {
	font-size: 18px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .categorytwosetfirsttest .categoryonesetwidth .iconone,
section.entiredashboard.maincustomerdash.customer-dashboard .categorytwosetfirsttest .categoryonesetwidth .icontwo {
	margin-bottom: 2px;
}

.modalspecial.asktheexpertconfirmpopup.popupouter button.close_btn {
	width: 52px;
	height: 52px;
	border-radius: 52px;
	background: #00831D;
	line-height: 51px;
	left: auto;
	right: 13px;
	top: 13px;
	position: absolute;
	text-align: center;
	cursor: pointer;
	border: none;
}

section.entiredashboard.maincustomerdash.customer-dashboard .qnabutton {
	display: none;
}

section.entiredashboard.maincustomerdash.customer-dashboard .sliouter {
	position: relative;
}

section.entiredashboard.maincustomerdash.customer-dashboard .parentcatname {
	top: 0
}

.sliderContent .pb-4 h5 {
	font-size: 16px;
	min-height: 180px;
	padding: 0 10px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .sliderContent .applybutton {
	border-radius: 6px;
	height: 45px;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px;
	padding: 10px 20px;
	margin: auto;
	display: flex;
	width: 150px;

	justify-content: center;
}

section.entiredashboard.maincustomerdash.customer-dashboard .selectcategory ul {
	flex-wrap: wrap;
	margin-top: 20px;
	justify-content: space-evenly;
}

.selectallinnercheckbox>ul>li {
	margin-right: 10px;
	margin-bottom: 10px;
}

.selectcategory button.edit-button {
	background-color: #2280B8;
	padding: 11px 45px;
	border: none;
	border-radius: 6px;
	color: #FFFFFF;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	font-family: "Poppins", sans-serif !important;
}

section.entiredashboard.maincustomerdash.customer-dashboard .catsubcatsection .maincat h4 {
	color: #000;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .subcat ul {
	padding: 0;
	margin-bottom: 0;
	display: flex;
}

section.entiredashboard.maincustomerdash.customer-dashboard .subcat ul li {
	border-radius: 30px;
	background: #FFE3D0;
	width: 100px;
	color: #303030;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-right: 10px;
	text-align: center;
	padding: 8px;
	display: flex;
	justify-content: center;

}

section.entiredashboard.maincustomerdash.customer-dashboard .subcat ul li span {
	width: 19px;
	height: 19px;
	background: #fff;
	border-radius: 40px;
	margin-right: 5px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .subcat ul li span img {
	width: 10px;
	padding: 1px;
}

.customer-dashboard.main-myaccount-dashboard .input-container .placeholder {
	top: 0
}

.boxsh.dashboardshaddow {
	background: #FFF;
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
	padding: 20px;
	margin: 20px 0;
}

textarea.textps {
	border-radius: 10px;
	background: #FFE3D0;
	width: 100%;
	height: 120px;
	border: 1px solid transparent;
	color: #1B1B1B;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding: 10px;
}

.customer-dashboard.main-myaccount-dashboard .main-myaccount-dashboard .input-icon-button {
	top: 38px
}

.dashboardrightpannerinnerleft {
	position: relative;
}

.modalspecial.productdetail-modal .dashboardrightpannerinnerleft .close_btn {
	background: #2F596F;
}

.customer-dashboard.main-myaccount-dashboard .form-group.disabled .selectbox>div {
	background-color: #e9ecef !important;
}

.customer-dashboard.main-myaccount-dashboard select.form-select {
	background-color: #FFE3D0 !important;
}

.modalspecial.productdetail-modal .category-4 .detailedtext .ctaonebutton:hover {
	color: var(--Bluetwo);
	border-color: var(--Bluetwo);
	background: var(--lightBluetwo);
}

.modalspecial.productdetail-modal .category-5 .detailedtext .ctaonebutton:hover {
	color: var(--green);
	background-color: var(--lightgreen);
	border-color: var(--green);
}

.modalspecial.productdetail-modal .category-3 .detailedtext .ctaonebutton:hover {
	color: var(--Blueone);
	border-color: var(--Blueone);
	background: var(--lightBlueone);
}

.modalspecial.productdetail-modal .category-2 .detailedtext .ctaonebutton:hover {
	color: var(--purple);
	border-color: var(--purple);
	background: var(--lightpurple);
}

.modalspecial.productdetail-modal .category-1 .detailedtext .ctaonebutton:hover {
	color: var(--orange);
	border-color: var(--orange);
	background: var(--lightorange);
}
button.myadbtn.cross_btn{
	width: 24px;
	height: 24px;
	line-height: 20px;
	background-image: url(../../../../assets/images/white_cross.svg) !important;
	background-size: 12px !important;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	background-color: #2F596F;
}
@media (max-width:767px) {
	.searchincustomer .searchmainara button.ctaonebutton.searchiconbutton{
		width: 90px;
		height: 30px;
		font-size: 12px;
		letter-spacing: 1.2px;
		margin: 5px;
	}
	section.entiredashboard.maincustomerdash.customer-dashboard .categorytwosetfirsttest .categoryonesetwidth {
		width: 90px;
		height: 90px;
	}
	section.entiredashboard.maincustomerdash.customer-dashboard .maincatcustomer .slick-slider 
	{
		width: 100%;
	}
	section.entiredashboard.maincustomerdash.customer-dashboard .main-question {
		max-width: 100%;
		width: 100%;
	}

	section.entiredashboard.maincustomerdash.customer-dashboard .categoryfourset {
		float: none;
	}

	section.alldashboard {
		background-color: #fff;
		padding: 0px 0px;
		padding-bottom: 0;
		border-radius: 2px 2px 0 0;
		margin-bottom: 0px;
	}

	.questionstext b {
		font-size: 12px;
		font-family: 'microsoft_sans_serifregular' !important;
		padding-right: 5px;
	}

	.customer-dashboard .searchincustomer .heroleftpanel h4 {
		color: #303030;
		text-align: center;
		font-family: 'microsoft_sans_serifregular' !important;
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-align: center !important;
	}

	.customer-dashboard .heroleftpanel {
		padding-bottom: 0;
	}

	.customer-dashboard .searchincustomer .searchmaininnerhero.heroleftpanel h2.h2_style {
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		margin-top: 10px;
	}

	section.backwhite {
		background: #fff;
		border-radius: 0;
		padding: 0;
	}

	.dashboardrightpanelinner .questioninnerin {
		min-height: auto;
	}

	.searchincustomer .myexpertformarea.searcharea {
		position: relative;
		width: 340px;
	}

	.searchincustomer .searchmainara button.ctaonebutton {
		width: auto;
		position: absolute;
		right: 0;
		top: -45px;
	}

	.searchincustomer .searchmainara .form-select {
		/* background-position: right 4.75rem center; */
	}

	.customer-dashboard .searchincustomer .heroleftpanel h2 {
		font-size: 18px;
	}

	.allcustans .questionstext span,
	.questioninnerin span button {
		font-size: 12px;
		font-family: 'microsoft_sans_serifregular' !important;
	}

	.customer-dashboard .dashboard-ps-offer {
		border-radius: 0;
		background: #FFF;
		box-shadow: none;
		padding: 20px 0px;
	}

	.selectallinnercheckbox>ul>li {
		margin-right: 0px;
		margin-bottom: 10px;
	}

	.dashboardinner li.nav-item a {
		display: flex;
		margin-bottom: 7px;
		margin-left: 10px;
		margin-top: 7px;
	}

	.dashboardinner li.nav-item a p {
		color: #000;
		margin-left: 20px;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		min-height: auto;
		margin-bottom: 0;
	}

	section.entiredashboard.maincustomerdash.customer-dashboard .menusideimage img {
		width: 25px;
	}

	section.entiredashboard.maincustomerdash.customer-dashboard .col-lg-8.col-sm-8.blogsinner {
		padding: 0 !important;
	}

	.dashboardinner li.nav-item a.active {
		color: #2F596F;
		background-color: #fff;
		border-radius: 50px 0 0 50px;
		padding: 0 5px;
		width: 100%;
		min-height: 45px;
		display: flex;
		margin-bottom: 0;
		align-items: center;
		text-align: start;
		margin-bottom: 5px;

		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
	}

	section.entiredashboard.maincustomerdash .p-0 {
		min-height: 300px;
	}

	.maincustomerdash div#askQus {
		margin: 0;
	}

	.maincustomerdash .catstart {
		margin: 0 0px;
	}

	span.menusideimage {
		margin-right: 10px;
	}

	.dashboard-container .navbar-toggler {
		transition: none;
		border: none;
		padding-left: 21px;
	}

	section.entiredashboard.maincustomerdash .dashboard h2 {
		font-size: 36px;
		color: #000000;
		font-weight: 700;
	}

	section.entiredashboard.maincustomerdash .dashboardrightpannerinnerleft>h2.font-weight-600 {
		position: absolute;
		left: 0;
		right: 0;
		width: max-content;
		margin: auto;
		top: -46px;
		color: #000;
		text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.dashboardleftpanel span.navbar-toggler-icon {
		background: none;
		width: auto;
		height: auto;
	}

	.navbar-toggler:focus {
		box-shadow: none;
	}

	.customer-dashboard.main-myaccount-dashboard button.edit-button {
		font-size: 12px;
		padding: 1px 8px;
		width: 75px;
		border-radius: 2px;
		height: 25px;
	}


.expertquestiondetailscategory {
	width: auto;
}

.customer-dashboard .container-fluid {
	padding: 0 0px;
}

.customer-dashboard .homepageofferblock.carddecoration {
	padding: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .expertquestiondetails {
	width: 100%;
}

section.entiredashboard.maincustomerdash.customer-dashboard .support-button {
	font-size: 12px;
	width: 120px;
	height: 30px;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	letter-spacing: 0;
	text-transform: uppercase;
	line-height: 1.6;
	border-radius: 2px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .catsubcatsection .maincat h4 {
	font-size: 12px;
}

.boxsh.dashboardshaddow h3 {
	color: #000;

	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.boxsh.dashboardshaddow p {
	font-size: 10px;
	font-family: 'microsoft_sans_serifregular' !important;
	line-height: 14px;
}

.boxsh.dashboardshaddow {
	background: #FFF;
	filter: none;
	padding: 10px;
	margin: 0px 0;
    margin-bottom: 20px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .account-settings {
	padding-bottom: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .account-settings-inner {
	padding: 0;
}

section.entiredashboard.maincustomerdash .dashboardrightpannerinnerleft>h2 span.headingicon {
	display: inline-block !important;
	margin-right: 5px;
}

section.entiredashboard.maincustomerdash.customer-dashboard section.limitedoffer {
	padding: 0px 0px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav.myaccountnav.qnanav ul li,
section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav.myaccountnav.qnanav ul li.active {
	width: 100px;
	height: 100px;
	font-size: 10px;
	line-height: 1.2;
}

section.entiredashboard.maincustomerdash.customer-dashboard .myaccountnav img {
	width: 40px;
	margin-bottom: 0;
}

.expertquestiondetailscategory {
	position: absolute;
	right: 0;
	top: 0
}

section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav.myaccountnav.martop40.customermain li.active,
section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav.myaccountnav.martop40.customermain li {
	width: 100px;
	height: 100px;
	font-size: 12px;
	flex-wrap: wrap;
	flex-direction: row;
	line-height: 1.1;

}

section.entiredashboard.maincustomerdash.customer-dashboard .myaccountsection {
	padding: 10px;
	margin: 10px;
}

.customer-dashboard.main-myaccount-dashboard .myaccountsection.institutedetails .myaccountsectionheading h3 {
	font-size: 12px;
}

.personal-details .form-fieldinner label,
.occupation .form-fieldinner label {
	line-height: 15px;
	font-weight: 500;
	font-size: 10px;
	color: #000;
	font-family: "Poppins", sans-serif !important;
}

.personal-details .fieldans,
.occupation .fieldans {
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 11px;
	color: #303030;
}

section.entiredashboard.maincustomerdash.customer-dashboard .blogblogmain {
	width: 50%;
	padding: 0 5px !important;
}

section.entiredashboard.maincustomerdash.customer-dashboard .customask {
	margin-left: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .catstart.clearfix.blogsasktheexpert {
	background-color: #FFFFFF;
	border-radius: 20px 20px 0 0;
	padding: 0px 0 17px 0;
	margin: 0 0px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .dashboardrightpannerinnerleft h5.h5_style,
section.entiredashboard.maincustomerdash.customer-dashboard p.catetext2 {
	font-size: 12px;
	font-family: "SF Pro Display" !important;
	line-height: 14px;
}

section.entiredashboard.maincustomerdash.customer-dashboard p.catetext1 {
	font-size: 12px;
	line-height: 1.5;
}

section.entiredashboard.maincustomerdash {
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .sliouter {
	background: linear-gradient(180deg, #F2FFF2 0.05%, #F3FFF3 20.7%, #CFFFCF 72.86%, #F3FFF3 89.21%, #F2FFF2 100%);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

}

section.entiredashboard.maincustomerdash.customer-dashboard .modalspecial.productdetail-modal .col-lg-6.col-sm-6 {
	padding: 0 12px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .modalspecial.productdetail-modal>div {
	width: 95%;
}

section.entiredashboard.maincustomerdash.customer-dashboard .modalspecial.productdetail-modal .detailedview .detailedimage {
	margin: 10px;
}

.dashboardrightpanel .modalspecial.productdetail-modal section.listofoffermainsection {
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .modalspecial.productdetail-modal .pname {
	font-size: 12px;
	width: max-content;
}

section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav>ul>li.active {
	z-index: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .Insurance.homepageofferblock {
	border-bottom: 1px solid #FF4C00;
	border-radius: 0;
	margin-bottom: 20px;
	background: #FFF2EA;
}

section.entiredashboard.maincustomerdash.customer-dashboard .parentcatname {
	top: -15px
}

section.entiredashboard.maincustomerdash.customer-dashboard .sliouter .categoryproductsliderinnerimage img {
	margin-top: 35px;
	box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
}

section.entiredashboard.maincustomerdash.customer-dashboard .categoryproductsliderinnerimage {
	border: none;
}

section.entiredashboard.maincustomerdash.customer-dashboard .sliouter .categoryproductsliderinnerimage {
	margin-top: 20px;
}

section.entiredashboard.maincustomerdash.customer-dashboard .Real.Estate.homepageofferblock {
	border-bottom: 1px solid #00831D;
	border-radius: 0;
	margin-bottom: 20px;
	background: #F1FFF1;
}

section.entiredashboard.maincustomerdash.customer-dashboard .Investment.homepageofferblock {
	border-bottom: 1px solid #9C2488;
	border-radius: 0;
	margin-bottom: 20px;
	background: #FFEFFF;
}

section.entiredashboard.maincustomerdash.customer-dashboard .Mortgage.homepageofferblock {
	border-bottom: 1px solid #2F596F;
	border-radius: 0;
	margin-bottom: 20px;
	background: #EDF0F6;
}

section.entiredashboard.maincustomerdash.customer-dashboard .Banking.homepageofferblock {
	border-bottom: 1px solid #2281B8;
	border-radius: 0;
	margin-bottom: 20px;
	background: #EDF9FF;
}

section.entiredashboard.maincustomerdash.customer-dashboard .dashboardrightpannerinnerleft {
	padding: 10px;
    padding-top: 20px;
}
section.entiredashboard.maincustomerdash.customer-dashboard .account-settings-inner .modalspecial.productdetail-modal .close_btn{
    top:4px;
}
section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav.myaccountnav {
	padding-left: 10px !important;
}
section.entiredashboard.maincustomerdash.customer-dashboard .selectallinnercheckbox li.selectinner .form-check-input {
    width: 16px;
    height: 16px;
    margin: 0;
    top:5px;
}
section.entiredashboard.maincustomerdash.customer-dashboard span.maincheckboxicon img {
    width: 23px;
    height: 20px;
    object-fit: contain;
}
section.entiredashboard.maincustomerdash .accountchangepass section.loginscreeninstitute.registrationinstitute
{
    padding: 0;
}
.customer-dashboard.main-myaccount-dashboard .accountchangepass .institutelogintext {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
}
.customer-dashboard .forgetpassinner h2{
    font-size: 18px;
    line-height: 1;
    text-align: left;
    margin-bottom: 10px;
}
.customer-dashboard .right-institute .forgetpassinner h5, .customer-dashboard .right-institute .forgetpassinner h6{
    color: #000;
font-family: "SF Pro Display" !important;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: left;
}
.customer-dashboard .right-institute .plaindetailsleft {
    justify-content: left;
    padding-left: 0;
}
.customer-dashboard .password-creation .passtext h5, .customer-dashboard .password-creation .passtext ul li{font-size: 12px; line-height: 16px;}
.customer-dashboard .passtext ul li:before{    top: 4px;}
.customer-dashboard .main-myaccount-dashboard .input-container .placeholder{
    font-size: 12px;
}
.customer-dashboard section.entiredashboard.maincustomerdash .input-icon-button {
    position: absolute;
    top: 38px;
}
.customer-dashboard .otpauthentication button.contiue-button{
    width: 95px !important;
height: 25px;
font-size: 12px;
padding: 0;
border-radius: 5px;
}
.customer-dashboard  .personal-details .disgrid {display: block;}
.customer-dashboard  .personal-details .form-fieldinner.col50{
    width: 48%;
    display: inline-block;
    margin-right: 5px;
}
.customer-dashboard .modalspecial.productdetail-modal h2.h2_style{
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1;
}
.customer-dashboard .right-institute .forgetpassinner h5.h5_style.martop30, .customer-dashboard .right-institute  .ft-inner.otpauthentication{
    margin-top: 10px;
}
section.entiredashboard.maincustomerdash .showallourbesttoo{width: 95px !important; height: 25px;}
section.entiredashboard.maincustomerdash.customer-dashboard .selectallinnercheckbox>ul>li {
    width: 140px;
    padding: 5px;
}
section.entiredashboard.maincustomerdash.customer-dashboard .selectinner .mainchecklabel {
    font-size: 15px;
}
section.entiredashboard.maincustomerdash.customer-dashboard .myexpertnav.myaccountnav.qnanav ul li:last-child {
	margin-right: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .dashboardrightpanel {
	z-index: 0;
	position: relative;
}

section.entiredashboard.maincustomerdash ul.navbar-nav.dashboardinner {
	position: absolute;
	z-index: 999999;
	background: #FFE3D0;
	width: 250px;
	padding-top: 15px;
	display: flex;
	align-items: flex-start;
	justify-content: left;
	height: 100vh;
	top: -7px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

.text-column ul.navbar-nav.dashboardinner li.nav-item {
	display: flex;
	margin-bottom: 20px;
	width: 100%;
}

section.entiredashboard.maincustomerdash .headingicon img {
	width: 23px;
}

section.entiredashboard.maincustomerdash .questioninnerinbottom {
	flex-wrap: nowrap;
}

.dashboardinner li.nav-item a {
	position: relative;
}

.dashboardinner li.nav-item a.active:before {
	background: #000;
	content: "";
	position: absolute;
	width: 4px;
	height: 30px;
	left: 14px;
	top: 8px;

}

section.entiredashboard.maincustomerdash div#navbarText {
	position: absolute;
	top: 52px;
	left: 0px;
	width: 100%;
	background: rgba(255, 255, 255, 0.7);
	height: 100vh;
	z-index: 99;
}

section.entiredashboard.maincustomerdash.customer-dashboard>.container-fluid>.row>.col-lg-4.col-sm-4 {
	max-width: 100%;
}

section.entiredashboard.maincustomerdash.customer-dashboard .round-box.nav-item a {
	font-family: "Fjalla One";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding: 0;
}

section.entiredashboard.maincustomerdash.customer-dashboard .round-box.nav-item:nth-child(5) {
	background: linear-gradient(180deg, #ACFFBF 0%, #FFF 100%);
	color: #00831D;
	text-align: center;
	font-family: "Fjalla One";
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	width: 20%;
	height: 80px;
	border-radius: 0;
	border-color: transparent;
}

section.entiredashboard.maincustomerdash.customer-dashboard .round-box.nav-item:nth-child(4) {
	background: linear-gradient(180deg, #B8DEFF 0%, #FFF 100%);
	color: #2280B8;
	text-align: center;
	font-family: "Fjalla One";
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	width: 20%;
	height:80px;
	border-radius: 0;
	border-color: transparent;
}

section.entiredashboard.maincustomerdash.customer-dashboard .round-box.nav-item:nth-child(3) {
	background: linear-gradient(180deg, #C7D1E3 0%, #FFF 100%);
	color: #2F596F;
	text-align: center;
	font-family: "Fjalla One";
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	width: 20%;
	height: 80px;
	border-radius: 0;
	border-color: transparent;
}

section.entiredashboard.maincustomerdash.customer-dashboard .round-box.nav-item:nth-child(2) {
	background: linear-gradient(0deg, #FFF -0.12%, #FFD0F8 102.5%);
	color: #86357A;
	text-align: center;
	font-family: "Fjalla One";
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	width: 20%;
	height: 80px;
	border-radius: 0;
	border-color: transparent;
}

section.entiredashboard.maincustomerdash.customer-dashboard .round-box.nav-item:nth-child(1) {
	background: linear-gradient(0deg, #FFF 0%, #FFBA9C 112.66%);
	color: #FF4C00;
	text-align: center;
	font-family: "Fjalla One";
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	width: 20%;
	height: 80px;
	border-radius: 0;
	border-color: transparent;
}

.main-round-box .nav-item:nth-child(4) img,
.main-round-box .nav-item:nth-child(3) img,
.main-round-box .nav-item:nth-child(5) img,
.main-round-box .nav-item:nth-child(1) img,
.main-round-box .nav-item:nth-child(2) img {
	height: 15px !important;
	width: auto !important;
	margin-bottom: 3px;
}

.main-round-box.nav.nav-pills {
	justify-content: center;
	align-items: center;
	margin: 20px 0 0 0;
	width: 93%;
}

.main-round-box .nav-item:nth-child(1) a.active,
.main-round-box .nav-item:nth-child(2) a.active,
.main-round-box .nav-item:nth-child(3) a.active,
.main-round-box .nav-item:nth-child(4) a.active,
.main-round-box .nav-item:nth-child(5) a.active {
	background: transparent;
	border-radius: 0% !important;
	box-shadow: none;
}

h4.finame {
	font-size: 0;
	margin-bottom: 0;
}

h4.finame:first-letter {

	text-shadow: 0.347px 0.924px 1.965px rgba(0, 0, 0, 0.25);
	font-family: "Red Hat Display";
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 0;
}

/* .main-round-box .nav-item:nth-child(5) a.active, .main-round-box .nav-item:nth-child(4) a.active, .main-round-box .nav-item:nth-child(3) a.active, .main-round-box .nav-item:nth-child(2) a.active, .main-round-box .nav-item:nth-child(1) a.active
    {
        background: transparent;
    } */
}