@font-face {
    font-family: 'microsoft_sans_serifregular';
    src: url('../../../assets/fonts/micross-webfont.woff') format('woff'),
         url('../../../assets/fonts/micross-webfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
 
}

.container-rimib{max-width: 1366px;
    margin: 0 auto;
    width: 100%;
}
.categorybanner{
    background: url(../../../assets/realEstateimages/RealEstatebanner.svg); 
    background-position: bottom !important;
    height: 550px;}
    .categorybanner  .bn-image img{height: 471px;}
.categorysectionfour .viewallbuttoncomm {
    padding: 11px 60px;
    font-size: 26px;
    font-weight: 500;
    width: 237px;
    border-radius: 5px;
    background-color: #FFFFFF;
    border: 1px solid #EF000B;
    color: #EF000B;
    box-shadow: 2px 2px 10px 0px #EF000B;
    margin: 0 auto;font-family: "Roboto Flex";
}
.categorysectionfour .viewallbuttoncomm:hover {
        box-shadow: 6px 6px 33px 0px #CF292940;
        border: 1px solid #EF000B;
        color: #EF000B;
}
  .latestbutton {
    text-align: center;
}
.categorysectionfour {
    margin-bottom: 0px;
}

.messagemebuttoncomm {
    background-color: #00831D;
    font-family: "Roboto Flex", sans-serif !important;
    padding: 10px 40px;
    border-radius: 10px;
    color: white;
    margin: 0 auto 15px;
    text-decoration: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    width: 200px;
    border: 1px solid transparent;

}
.messagemebuttoncomm:hover{
    background-color: #FFFFFF;
    color: #00831D;
    cursor: pointer;
    border: 1px solid #00831D;
}
.myadsbuttoncomm {
    background-color: #FFFFFF;
    width: 200px;
    padding: 10px 40px;
    border-radius: 10px;
    color: #00801D;
    margin: 0 auto 15px;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    border: 1px solid #00831D;
    line-height: 22px;
}

.myadsbuttoncomm:hover{
    color: white;
    background-color: #00831D;
    cursor: pointer;
}
.applynowbuttoncomm {
    background-color: #D2FFD2;
    border: 1.06px solid #00831D;
    padding: 10px 21px;
    border-radius: 5px;
    color: #00831D;
    text-decoration: none;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 22px;
font-family: "Roboto Flex", sans-serif;    

}
.applynowbuttoncomm:hover{
    background-color: #00831D;
    box-shadow: 0px 0px 4px 0px #00831D;
    color: #FFFFFF;
    border: 1.06px solid #FFFFFF;
    cursor: pointer;
}

.viewdetailsbuttoncomm {
    background-color: #00801D;
    padding: 10px 21px;
    border-radius: 5px;
    color: #FFF;
text-align: center;
font-family: "Roboto Flex";
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 2.925px;
    border: 2px solid transparent;
}
.viewdetailsbuttoncomm:hover{
    color: #00831D;
    background-color: #D2FFD2;
    cursor: pointer;
    border: 2px solid #00831D;
}



.realestateone .container.makethecontainer {
    display: flex;
    justify-content: end;
}
/* .categorybanner {
    background-image: url("../../../assets/realEstateimages/categorybanner.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 70px 0;
    background-position: bottom;
    
} */
.categorybanner > img{width: 100%; }
.bannerfont h2{
    color: #00831D;
    font-size: 50px;
    font-weight: 700;
    font-family: "Red Hat Display", sans-serif !important;
    line-height: 66px;
    text-align: left;
position: relative;
    text-transform: capitalize;
    margin-bottom: 0px;
    margin-bottom: 25px;
}
.bannerfont h2:after{
    content: "";
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 545px;
    background: #00831D;
    height: 6px;
}
.bannerfont p.realestateherotext{
    color: #000;
    max-width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;}
 .bannerfont h6 {
    color: #000000;
    font-family: "Montserrat",sans-serif  !important ;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 32px;
    line-height: 37px;
}
.bannerfont h6 strong{
    font-weight: 700;
}
.bannerfont  .bannersubtext{color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;}
.bannerfont p{color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height:  24px;
max-width: 565px;}
.bannerfont .borderbottom img{
    width: 72px;
}
.topsubtextfive{font-weight: 500;}
b.realEbold {
    color: #000000;
    font-size: 40px;
    font-weight: 800;
}

.realestateone .bannerfont p {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 40px;
    font-family: "Montserrat",sans-serif  !important ;

    max-width: 592px;
}
.realestateone p.realestateherotext {
    color: #000;
    font-family: "Montserrat",sans-serif  !important ;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 19px;
    max-width: 532px;
    margin-bottom: 0;
}
.bannerfont.borderbottom span {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
}
.categorysectionfive .realEbuttonmain img {
    width: 53px;
    margin: 0 0 0 10px;
}
.categorysectionfive .realEbuttonmain {
    background: #EF000B;
    max-width: 530px;
    padding: 16px;
    display: flex;
    border: 2px solid transparent;

    align-items: center;
    justify-content: space-around;
}
.categorysectionfive.realEbuttonmain p.realEbutton{
    font-size: 45px;
    color: white;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
}
.categorysectionfive .realEbuttonmain:hover {
    cursor: pointer;
    background-color: #EF000B;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 0px 10px 0px #EF000B;

}
.categorybanner .askbutton img {
    width: 53px;
    margin: 0 0 0 10px;
}
.categorybanner .askbutton {
    background: #EF000B;
    max-width: 530px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 2px solid transparent;
    margin: 0;

}
.categorybanner .askbutton p.askres{
    font-size: 50px;
    color: white;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
    line-height: 59px;

}
.categorybanner .askbutton:hover {
    cursor: pointer;
    background-color: red;
    box-shadow: 0px 0px 10px 0px #EF000B;
    border: 2px solid #FFFFFF;
    color: white;
}

.container-fluid.letestcontainer {
    padding: 0 75px;
}

.categorysectionone .letest-inner h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #000000;
    margin-bottom: 10px;
    font-family: "Montserrat",sans-serif  !important ;

}
.institubuttoni {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.institop img {
    display: flex;
    justify-content: center;
}
.institutext h5 {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    margin: 15px 0;
}
.offersss{
    position: relative;
}
.offersss .offer-insurence-inner .offer-name.disfontsmall{
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    text-align: center;
    min-height: 0px;
}
.categorysectionone .row.offersss, .categorysectiontwo .row.offersss {
    max-width: 1250px;
    margin: auto;
}
.offersss .slick-slide img {
    display: block; 
    height: 300px;
    object-fit: contain;
    width: 100%;

}
.offersss .offer-inner{
    padding: 0;
}
.offersss .slick-current {
    transform: none;
}
.instibottom {
    padding: 10px;
}
.institutionsitmes {
    max-width: 410px;
    height: 370px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #00831D66;
    text-align: center;
    border-radius: 12px;
}
.institumain{
    position: relative;
}
.institumain .slick-slide.slick-active {
    padding: 22px;
}


.institumain .slick-current {
    transform: none;
}

.categorysectionone .letest-inner h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
    line-height: 20px;
    font-family: "Montserrat",sans-serif  !important ;

    
}
.categorysectionone {
    margin: 40px 0;
}
.categorysectionone a.textcolors:hover {
    color: black;
}
.categorysectionone .letest-inner span {
    font-size: 16px;
    /* font-weight: 700; */
    color: #000000;
    font-family: "Montserrat",sans-serif  !important ;
    text-align: center;
}
.showallbtn {
    display: flex;
 
    align-items: end;
    
    justify-content: center;
    text-align: end;
}

.toprealEbutton {
    padding: 11px 60px;
    font-size: 26px;
    font-weight: 500;
    border-radius: 5px;
    background-color: #FFFFFF;
    border: 1px solid #EF000B;
    color: #EF000B;
    box-shadow: 2px 2px 10px 0px #EF000B; font-family: "Roboto Flex";
  }

  .optionsbuttoni {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
  .Adsbybuttoni {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.Adsbytop img {
    display: flex;
    justify-content: center;
}
.Adsbytext h5 {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    margin: 15px 0;
}
.Adsbybottom {
    padding: 10px;
}
.Adsbyitmes {
    max-width: 410px;
    height: 370px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #00831D66;
    text-align: center;
    border-radius: 12px;
}
 
.categorysectiontwo.TopRealEstateProducts-inner h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #000000; 
}
.categorysectionone .offersss .offer-inner .colinstititeinnername{
    line-height: normal;
}
.categorysectiontwo .offersss .offer-inner .colinstititeinnername{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}
.realestatefour .offersss .offer-inner .colinstititeinnername{
    line-height: normal;
}
.offersss .coliin .posbuttontf.d-flex {
    justify-content: center;
    padding: 37px 0px 21px 0;
}

.categorysectiontwo h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    text-align: center; 
    font-family: "Montserrat",sans-serif  !important ;
    line-height: 20px; 
    margin-bottom: 0px;
}
.offersss .offer-inner {
    margin: 0 0 20px;
}
.offersss .offer-inner .list-name.disfontsmall {
    color: #000;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 0;
    background: transparent;
    border-radius: 0;

}
.categorysectiontwo a.textcolors:hover {
    color: black;
}
.categorysectiontwo .TopRealEstateProducts-inner span {
    font-size: 16px;
    /* font-weight: 700; */
    color: #000000;
    text-align: center;
    font-family: "Montserrat",sans-serif  !important ;

}
.categorysectiontwo h2 {
    color: #000;
    text-align: center;
    font-family: "Montserrat",sans-serif  !important ;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
}

.categorysectiontwo , .categorysectionthree { 
    padding: 20px 0 0 0;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}
.categorysectiontwo{padding-bottom: 20px;}
.applynowbuttoncomm {
    color: #00831D;
}

.optionsmain{
    position: relative;
}
.optionsbuttoni {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.optionstop img {
    display: flex;
    justify-content: center;
}
.optionstext h5 {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    margin: 15px 0;
}
.optionsbottom {
    padding: 10px;
}
.optionsitmes {
    max-width: 410px;
    height: 370px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px #00831D66;
    text-align: center;
    border-radius: 12px;
}
.optionsmain .slick-slide.slick-active {
    padding: 22px;
}
.optionsmain .slick-current {
    transform: none;
}
.realestatefour {
    margin: 80px 0;
}
.realestatefour .realeheading{
    margin-bottom: 45px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: "Montserrat",sans-serif  !important ;
    line-height: 24px;
    color: #000000;
}
.realestatefour h2.letestadsheading {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    color: #000000;
    font-family: "Montserrat",sans-serif  !important ;
    margin-bottom: 10px;
}
.categorysectionthree{
    background-position: bottom;
    background-repeat: no-repeat  !important;
    width: 100%;
    background-size: cover  !important;
    margin: 40px 0 40px 0;
    padding: 0px 0 20px  0; 
}
.toprealEflexflow{

    justify-content: center;
}
.ToprealEimgslider {
    display: flex;
    flex-direction: column;
    height: 554px;
    max-width: 600px;
    overflow-y: scroll; 
}
.categorysectionthree .colinstititeinnerimage {
    border-radius: 12.894px;
    background: #FFF;
    box-shadow: 2.262px 2.262px 24.884px 0px rgba(0, 0, 0, 0.25);
    width: 130px;
    height: 130px;
    overflow: hidden;
    padding: 15px;
    position: relative;
    margin: auto;
}
.categorysectionthree .colinstititeinnerimage img {
    position: absolute;
    width: 60%;
    left: 0;
    right: 0;
    margin: auto;
}

.categorysectionthree .institute-text {
    margin: 10px 0 20px 0;
    display: none;
}
.categorysectionthree .institute-text{
    color: #000;
    font-family: "Montserrat",sans-serif  !important ;

font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-decoration-line: underline;}

.ToprealEimgslider{
    margin: 0 0 0 auto;
    margin-bottom: 30px; 
}
.ToprealEimg {
    display: flex;
    justify-content: center;
}
.toprealEblockname {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
}
.ToprealEimgbox img {
    height: auto;
    display: block;
}
.ToprealEimgbox{
    margin-bottom: 29px;
}
.ToprealE ::-webkit-scrollbar {
    width: 15.44px;
  }
  .ToprealE  ::-webkit-scrollbar-track {
   
    border-radius: 10px;
    background-color:  #FFFFFF;
  }
 
.toprealErighttext {
    margin-left: 50px;
    margin-top: 50px;
}
.toprealErighttext h2{
    font-size: 30px;
    font-weight: 700;
    color: #000000;
    font-family: "Montserrat",sans-serif  !important ;

    max-width: 500px;
}

.ToprealEimgbox a {
    border-bottom: 1px solid black;
    font-size: 16px;
    font-weight: 600;
}

.categorysectionthree .toprealErighttext p {
    font-size: 16px;
    font-weight: 300;
    color: #000000;
    font-family: "Montserrat",sans-serif  !important ;
max-width: 660px;
line-height: 20px;
font-weight: 500;
}

.categorysectionfour .expertheading{
    color: #000000;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 3px;
    font-family: "Montserrat",sans-serif  !important ;

}
.categorysectionthree .psi {
    padding: 10px 10px;
}
.Expertsbutton {
    border-radius: 0px 0px 18px 18px;
    background-color: #BCF5BC;
    padding: 17px 0 0 0;
}
.categorysectionfour .Expertstop img {
    display: flex;
    justify-content: center;
}
.categorysectionfour .Expertstext h5 {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    margin: 45px 0 10px 0;
    font-family: "Montserrat",sans-serif  !important ;

}
.categorysectionfour .Expertstext p {
    margin: 0 10px 9px 11px ;
    font-size: 10.5px;
    color: #000;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;min-height: 100px; font-family: 'microsoft_sans_serifregular' !important;
}
.Expertsitmes {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    box-shadow: 2.91px 2.91px 31.96px 0px #00000040;
    text-align: center;
    border-radius: 18px;
    position: relative;
    cursor: pointer;
}
.Expertstop {
    position: absolute;
    top: -61px;
    right: 0;
    left: 0;
    margin: auto;
    border-radius: 200px;
    width: 80px;
    height: 80px;
}
.Expertsbuttoni {
    display: flex;
    flex-direction: column;
    padding: 17px 15px 17px 15px;
}
.Expertstext img {
    margin: auto;
    padding-bottom: 19px;
    width: 64%;
}

.Expertmain{
    overflow: hidden;
    position: relative;
}
.Expertmain .slick-slide.slick-active {
    padding: 22px;
    margin: 100px 0;
}
.Expertmain .slick-current {
    transform: none;
}
.bestexperts .expertinnsearchname{
    padding: 0;
    background-color: #FFFFFF;
}
.categorysectionfour h5.expertsub1 {
    color: #000;
    font-weight: 500;
    line-height: 20px;
    font-size: 16px;
    font-family: "Montserrat",sans-serif  !important ;
margin-bottom: 0px;
}
.bestdeals{
    padding-bottom: 40px;
}
.categorysectionfour.bestdealsbox {
    max-width: 1394px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.categorysectionfour .expertsub2 {
    color: #000;
    font-weight: 500;
    line-height: 20px;
    font-size: 16px;
    font-family: "Montserrat", sans-serif !important;
    margin-bottom: 0px;
}
.categorysectionfive{
    background-image: url("../../../assets/sharedImages/rimibofferbanner.png");
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 30px 0 30px 0;
    margin-bottom: 40px;
}
.realEquestiontext{
    text-align: center;
}
.categorysectionfive .realEquestiontext h4 {
    font-size: 30px;
    color: #FFFFFF;
    font-weight: 700;
    line-height: 37px;
    margin-bottom: 0;
    font-family: "Montserrat",sans-serif  !important ;

}
.categorysectionfive .realEquestiontext h2 {
    font-size: 30px;
    color: #FFFFFF;
    font-weight: 700;
    line-height: 37px;
    margin-bottom: 0;
    font-family: "Montserrat",sans-serif  !important ;

}
.realEquestionbanner.realEquestiontext span{
    font-size: 40px;
    color: #FFFFFF;
    font-weight: 800;
    text-transform: uppercase;
    font-family: "Montserrat",sans-serif  !important ;

}
.categorysectionfive .realEquestiontext h3 {
    color: #FFFFFF;
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 40px;
    font-family: "Montserrat",sans-serif  !important ;

}
.realEmargin{
    margin: 0 auto;
}
.categorysectionsix {
    background-image: url("../../../assets//realEstateimages/blogsbg.png");
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin-bottom: 40px;
    padding: 20px 0 20px 0;
}
.categorysectionsix  .realEbloginner h2 {
    color: #000000;
    font-size: 30px;
    font-weight: 700;
}

.categorysectionsix  .realEbloglog{
    margin: 0 auto;
}
.latestbutton.margintop{
    margin-top: 20px;
}
.categorysectionsix  .realEbloginner p.realEblogtext {
    color: #000000;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    font-family: "Montserrat",sans-serif  !important ;
}
.categorysectionsix  .space-remove {
    margin-top: -100px;
}
.applynowbuttoncomm:hover {
    color: white;
}
.categorysectionsix  .bloginnertext {
    color:#2A2A2A;
    font-family: "Montserrat",sans-serif  !important ;
    min-height: 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 5px;
    margin-top: 55px;
}
section.categorysectionsix.blogdetailsexpertppv .latestbutton {
    margin-top: -20px;
}
.categorysectionsix  .bloginnerdate {
    color:  #2A2A2A;
    text-align: center;
    font-family: 'microsoft_sans_serifregular' !important;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
}
.categorysectionsix  .minheightrealestate{
    margin-bottom: 141px;
}
.categorysectionsix  .blogsmain {
    min-height: 155px;
    max-width: 630px;
    border-radius: 10px;
    position: relative;
    border: 1px solid #FFF;
    background: #FFF;
    padding: 0 29px 17px 29px;
    box-shadow: 3px 3px 33px 0px rgba(0, 0, 0, 0.25);
}
.categorysectionsix  .realEbloginner {
    margin-bottom: 40px;
}
.categorysectionsix .minheightrealestate{
    margin: 40px 0;
    display: flex;
    justify-content: center;
}
.categorysectionsix  .realEbloginner p {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
line-height: 20px;
    margin: 0 auto;
    font-family: "Montserrat",sans-serif  !important ;

}
.blogsmain{
    position: relative;
}
.displaynone{display: none;}
.categorysectionfour .Expertstop img {
    border-radius: 80px;
    width: 80px;
    height: 80px;
    border: 4px solid #BCF5BC;
}
.blogdetailsexpertppv.categorysectionsix  .bloginnerimage img {
    height: 120px;
    object-fit: cover;
    border-radius: 200px;
    width: 120px;
    border: 5px solid #fff;
    position: absolute;
    top: -68px;
    margin: auto;
    left: 0;
    right: 0;
}
.blogdetailsexpertppv.categorysectionsix  .bloginnerimage {
    margin: auto;
    text-align: center;
}
.blogdetailsexpertppv.categorysectionsix  .bloginner.mb-3.blogsmain {
    margin: 90px 0;
    box-shadow: 3px 3px 33px 0px #00000040;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    padding: 60px 10px 10px;
}
.realEquestiontext span {
    font-weight: 800;
}
.categorysectiontwo .offer-insurence-inner {
    margin-bottom: 20px;
}
.newrealestateui .slick-prev, .newrealestateui .slick-next, .categorysectionthree .slick-next,  .categorysectionthree .slick-prev{
    z-index: 99;
    top: 0px;
    bottom: 0px;
    margin: auto;
}
.categorysectionone .slick-prev, .categorysectiontwo .slick-prev, .categorysectionfour .slick-prev, .categorysectionthree .slick-prev {
    left: -30px;
    width: 40px;
    height: 40px;
}
.categorysectionthree .slick-prev{
    left:-40px;
}
.categorysectionone .slick-next, .categorysectiontwo .slick-next, .categorysectionfour .slick-next {
    right: -30px;
    width: 40px;
    height: 40px;
}
.categorysectionfour  .latestbutton.marginbottom{
    margin-top: 15px;
}
.categorysectionfour  .p.bestdealsbox.bestexperts {
    margin-top: -35px;
}
.categorysectionthree .slick-next{
    right: -40px;
    transform: none;
    width: 40px;
    height: 40px;
}
.categorysectionone .slick-prev:before, .categorysectionone .slick-next:before, .categorysectiontwo .slick-prev:before, .categorysectiontwo .slick-next:before, .categorysectionthree .slick-prev:before, .categorysectionthree .slick-next:before, .categorysectionfour .slick-prev:before, .categorysectionfour .slick-next:before    {
    font-size: 30px; color: #89df89;}
    .categorysectionone .slick-next:before, .categorysectiontwo .slick-next:before, .categorysectionthree .slick-next:before, .categorysectionfour .slick-next:before{
        content: "";
        background: url(../../../assets/homeimages/slicknext.svg);
        width: 40px;
        height: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        rotate: 0deg;
        border-radius: 3px;
        display: block;
        margin-top: 0px;
        left: 1px;
        top: 0px;
         
    }
    .categorysectionone .slick-prev:before, .categorysectiontwo .slick-prev:before, .categorysectionthree .slick-prev:before, .categorysectionfour .slick-prev:before{
        content: "";
	background: url(../../../assets/homeimages/slicknext.svg);
	width: 40px;
	height: 40px;
	background-size: contain;
	background-repeat: no-repeat;
	transform: rotate(180deg);
	display: block;
    margin-top: 0px;
	 top: 0px;
    }
.offer-inner {
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, .25);
    border-radius: 10px;
    padding: 10px;
}
.offer-image img {
    margin: auto;
}
button.viewallbuttoncomm:hover, a.toprealEbutton:hover, a.viewallbuttoncomm:hover {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #FFF;
    background: #EF000B;
    color: #FFF !important;
    font-family: "Roboto Flex";
 
    box-shadow: 2px 2px 10px 0px #EF000B;
}
.newrealestateui .offersss .posbuttontf.d-flex a:nth-child(2):hover{background-color: #D2FFD2;}
.categorybanner{text-align: center;}
.categorybanner {position: relative; }
 .categorybannertext {
    
    text-align: left;
    padding-top: 40px;
}
.offer-inner .categotyinpop {
    padding: 9px 11px;
}

.offer-inner .categotyinpopfl {
    justify-content: space-between;
}

.offer-inner .categotyinpopfl .pscat {
    color: #000;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 18px;
    position: relative;
    font-family: 'microsoft_sans_serifregular';
}
.categorysectionone .list-des.offerdes {
    color: #747474;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    min-height: 30px;
    background: transparent;
    font-family: 'microsoft_sans_serifregular';
    padding: 0;
    margin: 0;
    border-radius: 0;
    margin-top: 5px;
}
.categorysectionone .offersss .posbuttontf.d-flex{justify-content: left;}
.categorysectionone .offer-inner .categotyinpopfl .pscat:after{
    content: '';
    position: absolute;
    width: 68px;
    height: 1px;
    background: #00831D;
    left:0;
    bottom: -7px;
}
.categorysectionone .offer-inner .categotyinpopfl .pscat:nth-child(2):after{width: 150px;}
.categorysectionone .offersss .posbuttontf.d-flex{ margin: 0; padding: 0; margin-top: 15px;}
.categorysectionone .offersss .posbuttontf.d-flex a:first-child{margin: 0;}
 .categorysectiontwo .colinstititeinnername {
    color: #000;
    text-align: center;
    min-height: 48px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;  
}

.categorysectionfive .realEbuttonmain img {
    width: 53px;
    margin: 0 0 0 10px;
}
.list-inner{background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;}

.categorysectionfive .realEbuttonmain p.realEbutton{
    font-size: 50px;
    font-family: "Roboto Flex", sans-serif !important;
    color: white;
    line-height: 58.59px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
}
.categorysectionfive .realEbuttonmain:hover {
    cursor: pointer;
    background-color: red;
    box-shadow: 0px 0px 10px 0px #EF000B;
    border: 2px solid #FFFFFF;
    color: white;
}

.categorysectionfive .realestatefont .borderbottom img{
    width: 72px;
}

.categorysectionthree .slick-dots .slick-active {
    width: 20px;
    height: 20px;
}
.categorysectionthree .slick-dots li {
    width: 14px;
    height: 14px;
}
.institutelogosection{
    padding-left: 0;
    max-width: 640px;
    margin: auto;}
    .offersss .col-lg-12.col-md-12.col-sm-12.col-xs-12  {
        padding: 0 10px;
    }
.categorysectionthree .slick-dots li {
    fill: #FFF;
    stroke-width: 0.76px;
    stroke: #EDE1E1;
    box-shadow: 0px 3.04px 3.04px 0px rgba(0, 0, 0, 0.25) inset;
    filter: drop-shadow(0px 3.04px 3.04px rgba(0, 0, 0, 0.25));
}
 .categorysectionthree .slick-prev {
    left: -40px;
    transform: none;
}
.categorysectionthree ul.slick-dots {
    bottom: -24px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.categorysectionthree .showallbtn{margin-top: 40px;}

 .modalspecial.productdetail-modal .close_btn{
    width: 52px;
    height: 52px;
    border-radius: 52px;
    background: #00831D;
    line-height: 51px;
    left: auto;
    right: 13px;
    top: 13px;
    position: absolute;
    text-align: center;
    cursor: pointer;
    border: none;

}
.modalspecial.productdetail-modal .back_btn {
    width: 41.91px;
    height: 41.91px;
    border-radius: 3.81px;
    background: #00831D;
    text-align: center;
    line-height: 41px;
    margin-left: 31px;
}

.modalspecial.productdetail-modal >div{    width: 100%;
    border-radius: 40px;
    background: #FFF;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.40);
    max-width: 1114px;}
 .modalspecial.productdetail-modal  h1.h2_style.font-weight-bold
{
    color: #00831D;

font-size: 35.57px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.modalspecial.productdetail-modal  h5.h5_style.font-weight-bold{
    color: #00831D;

font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.modalspecial.productdetail-modal  h5.h5_style{
    color: #000;

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
 .modalspecial.productdetail-modal .detailedview {
    background: linear-gradient(181deg, #BAFFBA 0.71%, #C6FFC6 99.29%);
    margin-top: 15px;
    border-radius: 16.168px;
    padding: 20px 10px 20px 0px;
    box-shadow: none;
}
.modalspecial.productdetail-modal  section.listofoffermainsection{background: transparent;}
.modalspecial.productdetail-modal .detailedofferheading  {border-radius: 0px 28.699px 28.699px 0px;
    background: #FFF;
    color: #000;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;}
    .modalspecial.productdetail-modal  .detailedtext p{color: #000;
        
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    padding: 5px 30px;}
        .modalspecial.productdetail-modal  .detailedimage img {
            width: 700px;
            margin: auto;
            border: 11px solid #fff;
        }
        
        .modalspecial.productdetail-modal  .detailedimage {
            text-align: center;
        }
        .modalspecial.productdetail-modal  .pname{
            border-radius: 0px 37.591px 37.591px 0px;
background: #FFF;
padding: 10px;
margin-bottom: 20px;
width: auto;
color: #000;

font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
        }
        .modalspecial.productdetail-modal   .grid-2 {
            grid-template-columns: 1fr;
            grid-gap: 0;
            width: 100%;
            position: relative;
        }
        
        .modalspecial.productdetail-modal .detailedtext .ctaonebutton{margin-left: 30px; background: #00831D;}
        .modalspecial.productdetail-modal .category-4  .close_btn, .modalspecial.productdetail-modal .category-4 .back_btn{background: #2280B8; cursor: pointer;}
        .modalspecial.productdetail-modal .category-4 h1.h2_style.font-weight-bold, .modalspecial.productdetail-modal .category-4 h5.h5_style.font-weight-bold{color: #000000;}
        .modalspecial.productdetail-modal .category-4 .detailedview{background: #D8EDFF;}
        .modalspecial.productdetail-modal .category-4 .detailedtext .ctaonebutton{background:#2280B8 ; margin-left: 30px;}

        .modalspecial.productdetail-modal .category-2  .close_btn, .modalspecial.productdetail-modal .category-2 .back_btn{background: #86357A;}
        .modalspecial.productdetail-modal .category-2 h1.h2_style.font-weight-bold, .modalspecial.productdetail-modal .category-2 h5.h5_style.font-weight-bold{color: #000000;}
        .modalspecial.productdetail-modal .category-2 .detailedview{background: #FFE1FB;}
        .modalspecial.productdetail-modal .category-2 .detailedtext .ctaonebutton{background:#86357A ; margin-left: 30px;}

        
        .modalspecial.productdetail-modal .category-1  .close_btn, .modalspecial.productdetail-modal .category-1 .back_btn{background: #FF4C00;}
        .modalspecial.productdetail-modal .category-1 h1.h2_style.font-weight-bold, .modalspecial.productdetail-modal .category-1 h5.h5_style.font-weight-bold{color: #000000;}
        .modalspecial.productdetail-modal .category-1 .detailedview{background: #FFE3D7;}
        .modalspecial.productdetail-modal .category-1 .detailedtext .ctaonebutton{background:#FF4C00 ; margin-left: 30px;}

        .modalspecial.productdetail-modal .category-3  .close_btn, .modalspecial.productdetail-modal .category-3 .back_btn{background: #2f596f;}
        .modalspecial.productdetail-modal .category-3 h1.h2_style.font-weight-bold, .modalspecial.productdetail-modal .category-3 h5.h5_style.font-weight-bold{color: #000000;}
        .modalspecial.productdetail-modal .category-3 .detailedview{background: #D8DEE9;}
        .modalspecial.productdetail-modal .category-3 .detailedtext .ctaonebutton{background:#2f596f ; margin-left: 30px;}
        .modalspecial.productdetail-modal .listofoffermainsection .sidetext{padding-left: 30px;}
        .modalspecial.productdetail-modal .listofoffermainsection .sidetext p{
            color: #000;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
        }
        .modalspecial.productdetail-modal .listofoffermainsection  button.gototop{
            position: absolute; 
            right: 183px !important;
            bottom: 43px !important;
            background: rgba(70, 70, 70, 0.40) !important;
            width: 51.843px !important;
            height: 51.843px !important;
            border-radius: 5px !important;

        }
        .offersss .slick-slide .pscat span img {
            display: inline-block !important;
            width: 18px !important;
            height: auto !important;
        }

        .asdsd{
            background-image: url("../../../assets/realEstateimages/institutionsbg.png");
        }

        .realEstate-bgimg{
            background-image: url("../../../assets/realEstateimages/Productsbackground.png");
        }
        .categorysectiontwo  .categoryproductsliderinner{
            background: #fff;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, .25);
            border-radius: 10px;
            padding: 10px 10px 20px 10px;
            margin: 0 10px;
        }
        .categorysectiontwo .categoryproductsliderinnerimage{
            border: none;
    filter: none;
    background: #fff;
    margin: 0;
        }