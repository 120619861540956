.mainaccount {
  background: #FFFFFF;
  border-radius: 20px;
}

.subtitles {
  font-size: 28px;
  font-weight: 500;
  color: #2A2A2A;
  margin: 30px 40px;
}

.profilelogo .from-input img {
  object-fit: cover;
  border: 1px solid #EAEAEA;
  box-shadow: 6px 6px 16px 0px #00000040;
  border: 1px;
  border-radius: 180px;
}


.profilelogo .from-input {
  display: flex;
  background-color: #E4EFFF;
  border-radius: 10px 10px 10px 10px;
  align-items: center;
}

.from-input {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #6d6d6d;
}


.from-input {
  margin-bottom: 20px;
}


.from-input {
  margin-bottom: 20px;
}

.institutedetails .logoform .form-fieldinner img {
  object-fit: cover;
  height: 200px;
  border-radius: 100px;
  width: 200px;
  box-shadow: 6px 6px 16px 0px #00000040;
  border: 1px solid #EAEAEA;
  margin: -6px;
}

.myaccountsection {
  background: #E4EFFF;
  box-shadow: 5px 5px 25px rgba(6, 40, 61, 0.2);
  border-radius: 10px;
  padding: 20px;
  background: none;
  box-shadow: none;
}

.logoform .form-fieldinner {
  display: flex;
  background-color: #E4EFFF;
  border-radius: 100px 10px 10px 100px;
  align-items: center;
  margin: 6px;
}

.img-uplod-section {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

 .uploaddetails-section   {
  padding-left:50px;
}

section.uploaddetails-section p {
  color: #6D6D6D;
  font-size: 16px;
  font-weight: 400;
}

.file-name {
  color: #303030;
  font-size: 20px;  
  font-weight: 500;
}

section label.change_button {
  background-color: #2280B8;
  padding: 16px 22px;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
}

section label.change_button:hover {
  cursor: pointer;
  background-color: transparent;
  border: 1.5px solid #2280B8;
  color: #2280B8;
}

button.delete-button:hover {
  color: #FFFFFF;
  cursor: pointer;
  background-color: #2280B8;
}

button.delete-button {
  padding: 11px 45px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  border: 1.5px solid #2280B8;
  color: #2280B8;
}

button.delete-button[disabled] {
  background-color: transparent;
  border: 1.5px solid #2280B8;
  color: #2280B8;
}

.img-uplod-section .btn_upload {
  padding: 17px 19px 8px 0;
}

.personal-details, .occupation {
  background-color: #E4EFFF;
}

.personal-details .myaccountsectionheading h3 {
  font-size: 28px;
  color: #2A2A2A;
  font-weight: 500;
}

.occupation .myaccountsectionheading h3 {
  font-size: 28px;
  color: #2A2A2A;
  font-weight: 500;
}

.personal-details button.edit-button {
  background-color: #2280B8;
  padding: 11px 45px;
  border: none;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
}

.personal-details .form-fieldinner label {
  font-weight: 500;
  font-size: 14px;
  color: #6d6d6d;
}

.occupation .form-fieldinner label {
  font-weight: 500;
  font-size: 14px;
  color: #6d6d6d;
}

.personal-details .fieldans {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #303030;
  background-color: white;
  padding: 10px;
  border-radius: 3px;
}

.occupation .fieldans {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #303030;
  background-color: white;
  padding: 10px;
  border-radius: 3px;
}

.occupation button.edit-button {
  background-color: #2280B8;
  padding: 11px 45px;
  border: none;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 500;
}

.occupation .intersr-name {
  display: flex;
  align-items: center;
}

.file-heading {
  color: #6D6D6D;
  font-size: 16px;
  font-weight: 400;
  
  margin-top: 15px;
}

.logo-text {
  font-size: 28px;
  font-weight: 500;
  color: #2A2A2A;
  margin-top: 10px;
  margin-bottom: 60px;
}
 
section.entiredashboard.maincustomerdash.institute_dashboard   .myaccountsection.institutedetails {
  background: #FFF;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
  margin-top: 40px;
}
section.entiredashboard.maincustomerdash.institute_dashboard  .myaccountsection.institutedetails h3.h3_style {
  color: #2A2A2A;
  margin-bottom: 30px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}