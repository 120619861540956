.letest-inner h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color:   #004710; 
    margin-bottom: 10px;
    font-family: "Montserrat", sans-serif !important;
}
 .letest-inner h6 {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    text-align: center;
	height: 3px;
    margin-bottom: 20px; 
    font-family: "Montserrat", sans-serif !important;
}
.mainproducts{
	padding: 0px 0px;
}
@media (max-width:767px){
    .letest-inner h2{font-size: 18px;}
    .letest-inner h6{margin-bottom: 0; height:auto; font-size: 12px; font-family: "SF Pro Display" !important; margin-bottom: 0 !important; }
}