.myexpertnavnewdesign{
    margin-top: 20px;
    padding-left: 100px;
}
.myexpertnavnewdesign ul{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
    padding: 0;
    margin: 20px 0;
    width: 230px;
    height: max-content;
}
.myexpertnavnewdesign ul li{
color: #000;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding: 30px;
position: relative; 
width: 230px;
cursor: pointer;
}
.myexpertnavnewdesign ul li:after{
    position: absolute;
    left: 0;
    right: 0; 
    bottom: 0; 
    margin: auto;
    background: #D5D5D5;
    width: 1px;
    height: 2px;
    width: 80%;
    content: "";
}
.myexpertnavnewdesign ul li:last-child::after{
    display: none;
}
.latestdeailsexpertppvspecialize {

	display: none;
}

.latestdeailsexpertppvspecialize.active {
	display: block;
}
.latestdeailsexpertppvspecialize {
    margin-left: 20px;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);

}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .myexpertnavnewdesign{padding-left: 0;}
}
@media (max-width:767px){
    .myexpertnavnewdesign ul, .myexpertnavnewdesign ul li{
        width: 100%;
    }
    .myexpertnavnewdesign{padding-left: 0;}
    .myexpertnavnewdesign ul{
        display: flex;
        box-shadow: none;
        margin: 0px 0 20px 0;
        justify-content: center;
         border-top:5px solid #E1E6ED;
         border-bottom:5px solid #E1E6ED;
         padding:  0;
         border-radius: 0;
    }
    .myexpertnavnewdesign ul li{
        padding: 5px;
        text-align: center;
        font-family: "Fjalla One";
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 25%;
        height: 75px;
        display: flex        ;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
    }
     .expicontab img{
        height: 15px !important;
        width: auto !important;
        margin-bottom: 3px;
    }
   
}