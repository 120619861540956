@media (max-width: 2560px) {
    .icon-down {
      display: none;
    }
    .choose-main-sec {
      display: none;
    }
  }
  @media   (max-width: 1440px){
    .user-round-img {
      top: 10%;
        width: 43%;
    }
}

  @media (max-width: 1339px) {
    div#howitworkarrow::before {
      top: 23%;
      left: -65px;
    }
    .user-round-img{
      top: 0%;
      right: -6%;
    }
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {

    section.herosection.heroasktheexpert.heronewaskexpert .heroleftpanel{left: 0; bottom: 80px;}
    p.subh1 {
      font-family: "Montserrat", sans-serif;
      font-size: 11px;
      font-weight: 500;
      line-height: 13px;
    }
    .choose.categoryonesettwo {
      width: 148px;    height: 131px;
    }
    .input-container.form-group.input-group-reg {
      max-width: 335px;
    }
    #newfaq .col-lg-3.col-12 {
      width: 50%;
  }
    p.text-start {
      margin: 5px 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
  }
  b.ask-bold-text{ font-size: 14px;}
  .askbutton p {
    font-family: "Roboto Flex", sans-serif !important;
    font-size: 22px;
    font-weight: 700;
    line-height: 60px;
    text-align: left;
    color: #fff;
    margin-bottom: 0;
    margin-right: 11px;
}
  .askbutton>img {
    width: 25px;
}
section.herosection.heroasktheexpert.heronewaskexpert .askbutton {
  margin: 0;
  height: 44px;
  width: 240px;
}
.heroleftbutton {
  margin-top: 10px;
}
section.herosection.heroasktheexpert.heronewaskexpert h2.heroheading {
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}
.herosection.heronewaskexpert a.ctaonebutton {
  width: 150px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #00801D;
  color: #FFF;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.2px;
  padding: 0;
  border: 3px solid transparent;
  line-height: 21px;
}
.herosection.heronewaskexpert a.ctatwobutton {
  border: none;
  width: 100px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #FFF;
  color: #00831D;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding: 1px 0;
  line-height: 42px;
  letter-spacing: 1.2px;
  border: 5px solid transparent;
  line-height: 15px;
  margin-top: 0;
  margin-left: 10px;
}
.Howitworks .howitworkinner {
  margin-top: 60px;
  z-index: 1;
  position: relative;
}
  }
  @media (max-width: 767px) {
    .faqmobile .accordion-body p{
      font-size: 12px;
      margin-bottom: 0;
      font-family: "SF Pro Display" !important;
      line-height: 1.5;
    }
    .container-rimib a.registernowbutton{
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 100px;
      letter-spacing: 0px;
      border-width: 1px;
      border-radius: 2px;font-family: "Roboto Flex"; letter-spacing: 1.2px;
    }
    a.proceedbtn, button.proceedbtn{ font-size: 12px;
      font-weight: 500;
      line-height: 1;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 25px;
      border-width: 1px;
      border-radius: 2px;font-family: "Roboto Flex"; letter-spacing: 1.2px;}
      .mainexpforminner.register-fill .proceed-main-btn.mb-3.text-center {
        display: flex
    ;
        justify-content: center;
    }
    .clipartps img {
      width: 50%;    margin-top: 20px;
  }
  
  .clipartps {
      margin-right: 0;
      text-align: right;
  }
   .container-rimib .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth .icontwo
    {
      margin-bottom: 0;
    }
    .categorytwosetfirsttest .categoryonesettwo:hover {
      cursor: pointer;
      background-color: transparent;
      border: 3px solid #cbeaff;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth.active:nth-child(2)
  {    border: 3px solid #ffe8a4;}
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth.active:nth-child(3) {
    background: transparent;
    border: 3px solid #ffe0e0;
}
.asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth.active:nth-child(4) {
  background: transparent;
  border: 3px solid #c0ffc7;
}
.asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth.active:nth-child(5) {
  background: transparent;
  border: 3px solid #daf0ff;
}
.asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth.active {
  background: transparent;
  border: 3px solid #cbeaff;
}
.categorytwosetfirsttest .categoryonesetwidth
{
  border:3px solid transparent;
}
    span.psnew{
      color: #FFF;

font-family: "SF Pro Display" !important;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
    .catborderbottom .slick-prev {
      transform: translate(0, 0);
      top: auto;
      bottom: -37px;
      left: 40%;
      width: 30px;
      height: 30px;
      right: auto;
  }
  .catborderbottom .slick-next {
    transform: translate(0, 0);
    top: auto;
    bottom: -37px;
    right:  40%;
    width: 30px;
    height: 30px;
    left: auto;
}
.container-rimib .register-fill span.input-group-text img{
  margin: 8px;
    filter: brightness(0) saturate(100%) invert(26%) sepia(69%) saturate(1655%) hue-rotate(90deg) brightness(90%) contrast(104%);
    width: 17px;
}
.catborderbottom .slick-prev:before {
  content: "";
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(177deg);
}
.catborderbottom .slick-next:before {
  content: "";
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(0deg);
  right: auto !important;
  position: relative !important;
}
    /* section.herosection.heroasktheexpert.heronewaskexpert .askbutton{display: none;} */
    .input-container.form-group.input-group-reg.col-md-6.mb-5, .input-container.form-group.input-group-reg:nth-child(2), .input-container.form-group.input-group-reg.col-md-6.mb-5 {
      margin-left: 0;
      margin-right: 0;

  }
    .question-text h2{margin-bottom: 0;}
    .asktheexpertconfirmpopup.popupouter .popup {
      padding: 15px;
  }
  .heroleftpanel .askbutton {
    max-width: 200px;
    padding: 0;
    margin: 0 auto;
    height: 40px;
    margin-top: 5px;
}
.heroleftpanel .askbutton p {margin-bottom: 0; line-height: 1; font-size: 18px; margin: 0; margin-bottom:0; margin-right: 5px;}
  .asktheexpertconfirmpopup .cliparttext h2 {
    font-size: 27px;
    color: #ffffff;
    font-weight: 600;
    max-width: 500px;
    margin-bottom: 10px;
    width: 60%;
}

.cliparttextboxinner {
  border: 6px solid #fff;
  position: relative;
}
.clipartbtn.mt-4 {
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.cliparttextboxinner img {
  width: 110px;
}
.clipart {
  position: absolute;
    top: -400px;
    right: 0;
}
.clipart img {
  height: auto;
  width: 29%;
}
.cliparttext .clipartmain {
  margin-top: 25px;
}
.asktheexpertconfirmpopup.popupouter .cliparttext .clipartmain .cliparttextbox p .cliparttextboxouter p {
  font-size: 13px;
  
  font-weight: 300;
  
}
.asktheexpertconfirmpopup .cliparttext .clipartmain .cliparttextbox.cliparttextboxtwo p {
  font-size: 10px;
  font-weight: 600;
  line-height: 12.19px;
  
}
.cliparttextboxtwo .iconbase {
  width: 50px;
  height: 50px;
}

.cliparttextboxtwo{width: 110px; height: 110px; border-radius: 8px; flex-wrap: wrap; box-shadow: none;}
a.clipartbutton {
  color: #000;
  background-color: #a0ffa0;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 10px;
  margin-right: 0;
  text-decoration: none;
  border: 1px solid transparent;
  text-align: center;
  height: auto;
  line-height: 1.3;
  border-radius: 1px;
  letter-spacing: 0;
}
.modalspecial.asktheexpertconfirmpopup.popupouter a.clipartbutton {width: 100px;
  display: flex  ;
          justify-content: center;
          align-items: center;}
          a.signuptext.text-decoration-underline {
            text-decoration: underline !important;
        }
.modalspecial.asktheexpertconfirmpopup.popupouter a.clipartbutton2 {margin-top:0;
  width: 150px; margin-left: 0px; margin-top: 10px;       display: flex;
          justify-content: center;
          align-items: center;}
a.clipartbutton2 {
  color: #000000;
  background-color: #fff;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 3px;
  border-radius: 6.68px;
  text-decoration: none;
  border: 1px solid transparent;
  text-align: center;
  line-height: 1.3;
  height: auto;
  border-radius: 1px;
  margin-top: 10px;
  letter-spacing: 0;
}
.clipartmain .cliparttextboxouter {
  margin-left: 25px;
}
    .faqsection h2.frequently-text{font-family: Montserrat;
      font-size: 17px;
      font-weight: 600;
      line-height: 20.72px;
      text-align: left;
      }
      .faqsection h2.frequently-text{margin-bottom: 10px !important;font-weight: bold;
        text-align: center;
    }
      .asktheexpertcategorysectioninnerlinenew .textarecs{margin-top: 20px !important;}
      .faqsection h2.frequently-text span{ font-size: 17px;
       
        line-height: 20.72px;
        text-align: left;}
        section.faqsection{margin-bottom: 10px;}
    .resrrrow {
      margin: 0;
    }
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew  .categoryfourset
{padding-top: 0px;}    
section.herosection.heroasktheexpert.heronewaskexpert h2.heroheading{line-height: 1; display: inline-block; font-size: 18px; margin-bottom: 10px;}
.heroleftbutton {
  margin-top: 0px;
}
.Howitworks {
  margin-bottom: 40px;
  margin-top: 0;
}
.Howitworks .howitworkinner {
  margin-top: 0;

  z-index: 1;
        position: relative;
        max-width: 75%;
        margin: auto;
}
    .herosection.heronewaskexpert .heroleftpanel h2{text-align: left;}
    .herosection.heronewaskexpert a.ctaonebutton {
      font-size: 12px;
        line-height: 1.4;
        width: 175px;
        height: 30px;
        border-radius: 2px;
    }
    .herosection.heronewaskexpert a.ctatwobutton {
        font-size: 12px;line-height: 1.4; height: auto;        padding: 10px 20px;color: #658112;

      }
      .heroleftpanel h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 27px;
        color: #06283d;
        text-align: left;
    }
    .heroleftpanel h2.heroheading2{font-family: Montserrat;
        font-size: 42.11px;
        font-weight: 800;
        line-height: 42.11px;
        text-align: left;
        color: #4C6501;
}
    section.herosection.heroasktheexpert.heronewaskexpert {

      border-radius: 0;
    }
    .input-question textarea {
      height: 150px;
    }
    section.herosection.heroasktheexpert.heronewaskexpert s h2.heroheading2 {
      max-width: 270px;
    }
    .register-main-text h2,
    .register-main-text span {
      font-size: 17px;
    }
    .register-main-text p,
    .register-main-text h3,
    p.question-right-ptext,
    p.question-right-ptext span,
    p.question-right-text-too {
      font-size: 11px;
      text-align: center;
    }
    .heroleftpanel {
      padding-bottom: 50px;
      max-width: 50%;
      /* text-align: center; */
    }
    section#ask {
      margin-top: 18px;
    }
    .needs-validation .col-md-6.mb-3.p-0 {
      margin-bottom: 0 !important;
    }
    .main-question {
      margin: 5px 0;
      height: 50px;
      padding-left: 18px;
    }
    .input-margin-r {
      margin-right: 0;
    }
    .input-group-reg.mb-5.input-margin-r,
    .input-group-reg.mb-5.input-margin {
      margin-bottom: 10px !important;
    }
    section.herosection.heroasktheexpert.heronewaskexpert
      .heroleftbutton.d-flex.mt-4 {
      flex-wrap: wrap;
    }
    section.herosection.heroasktheexpert.heronewaskexpert .ctatwobutton {
      margin-left: 0px;
      margin-top: 0px;
      width: 75px;
      height: 30px;
      padding: 0px;
      border-radius: 2px;
      margin-top: 10px;
      line-height: 28px;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
     color: #000;
     font-weight: 500;
  }
  .choose-main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 0;
    flex-wrap: wrap;
    border: 5px solid #e1e6ed;
    padding: 0px 0px;
}
.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img, .asktheexpertcategorysectioninnerlinenew .categoryonesettwo .icontwo img{
  padding: 0 1px;
}
  .heroleftbutton.d-flex{display: block !important;}
    .catstart.clearfix.ml-4.pl-4.mr-5.pr-5 {
      padding: 0 !important;
      margin: 0 !important;
    }
    section.herosection.heroasktheexpert.heronewaskexpert .heroleftpanel {
      width: 100%;
      position: absolute;
      bottom: auto;
      top:15px;
      left: 10px;
      right: auto;
      text-align: left;
      padding: 0;
      max-width: 80%;
  }
    section.herosection.heroasktheexpert.heronewaskexpert {
      /* background: url(../../../assets/askTheExpertImages/ask_the_expert_banner_mobile_view2.svg);
      padding: 20px 0 0 0;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 367px;
      background-position: center center;        border: 1px solid #fff;
      border-radius: 30px; */
  
    }
    
    .queastio-main {
      padding-left: 0;
      margin-bottom: 0;
    }
    .inner-askexpertnewlayout .register-fill .form-group .form-control{
      border-radius: 5px; border-width: 1px;
    }
    .inner-askexpertnewlayout .input-container .placeholder {
      top: 15px;
      left: 13px;
  }
    .choose-main-sec {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 10px;
    }
    .choose.categoryonesettwo{height: 50px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img{height: 64px;
    }
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .iconone img{        height: 40px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo{width: 60px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesettwo{width: 72px; height: 72px; padding: 5px 0;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth .iconone {
        background: #fff;
        height: 40px;
        width: 40px;
        margin-bottom: 2px;
    }
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth .icontwo{
        height: 40px;
        width: 40px;
        margin-bottom: 2px;  
    }
    .textarecs .input-question textarea.input.form-control.textareaform{        height: 130px;
      border-radius: 5px;}
      div#askQus{box-shadow: none; margin-top: 0;}
    .input-container.form-group.input-group-reg.col-md-6.mb-5 {
        margin-bottom: 20px !important;
    }
    .register-main-text h2 {
      margin-top: 20px;
  }
    .proceed-main-btn.mb-3.text-end {
        margin-top: 20px;
    }
    .register-main-text h2,
    .question-text-right h2,
    .question-text h2,
    .question-text h2 span,
    .question-text-right span {
      text-align: center;
      font-size: 17px;
    }
    .categoryonesettwo {
      padding: 20px;
    }
    .choose-box-sec {
      box-shadow: 3px 3px 33px 0px #00000040;
      padding: 4px;
      min-width: 56px;
    }
    .choose-box-sec:hover {
      box-shadow: 0px 4px 4px 0px #ff7000;
    }
    .choose-sec {
      margin: 2px;
    }
    img.rimibimg {
      height: 54px;
    }
    .marginres {
      margin-bottom: 0;
    }
  
    .user-round-img {
      position: absolute;
      right: 14%;
      bottom: 0;
      width: 43%;
    }
    .d-flex.latestratesflex .herorightpanel,
    .heroleftpanel h3,
    .heroleftpanel h4,
    .heroleftpanel h5,
    .heroleftpanel h6 {
      text-align: left !important;
    }
    .howitworkinner .howitwork-box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .howitwork-box p {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.018em;
      text-align: left;
      max-width: 100%;
      margin: 0 0 0 25px;
      color: #000;
      margin-top: 0 !important;
      width: 70%;
    }
    .howitworkbox-inner {
      width: 70px;
  }
  .popupbutton.text-center.d-flex.justify-content-evenly {
    flex-wrap: wrap;
}
  
    div#howitworkarrow::before {
      left: 8px;
      transform: rotate(90deg);
      top: -40px;
      width: 30px;
      height: 72px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .howitworkinner-text h2 {
      color: #000;
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
      margin-top: 0px;
    }
    section.Howitworks.mb-5 {
        margin-bottom: 2px !important;
    }
    p.smalllinespan span:nth-child(2) {
        display: block;
    }
    
    .subcategoryinneraskexpert.mt-5 {
        margin-top: 0px !important;
    }
    .howitworkbox-inner img {
      width: 40px;
    }
    /* .heroleftpanel h2 {
              font-style: normal;
              font-weight: 700;
              font-size: 42px;
              margin-bottom: 0;
              text-align: start;
          } */
    .popup {
      position: absolute;
      top: 155px;
      right: 24%;
      background-color: #036518;
      color: #fff;
    }
    p.subh1 {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: #000;
      line-height: 1.2;
      margin-bottom: 10px;
      font-family: "SF Pro Text" !important;
      max-width: 270px;
    }

    p.text-start, b.ask-bold-text {
      font-weight: normal;
      font-size: 12px;
font-weight: 700;
display: inline-block; line-height: 1;margin-top: 0;
    }    p.text-start{margin-top: 10px; max-width: 300px; line-height: 1.2;}
  
    .howitworkbox-inner {
      width: 65px;
      height: 65px;
      margin: 10px 10px;
    }
    .categorytext h2 , .categorytext span{
      font-size: 18px;
      font-weight: 700;
    }
    .container-rimib p.catetext2{font-size: 12px;
      font-style: normal;
      font-weight: 400;
      font-family: "SF Pro Display" !important;
      line-height: 1.2;
      color: #000;
    }
    .container-rimib   .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth h5 {
      font-size: 10px;
      line-height: 1 !important;
      margin-top: 5px;
    }
    .container-rimib .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth .iconone{
      margin-bottom: 0;
    }
    .register-main .input:focus ~ .placeholder, .register-main .input:not(:placeholder-shown) ~ .placeholder {
      transform: translateY(-9px) translateX(1px) scale(1);
      font-size: 10px;
  }
    .questiontext-icon img {
      width: 35px;
  }
  .container-rimib .inner-askexpertnewlayout .textarecs .input-question textarea.input.form-control.textareaform{
    height: 250px;
  }
  .container-rimib .asktheexpertcategorysectioninnerlinenew .textarecs
  {margin: 0;
    border-top: 2px solid #ccc;
    border-radius: 0;
    margin-top: 30px !important;
    padding-top: 15px;}
 .questionslider-text{width: 88%; max-width: 100%;}
 .nextbtn button.proceed-too-btn{width: 90px; height: 25px; padding: 2px 5px; font-size: 12px; border-radius: 2px;}
 .inner-askexpertnewlayout .register-main-text h3 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: "SF Pro Display" !important;
  line-height: 1;
  color: #000;
  margin-top: 5px;
 }
 
 .inner-askexpertnewlayout .register-main-text p{display: none;}
    .container-rimib .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesettwo {
      width: 90px;
      height: 90px;
    }
    .inner-askexpertnewlayout .register-main-text h2,  .inner-askexpertnewlayout .register-main-text h2 span{
      font-family: Montserrat;
font-size: 18px;
font-style: normal;
line-height: 1;
    }
    .container-rimib .question-text h2{
      font-size: 18px;
    }
    .questionslider-text p{
      font-family: 'microsoft_sans_serifregular' !important;
      font-size: 10px;
    }
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth.active .icontwo{
      margin-bottom: 0;
    }
    .categoryonesetone {
      padding: 20px 0;
      text-align: center;
    }
    .input-margin {
      margin-left: 0;
    }
    .asktheexpertcategorysectioninnerlinenew .categoryonesettwo h5 {
      width: 100%;
    }
    .asktheexpertcategorysectioninnerlinenew .categoryonesettwo h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 100%;
      text-align: center;
      position: relative;
      padding-right: 0;
    }
    .joincanadians {
      min-height: auto;
      padding: 20px 0;
      background: url(../../../assets/askTheExpertImages/askthebackbannermobile.svg);
      background-size: cover;
    }
    .asktheexpertcategorysectioninnerline {
      padding: 20px 0 0 0;
    }
    .question-text h2 {
      text-align: center;
    }
    .categoryonesetone p,
    .categoryonesetone h4 {
      text-align: center;
    }
    .jioncanadianstext p, p.smalllinespan span {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      margin: 20px 0 0 0;
      color: #000;
    }
    p.smalllinespan span.psnew{
      color: #000;

font-family: "SF Pro Display" !important;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 0px;
display: inline-block;
margin-top: 20px;
    }
    .jioncanadianstext h2 {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      color: #000;
      max-width: 302px;
      margin-left: auto;
      margin-right: auto;
    }
    .jioncanadianstext span {
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: #000;
    }
    section.SelectMainCategory#ask {
      padding: 0px 0;
  }
    .popup {
      padding: 15px;
      max-width: 95%;
      top: 10px;
      right: 0;
      border-radius: 20px;
    }
  
    .popup-inner h2 {
      font-size: 18px;
      line-height: 24px;
      max-width: 220px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
    }
  
    .popup-inner p {
      font-size: 14px;
      line-height: 20px;
      max-width: 350px;
      margin: 0 auto;
    }
  
    a.signuptext {
      font-size: 14px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }
  
    .popup-inner span {
      font-size: 14px;
      line-height: 20px;
    }
  
    .popup-inner h3 {
      font-size: 16px;
      line-height: 22px;
    }
  
    .popupbutton a.popupbtn1,
    .popupbutton a.popupbtn2 {
      font-size: 12px;
        line-height: 22px;
        padding: 3px 10px;
        margin: 10px 0 0 0;
        letter-spacing: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      
    }
  
    .closebtnicon {
      padding: 2px;
    }
    section.herosection.heroasktheexpert.heronewaskexpert>img {
      width: 100%;
  }
  .heronewaskexpert .container {
    position: initial;
}
.container-rimib .faqsection .accordion-button:not(.collapsed), .container-rimib .faqsection .accordion-button{
  color: #000;

font-family: 'microsoft_sans_serifregular' !important;
font-size: 12px;
font-style: normal;
line-height: 1.2;
}
.container-rimib .faqsection .accordion-item
{
  margin-bottom: 10px;
}
.register-main .mainexpforminner{
  margin-bottom: 0;
}
.proceed-main-btn.mb-3.text-center {
  margin-bottom: 0 !important;
}
.container-rimib .faqsection .accordion-item .accordion-collapse,.container-rimib .faqsection .accordion-item .show {
  color: #fff;
  max-width: 317px;
  margin: 0 auto;
  background-color: #5e91b3;
  border-radius: 10px;
}
.accordion-header, button.accordion-button.collapsed.faqmobileborder {
  border-radius: 50px;
}




.asktheexpertconfirmpopup .cliparttext h2{
  font-size: 16px;
}
.asktheexpertconfirmpopup.popupouter .cliparttext .clipartmain .cliparttextbox p, .cliparttextboxouter p{
  font-weight: 500;
  font-size: 10px;
  width: 100%;
  display: block;
  line-height: 1;
}
.cliparttextboxinner img{
  width: 62px;
  
}
.cliparttextboxtwo{
  width: 85px;
  height: 85px;
  flex-direction: row;
  border-radius: 2px;
}
.clipartbtn{flex-wrap: wrap;}
.clipart {
  position: absolute;
  top: -232px;
  right: 0;
  width: 461px;
}
.cliparttext .clipartmain{
margin-top: 0px;
}
p.text-categ {
display: flex;
}
.thankyou .thakyoutext h2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
margin: 0 auto 10px auto;
margin-bottom: 0;

}
.thankyou .thakyoutext p.thankyousubtext{
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
}
.thankyou .thakyoutext p.thankyousubtext2{
  font-size: 12px;
font-style: normal;
font-weight: 400;
  max-width: 100%;
margin-left: auto;
margin-right: auto;
  margin-bottom: 0px;
  font-family: 'microsoft_sans_serifregular' !important;
}
a.thankyoubutton{
  color: #000;
        text-align: center;
        font-family: "Roboto Flex";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        height: 20px;
        width: auto;
        padding: 0px 20px;
        letter-spacing: 0;
        line-height: 1.5;
        border-radius: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
}
a.thankyoubutton2{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Flex";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  height: 20px;
  width: auto;
  padding: 0px 20px;
  line-height: 1.5;
  border-radius: 1px;
  letter-spacing: 0;
}
.thankyoubtn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
}
.thankyou .thakyoutext2{
  margin-top: 25px;
}
.thankyou .thakyoutext2 h2{
  font-size: 11px;
  max-width: 185px;
  margin: 0 auto;
}
.thankyou .thakyoutext2 p.thankyousubtext2{
  font-size: 7px;
  font-weight: 500;
  max-width: 224px;
  margin: 0 auto;
}
a.thankyou2button{
  width: 80px ;
height: 20px;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  color: #000;
  border: 1px solid #000;
margin: auto;
  padding: 0;
  letter-spacing: 0;
  line-height: 1;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thankyou2btn {
  margin-top: 10px;
}
.heroleftpanel{width: 100%; max-width:100%;}
.user-round-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 55%;
}
.faqsection .accordion-button::after {
  background: none;
}
.accordion-header {
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0px 4px 4px 0px #00000040;
  /* position: absolute;
          width: 100%;
          margin-top: -49px; */
}
button.accordion-button.collapsed.faqmobileborder {
  border-radius: 50px;
}
.faqsection .accordion-item .show {
  color: white;
  max-width: 317px;
  margin: 0 auto;
  background-color: #5e91b3;
  border-radius: 10px;
}
.faqsection .accordion-item.faqmobile {
  border: none;
  background-color: transparent;
  position: relative;
}
.faqsection .accordion-item.faqmobile:not(:first-of-type) {
  border: none;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.bgnone {background: transparent !important;}
  }
  @media(max-width:540px){
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
    {width: 118px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
    {
      height: 100px;
    }
    .choose.categoryonesettwo {
      height: 108px;
  }
  .clipart{width: auto;}
  .cliparttextboxinner img{height: 76px; width: 76px;}
  .cliparttextboxinner{       
     height: 85px;
    width: 80px;
}
  }
  @media(max-width:530px){
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
    {width: 100px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
    {
      height: 100px;
    }
    .choose.categoryonesettwo {
      height: 89px;
  }
  }
  @media(max-width:500px){
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
    {width: 105px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
    {
      height: 100px;
    }
    .choose.categoryonesettwo {
      height: 97px;
  }
  }
  @media(max-width:490px){
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
    {width: 93px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
    {
      height: 100px;
    }
    .choose.categoryonesettwo {
      height: 85px;
  }
  }
  @media(max-width:480px){
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
    {width: 100px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
    {
      height: 100px;
    }
    .choose.categoryonesettwo {
      height: 97px;
  }
  }
  @media(max-width:465px){
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
    {width: 90px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
    {
      height: 100px;
    }
    .choose.categoryonesettwo {
      height: 82px;
  }
  }
  @media(max-width:440px){
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
    {width: 85px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
    {
      height: 100px;
    }
    .choose.categoryonesettwo {
      height: 75px;
  }
  }
  @media (max-width:430px){
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
    {width: 82px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
    {
      height: 75px;
    }
    .choose.categoryonesettwo {
      height: 70px;
  }
}
  @media (max-width:420px){
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
    {width: 79px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
    {
      height: 100px;
    }
    .choose.categoryonesettwo {
      height: 70px;
  }
    .modalspecial.asktheexpertconfirmpopup > div {
      border-radius: 30px;
background: #036518;
box-shadow: 7.958px 1.206px 7.958px 0px rgba(0, 0, 0, 0.25);
padding: 22px 15px;}
  .cliparttextboxinner {
    border: 6px solid #fff;
    position: relative;
    width: 94px;
    height: 82px;
}
.cliparttextboxinner img{height: auto; width: 86px;}
.clipart{z-index: 1;}
.cliparttext{position: relative ; z-index: 9;}
  }
  @media (max-width:390px){
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
    {width: 72px;}
    .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
    {
      height: 100px;
    }
    .choose.categoryonesettwo {
      height: 60px;
  }
}
@media (max-width:375px){
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
  {width: 80px;}
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
  {
    height: 100px;
  }
  .choose.categoryonesettwo {
    height: 68px;
}
}
@media (max-width:360px){
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
  {width: 76px;}
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
  {
    height: 100px;
  }
  .choose.categoryonesettwo {
    height: 68px;
}
}
@media (max-width:350px){
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
  {width: 68px;}
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
  {
    height: 100px;
  }
  .choose.categoryonesettwo {
    height: 62px;
}
.container-rimib .faqsection .accordion-item .show {
  color: #fff;
  max-width: 320px;
  margin: 0 auto;
  background-color: #5e91b3;
}
}
@media (max-width:344px){
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo
  {width: 64px;}
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img
  {
    height: 100px;
  }
  .choose.categoryonesettwo {
    height: 62px;
}

}









  
  
  