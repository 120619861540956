.heroasktheexpert {
	position: relative;
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
  .roboto-thin {
	font-family: "Roboto", sans-serif;
	font-weight: 100;
	font-style: normal;
  }
  
  .roboto-light {
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	font-style: normal;
  }
  
  .roboto-regular {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: normal;
  }
  
  .roboto-medium {
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-style: normal;
  }
  
  .roboto-bold {
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	font-style: normal;
  }
  
  .roboto-black {
	font-family: "Roboto", sans-serif;
	font-weight: 900;
	font-style: normal;
  }
  
  .roboto-thin-italic {
	font-family: "Roboto", sans-serif;
	font-weight: 100;
	font-style: italic;
  }
  
  .roboto-light-italic {
	font-family: "Roboto", sans-serif;
	font-weight: 300;
	font-style: italic;
  }
  
  .roboto-regular-italic {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-style: italic;
  }
  
  .roboto-medium-italic {
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	font-style: italic;
  }
  
  .roboto-bold-italic {
	font-family: "Roboto", sans-serif;
	font-weight: 700;
	font-style: italic;
  }
  
  .roboto-black-italic {
	font-family: "Roboto", sans-serif;
	font-weight: 900;
	font-style: italic;
  }
  
  section.herosection.heroasktheexpert.heronewaskexpert {
	padding: 0;
	margin: 0;
	text-align: center;
  }
  section.herosection.heroasktheexpert.heronewaskexpert .heroleftpanel {
	width: 50%;
    text-align: right;
    position: absolute;
    bottom: 158px;
    left: 52px;
    right: auto;
    text-align: left;
}
  h1,
  h2,
  h3,
  h4,
  h5,
  P {
	font-family: "Montserrat", sans-serif !important;
  }
  .Howitworks{margin-bottom: 40px; margin-top:-39px; z-index: 9; position: relative;}
  .joincanadians {
	object-fit: contain;
	background-image: url("../../../assets/images/Rectangle4815.svg");
padding: 50px 0;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
  }
  
  .jioncanadianstext {
	max-width: 1000px;
	margin: 0 auto;
	text-align: center;
  }
  .jioncanadianstext h2 {
	font-size: 30px;
	font-weight: 700;
	text-align: center;
	color: #000;    
	font-family: "Montserrat", sans-serif !important;
	margin-bottom: 0 !important;
  }
  
  .jioncanadianstext p {
	font-size: 25px;
	font-weight: 500;
	text-align: left;
	color: #000;    
	font-family: "Montserrat", sans-serif !important;
	margin-bottom: 0;
	margin-top: 0px;
	line-height: 30px;
	text-align: center;
  }
  .categorytext span {
	color: #036518;
font-size: 30px;
font-style: normal;
font-weight: 900;
line-height: normal;
  }
  
  .categorytext h2 {
	color: #000;
	margin-bottom: 0;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
  }
  
  .categorytext h2,
  .categorytext p {
	text-align: center;
  }
  
  p.catetext1 {
	color: #000;
	font-family: Montserrat;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0;
  }
  section.SelectMainCategory#ask {
    padding: 40px 0;
}
  p.catetext2 {
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 0;
	line-height: 19px;

  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth.categoryonesettwo:hover .icontwo img {
    filter: none  !important;
}
  .choose-main {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px auto 0;
	flex-wrap: wrap;
	border: 9px solid #e1e6ed;padding: 0px 5px;
  }
  
  .choose {
	position: relative;
  }
  
  .choose:last-child {
	border-right: none;
  }
  
  .choose-main p {
	font-weight: 600;
  
	text-align: center;
	margin-top: 10px;
  }
  section.herosection.heroasktheexpert.heronewaskexpert .askbutton{margin: 0;}
  .choose-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  }
  
  .choose-box img {
	width: 55px;
  }
  
  .choose:hover {
	cursor: pointer;
	background-color: #036518;
	color: white;
  }
  
  .choose:hover img.onimg {
	color: white;
  } 
  p.categoryonesetone-text {
	font-family: "Montserrat", sans-serif;
	font-size: 48px;
	font-weight: 600;
  
	text-align: left;
	color: #ffff;
  }
  
  .categoryonesetone h4 {
	font-size: 48px;
	font-weight: 900;
  
	text-align: left;
	color: white;
  }
  
  .categoryonesetone p {
	font-family: "Montserrat", sans-serif;
	font-size: 24px;
	font-weight: 600;
  
	text-align: left;
	color: white;
  }
  
  p.categoryonesetone-text-too {
	font-family: "Montserrat", sans-serif;
	font-size: 15px;
	font-weight: 600;
	text-align: left;
	color: white;
  }
  
  .categoryonesetone {
	padding: 200px 100px;
  }
  

  
  .choose.categoryonesettwo {
	margin-bottom: 0px;
	height: 150px;
	background-color: transparent;
	border-radius: 0;
	text-align: center;
	display: flex;
	align-items: center;
	padding: 0;
	flex-wrap: wrap;
	justify-content: center;
  }
  
  .choose .categoryonesettwo h5 {
	padding-left: 0;
  }
  
  .choose .categoryonesettwo:after {
	content: "";
	position: absolute;
	z-index: -1;
	left: 35%;
	top: 100%;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #036518;
  }
  
  .categoryonesetone {
	padding: 50px 100px;
  }

  .asktheexpertcategorysectioninnerlinene .categoryoneset:after {
	display: none;
  }
  
  .categorytwosetfirsttest.categorytwoset,
  .categorytwosetfirsttest {
	position: relative;
  }
  
  .padleftright100 {
	padding: 0 50px !important;
  }
  
  .categoryonesettwo {
	margin-bottom: 20px;
	height: 150px;
	background-color: #fff;
	border-radius: 10px;
	text-align: center;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 40px;
  }
  
  .categoryonesetfirst {
	align-items: center;
	margin: 0 auto;
	display: flex;
  }
  
  .asktheexpertcategorysectioninnerline {
	position: relative;
	padding: 79px 0 0 0;
  }
  
  .question-text h2 {
	color: #000;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
	margin-bottom: 15px;
  }
  
  .question-text span {
	font-family: "Montserrat", sans-serif;
	font-size: 30px;
	font-weight: 700;
  
	text-align: left;
	color: #036518;
  }
  
  .question-text-right h2 {
	font-family: "Montserrat", sans-serif;
	font-size: 30px;
	font-weight: 700;
  
	color: #000;
  }
  
  .question-text-right span {
	font-family: "Montserrat", sans-serif;
	font-size: 30px;
	font-weight: 700;
  
	text-align: left;
	color: #036518;
  }
  
  .main-question {
	display: flex !important;
	align-items: center;
	justify-content: space-between;
	padding: 10px 12px 10px 33px;
	margin: 10px 9px;
	border-radius: 50px;
	max-width: 486px;
	box-shadow: 0px 3px 23px 0px #00000040;
	height: 82px;
  }
  .questiontext-icon img {
    width: 61px;
}
  
  .questionslider-text p {
	color: #000;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 0;
  }
  .questiontext-icon img {
    cursor: pointer;
}
  .questionslider .slick-prev:before {
	position: absolute;
	background-size: contain;
	background-repeat: no-repeat;
	left: 581px;
	font-size: 40px;
	rotate: 90deg;
	top: -45px;
	color: #036518;
	opacity: 1;
  }
  
  .catborderbottom .slick-next:before {
	position: absolute !important;
	background-size: contain !important;
	background-repeat: no-repeat !important;
	right: 64px !important;
	font-size: 40px !important;
content:"";
	color: #036518 !important;
	opacity: 1;
	background: url(../../../assets/homeimages/slicknext.svg);
	transform: rotate(90deg);;
	width: 40px;height: 40px;
	background-size: contain;
	background-repeat: no-repeat;
  }

button.proceedbtn:hover a {
	transition: none;
    color: white;
}
button.proceedbtn a {
    color: #EF000B;
}

.nextbtn button.proceed-too-btn {
    border: 1px solid #EF000B;
	border-radius: 5px;
	width: 150px;
height: 39.924px;
font-family: "Roboto Flex", sans-serif !important;
    color: #EF000B;
    background-color: white;
    font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 21px;
letter-spacing: 1.8px;
    padding: 9px;
	text-align: center;
    box-shadow: 2px 2px 10px 0px #EF000B;

}
  .catborderbottom .slick-prev:before{background: url(../../../assets/homeimages/slicknext.svg);content:"";	width: 40px;height: 40px;
	background-size: contain;
	background-repeat: no-repeat;    transform: rotate(-90deg);}
	.catborderbottom .slick-prev {
		left: auto;
		right: 64px;
		transform: translate(0, -87px);
	}
	.catborderbottom .slick-next {
		right: -21px;
	}
  .catborderbottom .slick-prev:before, .catborderbottom .slick-next:before{
	  color: #036518 !important;
	opacity: 1;}
  
  .all-border {
	border-bottom: 4px solid #efefef;
  }
  
  .questionslider-text {
	max-width: 329px;
  }
  
  p.question-right-ptext {
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 0;
  }
  
  .queastio-main {
	padding-left: 75px;
	position: relative;
  }
  
  .queastio-main:before {
	content: "";
	position: absolute;
	height: 93%;
	width: 4px;
	background-color: #efefef;
	left: -30px;
	top: 61px;
  }
  
  p.question-right-text-too {
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
  }
  
  .input-question textarea {
	border: none;
	box-shadow: 3px 3px 33px 0px #00000040;
	border-radius: 30px;
	padding: 22px 35px;
	height: 550px;
	resize: none;
	width: 100%;
  }
 
  a.nextbutton {
	border: 1px solid #ef000b;
	text-decoration: none;
	padding: 10px 25px;
	font-size: 26px;
	font-weight: 500;
	line-height: 30px;
	letter-spacing: 0.1em;
	text-align: left;
	box-shadow: 2px 2px 10px 0px #ef000b;
	text-align: left;
	color: #ef000b;
  }
  
  a.nextbutton:hover {
	color: #ef000b;
  }
  
  .nextbtn {
	text-align: end;
	margin-top: 30px;
  }
  .regbtn{margin: 20px 0;}
  .inner-askexpertnewlayout .register-main-text h2 {
	
	font-size: 30px;
	font-weight: 700;
  text-align: center;
	color: #036518;
	margin-bottom: 0;
  }
  
  .inner-askexpertnewlayout .register-main-text span {
	font-size: 30px;
	font-weight: 700;
  
	text-align: left;
	color: #000000;
  }
  
  .inner-askexpertnewlayout .register-main-text h3 {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
	line-height: 19px;
	text-align: center;
	color: #000000;
  }
  
  .inner-askexpertnewlayout .register-main-text p {
	font-size: 16px;
	font-weight: 500;line-height: 19px;
  margin-bottom: 0;
	text-align: center;
	color: #000000;
	max-width: 950px;
	margin: 0 auto;
  }
  
  a.registernowbutton {
	background: #00831D;
	border:2px solid transparent;
    width: 155px;
    height: 40px;
    color: white;
    text-decoration: none;
    border-radius: 6px;
    font-family: "Roboto Flex";
    font-size: 18px;
    letter-spacing: 1.8px;
    font-weight: 500;
    line-height: 21px;
    display: flex;
    padding: 9px;
    text-align: center;
	justify-content: center;
	margin: 0 auto;
	align-items: center;
  }
  
  span.input-group-text {
	border: none !important;
	background-color: transparent !important;
  }
  
  .input-group-text {
	position: absolute;
	right: 0;
  }
  
  .inner-askexpertnewlayout .register-fill .form-group .form-control {
	border-radius: 10px;
	height: 48px;
	font-size: 14px;
	padding: 17px 0px 0 13px;
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.10) !important;
	border:3px solid #00831D;font-family: 'microsoft_sans_serifregular' !important;
	color: #666;
	font-size: 13px;
	font-weight: 400;
  }
  .inner-askexpertnewlayout .input-container .placeholder{top:18px;}
  .form-group .form-control::placeholder{font-family: 'microsoft_sans_serifregular' !important;}
  .mainexpforminner .placeholder.font-place {
    font-size: 16.77px;
	font-weight: 600;
	color: #A7A7A7;
}
  .input-group-reg {
	border-radius: 12px;
	box-shadow: 3.59px 3.59px 11.98px 0px #00000040;
	outline: none;
	display: flex;
	align-items: center;
  }
  .input-container.form-group.input-group-reg {
    max-width: 440px;
    border: 1px solid transparent;
    padding: 0;
    margin-bottom: 30px !important;
}
.input-container.form-group.input-group-reg:first-child {
    margin-right: 10px;
}
.input-container.form-group.input-group-reg:nth-child(2) {
    margin-left: 10px;
}
.input-container.form-group.input-group-reg:nth-child(3) {
    margin-right: 10px;
}
.input-container.form-group.input-group-reg:nth-child(4) {
    margin-left: 10px;
}
  .input-group-reg input {
	border: none;
	box-shadow: none !important;
	padding: 25px;
	border-radius: 12px;
	font-size: 16.77px;
	font-weight: 600;
  }
  
  .input-group-reg input:focus {
	border: 1px solid #036518 !important;
  }
  
  a.proceedbtn,
  button.proceedbtn {
	border: 1px solid #ef000b;
	text-decoration: none;
	padding: 10px 25px;
	border-radius: 5px;
	font-family: "Roboto Flex", sans-serif !important;
	font-size: 18px;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: 1.8px;
	text-align: center;
	color: #ef000b;
	background: #fff;
  }
  
  .proceed-main-btn {
	margin: 0 0 0 auto;
	text-align: center;
  }
  .register {
	position: relative;
  }
  section#nextregister{
    margin-bottom: 0px;
  }
  
  .frequently {
	background-color: #daf0ff;
	padding: 15px 0;
  }
  
  h2.frequently-text {
	font-size: 30px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 20px;
  }
  

  
  .frequently-box {
	margin-bottom: 15px;
	box-shadow: 3.16px 3.16px 34.79px 0px #00000040;
	padding: 10px;
	min-height: 270px;
	background: #fff;
  }
  .frequently-box-down-text ul {
	margin-bottom: 0;
  }
  .frequently-box-down-text {
	background-color: #fff;
	border-radius: 10px;
	padding: 10px;
	cursor: pointer;
	min-height: 155px;
	border-radius: 5px;
border: 0.843px solid #97CBEE;
background: #FFF;
  }
  
  .frequently-box-down-text:hover p {
	cursor: pointer;
  }
  .frequently-box-down-text li {
	font-size: 13px;
	font-weight: 500;
	text-align: left;
	list-style: disc;font-family: 'microsoft_sans_serifregular' !important;
	line-height: 14px;
  }
  
  .frequenty-box-up h6 {
	font-size: 16px;
	font-weight: 700;
	min-height: 40px;
  }
  
  .frequently-box-down-text p {
	font-weight: 500;
	text-align: left;
	color: #000000;
	font-size: 13px;
	font-family: 'microsoft_sans_serifregular' !important;
	line-height: 15px;
  }
  
  .frequent-row {
	display: flex;
	flex-wrap: wrap;
  }
  
  .frequent-row .col-3 {
	display: flex;
	flex-direction: column;
  }
  
  .frequent-row .frequently-box {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
  }
  
  .frequent-row .frequenty-box-up,
  .frequent-row .frequenty-box-down {
	flex-grow: 1;
  }
  
  .frequent-row .frequently-box-down-text {
	display: flex;
	align-items: flex-end;
  }
  .frequenty-box-up img {
    width: 45px;
    margin-bottom: 0 !important;
}
  .input-margin {
	margin-left: 14px;
  }
  
  .input-margin-r {
	margin-right: 14px;
  }
  section.herosection.heroasktheexpert.heronewaskexpert h2.heroheading {
	color: #000;

font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  
  section.herosection.heroasktheexpert.heronewaskexpert h2.heroheading2 {
	color: #4C6501;

font-size: 45px;
font-style: normal;
font-weight: 800;
line-height: normal;
  }
  
  .heroleftpanel h5 {
	color: #000;
	
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
  }
 
  
  .heroleftpanel h6 {
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
  }
  
  .popup {
	position: absolute;
    top: 0;
    z-index: 1;
    right: 0;
    background-color: #036518;
    color: #fff;
    padding: 30px 130px;
    left: 0;
    margin: auto;
    top: 50px;
    max-width: 1001.1px;
    min-height: auto;
  }
  
  .popup-inner h2 {
	font-size: 30px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    color: #ffffff;
    margin: 0 auto;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .popup-inner p {
	font-size: 16px;
	font-weight: 500;
	line-height: 17px;
	text-align: center;
	color: #ffffff;
	max-width: 850px;
	margin: 0px auto;
  }
  
  .popup-inner span {
	font-size: 16px;
	line-height: 19.5px;
	text-align: center;
  }
  
  .popup-inner h3 {
	font-size: 20px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    color: #ffffff;
    margin: 10px 0;
  }
  
  .popupbutton a.popupbtn2:hover {
	background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
	color: #fff;
	cursor: pointer;
  }
  
  a.signuptext {
	font-size: 20px;
	font-weight: 700;
	line-height: 39px;
	text-align: center;
	color: #ffffff;
	text-decoration: none !important;
}

a.signuptext:hover {
	  cursor: pointer;
	color: #ffff !important;
	font-weight: 900;
  }
  
  .popupbutton a.popupbtn1 {
	width: 250px;
	height: 40px;
	border-radius: 5px;
	background: #A0FFA0;
	color: #000;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 34px;
	letter-spacing: 1.8px;
	border: 3px solid transparent;
  }
  
  .popupbutton a.popupbtn2 {
	width: 250px;
	height: 40px;
	border-radius: 5px;
	background: #fff;
	color: #3C5100;
text-align: center;
font-family: "Roboto Flex";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 34px;
letter-spacing: 1.8px;
border: 3px solid transparent;
  }
  
  .closebtnicon {
	
	background-image: url("../../../assets/askTheExpertImages/closed-icon.png");
    height: 30px;
    width: 30px;
    background-size: contain !important;
	display: inline-block;
	border-radius: 50px;
	padding: 6px;
	color: #3c5100;
	position: absolute;
	top: 17px;
	right: 28px;
	cursor: pointer;
  }
  
  .blurred {
	filter: blur(5px);
	transition: filter 0.3s ease;
  }

	p.text-start{
		margin: 10px 0;
		font-size: 20px;
		font-weight: 600;
		line-height: 24px;
  }
  b.ask-bold-text{
		margin-top: 40px;
		font-size: 18px;
		font-weight: 700;
  }
  
  .heronewaskexpert .container {
	position: relative;
  }
  
  
  .heroleftbutton{margin-top: 20px;}
  .heroleftbutton button {
	width: auto;
	padding: 10px 40px;
	margin-right: 16px;
	
  }
  
  p.subh1 {
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	margin-bottom: 0;
	text-align: left;
  }
  
  .user-round-img {
    position: absolute;
    top: 227px;
    right: 0;
    bottom: 0;
    width: 50%;
    max-width: 100%;
    height: auto;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: 0 auto;
}
.Howitworks .howitworkinner{
	margin-top: -71px;
    z-index: 1;
    position: relative;
}
  .howitworkinner-text h2 {
	color: #000;

font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 10px;
text-align: center;
  }
  
  .howitworkbox-inner {
	background-color: #ffd8d8;
	box-shadow: 2.74px 2.74px 30.09px 0px #00000040;
	width: 50%;
	/* or another percentage relative to the parent container */
	aspect-ratio: 1 / 1;
	/* Maintains a 1:1 aspect ratio, keeping it square */
	max-width: 150px;
	/* Optional: sets a maximum size */
	border-radius: 50%;
	/* Makes the element circular */
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
  }
  .howitworkbox-inner img{width: 90px;}
  .howitwork-box p {
	color: #000;
	text-align: center;
	font-size: 16px;
	line-height: 19px;
	font-weight: 500;
	margin: 20px auto 0;
	color: #000;
  }
  
  div#howitworkbox2color {
	background-color: #daf0ff;
  }
  
  div#howitworkbox3color {
	background-color: #ffe8a4;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone img,
  .asktheexpertcategorysectioninnerlinenew .categoryonesettwo .icontwo img {
	height: auto;
	border-radius: 0;
	padding: 0;
	background-color: transparent;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	padding: 0 5px;
  }
  .asktheexpertcategorysectioninnerlinenew .categoryonesettwo .icontwo img{top:2px;}

  
  .asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone,
  .asktheexpertcategorysectioninnerlinenew .categoryonesettwo .icontwo {
	width: 100%;
	position: relative;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryoneset {
	width: 100%;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesettwo .iconone {
	background: transparent;
	border-radius: 0;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesettwo h5 {
	padding-left: 0;
	width: 100%;
  }
  
  .choose.categoryonesettwo:hover:before {
	content: "";
	position: absolute;
	z-index: -1;
	left: 35%;
	top: 100%;
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 20px solid #036518;
	clear: both;
	display: none;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryoneset:after,
  .asktheexpertcategorysectioninnerlinenew .catstart::before {
	display: none;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesettwo:after {
	background: transparent;
  }
  
  .asktheexpertcategorysectioninnerlinenew .choose.categoryonesettwo:after {
	border-radius: 0;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesetfirst {
	width: 100%;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesettwo {
	flex-direction: row;
	background: #fff;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth h5 {
	text-align: center;
	font-size: 14px;
	font-weight: 500;
	line-height: 16px !important;
	line-height: 1;
	color: #000;
	padding-right: 0;
	margin-bottom: 0;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesettwo:hover {
	border-radius: 10px;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth {
	margin-bottom: 20px;
  }
  
  div#askts {
	align-items: center;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesetone.anotherexp p {
	font-family: "Montserrat", sans-serif;
	font-size: 48px;
	font-weight: 600;
	line-height: 59px;
	text-align: left;
	color: #fff;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryonesetone.anotherexp h4 {
	font-family: "Montserrat", sans-serif;
	font-size: 48px;
	font-weight: 900;
	line-height: 59px;
	text-align: left;
	color: #fff;
  }
  
  .asktheexpertcategorysectioninnerlinenew
	.categoryonesetone.anotherexp
	p.categoryonesetonetext
	b {
	
	font-size: 24px;
	font-weight: 600;
	line-height: 29px;
	text-align: left;
	display: block;
  }
  
  .asktheexpertcategorysectioninnerlinenew
	.categoryonesetone.anotherexp
	p.categoryonesetonetext {
	
	font-size: 15px;
	font-weight: 600;
	line-height: 18px;
	text-align: left;
  }
  
  .register-main .mainexpforminner {
	background: transparent;
	box-shadow: none;
	padding: 0;
	margin: 20px 10px;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categorytwoset:after,
  .asktheexpertcategorysectioninnerlinenew .categorythreeset:after,
  .asktheexpertcategorysectioninnerlinenew .categoryfourset:after {
	display: none;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryfourset {
	display: block;
  }
  
  .asktheexpertcategorysectioninnerlinenew .categoryfourset .categoryonesetone {
	width: 100%;
	padding: 0;
  }
  
  .subcategoryinneraskexpert {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	flex-wrap: wrap;
  }
  .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth:nth-child(1) {
	background: #cbeaff;
  }
  .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth:nth-child(2) {
	background: #ffe8a4;
  }
  .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth:nth-child(3) {
	background: #f6e4ff;
  }
  .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth:nth-child(4) {
	background: #ffe0e0;
  }
  .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth:nth-child(5) {
	background: #c0ffc7;
  }
  .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth:nth-child(6) {
	background: #daf0ff;
  }
  .herosection.heronewaskexpert a.ctaonebutton {
	width: 210px;
height: 45px;
flex-shrink: 0;
border-radius: 5px;
background: #00801D;
color: #FFF;
text-align: center;
font-family: "Roboto Flex";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.8px;
padding: 0;
	border: 3px solid transparent;
	line-height: 21px;
  }
  .herosection.heronewaskexpert a.ctatwobutton {
	border: none;
	width: 150px;
height: 45px;
flex-shrink: 0;
border-radius: 5px;
background: #FFF;
color: #00831D;
text-align: center;
font-family: "Roboto Flex";
font-size: 18px;
font-style: normal;
font-weight: 500;
padding: 10px 0;
line-height: 42px;
letter-spacing: 1.8px;
	border: 3px solid transparent;
	line-height: 21px;
	box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .categorytwosetfirsttest .categoryonesetwidth {
	width: 100px;
	height: 100px;
	padding: 10px 2px;
	display: block;
	text-align: center;
	margin: 10px;
	border: 4.71px solid transparent;
	box-shadow: 2.62px 3.66px 12.35px 0px #00000040;    background: #daf0ff;
  }
  .categorytwosetfirsttest .categoryonesettwo:hover {
	cursor: pointer;
	background-color: transparent;
	border: 4.71px solid #cbeaff;
  }
  .categorytwosetfirsttest .categoryonesettwo:nth-child(2):hover {
	border: 4.71px solid #ffe8a4;
  }
  .categorytwosetfirsttest .categoryonesettwo:nth-child(3):hover {
	border: 4.71px solid #f6e4ff;
  }
  .categorytwosetfirsttest .categoryonesettwo:nth-child(4):hover {
	border: 4.71px solid #ffe0e0;
  }
  .categorytwosetfirsttest .categoryonesettwo:nth-child(5):hover {
	border: 4.71px solid #c0ffc7;
  }
  .categorytwosetfirsttest .categoryonesettwo:nth-child(6):hover {
	border: 4.71px solid #daf0ff;
  }
  .categoryonesettwo:hover .iconone img {
	background-color: white;
  }
  .categorytwosetfirsttest .categoryonesettwo:hover h5 {
	color: #000;
  }
  .asktheexpertcategorysectioninnerlinenew .textarecs {
	background: transparent;
	box-shadow: none;
	border-radius: 10px;
	padding: 0;
	border-top: none;
  }
  .inner-askexpertnewlayout .textarecs .input-question textarea.input.form-control.textareaform {
	border: none;
	box-shadow: 3px 3px 33px 0px #00000040;
	border-radius: 30px;
	padding: 22px 35px;
	height: 304px;
	resize: none;
	width: 100%;
	padding-top: 50px;
	padding-left: 13px;
	font-size: 13px;
	font-weight: 400;
	line-height: 15px;
	color: #666;
	font-family: 'microsoft_sans_serifregular' !important;
  }
  .inner-askexpertnewlayout textarea.input:not(:placeholder-shown) ~ .placeholder{
	color: #666;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px;}
	.inner-askexpertnewlayout .input-container .placeholder{color: #666;
		font-family: 'microsoft_sans_serifregular' !important;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 15px;}
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew {
	padding: 0;
  }
  .choose-main .choose.categoryonesettwo:last-child {
	order: 1;
	display: flex;
  }
  
  .choose-main .choose.categoryonesettwo:first-child {
	order: 4;
  }
  
  .choose-main .choose.categoryonesettwo:nth-child(3) {
	order: 3;
  }
  
  .choose-main .choose.categoryonesettwo:nth-child(4) {
	order: 5;
  }
  
  .choose-main .choose.categoryonesettwo:nth-child(2) {
	order: 2;
  }
  .categoryfourset .slick-current {
	transform: scale(1);
  }
  .slick-slider .slick-track {
	height: auto !important;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesetwidth
	.iconone {
	background: #fff;
	height: 42px;
	width: 42px;
	position: relative;
	margin: auto;
	border-radius: 50px;
	padding: 10px;
	margin-bottom: 3px;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesetwidth
	.icontwo {
	background: #fff;
	height: 42px;
	width: 42px;
	position: relative;
	margin: auto;
	border-radius: 50px;
	padding: 10px;
	margin-bottom: 10px;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categoryonesettwo
	.iconone
	img {
	height: 160px;
	
  }
  .queastio-main textarea.input:not(:placeholder-shown) ~ .placeholder{
	top: 22px;
    border-radius: 20px;
    width: 90%;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesettwo.categoryonesetwidth
	.iconone
	img,
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesettwo.categoryonesetwidth
	.icontwo
	img {
	height: 17px;
  }
  
  .asktheexpertconfirmpopup h2 {
	color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
  }
  .asktheexpertconfirmpopup .cliparttext span {
	font-size: 20.29px;
	color: #ffffff;
	font-weight: 600;
  }
  
  .asktheexpertconfirmpopup .cliparttext .clipartmain .cliparttextbox p {
	font-size: 1.5rem;
	color: #ffffff;
	font-weight: 700;
	text-align: start;
  }
  .clipartmain {
	display: flex;
	align-items: center;
  }
  .clipartmain .cliparttextboxouter {
    margin-left: 119px;
}
.cliparttextboxinner img {
    width: 273px;
	height: 186px;
	object-fit: cover;
}
  .clipart img {
	width: 100%;
  }
  .clipart {
	text-align: center;
  }
  .clipartbtn {
	display: flex;
	align-items: center;
  }
  a.clipartbutton {
    width: 150px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFF;
    color: #00831D;
    text-align: center;
    font-family: "Roboto Flex";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    line-height: 42px;
    letter-spacing: 1.8px;
    border: 3px solid transparent;
	margin-right: 20px;
  }
  
  a.clipartbutton2 {
	width: 210px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #00801D;
    color: #FFF;
    text-align: center;
    font-family: "Roboto Flex";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.8px;
    padding: 8px;
    border: 3px solid transparent;

  }
  a.clipartbutton2:hover {
	cursor: pointer;
	background-color: #FFFFFF;
	border: 3px solid #A0FFA0;
	color: #000000;
  }
  a.clipartbutton:hover {
	cursor: pointer;
	background-color: #A0FFA0;
	border: 3px solid #FFFFFF;
	color: #036518;
  }
  .thankyou {
	text-align: center;
  }
  .thankyou .thakyoutext h2 {
	color: #FFF;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;

  }
  .thankyou .thakyoutext p.thankyousubtext {
	color: #FFF;
	text-align: center;
	font-size: 25px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 5px;}
  .thankyou .thakyoutext p.thankyousubtext2 {
	color: #FFF;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
	margin-bottom: 0px;
  }
  a.thankyoubutton {
	width: 150px;
    height: 45px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFF;
    color: #00831D;
    text-align: center;
    font-family: "Roboto Flex";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding: 0;
    line-height: 42px;
    letter-spacing: 1.8px;
	display: inline-block;
    border: 3px solid transparent;
  }
  a.thankyoubutton:hover {
	cursor: pointer;
	background-color: #FFFFFF;
	border: 3px solid #A0FFA0;
	color: #000000;

  }
  a.thankyoubutton2 {
    width: 250px;
    height: 45px;
    margin-left: 20px;
    border-radius: 5px;
    background: #A0FFA0;
    color: #000;
text-align: center;
font-family: "Roboto Flex";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.8px;
padding: 10px;
    border: 3px solid transparent;
	display: inline-block;

  }
  .thankyoubtn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
	margin-bottom: 40px;
}
  a.thankyoubutton2:hover {
	cursor: pointer;
	background-color: #FFFFFF;
	border: 3px solid #A0FFA0;
	color: #000000;
  }
  .thankyou .thakyoutext2 h2 {
	color: #ffffff;
	font-size: 23.77px;
	font-weight: 700;
  }
  .thankyou .thakyoutext2 p.thankyousubtext2 {
	color: #ffffff;
	font-size: 15.34px;
	font-weight: 500;
	margin-bottom: 0;
  }
  .thankyou .thakyoutext2 span.thankyousubtext2 {
	color: #ffffff;
	font-size: 1.25rem;
	font-weight: 500;
  }
  .thakyoutext2 {
	margin-top: 70px;
  }
  a.thankyou2button {
	width: 150px;
height: 39.924px;
border-radius: 5px;
border: 1px solid #EF000B;
background: #FFF;
color: #EF000B;
text-align: center;
font-family: "Roboto Flex";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.8px;
	
	display: inline-block;
	padding: 8px 0;
	

  }
  a.thankyou2button:hover {
	cursor: pointer;
	border: 1px solid #a0ffa0;
	color: #036518;
	border: 3px solid #FFFFFF;
	background: #A0FFA0;

  }
  .thankyou2btn {
	margin-top: 30px;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesetwidth.active
	.icontwo {
	background: #cbeaff;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesetwidth.active {
	background: transparent;
	border: 4.71px solid #cbeaff;
  }
  .categorytwosetfirsttest .categoryonesettwo.active h5 {
	color: #000;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesetwidth.active:nth-child(2) {
	background: transparent;
	border: 4.71px solid #ffe8a4;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesetwidth.active:nth-child(3) {
	background: transparent;
	border: 4.71px solid #f6e4ff;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesetwidth.active:nth-child(3) {
	background: transparent;
	border: 4.71px solid #ffe0e0;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesetwidth.active:nth-child(4) {
	background: transparent;
	border: 4.71px solid #c0ffc7;
  }
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew
	.categorytwosetfirsttest
	.categoryonesetwidth.active:nth-child(5) {
	background: transparent;
	border: 4.71px solid #daf0ff;
  }
  a.registernowbutton:hover{
	
	border: 2px solid #00801D;
	color: #00801D;
    background-color: #FFFFFF;
  }
  section.select-main-cat {
    padding: 50px 0;
}
  .popupbutton a.popupbtn1:hover,
  .popupbutton a.popupbtn2:hover,
  .herosection.heronewaskexpert a.ctaonebutton:hover,
  .herosection.heronewaskexpert a.ctatwobutton:hover,
  a.nextbutton:hover {
	box-shadow:none;
    background: #FFFFFF;
    color: #000000;
    border: 3px solid #A0FFA0;
	box-shadow: 3.53px 3.53px 38.85px 0px #00000040;


  }
  .popupbutton a.popupbtn2:hover {
    background-color: #A0FFA0;
    color: #3C5100;
    border: 3px solid #FFFFFF;
}
  button.proceedbtn:hover{
	background: #EF000B;
	border: 1px solid #FFFFFF;
	color: #FFFFFF;


}
  .asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth.categoryonesettwo:hover .icontwo {
	background: #cbeaff;
  }
  .cliparttextboxtwo {
	box-shadow: 0px 0px 28.88px 0px #0977d8;
	width: 206.25px;
	height: 206.25px;
	background: #9bd0ff;
	border-radius: 25px;
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	flex-direction: column;
  }
 
  .cliparttextboxtwo .iconbase {
	width: 100px;
	height: 100px;
	background:linear-gradient(0deg, #cbeaff, #cbeaff);
	border-radius: 100px;
	position: relative;
  }
  .cliparttextboxtwo .iconbase img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
  .popupouter {
	position: fixed;
	top: 87px;
	left: 0;
	right: 0;
	z-index: 99;
	background: rgba(255, 255, 255, 0.6);
	width: 100%;
	height: 100%;
	
  }
  button.proceedbtn[disabled] {
	background: #FFFFFF;
    border: 1px solid #EF000B;
	box-shadow: 2px 2px 10px 0px #EF000B;
	backdrop-filter: blur(4px);
    box-shadow: none;
    color: #EF000B;
	box-shadow: 0px 3px 10px 0px #3D3D3D80;

    cursor: auto;
  }
  .proceed-main-btn button[disabled]:hover {
	background:FFFFFF ;
	border: 1px solid #EF000B;

	color: #EF000B;cursor: auto;
  }
  .question-text-right .proceedbtn button[disabled]:hover {
    background: #FFFFFF;
    color: #EF000B;
  }
  .asktheexpertconfirmpopup.popupouter .popup {
	padding: 50px;
  }
  div#askQus {
	/* box-shadow:0 -7px 18px -11px #3D3D3D80; */
	margin-top: 0px;
  }
  .register-main-text{
border-bottom: 0px solid transparent; 
  }

  .cliparttextboxinner {
    border: 10px solid #fff;
    position: relative;
	height: 206px;
}

.cliparttextboxinner:after {
    position: absolute;
    content: "";
    right: -20px;
    top: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #fff;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
}
.asktheexpertconfirmpopup.popupouter  .cliparttext .clipartmain .cliparttextbox p , .cliparttextboxouter p{
    font-size: 20.29px;
    color: #ffffff;
    font-weight: 700;
    text-align: left;
}
.asktheexpertconfirmpopup .cliparttext .clipartmain .cliparttextbox.cliparttextboxtwo
p {

font-size: 20px;
font-weight: 600;
line-height: 41px;
text-align: center;
color: #000;
margin-bottom: 0;
}
.clipart img {
    height: 265.42px;
}
.clipart {
    text-align: right;
}
.clipart-box {
    display: flex;
    justify-content: end;
    align-items: center;
}
.inner-askexpertnewlayout .categorytwosetfirsttest .categoryonesetwidth{width: 120px; height: 120px;}
.register-main .input:focus ~ .placeholder, .register-main .input:not(:placeholder-shown) ~ .placeholder {
    transform: translateY(-13px) translateX(-12px) scale(1);
    font-size: 12px;
}
.modalspecial.asktheexpertconfirmpopup > div{
	background-color: #036518;
	padding: 50px;
}
