.myadposterimage img {
    border-radius: 5px;  
}

.myadposterimage {
    text-align: center;
}

.myadposter {
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 30px;
    box-shadow: 4px 4px 6px 0px #06283D40;
    background: #D2FFD2;

}

.myadposter:hover {
    background: #D2FFD2;
}

.myadposterbanner {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 20px 0 10px 0;
}


  
  
  
  
  .edit_btn { 
    background: #86357A;

    border-radius: 5px;
    border: none;
    width: 30px;
    height: 30px;
    transition: 0.3s all ease-in;
    position: relative;
  }
  .edit_btn:hover {
    background: #86357A;
  }
  
  .edit_btn:hover img {
    filter: invert(1);
  }
  
  .edit_btn:before {
    border: 6px solid transparent;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    position: absolute;
    content: "";
  }
  
  .edit_btn:after {
    border: 6px solid transparent;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    content: "";
    
  }
  .edit_btn:hover:after {
    width: 100%;
    height: 100%;
    border-bottom-color: #FFF;
    border-right-color: #FFF; 
    border-radius: 5px;
  }
  .edit_btn:hover:before {
    width: 100%;
    height: 100%;
    border-top-color: #FFF;
    border-left-color: #FFF;
    border-radius: 5px; 
  }
  
  
  .edit_btn.absoluteup {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 999;
  }
  
  .edit_btn.absolutedown {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
  
  .edit_btn.absoluteup
  {bottom: 15px; top: auto;}
  .edit_btn.absolutedown
  {top: 15px; bottom: auto; z-index: 99;}
  
  .edit_btn svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
  }
  
  .edit_btn:nth-child(1) {
    margin-right: 10px;
  }
  
   