header .custom.nav {
    background: #fff;
    padding: 5px 0;
}
header{    max-width: 1366px;
    margin: 0 auto;
    width: 100%;}
ul.main-menu-nav{
    box-shadow: 0px 0px 9.6px 0px #00000059;
    border-radius: 100px;
    padding: 00px 40px;
    width: auto;
}

header li.nav-item a{display: block;}
span.menuicon {
    display: block;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-bottom: 5px;
    margin-top: 5px;

}
div#navbarSupportedContent ul li {
    padding: 0 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    transition: 0.3s all ease-in;
}
li.seacheader button {
    background: #005BAC;
    border-radius: 31px;
    padding: 11px;
    width: 37px;
    height: 37px;
    line-height: 0;
    border: none;
}
.footerlogo img {
    width: 270px;
}
li.headersignup a {
    border: 1.5px solid #000;
    text-decoration: none;
    padding: 5px 12px;
    border-radius: 5px;
    color: #000;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;

    height: 32px;
    display: inline-block;
    text-align: center;
    line-height: 1.3;
}
li.headersignup a{text-align: center;}
li.headerlogin.nav-item a.nav-link{text-align: center;}
li.headerlogin a {
    border-radius: 5px;
    background: #EF000B;
    padding: 5px 12px;
    color: #fff;
    text-decoration: none;
    border: 1.5px solid #EF000B;width: 90px;
    text-align: center;
}
div#navbarSupportedContent {
    justify-content: center;
}
header ul.navbar-nav li a:before, 
header ul.navbar-nav li a:after, 
header ul.navbar-nav li.active a:before, 
header ul.navbar-nav li.active a:after{}
header ul.navbar-nav li a:before {
    content: "";
    position: absolute;
    background: #4448FF;
    width: 0;
    height: 4px;
    opacity: 0;
    transition: all .35s ease;
}

div#navbarSupportedContent ul.navbar-nav li {position:relative;}
div#navbarSupportedContent ul.navbar-nav li:after
{
    content: '';
    position: absolute;
    background: url(../../../assets/homeimages/menuhovericonblue.svg);
    width: 35px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
}

.dropdown-toggle::after{display: none;}
div#navbarSupportedContent ul.navbar-nav a.nav-link.dropdown-toggle.show:after{
    content: "";
    position: absolute;
    background: url(../../../assets/homeimages/menuhovericonblue.svg);
    width: 35px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    opacity: 1;
    margin: auto;
    border: none;
display: block ; }

div#navbarSupportedContent ul.navbar-nav a.nav-link.dropdown-toggle.show span.menuicon .imagehov1{display: none;}
div#navbarSupportedContent ul.navbar-nav a.nav-link.dropdown-toggle.show span.menuicon .imagehov2{display: block ; margin: auto;}
div#navbarSupportedContent ul.navbar-nav a.nav-link.dropdown-toggle.show {color: #4448FF ;}
    div#navbarSupportedContent ul.navbar-nav a.nav-link.dropdown-toggle.show:before{content: "";
        position: absolute;
        background: #4448FF;
        width: 60%;
        left:0;right: 0;
        height: 4px;
        opacity: 1;
        transition: all .35s ease;
    margin: auto;
bottom: 0;}

div#navbarSupportedContent ul.navbar-nav li:hover:after{opacity: 1;margin: auto;}
div#navbarSupportedContent ul.navbar-nav li a:before {
    right: 0;
    bottom: 0px;
    opacity: 1;
}
div#navbarSupportedContent a.dropdown-item{    padding: 1px 30px;
}
.navbar-expand-lg .navbar-nav .dropdown-menu{width: 230px; border-radius: 10.833px;
    background: #FFF;
    box-shadow: 0px 0px 9.148px 0px rgba(0, 0, 0, 0.20); margin-top: 5px;}
div#navbarSupportedContent ul.navbar-nav li:hover a:before{width:60%; margin:auto; left:0}
div#navbarSupportedContent ul li.active:after{
    content: '';
    position: absolute;
    background: url(../../../assets/homeimages/menuhovericonblue.svg);
    width: 35px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 1;
    margin: auto;
}
div#navbarSupportedContent ul li:hover{}
    
    div#navbarSupportedContent ul li ul.dropdown-menu li:hover{
        background-image: none;
        background: #E7E5E5;
        border-radius: 11px;
    }
    div#navbarSupportedContent ul li ul.dropdown-menu li:after, div#navbarSupportedContent ul li ul.dropdown-menu li:before{
        display: none;
    }
    div#navbarSupportedContent ul li ul.dropdown-menu li a:after, div#navbarSupportedContent ul li ul.dropdown-menu li a:before{
        display: none;
    }
    img.imagehov2{display: none;}
    div#navbarSupportedContent ul.navbar-nav li a{border-bottom: 4px solid transparent;}
    div#navbarSupportedContent ul li:hover img.imagehov2{display: block;text-align: center;
        margin: auto;}
    div#navbarSupportedContent ul li:hover img.imagehov1{display: none;}
    div#navbarSupportedContent ul li ul.dropdown-menu li{padding: 5px 0;}
    div#navbarSupportedContent ul li ul.dropdown-menu li .dropdownmenu img{padding-right: 10px;}
    div#navbarSupportedContent ul li ul.dropdown-menu li .dropdownmenu {
        width: 36px;
        display: inline-block;
        text-align: center;
    }
    div#navbarSupportedContent ul li ul.dropdown-menu li .dropdownmenu img {
        height: 21px;
        object-fit: contain;
        margin: auto;
    }
    div#navbarSupportedContent ul li ul.dropdown-menu li:hover a{color:#000;}
div#navbarSupportedContent ul li.active span.menuicon .imagehov1 {display: none;  
}
div#navbarSupportedContent ul li.active span.menuicon .imagehov2 {display: block; text-align: center; margin: auto;
   
}
div#navbarSupportedContent  ul.navbar-nav li.active a:before{display: none;}
div#navbarSupportedContent  ul.navbar-nav li.active a:after{ content: "";
    position: absolute;
    background: #4448FF;
    width: 60%;
    height: 4px;
    opacity:1;
    transition: all .35s ease; bottom: 0; left: 0; right:0; margin: auto;}
div#navbarSupportedContent ul li.active a {
    color: #4448FF;
}

div#navbarSupportedContent ul li.active .dropdown-menu  a{color: #000;}
div#navbarSupportedContent ul li:hover a {
    color: #4448FF;
}
div#navbarSupportedContent ul li:hover ul.dropdown-menu a{color: #000;}
footer {
    background: #95B8DA;
}
.footerone p {
    text-align: left;
}
footer h3:before{display: none;}
.footerone, .footertwo {
    position: relative;
}
.scrolled-down header .sticky-top.fixed-top.custom.nav{padding: 3px 0;}
.footerone:after{
    content: "";
    position: absolute;
    right: -30px;
    height: 74%;
    width: 1px;
    top: 21px;
    background: #fff;
}
.footerone.footerthree ul.conde li{padding-right:0 ;    line-height: 20px;    max-width: 245px; margin-bottom: 10px;}
.footertwo:after{
    content: "";
    position: absolute;
    right: 26px;
    height: 82%;
    width: 1px;
    top: 21px;
    background: #fff;
}
.footerthree:after{display: none;}
footer .container {
    padding-bottom: 30px;
}


ul.socialin.d-flex.mb-3 li:last-child {
    margin-right: 0;
}
header li.nav-item:last-child {
    
  
}
div#navbarSupportedContent ul li.headersignup:hover, div#navbarSupportedContent ul li.headerlogin:hover {
    background: none;
}

div#navbarSupportedContent ul li.headersignup:hover a {
    background: #000;
}
header li.nav-item:last-child:hover{border: none;}
header .right-menu li.nav-item a, header .right-menu .navbar-nav .nav-link:hover, header .right-menu .navbar-nav .nav-link:active, header .right-menu .navbar-nav .nav-link:focus, header .right-menu .navbar-nav .nav-link:visited {
color: #000;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
header li.nav-item a, header .navbar-nav .nav-link:hover, header .navbar-nav .nav-link:active, header .navbar-nav .nav-link:focus, header .navbar-nav .nav-link:visited
{font-size: 14px;
font-weight: 600;
color: #000;
}
header li.nav-item:last-child a.nav-link {
}
div#navbarSupportedContent ul li a.nav-link {
    /* width: 100px; */
    text-align: center;
    line-height: 1;
    /* min-height: 70px; */
    padding-top: 5px;
    padding-bottom: 5px;
}
ul.navbar-nav.navbar-navright {
    justify-content: right;
    align-items: center;
}

li.headersignup {
    margin: 0 10px;
}
.main-menu-nav {
    background: #fff;
}
.scrolled-down header .custom.nav {
    background: #fff !important;
}
.customsearchblock input {
    background: url(../../../assets/sharedImages/search_header.png) rgba(255, 255, 255, 1);
    border: none;
    border-radius: 50px;
    padding: 17px;
    width: 100%;
    position: relative;
    background-position: 10px;
    background-repeat: no-repeat;
    padding-left: 70px;
   padding-right: 200px;
font-size: 20px;
font-weight: 500;
line-height: 24.38px;
text-align: left;
color: rgba(147, 147, 147, 1);

}

.customsearchblock {
    width: 90%;
    margin: auto;
    position: relative;
}

.customsearchblock .headersearch {
    background: rgba(0, 131, 29, 1);
    border-radius: 50px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    width: 145px;
    text-align: center;
    padding: 10px;
    color: #fff;
    position: absolute;
    right: 12px;
    top: 7px;
    border: none;
}
.customsearchblock .headersearch:disabled {
    opacity: 0.3;
}
li.nav-item.headerlogin a {
    color: #fff;
   
    font-size: 16px;
    width: 90px;
    height: 32px;
    text-align: center;
    line-height: 1.3;
}
header li.nav-item.headerlogin a {
    color: #fff !important;
}
div#navbarSupportedContent ul.navbar-nav li.active a.nav-link.dropdown-toggle.show:before {
    content: "";
    position: absolute;
    background: #4448FF;
    width: 60%;
    height: 4px;
    opacity: 1;
    transition: all .35s ease;
    bottom: -83px;
    left: 0;
    right: 0;
    margin: auto;
    display: block !important;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .customsearchblock input {
       
        padding: 17px;
        width: 100%;
        position: relative;
        background-position: 10px;
        background-repeat: no-repeat;
        padding-left: 40px;
        padding-right: 154px;
        font-size: 15px;
       
    }
    button#mobilemenu {
        position: absolute;
        left: 15px;
        padding: 0;
        top: 11px;
    }
    .navbar .container{flex-wrap: nowrap;}

    li.headersignup a{height: 30px;}
    li.headersignup a{width: 80px;}
    li.headerlogin.nav-item a.nav-link {
        text-align: center;
        font-size: 12px !important;
        height: 30px;
    }
    .customsearchblock {
        width: 100%;
        margin: auto;
        position: relative;
    }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1023px)
{
    ul.main-menu-nav {
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        margin-right: 0;
        width: 120px;
        height: 100vh;
    }
    div#navbarSupportedContent {
        position: absolute;
        top: 47px;
        left: 0;
        width: 100%;
        background: hsla(0, 0%, 100%, .7);
        height: 100vh;
    }
    #navbar_top a.navbar-brand {
        margin-left: 30px;
        padding: 0;
        margin-right: 0;
    }
}
@media(max-width:767px){
    header
    {
        background: url(../../../assets/homeimages/home_banner_background.svg);
    }
    header li.nav-item a, header .navbar-nav .nav-link:hover, header .navbar-nav .nav-link:active, header .navbar-nav .nav-link:focus, header .navbar-nav .nav-link:visited {
        font-size: 11px;
        font-weight: 600;
        color: #000;
    }
    .scrolled-down header .custom.nav{
        background-image: url("../../../assets/homeimages/home_banner_background.svg") !important;
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
    }
    div#navbarSupportedContent ul.navbar-nav li:last-child:before{display: none;}
    .footerlogo img{ width: 130px;}
    
    .customsearchblock input{padding-right: 94px !important;}
    div#navbarSupportedContent ul.navbar-nav li {
        position: relative;
    }
    div#navbarSupportedContent .headersignup {margin: 15px auto;}
    div#navbarSupportedContent ul.navbar-nav li:hover:after{display: none;}
    div#navbarSupportedContent .headerlogin{margin: 0 auto; } 
    div#navbarSupportedContent .headerlogin a{color: #fff;background: #A20005;}
    div#navbarSupportedContent ul.navbar-nav li.headersignup:before{display: none;}
    div#navbarSupportedContent ul.navbar-nav li:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
        background-color: rgba(0, 91, 172, 0.50);
        width: 80px;
        margin: auto;
       
    }
    div#navbarSupportedContent ul.navbar-nav li:nth-child(6):before{display: none;}
    div#navbarSupportedContent ul li.active.headerlogin{
        background: transparent;
    }
    div#navbarSupportedContent ul.navbar-nav li.active:after, div#navbarSupportedContent ul.navbar-nav li.active a:after
    {display: none;}
    li.headersignup a, li.headerlogin a{        
        width: 80px !important;
        font-size: 12px !important;
        padding: 3px;
        height: 25px !important;
        line-height: 1 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    text-transform: uppercase;} 
    li.headerlogin a{border:1px solid transparent !important}
        li.headersignup a   {border: 1px solid #000 !important;}
    li.nav-item.headerlogin a {
        color: #fff;
        padding: 0px 5px; line-height: 1.9 !important;}
    
    .topproductcat.showEnable {
        height: auto;
    }
    button#mobilemenu {
        border: none;
    }
    li.headersignup {
        margin: 0 2px;
    }
    li.seacheader button{padding: 4px; margin-right: 5px;}
    button#mobilemenu span.navbar-toggler-icon {
        position: absolute;
        left: 2px;
        top:9px;
        background: none;
    }
    .scrolled-down button#mobilemenu span.navbar-toggler-icon{top:9px;}
    li.seacheader button{width: 27px;
        height: 27px;}
    button#mobilemenu span.navbar-toggler-icon img {
        width: 16px;
    }
    div#navbarSupportedContent a.dropdown-item {
        padding: 1px 15px;
    }
    nav#navbar_top {
        padding: 5px 5px;
    }
    .navbar-toggler-icon img{    filter: invert(0);border-radius: 2px;}
    div#navbarSupportedContent {
        position: absolute;
        top: 40px;
        left: 0px;
        width: 100%;
        background: rgba(255, 255, 255, 0.7);
        height: 100vh;
    }
    #navbar_top a.navbar-brand {
        margin-left: 30px;
        padding: 0;
        margin-right: 0;
    }
    div#navbarSupportedContent ul li.active, div#navbarSupportedContent ul li:hover{
         background: rgba(0, 91, 172, 1); 
        border-radius: 0 10px 9px 0px;
    }
    div#navbarSupportedContent ul li.active span.menuicon .imagehov2, div#navbarSupportedContent ul li:hover span.menuicon .imagehov2{
        display: none;
    }
    div#navbarSupportedContent ul li.active span.menuicon .imagehov1, div#navbarSupportedContent ul li:hover span.menuicon .imagehov1{
        display: inline-block;
        filter: invert(1);
    }
    div#navbarSupportedContent ul li.active a, div#navbarSupportedContent ul li:hover a{
        color: #fff;
    }
    li.seacheader button img {
        width: 16px;
    }
    
 
    div#navbarSupportedContent ul li a.nav-link {
        width: 100%;
    
    }
    span.menuicon {
        margin-bottom: 10px;
        margin-bottom: 5px;
    }
    #navbar_top a.navbar-brand img {
        width: 83px;
    }
    span.menuicon img {
        height: 15px;
        object-fit: contain;
        width: auto;
    }
    ul.main-menu-nav {
        box-shadow: none;
        border-radius: 0;
        padding: 00px 0px;
        margin-right: 0;
        width:125px;height: 100vh;
    }
    .footerone ul li .footericon img{width: 15px;}
    .footerone.footerthree ul.conde li{padding-left: 20px;}
    ul.navbar-nav.navbar-navright{flex-flow: row;}
    .navbar-nav .dropdown-menu {
        position: absolute;
        top: -3px;
        left: 160px;
    }
    div#navbarSupportedContent ul li {
        padding: 0 10px;
    }
    li.headerlogin a{width: auto;}
    .navbar-expand-lg .navbar-nav .dropdown-menu a{
        color: #4A4A4A;
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: normal;
    }
  
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        width: 184px;
    }
}
@media (max-width:420px){
    li.headersignup{
        margin: 0 5px;
    }
}
@media (max-width:340px){
    nav#navbar_top {
        padding: 5px 0;
    }
    #navbar_top a.navbar-brand img {
        width: 83px;
    }
    .navbar-toggler-icon img{width: 25px;}
    li.headersignup a, li.headerlogin a {
        width: 80px !important;
        font-size: 12px !important;
        border-radius: 2px;
    }
    #navbar_top a.navbar-brand{margin-left: 46px;}
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        width: 171px;
    }
}