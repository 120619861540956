/* .insurancenewui .categorybannertext {
    position: absolute;
    left: auto;
    bottom: 0;
    width: 50%;
    text-align: left;
    height: 606px;
    top: 0;
    margin: auto;
    right: auto;
} */
.insurancenewui .categorybanner{background: url(../../../assets/insuranceimges/Insurance_back.svg);}

.insurancenewui .bannerfont h2, .insurancenewui .applynowbuttoncomm {
    color: var(--orange);}
.insurancenewui .applynowbuttoncomm{border-color: var(--orange); background: #FFEADC;}
.insurancenewui .categorysectionone .offer-inner .categotyinpopfl .pscat:after{ background: var(--orange);}
.insurancenewui .categorysectiontwo, .insurancenewui .categorysectionthree{
    background: url(../../../assets/insuranceimges/Productsbg.png);
    
}
.insurancenewui .viewdetailsbuttoncomm{background: var(--orange)}
.insurancenewui  .bannerfont h2:after{background: var(--orange)}
.insurancenewui .Expertsbutton{background: #FFEADC;}
.insurancenewui .viewdetailsbuttoncomm:hover {
    color: var(--orange);
    background-color: #FFEADC;
    cursor: pointer;
    border-color: var(--orange);
}
.insurancenewui  .bannerfont.borderbottom span {
    color: var(--orange);}
/* .insurancenewui .categorysectionthree span.canada{color: var(--orange);} */
.insurancenewui .categorysectionfour .Expertstop img{border-color: #FFEADC;}
.insurancenewui .messagemebuttoncomm{background: var(--orange);}
.insurancenewui .categorysectionone .slick-prev:before, .insurancenewui .categorysectionone .slick-next:before, .insurancenewui .categorysectiontwo .slick-prev:before, .insurancenewui .categorysectiontwo .slick-next:before, .insurancenewui .categorysectionthree .slick-prev:before, .insurancenewui .categorysectionthree .slick-next:before, .insurancenewui .categorysectionfour .slick-prev:before, .insurancenewui .categorysectionfour .slick-next:before
{color: var(--orange);}
.insurancenewui .categorysectionsix.blogdetailsexpertppv{background: url(../../../assets/insuranceimges/blogsbginc.png);}
.insurance-bgimg{

 background: url(../../../assets/insuranceimges/Productsbg.png);
}
