.whatarecatbox {
    width: 100px;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.whatareyou .whatareyoucat .whatarecatbox.active {
    cursor: pointer;
    background-color: #009F23;
}
.whatarecatbox.active p {
    color: #fff;
}
.whatarecatbox:before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    background: #BDBDBD;
    height: 100%;
    width: 1px;
    bottom: 0;
    margin: auto;
}
.whatarecatbox img {
    width: auto;
    margin-bottom: 0;
}
.whatarecatbox p {
    /* color: #000000; */
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 14px;
    padding: 0 5px;
    line-height: 17.07px;
    text-align: center;
}
.whatarecatbox.active {
    cursor: pointer;
    background-color: #009F23;
}
.whatarecatbox.active p {
    color: #fff;
}
.whatarecatbox.active:after {
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -10px;
    content: "";
    display: block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #009F23;
}
.what-res{position: relative;}