.Ourintitutions {
	background-image: url("../../../assets/homeimages/Ourintitutions.png");
	background-repeat: no-repeat;
	width: 100%;
	max-height: 560px;
	background-size: cover;
	margin-bottom: 00px;
	padding: 40px 0px 20px 0;
}


.Ourintitutions ::-webkit-scrollbar {
	width: 12px !important;
}

.Ourintitutions ::-webkit-scrollbar-track {

	border-radius: 10px !important;
	background-color: #a075754f !important;
}

.Ourintitutions ::-webkit-scrollbar-thumb {
	background: white !important;
	border-radius: 10px !important;
}

.Ourintitutions ::-webkit-scrollbar-thumb:hover {
	background: white !important;
}

 


.ourbestimg {
	/* display: flex;
    justify-content: space-evenly; */
	display: inline-block;
	width: 50%;
	margin: auto;
	text-align: center;
}

.ourbestimgbox {
    max-width: 130px;
    height: 130px;
    margin: 0px 15px 20px;
    background-color: white;
    padding: 22px;
    border-radius: 20px;
    align-items: center;
    display: flex;
    justify-content: center;
    box-shadow: 2.262px 2.262px 14.884px 0px rgba(0, 0, 0, 0.25);
}

.ourbestimgbox img {
	width: 210px;
	height: auto;
	display: block;
}


.showallourbesttoo:hover {
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #EF000B;
	background: #EF000B;
	color: #FFF !important; 
}

.showallourbesttoo {
	background-color: white;
	border: 1px solid #EF000B;
	padding: 8px 19px;
	border-radius: 5px;
	display: inline-block !important;
	width: 150px !important;
	height: 40px;
	color: #EF000B;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif;
	margin-left: auto;
	margin-right: auto;
}


.ourbestrighttext {
	margin-left: 50px;
}

.ourbestrighttext h2 {
	font-size: 30px;
	font-weight: 700;
	color: #FFFFFF;
	max-width: 500px;
	position: relative;
	margin-bottom: 20px;
}
.ourbestrighttext h2:before {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 78%;
    background: #036518;
    height: 6px;
}


.ourbestrighttext p {
	font-size: 20px;
	font-weight: 700;
	color: #FFFFFF;
	max-width: 450px;
	text-align: start;
	margin-top: 30px;
	line-height: 24.38px;
	
}
.ourbestrighttext p:nth-child(3), .ourbestrighttext p:nth-child(4){margin: 0 !important;}

.our-best-alignment{
	display: flex;
	align-items: center;
	justify-content: center;
}
 
.categorylogosection{
    padding-left: 0;
    max-width: 640px;
    margin: auto;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
	.categorylogosection{    max-width: 320px;}
} 
@media (max-width:767px){
	.showallourbesttoo
	{
		padding: 0px;
        height: 25px;
        font-weight: 500;
        line-height: 2;
        letter-spacing: 1.2px;
        text-align: center;
        width: 90px !important;
        font-size: 12px;
		border-width: 1px;
		border-radius: 1px;
	}
	.ourbestrighttext{
		margin-left:0px
	}
	.ourbestrighttext p
	{
		font-family: "SF Pro Text" !important;
	font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    max-width: 100%;
    text-align: center;
    margin-top: 30px;
    line-height: 1.4;
	margin-top: 9px;
	margin-bottom: 0;
	}
	.ourbestimgbox{
		max-width: 100px;
		height: 100px;
		margin: 0px 10px 10px;
		background-color: white;
		padding: 10px;
		border-radius: 10px;
		align-items: center;
		display: flex	;
		justify-content: center;
		box-shadow: 2.262px 2.262px 14.884px 0px rgba(0, 0, 0, 0.25);
	}
	.ourbestimg a{display: flex; justify-content: center;}
	.ourbestrighttext h2{
		font-size: 18px;
		text-align: center;
	}
	.ourbestrighttext h2:before{
	content: "";
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 48%;
    background: #036518;
    height: 3px;
    right: 0;
    margin: auto;
	}
	.ourbestimgslider{
		margin-top: 20px;
	}
	.categorylogosection{
		max-width: 320px;
		width: 320px;
	}
}

 