.blogsmain {
    min-height: 155px;
    max-width: 630px;
    border-radius: 10px;
    position: relative;
    border: 1px solid #fff;
    background: #fff;
    padding: 0 29px 17px;
    box-shadow: 3px 3px 33px 0 rgba(0, 0, 0, .25);
}

.bloginner {
    text-decoration: none;
}
.bloginnerdate a{color:#000;}
p.articletitle, p.articlesubtitle{margin-bottom: 0;}
.bloginnerimage {
    margin: auto;
    text-align: center;
}
p.articleauthor{
    font-family: "Microsoft", sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #000000;
    margin-bottom: 5px;
}
p.articledatepublish{
    font-family: "Microsoft", sans-serif !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #7D7D7D;
}
.bloginnerimage img {
    height: 120px;
    object-fit: cover;
    border-radius: 200px;
    width: 120px;
    border: 5px solid #fff;
    position: absolute;
    top: -68px;
    margin: auto;
    left: 0;
    right: 0;
}

.bloginnertext {
    color: #2a2a2a;
    font-family: "Montserrat", sans-serif !important;
    min-height: 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.5px;
    margin-bottom: 5px;
    margin-top: 55px;
}

.bloginnerdate {
    color: #2a2a2a;
    text-align: center;
    font-family: "microsoft_sans_serifregular" !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14.71px;
}

.text-center {
    text-align: center;
}

.articlecard {
    border-radius: 20px;
    padding: 17px 0px 17px 0px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    margin: 20px 0px 20px 0px;
    cursor: pointer;
}

 


.articlecard:nth-child(1) {
    background-color: #DAF0FF;
}

.articlecard:nth-child(2) {
    background-color: #EEECFB;
}

.articlecard:nth-child(3) {
    background-color: #FFEBAF;

}

.articlecard:nth-child(4) {
    background-color: #DAF0FF;

}

.articlecard:nth-child(5) {
    background-color: #EEECFB;
}

.articlecard:nth-child(6) {
    background-color: #DAF0FF;

}

.articlecard:nth-child(7) {
    background-color: #EEECFB;
}

.articlecard:nth-child(8) {
    background-color: #FFEBAF;

}

.articlecard:nth-child(9) {
    background-color: #DAF0FF;

}

.articlecard:nth-child(10) {
    background-color: #EEECFB;
}

.articlecard:nth-child(11) {
    background-color: #DAF0FF;
}


.articlecardimg img {
    border-radius: 100px;
    height: 138px;
    width: 138px;
    object-fit: cover;
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
}




@media (min-width: 767px) {

    .articlehading {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 30px;
    }


    .articletitle {
        width: 95%;
        font-size: 24px;
        font-weight: 600;
    }

    .articlesubtitle {
        width: 95%;
        font-size: 16px;
    }
}


@media (max-width: 767px) {



    .articlecard {
        border-radius: 20px;
        padding: 10px 0px 10px 0px;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        margin: 20px 0px 20px 0px;
        cursor: pointer;
    }

    .articlecardimg img {
        border-radius: 100px;
        height: 70px;
        width: 70px;
        object-fit: cover;
        display: flex;
        align-items: center;
        margin: 0 auto;
        justify-content: center;
    }


    .articletitle {
        width: 95%;
        font-size: 13px;
        font-weight: 600;
    }

    .subtitle {
        width: 95%;
        font-size: 8px;

    }

}