@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
	.publicmainpopdispp{width: 100%;padding: 15px;}
	.institutenewdesignppvinner .publicviewprofilede {
		width: 74%;
		border-right: 4px solid gray;
		padding: 0;
	}
	
	.institutenewdesignppvinner ul.deone li a {
		color: #000;
		font-family: Montserrat;
		font-size: 12px;
	}
	.institutenewdesignppvinner .socialpublic {
		position: absolute;
		right: -6px;
	}
	.publicviewprofiledetails .publicpersonaldetails {
		background: var(--green);
		padding: 10px;
		width: 100%;
		border-radius: 7px;
		text-align: center;
		position: absolute;
		max-width: 320px;
		right: 41px;
		bottom: 26px;
	}
	.institutenewdesignppv section#ppvOfferSection {
		padding: 20px 0;
		margin-top: 0;
	}
	.service-offer .heading h2{padding-top: 15px;}
	.service-offer .heading img {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		height: 183px;
		object-fit: cover;
		border-radius: 30px 30px 0 0px;
	}
	.disgrid.grid-8.gap-0.mt-5{
		grid-template-columns: 1fr 1fr 1fr;
	}
	.serviceoffer-inner{margin-top: -30px;}
	.ppvexpertsecnew .grid-2{grid-template-columns: 1fr}
.listofoffermainsection .offerlistpadding{
	width: 100%;
	padding: 15px;
}
}
@media (max-width: 767px) {
	button.serviceshowhide {
		width: 100%;
		display: flex
	;
		grid-column: 1 / -1;
		margin-top: 20px;
		padding: 0px;
		height: 25px;
		font-weight: 500;
		line-height: 2;
		letter-spacing: 1.2px;
		text-align: center;
		width: 90px !important;
		font-size: 12px;
		border-radius: 2px;
		border-width: 1px;
		background-color: white;
		border: 1px solid #EF000B;
		color: #EF000B;
		justify-content: center;
		align-items: center;
		margin: 20px auto 0;
	
	}
	section.service-offer.false li{display: none;}
	section.service-offer.show-all-active li{display: block;}
	section.service-offer.false li:nth-child(1), section.service-offer.false li:nth-child(2), section.service-offer.false li:nth-child(3),
	section.service-offer.false li:nth-child(4), section.service-offer.false li:nth-child(5), section.service-offer.false li:nth-child(6){
		display: block;
	}
	section.institutenewdesignppv.Banking section.limitedtimeoffer{
		background: url(../../../assets/Instituteppvimages/insppvOfferbanking.svg);
		background-size: cover;
		background-position: center center;
	}
	section.institutenewdesignppv.Real.Estate section.limitedtimeoffer{
		background: url(../../../assets/Instituteppvimages/insppvOfferrealestate.svg);
		background-size: cover;
		background-position: center center;
	}
	section.institutenewdesignppv.Investment section.limitedtimeoffer{
		background: url(../../../assets/Instituteppvimages/insppvOfferinvestment.svg);
		background-size: cover;
		background-position: center center;
	}
	section.institutenewdesignppv.Insurance section.limitedtimeoffer{
		background: url(../../../assets/Instituteppvimages/insppvOfferinsurance.svg);
		background-size: cover;
		background-position: center center;
	}
	section.institutenewdesignppv.Mortgage section.limitedtimeoffer{
		background: url(../../../assets/Instituteppvimages/insppvOffermortgage.svg);
		background-size: cover;
		background-position: center center;
	}
	section.institutenewdesignppv.Mortgage 	.institutenewdesignppvinner .publicviewprofiledetails{
		background: url(../../../assets/Instituteppvimages/insppvmortgagebanner.svg);
		background-size: cover;
		background-position: center center;
	}
	section.institutenewdesignppv.Real.Estate 	.institutenewdesignppvinner .publicviewprofiledetails{
		background: url(../../../assets/Instituteppvimages/insppvrealestatebanner.svg);
		background-size: cover;
		background-position: center center;
	}
	section.institutenewdesignppv.Insurance .institutenewdesignppvinner .publicviewprofiledetails{
		background: url(../../../assets/Instituteppvimages/insppvinsurancebanner.svg);
		background-size: cover;
		background-position: center center;
	}
	section.institutenewdesignppv.Investment .institutenewdesignppvinner .publicviewprofiledetails{
		background: url(../../../assets/Instituteppvimages/insppvinvestmentbanner.svg);
		background-size: cover;
		background-position: center center;
	}
	section.institutenewdesignppv.Banking 	.institutenewdesignppvinner .publicviewprofiledetails{
		background: url(../../../assets/Instituteppvimages/insppvbankingbanner.svg);
		background-size: cover;
		background-position: center center;
	}





	section.institutenewdesignppv.Mortgage 	.institutenewdesignppvinner ul.deone li span{
		border-radius: 4.441px;
		background: #2F596F;
	}
	section.institutenewdesignppv.Real.Estate .institutenewdesignppvinner ul.deone li span{
		border-radius: 4.441px;
background: #00831D;
	}
	section.institutenewdesignppv.Insurance .institutenewdesignppvinner ul.deone li span{
		border-radius: 4.441px;
		background: #FF4C00;
	}
	section.institutenewdesignppv.Investment .institutenewdesignppvinner ul.deone li span{
		border-radius: 4.441px;
		background: #86357A;
	}
	section.institutenewdesignppv.Banking .institutenewdesignppvinner ul.deone li span{
		border-radius: 4.441px;
		background: #2280B8;
	}





	section.institutenewdesignppv.Mortgage .institutenewdesignppvinner ul.deone li	{
		border-radius: 4.441px;
		background: #E9EEF9;
	}
	section.institutenewdesignppv.Real.Estate .institutenewdesignppvinner ul.deone li{
		border-radius: 4.441px;
background: #B9E8C0;
	}
	section.institutenewdesignppv.Insurance .institutenewdesignppvinner ul.deone li {
		border-radius: 4.441px;
		background: #FFE3D0;
	}
	section.institutenewdesignppv.Investment .institutenewdesignppvinner ul.deone li{
		border-radius: 4.441px;
		background: #FFE0FF;
	}
	section.institutenewdesignppv.Banking .institutenewdesignppvinner ul.deone li{
		border-radius: 4.441px;
		background: #DAF3FF;
	}



	section.institutenewdesignppv.Mortgage  .publicviewprofiledetails .publicpersonaldetails	{
		border-radius: 7px;
		background: #2F596F;
	}
	section.institutenewdesignppv.Real.Estate .publicviewprofiledetails .publicpersonaldetails {
		border-radius: 7px;
background: #00831D;
	}
	section.institutenewdesignppv.Insurance .publicviewprofiledetails .publicpersonaldetails {
		border-radius: 7px;
		background: #FF4C00;
	}
	section.institutenewdesignppv.Investment .publicviewprofiledetails .publicpersonaldetails {
		border-radius: 7px;
		background: #86357A;
	}
	section.institutenewdesignppv.Banking .publicviewprofiledetails .publicpersonaldetails {
		border-radius: 7px;
		background: #2280B8;
	}


	section.institutenewdesignppv.Mortgage	.bgmortgage {display: block;}
	section.institutenewdesignppv.Real.Estate .bgrealestate {display: block;}
	section.institutenewdesignppv.Investment .bginvestment {display: block;}
	section.institutenewdesignppv.Insurance .bginsurance {display: block;}
	section.institutenewdesignppv.Banking .bgbanking {display: block;}


	.bgmortgagedesktop {display: none !important;}
	.bgrealestatedesktop {display: none !important;}
	.bginvestmentdesktop {display: none !important;}
	.bginsurancedesktop {display: none !important;}
	.bgbankingdesktop {display: none !important;}



	#ppvProductSection h5, #ppvProductSection h6{font-family: "SF Pro Display" !important;}
.institutenewdesignppvinner .publicviewprofiledetailslogoupload{
	text-align: center;
	}
.institutenewdesignppv .cstlist{display: flex;justify-content: space-between;align-items: center;}
.institutenewdesignppv .cstlist:has(:only-child) {justify-content: center;  }
.institutenewdesignppv .cstlist:has(:nth-child(4)), .institutenewdesignppv .cstlist:has(:nth-child(3)), .institutenewdesignppv .cstlist:has(:nth-child(2)) { 
justify-content: space-around;				  }
	.institutenewdesignppv .offersss .applybutton:hover{margin: 10px 0 !important;}
	.modalspecial.productdetail-modal .detailedtext .ctaonebutton, .modalspecial.productdetail-modal .detailedtext .ctaonebutton, .modalspecial.productdetail-modal .detailedtext .ctaonebutton, .modalspecial.productdetail-modal .detailedtext .ctaonebutton, .modalspecial.productdetail-modal .detailedtext .ctaonebutton{
		margin-left: auto;
        margin-right: auto;
        width: 95px !important;
        height: 25px !important;
        font-size: 12px !important;
        display: flex
;
        justify-content: center;
        align-items: center;
        padding: 0;
	}
	.expertsection.martop30.disgrid.grid-2 {
		display: flex;
		flex-wrap: wrap;
	}
	.entireexpertppv  .institutesearchmainlistingsection .statusundefined {
		margin-bottom: 10px;
	}
	section.entireexpertppv.listofoffermainsection .blog-image, section.entireexpertppv.listofoffermainsection .blog-image img{
		width: 85px;
        border-radius: 0;
        height: auto;
        object-fit: contain;
		box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
	}
	section.entireexpertppv.listofoffermainsection .col-10 {
		flex: 0 0 auto;
		width: 73.333333%;
	}
	section.entireexpertppv.listofoffermainsection .col-2 {
		flex: 0 0 auto;
		width: 23.7777%;
	}
	.listofoffermainsection section.exclusive.offerlistpadding h2, .listofoffermainsection .exclusive h6{ text-align: center;}
	.listofoffermainsection .exclusive h6{margin-bottom: 10px;}
	.listofoffermainsection .institutesearchmainlistingsection ul{
		text-align: left;
	}
	section.entireexpertppv.listofoffermainsection .bloginnertext
	{        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
		font-family: "microsoft_sans_serifregular" !important; text-align: left;}
		section.entireexpertppv.listofoffermainsection p.bloginnertextfe{
			color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
		font-family: "microsoft_sans_serifregular" !important; text-align: left;
		}
.expertformppi .grid-2{grid-template-columns: 1fr;}
	section .expertformppi .input-container-git input,
	section .expertformppi .input-container-git select,
	section .expertformppi .input-container-git textarea {
		width: 100%;
	}
	.expertformppi  span.text-danger {
		position: absolute;
		font-size: 10px;
		left: 0;
		line-height: 12px;
		bottom: -6px;
		
	}
	.institutenewdesignppvinner .publicviewprofilede .Ppvlocation a {
		color: #000;
		font-family: "microsoft_sans_serifregular";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
    .service-offer .container-fluid{padding: 0;}
	section .expertformppi .input-container-git input,
	section .expertformppi .input-container-git select,
	section .expertformppi .input-container-git textarea {
		box-shadow: none;
		border-radius: 2px;
		/* box-shadow: 0px 0px 8px 0px #FFF; */
		border: 1px solid #000;
		margin-bottom: 10px;
		color: #000;
	}

	.limitedofferinner.ppvofferinnersection .applybutton {
		padding: 2px 5px;
		border-width: 1px;
		border-style: solid;
		border-radius: 2px;
		margin-left: 20px;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 20px;
		font-family: "Roboto Flex", sans-serif;
		height: 25px;
		width: 90px;
		display: inline-block;
	}
	#getname::placeholder, #gettel::placeholder, #getemail::placeholder, #gettext::placeholder, section.expertformppi h2{
		color: #000 !important;
		}
	 	span.formfieldicon img {
			filter: invert(1);
		}
	.institutenewdesignppv a.showallbtn,
	.institutenewdesignppv a.topproductshowallbtn {
		width: 95px;
		height: 25px;
		padding: 5px;
		font-size: 12px;
		border-radius: 2px;
	}

	#ppvAdsSection .container,
	#ppvProductSection .ppvProductSection,
	.institutenewdesignppv .products-list .col-lg-6.col-md-6.col-sm-12.col-xs-12,
	#ppvAdsSection .container,
	#ppvOfferSection .container,
	#ppvOfferSection .container .offersss .col-lg-12.col-md-12.col-sm-12.col-xs-12 {
		padding: 0;
	}

	#ppvAdsSection .container .sliderContent {
		margin: 20px 0;
	}

	.serviceoffer-inner .disgrid.grid-8 {
		grid-template-columns: 1fr 1fr 1fr;
		margin-top: 0 !important;
	
	}
	.serviceoffer-inner .disgrid.grid-8 li:nth-child(3n+3){
		border-radius: 0 10px 10px 0px;
	}
	.serviceoffer-inner .disgrid.grid-8 li:nth-child(3n + 4) {
		border-radius: 10px 0px 0px 10px;
	}
	.serviceoffer-inner li:nth-child(1){
		border-radius: 10px 0px 0px 10px;
	}
	.serviceoffer-inner li {
		width: 105px;
		height: 110px;
        position: relative;
		box-shadow:0.475px 4.475px 3.225px 2px rgba(0, 0, 0, 0.15);
	}
    .serviceoffer-inner li::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 1px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
        right: 0;
        top: 0;
    }
  
	section.expertformppi
   {
       background:url(../../../assets/expertppvimage/mobilegetintouchimage.svg);
       background-repeat: no-repeat;
       background-size: cover;
	   background-position: center center;
   
   }
   .listofoffermainsection .parentcatname, .listofoffermainsection .sliouter .productcatname{display: block !important;}
   .listofoffermainsection .sliouter .productcatname.d-none.d-sm-block{display: none !important;}  
   .ppvexpertsecnew {
        margin-bottom: 20px;
    }
	.service-offer .heading h2,
	.ppvexperttitle h2,
	section.expertformppi h2 {
		padding-top: 0px;
		font-size: 18px;
		line-height: 1;
	}

	section.service-offer h3,
	.ppvexperttitle h4 {
		color: #000;
		font-size: 12px;
		line-height: 1.2;
		font-family: 'SF Pro Display' !important;
	}
	section.listofoffermainsection .offerlistpadding h6{font-family: 'SF Pro Display' !important;}
	.tabmain.parentInstitutionCategory-id1 .categoryproductsliderinner{border-bottom:1px solid #FF4C00;}
	.tabmain.parentInstitutionCategory-id2 .categoryproductsliderinner{border-bottom:1px solid #86357A;}
	.tabmain.parentInstitutionCategory-id3 .categoryproductsliderinner{border-bottom:1px solid #006074;}
	.tabmain.parentInstitutionCategory-id4 .categoryproductsliderinner{border-bottom:1px solid #2280B8;}
	.tabmain.parentInstitutionCategory-id5 .categoryproductsliderinner{border-bottom:1px solid var(--green);}

	section.service-offer h3 {
		color: #000;
		font-size: 12px;
		max-width: 80%;
		margin: auto;
		font-family: "SF Pro Display" !important;
	}
	section#ppvProductSection .container{padding: 0;}
	.service-offer .heading h2 {
		padding-top: 15px;
		margin-bottom: 4px;
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;
		color: #000;
	}

	.institutenewdesignppvinner .publicviewprofilede {
		width: 100%;
		border-right: none;
		order: 1;
	}

	.serviceoffer-inner li h5 {
		font-size: 10px;
		line-height: 1;
	}

	.serviceoffer-inner li img {
		width: 30px;
	}

	.institutenewdesignppvinner .publicviewprofilede h2.h2_style {
		text-align: center;
		font-size: 18px;
		margin-top: 20px;
		padding-top: 10px;
		border-top: 1px solid #000;
		width: max-content;
		margin-left: auto;
		margin-right: auto;
	}

	.publicmainpopdispp {
		width: 100%;
		padding: 30px;
		flex-wrap: wrap;
	}

	.institutenewdesignppv .limitedofferinner.ppvofferinnersection {
		margin: 20px 0;
	}

	.institutenewdesignppvinner ul.deone li span {
		position: absolute;
		left: 0;
		top: 5px;
		border-radius: 5px;
		border: 1px solid white;
		background: #368b43;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 24px;
		transform: scale(1.2);
	}

	.institutenewdesignppvinner ul.deone li a {
		font-weight: 400;
		font-size: 12px;
		font-family: "microsoft_sans_serifregular";
		text-shadow: none;
	}

	.institutenewdesignppvinner ul.deone li {
		margin-right: 0;
		margin-bottom: 10px;
		width: 100%;
		padding-right: 0;
	}

	.institutenewdesignppvinner .pscatbottom:nth-child(2):after {
		width: 133px;
	}

	.institutenewdesignppvinner .publicviewprofilede .Ppvlocation {
		position: relative;
		padding-left: 25px;
	}

	.institutenewdesignppvinner .publicviewprofilede .Ppvlocation img {
		position: absolute;
		left: 0px;
	}

	.publicviewprofiledetails .publicpersonaldetails {
		background: var(--green);
		padding: 10px 20px;
		width: max-content;
		border-radius: 7px;
		text-align: center;

		margin-top: -20px;
		position: absolute;
		max-width: 320px;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.institutenewdesignppvinner .publicviewprofiledetails {
		padding: 55px 0px 50px 0px;
	}

	.institutenewdesignppvinner .socialpublic ul li {
		background: #fff;
		border-radius: 30px;
		margin-bottom: 0;
		margin-right: 15px;
		filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25));
	}
	.institutenewdesignppvinner .socialpublic ul li:last-child{margin-right: 0;}
	section.expertformppi h2,  .expertformppi h4{text-align: center;}
	.institutenewdesignppv .articles {
        margin-top: 0px;
        margin-bottom: 0;
    }
	.ctatwobutton-git{
		width: 90px;
height: 25px;
background:#71D076;
font-size: 12px;
border-radius: 2px;
	}
	.institutenewdesignppvinner .socialpublic {
		position: absolute;
		width: 100%;
		top: 15px;
		right: 0;
		height: max-content;
		left: 0;
		margin: auto;
		bottom: auto;
	}

	.institutenewdesignppvinner .socialpublic ul {
		display: flex;
		justify-content: center;
	}

	.slick-prev {
		left: 0;
	}

	.slick-next {
		right: 0;
	}

	.form-group-git {
		position: relative;
		width: 100%;
	}

	.ppvgitmessage .form-group-git {
		width: 100%;
	}

	.expertformppiinnerdis-git .form-group-git {
		margin-bottom: 10px !important;
	}

	section .expertformppi .expertformppiinner-git .expertformppiinnerdis-git {
		display: flex;
		grid-gap: 0px;
		gap: 0px;
		flex-wrap: wrap;
	}

	.expertformppiinner-git {
		margin-top: 20px;
	}

	.insticategory .cstlist p {
		display: inline-block;
		margin-bottom: 0;
		padding: 0 ;
		font-size: 12px;
		letter-spacing: 0.3px;
	}

	.institutenewdesignppv .latestbutton.margintop {
		margin-top: 10px;
	}

	.limitedofferinner.ppvofferinnersection .applybutton {
		margin-left: 0;
	}

	.service-offer .heading img {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		height: 140px;
		object-fit: cover;
		border-radius: 20px 20px 0 0px;
	}

	.listofoffermainsection .regular.slider .col-lg-6.col-md-6.col-sm-12.col-xs-12 {
		padding: 0;
	}

	.institutenewdesignppv section#ppvOfferSection .slick-prev:before,
	.institutenewdesignppv .offerslider .slick-prev:before {
		left: 13px;
		top: 84px;
		right: auto;
	}
	.institutenewdesignppv .offerslider .slick-prev:before{left: 0;}
	.institutenewdesignppv section#ppvOfferSection .slick-next:before,
	.institutenewdesignppv .offerslider .slick-next:before {
		right: 13px;
		top: 84px;
		left: auto;
	}
	.institutenewdesignppv .offerslider .slick-next:before{right: 0;}
	.institutenewdesignppv .homepageofferblock {
		padding: 0 25px;
	}

	.institutenewdesignppv .Insurance.homepageofferblock,
	.listofoffermainsection .Insurance.homepageofferblock {
		border-bottom: 1px solid #FF4C00;
	}

	.institutenewdesignppv .Real.Estate.homepageofferblock,
	.listofoffermainsection .Real.Estate.homepageofferblock {
		border-bottom: 1px solid #00831D;
	}

	.institutenewdesignppv .Investment.homepageofferblock,
	.listofoffermainsection .Investment.homepageofferblock {
		border-bottom: 1px solid #9C2488;
	}

	.institutenewdesignppv .Mortgage.homepageofferblock,
	.listofoffermainsection .Mortgage.homepageofferblock {
		border-bottom: 1px solid #2F596F;
	}

	.institutenewdesignppv .Banking.homepageofferblock,
	.listofoffermainsection .Banking.homepageofferblock {
		border-bottom: 1px solid #2281B8;
	}

	.institutenewdesignppv .offersss {
		margin-bottom: 0;
	}

	section.limitedtimeoffer {
		margin-top: 10px;
		background: url(../../../assets/realEstateimages/Realestatebackgroundmobile.svg);
		background-size: cover !important;
		background-position: center center;
	}
	.listofoffermainsection section.exclusive.offerlistpadding{
		background: url(../../../assets/Instituteppvimages/Institution_PPV_offer_bg_mobile.svg);
		background-size: cover !important;
		background-position: center center;}
	.listofoffermainsection .container-fluid {
		padding: 0;
	}

	.institutenewdesignppvinner .insticategory {
		font-size: 0;
		padding: 10px 0;
		background: #FFF;
		box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.20);
		margin-bottom: 10px;
	}

	.adsHeading h2 {
		font-size: 18px;
	}

	.institutenewdesignppv .limitedofferinner h5.mt-2,
	section.expertformppi h4 {
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		margin: 3px !important;
		line-height: 1;
		font-family: "SF Pro Display" !important;
		color: #000;
	}

	.institutenewdesignppv .sliderContent .pb-4 h5 {
		font-size: 12px;
		min-height: auto;
		padding-left: 30px;
        padding-right: 30px;
	}

	.institutenewdesignppv .showallourbesttwo.mt-5.text-center {
		margin-top: 0 !important;
	}

	.institutenewdesignppv section .expertformppi .expertformppiinner-git .expertformppiinnerdis-git {
		flex-wrap: wrap;
	}

	.ads-hedding {
		color: #004710;
		text-align: center;
		font-size: 18px;
		margin-bottom: 10px;
	}

	.entireexpertppv .hedingpading {
		margin-bottom: 10px;
	}

	.entireexpertppv .sliderContent .pb-4 h5 {
		font-size: 12px;
		padding: 0 35px;
		min-height: auto;
	}

	.entireexpertppv.listofoffermainsection .applybutton {
		font-family: "Roboto Flex", sans-serif !important;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 15px;
		width: 91px;
		height: 25px;
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		margin: 0 auto;
		padding: 2px;
		transition: all .3sease-in-out;
		border-radius: 2px;
		border-width: 1px;
		border-style: solid;
	}

	.entireexpertppv .sliderContent {
		margin: 10px 0;
		box-shadow: none;
		border-radius: 0;
	}

	.entireexpertppv .sliderContent.category-id4 {
		border-bottom: 1px solid #2280b8;
	}

	.entireexpertppv .sliderContent.category-id1 {
		border-bottom: 1px solid #ff4c00;
	}

	.entireexpertppv .sliderContent.category-id2 {
		border-bottom: 1px solid #86357a;
	}

	.entireexpertppv .sliderContent.category-id3 {
		border-bottom: 1px solid #2f596f;
	}

	.entireexpertppv .sliderContent.category-id5 {
		border-bottom: 1px solid #00831D;
	}

	.entireexpertppv .col-lg-6.col-md-6.col-sm-6.col-xs-12 {
		padding: 0;
	}

	section.service-offer {
		background: #fff;
		padding-bottom: 0px;
	}
}

@media (max-width:767px) {
	.serviceoffer-inner li h5 {
		font-size: 12px;
		line-height: 1;
		height: 43px;
	}
}

@media (max-width:600px){
    .section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration > a > div img {
        height: 300px;
    }
}
@media (max-width:530px){
    .section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration > a > div img {
        height: 300px;
    }
}

@media (max-width:480px){
    .section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration > a > div img {
        height: 270px;
    }
}
@media (max-width:440px){
    .section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration > a > div img {
        height: 240px;
    }
}

@media (max-width:430px){
    .section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration > a > div img {
        height: 220px;
    }
}
@media (max-width:414px){
    .section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration > a > div img {
        height: 210px;
    }
}
@media (max-width:380px){
    .section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration > a > div img {
        height: 200px;
    }
}
@media (max-width:375px){
    .section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration > a > div img {
        height: 190px;
    }
}
@media (max-width:350px){
	section.listofoffermainsection .offerlistpadding .homepageofferblock.carddecoration > a > div img {
        height: 178px;
    }
}