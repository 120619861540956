 

.main.entiredashboard.maincustomerdash .col-lg-3.col-sm-3:before { 
  background-color: #449FD6;  
}
 
.main.entiredashboard.maincustomerdash > .container > .row > .col-lg-3.col-sm-3 {
  background: #2280B8;
}
 

.title{
  margin: 25px 0 15px auto;
  font-weight: 700;
  font-size: 40px;

  align-self: start; 
  display: flex;
  align-items: start;
}

.subTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #06293f;
  margin: 3px 0 15px auto;
}


.logout{
  font-weight: 700;
  font-size: 13px;
  color: #FFFFFF;
}

.dashboard-decoration{
  background: #E4EFFF;
  border-radius: 25px; 
  box-shadow: 0px 0px 15.7px 0px #2280B880; 
}

.dashboard-data{
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column; 
}

.col-lg-9.col-sm-9.dashboard-data {
  padding: 0 55px;
  display: block;
}

.helpImg{
  padding-top:15%;
  padding-bottom: 7%; 
  width: 25%; 
  filter: brightness(100);
} 


.helpbutton{
  border-radius: 8px;
  background: #2280B8; 
  width: 65px;
  height: 70px; 
  margin: 25px 0px 0 auto;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left:40px;
}

.logoutbutton.loginrightmargin{
  margin-right:15px;
}


.myaccountnav > ul {
  padding: 0;
  list-style: none;
  display: flex; 
  position: relative; 
  padding: 30px 0;
}

.myaccountnav > ul li {
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center; 
  color: #000; 
  background: #D2FFD2;
  background-size: contain;
  background-repeat: no-repeat; 
  background-position: center;
  cursor: pointer;
  width: 144.93px;
  height: 144.93px;  
  margin-right: 25px;  
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
 
.myaccountnav > ul > li.active { 
  color: #fff;
  background: #86357A;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.35));
 
}

.myaccountnav img{ 
  fill: white !important;
  display: flex;
  justify-content: center; 
  align-items: center;
  margin: 0 auto;
  width: 40px; 
  margin-bottom: 0px;
  margin-top: 0px;

} 

.myaccountnav > ul > li > img.active{
  filter: invert(1);
}
