section.entiredashboard.maincustomerdash .customermain {
    margin-bottom: 40px;
    display: flex;
    border:none;
}
.main-blogs {
    background: #D1D9E9;
    margin-top: 40px;
    box-shadow: 0px 0px 15.7px 0px #2280B880;

box-shadow: 2.84px 2.84px 31px 0px #00000040;
border-radius: 0 25px 25px 0;
}
.main-blogs .customermain {
    margin-bottom: 50px;
    display: flex;
    border: none;
    justify-content: space-evenly;
}

section.entiredashboard.maincustomerdash .blog-text {
    margin: 0 7px;
    max-width: 229px;
    padding: 10px 0;
    min-height: 130px;
    font-size: 14px;
    color: #303030;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 21px;
}
section.entiredashboard.maincustomerdash .bloginnertext {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;
    color: #505050;
}
section.entiredashboard.maincustomerdash .blog-text {
    width: auto;
}
section.entiredashboard.maincustomerdash .blog_user_name p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0;
    color: #303030;
    line-height: 18px;
    font-family: "Poppins",sans-serif !important;
}
section.entiredashboard.maincustomerdash p.blog_user_date{
    line-height: 18px;
    font-family: "Poppins",sans-serif !important;
    font-size: 10px;
    font-weight: 600;
    color: #7D7D7D;
    margin-bottom: 0;
}

.bolg_user {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 0 10px 0 0;
}
.blog_user_img {
    display: flex;
    align-items: center;
}
.blog_user_main_img img {
    margin: 10px;
}
.blog-design-investment a.readmore-button {
    color: #86357A;
    font-size: 8px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 12px;
}

.blog-design-Mortgage a.readmore-button {
    color: #2F596F;
    font-size: 8px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 12px;
}
.blog-design-Insurance a.readmore-button {
    color: #FF7000;
    font-size: 8px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 12px;
}

.blog-design-Banking a.readmore-button {
    color: #2280B8;
    font-size: 8px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 12px;
}
.blogblogmain .bloginnerimage img {
    height: 134px;
}
section.entiredashboard.maincustomerdash .blog-button-Banking{
    background-color: #2280B8;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-family: 'Poppins',sans-serif;
    width: 150px;
    margin: 0 15px;
    height: 40px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 10px 10px 16px 0px #00000040;
}

.round-box.nav-item:nth-child(1) {
    background: #FFD5C3;
    width: 144px;
    height: 144px;
    border-radius: 200px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: #00831D;
    display: flex;
    align-items: center;
    cursor: pointer;
    order: 4;
    border:1px solid #FF7000
}
.round-box.nav-item:nth-child(2) {
    order: 2;
    background: #FFE5FB;
    width: 144px;
    height: 144px;
    border-radius: 200px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: #00831D;
    display: flex;
    align-items: center;
    border: 1px solid #86357A;
}

.round-box.nav-item:nth-child(3) {
    background:#E2ECFF;
    order: 3;
    width: 144px;
    height: 144px;
    border-radius: 200px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: #00831D;
    display: flex;
    align-items: center;
    border: 1px solid #2F596F;
}
.round-box.nav-item:nth-child(4) {
    background:#E3F2FF;
    order: 5;
    width: 144px;
    height: 144px;
    border-radius: 200px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: #00831D;
    display: flex;
    align-items: center;
    border: 1px solid #2280B8;
}
.round-box.nav-item:nth-child(5) {
    background:#DDFFDD;
    width: 144px;
    order: 1;
    height: 144px;
    border-radius: 200px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: #00831D;
    display: flex;
    align-items: center;
    border: 1px solid #00831D;
}
.round-box.nav-item img {
    width: 25px;
}

.round-box.nav-item a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 24px;
}
.main-round-box.nav.nav-pills {
    justify-content: space-evenly;
    align-items: center;
    margin: 50px 0;
}
.main-round-box .nav-item:nth-child(1) a.active {
    background: #FF7000 ;
    border-radius: 100% ;
    color: white ;
    box-shadow: 4px 4px 12.2px 0px #00000040;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 24px;

}
.main-round-box .nav-item:nth-child(2) a.active {
    background: #86357A ;
    border-radius: 100% ;
    box-shadow: 4px 4px 12.2px 0px #00000040;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 24px;
    color: white ;
}
.main-round-box .nav-item:nth-child(3) a.active {
    background: #2F596F ;
    border-radius: 100% ;
    color: white ;
    box-shadow: 4px 4px 12.2px 0px #00000040;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 24px;
}

.main-round-box .nav-item:nth-child(4) a.active {
    background: #2280B8 ;
    border-radius: 100% ;
    color: white ;
    box-shadow: 4px 4px 12.2px 0px #00000040;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 24px;
}
.main-round-box .nav-item:nth-child(5) a.active {
    background: #00831D ;
    border-radius: 100% ;
    color: white ;
    box-shadow: 4px 4px 12.2px 0px #00000040;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 24px;
}
.main-round-box .nav-item:nth-child(5) a.active img {
    filter: invert(1);
}
.main-round-box .nav-item:nth-child(4) a.active img {
    filter: invert(1);
}
.main-round-box .nav-item:nth-child(3) a.active img {
    filter: invert(1);
}
.main-round-box .nav-item:nth-child(2) a.active img {
    filter: invert(1);
}
.main-round-box .nav-item:nth-child(1) a.active img {
    filter: invert(1);
}
.main-round-box .nav-item:nth-child(2) a {
    color: #86357A;
}
.main-round-box .nav-item:nth-child(3) a {
    color: #2F596F;
}
.main-round-box .nav-item:nth-child(1) a {
    color: #FF7000;
}
.main-round-box .nav-item:nth-child(4) a {
    color: #2280B8;
}
.main-round-box .nav-item:nth-child(5) a {
    color: #00831D;
}
.main-round-box .nav-item:nth-child(5) img {
    height: 49.57px;
    width: 21.17px;
    filter: invert(25%) sepia(100%) saturate(1736%) hue-rotate(123deg) brightness(89%) contrast(101%);
}
.main-round-box .nav-item:nth-child(2) img {
    height: 49.13px;
    width: 28.98px;
    filter: invert(25%) sepia(89%) saturate(454%) hue-rotate(258deg) brightness(97%) contrast(96%);
}
.main-round-box .nav-item:nth-child(3) img {
    height: 37.72px;
    width: 52.23px;
    filter: invert(30%) sepia(31%) saturate(775%) hue-rotate(156deg) brightness(90%) contrast(87%);
}
.main-round-box .nav-item:nth-child(1)  img {
    height: 43px;
    width:44.17px;
    filter: invert(37%) sepia(36%) saturate(6250%) hue-rotate(1deg) brightness(103%) contrast(105%);
}
.main-round-box .nav-item:nth-child(4)  img {
    height: 45.65px;
    width:35px;
    filter: invert(38%) sepia(99%) saturate(398%) hue-rotate(158deg) brightness(94%) contrast(93%);
}
.RealEstate .blog-design{
    box-shadow: 10px 10px 16px 0px #00000040;
    border: 1px solid #00801D;
    border-top: none;
    /* min-height: 252px; */
    padding: 0 0 10px 0;
    border-radius: 0 0 6px 6px;
    margin: 0 0px 45px 0px;
}
.RealEstate .blog-button {
    background-color: #00801D;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-family: 'Poppins',sans-serif;
    width: 150px;
    margin: 0 15px;
    height: 40px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 4px;
    border: none;
}
.RealEstate a.readmore-button {
    color: #00801D;
    font-size: 8px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 12px;
}
.RealEstate .readmore-button img {
    filter: invert(25%) sepia(100%) saturate(1736%) hue-rotate(123deg) brightness(89%) contrast(101%);
    padding: 0 0 0 7px;
}
.Investment .blog-design{
    box-shadow: 10px 10px 16px 0px #00000040;
    border: 1px solid #86357A;
    border-top: none;
    /* min-height: 252px; */
    padding: 0 0 10px 0;
    border-radius: 0 0 6px 6px;
    margin: 0 0px 45px 0px;
}
.Investment .blog-button {
    background-color: #86357A;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-family: 'Poppins',sans-serif;
    width: 150px;
    margin: 0 15px;
    height: 40px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 4px;
    border: none;
}
.Investment a.readmore-button {
    color: #86357A;
    font-size: 8px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 12px;
}
.Investment .readmore-button img {
    filter: invert(25%) sepia(89%) saturate(454%) hue-rotate(258deg) brightness(97%) contrast(96%);
    padding: 0 0 0 7px;
}
.Mortgage .blog-design{
    box-shadow: 10px 10px 16px 0px #00000040;
    border: 1px solid #2F596F;
    border-top: none;
    /* min-height: 252px; */
    padding: 0 0 10px 0;
    border-radius: 0 0 6px 6px;
    margin: 0 0px 45px 0px;
}
.Mortgage .blog-button {
    background-color: #2F596F;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-family: 'Poppins',sans-serif;
    width: 150px;
    margin: 0 15px;
    height: 40px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 4px;
    border: none;
}
.Mortgage a.readmore-button {
    color: #2F596F;
    font-size: 8px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 12px;
}
.Mortgage .readmore-button img {
    filter: invert(30%) sepia(31%) saturate(775%) hue-rotate(156deg) brightness(90%) contrast(87%);
    padding: 0 0 0 7px;
}
.Insurance .blog-design{
    box-shadow: 10px 10px 16px 0px #00000040;
    border: 1px solid #FF7000;
    border-top: none;
    /* min-height: 252px; */
    padding: 0 0 10px 0;
    border-radius: 0 0 6px 6px;
    margin: 0 0px 45px 0px;
}
.Insurance .blog-button {
    background-color: #FF7000;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-family: 'Poppins',sans-serif;
    width: 150px;
    margin: 0 15px;
    height: 40px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 4px;
    border: none;
}
.Insurance a.readmore-button {
    color: #FF7000;
    font-size: 8px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 12px;
}
.Insurance .readmore-button img {
    filter: invert(37%) sepia(36%) saturate(6250%) hue-rotate(1deg) brightness(103%) contrast(105%);
    padding: 0 0 0 7px;
}
.Banking .blog-design{
    box-shadow: 10px 10px 16px 0px #00000040;
    border: 1px solid #2280B8;
    border-top: none;
    /* min-height: 252px; */
    padding: 0 0 10px 0;
    border-radius: 0 0 6px 6px;
    margin: 0 0px 45px 0px;
}
.Banking .blog-button {
    background-color: #2280B8;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-family: 'Poppins',sans-serif;
    width: 150px;
    margin: 0 15px;
    height: 40px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 4px;
    border: none;
}
.Banking a.readmore-button {
    color: #2280B8;
    font-size: 8px;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    line-height: 12px;
}
.Banking .readmore-button img {
    filter: invert(38%) sepia(99%) saturate(398%) hue-rotate(158deg) brightness(94%) contrast(93%);
    padding: 0 0 0 7px;
}
@media (max-width:767px){
    section.entiredashboard.maincustomerdash .main-blog-section {
        background-color: #ffffff;
        border-radius: 20px;
        padding:0px;
        margin: 0;
    }
    .main-round-box.nav.nav-pills {
        justify-content: center;
        align-items: center;
        margin: 20px auto 0;
        width: 93%;
        border:5px solid #e1e6ed;
    }
    .main-round-box .nav-item:nth-child(5) a.active{
        background: linear-gradient(0deg, #5BC272 0%, #00831D 100%);
    }
    .main-round-box .nav-item:nth-child(2) a.active{
        background: linear-gradient(0deg, #D387C8 -0.12%, #86357A 102.5%);
    }
    .main-round-box .nav-item:nth-child(3) a.active{
        background: linear-gradient(180deg, #2F596F 0%, #6997AE 100%);
    }
    .main-round-box .nav-item:nth-child(4) a.active{
        background: linear-gradient(180deg, #2280B8 0%, #75BBE4 100%);
    }
    .main-round-box .nav-item:nth-child(1) a.active{
        background: linear-gradient(180deg, #FF4C00 -12.66%, #FFBA9C 100%);
    }
    .main-round-box .nav-item a{
        position: relative;
    }
    .main-round-box .nav-item a.active:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
  
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        bottom: -10px;
    }
    .main-round-box .nav-item:nth-child(1) a.active:after{
        border-top: 11px solid #FFBA9C;
    }
    .main-round-box .nav-item:nth-child(2) a.active:after{
        border-top: 11px solid #D387C8;
    }
    .main-round-box .nav-item:nth-child(3) a.active:after{
        border-top: 11px solid #6997AE ;
    }
    .main-round-box .nav-item:nth-child(4) a.active:after{
        border-top: 11px solid #75BBE4 ;
    }
    .main-round-box .nav-item:nth-child(5) a.active:after{
        border-top: 11px solid #5BC272;
    }
}