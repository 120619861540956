@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

.expertpublicviewprofiledetails {
	background: url(../../../assets/expertppvimage/BANNER_EXPERT_PPV.svg);
	padding: 35px 0;
}

.expertpublicviewprofiledetails .container {
	position: relative;
}

.expertprofilelogouploadinner {
	border-radius: 273px;
	border: 8px solid #FFF;
	width: 273px;
	height: 273px;
	overflow: hidden;
	margin: auto;
}

.expertprofilelogoupload {
	border-bottom: 1px solid #fff;
	padding-bottom: 20px;
	width: 100%;
	margin: auto;
	position: relative;
	max-width: 700px;
}

.Expertbasicdeatail h2.h2_style {
	color: #FFF;
	text-align: center;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 20px;
	text-align: center;
	padding-top: 10px;
	text-transform: capitalize;
}

.newexpertppvdesign .cstlist {
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-family: "Fjalla One",sans-serif;
	font-weight: 400;
	border-radius: 60px;
	border: 1px solid #FFF;
	padding: 5px 20px;
	line-height: 22px;
	display: inline-block;
	margin-right: 15px;
	display: flex;
	align-items: center;
}

.cstlist img {
	filter: invert(1);
	width: 20px !important;
	height: 20px !important;
	object-fit: contain;
	margin-right: 5px;
}

.ovtercslist {
	display: flex;
	justify-content: center;
	padding-bottom: 10px;
}



.newexpertppvdesign ul.deone li {
	position: relative;
	padding: 8px;
	padding-left: 53px;
	border-radius: 6.401px;
	border: 1px solid #FFF;
	background: #fff;
	box-shadow: 0px 0px 8.445px 0px rgba(117, 11, 169, 0.50);
	width: auto;

}

.csadress {
	display: flex;
	justify-content: center;
	color: #FFF;
	font-family: "Roboto Flex";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	list-style: none;
	margin-bottom: 10px;
}

.csadress li {
	padding-right: 50px;
	position: relative;
	padding-left: 20px;
	color: #FFF;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.csadress ul {
	list-style: none;
	display: flex;
	justify-content: center;
	margin-bottom: 0;
}

.csadress ul span {
	position: absolute;
	left: 0;
	top: -1px;
}

.newexpertppvdesign ul.deone:before {
	display: none;
}

.newexpertppvdesign ul.deone li:first-child {
	margin-right: 60px;
}

.newexpertppvdesign ul.deone {
	border: none;
	padding: 0;
	justify-content: center;
	margin-bottom: 0;
}

.newexpertppvdesign ul.deone li a {
	color: #000;
font-family: "Microsoft Sans Serif";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.newexpertppvdesign ul.deone li span {
	position: absolute;
	left: 0px;
	top: 0px;
	filter: drop-shadow(0px 5.121px 5.121px rgba(0, 0, 0, 0.25));
	border-radius: 6.401px;
	border: 1px solid #FFF;
	background: #2f596f;
	box-shadow: 0px 0px 8.445px 0px rgba(117, 11, 169, 0.50);
	width: 46px;
    height: 40px;
    text-align: center;
    line-height: 40px;
}
.newexpertppvdesign ul.deone li span img{filter: invert(100%) sepia(0%) saturate(7472%) hue-rotate(80deg) brightness(98%) contrast(113%);}
.newexpertppvdesign .ppvplan img {
	width: 35px;
	margin-top: -5px;
}

.newexpertppvdesign .ppvplan {
	width: 155px;
	border-radius: 100px;
	background-color: #DAF3FF;
	border: 1px solid #2280B8;
	box-shadow: 0px 0px 5px 0px #2281B8 inset;
	color: #000;
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	text-align: center;
	top: 12px;
	left: 0;
	right: -293px;
	margin: auto;
	line-height: 2.1;
	padding-left: 30px;
}

.ppvplan.Premium:before{
	content: "";
	position: absolute;
	background: url(../../../assets/expertppvimage/diamond.svg);
	width: 20px;
    height: 22px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 30px;
    top: 9px;
	}
	.ppvplan.Elite:before{
	content: "";
	position: absolute;
	background: url(../../../assets/expertppvimage/elite_star.svg);
	width: 25px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 42px;
    top: 4px;
		}

.newexpertppvdesign .socialpublic ul li {
    background: #dff6ff;
    width: 40px;
    height: 40px;
    margin-right: 5px;
    display: inline-block;
    text-align: center;
    border-radius: 70px;
    line-height: 39px;
    margin-bottom: 16px;
}
.specialisationexpertppv{
	background: #FFF;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.25);
    padding: 15px;
 
    margin: auto;
}
.newexpertppvdesign .socialpublic li:first-child span img,
.newexpertppvdesign .socialpublic li span img {
	width: auto;
}
.newexpertppvdesign .socialpublic li span img{width: 21px;}
.newexpertppvdesign .socialpublic {
	position: absolute;
	left: 10px;
	width: 60px;
	bottom: -9%;
	margin: auto;
}

.newexpertppvdesign .socialpublic ul {
	padding: 0;
}
.entireexpertppv.Mortgage .aboutexpertppv{
	background: url(../../../assets/expertppvimage/MortgageAboutmeBG.svg);
	background-size: cover;
	background-repeat: no-repeat;
}
.entireexpertppv.Real.Estate .aboutexpertppv{
	background: url(../../../assets/expertppvimage/RealEstateAboutmeBG.svg);
	background-size: cover;
	background-repeat: no-repeat;
}
.entireexpertppv.Insurance .aboutexpertppv{
	background: url(../../../assets/expertppvimage/InsuranceAboutmeBG.svg);
	background-size: cover;
	background-repeat: no-repeat;
}
.entireexpertppv.Investment .aboutexpertppv{
	background: url(../../../assets/expertppvimage/InvestmentAboutmeBG.svg);
	background-size: cover;
	background-repeat: no-repeat;
}
.entireexpertppv.Banking .aboutexpertppv{
	background: url(../../../assets/expertppvimage/BankingAboutmeBG.svg);
	background-size: cover;
	background-repeat: no-repeat;
}
.aboutexpertppv .heading h2 {
color: #000;
text-align: left;
text-transform: uppercase;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.aboutexpertppv {
	/* background: #2F596F;
	position: relative;
	overflow: hidden; */
}

.about_me {
	align-items: center;
}

.about-me-ppv h3 {
	color: #000;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
}

.about-me-ppv p {
	color: #000;
font-family: "Microsoft Sans Serif";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.dp {
	position: relative;
	width: 100%;
}



/* .aboutexpertppv:before {
	width: 100px;
	height: 100px;
	border-bottom: 100px solid #2F596F;
	;
	border-left: 100px solid transparent;
	content: "";
	position: absolute;
	bottom: 0;
	z-index: 99;
	right: 14%;
} */



.dp img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    margin-right: 0;
    object-position: top;
}
.entireexpertppv .sliderContent {
	border-radius: 14px;
	background: #FFF;
	box-shadow: 0px 0px 10px 0px rgba(47, 89, 111, 0.60);
	margin: 10px;
}

.entireexpertppv .sliderContent img {
	padding: 15px 10px 0;
}

.entireexpertppv .descriptionppvpop {
	border-radius: 0;
	background: #fff;
	padding: 0;
	position: relative;
	width: 100%;
	bottom: 0;
	border: none;
	box-shadow: none;
}

.entireexpertppv .expppvofferinnersection .offerslider .sliderContent h3 {
	background: linear-gradient(90deg, rgba(55, 120, 154, 0.50) 0%, rgba(98, 166, 201, 0.50) 11.62%, rgba(55, 120, 154, 0.50) 22.61%, rgba(98, 166, 201, 0.50) 34.54%, rgba(55, 120, 154, 0.50) 44.96%, rgba(98, 166, 201, 0.50) 56.52%, rgba(55, 120, 154, 0.50) 67.5%, rgba(98, 166, 201, 0.50) 77.92%, rgba(55, 120, 154, 0.50) 87.77%, rgba(98, 166, 201, 0.50) 100%);
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-align: start;
	padding: 20px
}

.entireexpertppv .limitedofferinner .descriptionppvpop h5 {
	color: #000;

	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding: 18px 30px
}

.entireexpertppv .specialisationppv section.latestrates .container {
	border-radius: 22px;
	background: #FFF;
	box-shadow: 0px 0px 15px 0px rgba(47, 89, 111, 0.80);
}

.entireexpertppv section.awards {
	background: url(../../../assets/expertppvimage/background_of_certificates.svg) #eee;
	padding: 45px 0;
	background-size: cover;
	background-position: bottom;
	margin: 40px 0;
}

.entireexpertppv section.awards heading {
	color: #000;
	font-family: "Poppins", sans-serif;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-align: center;
	display: block;
	margin-bottom: 20px;
}

.entireexpertppv section.awards ul.cetificatesection,
.entireexpertppv section.awards ul.awardssection {
	display: flex;
	justify-content: center;
}

.entireexpertppv section.awards ul.cetificatesection li,
.entireexpertppv section.awards ul.awardssection li {
	border-radius: 10px;
	border: 1px solid #000;
	width: auto;
	display: flex;
	color: #000;
	font-family: "Poppins", sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	position: relative;
	background: rgba(0, 0, 0, 0.10);
	margin-right: 20px;
	padding: 10px;
	padding-left: 80px;
}

.entireexpertppv section.awards ul.cetificatesection li span,
.entireexpertppv section.awards ul.awardssection li span {
	position: absolute;
	left: 20px;
	width: 40px;
	top:6px;
}
.entireexpertppv section.awards ul.cetificatesection li span img,
.entireexpertppv section.awards ul.awardssection li span img{
	filter: invert(1);
}
.entireexpertppv button.readmore {
	background: none;
	display: block;
	padding: 0;
	color: #000;
	border: none;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 19.5px;
}

.entireexpertppv .slick-list {
	margin: 0;
	height: auto !important;
}

.entireexpertppv .slick-slide>div {
	padding: 0 30px;
}

.entireexpertppv .descriptionppvpop h5 {
	color: #000;
	min-height: 120px;
	font-size: 17px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding: 0px 30px;
}

span.inputimage {
	position: absolute;
	right: 10px;
	top: 10px;
}

.entireexpertppv .expppvofferinnersection h5.h5_style {
	color: #000;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
	margin-bottom: 0;
}

.entireexpertppv .expppvofferinnersection h2 {
	color: #000;
text-align: center;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 0;
}

.entireexpertppv .expppvofferinnersection {
	padding: 10px 0;
}

.expmodaltext {
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 40px;
	margin-top: 30px;
	text-align: left;

}

.expertadmodal .ds {
	background: linear-gradient(90deg, rgba(55, 120, 154, 0.50) 0%, rgba(98, 166, 201, 0.50) 11.62%, rgba(55, 120, 154, 0.50) 22.61%, rgba(98, 166, 201, 0.50) 34.54%, rgba(55, 120, 154, 0.50) 44.96%, rgba(98, 166, 201, 0.50) 56.52%, rgba(55, 120, 154, 0.50) 67.5%, rgba(98, 166, 201, 0.50) 77.92%, rgba(55, 120, 154, 0.50) 87.77%, rgba(98, 166, 201, 0.50) 100%);
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-align: center;
	padding: 30px
}

.expertadmodal {
	position: relative;
	text-align: center;
	width: 100%;
	margin: auto;
	left: 0;
	right: 0;
	padding: 50px;
	background: #496D81;
	max-width: 950px;
}

button.modalbutton {
	position: absolute;
	right: 24px;
	width: 20px;
	background: transparent;
	z-index: 99;
	top: 18px;

	height: 20px;
}

button.modalbutton svg {
	width: 25px;
	height: 25px;
	border: 1px solid #fff;
}

section.expertformppinew .container {
	border-radius: 30px;
	border: 4px solid #2F596F;
	background: #FFF;
	box-shadow: 0px 0px 18.6px 0px rgba(83, 142, 171, 0.50);
	margin: 40px 0;
	overflow: hidden;
}
section.expertformppinew .backgroundgrey{
	background: #E9EEF9;
	margin-right: 5px;
    width: 49.5%;
	border-radius: 0px 20px 20px 0px;
}
.backpap {
	border-radius: 0px 20px 20px 0px;
	padding: 10px 40px;
	position: relative;
	
}

.backpap h2 {
	color: #000;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
margin-bottom: 0;
}
.getintouchimage img{
	height: 600px;
    width: 100%;
    object-fit: cover;    
	border-radius: 35px 0 0 35px;
}
.expertformppinew .row{
	align-items: center;
}
.backpap .ctatwobutton {
	border-radius: 4px;
	background: #2F596F;
	color: #FFF;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif ;
	font-weight: 500;
	line-height: normal;
	width: 220px;
	line-height: 1;
	border:1px solid #2F596F;
	transition: 0.3s all ease-in-out;
}
.backpap .ctatwobutton:hover{
	background: transparent;
	color: #2F596F;
}
.backpap button.ctatwobutton[disabled]
{
	background: #ccc;
	border-color: #ccc;
	color: #fff;
}
.backpap textarea.form-control.textareaform {
	height: 100px;
}

.nopadright {
	padding-right: 0;
}

.nopadleft {
	padding-left: 0;
}

.backpap h5 {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.bg-white-new-exp {
	position: relative;
	border-bottom: 1px solid;
    padding: 20px 0;
}


section.specialisationexpertppv h2 {
	color: #000;
text-align: center;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-bottom: 0;
}
section.specialisationexpertppv h5{
	color: #000;
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 0;
}

.expppvofferinnersection .slick-prev:before {
	background: url(../../../assets/sharedImages/leftarrowslidercat.png);
	background-repeat: no-repeat;
	background-size: contain;
	height: 20px;
	width: 20px;
	content: "";
	opacity: 1;
}

.expppvofferinnersection .slick-next:before {
	background: url(../../../assets/sharedImages/rightarrowslidercat.png);
	background-repeat: no-repeat;
	background-size: contain;
	height: 20px;
	width: 20px;
	content: "";
	opacity: 1;
}

.expppvofferinnersection .slick-prev,
.expppvofferinnersection .slick-next {
	z-index: 99;
	/* top: auto;
	bottom: -8px;
	left: 0;
	right: 0; */
	margin: auto;
}

.expppvofferinnersection .slick-prev {
	left: -67px;
}

.expppvofferinnersection .slick-next {
	right: -67px;
}



section.blogexpertppvsection {
	width: 100%;
	margin: 40px auto;
	max-width: 1260px;
}


.blogexpertinnernew {
	margin: 20px 0;
}

.blogexpertinner {
	display: grid;
	gap: 30px;
}

.blogexpertinner div {
	width: 100%;
	/* Ensure each card takes full width in its column */
}

.blogexpertinner.lengthblock5 div:nth-child(5) {
	grid-column: 3 / 4;
	grid-row: 1 / 3;
}

/* For 4 cards: 2 on top, 1 in the middle between them, and a banner in the middle */
.blogexpertinner.lengthblock5 div:nth-child(1):nth-last-child(4),
.blogexpertinner.lengthblock5 div:nth-child(2):nth-last-child(3) {
	grid-column: 1 / 2;
	/* First 2 cards, top row */
	grid-row: 1;
}

.blogexpertinner.lengthblock3 div:nth-child(2):nth-last-child(3) {
	grid-column: 2 / 3;
	grid-row: 1;
}

.blogexpertinner.lengthblock4 div:nth-child(3):nth-last-child(2) {
	grid-column: 1 / 3;
	/* Middle card between the top 2 */
	grid-row: 2;
}

.blogexpertinner.lengthblock4 div:nth-child(4):nth-last-child(1) {
	grid-column: 3 / 4;
	/* Fourth card on the right side */
	grid-row: 1 / 3;
}

/* For 3 cards: 2 on top, 1 below spanning the width of the 2 above */
.blogexpertinner.lengthblock3 div:nth-child(1):nth-last-child(3) {

	grid-column: 1 / 2;
	/* First 2 cards, top row */
	grid-row: 1;
}

.blogexpertinner.lengthblock3 div:nth-child(2):nth-last-child(2) {
	grid-column: 2 / 2;
	/* First 2 cards, top row */
	grid-row: 1;
}

.blogexpertinner.lengthblock3 div:nth-child(3):nth-last-child(1) {
	grid-column: 1 / 3;
	/* Third card spans both columns */
	grid-row: 2;
}


.blogexpertinner.lengthblock5 div:nth-child(1):nth-last-child(2),
.blogexpertinner div:nth-child(2):nth-last-child(1) {
	grid-column: 2 / 3;
	grid-row: auto;
}


.blogexpertinner.lengthblock5 div:only-child {
	grid-column: 2 / 3;
	grid-row: 1;
}

.blogexpertinner .p_sliderouter:nth-child(2) {
	background: #2F596F;
	border-radius: 20px;
	box-shadow: 2px 2px 12.9px 0px rgba(0, 0, 0, 0.40);
}

.blogexpertinner .p_sliderouter:nth-child(2) .p_slider {
	background: #2F596F;
}

.blogexpertinner .p_sliderouter:nth-child(2) .p_slider h4,
.blogexpertinner .p_sliderouter:nth-child(2) .p_slider p {
	color: #fff;
}

.blogexpertinner.lengthblock2 {
	grid-template-columns: 1fr 1fr;
}

.blogexpertinner.lengthblock3 {
	grid-template-columns: 1fr 1fr;
}

.blogexpertinner.lengthblock4 {
	grid-template-columns: 1fr 1fr 1fr;
}

/*.blogexpertinner.lengthblock4 .p_sliderouter:nth-child(3){grid-row-start: 1;
			grid-row-end: 3;}*/
.blogexpertinner.lengthblock4 .p_sliderouter:nth-child(4) {
	grid-column: 1/3;
}

.blogexpertinner.lengthblock4 .p_sliderouter:nth-child(3) {
	grid-row: span 2;
}

.blogexpertinner.lengthblock3 .p_sliderouter:nth-child(3) .p_slider,
.blogexpertinner.lengthblock4 .p_sliderouter:nth-child(3) .p_slider {
	display: flex;
	align-items: center;
}

.blogexpertinner.lengthblock1 {
	display: flex;
	justify-content: center;
}

.blogexpertinner.lengthblock1 .p_sliderouter {
	width: 300px;
	margin: auto;
}

.blogexpertinner.lengthblock3 .p_sliderouter:nth-child(3) .p_slider .blog-image,
.blogexpertinner.lengthblock4 .p_sliderouter:nth-child(3) .p_slider .blog-image {
	width: 50%;
}

.blogexpertinner.lengthblock3 .p_sliderouter:nth-child(3) .p_slider .slidertext,
.blogexpertinner.lengthblock4 .p_sliderouter:nth-child(3) .p_slider .slidertext {
	padding-left: 20px;
}


section.blogexpert .p_sliderouter {
	border-radius: 20px;
	background: #FFF;
	box-shadow: 2px 2px 12.9px 0px rgba(0, 0, 0, 0.40);
	padding-bottom: 0;
	align-self: stretch;
}

section.blogexpert .p_sliderouter .blog-image img {
	border-radius: 12px;
	object-fit: cover;
	margin-bottom: 10px;
}

section.blogexpert .slidertext h4 {
	color: #000;
	text-align: left;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

section.blogexpert .slidertext p {
	color: #000;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0;
}

section.blogexpertppvsection h2 {
	color: #000;
	text-align: center;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	margin-bottom: 0;
}


section.blogexpertppvsection h4 {
	color: #000;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}


.myexpertnavnewdesign ul {
	display: flex;
	list-style: none;
	justify-content: center;
}

.myexpertnavnewdesign {
	border-radius: 30px;
	background: #FFF;
	box-shadow: 0px 0px 10px 0px rgba(47, 89, 111, 0.80);
	margin-top: 30px;
	padding-bottom: 90px;
}

.myexpertnavnewdesign .bg-white-new-exp {
	border-radius: 22px;
	background: #FFF;
	box-shadow: 0px 0px 15px 0px rgba(47, 89, 111, 0.80);
	padding: 30px 0;
	margin-bottom: 30px;
}

.myexpertnavnewdesign ul li {
	color: #000;
	text-align: center;
	font-family: Montserrat;
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	padding: 0;
	padding-bottom: 0;
	margin: 20px 0px 0px 0px;
	padding: 10px 25px;
}

.myexpertnavnewdesign ul {
	display: flex;
	list-style: none;
	justify-content: center;
	margin: 0;
}

.myexpertnavnewdesign {
	border-radius: 30px;
	background: #FFF;
	box-shadow: 0px 0px 10px 0px rgba(47, 89, 111, 0.80);
	margin-top: 40px;
}

.bg-white-new-exp h3 {
	border-radius: 0px 26.5px 26.5px 0px;

	color: #FFF;
	text-align: center;
	font-family: Montserrat;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	width: max-content;
	padding: 5px 34px;
	margin-bottom: 20px;
}


.bg-white-new-exp .detailsbank,
.bg-white-new-exp .detailsbank h4 {
	position: relative;
}

.bg-white-new-exp .detailsbank a {
	color: inherit;
}


.bg-white-new-exp .grid-2 {
	grid-gap: 0;
}

.bg-white-new-exp .detailsbank h4 {
	color: #000;

	font-weight: 500;
	line-height: normal;
	font-size: 28px;
}


.latestdeailsexpertppvspecialize {

	display: none;
}

.latestdeailsexpertppvspecialize.active {
	display: block;
}

.specialisationexpertppv .myexpertnavnewdesign li.active.Investment {
	background: url(../../../assets/expertppvimage/Investmentback.svg);
	color: #FFF;
	text-align: center;
	background-size: cover;
	background-position: center center;
}


.specialisationexpertppv .myexpertnavnewdesign li.active.Real.Estate {
	background: url(../../../assets/expertppvimage/realestateback.svg);
	color: #FFF;
	background-size: cover;
	background-position: center center;
}

.specialisationexpertppv .myexpertnavnewdesign li.active.Insurance {
	background: url(../../../assets/expertppvimage/Insuranceback.svg);
	color: #FFF;
	background-size: cover;
	background-position: center center;
}

.specialisationexpertppv .myexpertnavnewdesign li.active.Mortgage {
	background: url(../../../assets/expertppvimage/Mortgageback.svg);
	color: #FFF;
	background-size: cover;
	background-position: center center;
}

.specialisationexpertppv .myexpertnavnewdesign li.active.Banking {
	background: url(../../../assets/expertppvimage/Bankingback.svg);
	color: #FFF;
	background-size: cover;
	background-position: center center;
}

.entireexpertppv .categoryonesetwidthexpert span {
	width: 140px;
	height: 140px;
	padding: 10px 2px;
	display: inline-block;
	text-align: center;
	margin: 10px;
	border: 4.71px solid transparent;
	box-shadow: 2.62px 3.66px 12.35px 0px #00000040;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	line-height: 1;
	color: #000;
	padding-right: 0;
	background: #cbeaff;

}

.entireexpertppv .categoryonesetwidthexpert span:nth-child(1) {
	background: #cbeaff;
	margin-left: 0;
}
.entireexpertppv .categoryonesetwidthexpert span{
	font-size: 10px;
	width: 90px;
    height: 90px;
border-radius: 10px;}
.entireexpertppv .categoryonesetwidthexpert span:nth-child(2) {
	background: #ffe8a4;
}

.entireexpertppv .categoryonesetwidthexpert span:nth-child(3) {
	background: #f6e4ff;
}

.entireexpertppv .categoryonesetwidthexpert span:nth-child(4) {
	background: #ffe0e0;
}

.entireexpertppv .categoryonesetwidthexpert span:nth-child(5) {
	background: #c0ffc7;
}

.entireexpertppv .categoryonesetwidthexpert span:hover {

	border: 4.71px solid transparent;
}

.entireexpertppv .categoryonesetwidthexpert span:nth-child(2) {
	border: 4.71px solid #ffe8a4;
}

.entireexpertppv .categoryonesetwidthexpert span:nth-child(3) {
	border: 4.71px solid #f6e4ff;
}

.entireexpertppv .categoryonesetwidthexpert span:nth-child(4) {
	border: 4.71px solid #ffe0e0;
}

.entireexpertppv .categoryonesetwidthexpert span:nth-child(5) {
	border: 4.71px solid #c0ffc7;
}

.entireexpertppv .categoryonesetwidthinner {
	width: 100%;
	display: flex;
	justify-content: left;
	align-items: center;
}

.bg-white-new-exp {
	position: relative;
}


.latestdeailsexpertppvspecialize {
	position: relative;
	border-radius: 12px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
	padding: 0px 50px;
	margin-left: 10px;
	width: 100%;
}
.entireexpertppv .form-group .form-control , .entireexpertppv .input-container .placeholder{color: #626262;
	font-family: "Microsoft Sans Serif";
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.entireexpertppv .form-group .form-control{height: 50px;}
.entireexpertppv .input-container .placeholder{top:18px;}
	.entireexpertppv	.input-container .placeholder{left:17px}

.swal-overlay--show-modal .swal-modal {
	border-radius: 20px;
	background: #2F596F;
	width: 887px;
}

.swal-text {
	color: rgb(3, 3, 3);

	text-align: center;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.swal-footer {
	text-align: center;
}

button.swal-button.swal-button--confirm {
	color: #000 !important;
    text-align: center;
    font-family: "Roboto Flex";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.8px;
    background: transparent !important;
    border: 2px solid #000;
}

.swal-button--danger {
	background: none;
}

section.expertppv-quotes {
	display: block;
}


.swal-overlay--show-modal .swal-modal {
	border-radius: 20px;
	background: #2F596F;
	width: 887px;
}

.swal-text {
	color: rgb(3, 3, 3);

	text-align: center;
	font-size: 48px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.swal-footer {
	text-align: center;
}

.blogexpertinnerps button.swal-button.swal-button--confirm {
	border-radius: 6.675px;
	background: #FFF;
	color: #496D81;

	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	font-family: "Poppins", sans-serif !important;
	filter: drop-shadow(3px 3px 33px #9B9B9B) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.blogexpertinnerps .p_slider {
	border-radius: 20px;
	background: #FFF;
	box-shadow: 2px 2px 12.9px 0px rgba(0, 0, 0, 0.40);
	padding-bottom: 15px;
	align-self: stretch;
}

section.blogexpert .blogexpertinnerps .blofimage img {
	border-radius: 12px;
	height: 200px;
	object-fit: cover;
	margin-bottom: 10px;
}

section.blogexpert .blogexpertinnerps .slidertext h4 {
	color: #000;
	text-align: left;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

}

section.blogexpert .blogexpertinnerps .slidertext p {
	color: #000;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 15px;
}

section.blogexpert .blogexpertinnerps .col-lg-4.col-sm-4:nth-child(2) .p_slider {
	background: #2F596F;
}

section.blogexpert .blogexpertinnerps .col-lg-4.col-sm-4:nth-child(2) .p_slider h4,
.blogexpertinnerps .col-lg-4.col-sm-4:nth-child(2) .p_slider p {
	color: #fff;
}

.myexpertnavnewdesign ul li span {
	padding: 10px 15px;
}

.modalspecial.popupad>div {
	padding: 0;
}

.expertadmodal button.close-button {
	border: none;
	background: none;
	border: none;
	background: none;
	display: flex;
	margin: 0 0 0 auto;
	position: absolute;
	top: 6px;
	right: 1px;
}

.detailsbank .categoryonesetwidthinner .iconone {
	width: 35px;
	height: 35px;
	background: #fff;
	margin: auto;
	border-radius: 60px;
	margin-bottom: 5px;
	line-height: 35px;
}
.detailsbank .categoryonesetwidthinner .iconone img {
    width: 17px;
}
.entireexpertppv .categoryonesetwidthexpert span:hover .iconone {
	background: #fff;
}

button.knowpop {
	width: 154.665px;
	height: 45px;
	flex-shrink: 0;
	background: #fff;
	border-radius: 7px;
	display: inline-block;
	text-align: center;
	font-family: Montserrat;
	font-size: 16.715px;
	font-style: normal;
	font-weight: 700;
	border: none;
	line-height: 2.4;
	margin-top: 30px;
	color: #496D81;
}

button.knowmorebutton {
	width: 154.665px;
	height: 45px;
	flex-shrink: 0;
	background: #496D81;
	border-radius: 7px;
	font-size: 16.715px;
	display: flex;
	font-style: normal;
	font-weight: 700;
	line-height: 2.4;
	color: #fff;
	align-items: center;
	text-align: center;
	margin: 10px auto;
	justify-content: center;
	border: none;
}

section.entireexpertppv.listofoffermainsection .listofimage {
	background: transparent;
}

section.entireexpertppv.listofoffermainsection .offerslider .listofadbutton {
	justify-content: center;
}

section.entireexpertppv.listofoffermainsection .offerslider .listofadbutton button.ctatwobutton.ctanewtwobutton.width100 {
	border-radius: 6.675px;
	background: #2F596F;
	width: 154.665px;
	height: 45px;
	color: #FFF;
	font-size: 16.715px;
	text-align: center;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 8px;
}

section.entireexpertppv.listofoffermainsection .descriptionppvpop {
	background: transparent;
}

a.know-button {
	width: 154.665px;
	height: 45px;
	flex-shrink: 0;
	color: #fff;
	background: #2F596F;
	border-radius: 7px;
	display: inline-block;
	text-align: center;
	font-family: Montserrat;
	font-size: 16.715px;
	font-style: normal;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;

}

.entireexpertppv .expertprofilelogouploadinner>img {
	width: 270px;
}

/* .entireexpertppv .dp img {
    width: 400px;
    height: 400px;
} */
.detailsbank.bg-white-new-exp.no-after {
	position: relative;
}

.detailsbank.bg-white-new-exp.no-after:after {
	position: absolute;
	content: "";
	width: 3px;
	height: 72%;
	background: #ffffff;
	left: -46px;
	top: 63px;
}

.expertformppiinnernew .recaptcha_css {
	height: 90px !important;
	overflow: hidden;
}

.blogexpert .blogexpertinnerps .col-lg-4.col-sm-4 {
	display: grid;
	margin-bottom: 20px;
}

/*---global specification--*/
.globalexpertspecification ul li.active:before {
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid;
	position: absolute;
	content: "";
	right: -10px;
	top: 0;
	bottom: 0;
	margin: auto;
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.35));
	z-index: 99;

}
.globalexpertspecification ul li.active:after{display: none;}

.globalexpertspecification ul li:has( li.active):after {display: none;}

.globalexpertspecification ul li.active.Investment:before {
	border-left: 10px solid var(--lightpurple);
}

.globalexpertspecification ul li.active.Banking:before {
	border-left: 10px solid var(--lightBluetwo)
}

.globalexpertspecification ul li.active.Mortgage:before {
	border-left: 10px solid var(--lightBlueone);
}

.globalexpertspecification ul li.active.Insurance:before {
	border-left: 10px solid var(--lightorange);
}

.globalexpertspecification ul li.active.Real.Estate:before {
	border-left: 10px solid var(--lightgreen);
}

.globalexpertspecification ul li.active.Investment,
.latestdeailsexpertppvspecialize.active.Investment {
	background: var(--lightpurple);
}

.globalexpertspecification ul li.active.Banking,
.latestdeailsexpertppvspecialize.active.Banking {
	background: var(--lightBluetwo);

}

.globalexpertspecification ul li.active.Mortgage,
.latestdeailsexpertppvspecialize.active.Mortgage {
	background: var(--lightBlueone);

}

.globalexpertspecification ul li.active.Insurance,
.latestdeailsexpertppvspecialize.active.Insurance {
	background: var(--lightorange);

}

.globalexpertspecification ul li.active.Real.Estate,
.latestdeailsexpertppvspecialize.active.Real.Estate {
	background: var(--lightgreen);

}

.globalexpertspecification ul li.active {
	position: relative;
}

section.specialisationexpertppv .bg-white-new-exp .detailsbank h5 {
	color: #000;
	font-family: "Microsoft Sans Serif" !important;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 10px;
	position: relative;
	text-align: left;
	padding-left: 15px;
}

.bg-white-new-exp .detailsbank h5:before {
	content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background: url(../../../assets/expertppvimage/blackdot.svg);
    left: 0px;
    top: 5px;
   
}
.bg-white-new-exp .detailsbank h5:empty:before{display: none;}
.latestdeailsexpertppvspecialize.active.Investment .bg-white-new-exp{
	border-bottom: 1px solid var(--purple);
}
.latestdeailsexpertppvspecialize.active.Real.Estate .bg-white-new-exp{
	border-bottom: 1px solid var(--green);
}
.latestdeailsexpertppvspecialize.active.Mortgage .bg-white-new-exp{
	border-bottom: 1px solid var(--Blueone);
}
.latestdeailsexpertppvspecialize.active.Banking .bg-white-new-exp{
	border-bottom: 1px solid var(--Bluetwo);
}
.latestdeailsexpertppvspecialize.active.Insurance .bg-white-new-exp{
	border-bottom: 1px solid var(--orange);
}
.latestdeailsexpertppvspecialize .bg-white-new-exp:last-child
{
	border: none !important;
	
}
.globalexpertspecification .category-id5:hover, .globalexpertspecification .category-id4:hover, .globalexpertspecification .category-id3:hover,
.globalexpertspecification .category-id2:hover, .globalexpertspecification .category-id1:hover{
	box-shadow: none;
}
li.Insurance.category-id1:hover {
    cursor: pointer;
    background-color: #FFE3D04D;
}
li.Mortgage.category-id3:hover {
    cursor: pointer;
    background-color: #E9EEF980;
}
li.Investment.category-id2:hover {
    cursor: pointer;
    background-color: #FFE0FF4D;
}
li.Banking.category-id4:hover {
    cursor: pointer;
    background-color: #DAF3FF80;

}
li.Real.Estate.category-id5:hover {
    cursor: pointer;
    background-color: #D2FFD24D;
}
.aboutexpertppvback{
	
	background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    min-height: 250px;
    display: flex;
    justify-content: left;
    align-items: center;
}
/* .aboutexpertppvback:before {
	content: "";
    position: absolute;
    background: #2F596F;
    height: 100%;
    width: 22px;
    left: -23px;
    top: 0;
} */
.about-me-ppv {
    padding-left: 60px;
    padding-right: 50px;
}
.no-pad-right{padding-right: 0;}
.aboutexpertppv .container {
	max-width: 1366px !important;
}


.blogexpert .bloglist {
    justify-content: space-between;
    align-items: center;
	margin-bottom: 20px;
}
.blogexpert .bloglist a{display: flex;
    width: 100%;
	justify-content: space-between;
    text-align: left;
    margin: auto;
    align-items: center;
	padding: 20px 40px;
}

.blogexpert .blogtext h5 {
    text-align: left;
    color: #000;
    font-family: "Microsoft Sans Serif";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
	margin-top: 20px;

}

.blogexpert .blogtext p {
    color: #000;
    font-family: "Microsoft Sans Serif";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
	margin: 0;
}

.blogexpert .blogtext {
    text-align: left !important;
    padding-right: 0;

}
.blogexpert .bloglist:last-child{margin-bottom: 0;}
.blogexpert .bloglist:nth-child(1) .blogtext , .blogexpert .bloglist .blogtext{
    padding: 0;
    text-align: left !important;
    justify-content: left;
    margin: 0;
    align-items: normal;
    padding-left: 30px;
	width: 100%;
}
.blogexpert .bloglist:nth-child(1) a{display: flex !important;}
.blogexpert .bloglist:nth-child(1) .blogimage{margin: 0;}
.blogexpert .bloglist:nth-child(1), .blogexpert .bloglist:nth-child(4), .blogexpert .bloglist:nth-child(6), .blogexpert .bloglist:nth-child(8){ background: #EEECFB;;}
.blogexpert .bloglist:nth-child(2), .blogexpert .bloglist:nth-child(5){ background: #FFEBAF;}
.blogexpert .bloglist:nth-child(3), .blogexpert .bloglist:nth-child(7){ background:#DAF0FF ;}
.blogexpert .basicuserdetails{padding: 0;}
.listofoffermainsection .sliderDes a.applybutton{display: none;}
.hedingpading{
	margin-bottom: 20px;
}

.ads-hedding{
	color: #004710;  
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}
.entireexpertppv.listofoffermainsection .applybutton
{    font-family: "Roboto Flex", sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    width: 150px;
    height: 42px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    padding: 10px;
    transition: all .3s ease-in-out;
    border-radius: 8.014px;
    border-width: 1.603px;    border-style: solid;}
	.expppvofferinnersection .sliderContent{text-align: center;}
	.expppvofferinnersection .applybutton{
		padding: 10px 21px;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    margin-left: 20px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.09px;
    font-family: "Roboto Flex", sans-serif;
	}
	.entireexpertppv #ppvAdsSection{margin: 40px 0;}
	.expertformppinew.Premium{display: none;}
   