@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

section.ourbest.ourbestexpertsearch .ourexpertuser{ border-radius: 20px; transition: 0.3s all ease-in;}
section.ourbest.ourbestexpertsearch .Banking .ourexpertuser .Expertsbuttoni, section.ourbest.ourbestexpertsearch .Banking .ourexpertuser .Expertsbutton{background: var(--lightBluetwo);}
section.ourbest.ourbestexpertsearch .Investment .ourexpertuser .Expertsbuttoni, section.ourbest.ourbestexpertsearch .Investment .ourexpertuser .Expertsbutton{background: var(--lightpurple);}
section.ourbest.ourbestexpertsearch .Insurance .ourexpertuser .Expertsbuttoni, section.ourbest.ourbestexpertsearch .Insurance .ourexpertuser .Expertsbutton{background: var(--lightorange);}
section.ourbest.ourbestexpertsearch .Mortgage .ourexpertuser .Expertsbuttoni, section.ourbest.ourbestexpertsearch .Mortgage .ourexpertuser .Expertsbutton{background: var(--lightBlueone);}
section.ourbest.ourbestexpertsearch .Estate .ourexpertuser .Expertsbuttoni, section.ourbest.ourbestexpertsearch .Estate .ourexpertuser .Expertsbutton{background: var(--lightgreen);}
section.ourbest.ourbestexpertsearch .messagemebuttoncomm,section.ourbest.ourbestexpertsearch .myadsbuttoncomm{background: white; box-shadow: none; margin-bottom: 8px; color: #00801D;}
/* section.homecustom.homecustomsearchfull .blog-upperstrip.blog-upperstriptrending .d-flex.upperstrip{ display: none !important;} */
section.homecustom.homecustomsearchfull .blog-upperstrip{background: transparent;}
section.homecustom.homecustomsearchfull .bloginnertext{margin-top: 0;}

section.ourbest.ourbestexpertsearch .Banking .ourexpertuser:hover {filter: drop-shadow(0px 0px 11.024px rgba(3, 52, 80, 0.486));}
section.ourbest.ourbestexpertsearch .Investment .ourexpertuser:hover{ filter: drop-shadow(0px 0px 11.024px rgba(134, 53, 122, 0.50));}
section.ourbest.ourbestexpertsearch .Insurance .ourexpertuser:hover {filter: drop-shadow(0px 0px 11.146px rgba(255, 76, 0, 0.50));}
section.ourbest.ourbestexpertsearch .Mortgage .ourexpertuser:hover {filter: drop-shadow(0px 0px 11.024px rgba(47, 89, 111, 0.50));}
section.ourbest.ourbestexpertsearch .Estate .ourexpertuser:hover {filter: drop-shadow(0px 0px 11.024px rgba(0, 159, 36, 0.60));}
.searchcustom .ourexpertinner img{top:0}
.searchcustom section.topproduct {
    padding-bottom: 30px;
}
section.ourbest.ourbestexpertsearch .Banking .ourexpertuser .messagemebuttoncomm, section.ourbest.ourbestexpertsearch .Banking .ourexpertuser .myadsbuttoncomm{color: #fff; border-color:var(--Bluetwo); background: var(--Bluetwo);}
section.ourbest.ourbestexpertsearch .Investment .ourexpertuser .messagemebuttoncomm, section.ourbest.ourbestexpertsearch .Investment .ourexpertuser .myadsbuttoncomm{color: #fff; border-color: var(--puple); background: var(--purple);}
section.ourbest.ourbestexpertsearch .Insurance .ourexpertuser .messagemebuttoncomm, section.ourbest.ourbestexpertsearch .Insurance .ourexpertuser .myadsbuttoncomm{color: #fff; border-color: var(--orange); background: var(--orange);}
section.ourbest.ourbestexpertsearch .Mortgage .ourexpertuser .messagemebuttoncomm, section.ourbest.ourbestexpertsearch .Mortgage .ourexpertuser .myadsbuttoncomm{color: #fff; border-color: var(--Blueone); background: var(--Blueone);}
section.ourbest.ourbestexpertsearch .Estate .ourexpertuser .messagemebuttoncomm, section.ourbest.ourbestexpertsearch .Estate .ourexpertuser .myadsbuttoncomm{color: #fff; border-color: var(--green); background: var(--green);}



section.ourbest.ourbestexpertsearch .Banking .ourexpertuser .messagemebuttoncomm:hover, section.ourbest.ourbestexpertsearch .Banking .ourexpertuser .myadsbuttoncomm:hover{color: var(--Bluetwo); border-color:var(--Bluetwo); background: var(--lightBluetwo);}
section.ourbest.ourbestexpertsearch .Investment .ourexpertuser .messagemebuttoncomm:hover, section.ourbest.ourbestexpertsearch .Investment .ourexpertuser .myadsbuttoncomm:hover{color: var(--puple); border-color: var(--puple); background: var(--lightpurple);}
section.ourbest.ourbestexpertsearch .Insurance .ourexpertuser .messagemebuttoncomm:hover, section.ourbest.ourbestexpertsearch .Insurance .ourexpertuser .myadsbuttoncomm:hover{color: var(--orange); border-color: var(--orange); background: var(--lightorange);}
section.ourbest.ourbestexpertsearch .Mortgage .ourexpertuser .messagemebuttoncomm:hover, section.ourbest.ourbestexpertsearch .Mortgage .ourexpertuser .myadsbuttoncomm:hover{color: var(--Blueone); border-color: var(--Blueone); background: var(--lightBlueone);}
section.ourbest.ourbestexpertsearch .Estate .ourexpertuser .messagemebuttoncomm:hover, section.ourbest.ourbestexpertsearch .Estate .ourexpertuser .myadsbuttoncomm:hover{color: var(--green); border-color: var(--green); background: var(--lightgreen);}


section.ourbest.ourbestexpertsearch  .ourexpertuser .ourexpertinner h3{color: #000;
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
margin-top: 40px;
min-height: 60px;}

	section.ourbest.ourbestexpertsearch .ourexpertinnerimg img {
	
		border: 4px solid #fff;
		box-shadow: 3.52px 3.52px 38.719px 0px rgba(0, 0, 0, 0.25);
	}
	section.ourbest.ourbestexpertsearch .slick-prev, section.ourbest.ourbestexpertsearch .slick-next{width:30px; height: 30px;}
	section.ourbest.ourbestexpertsearch .slick-prev:before {
		content: "";
		background: url(../../../../assets/homeimages/slicknext.svg);
		width: 30px;
		height: 30px;
		background-size: contain;
		background-repeat: no-repeat;
		rotate: 180deg;
		display: block;
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		margin: auto;
	}
	section.ourbest.ourbestexpertsearch .slick-next::before{
		content: "";
		background: url(../../../../assets/homeimages/slicknext.svg);
		width: 30px;
		height: 30px;
		background-size: contain;
		background-repeat: no-repeat;
		rotate: 0deg;
		border-radius: 3px;
		display: block;
		position: absolute;
		right: 0px;
		top: 0;
		bottom: 0;
		margin: auto;
		
	}
	section.ourbest.ourbestexpertsearch .slick-prev, section.ourbest.ourbestexpertsearch .slick-next {
		width: 30px;
		height: 30px;
		top:100px;
		bottom: 0;
		margin: auto;
	}
	section.ourbest.ourbestexpertsearch .ourexpertuser .Expertsbuttoni{border-radius: 0 0px 20px 20px; padding-top: 10px;}
	section.ourbest.ourbestexpertsearch .ourexpertuser .Expertsbutton{padding-top: 8px;}
	section.ourbest.ourbestexpertsearch .ourexpertinner h3:after{background: #000;}
	.ourinstituteinnerimg {
		top: -48px;
		right: 0;
		left: 0;
		margin: auto;
		border-radius: 200px;
		width: 200px;
		height: 160px;
		position: absolute;
	}
	.ourinstituteinnerimg img {
		width: 100px;
	}
	
	.ourinstituteinnerimg img {
		border: 1px solid #000;
		border-radius: 8px;
	}
	.Estate .ourinstituteinnerimg img{border: 1.5px solid #00831D;}
	.Banking .ourinstituteinnerimg img{border: 1.5px solid #2280B8}
	.Investment .ourinstituteinnerimg img{border: 1.5px solid #86357A;}
	.Insurance .ourinstituteinnerimg img{border: 1.5px solid #FF4C00;}
	.Mortgage .ourinstituteinnerimg img{border: 1.5px solid #2F596F;}
	.searcharticleinner {
		display: flex;
		border-radius: 20px;
		background: #DAF0FF;
		margin-bottom: 20px;
		padding: 20px 45px;
		align-items: center;
	}
	
	.searcharticleimage {
		width: 160px;
		border-radius: 100px;
		height: 160px;
	}
	
	.searcharticleimage img {
		border-radius: 100px;
    width: 100%;
    height: 100%;
    object-fit: cover;
	}
	
	.searcharticlecontent h3 {
		color: #000;
		font-family: Montserrat;
		font-size: 28px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	
	.searcharticlecontent p {color: #000;font-family: Montserrat;font-size: 16px;font-style: italic;font-weight: 400;line-height: normal;margin-bottom: 0;}
	
	.searcharticlecontent {
		margin-left: 50px;
		color: #000;font-family: Montserrat;font-size: 16px;font-style: italic;font-weight: 400;line-height: normal;margin-bottom: 0;
	}
	.searcharticle h2{
		color: #000;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
margin-bottom: 10px;
	}
	.searchtopproduct h2{text-align: center;}
	.searcharticleinner:nth-child(2){background: #EEECFB;}
	.searcharticleinner:nth-child(3){background: #FFEBAF;}
	.searcharticleinner:nth-child(4){background: #DAF0FF;}
	.searcharticleinner:nth-child(5){background: #EEECFB;}
	/* section.ourbest.ourbestexpertsearch .ourexpertuser:hover {
		transform: scale(1.03);
	} */
.searchcustom .showallourbesttwo .mt-5.mb-4{margin-top: 20px !important; margin-bottom: 0 !important;}
section.searcharticle{margin-bottom: 40px;}
.searchcustom .latestbutton.margintop {
    margin-top: 0;
}
.searchcustom .ourexpertuser .ourexpertinner p{display: none;}
.searchcustom .Expertsbuttoni{padding-bottom: 5px;}
@media (max-width:767px){
	.unverifiedinfo {
		color: #EF000B;
		font-family: Montserrat;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin-top: 20px;
		display: inline-block;
		margin-left: 10px;
	}
	.searchcustom .searcharticle h2{font-size: 18px;}
	.searchcustom .mt-4.mb-4.categoryproductsliderinner {
		margin-top: 0 !important;
	}
	.searchcustom .offersss .col-lg-12.col-md-12.col-sm-12.col-xs-12  {
		padding: 0 0px;
	}
	.searchcustom .offersss .slick-slider{
		padding: 0;
	}
	.searchcustom .latestbutton.margintop.dasof {
		margin-top: 22px;
	}
	.searchcustom .messagemebuttoncomm, .searchcustom .myadsbuttoncomm {
		background-color: #00831D;
		font-family: "Roboto Flex", sans-serif !important;
		padding: 1px 5px;
		border-radius: 2px;
		color: white;
		margin: 2px;
		text-decoration: none;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 19px;
		width: 95px;
		border: 1px solid transparent;
		height: 25px;
	}
	.searchcustom .topproduct .slick-prev, .searchcustom .topproduct .slick-next{bottom: -5px;}
	.searchcustom .topproduct .slick-prev:before, .searchcustom .topproduct .slick-next:before{bottom: 0;}
	.searchcustom .topproduct .slick-prev{left: 40%; right: auto;}
	.searchcustom .topproduct .slick-next{right: 40%; left: auto;}
	.searchcustom section.ourbest.ourbestexpertsearch .slick-prev, 	.searchcustom section.ourbest.ourbestexpertsearch .slick-next{
		top:20px
	}
}