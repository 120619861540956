
 @media screen and (min-device-width: 1200px) and (max-device-width: 5000px) {
    /* .insuranceBanner {height: calc(100vh - 0px);} */
    /* .insuranceBanner {height: 702px;} */
 }
 
@media (max-width: 767px) {
    .insurancenewui .categorybanner{
        background: url(../../../assets/insuranceimges/insurancemobilebanner.svg);
        background-position: bottom !important;
    height: 450px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    }
    
    .insurancenewui .categorysectiontwo, .investmentnewui .categorysectionthree{
        background: url(../../../assets/insuranceimges/Insurancebgmobile.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .insurancenewui .categorysectionsix.blogdetailsexpertppv{
        background: url(../../../assets/insuranceimges/Insurancearticlebg.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .insurancenewui  section.categorysectionsix .blogreadmore{
        color: #FF4C00;
        border-color: #FF4C00;
        background: #FFE3D0;
    }
    .spantextcomm {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }
    .insurance .rimibmentfont p {
        font-size: 18px;
        max-width: 100%;
    }
    .insurance b.realEbold {
        font-size: 28px; 
    }
    .insurance p.rimibmentsubtext span {
        font-size: 18px;
    }
    .insurance .realEbuttonmain {
        width: 100%;
    }
    .insurance .realEbuttonmain p.realEbutton {
        font-size: 28px;
    }
    .insuranceviewallbuttoncomm {
      padding: 10px 40px;
      font-size: 20px;
      font-weight: 500;
      border-radius: 4px;
    }
    .insurance.makethecontainer .realEinner {
        margin:0px;
    
}
    .insuranceviewallbuttoncomm:hover {
      box-shadow: 2px 2px 20px 0px #00000030;
      background-color: #028a0f;
      border: 4px solid #b6d055;
      color: #ffffff;
    }
  
    .insurancemessagemebuttoncomm {
      padding: 8px 40px;
      font-size: 16px;
      border-radius: 10px;
    }
  
    .insurancemessagemebuttoncomm:hover {
      background-color: #f2f2f2;
      color: #333333;
    }
  
    .insuranceBanner {
      padding: 0px 0;
      background-size: contain;
    }
    .insurance .rimibmentfont h2 {
      font-size: 48px;
      font-weight: 700;
    }
    .insurance .rimibmentfont h6 {
      font-size: 28px;
      line-height: 36px;
      font-weight: 400;
    }
    .insurancetwo .letest-inner.resclass h2 {
        font-size: 31px;
    }
   .insurancetwo .letest-inner h6 {
        font-size: 17px;
        margin-bottom: 20px;
    }
    .insurancetwo .resclass .homepageproductoffer .letest-inner h2 {
        font-size: 32px;
        margin-bottom: 15px;
    }
    .insurancetwo .letest-inner span {
        font-size: 18px;
    }
    .insurancetwo .investmentviewallbuttoncomm {
        padding: 8px 40px;
        font-size: 20px;
    }
    .offersss {
        margin-bottom: 30px;
    }
    .insurancethree .TopRealEstateProducts-inner h2.investthreesubtext {
        font-size: 36px;
        margin-bottom: 15px;
    }
    .insurancethree .viewallbuttoncomm {
        padding: 8px 40px;
        font-size: 20px;
    }
    .insurancefour.homepageproductoffer h2.letestadsheading {
        font-size: 28px;
        line-height: 36px;
    }
    section.homepageproductoffer.insurancefour {
        margin: 50px 0;
    }
    .insurancefour .viewallbuttoncomm {
        padding: 8px 40px;
        font-size: 20px;
    }
    .insurancefive  .toprealEbutton {
        padding: 8px 21px;
        font-size: 17px;
    }
    .insurancefive .showallbtn {
        max-width: 100%;
        justify-content: center;
        text-align: center;
    }
    .insurancesix  h2.expertheading {
        font-size: 29px;
        margin: 0;
    }
    .insurancesix h5.expertsub1 {
        font-size: 15px;
        margin: 0;
    }
    .insurancesix .expertsub2 {
        font-size: 15px;
        margin: 0;
    }
    .insurancesix .viewallbuttoncomm {
        padding: 8px 40px;
        font-size: 20px;
    }
    section.homepageproductoffer.bestdeals.Experts.insurancesix {
        padding-bottom: 0px;
    }
    .insuranceseven .realEquestion .realEquestiontext h4 {
        font-size: 20px;
        color: #FFFFFF;
        font-weight: 600;
        margin: 0;
    }
     .insuranceseven h2.resstext {
        font-size: 17px;
        color: #FFFFFF;
        font-weight: 500;
        margin: 0;
    }
    .insuranceseven .realEquestiontext h3 {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 20px;
    }
    
    .realEbloginner p.realEblogtext{
        font-size: 10px;
    }
    .insuranceeight p.realEblogtext {
        font-size: 18px;
        max-width: 100%;
    }
    .insuranceeight .viewallbuttoncomm {
        padding: 8px 40px;
        font-size: 20px;
    }
}
@media (max-width: 420px) {
    .spantextcomm {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
    }
    .insurance .rimibmentfont p {
        font-size: 16px;
        max-width: 100%;
    }
    .insurance b.realEbold {
        font-size: 24px;
    }
    .insurance p.rimibmentsubtext span {
        font-size: 16px;
    }
    .insurance .realEbuttonmain {
        width: 100%;
    }
    .insurance .realEbuttonmain p.realEbutton {
        font-size: 24px;
    }
    .insuranceviewallbuttoncomm {
        padding: 8px 20px;
        font-size: 16px;
        font-weight: 400;
        border-radius: 4px;
    }
  
    .insuranceviewallbuttoncomm:hover {
        box-shadow: 2px 2px 10px 0px #00000030;
        background-color: #028a0f;
        border: 3px solid #b6d055;
        color: #ffffff;
    }
  
    .insurancemessagemebuttoncomm {
        padding: 6px 20px;
        font-size: 14px;
        border-radius: 8px;
    }
  
    .insurancemessagemebuttoncomm:hover {
        background-color: #f2f2f2;
        color: #333333;
    }
  
    .insuranceBanner {
        padding: 0;
        background-size: contain;
    }
    .insurance .rimibmentfont h2 {
        font-size: 36px;
        font-weight: 700;
    }
    .insurance .rimibmentfont h6 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
    }
    .insurancetwo .letest-inner.resclass h2 {
        font-size: 24px;
    }
   .insurancetwo .letest-inner h6 {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .insurancetwo .resclass .homepageproductoffer .letest-inner h2 {
        font-size: 25px;
        margin-bottom: 10px;
    }
    .insurancetwo .letest-inner span {
        font-size: 16px;
    }
    .insurancetwo .investmentviewallbuttoncomm {
        padding: 6px 20px;
        font-size: 16px;
    }
    .offersss {
        margin-bottom: 20px;
    }
    .insurancethree .TopRealEstateProducts-inner h2.investthreesubtext {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .insurancethree .viewallbuttoncomm {
        padding: 6px 20px;
        font-size: 16px;
    }
    .insurancefour.homepageproductoffer h2.letestadsheading {
        font-size: 20px;
        line-height: 28px;
    }
    section.homepageproductoffer.insurancefour {
        margin: 30px 0;
    }
    .insurancefour .viewallbuttoncomm {
        padding: 6px 20px;
        font-size: 16px;
    }
    .insurancefive .toprealEbutton {
        padding: 6px 15px;
        font-size: 14px;
    }
    .insurancefive .showallbtn {
        max-width: 100%;
        justify-content: center;
        text-align: center;
    }
    .insurancesix h2.expertheading {
        font-size: 24px;
        margin: 0;
    }
    .insurancesix h5.expertsub1 {
        font-size: 13px;
        margin: 0;
    }
    .insurancesix .expertsub2 {
        font-size: 13px;
        margin: 0;
    }
    .insurancesix .viewallbuttoncomm {
        padding: 6px 20px;
        font-size: 16px;
    }
    section.homepageproductoffer.bestdeals.Experts.insurancesix {
        padding-bottom: 0;
    }
    .insuranceseven .realEquestion .realEquestiontext h4 {
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 600;
        margin: 0;
    }
    .insuranceseven h2.resstext {
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 500;
        margin: 0;
    }
    .insuranceseven .realEquestiontext h3 {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 15px;
    }
    
    .realEbloginner p.realEblogtext {
        font-size: 8px;
    }
    .insuranceeight p.realEblogtext {
        font-size: 16px;
        max-width: 100%;
    }
    .insuranceeight .viewallbuttoncomm {
        padding: 6px 20px;
        font-size: 16px;
    }
    .posbuttontf {
        justify-content: center;

    }
    .insurancetwo .offersss .posbuttontf.d-flex a{
        margin: 0;
    }
}
@media (max-width: 320px) {
    .spantextcomm {
        font-size: 14px;
        font-weight: 300;
        color: #333333;
    }
    .insurance .rimibmentfont p {
        font-size: 14px;
        max-width: 100%;
    }
    .insurance b.realEbold {
        font-size: 20px;
    }
    .insurance p.rimibmentsubtext span {
        font-size: 14px;
    }
    .insurance .realEbuttonmain {
        width: 100%;
    }
    .insurance .realEbuttonmain p.realEbutton {
        font-size: 20px;
    }
    .insuranceviewallbuttoncomm {
        padding: 6px 15px;
        font-size: 14px;
        font-weight: 300;
        border-radius: 4px;
    }
  
    .insuranceviewallbuttoncomm:hover {
        box-shadow: 1px 1px 5px 0px #00000030;
        background-color: #028a0f;
        border: 2px solid #b6d055;
        color: #ffffff;
    }
    .insuranceeight .realEbloginner h2{
        font-size: 12px;
    }
  
    .insurancemessagemebuttoncomm:hover {
        background-color: #f2f2f2;
        color: #333333;
    }
    .realEblogbg .realEbloginner h2 {
        color: #000000;
        font-size: 36px;
        font-weight: 700;
        line-height: 1.1;
    }
    .insuranceBanner {
        padding: 0;
        background-size: contain;
    }
    .insurance .rimibmentfont h2 {
        font-size: 28px;
        font-weight: 700;
    }
    .insurance .rimibmentfont h6 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
    .insurancetwo .letest-inner.resclass h2 {
        font-size: 20px;
    }
   .insurancetwo .letest-inner h6 {
        font-size: 12px;
        margin-bottom: 10px;
    }
    .insurancetwo .resclass .homepageproductoffer .letest-inner h2 {
        font-size: 22px;
        margin-bottom: 8px;
    }
    .insurancetwo .letest-inner span {
        font-size: 14px;
    }
    .insurancetwo .investmentviewallbuttoncomm {
        padding: 5px 15px;
        font-size: 14px;
    }
    .offersss {
        margin-bottom: 15px;
    }
    .insurancethree .TopRealEstateProducts-inner h2.investthreesubtext {
        font-size: 20px;
        margin-bottom: 8px;
    }
    .insurancethree .viewallbuttoncomm {
        padding: 5px 15px;
        font-size: 14px;
    }
    .insurancefour.homepageproductoffer h2.letestadsheading {
        font-size: 16px;
        line-height: 24px;
    }
    section.homepageproductoffer.insurancefour {
        margin: 20px 0;
    }
    .insurancefour .viewallbuttoncomm {
        padding: 5px 15px;
        font-size: 14px;
    }
    .insurancefive .toprealEbutton {
        padding: 5px 12px;
        font-size: 12px;
    }
    .insurancefive .showallbtn {
        max-width: 100%;
        justify-content: center;
        text-align: center;
    }
    .insurancesix h2.expertheading {
        font-size: 20px;
        margin: 0;
    }
    .insurancesix h5.expertsub1 {
        font-size: 12px;
        margin: 0;
    }
    .insurancesix .expertsub2 {
        font-size: 12px;
        margin: 0;
    }
    .insurancesix .viewallbuttoncomm {
        padding: 5px 15px;
        font-size: 14px;
    }
    section.homepageproductoffer.bestdeals.Experts.insurancesix {
        padding-bottom: 0;
    }
    .insuranceseven .realEquestion .realEquestiontext h4 {
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 600;
        margin: 0;
    }
    .insuranceseven h2.resstext {
        font-size: 12px;
        color: #FFFFFF;
        font-weight: 500;
        margin: 0;
    }
    .insuranceseven .realEquestiontext h3 {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    
    .realEbloginner p.realEblogtext {
        font-size: 6px;
    }
    .insuranceeight p.realEblogtext {
        font-size: 14px;
        max-width: 100%;
    }
    .insuranceeight .viewallbuttoncomm {
        padding: 5px 15px;
        font-size: 14px;
    }
}

