button.craete-button.craete-button-margin {
    margin-left: 20%;
}
 

.single-blog.width-set {
    margin-top: 60px;
    width: 31%;
    padding-bottom: 10px;
}

.row.subcategory-heading {
    padding: 50px 0px 50px 0;
    margin: 50px 0px 10px 5px;
    width: 100%;
}


button.offer-edit-btn {
    width: 62px;
    height: 30px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 500;
    color: #FFF;
    border: none;
    background: #2280B8;
    margin-right: 10px;
}

.offer-btn-img {
    width: 15px;
    height: 15px;
    padding-left: 2px;
}



button.offer-delete-btn {
    width: 65px;
    height: 30px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 500;
    border: none;
    border: 1.5px;
    border: 1.5px solid #2280B8;
    color: #2280B8;
}

span.slider.round::after {
    top: -20px;
    font-weight: 500;
    font-size: 12px;
}


span.slider.round::before {
    border-radius: 3px;
}

.diffrance.switch .slider.round:before {
    border-radius: 3px;
    background: #FFFFFF;
    background: url(../../../../assets/Instituteppvimages/switch.svg);
    padding-right: 10px;
    width: 30px;
    height: 22px;
}

.diffrance.switch input:checked+.slider {
    background-color: #2280B8;

}

.diffrance.switch input:checked+.slider::after {
    content: "Active";
    position: absolute;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    left: 15px;
    top: -20px;
    color: #06293f;
}

.icon-true {
    width: 21px;
    height: 15px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 22px;
    margin-top: 4px;
    margin-left: 3px;
    background: url(../../../../assets/Instituteppvimages/right.svg);
}

.posterswitch {
    margin-top: 15px;
}

.diffrance.switch span.slider.round {
    border-radius: 5px;
    height: 30px;
    width: 68px;
}

.diffrance.switch input:checked+.slider:before {
    transform: translateX(30px);
}

.blog-description {
    padding-bottom: 2px;
}

.offer-divider {
    height: 1px;
    width: 100%;
    border: 1px solid #D7D7D7;
    margin-bottom: 20px;
}

.sub-title {
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.craete-offer-containt {
    background-image: url("../../../../assets/Instituteppvimages/Institution_Dashboard_image.svg");
    background-Repeat: no-repeat;
    border-radius: 10px;
    padding: 12px;
    height: 100px;
    padding-left: calc(var(--bs-gutter-x)* 1);
     
  }


  .offertitle {
    margin: 10px 0 5px auto;
    font-weight: 700;
    font-size: 30px;  
    padding-top: 20px;
    color: #000;  
  }
  
  .offersubtitle {
    font-size: 16px;
    font-weight: 500;   
    color: #000; 
    line-height: 9px; 
  }
  section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain.offernewform span.dateim {
    position: absolute;
    right: 7px;
    top: 37px;
}
section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain.offernewform .uploadexpert {
    background: #D2FFD2;
    height: 155px;
    overflow: hidden;
    margin-bottom: 9px;
    padding: 50px 10px;
}
section.entiredashboard.maincustomerdash .expertinnerformmain.offernewform .showallourbesttoo, section.entiredashboard.maincustomerdash .expertinnerformmain .showallourbesttoo, .craete-ads .showallourbesttoo{
    margin-right: 0;
}
section.entiredashboard.maincustomerdash.institute_dashboard .expertinnerformmain.offernewform .DatePicker.input-container.form-group input{
    height: 50px;
    background: #D2FFD2;
}
section.entiredashboard.maincustomerdash.institute_dashboard  span.dateim img, section.entiredashboard.maincustomerdash.institute_dashboard .input-icon-add img {
    filter: invert(18%) sepia(99%) saturate(4990%) hue-rotate(145deg) brightness(97%) contrast(101%);
}
