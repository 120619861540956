section.entiredashboard.maincustomerdash.expert-dashboard .col-lg-4.col-sm-4:before {
	position: absolute;
	top: 0;
	border-radius: 25px 25px 0 0;
	background-color: #fff;
	width: 73px;
	height: 100%;
	content: "";
	left: 35px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .col-lg-4.col-sm-4 {
	position: relative;
}

section.entiredashboard.maincustomerdash.expert-dashboard .dashboardlink.dashboardtext .active {
	color: #FF4C00;
	background-color: #fff;
	border-radius: 50px 0 0 50px;
	padding: 0 22px;
	width: 100%;
	min-height: 69px;
	display: flex;
	margin-bottom: 0;
	align-items: center;
	text-align: start;
	position: relative;
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav.qnanav ul li {
	margin-right: 40px;
	line-height: 1;
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav.qnanav ul li.active .micon img {
	filter: invert(1);
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav.qnanav ul li .micon img {
	width: 50px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav.qnanav ul li.active {
	background: #FF4C00;
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav ul li:first-child.active {
	background: #FF4C00;
	color: #fff;
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav.qnanav ul li.active {
	color: #fff;
	line-height: 1;
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav.qnanav ul li {
	width: 140px;
	height: 140px;
	background-color: #DAF3FF;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	color: #000;
	margin-left: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav.qnanav.martop40 ul li.active a {
	color: #FFF;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 1;
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav.qnanav.martop40 ul li a {
	color: #000;
	text-align: center;

	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 1;
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav.qnanav ul li span {
	color: #000;
	text-align: center;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	display: block;
	background: none;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	padding: 0;
	margin: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .myexpertnav.myaccountnav.qnanav ul li.active span {
	display: block;
	background: none;
	color: #FFF;
	text-align: center;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	padding: 0;
	margin: 0;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard>.container-fluid>.row>.col-lg-4.col-sm-4 {
	background-color: #D1F0FF;
	border-radius: 25px 25px 0 0;
	box-shadow: none;
	padding: 0;
	max-width: 400px;
}

.expert-dashboard .container-fluid {
	border-radius: 25px;
	background: #FFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

.expert-dashboard .dashboardlink.dashboardtext .active:before {
	position: absolute;
	content: "";
	background: #000;
	height: 100%;
	width: 7px;
	border-radius: 3px;
	left: -73px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .dashboardlink.dashboardtext .active a {
	color: #000;
	background-color: #fff;
	border-radius: 50px 0 0 50px;
	padding: 0 22px;
	width: 100%;
	min-height: 69px;
	display: flex;
	margin-bottom: 0;
	align-items: center;
	text-align: start;
}

section.entiredashboard.maincustomerdash.expert-dashboard .responsename {
	margin-left: 0;
	flex-wrap: wrap;
	margin-bottom: 0;
	color: #000;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .viewprofilebtn {
	color: #000;
	text-decoration: underline !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-top: 10px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .maincustomerdashboard .dashboardlogo,
section.entiredashboard.maincustomerdash.expert-dashboard .maincustomerdashboard .dashboardlogo img {
	width: 100px;
	height: 100px;
	border-radius: 100px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .maincustomerdashboard .dashboardlogo {
	width: 124px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .userdetails {
	margin-left: 90px;
}

.expertblogtextback {
	border-radius: 10px;
	background: #DAF3FF;
	padding: 20px;
	margin-top: 40px;
}

.expertblogtextback h3 {
	color: #000;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.expertblogtextback h5 {
	color: #000;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 20px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails {
	background: #FFF;
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
	margin-top: 40px;
	position: relative;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails h3.h3_style {
	color: #2A2A2A;
	margin-bottom: 30px;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
	/* 100% */
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .button_outer {
	background: white;
	border: 1px solid #EF000B;
	padding: 8px 5px 0;
	border-radius: 5px;
	display: inline-block !important;
	margin-left: auto;
	margin-right: auto;
	height: auto;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .uploaddetails .button_outer label {
	color: #EF000B;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 2;
	letter-spacing: 1px;
	font-family: "Roboto Flex", sans-serif;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .btn_upload {
	padding: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .uploaddetails label img {
	width: 21px !important;
	height: 21px !important;
	margin: 0 !important;
	box-shadow: none !important;
	border: none !important;
	border-radius: 0 !important;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .logoform .form-fieldinner img {
	width: 100px;
	height: 100px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails button.myadbtn {
	background: #FF4C00;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .grid-2 {
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .form-fieldinner {
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .grid-1 {
	grid-gap: 20px 20px;
	margin-bottom: 20px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .form-fieldinner.sociallink label {
	background: #ffffff;
	border: none;
	border-radius: 4px;
	width: 60px;
	height: 60px;
	text-align: center;
	line-height: 28px;
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .form-fieldinner.sociallink .fieldans {
	color: #212121;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails.awardsectionmyaccount .form-fieldinner ul li {
	color: #303030;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border-radius: 10px;
	background: #D1F0FF;
	margin-right: 10px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .myaccountsection.institutedetails {
	margin-top: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails button.myadbtn.absolutedown {
	position: absolute;
	bottom: auto;
	right: 15px;
	top: 50px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails button.myadbtn {
	background: #FF4C00;
	position: absolute;
	top: 15px;
	right: 15px;
	margin-right: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails button.myadbtn.absoluteup {
	background: #FF4C00;
	position: absolute;
	top: 15px;
	right: 15px;
	margin-right: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .form-fieldinner label {
	color: #000;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .fieldans {
	color: #303030;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .form-fieldinner .fieldans.d-flex span {
	border-radius: 10px;
	background: #D1F0FF;
	padding: 5px 10px;
	margin-right: 10px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .placeholder {
	transform: none;
	color: #3B3737;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	position: relative;
	top: 0;
	left: 0;
	padding: 0;
	background: none;
	opacity: 1;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .ss {
	margin-left: 20px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .input-container.form-group.specialtag {
	border-radius: 3px;
	background: #D1F0FF;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .input-container.form-group.specialtag .form-control {
	border: none;
	background: transparent;

}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec span.rti--tag {
	border-radius: 20px;
	background: #FFF;
	color: #303030;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec span.rti--tag button {
	background: #000;
	width: 13px;
	height: 13px;
	display: inline-block;
	font-size: 8px;
	padding: 0;
	color: #fff;
	line-height: 1;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .input-container.form-group.specialtag>div>div {
	border-radius: 3px;
	background: #D1F0FF;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .formscicon {
	position: absolute;
	top: -2px;
	left: -16px;
	color: #06293f;
	width: 45px;
	height: 45px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .selectbox.css-b62m3t-container .css-13cymwt-control {
	height: 37px;
	border-radius: 3px;
	/* border: 1px solid #2280B8; */
	color: #303030;
}
section.entiredashboard.maincustomerdash  .radioform label.radiocontrol input[type="radio"]{	accent-color:#FF4C00;margin-left: 5px;}
section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .form-group .form-control {
	padding: 5px;
	


}
section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails button.myadbtn.adptp {
    top: -50px;
    right: 0;
}
.textchange{
	color: #000;
margin-top: 30px;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails .myaccountsection.institutedetails {
	filter: none;
	padding: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails button.ctaonebutton.widthhalf {
	background-color: white;
	border: 1px solid #EF000B;
	color: #EF000B !important;
	padding: 8px 19px;
	border-radius: 5px;
	font-size: 20px;
	font-weight: 700;
	/* display: inline-block; */
	width: 150px;
	height: 40px;
	color: #EF000B;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .myaccountsection.institutedetails button.ctaonebutton.widthhalf:hover {
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #EF000B;
	background: #EF000B;
	color: #FFF !important;
}

section.entiredashboard.maincustomerdash.expert-dashboard .addexpertformsec .form-group .form-control {
	border-radius: 3px;
	border: 1px solid #2280B8;
	color: #303030;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	height: 40px;

}

section.entiredashboard.maincustomerdash.expert-dashboard .myaccountsection.institutedetails .ss .form-group .form-control {
	padding: 5px;
	padding-left: 36px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .planesall {
	border-radius: 6px;
	background: #D1F0FF;
	box-shadow: 10px 10px 16px 0px rgba(0, 0, 0, 0.25);
	border: none;
}

section.entiredashboard.maincustomerdash.expert-dashboard .broadtp {
	border-radius: 6px;
	border: 1px solid #E2E2E2;
	background: #FFF;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

section.entiredashboard.maincustomerdash.expert-dashboard h2.text-center.h3_style.borname {
	position: absolute;
	top: -69px;
	width: 90%;
	background: #FF4C00;
	filter: drop-shadow(10px 10px 16px rgba(0, 0, 0, 0.25));
	color: #FFF;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 20px;
	border-radius: 5px 5px 0 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .planesinnerright.broadtp hr {
	margin-top: 42px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .planh2 {
	color: #303030;

	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 40px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .accountchangepass section.loginscreeninstitute.registrationinstitute {
	background: #FFF;
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
	margin-top: 40px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .accountchangepass .institutelogintext {
	width: 100%;
	margin-left: 0;
	margin-right: auto;
	padding-top: 30px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .ft-inner.otpauthentication {
	width: 70%;
	margin: auto;
}

section.entiredashboard.maincustomerdash.expert-dashboard .right-institute .forgetpassinner h2 {
	color: #000;
	margin-bottom: 0;
	font-family: "Montserrat", sans-serif !important;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
	/* 125% */
}

section.entiredashboard.maincustomerdash.expert-dashboard .right-institute .forgetpassinner h5 {
	color: #000;
	font-family: "Montserrat", sans-serif !important;
	font-size: 20px;
	margin-bottom: 0;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .right-institute .forgetpassinner h6 {
	color: #000;
	margin-bottom: 0;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	font-family: "Montserrat", sans-serif !important;
}

section.entiredashboard.maincustomerdash.expert-dashboard .expertquestiondetails {
	flex-wrap: wrap;
	width: 90%;
}

.dashboardlink ul li span img {
	filter: brightness(100);
	width: 30px;
}

.dashboardrightpanelinner .questioninner .qnabutton {
	color: #FFF;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	border: 1px solid transparent;
	padding: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .dashboardrightpanelinner textarea.form-control.textareaform {
	height: 141px;
	padding: 15px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .expertquestiondetails h5 {
	margin-bottom: 0;
	color: #000;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.dashboardrightpanelinner .questioninner .RealEstate .qnabutton {
	background-color: var(--green);
}

.dashboardrightpanelinner .questioninner .Investment .qnabutton {
	background-color: var(--purple);
}

.dashboardrightpanelinner .questioninner .Insurance .qnabutton {
	background-color: var(--orange);
}

.dashboardrightpanelinner .questioninner .Mortgage .qnabutton {
	background-color: var(--Blueone);
}

.dashboardrightpanelinner .questioninner .Banking .qnabutton {
	background-color: var(--Bluetwo);
}



.dashboardrightpanelinner .questioninner .RealEstate .qnabutton:hover {
	border-color: var(--green);
	color: var(--green);
	background: transparent;
}

.dashboardrightpanelinner .questioninner .Investment .qnabutton:hover {
	border-color: var(--purple);
	color: var(--purple) ;
	background: transparent;
}

.dashboardrightpanelinner .questioninner .Insurance .qnabutton:hover {
	border-color: var(--orange);
	color: var(--orange);
}

.dashboardrightpanelinner .questioninner .Mortgage .qnabutton:hover {
	border-color: var(--Blueone);
	color: var(--Blueone);
	background: transparent;
}

.dashboardrightpanelinner .questioninner .Banking .qnabutton:hover {
	border-color: var(--Bluetwo);
	color: var(--Bluetwo);
	background: transparent;
}


.p-0.blogblogmain {
	padding: 0 5px !important;
}
.dashboardrightpanelinner .questioninnerin {
    /* z-index: 1; */
    position: relative;
}
.dashboardrightpanelinner .questioninner .qnabutton {
	width: 150px;
	float: right;
}

.questioninnerin span button {
	background: none;
	border: none;
	padding: 0;
	padding-left: 10px;
	font-weight: bold;
	text-decoration: underline;
}

section.entiredashboard.maincustomerdash.expert-dashboard .input-container .placeholder {
	position: relative;
	left: 0;
	top: 0;
	margin-top: 0;
}
section.entiredashboard.maincustomerdash.expert-dashboard .input-caption input.input.form-control
{
	border: 1px solid #2280B8;
    border-radius: 3px;
	height: 48px;
}
.input-caption {
    position: relative;
}
section.entiredashboard.maincustomerdash.expert-dashboard .craete-ads{position: relative;}
section.entiredashboard.maincustomerdash.expert-dashboard .close-ads{
	position: absolute;
	top:15px;
	right:15px;
	background: #FF4C00;
}
section.entiredashboard.maincustomerdash.expert-dashboard .craete-ads .input-container .placeholder{margin-top: 14px;;}
section.entiredashboard.maincustomerdash.expert-dashboard .showallourbestone {
	border-radius: 5px;
	border: 1px solid #000;
	color: #000;
    margin-left: 10px !important;
}
.form-field.speciallinfobox {
	background: #FFF;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
   margin-bottom: 30px;
    position: relative;
	padding: 15px;
	border-radius: 5px;
}
.form-field.speciallinfobox:first-child{
	border: none;
}
.uploaddetails{display: flex;}
.uploaddetails .ctaonebutton{margin-left: 10px;}
.qbtn .showallourbesttoo:nth-child(1) {
	margin-right: 10px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .qmodal ul {
	background: transparent;
	padding: 0px;
	margin-top: 0;
	border-radius: 5px;
	height: auto;
	overflow-y: auto;
	list-style-type: disc;
}

section.entiredashboard.maincustomerdash.expert-dashboard .qmodal ul li:hover {
	background: none;
	color: #000;
}

section.entiredashboard.maincustomerdash.expert-dashboard .qmodal ul li {
	word-break: break-all;
	list-style: disc;
	padding: 10px 0;
	padding-top: 0;
	font-size: 13px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .replacelogo2.ctaonebutton,
section.entiredashboard.maincustomerdash.expert-dashboard .subscribtionsummeryheading h4 {
	background: #FF4C00 ;
}
button[disabled]:hover
{
	cursor:unset;
}
button.showallourbesttoo[disabled]:hover {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid transparent;
    background: #ccc;
    color: #FFF !important;
}
section.entiredashboard.maincustomerdash.expert-dashboard .replacelogo2.ctaonebutton {
	color: #FFF;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
}

.expert-dashboard .dashboardrightpannerinnerleft h2.h2_style.font-weight-600 {
	color: #000;
	margin-bottom: 0;
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.expert-dashboard .dashboardrightpannerinnerleft h5,
.expert-dashboard .questioninner h5.h5_style {
	color: #000;
	margin-bottom: 20px;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.expert-dashboard .questioninner h2 {
	color: #000;

	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.expert-dashboard .expertquestiondetails h5.h5_style a {
	text-decoration: underline !important;
	color: #000;
}

.expert-dashboard .expertquestiondetails .qmodal h2 {
	color: #000;

	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.expert-dashboard .expertquestiondetails .qmodal p {
	color: #000;
	margin-bottom: 10px;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	font-family: 'microsoft_sans_serifregular' !important;
}

.expert-dashboard .expertquestiondetails .qmodal p>br {
	margin-bottom: 10px;
	content: " ";
	display: block;
}

section.entiredashboard.maincustomerdash.expert-dashboard .qmodal ul li {
	color: #000;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	position: relative;
	padding-left: 20px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .qmodal ul li:before {
	content: "";
	position: absolute;
	height: 10px;
	width: 10px;
	border-radius: 10px;
	background: #000;
	left: 0;
	top: 4px;
}
section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin .qmodal .backnorm{
	padding: 20px;
}
section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin.Banking .qmodal .backnorm {
	background: var(--lightBluetwo);
}

section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin.Insurance .qmodal .backnorm {
	background: var(--lightorange);
}

section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin.Investment .qmodal .backnorm {
	background: var(--lightpurple);
}

section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin.Mortgage .qmodal .backnorm {
	background: var(--lightBlueone);
}

section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin.Real.Estate .qmodal .backnorm {
	background: var(--lightgreen);
}

section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin .qmodal .close_btn {
	width: 52px;
	height: 52px;
	border-radius: 52px;
	background: #00831D;
	line-height: 51px;
	left: auto;
	right: 0px;
	top: 0px;
	position: absolute;
	text-align: center;
	cursor: pointer;
	border: none;
}

section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin.Banking .qmodal .close_btn {
	background: var(--Bluetwo);
}

section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin.Insurance .qmodal .close_btn {
	background: var(--orange);
}

section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin.Investment .qmodal .close_btn {
	background: var(--purple);
}

section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin.Mortgage .qmodal .close_btn {
	background: var(--Blueone);
}

section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin.Real.Estate .qmodal .close_btn {
	background: var(--green);
}

section.entiredashboard.maincustomerdash .blogblogmain .bloginnertext {
	color: #303030;
	font-family: 'microsoft_sans_serifregular' !important;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
	min-height: 50px;
}

section.entiredashboard.maincustomerdash .blogblogmain {
	margin-bottom: 30px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .craete-ad-containt p.subtitle {
	color: #000;
	margin-bottom: 0px;
	font-size: 20px;
	font-style: italic;
	font-weight: 600;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard button.col-4.craete-ads-button {
	background: #2280B8;
}

section.entiredashboard.maincustomerdash.expert-dashboard .adsblog {
	margin-top: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .switch-active input:checked+.slider {
	background-color: #FF4C00;
	;
}

section.entiredashboard.maincustomerdash.expert-dashboard .craete-ad-containt {
	background: url(../../../assets/images/myadexpertback.svg);
}

.psshadow {
	background: #FFF;
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
	border-radius: 5px;
	padding: 10px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionleft h4,
section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionright h4 {
	color: #303030;
	margin-bottom: 0;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionleft p,
section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionright p {
	color: #303030;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .subscribtionsummeryheading h4 {
	color: #FFF;

	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .currentplan.text-center h4 {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 30px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .currentplan.text-center h4 span {
	color: #FF4C00;

	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding-left: 10px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .substartdates,
section.entiredashboard.maincustomerdash.expert-dashboard .substartdates {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionright .subscriptiondetailsleft h4,
section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionright .subscriptiondetailsright h4 {
	color: #FF4C00;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .subscribtionsummery {
	border-radius: 5px;
	background: #D1F0FF;
	box-shadow: 5px 5px 20px 0px rgba(6, 40, 61, 0.20);
	margin-bottom: 34px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .subscriptiondetails {
	padding-bottom: 40px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .changeplanbtn {
	margin: auto;
	width: 220px;
	height: 50px;
	backdrop-filter: blur(2px);
	color: #FFF;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	background: #FF4C00;
}

section.entiredashboard.maincustomerdash.expert-dashboard  .backor {
	background: #FF4C00 !important;
}

section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionleft .previewcardinner {
	padding: 40px 20px
}

section.entiredashboard.maincustomerdash.expert-dashboard .paymenthistory h3 {
	color: #000;

	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .paymenthistory p {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .paymenthistory ul.paymentstructure li:nth-child(1) {
	background: transparent;
	color: #303030;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-decoration: underline;

}

section.entiredashboard.maincustomerdash.expert-dashboard .paymenthistory ul.paymentstructure li {
	border-radius: 6px;
	background: #D1F0FF;
	color: #303030;

	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .replacecard h2 {
	color: #000;

	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .replacecard h4 {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .detailscardenter {
	background: #FFF;
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
	width: 100%;
}

section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscription_details .form-group .form-control {
	border: 1px solid #c3c6c9;
	border-radius: 4px;
	height: 40px;
	font-size: 14px;
	font-family: "Poppins", sans-serif !important;
	padding: 5px;


}

section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscription_details .input-container .placeholder {
	color: #000;
	
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .psirelative .previewcardinner {
	position: relative;
	top: -90px
}

section.entiredashboard.maincustomerdash.expert-dashboard .previewcardinner .namecardholder,
.previewcardinner .cardnumber,
.previewcardinner .carddate {
	color: #fff;
}

section.entiredashboard.maincustomerdash.expert-dashboard .leftpad {
	padding-left: 50px;
	margin-bottom: 40px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .showallourbesttoo {
	width: auto !important;
	margin: auto;
	justify-content: center;
	text-align: center;
	display: flex !important;
}
section.entiredashboard.maincustomerdash.expert-dashboard .craete-ad-containt .showallourbesttoo
{
	width: 150px !important;
}
section.entiredashboard.maincustomerdash.expert-dashboard .qmodal > div{
	width: 75%;
	height: 750px;
}
section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin .showallourbesttoo{width: 150px !important; justify-content: center;}
section.entiredashboard.maincustomerdash.expert-dashboard .leftpad h5 {
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .forgetpassinner h2.h2_style {
	color: #000;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
	/* 125% */
}

section.entiredashboard.maincustomerdash.expert-dashboard .forgetpassinner h5 {
	color: #000;

	font-size: 20px;
	font-style: italic;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .forgetpassinner h6 {
	color: #000;
	
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash.expert-dashboard .forgetpassinner .ft-inner.otpauthentication input {
	height: 40px;
	border-radius: 5px;
	border: 1px solid #2280B8;
	padding: 5px;
}

section.entiredashboard.maincustomerdash.expert-dashboard .forgetpassinner .input-icon-button {}

section.entiredashboard.maincustomerdash.expert-dashboard .col-8.brows-part {
	padding-right: 0%;
}

section.entiredashboard.maincustomerdash.expert-dashboard .upload-image {
	align-items: flex-end;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .divider::before {
	left: 0;
	background: #000;
}

section.entiredashboard.maincustomerdash.expert-dashboard .divider::after {
	right: 0;
	background: #000;
    margin-right: 22%;
}

section.entiredashboard.maincustomerdash.expert-dashboard .uplod-title {
	color: #000;
}

section.entiredashboard.maincustomerdash.expert-dashboard .uploadlogo {
	margin: auto;
}

section.entiredashboard.maincustomerdash.expert-dashboard .border-div {
	
}

section.entiredashboard.maincustomerdash.expert-dashboard .uploadexpert.mb-4 {
	margin: 0;
}

section.entiredashboard.maincustomerdash.expert-dashboard .ads-title {
	color: #2A2A2A;
	padding: 0;
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: 30px;
	/* 107.143% */
}

section.entiredashboard.maincustomerdash.expert-dashboard .uploadexpert.mb-4 {
	background: #DAF3FF;
}

section.entiredashboard.maincustomerdash.expert-dashboard .upload-image img {
	filter: invert(1);
}

section.entiredashboard.maincustomerdash.expert-dashboard .input-form-div {

	border-radius: 10px;
	background: #FFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}

section.entiredashboard.maincustomerdash.expert-dashboard .paymenthistory ul.paymentstructure li span {
	text-align: left;
}
section.entiredashboard.maincustomerdash.expert-dashboard .textarea-caption textarea
{
    border: 1px solid #2280B8;
	padding: 5px;
    height: 118px;
    border-radius: 3px;
    color: #303030;
    font-family: 'microsoft_sans_serifregular' !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
section.entiredashboard.maincustomerdash.expert-dashboard .divider-text
{
    color: #000;
}
section.entiredashboard.maincustomerdash.expert-dashboard .textarea-caption
{   color: #303030;
    height: auto;
    padding: 0;
    font-family: 'microsoft_sans_serifregular' !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
section.entiredashboard.maincustomerdash.expert-dashboard .adsimg{display: none;}
section.entiredashboard.maincustomerdash.expert-dashboard .radioform{
    justify-content: left;
    margin-bottom: 0;
}
section.entiredashboard.maincustomerdash.expert-dashboard input.input-caption, TagsInput.input-caption{
    padding: 0;
    color: #303030;
    font-family: 'microsoft_sans_serifregular' !important;;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
section.entiredashboard.maincustomerdash.expert-dashboard .form-group .form-control.form-select{
    border-radius: 3px;
    border: 1px solid #2280B8; padding: 5px;
    height: 50px;
    
}
section.entiredashboard.maincustomerdash.expert-dashboard .radioform label.radiocontrol
{
   padding-left: 0;
   padding-right: 10px;
}
section.entiredashboard.maincustomerdash.expert-dashboard .placeholder{ background: transparent; opacity: 1;}
.input-caption .input-caption{
    

width: 100%;
}

::placeholder
{  color: #303030;
    font-family: 'microsoft_sans_serifregular' !important;;
font-size: 12px !important;
font-style: normal;
font-weight: 400;
line-height: normal;}
section.entiredashboard.maincustomerdash.expert-dashboard .input-caption-border{
    border: 1px solid #2280B8;border-radius: 3px;
}
section.entiredashboard.maincustomerdash.expert-dashboard  span.input-icon-add {
    position: absolute;
    right: 5px;
    top: 44px;
    width: 29px;
    height: 29px;
}
section.entiredashboard.maincustomerdash.expert-dashboard label.placeholder{color: #6D6D6D;

    
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
margin-top: 11px;}
.myexpertnav > ul > li.active{z-index: 1;}
section.entiredashboard.maincustomerdash.expert-dashboard .col-lg-6 .input-caption-border{height: 50px;}
section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionright .subscriptiondetailsleft {
    position: relative;
}

section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionright .subscriptiondetailsleft:before {
    width: 3px;
    height: 116%;
    content: "";
    position: absolute;
    right: -29px;
    background: #ccc;
    top: 0px;
}

#react-select-3-listbox, #react-select-3-input {
  z-index: 999 !important;
  font-size: 11px;
}

section.entiredashboard.maincustomerdash.expert-dashboard  #react-select-8-listbox{
	z-index: 999;
	font-size: 13px;
}
section.entiredashboard.maincustomerdash.expert-dashboard .selectbox > div{
	z-index: 999;
	font-size: 13px;
	
}
section.entiredashboard.maincustomerdash.expert-dashboard .myaccountsection.institutedetails:nth-child(1){
	position: relative;
	z-index: 9;
}
section.entiredashboard.maincustomerdash.expert-dashboard .myaccountsection.institutedetails:nth-child(2){
	position: relative;
	z-index: 8;
}
section.entiredashboard.maincustomerdash.expert-dashboard .myaccountsection.institutedetails:nth-child(3){
	position: relative;
	z-index: 7;
}
section.entiredashboard.maincustomerdash.expert-dashboard .myaccountsection.institutedetails:nth-child(4){
	position: relative;
	z-index: 6;
}
section.entiredashboard.maincustomerdash.expert-dashboard  .cardsubscriptionleft .previewcardinner{
	background-image: url(../../../assets/Instituteppvimages/card.svg);
}
section.entiredashboard.maincustomerdash.expert-dashboard  .cardsubscriptionleft .previewcardinner.previewcardback
{
	background-image: url(../../../assets/images/card_back.svg);
	background-size: contain;
	background-repeat: no-repeat;
	padding: 100px 0;
}
section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionright .input.form-control
{
	border-radius: 3px;
border: 1px solid #2280B8;
}
section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionright .currentplandetails h4{color: #000;
	
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;}
	section.entiredashboard.maincustomerdash.expert-dashboard .cardsubscriptionright .currentplandetails h4 span{
		color: #FF4C00;

font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
	}
 p.namecardholder.text-white{
	margin-bottom: 0;
}
section.entiredashboard.maincustomerdash.expert-dashboard  .selectbox.css-b62m3t-container .css-t3ipsp-control{height: auto;}

section.entiredashboard.maincustomerdash.expert-dashboard  .selectbox>div, section.entiredashboard.maincustomerdash.expert-dashboard .selectbox {
    height: 42px;
    border-radius: 3px;
    border: 1px solid #2280B8;
    color: #303030;
}
section.entiredashboard.maincustomerdash.expert-dashboard .selectbox >div {background: #D1F0FF;}
section.entiredashboard.maincustomerdash.expert-dashboard .selectbox>div{border: none;  height: auto;}
select.form-select, .input-caption-border {
    background-color: #DAF3FF;
}
section.entiredashboard.maincustomerdash.expert-dashboard .previewcardinner{
	background:url(../../../assets/Instituteppvimages/card.svg);}
	section.entiredashboard.maincustomerdash.expert-dashboard  .backlightblue
	{ background-color: #D1F0FF;}
	section.entiredashboard.maincustomerdash.expert-dashboard  .userguide{display: none;}
	section.entiredashboard.maincustomerdash .dashboardrightpanel .myplandashboard .planesinner ul li{
		color: #414446;
		
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard .dashboardrightpanel .planesall .planesinnerright ul li
	{
		color: #000;
text-align: center;

font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
	}
	section.entiredashboard.maincustomerdash{
		margin-bottom: 40px;
	}
@media(max-width:767px){
	section.entiredashboard.maincustomerdash.customer-dashboard .col-lg-4.col-sm-4:before{display: none;}
	section.entiredashboard.maincustomerdash.expert-dashboard .col-lg-4.col-sm-4:before{display: none;}
	section.entiredashboard.maincustomerdash .dashboardleftpanel{margin: 0;}
	section.entiredashboard.maincustomerdash .p-0{
		padding: 0 15px !important;
	}
	section.entiredashboard.maincustomerdash .dashboardlink ul.dashboardinner li p, section.entiredashboard.maincustomerdash.customer-dashboard .dashboardlink.dashboardtext .activetext{display: none !important;}
	.customer-dashboard .dashboardlink.dashboardtext .activetext:before, .expert-dashboard .dashboardlink.dashboardtext .activetext:before{display: none;}
	section.entiredashboard.maincustomerdash .dashboardlink ul{display: flex; justify-content: center;}
	.customer-dashboard .dashboardlink ul li {
		
		padding: 5px 0 0 0px;
	}
	.dashboardleftpanel{max-width: 100%;}
	section.entiredashboard.maincustomerdash .dashboardlink {
        position: relative;
        top: 0px;
        background: #dff6ff;
        box-shadow: 5px 5px 25px rgb(6 40 61 / 20%);
        z-index: 999;
        left: 0;
        right: 0;
        padding: 10px;
        width: 100%;
    }
	.expert-dashboard .dashboardrightpannerinnerleft h5, .expert-dashboard .questioninner h5.h5_style{
		color: #000;

font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
	}
	.expert-dashboard .questioninner h2{
		color: #000;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
	}
	.dashboardrightpanelinner .questioninner .qnabutton{
		color: #FFF;
text-align: center;
font-family: "Roboto Flex";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.2px;
width: 90px;
height: 25px;
position: absolute;
        bottom: 8px;
        right: 7px;
		z-index: 99;
	}
	.dashboardrightpanelinner .questioninnerin .mb-4.mt-3.input-container.form-group
	{
		margin-top: 0 !important;
	}
	.questionstext span {
		font-size: 14px;        font-family: 'microsoft_sans_serifregular' !important;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerinbottom
	{
		flex-wrap: wrap;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard .expertquestiondetails h5.h5_style.questionstext
	{
		margin-bottom: 25px;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard .expertquestiondetails h5.h5_style
	{
		color: #000;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom: 10px;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin .showallourbesttoo, section.entiredashboard.maincustomerdash.expert-dashboard .showallourbesttoo {
		width: 90px !important;
		justify-content: center;
		height: 25px;
		border-radius: 2px;
		margin: 0;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard .questioninnerin .qbtn {
		display: flex	;
		justify-content: center;
		width: 100%;
	}
	.craete-ad-containt{
		padding: 5px;
		padding-left: 5px;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard label.placeholder{display: block;}
	section.entiredashboard.maincustomerdash.expert-dashboard label.placeholder{
		color: #6D6D6D;
		font-family: "Montserrat", sans-serif !important;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: normal;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard .textarea-caption label.placeholder.mt-0{
		margin-top: 11px !important;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard .ads-title {
		color: #2A2A2A;
		padding: 0;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 1;
		margin-bottom: 0;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard .input-form-div .samll {
		font-size: 10px;
		margin: 5px 0;
	}
	section.entiredashboard.maincustomerdash.expert-dashboard .DatePicker, section.entiredashboard.maincustomerdash.expert-dashboard .col-lg-6 .input-caption-border{width: 100%;}
	section.entiredashboard.maincustomerdash.expert-dashboard .craete-ad-containt p.subtitle{font-size: 14px;}
	section.entiredashboard.maincustomerdash.expert-dashboard .expertquestiondetailscategory {
        width: auto;
        position: absolute;
        right: 0;
        top: 0;
    }
	section.entiredashboard.maincustomerdash.expert-dashboard .expertquestiondetails{width: 100%;}
}