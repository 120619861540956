.imageorlogo {
    background: url(../../../assets/Instituteppvimages/bannerbg.svg);
    width: 1366px;
    margin: 0 auto;
    padding: 10px 0;
    margin-bottom: 10px; 
	background-size: cover;
    background-repeat: no-repeat;
}
 
.title {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: #FFFFFF;
    height: 100%;
    align-self: center;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.url {
    font-size: 20px;
    font-style: italic;
    font-weight: 600;
    color: #FFFFFF;
    height: 100%;
    align-self: center;
    text-align: center;
    display: flex
;
    align-items: center;
    align-self: center;
    margin: 0 auto;
}

.iconsection {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 20px;
}

.social-icon {
    width: 20px;
    height: 20px; 
    filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);  
    padding: 0px 4px 4px 4px;
}
 

.company-icon {
    margin: 0 40px;
    box-shadow: 0px 1px 1px 0px #00000040;
    box-shadow: 0px 2px 2px 0px #00000040 inset;
    width: 55px;
    height: 55px;
    border-radius: 90px;
    border:2px solid #fff;
}

.icondiv{
    width: 20px;
    height: 20px;
    background:white;
    border-radius: 100px;   
    margin: 0px 5px;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .imageorlogo{width: 100%;}
}
@media (max-width:767px){
    .iconsection{
        justify-content: start;
    }
    .company-icon{
        width: 80px;
        height: 80px;
        border-radius: 80px;
        margin: 0 15px 0px 0px;
    }
    .pstite{
        font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #FFFFFF;
    height: 100%;
 
    align-items: center;
    text-transform: capitalize;
    margin-bottom: 0;

    }
    .imageorlogo{width: 100%;}
    .psweb{display: block;
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;}
        .icondiv{position: relative;}
        .social-icon{position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            padding: 0;
            width: 13px;
            height: 13px;}
            .social-icon {
                filter: invert(1);
            }
}