.institutesearchmainlistingsectionps {
    border-radius: 20px;
    background: #fff;

    position: relative;
    padding: 20px;
    margin: 120px 0;
    margin-bottom: 0;
}

.instilogoouter {
    position: relative;
}

.instilogo {
    border-radius: 20px;
    background: #fff;
    box-shadow: 3px 3px 20px 0 rgba(0, 0, 0, .25);
    width: 140px;
    height: 140px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -104px;
    overflow: hidden;
    text-align: center;
    border-radius: 188px;
}

.instilogoimage img {
    border-radius: 200px;
    height: 140px;
    width: 140px;
    object-fit: cover;
}

.instisegricateone h4 {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    margin-bottom: 30px;
    text-transform: capitalize;
}

.instisegricateone h4:before {
    content: "";
    position: absolute;
    background: #cacaca;
    left: 0;
    bottom: -22px;
    width: 200px;
    height: 3px;
}

a.bdetail {
    border-radius: 8px;
    width: 160px;
    height: 36px;
    display: inline-block;
    color: #000;
    font-family: "Roboto Flex", sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.9;
    text-align: center;
    margin-right: 30px;
    margin-top: 22px;
}

.Banking .instisegricateone .condetails ul span img {
    filter: invert(39%) sepia(78%) saturate(554%) hue-rotate(159deg) brightness(95%) contrast(87%);
}

.Real.Estate .instisegricateone .condetails ul span img {
    filter: invert(26%) sepia(97%) saturate(1287%) hue-rotate(112deg) brightness(97%) contrast(101%);
}

.Investment .instisegricateone .condetails ul span img {
    filter: invert(25%) sepia(18%) saturate(3678%) hue-rotate(269deg) brightness(97%) contrast(87%);
}

.Insurance .instisegricateone .condetails ul span img {
    filter: invert(36%) sepia(92%) saturate(3306%) hue-rotate(1deg) brightness(103%) contrast(105%);
}

.Mortgage .instisegricateone .condetails ul span img {
    filter: invert(31%) sepia(57%) saturate(451%) hue-rotate(156deg) brightness(83%) contrast(86%);
}

.Banking a.bdetail {
    border-color: #2280B8;
}

.Real.Estate a.bdetail {
    border-color: #00801D;
}

.Investment a.bdetail {
    border-color: #86357A;
}

.Mortgage a.bdetail {
    border-color: #2F596F;
}

.Insurance a.bdetail {
    border-color: #FF4C00;
}

.instisegricateouter {
    justify-content: space-between;
    display: flex;
}

.instisegricateone .condetails ul {
    list-style: none;
    display: flex;
    padding: 12px 0 0;
    margin-bottom: 0;
}

.instisegricateone .condetails ul li {
    padding-right: 70px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.instisegricateone .condetails ul li span {
    padding-right: 10px;
}

.plainde {
    border-radius: 63.629px;
    background: #fff;
    color: #000;
    z-index: 99;
    font-size: 15.271px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 99.152px;
    height: 30.462px;
    position: absolute;
    text-align: center;
    left: 0;
    right: -217px;
    margin: auto;
    line-height: 1.8;
    bottom: 18px;
    font-family: "Roboto Flex", sans-serif !important;
}

.instisegricateone .condetails ul li a {
    color: #000;
}

.instisegricateone .condetails ul span img {
    width: 16px;
}

a.bdetail span img {
    width: 20px;
    padding-right: 5px;
}

.instisegricatetwo {
    display: block;
}

.instisegricatetwo ul li {
    list-style: disc;
}

.resumebutton {
    position: absolute;
    right: 17px;
    top: 17px;
    backdrop-filter: blur(4px);
    width: 149.8px;
    height: 40px;
    align-self: center;
    border: 1px solid #EF000B;
    background: #FFFFFF;
    font-size: 18px;
    font-weight: 500; 
    text-align: center;
    color: #EF000B; 
    border-radius: 5px;
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px){
    .instisegricateone .condetails ul li{
        padding-right: 11px;font-size: 13px;
    }
    .instisegricateone .condetails ul{flex-wrap: wrap;}
    .instisegricateone .condetails ul li span{
        padding-right: 5px;
    }
    .instisegricateone h4{        
        margin-top: 39px;
        font-size: 20px;
    margin-bottom: 0;}
    .instisegricateone h4::before{
        display: none;
    }
    a.bdetail{margin-top: 10px;}
}
@media (max-width:767px) {
    .instisegricateone .condetails ul li a{font-size: 12px;}
    .instisegricateone h4 {
        font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
        margin-bottom: 0px;
        margin-top: 30px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .instilogoimage, .instilogo, .instilogoimage img {
        height: 100px;
        width: 100px;
    }

    .instilogo {
        top: -87px;
    }
.instisegricateouter{
    flex-direction: column-reverse;
  
}
.instisegricateouter .instisegricatetwo{  margin-top: 10px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);}
   
    .Premium .Insurance a.bdetail{color: #FF4C00;}
    .Premium .Real.Estate a.bdetail{color: #00831d;}
    .Premium .Mortgage a.bdetail{color: #2F596F;}
    .Premium .Investment a.bdetail{color: #86357A;}
    .Premium .Banking a.bdetail{color: #2280B8;}
    .instisegricateouter, .instisegricateone .condetails ul {
        flex-wrap: wrap;
        padding-left: 10px;
    }
    .instisegricateouter{padding-left: 0;}
    .instisegricateone .condetails ul li span {
        padding-right: 3px;
    }
    .basicdetails{padding-left: 10px;}
    .instisegricateone .condetails ul li {
        padding-right: 0px;
        width: 100%;
        font-family:'microsoft_sans_serifregular' !important;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 10px;
    }
    .instisegricatetwo ul li:after{
        content: "";
    position: absolute;
    right: 0px;
    width: 2px;
    height: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #ccc;
    }
    .instisegricatetwo ul{        padding: 0;
      display: flex;}
        .instisegricatetwo ul li:last-child::after{display: none;}
        .instisegricatetwo ul li{list-style: none;font-weight: 600;
            font-family: "Fjalla One";
        font-size: 12px;
    padding: 5px 7px; position: relative;}
    a.bdetail {
        width: 95px;
        height: 30px;
        font-size: 12px;
        line-height: 2.5;
        text-align: center;
        margin-right: 10px;
        margin-top: 5px;
        border-radius: 2px;
        margin-bottom: 5px;
        font-family: "Roboto Flex", sans-serif !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    a.bdetail span img {
        width: 15px;
        padding-right: 5px;
    }

    .instisegricateone h4:before {
        bottom: -10px; display: none;
    }

    .instisegricatetwo ul {
        padding-left: 6px; margin: 0;
    }

    .plainde {
        right: -178px;
        bottom: -5px;
        z-index: 9;
    }
    
    .instisegricatetwo ul li{
        list-style: none;
        padding: 5px 10px;
    }
    .institutesearchmainlistingsectionps{
        border-radius: 20px;
    background: #fff;
    position: relative;
    padding: 10px 0;
    margin: 60px 0;
    }
    .instilogoimage , .instilogoimage img, .instilogo{
        height: 80px;
        width: 80px;
    
    }
  
    .instilogo{top:-50px;}
}