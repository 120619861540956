.right-institute .forgetpassinner h2 {
    font-size: 28px;
    color: #303030;
    font-weight: 500;
    text-align: start;
}
.right-institute .forgetpassinner h5 {
    font-size: 20px;
    color: #303030;
    font-weight: 500;
    text-align: start;
}
button.contiue-button {
    background-color: #2280B8;
    padding: 10px 90px;
    color: white;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.password-creation .passtext h4 {
    font-size: 20px;
    font-weight: 600;
    font-style: italic;
    color: #2A2A2A;
}

.main-myaccount-dashboard .accountchangepass section.loginscreeninstitute.registrationinstitute{
    background: none;
}
.account-settings{
    background: white;
    border-radius: 20px 20px 0 0 ;
    padding-bottom: 100px;
}
.main-myaccount-dashboard .accountchangepass .institutelogintext{
    padding: 30px;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
}
.main-myaccount-dashboard .form-group .form-control {
    border: none;
    border-radius: 6px;
    height: 50px;
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
    padding: 17px 13px ;

    font-weight: 500;

}
.main-myaccount-dashboard .input:not(:placeholder-shown) ~ .placeholder {
    color: #214860;
}
.main-myaccount-dashboard .input:focus ~ .placeholder {
    transform: none;
    font-size: 14px;
    font-weight: 500;

}
.main-myaccount-dashboard .input:not(:placeholder-shown) ~ .placeholder{
    transform: none;
    font-size: 14px;

}
.main-myaccount-dashboard .input-container .placeholder {
    color: #666666;
    left: 0px;
    
    cursor: initial;
    pointer-events: none;
    position: relative;
    
    transition:none;
    top: -74px;
    background: transparent;
    font-size: 14px;
    font-weight: 500;
    opacity: 1;
    z-index: 9;
}
.main-myaccount-dashboard .placeholder {
    display: inline-block;
    
    vertical-align: middle;
    cursor: wait;
    background-color: currentcolor;
    opacity: 0.5;
}
.main-myaccount-dashboard .otpauthentication span.text-danger{
    bottom: -22px;
}
.main-myaccount-dashboard  .input-icon-button {
    position: absolute;
    right: 30px;
    top: 18%;
    transform:none;
    cursor: pointer;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    z-index: 2;
    outline: none;
    padding: 0;
}
.password-creation .password-creation-inner {
    background-color: white;
    border-radius: 0 0 20px 20px;
}
.password-creation .passtext h5 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
    color: #2A2A2A;
}
.password-creation .passtext ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #2A2A2A;
    padding-left: 20px;
    position: relative;
}