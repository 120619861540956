
.sliderDes{
    text-align: center;
    padding: 10px 10px;
}
.slideImg{
    border-radius: 15px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}
.institutenewdesignppv section#ppvOfferSection  .slick-prev{left: -15px;}
.institutenewdesignppv section#ppvOfferSection  .slick-next{right: -15px;}
.offerslider .slick-prev:before, .institutenewdesignppv section#ppvOfferSection .slick-prev:before {
	content: "";
	background: url(../../../assets/homeimages/slicknext.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	rotate: 180deg;
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
}

.offerslider .slick-prev:hover:before, .institutenewdesignppv section#ppvOfferSection .slick-prev:hover:before {
	opacity: 1;

}

.offerslider .slick-next:before, .institutenewdesignppv section#ppvOfferSection .slick-next:before {
	content: "";
	background: url(../../../assets/homeimages/slicknext.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	rotate: 0deg;
	border-radius: 3px;
	display: block;
	position: absolute;
	left: 1px;
	top: 0;

}

.offerslider .slick-next:hover:before, .institutenewdesignppv section#ppvOfferSection .slick-next:before {

	opacity: 1;
}

.offerslider .slick-prev, .offerslider .slick-next, .institutenewdesignppv section#ppvOfferSection .slick-next, .institutenewdesignppv section#ppvOfferSection .slick-prev{
	width: 30px;
	height: 30px;
}



.applyNowbtn{

background-color: #D2FFD2;
border: 1px solid var(--green);
color: var(--green) !important;
padding: 8px 19px;
border-radius: 5px;
font-size: 20px;
font-weight: 700;
display: inline-block;
width: 150px;
height: 40px;
color: var(--green);
text-align: center;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 1.8px;
font-family: "Roboto Flex", sans-serif;
}


.applyNowbtn:hover {
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid var(--green);
	background: var(--green);
	color: #FFF !important;

}
.sliderContent{
    border-radius: 14.002px;
    background: #FFF;
    box-shadow: 2.1px 2.1px 23.104px 0px rgba(0, 0, 0, 0.25);
    margin: 20px;
    
}
@media (max-width:767px){
	.institutenewdesignppv section#ppvOfferSection .slick-next{right:0;}
	.institutenewdesignppv section#ppvOfferSection .slick-prev{left:0;}
}
