 .itemtts:first-child, .item2 {
    display: flex;
    justify-content: center;
}
 .itemtts:first-child {
    grid-row-start: 1;
    grid-row-end: 3;
    background-color: #daf0ff;
}
 .itemtts {
    align-self: stretch;
    align-items: center;
    display: flex;
    border-radius: 30px;
}
.itemtts:hover {
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, .41));
    cursor: pointer;
}
.itemtts:first-child a {
    display: block;
    width: 100%;
    text-align: center;
    margin: auto;
}
.itemtts a {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px 15px;
    align-items: center;
    cursor: pointer;
}
 .itemtts:first-child a .item-img {
    margin-bottom: 40px;
}
 .itemtts:first-child a .item-img {
    margin-left: auto;
    margin-right: auto;
}
.itemtts .item-img, .itemtts .item-img img {
    width: 100px;
    border-radius: 100px;
    height: 100px;
    object-fit: cover;
}
.itemtts:first-child a .item-text {
    text-align: center;
    margin: auto;
    width: 100%;
    padding: 0 20px;
}
.itemtts .item-text {
    width: 70%;
}
.item-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}
.item-text h5, .item-text p {
    color: #000;
    font-family: "microsoft_sans_serifregular" !important;
    font-size: 12px;
    text-align: left;
}
.item-text h5 {
    text-align: start;
    font-size: 16px;   
    line-height: 1;
    line-height: 18.11px;
    margin-bottom: 20px;
}
.item-text p {
    font-size: 12px;
    margin-bottom: 0;
    line-height: 13.5px;
    overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; 
}
.itemtts:nth-child(2), .item1 .itemtts:nth-child(7), .item2 {
    background-color: #eeecfb;
}
.itemtts:nth-child(3), .item1 .itemtts:nth-child(8) {
    background-color: #daf0ff;
}
 .itemtts:nth-child(4),  .itemtts:nth-child(9) {
    background-color: #ffe8a4;
}
 .itemtts:first-child, .item2 {
    display: flex;
    justify-content: center;
}
 .itemtts:first-child {
    grid-row-start: 1;
    grid-row-end: 3;
    background-color: #daf0ff;
}
 .itemtts:nth-child(5), .item1 .itemtts:nth-child(6) {
    background-color: #eeecfb;
}
 .itemtts:first-child a .item-img {
	margin-bottom: 40px;
}

.itemtts:first-child a .item-text h5 {
	margin-bottom: 20px;
}
@media (max-width:767px){
    .itemtts{margin-bottom: 10px;}
    .itemtts:first-child a .item-img {
        margin-bottom: 0px;
    }
    
    .itemtts:first-child a .item-text h5 {
        margin-bottom: 20px;
    }
    .itemtts:first-child a .item-img {
        margin-left: 0;
        margin-right: 0;
    }
    .itemtts:first-child a .item-text {
        text-align: left;
        margin: auto;
        width: 70%;
        padding: 0 0px;
    }
    .item-text h5,  .itemtts:first-child a .item-text h5{margin-bottom: 5px;}
    .itemtts:first-child a .item-text {
        display: flex
        ;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
    }
    .itemtts:first-child a .item-img, .itemtts a .item-img {
        width: 100px;
        border-radius: 100px;
        height: 100px;
        object-fit: cover;
    }
   .itemtts:first-child a {
    display: flex    ;
        flex-wrap: nowrap;
        padding: 15px 15px;
        align-items: center;
        cursor: pointer;
    }
    .blogtext{padding-right: 0;}
    .item-text h5{
        color: #000;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
    .item-text p{font-size: 12px;}
    .itemtts .item-img img
    {
        width: 100px;
    border-radius: 0;
    height: 100px;
    object-fit: cover;
    border-radius: 2px;
border: 0.498px solid #E3E3E3;
box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.25);
    }
    .entireexpertppv .slick-slide>div {
        padding: 0 0px;
    }
    .entireexpertppv #ppvAdsSection {
        margin: 20px 0;
    }
    section.blogexpertppvsection {
        width: 100%;
        margin: 20px auto;
    }
}