.main-interest .dashboardrightpannerinnerleft h2 {
    color: #000000;
    font-size: 30px;
    font-weight: 700;
    line-height: 36.57px;
    margin-bottom: 20px;
    font-family: "Montserrat" , sans-serif !important;
}
.main-interest .dashboardrightpannerinnerleft h5 {
    color: #000000;
    font-size: 16px;
    font-family: 'Poppins',sans-serif !important;
    font-weight: 500;
    line-height: 24px;
}
.main-interest .myexpertnav.myaccountnav ul li:first-child.active {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 144px;
    width: 144px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins',sans-serif;

}
.main-interest .myexpertnav.myaccountnav.qnanav ul li {
    width: 144px;
    height: 144px;
    background-color: #90E0EF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: 'Poppins',sans-serif;
    color: #004E77;
}
.main-interest .myexpertnav.myaccountnav > ul > li:last-child.active {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 144px;
    width: 144px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins',sans-serif;

}
.main-interest .myexpertnav.myaccountnav > ul > li:nth-child(2) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 144px;
    width: 144px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins',sans-serif;
}
.main-interest .myexpertnav.myaccountnav > ul > li:nth-child(2).active {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: 144px;
    width: 144px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins',sans-serif;
   
}
.main-interest  .myexpertnav.myaccountnav ul li:first-child.active img {
    filter: invert(108%) sepia(82%) saturate(830%) hue-rotate(210deg) brightness(132%) contrast(158%);
}
.main-interest .myexpertnav.myaccountnav > ul > li:last-child.active img {
    filter: invert(108%) sepia(82%) saturate(830%) hue-rotate(210deg) brightness(132%) contrast(158%);
}
.main-interest .myexpertnav.myaccountnav > ul > li:nth-child(2).active img {
    filter: invert(108%) sepia(82%) saturate(830%) hue-rotate(210deg) brightness(132%) contrast(158%);
}