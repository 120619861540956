.categorytwosetfirsttest .categoryonesetwidth{
    width: 111.93px;
    height: 113.93px;
}
 .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth h5{
    font-size: 13px;
}
.asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth .iconone {
    background: #fff;
    height: 42.95px;
    width: 42.95px;
    position: relative;
    margin: auto;
    border-radius: 50px;
    padding: 10px;
    margin-bottom: 10px;
}
.catstart.clearfix.blogsasktheexpert {
    background-color: #FFFFFF;
    border-radius: 20px 20px 0 0;
    padding: 50px 0 17px 0;
    margin: 0 30px;
}
.asktheexpertcategorysectioninner.asktheexpertcategorysectioninnerline.asktheexpertcategorysectioninnerlinenew .categorytwosetfirsttest .categoryonesetwidth .icontwo{
    background: #fff;
    height: 42.95px;
    width: 42.95px;
    position: relative;
    margin: auto;
    border-radius: 50px;
    padding: 10px;
    margin-bottom: 10px;
}
.maincustomerdash div#askQus{
    margin: 0 30px;
}

.asktheexpertcategorysectioninnerlinenew .textarecs {
    background: transparent;
    box-shadow: none;
    border-radius: 10px;
    padding: 0;
    border-top: none;
    margin: 0 30px;
}
.maincustomerdash .register-main {
    background: #fff;
    padding: 20px 24px;
    margin: 0px 30px 30px 30px;
    border-radius: 0 0 20px 20px;
}
.register-main-text h2 {
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    color: #036518;
    line-height: 36.57px;
}
.questioncustomer p.question-right-text-too {
    max-width: 100%;
    margin: 0;
    text-align: center;
}
.register-main-text span {
    font-size: 29px;
    font-weight: 800;
    text-align: left;
    color: #000000;
}.maincustomerdash .textarecs .input-question textarea.input.form-control.textareaform {
    padding: 72px 50px;
    height: 200px;
    font-size: 13px;
    font-weight: 400;
    line-height: 14.71px;
    font-family: "Microsoft Sans Serif",sans-serif !important;
    
}
.textarecs .input-question textarea.input.form-control.textareaform {
    font-family: "Microsoft Sans Serif",sans-serif !important;
    border: none;
    box-shadow: 3px 3px 33px 0px #00000040;
    border-radius: 30px;
    padding: 22px 35px;
    resize: none;
    width: 92%;
    padding-top: 50px;
    display: flex;
    padding-left: 13px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.maincustomerdash .questioncust .form-group  .placeholder {
    color: #666666;
    left: 72px;
    line-height: 14.71px    ;
    cursor: initial;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    background: transparent;
    font-size: 13px;
    font-family: "Microsoft Sans Serif",sans-serif !important;
    font-weight: 400;
    opacity: 1;
    z-index: 9;
}
.register-main-text p {
    font-size: 16px;
    line-height: 19.5px;
    font-weight: 500;
    text-align: center;
    color: #000000;
    max-width: 678px;
    margin: 0 auto;
}
.register-main-text h3 {
    font-size: 16px;
    line-height: 19.5px;
    font-weight: 700;
    text-align: center;
    color: #000000;
}
.register-fill .form-group .form-control {
    border-radius: 11.98px;
    height: 65px;
    font-size: 16.77px;
    padding: 17px 0px 0 28px;
    font-weight: 600;
    line-height: 20.45px;
    box-shadow: 0px 3px 10px 0px rgba(61, 61, 61, 0.50) !important;
}
.input-container .placeholder {
    color: #666666;
    left: 29px;
    line-height: 14px;
    cursor: initial;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 25px;
    background: transparent;
    font-size: 16.77px;
    font-weight: 600;
    opacity: 1;
    z-index: 9;
}
.proceed-main-btn {
    text-align: center;
}
.register-fill  span.input-group-text img {
    margin: 15px;
    filter: brightness(0) saturate(100%) invert(26%) sepia(69%) saturate(1655%) hue-rotate(90deg) brightness(90%) contrast(104%);
    width: 24.34px;
}
.maincustosection .slick-list {
    width: 699px;
}
.maincustosection .questionslider-text p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.85px;
    margin-bottom: 0;
    font-family: "Microsoft Sans Serif",sans-serif  !important;

}
/* .categorytext h2 {
    color: #000;
    margin-bottom: 0;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 66.18px;
    font-family: "Red Hat Display",sans-serif !important;
} 
.categorytext span {
    color: #036518;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 66.18px;
    font-family: "Red Hat Display",sans-serif;

}*/
p.catetext1 {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.38px;
    margin-bottom: 0;
}
p.catetext2 {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    line-height: 19.5px;
}
.asktheexpertcategorysectioninnerlinenew .categoryonesetwidth h5 {
    font-size: 13px;
    font-weight: 600;
    font-family: "Montserrat" , sans-serif !important;

}
.question-text h2 {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36.57px;
    margin-bottom: 15px;

}
.question-text span {
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    color: #036518;
    line-height: 36.57px;

}
span.question_span {
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    color: #036518;
}
a.nextbutton {
    border: 1px solid #ef000b;
    text-decoration: none;
    padding: 10px 25px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.9px;
    letter-spacing: 0.1em;
    text-align: left;
    box-shadow: 2px 2px 10px 0px #ef000b;
    text-align: left;
    color: #ef000b;
    font-family: "Roboto Flex",sans-serif;
}
a.registernowbutton {
    background: #00831D;
    border: 2px solid transparent;
    width: 160px;
    height: 40px;
    color: white;
    text-decoration: none;
    border-radius: 6px;
    font-family: "Roboto Flex";
    font-size: 18px;
    letter-spacing: 1.8px;
    font-weight: 700;
    line-height: 21.09px;
    display: flex;
    padding: 9px;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
}
.maincustosection .slick-prev:before{background: url(../../../../assets/homeimages/slicknext.svg);content:"";	width: 40px;height: 40px;
	background-size: contain;
	background-repeat: no-repeat;    transform: rotate(-90deg);}
	.maincustosection .slick-prev {
		left: auto;
		right: 64px;
		transform: translate(0, -87px);
	}
	.maincustosection .slick-next {
		right: -21px;
	}
  .maincustosection .slick-prev:before, .maincustosection .slick-next:before{
	  color: #036518 !important;
	opacity: 1;}
    .maincustosection .slick-next:before {
        position: absolute !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        right: 64px !important;
        font-size: 40px !important;
    content:"";
        color: #036518 !important;
        opacity: 1;
        background: url(../../../../assets/homeimages/slicknext.svg);
        transform: rotate(90deg);;
        width: 40px;height: 40px;
        background-size: contain;
        background-repeat: no-repeat;
      }
     .customer-dashboard .categoryfourset{padding-top: 0;}
     .customer-dashboard .question-text h2{margin-bottom: 0;}
     .customer-dashboard.maincustomerdash div#askQus{padding: 0;}
     .customer-dashboard.maincustomerdash .nextbutton{
        background-color: white;
        border: 1px solid #EF000B;
        padding: 8px 19px;
        border-radius: 5px;
        display: inline-block !important;
        width: 150px !important;
        height: 40px;
        color: #EF000B;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.8px;
        font-family: "Roboto Flex", sans-serif;
        margin-left: auto;
        margin-right: auto;
     }
     .customer-dashboard.maincustomerdash .nextbutton:hover{
      background: #EF000B;
      color: #fff;   
     }
     .customer-dashboard.maincustomerdash .nextbutton[disabled]
     {
        background: #ccc;
        border: 1px solid #ccc;
     }
     .modalspecial.asktheexpertconfirmpopup.popupouter.customerpopupmnl {
        position: fixed;
        background-color: rgba(255, 255, 255, 0.6);
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        z-index: 9999;
        max-width: 1366px;
    }
    .modalspecial.asktheexpertconfirmpopup.popupouter.customerpopupmn {
        position: absolute;
        background-color: rgba(255, 255, 255, 0.6);
        top: 82px;
        right: 3%;
        bottom: 0;
        left: auto;
        z-index: 9999;
        max-width: 66.6%;
        height: 520px;
        width: 66.6%;
    }
    .modalspecial.asktheexpertconfirmpopup.popupouter.customerpopupmn > div {
   
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        width: 75%;
        position: absolute;
        
        left: 0;
        right: 0;
        margin: auto;
        transform: translate(-50%, -50%);
        transform: initial;
        top: 150px;
    }
    @media (max-width:767px){
        .maincustomerdash div#askQus{
            margin: 0;
        }
        .modalspecial.asktheexpertconfirmpopup.popupouter.customerpopupmn {
            position: absolute;
            background-color: rgba(255, 255, 255, 0.6);
            top: 82px;
            right: 0;
            bottom: 0;
            left: auto;
            z-index: 9999;
            max-width: 100%;
            height: auto;
            width: 100%;
        }
        .modalspecial.asktheexpertconfirmpopup.popupouter button.close_btn {
            width: 20px;
            height: 20px;
            border-radius: 52px;
            background:#00831D;
            line-height: initial;
            left: auto;
            right: 3px;
            top: 6px;
            position: absolute;
            text-align: center;
            cursor: pointer;
            border: none;
            line-height: 0.4;
            background-image: url(../../../../assets/images/cross.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
        .modalspecial.asktheexpertconfirmpopup.popupouter .cliparttext{position: initial ; z-index: 9;}
        .modalspecial.asktheexpertconfirmpopup.popupouter button.close_btn img{display: none;}
        .modalspecial.asktheexpertconfirmpopup.popupouter.customerpopupmn > div{width:85%;}
        .customer-dashboard .question-text h2
        {
            font-size: 17px;
        }
       .customer-dashboard .asktheexpertcategorysectioninnerlinenew .categoryonesetwidth h5
       {font-size:10px; line-height: 1 !important;}
       .maincustomerdash .textarecs .input-question textarea.input.form-control.textareaform {
        padding: 15px;
       }
       .maincustomerdash .questioncust .form-group .placeholder {
        color: #666666;
        left: 26px;
       }
       .asktheexpertcategorysectioninnerlinenew .textarecs
       {
           margin: 0;
       }
       .textarecs .input-question textarea.input.form-control.textareaform{
           width: 100%;
       }
       .customer-dashboard.maincustomerdash .nextbutton {
        background-color: white;
        border: 1px solid #EF000B;
        padding: 0px 0px;
        border-radius: 2px;
        display: flex !important    ;
        width: 95px !important;
        height: 30px;
        color: #EF000B;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.8px;
        font-family: "Roboto Flex", sans-serif;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
    }
    span.question_span{font-size: 17px;}
    .maincustosection .slick-list {
        width: auto;
    }
    .maincustosection .questionslider-text p {
        color: #000;
        font-size: 12px;
    }
    .maincustosection .slick-prev {
        transform: translate(0, 0);
        top: auto;
        bottom: -37px;
        left: 40%;
        width: 30px;
        height: 30px;
        right: auto;
    }
    .maincustosection .slick-next {
        transform: translate(0, 0);
        top: auto;
        bottom: -37px;
        right: 40%;
        width: 30px;
        height: 30px;
        left: auto;
    }
    .maincustosection .slick-prev:before {
        content: "";
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(177deg);
    }
    .maincustosection .slick-next:before {
        content: "";
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        transform: rotate(0deg);
        right: auto !important;
        position: relative !important;
    }
    }