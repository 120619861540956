 

.main.entiredashboard.maincustomerdash .col-lg-3.col-sm-3:before { 
  background-color: #449FD6;  
}
 
.main.entiredashboard.maincustomerdash > .container > .row > .col-lg-3.col-sm-3 {
  background: #00801D;
}

.maintitle{
  margin: 25px 0 7px auto;
  font-weight: 700;
  font-size: 40px;
  align-self: start; 
  display: flex;
  align-items: start;
  padding-left: 00px;
}

.mainsubTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #06293f;
  margin: 3px 0 15px auto;
  padding-left: 0px;
}

.logout{
  font-weight: 700;
  font-size: 13px;
  color: #FFFFFF;
}

.dashboard-decoration{
  background: #E4EFFF;
  border-radius: 25px; 
  box-shadow: 0px 0px 15.7px 0px #00801D80; 
}

.dashboard-data{
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column; 
  margin-bottom: 70px;
}

.col-lg-9.col-sm-9.dashboard-data {
  padding: 0 35px;
  display: block;
}


.helpImg{
  padding-top:15%;
  padding-bottom: 7%; 
  width: 25%; 
  filter: brightness(100);
} 


.helpbutton{
  border-radius: 8px;
  background: #00801D; 
  width: 65px;
  height: 70px; 
  margin: 25px 0px 0 auto;
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left:40px;
}

.logoutbutton.loginrightmargin{
  margin-right:15px;
}

.your-card{ 
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;  
  color: #303030;
}

.your-card-subtitle{ 
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;  
  color: #303030;
}

.payment-card{
  background-image: url("../../../../assets/Instituteppvimages/card.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 380px;
  height: 248px;
  padding: 30px 50px;
  margin: 40px 30px;
}

.replace-button{ 
  border-radius: 6px; 
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center; 
  background: #00801D;
  color: #FFFFFF;
  width: 90%; 
  height: 50px; 
  align-self: center; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:5%;
}


.col-6.replace-button-section {
  display: flex;
  padding-top: 80px;
}

.name-card-holder {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #212121; 
}

.card-simbol {
  background: linear-gradient(126.34deg, #EEA158 25.7%, #E7D0BB 64.64%, #EEA158 82.63%);
  width: 50px;
  height: 40px; 
  border-radius: 6px;
}

.card-logo{
  width: 30px;
  height: 30px; 
  background: #D9D9D9;
}

.card-user-name{
  display: flex;
  color: #FFF;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px; 
  text-align: start;
  
}

.card-digit{ 
  font-size: 24px;
  font-weight: 400;  
  text-align: start; 
  color: #FFFFFF;
  padding-bottom: 10px; 

}


.digit-logo{
  display: flex;
  width: 33%;
  
}

.expiry{
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 10px;

}
.card-date{ 
font-size: 20px;
font-weight: 400;
line-height: 30px; 
color: #FFF; 
}

.card-date-title{ 
  font-size: 10px;
  font-weight: 400;    
  color:#FFF;
}

.exp-date-section{
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.namewithlogo{
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;

}

.card-details{
  background: #FFFFFF;
  border-radius: 20px; 
  padding: 30px 20px 30px 20px;
  margin: 0 auto;
}

.card-section{
  background: #E4EFFF;
  border-radius: 6px; 
  padding: 30px 20px;
  margin-bottom: 35px;
}
.title-section{
  padding-bottom: 30px;
}

.customer-since{
  color: #303030; 
  font-size: 18px;
  font-weight: 600; 
  display: flex;
  justify-content: center;
  align-self: center;
  height: 15px;
}

.plan-section{
  background: #FFFFFF;
  border-radius: 6px;
  padding-top: 35px; 
}

.plan-title{ 
  font-size: 16px;
  font-weight: 500;
  line-height: 15px; 
  color: #979797;
}
.plan-answer{ 
  font-size: 24px;
  font-weight: 600;
  line-height: 15px; 
  color: #00801D;
}

.plan-time{
  padding: 20px 10% 30px 10%;
}

.change-plan-button{
  background: #00801D;
  border-radius: 6px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center; 
  color: #FFFFFF;
  padding: 15px 80px;
  margin: 30px 0 15px 0; 
}

a.change-plan-button {

  width: 45%;
  
}
a.change-plan-button:hover {

  color: #FFFFFF;
  cursor: pointer;

}

.change-plan-section{
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-history{
  margin: 0px 25px;
}



ul.payment-structure li {
  display: flex;
  justify-content: space-between; 
  border-radius: 4px;
  margin-bottom: 15px;
  align-items: center;
   
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  color: #303030;   
}


.text-underline{ 
  text-decoration: underline;
  padding: 10px 45px 10px 25px;
}

ul.payment-structure {
  padding: 0;
  list-style: none;
}


.download-button{ 
font-size: 14px;
font-weight: 600; 
letter-spacing: 0.004em; 
background: #00801D; 
border-radius: 4px ;
width: 100px;
height: 50px; 
border: none;
color: white;
}

.payment-entry{
  background: #E4EFFF;
  border-radius: 6px;
  padding: 15px 25px;
}

.period{
  width: 30%;
}

span.amount{
  width: 15%;
}

span.status{
  width: 17%;
}
section.entiredashboard.maincustomerdash .cardsubscriptionleft h4,
section.entiredashboard.maincustomerdash .cardsubscriptionright h4 {
	color: #303030;
	margin-bottom: 0;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.cardsubscriptionright .grid-3{
  grid-gap:10px;
}
section.entiredashboard.maincustomerdash .cardsubscriptionleft p,
section.entiredashboard.maincustomerdash .cardsubscriptionright p {
	color: #303030;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .subscribtionsummeryheading h4 {
	color: #FFF;

	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
section.entiredashboard.maincustomerdash .replacelogo2.ctaonebutton, section.entiredashboard.maincustomerdash .subscribtionsummeryheading h4 {
  background: #86357A;
}
section.entiredashboard.maincustomerdash .currentplan.text-center h4 {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 30px;
}

section.entiredashboard.maincustomerdash .currentplan.text-center h4 span {
	color: #86357A;

	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding-left: 10px;
}

section.entiredashboard.maincustomerdash .substartdates,
section.entiredashboard.maincustomerdash .substartdates {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .cardsubscriptionright .subscriptiondetailsleft h4,
section.entiredashboard.maincustomerdash .cardsubscriptionright .subscriptiondetailsright h4 {
	color: #86357A;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .subscribtionsummery {
	border-radius: 5px;
	background: #D1F0FF;
	box-shadow: 5px 5px 20px 0px rgba(6, 40, 61, 0.20);
	margin-bottom: 34px;
}

section.entiredashboard.maincustomerdash .subscriptiondetails {
	padding-bottom: 40px;
}

section.entiredashboard.maincustomerdash .changeplanbtn {
	margin: auto;
	width: 220px;
	height: 50px;
	backdrop-filter: blur(2px);
	color: #FFF;
	text-align: center;
	font-family: "Roboto Flex";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	background: #86357A;
}

section.entiredashboard.maincustomerdash  .backor {
	background: #86357A !important;
}
section.entiredashboard.maincustomerdash .previewcardinner{
	background:url(../../../../assets/Instituteppvimages/card.svg);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 200px;}
section.entiredashboard.maincustomerdash .cardsubscriptionleft .previewcardinner {
	padding: 40px 20px
}
.sectioncardadd.mt-5 {
  margin-top: 83px !important;
}
section.entiredashboard.maincustomerdash .paymenthistory h3 {
	color: #000;

	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .paymenthistory p {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .paymenthistory ul.paymentstructure li:nth-child(1) {
	background: transparent;
	color: #303030;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-decoration: underline;

}

section.entiredashboard.maincustomerdash .paymenthistory ul.paymentstructure li {
	border-radius: 6px;
	background: #D1F0FF;
	color: #303030;

	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .replacecard h2 {
	color: #000;

	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .replacecard h4 {
	color: #000;

	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .detailscardenter {
	background: #FFF;
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
	width: 100%;
}

section.entiredashboard.maincustomerdash .cardsubscription_details .form-group .form-control {
	border: 1px solid #c3c6c9;
	border-radius: 4px;
	height: 40px;
	font-size: 14px;
	font-family: "Poppins", sans-serif !important;
	padding: 5px;


}

section.entiredashboard.maincustomerdash .cardsubscription_details .input-container .placeholder {
	color: #000;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

section.entiredashboard.maincustomerdash .psirelative .previewcardinner {
	position: relative;
	top: -70px
}
.paymentchange.psirelative{min-height: 280px;}
section.entiredashboard.maincustomerdash .previewcardinner .namecardholder,
.previewcardinner .cardnumber,
.previewcardinner .carddate {
	color: #fff;
}
 .subscribtionsummery
 {
   min-height: 197px;
 }

section.entiredashboard.maincustomerdash .leftpad {
	padding-left: 50px;
	margin-bottom: 40px;
}

section.entiredashboard.maincustomerdash .showallourbesttoo {
	width: auto !important;
	margin: auto;
	text-align: center;
	display: flex !important;
}
section.entiredashboard.maincustomerdash  .cardsubscriptionleft .previewcardinner{
	background-image: url(../../../../assets/Instituteppvimages/card.svg);
}
section.entiredashboard.maincustomerdash  .cardsubscriptionleft .previewcardinner.previewcardback
{
	background-image: url(../../../../assets/images/card_back.svg);
	background-size: contain;
	background-repeat: no-repeat;
	padding: 100px 0;
}
section.entiredashboard.maincustomerdash .cardsubscriptionright .input.form-control
{
	border-radius: 3px;
border: 1px solid #00801D;
}
section.entiredashboard.maincustomerdash .cardsubscriptionright .currentplandetails h4{color: #000;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;}
	section.entiredashboard.maincustomerdash .cardsubscriptionright .currentplandetails h4 span{
		color: #86357A;
font-family: Montserrat;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
	}
  .dashboardrightpanel .sectioncardadd .Paymentsummeryinner {
    border-radius: 6px;
    background: #D2FFD2;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}
.dashboardrightpanel  .sectioncardadd {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}
.dashboardrightpanel .paymentsummery {
    margin: 0;
    border: navajowhite;
    background: transparent;
}

.dashboardrightpanel .Paymentsummeryinner {
    width: 100%;
}

.dashboardrightpanel .paymentsummery li {
    color: #858585;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.dashboardrightpanel .paymentsummery li span {
    color: #303030;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
button.paybtn{
  border-radius: 4px;
  background: #FF4C00;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 214.286% */
  letter-spacing: 0.056px;
  border: 1px solid #FF4C00;
  }
  section.entiredashboard.maincustomerdash.main.institute_dashboard button.paybtn
  {
    background: #86357A;
    border: 1px solid #86357A;;
  }
  button.paybtn span{margin-left:5px}
  .previewcardback .cardnumber{
      justify-content: center;
      text-decoration: underline;
      font-size: 30px;
      display: flex;
      letter-spacing: 10px;
  }
  .sectioncardadd button.paybtn {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.sectioncardadd.mt-5 {
  border-radius: 6px;
background: #D1F0FF;
}
section.entiredashboard .dashboardrightpanel .planesall.dashbook
{
  border-radius: 10px;
background: #FFF;
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}
