.dashboardrightpannerinnerleft h2{
    font-size: 30px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 36.57px;
    font-family: "Montserrat", sans-serif  !important;
}
.dashboardrightpannerinnerleft h5{
    font-size: 20px;
    color: #06293F;
    font-weight: 500;

}
.myexpertnav.myaccountnav ul li:nth-child(n + 1) {
    font-size: 20px;
    font-weight: 600;
}
.myexpertnav.myaccountnav.martop40.customermain li {
    width: 144px;
    height: 144px;
    background-color: #FFE3D0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-right: 20px;
    font-family: "Poppins" !important;
    line-height: 24.16px;
}
.myexpertnav.myaccountnav.martop40.customermain li.active {
    width: 144px;
    height: 144px;
    background-color: #90E0EF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-family: "Poppins" ,sans-serif !important;
    line-height: 24px;
    font-weight: 600;
    color: #ffff;
}
.myexpertnav.myaccountnav.martop40.customermain li.active img {
    filter:  invert(108%) sepia(82%) saturate(830%) hue-rotate(210deg) brightness(132%) contrast(158%);
}
.myexpertnav.myaccountnav.martop40.customermain li img {
    margin-bottom: 10px;
}
.main-myaccount-dashboard .myexpertnav.myaccountnav.martop40.customermain.account-setting-block{
    justify-content: start;
    
}
.main-myaccount-dashboard .right-institute {
    background-color: white;
    border-radius: 20px 20px 0 0;
}
section.main-myaccount-dashboard{
    margin-top: 0;
}
.my-account .uploaddetails p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Poppins" !important;
    color: #6D6D6D;
}
.my-account p.full_name {
    color: #303030;
    font-size: 30px;
    margin-bottom: 35px;
    line-height: 45px;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500;
}
.my-account label.change_button {
    background-color: #2280B8;
    padding: 16px;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;
    line-height: 15px;
}

.my-account .delete-button {
    padding: 11px 40px;
    border-radius: 6px;
    background-color: transparent;
    border: 1.5px solid #2280B8;
    color: #2280B8;
    font-size: 16px;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;
    line-height: 15px;
}
.personal-details .myaccountsectionheading h3 {
    font-size: 30px;
    color: #2A2A2A;
    font-weight: 500;
    line-height: 30px;
    font-family: "Poppins", sans-serif !important;

}
.occupation .myaccountsectionheading h3 {
    font-size: 30px;
    color: #2A2A2A;
    font-weight: 500;
    line-height: 30px;

    font-family: "Poppins", sans-serif !important;

}

.personal-details button.edit-button {
    background-color: #2F596F;
    border: 1px solid #2F596F;
    padding: 11px 45px;
    border: none;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    font-family: "Poppins", sans-serif !important;
}
.occupation button.edit-button {
    background-color: #2280B8;
    padding: 11px 45px;
    border: none;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    font-family: "Poppins", sans-serif !important;
    
}
.personal-details .form-fieldinner label {
    line-height: 21px;
    font-weight: 500;
    font-size: 14px;
    color: #6d6d6d;
    font-family: "Poppins", sans-serif !important;

}
.occupation .form-fieldinner label {
    line-height: 21px;
    font-weight: 500;
    font-size: 14px;
    color: #6d6d6d;
    font-family: "Poppins", sans-serif !important;
}
.personal-details .fieldans {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
    background-color: white;
    padding: 10px;
    border-radius: 3px;
    font-family: "Poppins", sans-serif !important;

}
.occupation .fieldans {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
    background-color: white;
    padding: 10px;
    border-radius: 3px;
    font-family: "Poppins", sans-serif !important;

}
.right-institute .forgetpassinner h2 {
    font-size: 30px;
    color: #303030;
    margin-bottom: 20px;
    line-height: 30px;
    font-weight: 500;
    text-align: start;
    font-family: "Poppins", sans-serif !important;
    
}
.right-institute .forgetpassinner h6 {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    color: #303030;
    font-weight: 500;
    text-align: start;
    margin-bottom: 60px;
}
.right-institute .forgetpassinner h5 {
    font-size: 16px;
    line-height: 24px;
    color: #303030;
    font-weight: 500;
    text-align: start;
    font-family: "Poppins", sans-serif !important;
}
.main-myaccount-dashboard .input:not(:placeholder-shown) ~ .placeholder {
    font-family: "Poppins", sans-serif !important;
    line-height: 21px;
    transform: none;
    font-size: 14px;
    font-weight: 500;
    
}
.password-creation .passtext h4 {
    font-family: "Poppins", sans-serif !important;
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    color: #2A2A2A;
}
.password-creation .passtext h5 {
    font-family: "Poppins", sans-serif !important;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    color: #2A2A2A;
}
.password-creation .passtext ul li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2A2A2A;
    padding-left: 20px;
    position: relative;
    font-family: "Poppins", sans-serif !important;
}
.otpauthentication.ft-inner button.ctaonebutton {
    display: flex;
    margin: 0 auto;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    background-color: #2280B8;
    padding: 10px 90px;
    
    font-family: "Poppins", sans-serif !important;

}
button.contiue-button {
    padding: 10px 90px;
    color: white;
    border-radius: 6px;

    font-size: 20px;
    font-weight: 500;
    font-family: "Poppins", sans-serif !important;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.otpauthentication button.contiue-button {
    border: none;
}