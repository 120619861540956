:root {
    --Customerdashboard-RealEstate: #B8FFB8;
    --Customerdashboard-Investment: #FFD0F8;
    --Customerdashboard-Insurance: #FFCE9D;
    --Customerdashboard-Mortgage: #D1D9E9;
    --Customerdashboard-Banking: #C3E3FF;
    --Customerdashboard-Button-RealEstate: #00831D;
    --Customerdashboard-Button-Investment:#86357A ;
    --Customerdashboard-Button-Insurance: #FF4C00;
    --Customerdashboard-Button-Mortgage: #2F596F;
    --Customerdashboard-Button-Banking: #2281B8;
    
}
.offerslider .ppvawesomesliderdescription {
    position: absolute;
    bottom: 71px;
    width: 100%;
    left: 0;
    right: 0;
    opacity: 1;
}
.ppvawesomeslider-button button {
    font-family: "Poppins", sans-serif ;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}
.ppvawesomesliderproducttype-button button {
    font-family: "Poppins", sans-serif ;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}
.ppvawesomeslider-button .RealEstate {
    background-color: var(--Customerdashboard-Button-RealEstate);
}
.ppvawesomeslider-button .Investment {
    background-color: var(--Customerdashboard-Button-Investment);
}
.ppvawesomeslider-button .Banking {
    background-color: var(--Customerdashboard-Button-Banking);
}
.ppvawesomeslider-button .Mortgage {
    background-color: var(--Customerdashboard-Button-Mortgage);
}
.ppvawesomeslider-button .Insurance {
    background-color: var(--Customerdashboard-Button-Insurance);
}
.RealEstate .ppvawesomesliderproducttype-inner {
    background-color: var(--Customerdashboard-RealEstate);
}
.Investment .ppvawesomesliderproducttype-inner{
    background-color: var(--Customerdashboard-Investment);
}
.Insurance .ppvawesomesliderproducttype-inner{
    background-color: var(--Customerdashboard-Insurance);
}
.Mortgage .ppvawesomesliderproducttype-inner{
    background-color: var(--Customerdashboard-Mortgage);
}
.Banking .ppvawesomesliderproducttype-inner{
    background-color: var(--Customerdashboard-Banking);
}
.ppvawesomesliderproducttype-inner {
    
    border-radius: 6px;
    padding: 15px;
    min-width: 800px;
}
.ppvawesomesliderproducttype-product {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ppvawesomesliderproducttyp-name h4{
    font-family: "Poppins", sans-serif !important ;
    font-size: 20px;
    font-weight:600;
    line-height: 30px;
    color: #303030;

}
.ppvawesomesliderproducttyp-name p{
    font-family: "Poppins", sans-serif !important ;
    font-size: 16px;
    color: #303030;
    font-weight: 400;
    line-height: 24px;
    
}
.ppvawesomesliderproducttype-product-text h4 {
    color: #303030;
    font-family: "Poppins", sans-serif  !important;
    font-size: 20px;
    font-weight:600;
}
.ppvawesomesliderproducttype-product-text p {
    font-size: 16px;
    font-family: "Poppins", sans-serif !important ;
    color: #303030;
    font-weight: 400;
}
.awssld__content img{
    height: auto;
    padding-bottom: 0;
}
.limitedofferinner .awssld__content img {
    height: 389.06px;
    padding: 15px 10px;
}
.widthhalf{
    width: auto;
}
.ppvawesomeslider-button {
    position: absolute;
    right: 0;
    margin: 26px 30px;
    z-index: 999;
}
.ppvawesomesliderdescription-main {
    margin-top: 8px;
}

.limitedofferinner {
    background: white;
    box-shadow:none;
    border-radius: 10px;
    padding: 0px;
    margin-bottom: 40px;
}
section.entiredashboard.maincustomerdash .dashboardrightpanel{
    box-shadow: none;
    border-radius: 0px 20px 0 0;
}
/* .limitedoffer .limitedofferinner .offerslider .slick-next{
    right: 0px;
    top: 32%;
}
.limitedoffer .limitedofferinner .offerslider .slick-prev{
    right: 0px;
    top: 32%;
}
.limitedoffer .limitedofferinner .offerslider .slick-prev:before, .limitedoffer .limitedofferinner .offerslider .slick-next:before{
    content: "";
        background: url(../../../../assets/homeimages/slicknext.svg);
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        rotate: 0deg;
        border-radius: 3px;

        position: absolute;
    top: 0;
    right: -16px;
}
.limitedoffer .limitedofferinner .offerslider .slick-prev:before, .limitedoffer .limitedofferinner .offerslider .slick-prev:before{
    content: "";
    background: url(../../../../assets/homeimages/slicknext.svg);
        width: 30px;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        rotate: 178deg;
        border-radius: 3px;
        left: 22px;
    position: absolute;
    top: 0;

} */
/* .dashboardrightpanel {
    padding: 0px 40px 40px  40px;
} */
section.limitedoffer {
    background-color: white;
}
.awssld__wrapper .awssld__controls button{
    top: 28%;
}
.opencustomer {
    background: #D1D9E9;
    border-radius: 0px 20px 0 0;

}
.opencustomer h5 {
    font-size: 16px;
    font-weight: 500px;
    font-family: "Poppins", sans-serif  !important;
    color: #06293F;
}
/* .dashboardrightpannerinnerleft h2 {
    font-size: 30px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 36.57px;
    font-family: "Poppins", sans-serif  !important;

} */
.ppvawesomesliderdescription-main.RealEstate button.knowmore_button {
    background-color: var(--Customerdashboard-Button-RealEstate);
}
.ppvawesomesliderdescription-main.Investment button.knowmore_button {
    background-color: var(--Customerdashboard-Button-Investment);
}
.ppvawesomesliderdescription-main.Banking button.knowmore_button {
    background-color: var(--Customerdashboard-Button-Banking);
}
.ppvawesomesliderdescription-main.Mortgage button.knowmore_button {
    background-color: var(--Customerdashboard-Button-Mortgage);
}
.ppvawesomesliderdescription-main.Insurance button.knowmore_button {
    background-color: var(--Customerdashboard-Button-Insurance);
}
.ppvawesomeslider-button.RealEstate button.knowmore_button {
    background-color: var(--Customerdashboard-Button-RealEstate);
}
.ppvawesomeslider-button.Investment button.knowmore_button {
    background-color: var(--Customerdashboard-Button-Investment);
}
.ppvawesomeslider-button.Banking button.knowmore_button {
    background-color: var(--Customerdashboard-Button-Banking);
}
.ppvawesomeslider-button.Mortgage button.knowmore_button {
    background-color: var(--Customerdashboard-Button-Mortgage);
}
.ppvawesomeslider-button.Insurance button.knowmore_button {
    background-color: var(--Customerdashboard-Button-Insurance);
}
