.topproduct .sldeimage.product_image, .exclusive  .sldeimage.offer_image  {
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: 100%;
	position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 0px 10px 10px 10px;
}

.topproduct {
	background-image: url("../../../assets/homeimages/topproductbg.png");
	background-repeat: no-repeat;
	width: 100%;
	background-size: cover;
	margin: 40px 0; padding-top: 28px;
	padding-bottom: 10px;
}

.topproduct-text h2 {
	font-size: 30px;
	font-weight: 700;
	color: #FFFFFF;
	text-align: start;
	margin-bottom: 0;
}

.topproduct .slick-prev {
	left: -30px;
}

.topproduct .slick-next {
	right: -30px;
}

.topproductcat .topproductcatbox:first-child img {
	filter: invert(0);
}

.topproductcat .topproductcatbox:first-child:hover img {
	filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
}

 .topproduct-text p {
	font-size: 16px;
	font-weight: 500;
	color: #FFFFFF;
	text-align: start;
	line-height: 19.5px;
	margin-bottom: 0.5rem !important;
}


.topproductcat {
	align-items: center;
	justify-content: center;
	display: flex;
	position: relative;
	margin-top: 40px;
}

.topproductcatbox p {
	color: #FFFFFF;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 0;
	font-size: 14px;
	padding: 0 5px;
	text-align: center;
	line-height: 17.07px;
}

.topproductcat .topproductcatbox img {
	width: auto;
	filter: invert(1);
}

.topproductcat .topproductcatbox.active {
	background-color: white;
	cursor: pointer;
}

.topproductcat .topproductcatbox.active img {
	filter: invert(29%) sepia(98%) saturate(1911%) hue-rotate(122deg) brightness(97%) contrast(101%);
}

.topproductcat .topproductcatbox.active:first-child img {
	filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
}

.topproductcat .topproductcatbox.active p {
	filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
}

.topproductcatbox.active:after {
	position: absolute;
	top: -9px;
	left: 50%;
	margin-left: -10px;
	content: "";
	display: block;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid white;
}

.topproductcat .topproductcatbox:hover img {
	filter: invert(29%) sepia(98%) saturate(1911%) hue-rotate(122deg) brightness(97%) contrast(101%);
}

.topproductcat .topproductcatbox:hover p {
	filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
}

.topproductcatbox:hover {
	background-color: white;
	cursor: pointer;

}

.topproductcatbox {
	width: 100px;
	height: 90px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	transition: 0.3s all ease-out;
}

.topproductcatbox:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	background: #BDBDBD;
	height: 100%;
	width: 1px;
	bottom: 0;
	margin: auto;
}

.topproductcatbox:hover:before {
	display: none;
}

.topproductcatbox:hover:after {
	position: absolute;
	top: -10px;
	left: 50%;
	margin-left: -10px;
	content: "";
	display: block;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid white;
}

.topproductcatbox:last-child:before {
	display: none;
}

.exclusive-slider {
	width:100%;
		margin: auto;
		margin-bottom: 0px;
	
	}

	

.exclusive-slider .slick-dots li.slick-active button {
	background: #000;
	width: 8px;
	height: 8px;
}

.exclusive-slider .slick-dots li {
	display: none;
}

 
.exclusive-slider .slick-dots li.slick-active,
.exclusive-slider .slick-dots li.slick-active~li:nth-child(-n+2),
 
.exclusive-slider .slick-dots li.slick-active~li:nth-last-child(-n+2)
{
	display: inline-block;
}
 
.exclusive-slider .slick-dots li button {
	background-color: gray;
	border: none;
	border-radius: 50%;
	width: 10px;
	height: 10px;
}

.exclusive-slider .slick-dots li {
	width: 10px;
	height: 10px;
}
 
.exclusive-slider .slick-dots li.slick-active button {
	background-color: black;
}

.prodectsslider .slick-dots li.slick-active button {
	background-color: white;
}


a.topproductshowallbtn {
	background-color: white;
	border: 1px solid #EF000B;
	color: #EF000B !important;
	padding: 8px 19px;
	border-radius: 5px;
	font-size: 20px;
	font-weight: 700; 
	display: inline-block;
	width: 150px;
	height: 40px;
	color: #EF000B;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 1.8px;
	font-family: "Roboto Flex", sans-serif;
}


 
a.topproductshowallbtn:hover{
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #EF000B;
	background: #EF000B;
	color: #FFF !important;
}

.slick-slide.slick-active.slick-current {
    margin: 0 40px 0 10px;
}

 .slick-prev::before{
	content: "";
	background: url(../../../assets/homeimages/slicknext2.svg);
	width: 30px;
	height: 30px;
	background-size: contain;
	background-repeat: no-repeat;
	transform: rotate(180deg);
	display: block;
	position: absolute;
	right: 0;
	left: 0; 
}

.productmain{
	padding: 0px 100px;
}

.posbuttontf {
    padding: 0;
    margin: 15px 0 0;
    justify-content: left;
}

.topproductheading-text h2 {
	font-size: 30px;
	font-weight: 700;
	color: #FFFFFF;
	text-align: center;  
}
@media (max-width:767px){
	.exclusive-slider {
		width:100%;
			margin: auto;
			margin-bottom: 0px;
		
		}
	.topproduct-text h2, .topproductheading-text h2{font-size: 18px}
	
	.topproduct-text p{font-size:12px;  font-family: "SF Pro Text" !important; line-height: 1.2;}
	a.topproductshowallbtn{
		padding:  0px;
        height: 25px;
        font-weight: 500;
        line-height: 1.9;
        letter-spacing: 1.2px;
        text-align: center;
        width: 90px !important;
        font-size: 12px;
		border-radius: 2px;
		border-width: 1px;
	}
	
}
 