.institutesearchmain{
    max-width: 1366px;
    margin: 0 auto;
}
.institutesearchmain .hero_subtext2 h2{text-align: right;}
.forminsti  button.contiue-button{
    
    padding: 8px 19px;
    border-radius: 5px;
    display: inline-block !important;
    width: 150px !important;
    height: 40px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.8px;
    font-family: "Roboto Flex", sans-serif !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
}
.institutesearchmain .hero_subtext h2{max-width: 100%; justify-content: right;}
.institutesearchmain  .hero_subtext3 h5{text-align: right;}
.instilogo {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.25);
    width: 155px;
    height: 155px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -104px;
    overflow: hidden;
    text-align: center;
}

.institutesearchmainlistingsectionps {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(255, 76, 0, 0.60);
    position: relative;
    padding: 32px;
    margin: 130px 0;
}
.institutesearchmain section.institutesearchmainherosection {
    text-align: center;
}
.instilogoouter {
    position: relative;
}
.plainde {
    border-radius: 63.629px;
    background: #FFF;
    color: #EF000B;
  z-index: 99;
    font-size: 15.271px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 99.152px;
    height: 30.462px;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 1.8;
    filter: drop-shadow(0px 0px 4px #EF007D);
    bottom: -65px;
}
.instilogoimage img {
    width: 155px;
}
.instisegricateouter.d-flex{
    justify-content:space-between
}
.instisegricatetwo ul li {
    color: #000;
  
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
section.institutesearchmainherosection > img {
    width: 100%;
}
.instisegricatetwo ul li.Banking{color: #2281B8;}
.instisegricatetwo ul li.Insurance{color: #FF4D00;}
.instisegricatetwo ul li.Investment{color: #86357A;}
.instisegricatetwo ul li.Real.Estate{color:#00831D ;}
.instisegricatetwo ul li.Mortgage{color: #2F596F;}
.instisegricateone h4 {
    color: #000;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: relative;
    margin-bottom: 30px;
}

.instisegricateone h4:before {
    content: "";
    position: absolute;
    background: #CACACA;
    left: 0;
    bottom: -22px;
    width: 200px;
    height: 3px;
}

.instisegricateone .condetails ul {
    list-style: none;
    display: flex;
    padding: 0;
    padding-top: 12px;
}

.instisegricateone .condetails ul span img {
    width: 16px;
}

.instisegricateone .condetails ul li {
    padding-right: 70px;
    color: #000;
     font-size:16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.instisegricateone .condetails ul li a{  color: #000;}

.instisegricateone .condetails ul li span {
    padding-right: 10px;
}

a.bdetail {
    border-radius: 8px;
    border: 1.5px solid #FF4C00;
    box-shadow: 0px 0px 6.4px 0px rgba(255, 76, 0, 0.50);
    width: 160px;
    height: 36px;
    display: inline-block;
    color: #000;
  
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.9;
    text-align: center;
    margin-right: 30px;
    margin-top: 22px;
}
.Banking .instisegricateone .condetails ul span img{filter: invert(39%) sepia(78%) saturate(554%) hue-rotate(159deg) brightness(95%) contrast(87%);}
.Real.Estate .instisegricateone .condetails ul span img{filter: invert(26%) sepia(97%) saturate(1287%) hue-rotate(112deg) brightness(97%) contrast(101%);}
.Investment .instisegricateone .condetails ul span img{filter: invert(25%) sepia(18%) saturate(3678%) hue-rotate(269deg) brightness(97%) contrast(87%);}
.Insurance .instisegricateone .condetails ul span img{filter: invert(36%) sepia(92%) saturate(3306%) hue-rotate(1deg) brightness(103%) contrast(105%);}
.Mortgage .instisegricateone .condetails ul span img{filter: invert(31%) sepia(57%) saturate(451%) hue-rotate(156deg) brightness(83%) contrast(86%);}
.Banking  a.bdetail{border-color: #2280B8;}
.Real.Estate a.bdetail{border-color: #00801D;}
.Investment a.bdetail{border-color: #86357A;}
.Mortgage a.bdetail{border-color: #2F596F;}
.Insurance a.bdetail{border-color: #FF4C00;}
.institutesearchmainlistingsectionps.Real.Estate{border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 159, 36, 0.60);}
.institutesearchmainlistingsectionps.Banking{border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px #2280B8;}
.institutesearchmainlistingsectionps.Mortgage{border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 2px 10px 0px rgba(47, 89, 111, 0.60);}
.institutesearchmainlistingsectionps.Investment{
    border-radius: 20px;
background: #FFF;
box-shadow: 0px 2px 10px 0px #86357A;
}
.institutesearchmainlistingsectionps.Insurance{
    border-radius: 20px;
background: #FFF;
box-shadow: 0px 2px 10px 0px rgba(255, 76, 0, 0.60);
}
.instisearchheading h2{
    color: #000;
text-align: center;

font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin: 0;
}
.instisearchheading h3{color: #000;
    text-align: center;
  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;}
    .herotext {
        width: 50%;
        text-align: right;
        position: absolute;
        right: 0px;
        bottom: 160px;
    }
    
    section.institutesearchmainherosection , section.institutesearchmainherosection .container {
        position: relative;
    }
    .institutesearchmainlistingsectionps.Elite {
        box-shadow: 0px 2px 13.1px 0px #00803E99;
    }
    .hero_subtext h2{
        color: #000;
        font-size: 45px;
        font-style: normal;
        font-weight: 700;
        line-height: 54.86px;
        max-width: 150px;
        display: flex;
        align-items: end;
    }
   
    .herotext h2 span {
        color: #01456D;
        font-weight: 800;
    }
    
    .forminsti .form-control {
        border-radius: 91.156px;
        background: #FFF;
        box-shadow: 3.039px 3.039px 12.7px 0px #006074;
        width: 537.82px;
        height: 70px;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 15px 28px;
    }
   .forminsti .input-container .placeholder{top:28px;}
    .input-container.ts {
    margin: 0 15px;
}
    .forminsti .showallourbesttwo {
        display: block;
        width: 100%;
    }
    .forminsti.d-flex {justify-content: center; flex-wrap: wrap; margin-top: 40px;}
    .ts {
        position: relative;
    }
    .instisearchheading{margin-top: 40px;}
    .ts span {
        position: absolute;
        right: 28px;
        top: 12px;
    }
    
    input.form-control.bc-name {
        margin-right: 0;
    }
    .plainde span{padding-right: 5px;}
    .hero_subtext2 h3{
        position: relative; 
        margin-bottom: 20px;}
    .hero_subtext2 h3:after {
        position: absolute;
        content: "";
        width: 559px;
        height: 7px;
        background: #01456D;
        bottom: -14px;
        right: 0;
    }
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px){
        .herotext {
            width: 50%;     
            bottom: 47px;
        }
        .hero_subtext h2, .hero_subtext strong {
            font-size: 30px;
            line-height: 35px;
            display: block; max-width: 100%;
        }
        .forminsti .form-control{width:318px;}
        .hero_subtext2 h3:after {
            position: absolute;
            content: "";
            width: 40%;
            height: 3px;
            background: #01456D;
            bottom: -11px;
            right: 0;
        }
    }
    @media (max-width:767px){
        .forminsti button.contiue-button {
            padding: 0;
            border-radius: 2px;
            display: inline-block !important;
            width: 90px !important;
            height: 25px;
            color: #fff;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1.2px;
            font-family: "Roboto Flex", sans-serif !important;
            margin-left: auto;
            margin-right: auto;
            margin-top: 10px;
        }
        div#searchinstitute{padding: 0;}
        .institutesearchmain .Premium .Premium {right: -167px;
            bottom: 6px;}
        .instisearchheading h3{font-family: "SF Pro Display";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;}
        .forminsti.d-flex {
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 20px;
        }
        .input-container.ts {
            margin: 0px 0px;
        }
        .input-container.ts {
            margin: 0px 0px;
            width: 100%;
        }
        .ts span {
            position: absolute;
            right: 20px;
            top: 11px;
            width: 30px;
        }
        .input-container.ts:nth-child(2)  span{
            width: 20px;
        }
        .instisegricateone .condetails ul li a{font-size: 12px;}
        .instisearchheading h2{
            font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 1.3;
        }
        .forminsti .input-container .placeholder {
            top: 19px;
            left: 17px;
            font-size: 12px;
        }
        .forminsti .form-control {
            border-radius: 91.156px;
            background: #FFF;
            box-shadow: 3.039px 3.039px 12.7px 0px #006074;
            width: 537.82px;
            height: 53px;
            color: #000;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 6px 64px 6px 18px;
        }
        .herotext {
            width: 80%;
            text-align: left;
            position: absolute;
            right: auto;
            bottom: auto;
            left: 15px;
            top: 50px;
        }
        .institutesearchmain .hero_subtext2 h2, .institutesearchmain .hero_subtext3 h5{text-align: left;}
        .institutesearchmain .hero_subtext h2{display: block; margin-bottom: 0; font-size: 30px !important;}
        .forminsti .form-control{
            width: 100%;
            margin-bottom: 20px;
        }
        .forminsti a.showallourbesttoo.mt-5.mb-4 {
            margin-top: 0 !important;
        }
        .selectallinnercheckbox>ul {
            display: flex;
            padding: 0;
            flex-wrap: wrap;
            justify-content: center;
        }
        .selectallinnercheckbox>ul>li{margin-right: 0; margin-bottom: 10px;}
        .institutesearchmainlistingsectionps{padding: 10px;}
        a.bdetail{display: block;}
        .instisegricateone .condetails ul{flex-wrap: wrap;}
        .instisegricateone .condetails ul li{padding-right: 0;}
        .instisegricateone .condetails ul li{font-size: 12px; display: block;}
        .instisegricateouter.d-flex {
            display: block !important;
        }
        .instilogo{
            width: 100px;
            height: 100px;
        }
        .plainde{bottom: 0;}
        .instisegricateone h4{margin-top: 20px; padding-right: 10px;}
        section.sortingdiv {
            margin-top: 20px;
        }
        section.sortingdiv ul{margin-left: 0;}
        span.shortby{font-size: 15px;}
        section.sortingdiv ul li a{font-size: 15px;}
        section.sortingdiv ul li {
            padding-right: 0;
        }
        section.sortingdiv .container {
            display: flex;
            justify-content: center;
            justify-content: space-evenly;
            align-items: center;
        }
        section.sortingdiv ul li img{width: 20px;}
        .institutesearchmain section.institutesearchmainherosection{
            position: relative;
        }
        .institutesearchmain .mobiledrop .filtersectionglobal {
            margin-top: 0;
        }
        button#dropdownFilter, button#dropdownShortBy {
            color: #000;
            font-family: "Fjalla One";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: none;
            border: none;
        }
        .d-flex.mobiledrop {
            justify-content: center;
            border-top: 1px solid #ACACAC;
            border-bottom: 1px solid #acacac;
            margin-top: 20px;
        }
        .institutesearchmain .mobiledrop .filtersectionglobal {
            margin-top: 0;
            text-align: left;
        }
        .institutesearchmain .mobiledrop .selectinner .mainchecklabel{font-size: 14px;}
        .institutesearchmain .mobiledrop .selectinner  span.maincheckboxicon img {
            width: 23px;
            height: 23px;
            object-fit: contain;
        }
        .institutesearchmain .mobiledrop  .selectallinnercheckbox li.selectinner .form-check-input,.institutesearchmain .mobiledrop input#Checkall {
            width: 18px;
            height: 18px;
            margin-left: 3px;
            z-index: 9;
        }
        .institutesearchmain .mobiledrop .selectallinnercheckbox li.selectinner .dropdown-item .form-check-input
        {
            margin-left: -25px;
        }
        .institutesearchmain .mobiledrop .selectallcheckbox label.form-check-label.mainchecklabel{font-size: 14px;}
        .institutesearchmain .mobiledrop .form-checkselect{padding-left: 5px; padding:5px}
        .institutesearchmain .mobiledrop  .selectinner .dropshortby a.dropdown-item{padding: 0;}
        .institutesearchmain .mobiledrop  .selectinner .dropshortby  a.dropdown-item .form-check-input
        {
            margin-left: -20px;
        }
        .institutesearchmain .mobiledrop .selectallinnercheckbox ul.dropdown-menu label.form-check-label{
            color: #000;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 11px;
            padding-left: 4px;
            width: 126px;
        }
        .show>.btn-secondary.dropdown-toggle:focus{
            box-shadow: none !important;
        }
        .dropshortby{position: relative;}
        .mobiledrop > .dropdown:before, .dropshortby:before {
            content: "";
        position: absolute;
        right: -12px;
        width: 1px;
        height: 24px;
        background: #ccc;
        top: 4px;
        }
        .mobiledrop  .dropdown-menu.show {
            display: block;
        width: 282px;
        padding: 0;
        left: auto !important;
        right: -145px;
        }
        .mobiledrop .dropfilter  span.subcatimage {
            display: inline-block;
            margin-left: 10px;
        }
        .mobiledrop .dropfilter  span.subcatimage img{
            filter: invert(0);
        }
        .mobiledrop .dropfilter .form-check .form-check-input{
            margin-left: -10px;margin-top: 0;
        }
        .mobiledrop .dropfilter  input[type="checkbox"]:enabled:checked{
            background-color: #00831D;
            color: white;
        }
        .mobiledrop > .dropdown:last-child:before, .mobiledrop > .dropshortby:last-child:before{display: none;}
        .mobiledrop > .dropdown, .mobiledrop > .dropshortby {
            margin: 0 15px;
            position: relative;
            width:50%;
            text-align: center;
        }
        .institutesearchmain .mobiledrop .dropdown-menu ul{padding-left: 0; 
            background: #fff; 
            margin-bottom: 0;
            fill: #FFF;
            filter: drop-shadow(2px 2px 18px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
        position: relative;
        z-index: 9;
        padding: 10px ;
    }
    .institutesearchmain .mobiledrop .dropdown-menu ul li:last-child{border: none; padding-bottom: 0; margin-bottom: 0;}
    .institutesearchmain .mobiledrop .dropdown-menu ul li{position: relative; padding-bottom: 5px; margin-bottom: 5px; border-bottom: 1px solid #ccc;}
        .institutesearchmain .mobiledrop  li.selectinner .dropdown-toggle:after {
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 9px solid #fff;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            position: absolute;
            right: 8px;
            top: 9px;
            bottom: 0;
            margin: auto;
            display: block;
        }
        .form-checkselect{width:251px}
        .institutesearchmain .mobiledrop .selectallinnercheckbox>ul>li{width: 250px; padding: 5px;}
        .selectallcheckbox  span.maincheckboxicon img{margin-left: 12px;}
        .filtersectionglobal {
            position: absolute;
            z-index: 999;
            left: -158px;
            right: auto;
            top:43px;
            width: 282px;
            margin: auto;
            background: rgba(255, 255, 255, 0.6);
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 2px 2px 18px 0px rgba(0, 0, 0, 0.25);
        
        }
        .viewbutton{width: 90px;
            height: 25px;
            font-family: "Roboto Flex";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        padding:5px;}
        button#dropdownFilter:after, button#dropdownShortBy:after {
       
            content: "";
            border-top: 7px solid #000000;
            border-right: 5px solid transparent;
            border-left: 5px solid transparent;
            position: absolute;
            right: 20px;
            top: 13px;
            bottom: 0;
            margin: auto;
            display: block;
        } 
        .institutesearchmain .mobiledrop li.selectinner ul.dropdown-menu.show{width: auto !important; opacity: 1;}

        .filter-wrap {
            display: none;
        }
        
        .open-active.filter-wrap {
            display: block;
        }
        .filtersectionglobal:after{
            position: absolute;
        top: -9px;
        bottom: auto;
        left: 85%;
        margin-left: -10px;
        content: "";
        display: block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid white;
        border-top: none;
        }
    }
    .institutesearchmain .statusverified .expertoutershadow, .institutesearchmain .statusunverified .expertoutershadow {
        display: none;
    }
  