 @media screen and (min-device-width: 1200px) and (max-device-width: 5000px) {
    /* .bankingBanner {height: calc(100vh - 0px);} */
    /* .bankingBanner {height:702px} */
 }
@media (max-width: 767px) {
    .bankingnewui .categorybanner{
        background: url(../../../assets/bankingimges/bankingmobilebanner.svg);
        background-position: bottom !important;
    height: 470px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    }
    .bankingnewui .categorybanner .bn-image img {
        height: 261px;
        margin-top: 0px;
    }
    .bankingnewui .categorysectiontwo{
        background: url(../../../assets/bankingimges/Bankingbgmobile.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .bankingnewui .categorysectionsix.blogdetailsexpertppv{
        background: url(../../../assets/bankingimges/Bankingarticlebg.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .bankingnewui  section.categorysectionsix .blogreadmore{
        color: #54A0E3;
        border-color: #54A0E3;
        background: #DAF3FF;
    }
    .spantextcomm {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }
    .banking .rimibmentfont p {
        font-size: 18px;
        max-width: 100%;
    }
    .banking b.realEbold {
        font-size: 28px; 
    }
    .bankingnewui .categorybanner .bn-image img{width: 200px;}
    .banking p.rimibmentsubtext span {
        font-size: 18px;
    }
    .banking .realEbuttonmain {
        width: 100%;
    }
    .banking .realEbuttonmain p.realEbutton {
        font-size: 28px;
    }
    .bankingviewallbuttoncomm {
      padding: 10px 40px;
      font-size: 20px;
      font-weight: 500;
      border-radius: 4px;
    }
    .banking.makethecontainer .realEinner {
        margin:0px;
    
}
    .bankingviewallbuttoncomm:hover {
      box-shadow: 2px 2px 20px 0px #00000030;
      background-color: #028a0f;
      border: 4px solid #b6d055;
      color: #ffffff;
    }
  
    .bankingmessagemebuttoncomm {
      padding: 8px 40px;
      font-size: 16px;
      border-radius: 10px;
    }
  
    .bankingmessagemebuttoncomm:hover {
      background-color: #f2f2f2;
      color: #333333;
    }
  
    .bankingBanner {
      padding: 0px 0;
      background-size: contain;
    }
    .banking .rimibmentfont h2 {
      font-size: 48px;
      font-weight: 700;
    }
    .banking .rimibmentfont h6 {
      font-size: 28px;
      line-height: 36px;
      font-weight: 400;
    }
    .bankingtwo .letest-inner.resclass h2 {
        font-size: 31px;
    }
   .bankingtwo .letest-inner h6 {
        font-size: 17px;
        margin-bottom: 20px;
    }
    .bankingtwo .resclass .homepageproductoffer .letest-inner h2 {
        font-size: 32px;
        margin-bottom: 15px;
    }
    .bankingtwo .letest-inner span {
        font-size: 18px;
    }
    .bankingtwo .investmentviewallbuttoncomm {
        padding: 8px 40px;
        font-size: 20px;
    }
    .offersss {
        margin-bottom: 30px;
    }
    .bankingthree .TopRealEstateProducts-inner h2.investthreesubtext {
        font-size: 36px;
        margin-bottom: 15px;
    }
    .bankingthree .viewallbuttoncomm {
        padding: 8px 40px;
        font-size: 20px;
    }
    .bankingfour.homepageproductoffer h2.letestadsheading {
        font-size: 28px;
        line-height: 36px;
    }
    section.homepageproductoffer.bankingfour {
        margin: 50px 0;
    }
    .bankingfour .viewallbuttoncomm {
        padding: 8px 40px;
        font-size: 20px;
    }
    .bankingfive  .toprealEbutton {
        padding: 8px 21px;
        font-size: 17px;
    }
    .bankingfive .showallbtn {
        max-width: 100%;
        justify-content: center;
        text-align: center;
    }
    .bankingsix  h2.expertheading {
        font-size: 29px;
        margin: 0;
    }
    .bankingsix h5.expertsub1 {
        font-size: 15px;
        margin: 0;
    }
    .bankingsix .expertsub2 {
        font-size: 15px;
        margin: 0;
    }
    .bankingsix .viewallbuttoncomm {
        padding: 8px 40px;
        font-size: 20px;
    }
    section.homepageproductoffer.bestdeals.Experts.bankingsix {
        padding-bottom: 0px;
    }
    .bankingseven .realEquestion .realEquestiontext h4 {
        font-size: 20px;
        color: #FFFFFF;
        font-weight: 600;
        margin: 0;
    }
     .bankingseven h2.resstext {
        font-size: 17px;
        color: #FFFFFF;
        font-weight: 500;
        margin: 0;
    }
    .bankingseven .realEquestiontext h3 {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 20px;
    }
    
    .realEbloginner p.realEblogtext{
        font-size: 10px;
    }
    .bankingeight p.realEblogtext {
        font-size: 18px;
        max-width: 100%;
    }
    .bankingeight .viewallbuttoncomm {
        padding: 8px 40px;
        font-size: 20px;
    }
}
@media (max-width: 420px) {
    .spantextcomm {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
    }
    .banking .rimibmentfont p {
        font-size: 16px;
        max-width: 100%;
    }
    .banking b.realEbold {
        font-size: 24px;
    }
    .banking p.rimibmentsubtext span {
        font-size: 16px;
    }
    .banking .realEbuttonmain {
        width: 100%;
    }
    .banking .realEbuttonmain p.realEbutton {
        font-size: 24px;
    }
    .bankingviewallbuttoncomm {
        padding: 8px 20px;
        font-size: 16px;
        font-weight: 400;
        border-radius: 4px;
    }
  
    .bankingviewallbuttoncomm:hover {
        box-shadow: 2px 2px 10px 0px #00000030;
        background-color: #028a0f;
        border: 3px solid #b6d055;
        color: #ffffff;
    }
  
    .bankingmessagemebuttoncomm {
        padding: 6px 20px;
        font-size: 14px;
        border-radius: 8px;
    }
  
    .bankingmessagemebuttoncomm:hover {
        background-color: #f2f2f2;
        color: #333333;
    }
  
    .insuranceBanner {
        padding: 0;
        background-size: contain;
    }
    .banking .rimibmentfont h2 {
        font-size: 36px;
        font-weight: 700;
    }
    .banking .rimibmentfont h6 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
    }
    .bankingtwo .letest-inner.resclass h2 {
        font-size: 24px;
    }
   .bankingtwo .letest-inner h6 {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .bankingtwo .resclass .homepageproductoffer .letest-inner h2 {
        font-size: 25px;
        margin-bottom: 10px;
    }
    .bankingtwo .letest-inner span {
        font-size: 16px;
    }
    .bankingtwo .investmentviewallbuttoncomm {
        padding: 6px 20px;
        font-size: 16px;
    }
    .offersss {
        margin-bottom: 20px;
    }
    .bankingthree .TopRealEstateProducts-inner h2.investthreesubtext {
        font-size: 24px;
        margin-bottom: 10px;
    }
    .bankingthree .viewallbuttoncomm {
        padding: 6px 20px;
        font-size: 16px;
    }
    .bankingfour.homepageproductoffer h2.letestadsheading {
        font-size: 20px;
        line-height: 28px;
    }
    section.homepageproductoffer.bankingfour {
        margin: 30px 0;
    }
    .bankingfour .viewallbuttoncomm {
        padding: 6px 20px;
        font-size: 16px;
    }
    .bankingfive .toprealEbutton {
        padding: 6px 15px;
        font-size: 14px;
    }
    .bankingfive .showallbtn {
        max-width: 100%;
        justify-content: center;
        text-align: center;
    }
    .bankingsix h2.expertheading {
        font-size: 24px;
        margin: 0;
    }
    .bankingsix h5.expertsub1 {
        font-size: 13px;
        margin: 0;
    }
    .bankingsix .expertsub2 {
        font-size: 13px;
        margin: 0;
    }
    .bankingsix .viewallbuttoncomm {
        padding: 6px 20px;
        font-size: 16px;
    }
    section.homepageproductoffer.bestdeals.Experts.bankingsix {
        padding-bottom: 0;
    }
    .bankingseven .realEquestion .realEquestiontext h4 {
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 600;
        margin: 0;
    }
    .bankingseven h2.resstext {
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 500;
        margin: 0;
    }
    .bankingseven .realEquestiontext h3 {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 15px;
    }
    
    .realEbloginner p.realEblogtext {
        font-size: 8px;
    }
    .bankingeight p.realEblogtext {
        font-size: 16px;
        max-width: 100%;
    }
    .bankingeight .viewallbuttoncomm {
        padding: 6px 20px;
        font-size: 16px;
    }
    .posbuttontf {
        justify-content: center;

    }
    .bankingtwo .offersss .posbuttontf.d-flex a{
        margin: 0;
    }
}
@media (max-width: 320px) {
    .spantextcomm {
        font-size: 14px;
        font-weight: 300;
        color: #333333;
    }
    .banking .rimibmentfont p {
        font-size: 14px;
        max-width: 100%;
    }
    .banking b.realEbold {
        font-size: 20px;
    }
    .banking p.rimibmentsubtext span {
        font-size: 14px;
    }
    .banking .realEbuttonmain {
        width: 100%;
    }
    .banking .realEbuttonmain p.realEbutton {
        font-size: 20px;
    }
    .bankingviewallbuttoncomm {
        padding: 6px 15px;
        font-size: 14px;
        font-weight: 300;
        border-radius: 4px;
    }
  
    .bankingviewallbuttoncomm:hover {
        box-shadow: 1px 1px 5px 0px #00000030;
        background-color: #028a0f;
        border: 2px solid #b6d055;
        color: #ffffff;
    }
    .bankingeight .realEbloginner h2{
        font-size: 12px;
    }
  
    .bankingmessagemebuttoncomm:hover {
        background-color: #f2f2f2;
        color: #333333;
    }
    .realEblogbg .realEbloginner h2 {
        color: #000000;
        font-size: 36px;
        font-weight: 700;
        line-height: 1.1;
    }
    .insuranceBanner {
        padding: 0;
        background-size: contain;
    }
    .banking .rimibmentfont h2 {
        font-size: 28px;
        font-weight: 700;
    }
    .banking .rimibmentfont h6 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
    }
    .bankingtwo .letest-inner.resclass h2 {
        font-size: 20px;
    }
   .bankingtwo .letest-inner h6 {
        font-size: 12px;
        margin-bottom: 10px;
    }
    .bankingtwo .resclass .homepageproductoffer .letest-inner h2 {
        font-size: 22px;
        margin-bottom: 8px;
    }
    .bankingtwo .letest-inner span {
        font-size: 14px;
    }
    .bankingtwo .investmentviewallbuttoncomm {
        padding: 5px 15px;
        font-size: 14px;
    }
    .offersss {
        margin-bottom: 15px;
    }
    .bankingthree .TopRealEstateProducts-inner h2.investthreesubtext {
        font-size: 20px;
        margin-bottom: 8px;
    }
    .bankingthree .viewallbuttoncomm {
        padding: 5px 15px;
        font-size: 14px;
    }
    .bankingfour.homepageproductoffer h2.letestadsheading {
        font-size: 16px;
        line-height: 24px;
    }
    section.homepageproductoffer.bankingfour {
        margin: 20px 0;
    }
    .bankingfour .viewallbuttoncomm {
        padding: 5px 15px;
        font-size: 14px;
    }
    .bankingfive .toprealEbutton {
        padding: 5px 12px;
        font-size: 12px;
    }
    .bankingfive .showallbtn {
        max-width: 100%;
        justify-content: center;
        text-align: center;
    }
    .bankingsix h2.expertheading {
        font-size: 20px;
        margin: 0;
    }
    .bankingsix h5.expertsub1 {
        font-size: 12px;
        margin: 0;
    }
    .bankingsix .expertsub2 {
        font-size: 12px;
        margin: 0;
    }
    .bankingsix .viewallbuttoncomm {
        padding: 5px 15px;
        font-size: 14px;
    }
    section.homepageproductoffer.bestdeals.Experts.bankingsix {
        padding-bottom: 0;
    }
    .bankingseven .realEquestion .realEquestiontext h4 {
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 600;
        margin: 0;
    }
    .bankingseven h2.resstext {
        font-size: 12px;
        color: #FFFFFF;
        font-weight: 500;
        margin: 0;
    }
    .bankingseven .realEquestiontext h3 {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
    }
    
    .realEbloginner p.realEblogtext {
        font-size: 6px;
    }
    .bankingeight p.realEblogtext {
        font-size: 14px;
        max-width: 100%;
    }
    .bankingeight .viewallbuttoncomm {
        padding: 5px 15px;
        font-size: 14px;
    }
}

