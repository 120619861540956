
 @media screen and (min-device-width: 1200px) and (max-device-width: 5000px) {
    /* .investmentBanner {height: calc(100vh - 0px);} */
    /* .investmentBanner {height: 702px;} */
 }
@media (max-width: 767px) {
    .investmentnewui .categorybanner{
        background: url(../../../assets/investmentimages/investmentmobilebanner.svg);
        background-position: bottom !important;
    height: 493px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    }
    
    .investmentnewui  section.categorysectionsix .blogreadmore{
        color: #86357A;
        border-color: #86357A;
        background: #FFDDFA;
    }
    .investmentnewui .categorysectiontwo, .investmentnewui .categorysectionthree{
        background: url(../../../assets/investmentimages/Investmentbgmobile.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .investmentnewui .categorysectionsix.blogdetailsexpertppv{
        background: url(../../../assets/investmentimages/InvestmentArticlebg.svg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    .investment .makethecontainer .realEinner{
        width: 100%;
    }
    .investmentthree .TopRealEstateProducts-inner h2.investthreesubtext{
        font-size: 36px;
        margin-bottom: 15px;
    }
    .investmentthree .TopRealEstateProducts-inner h6 {
        font-size: 18px;
        margin-bottom: 20px;
    }
    .investmentthree .TopRealEstateProducts-inner span {
        font-size: 18px;
    }

}
