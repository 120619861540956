.questionstext span  {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
    color: #303030;
    font-family: 'Poppins' , sans-serif !important; 
}
.questionstext b  {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poppins',sans-serif !important ;
    line-height: 21.6px;

    color: #303030;
}
@media (max-width:767px){
    .questionstext span {
        font-size: 14px;
        font-family: 'microsoft_sans_serifregular' !important;
    }
}
