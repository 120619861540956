.logoform .form-fieldinner img {
    object-fit: contain;
    height: 200px;
    border-radius: 100px;
    width: 200px;
    box-shadow: 6px 6px 16px 0px #00000040;

}
.myaccountsection {
    background: #E4EFFF;
    box-shadow: 5px 5px 25px rgba(6, 40, 61, 0.2);
    border-radius: 10px;
    padding: 20px;
}
.logoform .form-fieldinner {
    display: flex;
    background-color: #E4EFFF;
    border-radius: 100px 10px 10px 100px;
    align-items: center;
}
.myaccountsection{
    background: none;
    box-shadow: none;
}
.main-uplod-section {
    display: flex;
    align-items: center;
}
.my-account .uploaddetails p {
    color: #6D6D6D;
    font-size: 16px;
    font-weight: 400;
}
.my-account p.full_name {
    color: #303030;
    font-size: 20px;
    margin-bottom: 35px;
    font-weight: 500;
}
.my-account label.change_button {
    background-color: #2280B8;
    padding: 16px;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
}
.my-account label.change_button:hover {
    cursor: pointer;
    background-color: transparent;
    border: 1.5px solid #2280B8;
    color: #2280B8;
}
.my-account .delete-button:hover {
    color: #FFFFFF;
    cursor: pointer;
    background-color: #2280B8;
}
.my-account .delete-button {
    padding: 11px 40px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    background-color: transparent;
    border: 1.5px solid #2280B8;
    color: #2280B8;
}
.main-uplod-section .btn_upload{
    padding: 17px 30px 12px 0;
}
.personal-details , .occupation{
    border-radius: 10px;
background: #FFF;
box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
}
.personal-details .myaccountsectionheading h3 {
    font-size: 28px;
    color: #2A2A2A;
    font-weight: 500;
}
.occupation .myaccountsectionheading h3 {
    font-size: 28px;
    color: #2A2A2A;
    font-weight: 500;
}
.personal-details  button.edit-button {
    background-color: #2280B8;
    padding: 11px 45px;
    border: none;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
 
}
.personal-details .form-fieldinner label {
    
    font-weight: 500;
    font-size: 14px;
    
    color: #6d6d6d;
}
.occupation .form-fieldinner label {
    
    font-weight: 500;
    font-size: 14px;
    
    color: #6d6d6d;
}
.personal-details .fieldans {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #303030;
    background-color: white;
    padding: 10px;
    border-radius: 3px;
}
.occupation .fieldans {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #303030;
    background-color: white;
    padding: 10px;
    border-radius: 3px;
}
.occupation  button.edit-button {
    background-color: #2280B8;
    padding: 11px 45px;
    border: none;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
 
}
.occupation .intersr-name {
    display: flex;
    align-items: center;
}
