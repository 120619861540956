
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.row.rimibicons{flex-wrap: wrap;}
	.homepageexpertinner{display: block;}
	.offerbannerslider {width: 35%;}
	.ppvexpertdetailspop.institutepublicprofile .publicdeinner{overflow: visible;}
	.heroleftbutton.d-flex.mt-4 {
		flex-wrap: wrap !important;
	}
	.off-pro{width: 170px;}
	.off-pro button:first-child {
		padding: 10px 20px !important;
	}
	.col-sm-3.col-xs-12.find {
		width: 20%;
	}
	.search-block .col-sm-3.col-xs-12:first-child {
		width: 30%;
	}
	.col-sm-6.col-xs-12.selection {
		width: 50%;
	}
	.topexpert{margin-top: 40px;}
	.categoryonesetfirst {
		width: 100%;
	}
	.categoryonesetfirst {
        width: 75%;
        float: none;
    }
	.categorytwosetfirst {
		width: 70%;
	}
	.categorytwosetfirst.categorytwoset {
		width: 70%;
		margin-top: 50px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-left: auto;
		clear: both;
		float: none;
	}
	.off-pro button:nth-child(2) {
		padding: 10px 10px !important;
	}
	.row.search-block{width:100%;}
	.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede .cstlist{font-size: 15px;
		font-style: normal;
		font-weight: 500;
		line-height: 1.2;}
	
	.herosection a.ctatwobutton {
		margin-left: 0;
		margin-top: 10px;
	}
	.ppvexpertdetailspop.institutepublicprofile.publicdeinnerts .publicmainpopdis.basicdetails {
		width: 100%;
		position: relative;
		display: flex;
	
	}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicpersonaldetails {
		width: auto;
		margin-top: 127px;
		border: none;
		margin-left: 0;
		padding-left: 0;
	}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .socialpublic {
		background: #024E7C;
		position: absolute;
		right: 10px;
		bottom: 20px;
		width: 40px;
		padding: 0px;
		top: auto;
		height: 54%;
		border-radius: 40px;
		margin: auto;
	}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede {
		padding-left: 121px;
	}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .d-flex {
		flex-wrap: wrap;
		justify-content: space-between;
	}
	
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .d-flex .publicpersonaldetails {
		width: 45%;
	}
	.ppvexpertdetailspop.institutepublicprofile.publicdeinnerts .publicmainpopdis.basicdetails:before{height:100%}
	.searcharea  .ctaonebutton {
		font-size: 13px;
		line-height: 20px;
	}
	.adscreenone{height: auto;}
	.p_slider .ctaonebutton {
		font-size: 13px;
	}
	.latestrates .d-flex.latestratesflex {
		flex-wrap: wrap;
	}
	
	.searchpageherosection .searchmainara {
		width: 100%;
	}
	header ul.navbar-nav li.active a:before{top:-4px;}
	header ul.navbar-nav li.active a:after{bottom: -4px;}
	.right-institute .institutelogintext .grid-2 {
		grid-template-columns: 1fr;
		grid-gap:0;
		}
		.DatePicker.input-container.form-group {
			margin-bottom: 30px;
		}
		section{overflow-x: hidden;}
		.institutelogintext {
			width: 85%;
		}
		.modalspecial > div{width: 90%; padding: 15px;}
		.addexpertleft {
			width: 66%;
		}
		.addexpertright {
			width: 34%;
		}
		.uploadexpert{padding: 25px 10px;}
		.button_outer{width: 140px; padding: 17px 15px 12px;}

.expertinnerformmain .grid-2 {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    width: 100%;
}
.btn_upload {
    padding: 0px 2px 12px;
    color: #fff;}
	.myexpertnav.myaccountnav ul li:nth-child(n + 1) {
		border-left: none;
		border-right: none;
		margin-right: -10px;
	}
	.myexpertnav > ul li{font-size: 14px;}
	.form-fieldinner.sociallink .fieldans{width:auto;}
	.publicviewprofiledetails{margin-bottom: 30px;}
	.planbutton {
		width: 165px;}
		.planbuttonouter{padding: 0;}
		.planesinner ul li {
			position: relative;
			padding-left: 20px;
			min-height: 90px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			line-height: initial;
			font-size: 14px;
		}
		.planesinnerright ul li {
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			text-align: center;
			color: #214860;
			list-style: none;
			border-bottom: 1px solid #dff6ff;
			min-height: 90px;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: initial;
		}
		.planesinner ul li:before{top:auto;}
		span.plantooltip.custooltip:hover .tooltipcontent {
			visibility: visible;
			left: 23px;
			top: -40px;
			width: 200px;
		}
		span.plantooltip.custooltip {
			position: absolute;
			right: 0;
		}
		.categorythreeset .categoryonesetone {
			padding-right: 0px;
		}
		.categoryfourset .categoryonesetone {
			padding-left: 0px;
		}
		.categoryfourset .awssld {
			position: relative;
			width: 45%;
		}
		.categoryfourset .categoryonesetone {
			width: 40%;
		}
		.catstart .awssld__content > div{width:80%;}
 }
@media (max-width: 1180px) {
	#sb-search .sb-search-icon.float {
		right: 120px!important;
top: 14px!important;
width: 60px;
height: 60px;
left: auto!important;
	}
	section#ask {
		padding: 100px 0 0 0;
	}
	ul.deone li a, ul.detwo li a {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		word-break: break-all;
	}
	.searchinnerhero {
		width: 100%;
	}
	.myexpertformarea.searcharea .input-container.form-group{width: 38%;}
	.homepageproductoffer .col {
		width: 30%;
		flex: auto;
	}

	.testileftpanel {
		padding-left: 0px;
		position: relative;
		margin-bottom: 90px;
	}
	.pofferinner{padding: 10px;}
	.ctaonebutton{font-size: 16px;}
	div#navbarSupportedContent ul li {
		padding: 0 10px;
	}
	.homepageproductoffer .col {
		flex: auto;
	}
	.myexpertformarea.searcharea .input-container.form-group:last-child{width:25%;}
	footer ul li a {
		line-break: anywhere;
		font-size: 10px;
		line-height: 15px;
	}
	.footerone.footerthree ul.conde li {
		padding-right: 0;
		line-height: 10px;
		max-width: 100%;
		margin-bottom: 10px;
		font-size: 10px;
		margin-bottom: 0 !important;
	}
	ul.socialin.d-flex.mb-3 {
		justify-content: left;
	}
	
	ul.socialin.d-flex.mb-3 img {
		width: 20px;
	}
	section.homepageproductoffer.mt-5.mb-5.d-block.d-sm-none {
		margin-bottom: 0 !important;
	}
	section.herosection.heroasktheexpert{padding: 15px 0; border-radius: 0;}
	.waveWrapper{display: none;}
	section#ask {
		padding: 0 0 0 0;
	}
	section.faqsection{padding: 20px 0;}
	ul.conde li {
		padding-left: 38px;
		padding-bottom: 15px;
		font-size: 13px;
		line-height: 20px;
	}

	.homepageexpertinner {
		margin-bottom: 25px;
	}
	.expertinner ul li{font-size: 13px;}
	.searchresultsection .expertinner ul li:nth-child(2){width: 25%;}
	.rimibimage{text-align: center;}

}
@media screen and (min-device-width: 1024px) and (max-device-width: 1179px) {
	div#navbarSupportedContent ul li {
		padding: 0 5px;
		text-align: center;
	}
	.navbar-expand-lg .navbar-nav .nav-link{padding-left: 0 !important;padding-right: 0 !important;font-size: 11px;}
	img.rimibimagetwo{margin: auto;}
	.homepageproductoffer .col {
		width: 20%;
	}
	.p_slider .ctaonebutton {
		font-size: 13px;
		line-height: 1.2;
	}
	section.loginscreeninstitute.loginmainds{height: auto;}
}
@media (max-width: 767px) {
	.Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right{
		top:40px !important;
		width:100% !important;
	}
	.experdetails h3 span:nth-child(1) {
		margin-left: 0;
	}
	footer .col-lg-4.col-md-6.col-sm-6.col-xs-12 {
		width: 50%;
	}
	footer h3 {
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
	}
	ul.socialin.d-flex.mb-3 {
		position: absolute;
		margin: auto;
		left: 0;
		margin-bottom: 0 !important;
	
		justify-content: left;
		right: 0;
		width: 100%;
	}
	ul.conde li {
        padding-left: 38px;
        padding-bottom: 10px;
        font-size: 10px;
        line-height: 16px;
        margin-right: 0 !important;
    }
	footer .container {
		padding-bottom: 30px;
	}
	.footertwo:after{display: none;}
	.experdetails h3>span:first-child {
		display: block;
	}
	.experdetails h3 .expcat span {
		display: inline-block;
	}
	input.inputSearch {
		border-bottom: 1px solid #ccc;
		margin-bottom: 10px;
		font-size: 13px;
		width: 100%;
	}
	.rimibimage{min-height: auto;}
	.selection{flex-wrap: wrap;}
	.search-block{height: auto;}
	.row.search-block{width:100%;}
	.selection .input-container.form-group1 {
		margin-left: 0 !important;
	}
	.off-pro {
        width: 220px;
        justify-content: center;
        margin: auto;
		margin-top: 10px;
    }
	.find{display: inline;}
	.ctaonebutton1 {
		position: absolute;
		right: 0;
		top: 0;
	}
	.inputSearch{    margin-left: 0;
		position: relative;}
	.ppvexpertdetailspop.institutepublicprofile.publicdeinnerts .publicmainpopdis.basicdetails {
		width: 100%;
		position: relative;
		display: flex;
		flex-wrap: wrap;	}
	.specialisationppv section.latestrates .bg-white-new .detailsbank h5, .specialisationppv section.latestrates .detailsbank.bg-white-new h5{font-size: 15px;}

	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede {
		padding-left: 0;
		background: transparent;
	}
	.institutepublicprofile .publicviewprofilede{padding: 0 10px  !important;}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede h2.h2_style {
		color: #fff;
		margin-bottom: 0;
		line-height: 35px;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
		background: #024E7C;
		border-radius: 20px;
		padding: 10px 0;
	}
	.ppvexpertdetailspop.institutepublicprofile .publicdeinner {
		display: flex;
		padding: 10px;
	}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .logemail.mainsecpos {
		position: relative;
		top: auto;
		bottom: 0;
		right: 0;
		width: 100%;
		left: 0;
		padding: 0;
		margin-top: 20px;
	}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .logemail.mainsecpos {
		position: relative;
		top: auto;
		bottom: 0;
		right: 0;
		width: 100%;
		left: 0;
	}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .ppvplan {
		border-radius: 10px;
		background: #FFF;
		box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.30);
		right: 8px;
		top: 4px;
		color: #024E7C;
		z-index: 9;
		font-size: 16px;
	}
	.searchresultsection .expertinner .experdetails h3>span:first-child {
		display: block;
	}
	.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede .cstlist{position: relative; font-size: 15px;}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicpersonaldetails{margin: 0;}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicpersonaldetails:first-child{margin-left: 0; padding-left: 0;}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .socialpublic {
		background: #024E7C;
		position: relative;
		right: 0;
		bottom: 0;
		width: 100%;
		padding: 10px;
		top: 0;
		height: auto;
		border-radius: 40px;
		margin: auto;
	}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicpersonaldetails heading{font-size: 16px;}
	.ppvexpertdetailspop.institutepublicprofile.publicdeinnerts .publicmainpopdis.basicdetails:before
	{height:55%;}
	.publicmainpopdis {
		position: relative;
		width: 100%;
	}
	.institutepublicprofile .publicdeinner {
		flex-wrap: wrap;
		border-radius: 0;
		margin: 0;
		margin-bottom: 110px;
	}
	.institutepublicprofile .publicpersonaldetails {width: 100%;}
	.publicdeinnerts.institutepublicprofile .publicviewprofilede, .institutepublicprofile .publicviewprofilede{
		position: relative;
		width: 100%;
		text-align: center;
		padding-left: 0;
		margin-bottom: 20px;
	}
	.publicdeinnerts .publicmainpopdis.basicdetails {
		width: 100%;
	}
	.institutepublicprofile .publicpersonaldetails {
		position: relative;
		padding-left: 10px;
		margin-left: 10px;
		border-left: 1px solid #ccc;
	}
	.institutepublicprofile .publicviewprofilelogoupload{
		margin: 30px auto;
		margin-left: auto;		
		display:block;}
	ul.detwo.ppvtwo li span img {
			padding-right: 0;
	}
	.logemail.mainsecpos {
		position: relative;
		top: auto;
		right: 0;
		width: 100%;
		padding-left: 15px;
	}
	.institutepublicprofile .socialpublic {
		background: #024E7C;
		position: relative;
		right: auto;
		bottom: 0px;
		width: 100%;
		padding: 10px;
		padding-left: 0;
		justify-content: center;
	}
	.mobile_description {
		border-radius: 4px;
		background: #c9daea;
		padding: 10px;
		position: absolute;
		width: 96%;
		height: max-content;
		margin: auto;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
	.offerdspop {
		position: relative;
		top: 0;
		left: 0;
	}
	.offerslider .sliderContent h3 {
		opacity: 1;
		display: block;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
	}
	.offerslider .sliderContent .ppvcat h3, .offerslider .sliderContent .ppvdate h3{margin-bottom: 0;}
	.ppvcat {
		margin-bottom: 5px;
		border-bottom: 1px solid #fff;
		padding-bottom: 3px;
	}
	.ppvcat, .ppvdate{font-size: 15px;}
	.descriptionppvpop {padding: 15px;}
	.limitedofferinner .descriptionppvpop h5 {
		color: #212121;
		font-size: 12px;
		line-height: 1.2;
	}
	.mobile_description{opacity: 1; display: none;}
	.offerslider .ppvawesomesliderdescription{display: none !important;}
	.limitedofferinner .offerslider .sliderContent{position: relative;}
	/* .limitedofferinner .offerslider .sliderContent .ctaonebutton.widthhalf {
		display: none;
	}
	.limitedofferinner .offerslider .sliderContent .ctaonebutton.widthhalf {
		display: none;
	} */
	
	button.ctaonebutton.mobilebutton {
		width: auto;
		padding: 4px 10px;
		margin-bottom: 0px;
		border-radius: 8px;
		height: auto;
		margin-top: 10px;
		font-size: 14px;
	}
	.limitedofferinner .offerslider .sliderContent:hover .mobile_description{display: block;}
	.mobile_description p {
		font-size: 13px;
		margin-bottom: 3px;
		line-height: 15px;
	}
	
	section.loginscreeninstitute.loginmainds{height:auto;}
	.ppvawesomesliderproductoffer h4, .ppvawesomesliderproducttype h4, .ppvawesomesliderreleatedproduct h4 {
		color: #fff;
		font-size: 16px;
		font-weight: 600;
	}
	section.topexpert.topexpertproduct {
		padding: 20px 0;
		margin-top: 15px;
		margin-bottom: 15px !important;
	}
	section.topexpert.topexpertproduct h2 {
		font-size: 30px;
		line-height: 40px;
	}
	.blog-modal .mybtn button{margin-left: 0;}
	#sb-search .sb-content {
		background-color: rgba(0,0,0,0.2) !important;
		
	}
	#sb-search .sb-content, #sb-search .sb-content .sb-search, #sb-search .sb-content.sb-hero {
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
	}
	#sb-search input{color: #000 !important;}
	.sb-item {
		background: #fff !important;
	}
	
	.topexpert .p_sliderouter {
		padding-top: 14px;
	}
	.blog-modal h2.wp-block-heading {
		font-size: 22px;
		line-height: 29px;
	}
	.offersliderh0me .slbtn.slidertext-pp p {
		text-align: center;
	}
	.categoryfourset .awssld__content{}

	.ppvawesomesliderproducttype, .ppvawesomesliderreleatedproduct, .ppvawesomesliderproductoffer {
		
		padding: 10px;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 17px;
	}
	.limitedofferinner .awssld__wrapper {
		overflow: visible;
		height: 100%;
		overflow-x: hidden;
	}
	.offerslider .awssld__container {
		padding-bottom: calc(var(--slider-height-percentage) * 2.55);
	}
	section.limitedoffer.mt-5 {
		margin-top: 10px !important;
	}
	.limitedoffer .awssld__content {
		overflow: visible;
	}
	.offerslider .awssld__box, .awssld__container figure, .offerslider .awssld__content{position: relative;}
	.offerslider .ppvawesomesliderdescription {
		opacity: 1;
		position: relative;
		bottom: auto;
		margin: 20px 0;
	}
	
	.modalspecial.blog-modal {
		z-index: 9999999999;
	}
	.modal-header h3 {
		padding-right: 40px;
	}
	#sb-search .sb-icon {
		font-size: 18px !important;
		padding: 0px 6px !important;
		cursor: pointer !important;
		width: 25px !important;
		height: 25px !important;
	}
	#sb-search .sb-modal .sb-icon{padding: 0px 5px !important;}
	#sb-search .sb-icon.sb-back-icon svg {
		margin-top: 0px !important;
		filter: none;
		fill: blue !important;
	}
	.sb-modal .sb-search .sb-input i.sb-icon.icon-ic_search svg path{fill: #fff !important;}
	#sb-search .sb-icon svg{-webkit-filter: none !important;}
	/* #sb-search .sb-modal .sb-icon svg path{fill:#fff !important} */
	
	.productslider .p_slider{min-height: auto;}
	.offersliderh0me .p_slider{min-height: 500px;}
	.topexpert {
		background: #dff6ff;
		padding: 15px 0 15px;
		margin-top: 30px;
	}
	.topexpert h2 {
		font-size: 30px;
		line-height: 35px;
	}
	.d-flex.latestratesflex .herorightpanel, .heroleftpanel  h2, .heroleftpanel  h3, .heroleftpanel  h4, .heroleftpanel  h5, .heroleftpanel  h6 {text-align: center;}
	.sldeimage img{height: auto;}
	.slidertext-pp{min-height: auto;}
	.p_slider .d-flex {
		flex-wrap: nowrap;
		justify-content: center;
	}
	
	.slbtn .ctaonebutton:first-child {
		margin-right: 0;
		margin-bottom: 10px;
	}
	.logochangemodal>div {
		width: 95%;
	}
	.modal-footer button {
		margin: 10px 0;
	}
	.footertwo {
		margin-left: 0px;
	  }
	  .categoryfourset .categoryonesetone {
		padding-left: 0px;
	}
	.categorythreeset .categoryonesetone {
		padding-right: 0px;
	  }
	.listofoffermainsection .detailedview .disgrid.grid-4 {
		grid-template-columns: 1fr 1fr;
	}
	.slick-current {
		transform: scale(1);
	}
	.dropdefaultimage img {
		margin: auto;
	}
	div#navbarSupportedContent ul li {
		padding: 5px 16px;
	}

	/* div#navbarSupportedContent ul li:last-child{margin-left: 15px;} */
	.slbtn{display: block; visibility: visible;}
	/* .heroleftpanel h2 {
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 43px;
	} */
	.adscreenone{height: auto;}
	.offer-insurence-inner {
		width: 100%;
	}
	.myexpertformarea.searcharea .input-container.form-group:last-child {
		width: 100%;
	}
	.herorightpanel {
		margin-top: 20px;
	}

	.categoryonesetfirst, .categorytwosetfirst {
		width: 100%;
	} 
    .asktheexpertcategorysectioninner .grid-3, .asktheexpertcategorysectioninner .grid-4 {
        grid-gap: 10px;
    }
   .categorytwosetfirst .grid-4 {grid-template-columns: 1fr 1fr 1fr; grid-gap:10px;}
   .categoryfourset{justify-content: space-between;}
    .categorythreeset {
        width: 45%;
    }
    .categoryonesetone.anotherexp {
        grid-column: 1 / 3;
        width: 189px;
        padding-right: 18px;
    }
    .categoryfourset .categoryonesetone {
        width: 46%;
    }
    header ul.navbar-nav li.active a:before{top:0}
    header ul.navbar-nav li.active a:after{bottom: 0;}
    .myexpertformarea.searcharea .input-container.form-group{width:100%;}
    .searchmainara {
        width: 100%;
        margin: 20px auto;
    }
    .myexpertformarea.searcharea .input-container.form-group:first-child, .myexpertformarea.searcharea .input-container.form-group:nth-child(3){border:none;}
   
    .mainexpforminner .grid-4 {grid-template-columns: 1fr 1fr;}
    .categoryfourset .awssld{width: 45%;}
	body {
		overflow-x: hidden !important;
	}

	section.loginscreeninstitute {
		position: relative;
		height: auto;
	}

	section.loginscreeninstitute:before {
		content: "";
		position: absolute;
		background: #DFF6FF;
		top: 0;
		left: 0;
		height: 30%;
		width: 100%;
		display: none;
	}

	section.loginscreeninstitute:after {
		content: "";
		position: absolute;
		background: #F6FDFF;
		top: auto;
		right: 0;
		height: 100%;
		width: 100%;
		bottom: 0;
		top: 0;
		box-shadow: none;
	}

	.loginscreenimage {
		background: #fff;
		width: 100%;
		text-align: center;
		border-radius: 20px;
	}

	.institutelogintext h2 {
		font-style: normal;
		font-weight: 600;
		font-size: 30px;
	}

	.institutelogintext {
		width: 100%;
	}

	.leftsightbackdetails {
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		margin-top: 0;
	}
	.instituteleft-illustration.martop60 {
		margin-top: 0;
	}
	section.loginscreeninstitute {
		padding: 50px 0;
	}

	a.navbar-brand {
		padding-left: 15px;
	}

	.loginscreenimage figcaption {
		text-align: left;
	}

	.institutelogintext {
		margin-top: 40px;
	}

	header ul.navbar-nav li a:after {
		height: 1px !important;
		bottom: 0px;

	}
	.productslider .slick-slide {
		padding: 0px;
		text-align: center;
	}
	header ul.navbar-nav li a:before {
		display: none;
		height: 2px;
		top: 0px;
	}

	header li.nav-item:last-child {
	
	}

	section.entiredashboard:before {
		background: #DFF6FF;
		box-shadow: 2px 2px 5px rgb(3 78 124 / 25%);
		content: "";
		position: absolute;
		width: 20%;
		height: 37%;
		
	}

	.experdetails ul {
		flex-wrap: wrap;
	}

	/* .d-flex {
		display: block !important;
	} */
	.searchpageherosection .searchmainara{width:100%;}
	.heroleftbutton button {
		width: auto;
		padding: 10px 20px;
		margin-right: 14px;
	}
.searcharea.searchmainara .input.form-control, .searchpageherosection .searcharea .input-container.form-group .input.form-control{ border-bottom: 1px solid #212121; margin-bottom: 5px;}
.myexpertformarea.searcharea .input-container.form-group:first-child, .myexpertformarea.searcharea .input-container.form-group:nth-child(2){width: 100%;}
.experdetails ul li span img {
    width: 15px;
    margin-right: 10px;
}
.experdetails h3 span {
    display: block;
    margin-left: 0;
    word-break: break-word;
}
.searchresultsection .expertinner .experdetails h3 span {
	display: inline-block;
}
.pofferinner {
		background: #FCFCFC;
		border-radius: 40px;
		filter: drop-shadow(0px 4px 40px rgba(42, 42, 42, 0.1));
		padding:10px 2px;
		max-width: 100%;
		margin: auto;
		margin-bottom: 15px;
		min-height: auto;
	}
	section.latestrates {
		background: #dff6ff;
		padding: 20px 0;
	}
	section.latestrates h2 {
		font-style: normal;
		font-weight: 700;
		font-size: 30px;
		line-height: 35px;
		color: #06283d;
	}

	img.testiconone {
		position: relative;
		top: 0;
		left: 0;
	}

	.rightform {
		margin-top: 70px;
	}

	.homepageexpertinner {
		margin-bottom: 20px;
		display: block;
        min-height: auto;
	}

	.homepageproductoffer .col {
		width: 100%;
		flex: auto;
	}

	.rimibimage {
		text-align: center;
	}

	.homeblogsection.nav-tabs .nav-link {
		margin-bottom: 12px;
	}

	.d-flex.latestratesflex {
		display: block !important;
		text-align: center;
	}

	
	
    .searchinnerhero{width: 100%;}
    .experdetails, .addexpertleft, .addexpertright {
        width: 100%;
    }
	.addexpertright{margin-top: 10px;}
	.myexpertnav > ul li {
		padding: 10px 7px;
		font-size: 12px;
		line-height: 41px;
		height: 63px;
		width: 96px;
	}
	.uploadexpert{padding: 25px;}
	.dashboardlink ul li {
		display: inline-block;
		font-size: 0;
		padding: 10px;
		height: 48px;
		width: 48px;
		line-height: 8px;
		vertical-align: middle;
	}
	.myexpertnav.myaccountnav ul li:nth-child(n + 1){margin-right: -10px;}
	.myexpertnav.myaccountnav ul li{height: 57px;
		width: 100px;    line-height: 15px;}
		.userlistinner {
			flex-wrap: wrap;
		}
		.mainplan p {
			margin-top: 20px;
		}
		.d-flex.questioninnerinbottom {
    flex-wrap: wrap;
}
	.dashboardleftpanel{margin-bottom: 40px;}
	.dashboardlink ul li a {
		font-size: 0;
		line-height: initial;
	}
	.dashboardlink {
		position: fixed;
		bottom: 0;
		background: #dff6ff;
		box-shadow: 5px 5px 25px rgb(6 40 61 / 20%);
		z-index: 999;
		left: 0;
		right: 0;
		padding: 10px;
	}
	
	.dashboardlink ul {
		margin: 0;
	}
	
	.dashboardlink ul li {
		margin: 0;
	}
	.myexpertformarea .input-container.form-group:first-child {
		width: 100%;
		border-right: none;
	}
	.myexpertformarea .input-container.form-group:nth-child(2), .myexpertformarea .input-container.form-group {
		width: 100%;
	}
	
	.dashboardlink ul li a img {
		margin: 0;
	}
	.myexpertnav > ul > li:nth-child(n + 1){margin-right: -10px;}
	section {
		overflow-x: hidden;
	}
	.dashboardrightpanel {
		padding-left: 0;
	}
	.entiredashboard .col-lg-9.col-sm-9 {
		margin-bottom: 116px;
	}
	.tooltipcontent{width: 250px;}
	.sectionheader {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
	.addexpert{flex-wrap: wrap;}
    .catstart:first-child:before{display: none;}
    .searchresultsection .expertinner ul li:first-child, .searchresultsection .expertinner ul li:nth-child(2) {
        width: 100%;
    }
    .expertinner{flex-wrap: wrap;}
    .expertinner .expertuser {
        width: 100%;
    }
	.myexpertformarea.searcharea {
		flex-wrap: wrap;
		justify-content: left;
	}

	.searchmainara button.ctaonebutton {
		width: 200px;
		margin: auto;
	}

	.p_slider .ctaonebutton {
		font-size: 16px;
    	width: 225px;
    	margin: 0 5px !important;
	}
	.limitedofferinner .awssld__content img {
		height: auto;
		padding-bottom: 0;
	}
	.footerlogo img {
		width: 200px;
	}
	.myexpertformarea.searcharea .input-container.form-group:nth-child(2) {
		border: none;
	}

	.homeblogsection.nav-tabs .nav-link {
		padding: 10px 5px;
		border-radius: 7px;
		font-weight: 400;
		font-size: 13px;
		line-height: 20px;
		height: 46px !important;
		width: 93px !important;

	}

	.homeblogsection.nav-tabs .nav-link:nth-child(n + 1) {
		margin-right: -10px;
	}

	.herosection a.ctaonebutton,
	.heroleftbutton.experthomepagebutton a.ctaonebutton {
		font-size: 14px;
		padding: 10px 20px;
	}
    .categoryonesetfirst .grid-3{grid-template-columns: 1fr 1fr; justify-items: center;}
	.myaccountsection .grid-4, .serviceoffer-inner .grid-4  {
		grid-template-columns: 1fr 1fr;
	}
	.serviceoffer-inner {
		width: 100%;
		margin: 20px auto;
	}
	.expertformppiinner, .ppvawesomesliderdescription{flex-wrap: wrap;}
	.ppvawesomesliderproducttype {
		width: 100%;
		margin-right: 0;
		margin-bottom: 10px;
	}
	.ppvawesomesliderreleatedproduct{width:50%;}
	.limitedofferinner .awssld__content h3{display: none;}
	
	ul.deone li {
		width: 100%;
	}
	ul.deone:before, ul.detwo:before{display: none;}
	ul.detwo li:nth-child(2) {
		padding-left: 0;
		margin-top: 10px;
	}
	section.publicviewprofiledetails.institutepublicprofile ul.detwo li:nth-child(2) {
		
		margin-top: 0px;
	}

	.publicviewprofiledetails{margin-bottom: 40px;}
	.ppvplan{top:10px;}
	.publicviewprofilede .cstlist, ul.deone li a, ul.detwo li a{line-break: anywhere;}
	ul.detwo, ul.deone{flex-wrap: wrap;}
	.expertformppiinnerdis {
		width: 49%;
	}
	.addcard{padding: 20px;}
	.inlinecard span.text-danger {
		bottom: -13px;
		font-size: 11px;
	}
	.paymentsummery li span {
		width: 50%;
		word-break: break-all;
	}
	.previewcard {
		width: 100%;
	}
	.planbuttonouter {
		padding-right: 0;
		text-align: center;
	}
	.planesall .row {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		overflow-y: scroll;
	}
	.planesinnerright ul {
		padding: 0;
		margin-bottom: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.planesinner ul {
		padding: 0;
		list-style: none;
		margin-bottom: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.categoryaskecpertconfirm {
		width: 200px;
		margin: auto;
		position: relative;
		top: 0;
		margin-bottom: 30px;
		margin-top: 20px;
	}
	.categorytwosetfirst.categorytwoset {
		width: 100%;}
		.input-container .placeholder {
			z-index: auto;
		}
		.mobileregistration{display: block;}
		.mobileregistration .back_btn {
			margin: auto;
		}
		.specialinfoinner{padding: 10px;}
		.modalspecial > div{width:100%; padding: 10px;}
		.modalspecial .right-institute {
			width: 100%;
			margin: auto;
		}
		.myexpertformarea.searcharea.searchmainara.searchmainarainner .input-container.form-group:first-child
		{width: 100%; border-right: none;}
		.listofoffermainsection .leftsightbackdetails.listofofferleft.padtop60 .plaindetailsleft {padding: 0;}
		.form-field .grid-4 {
			grid-template-columns: 1fr 1fr;
		}
		.planesall {
			background: transparent;
			border: transparent;
			border-radius: 8px;
		}
		.awssld__content img {
			height: 300px;
			padding-bottom: 80px;
			width: auto !important;
			margin: auto;
		}
		/* .socialpublic li:first-child span img {
			width: 21px;
		} */
		section.product-offer.service-offer .myexpertnav > ul li {
			padding: 10px 7px;
			font-size: 12px;
			line-height: 41px;
			height: 63px;
			width: 96px;
			background-size: cover;
		}
		/* .categoryfourset .awssld__controls__arrow-left:after, .categoryfourset .awssld__controls__arrow-right:after{display: none !important; background: transparent !important;}
		.categoryfourset .awssld__controls__arrow-left::before {
			background: url(../images/previous.png);
			background-size: contain;
			width: 15px;
			height: 15px;
			transform: none;
			background-repeat: no-repeat;
			opacity: 1;
			top: -3px;
			background-color: transparent;
		}
		.categoryfourset .awssld__controls__arrow-right::before {
			background: url(../images/next.png);
			background-size: contain;
			width: 15px;
			height: 15px;
			transform: none;
			background-repeat: no-repeat;
			opacity: 1;
			top: -3px;
		}
		.categoryfourset .awssld__wrapper .awssld__controls button.awssld__prev {
			background: transparent;
			height: 27px;
			width: 27px;
			border-radius: 44px;
			right: 15px;
			left: auto;
		}
		.categoryfourset .awssld__controls button:hover .categoryfourset .awssld__controls__arrow-left:before {
			opacity: 1;
			transform: none;
		}
		.categoryfourset .awssld__controls__arrow-left--active:before {
			transform: none !important;
		}
		.categoryfourset .awssld__controls button:hover .awssld__controls__arrow-left:before {
			opacity: 1;
			transform: rotate(0deg);
		}
		.categoryfourset .awssld__controls--active .awssld__controls__arrow-left, .awssld__controls--active .awssld__controls__arrow-right{transform: none !important;} */
		.limitedofferinner nav.awssld__bullets{display: flex;}
		
		.aos-animate {
			-o-transition-property: none !important;
			-moz-transition-property: none !important;
			-ms-transition-property: none !important;
			-webkit-transition-property: none !important;
			transition-property: none !important;
		
			-o-transform: none !important;
			-moz-transform: none !important;
			-ms-transform: none !important;
			-webkit-transform: none !important;
			transform: none !important;
		
			-webkit-animation: none !important;
			-moz-animation: none !important;
			-o-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
		}
		[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos]{transition-timing-function:none;}
		#sb-search .sb-search-icon.float {
			right: 60px!important;
			top: 25px!important;
			width: 37px;
			height: 37px;
			left: auto!important;
		}
	
}
@media screen and (min-device-width: 576px) and (max-device-width: 700px) {
	.heroleftbutton.d-flex {
		flex-wrap: wrap;
	}
	.herosection a.ctatwobutton{margin-left: 0;}
	.herosection a.ctaonebutton{margin-bottom: 10px;}
	.homepageproductoffer .col-sm-6 {
		flex: 0 0 auto;
		width: 100%;
	}
}
@media (max-width: 520px) {

	.input-container.psenter .placeholder {
		color: #214860;
		left: 4px;
		line-height: 14px;
		cursor: initial;
		pointer-events: none;
		position: absolute;
		transform-origin: 0 50%;
		transition: transform 200ms, color 200ms;
		top: 14px;
		background: transparent;
		font-size: 14px;
		opacity: 1;
		font-family: "Poppins", sans-serif;
		z-index: 9;
	}
	.publicdeinnerts.ppvexpertdetailspop.institutepublicprofile .publicpersonaldetails{width:50%;}
	img.plus {
		position: absolute;
		right: 5px;
		top: 15px;
	}
	.experdetails ul li span img{margin-left: 2px;}
	section.product-offer.service-offer .myexpertnav > ul li{padding: 10px 12px;}
	.expertinner ul li{font-size: 11px;}
	.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede .cstlist{display: flex;
        flex-wrap: wrap;
    }
	.ppvexpertdetailspop.institutepublicprofile .publicviewprofilede .cstlist span {
		padding-left: 5px;
		text-decoration-line: underline;
	}

	.categoryaskecpertconfirmsub {
		width: 200px;
		margin: auto;
		position: relative;
		top: 0;
		margin-top: 70px;
	}
	section.limitedoffer.mt-5 {
		margin-top: 10px !important;
	}
	.of-div {
		font-size: 12px;
    line-height: 15px;
    margin-top: 5px;
    height: 47px;
    overflow-y: scroll;
    font-weight: 200;
	}
	.categoryfourset .awssld__controls {
		visibility: hidden !important; top: 0}
		.categoryfourset .awssld .awssld__bullets{bottom:9px}
		
		.limitedofferinner .awssld__controls {
			visibility: hidden !important;
		}
		.categoryfourset .awssld__wrapper .awssld__controls button{top:0;}
		.categoryfourset .awssld__wrapper .awssld__controls button {
			background: transparent;
			height: 27px;
			width: 27px;
			border-radius: 44px;
		}
		.categoryfourset nav.awssld__bullets {
			display: flex;
			left: 0;
			right: 0;
		}
		.categoryfourset .awssld{    padding: 10px 0;}
		.categoryfourset nav.awssld__bullets button {
			background: #c9daea;
			width: 8px;
			height: 8px;
		}
		.categoryfourset .awssld__bullets .awssld__bullets--active{background: #06283d;}

}
@media (max-width: 440px) {
	.search-block select.input.form-control1.form-select {
		font-size: 12px;
	}
	ul.serviceoffer-inner-accordian li{padding: 10px 30px;}
	.listofadbutton .ctaonebutton, .listofadbutton .ctatwobutton {
        width: 200px;
        font-size: 14px;
        margin: 0 5px;
        line-height: 1;
        height: auto;
    }
	section.service-offer h3{font-size: 18px;}
	.plansummerytop ul li {
		font-style: normal;
		font-weight: 400;
		font-size: 13px;
		line-height: 24px;
		color: #06283d;
		display: flex;
		justify-content: space-between;
		line-height: 30px;
	}
	.catstart .awssld__content > div {
		background: #ffffff;
		border: 0.6px solid #06293f;
		border-radius: 4px;
		padding: 10px;
		width: 85%;
		font-size: 13px;
	}
	.categoryfourset .awssld__container{padding-bottom:200px ;}
	ul.detwo li {
		display: block;
		width: 100%;
	}
	ul.dethree li {
		display: block;
		width: 100%;
	}
	ul.dethree:before{display: none;}
	.logoform .form-fieldinner{display: block;}
	.specialinfoinner .form-field {
		background: #ffffff;
		border-radius: 6px;
		padding: 5px;
	}
	ul.dethree{flex-wrap: wrap;}
	.uploaddetails {
		padding-left: 0px;
	}
	button.myadbtn.absolutedown, button.myadbtn.absoluteup{right: 0px;}
	.form-field .grid-4 {
		grid-template-columns: 1fr;
	}
	
	.listofoffermainsection .leftsightbackdetails.listofofferleft.padtop60 {
		display: block;
	}
	.specialisationppv .grid-4 {
		grid-template-columns: 1fr; gap: 0px;}
		.expertformppiinnerdis .form-group {
			margin-bottom: 25px !important;
		}
		.expertformppiinnerdis {
			width: 100%;
		}
		.specialisationppv section.latestrates .detailsbank.bg-white-new h4, .specialisationppv section.latestrates .bg-white-new .detailsbank h4{
			margin-bottom: 0;
			font-size: 16px;
		}
		.bg-white-new{padding: 10px;}
		section.specialisationppv .heading h5{font-size: 15px;
			line-height: 1.3;}
	span.plantooltip.custooltip:hover .tooltipcontent {
		visibility: visible;
		left: -100px;
		top: 0;
	}	span.plantooltip.custooltip.righttool .tooltipcontent {
		right: auto;
		left: -147px;
		width: 350px;
		margin: auto;
		top: 0;
		z-index: 99;
	}
	section.entiredashboard:before {
		background: #DFF6FF;
		box-shadow: 2px 2px 5px rgb(3 78 124 / 25%);
		content: "";
		position: absolute;
		width: 20%;
		height: 37%;
		display: none;
		
	}

	.grid-3,
	.grid-2 {
		grid-template-columns: 1fr;
		grid-gap: 0;
	}

	nav.homeblogsection {
		flex-wrap: wrap;
	}
	.confirmtion{width: 100%;}
	.categoryonesetone.anotherexp {
		width: 100%;
		background: #fff;
		padding: 10px 0;
	}
	.categorytwosetfirst .grid-4 {
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px;
	}
	.categoryonesetwidth {
		width: 110px;
		height: 120px;
		margin-bottom: 10px;
		margin-left: auto;
		margin-right: auto;
	}
	.main_full {width:100%;}
	.detailsleft h1{padding-left: 10px;}
	.limitedofferinner{padding: 0 10px 15px; margin-bottom: 0px;}
	.limitedofferinner h5.mt-2 {
		font-size: 16px;
		line-height: 23px;
	}
	.typepop {
		display: block;
	}
	.homepageproductoffer h2 {
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 42px;
	}
	section.homepageproductoffer.mt-5.mb-5.d-block.d-sm-none {
		margin-top: 20px !important;
	}
	section.homepageproductoffer.mt-5.mb-5.d-block.d-sm-none  h2.text-center.mb-5{margin-bottom: 0px !important;}
	.ppvexpertdetailspop .ppvplan {
		top: 0;
	}
	.basicdetails.d-flex{display: block !important;}
	.socialpublic ul, .publicviewprofilede {
		padding: 0;
	}
	ul.detwo.ppvtwo li heading, ul.dethree.ppvthree li heading {
		width: auto;
		font-size: 13px;}
		ul.detwo.ppvtwo li heading:before, ul.dethree.ppvthree li heading:before{display: none;}
		ul.detwo.ppvtwo li span , ul.dethree.ppvthree li span{
			padding: 0 5px;
		}
		.publicviewprofilede {
			margin-top: 0px;
		}
		ul.deone li a, ul.detwo li a {
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			word-break: break-all;
		}
		.limitedofferinner .awssld__content h3{position: relative;}
		.expertinnerformmain {padding: 10px;}
		.col-lg-7 section.listofoffermainsection .specialinfoinner.mt-5.mb-5 .col-lg-12 {
			padding: 0;
		}
		.col-lg-7 section.listofoffermainsection .container {
			padding: 0;
		}
		.mainexpforminner .grid-4 {
			grid-template-columns: 1fr;
			gap: 30px;
			}
		.dashboardrightpanel  .myexpertnav > ul{flex-wrap: wrap;}
		.myexpertnav.myaccountnav.qnanav.martop40 ul {
			flex-wrap: nowrap;
		}
		.myexpertnav.myaccountnav.qnanav.martop40 ul> li:nth-child(n + 1) {
			margin-right: 0px;
		}
		.dashboardrightpanel .myexpertnav > ul > li:nth-child(n + 1) {
			margin-right: 0px;
		}
		.dashboardrightpanel .myexpertnav > ul li{text-align: center;}
		.myexpertnav.myaccountnav.qnanav ul li.active span {
			background: #f6fdff;
			border-radius: 10px;
			padding: 2px 6px;
			margin-left: 7px;
			font-weight: 500;
			font-size: 12px;
			color: #043d5f;
		}
		.myexpertnav.myaccountnav.qnanav ul li span {
			background: linear-gradient(90.46deg, #024e7c 0%, #06293f 100%);
			border-radius: 10px;
			padding: 2px 6px;
			margin-left: 8px;
			font-weight: 500;
			font-size: 12px;
			color: #ffffff;
		}
		.homepageproductoffer .colsec {padding: 0 5px !important;}
		
}
@media (max-width: 345px){	
	.selection .input-container.form-group1:nth-child(2) .form-select {
		width: 256px !important;
	}
	.inlinecard span.text-danger {
		bottom: -13px;
		font-size: 11px;
	}
	.p_slider .ctaonebutton {
        font-size: 14px;
	}
	.categoryonesetwidth {
        width: 150px;
	}
	.listofoffermainsection .detailedview .disgrid.grid-4 {
        grid-template-columns: 1fr;
		gap:0;
    }
	.search-block select.input.form-control1.form-select {
		font-size: 12px;
		width: auto !important;
	}
	.mb-4 .css-1p3m7a8-multiValue:first-child{margin-top: 29px;}
	.mb-4 .css-1p3m7a8-multiValue{margin-top: 10px;}
	.form-container.form-group.multiselect .css-13cymwt-control{height: auto;}
	.inlinecard {grid-template-columns: 1fr;
	}
	.addcard {
		padding: 10px;
	}
	span.validth{top:-16px;}
	.button_outer {width: 140px;}
	.btn_upload {
		padding: 17px 17px 12px;}	
		.uploadexpert {
			padding: 10px;
		}
	.myexpertnav.myaccountnav ul li:nth-child(n + 1) {
			margin-right: 0px;
		}
	ul.detwo.ppvtwo li span, ul.dethree.ppvthree li span{display: block;}
	.myexpertnav > ul li{text-align: left;}
		.myexpertnav.myaccountnav > ul > li:last-child.active, .myexpertnav.myaccountnav ul li{background-size: contain;}
	span.plantooltip.custooltip.righttool .tooltipcontent {
		right: 0;
		left: -145px;
		margin: auto;
		top: 0;
		z-index: 99;
		width: 246px;
	}
	.detailsbank h5{line-break: anywhere;}
	.herosection a.ctatwobutton, .heroleftbutton.experthomepagebutton a.ctatwobutton{margin-left: 0;}
.categoryonesetfirst .grid-3 , .categorytwosetfirst .grid-4, .mainexpforminner .grid-4{
    grid-template-columns: 1fr;
	gap:10px;
}
/* .categoryonesettwo{margin: auto;} */
.categoryonesetfirst, .categorytwosetfirst{background: #fff;}
.herobutton.mt-4.d-flex, .heroleftbutton.d-flex.mt-4{display: block  !important;}
.herobutton button.ctatwobutton {
    width: 200px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 21px;
}
.herobutton button.ctaonebutton{width: auto;margin-right: auto;
    margin-left: auto;}
	.tooltipcontent {
		width: 221px;
	}
	span.plantooltip.custooltip:hover .tooltipcontent {
		visibility: visible;
		left: -79px;
		top: 0;
	}
	.expertfileupload{padding: 10px;}
	.myexpertnav.myaccountnav.qnanav.martop40 ul> li:nth-child(n + 1) {
		margin-right: 0px;
	}
}
@media (min-width:769px) and (max-device-width : 1024px)  and (orientation: landscape)
{
	body:before {content: '';position: fixed;z-index: 99999999999;top: 0;right: 0;bottom: 0;left: 0;background: #212121 url(../images/turn.webp);background-position: center;background-size:contain;background-repeat: no-repeat;}
}

@media (max-width: 1400px) {
	span.text-danger.samemailerr {
    bottom: -24px;
	}
}

@media (min-width: 1340px) {
	#root .container {
        max-width: 1320px;
    }
}
