 .main.entiredashboard.maincustomerdash .col-lg-3.col-sm-3:before {
   background-color: #449FD6;
 }

 .main.entiredashboard.maincustomerdash>.container>.row>.col-lg-3.col-sm-3 {
   background: #2280B8;
 } 
 .logout {
   font-weight: 700;
   font-size: 13px;
   color: #FFFFFF;
 }

 .dashboard-decoration {
   background: #E4EFFF;
   border-radius: 25px;
   box-shadow: 0px 0px 15.7px 0px #2280B880;
 }

 .myworkspace-data {
   align-self: start;
   display: flex;
   align-items: start;
   flex-direction: column;
 }

 .myworkspace-padding {
   padding-left: 40px;
 }

 .col-lg-9.col-sm-9.dashboard-data {
   padding: 0 55px;
   display: block;
 }
  
 .myworkspacenav>ul {
   padding: 0;
   list-style: none;
   display: flex;
   position: relative;
   padding: 50px 0;
   margin: 0 auto;
 }

 .myworkspacenav>ul li {
   padding: 10px 20px;
   border-radius: 100px;
   font-weight: 600;
   font-size: 20px;
   line-height: 27px;
   text-align: center;
   color: #06293f;
   background: #90E0EF;
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
   cursor: pointer;
   width: 144.93px;
   height: 144.93px;
   margin-right: 25px;
 }

 .myworkspacenav>ul>li.active {
   color: #fff;
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
   background: linear-gradient(180deg, #0077B6 0%, #003450 100%);
   box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
   box-shadow: 2.84px 2.84px 31.27px 0px #00000040;
   z-index: 9;
   width: 144.93px;
   height: 144.93px;

 }

 .myworkspacenav img {
   fill: white !important;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0 auto;
   height: 45px;
   margin-bottom: 8px;
   margin-top: 8px;
 }

 .myworkspacenav>ul>li>img.active {
   filter: invert(100%) sepia(13%) saturate(0) hue-rotate(21deg) brightness(114%) contrast(101%);
 }