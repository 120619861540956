.dashboardlink ul li span img{ 
    filter: brightness(100);
  } 
  
.dashboardlink
  {
    margin-left: 2px;
  }
   
.main.entiredashboard.maincustomerdash > .container > .row > .col-lg-3.col-sm-3 {
    background: #2280B8;
   
  }
  
.activetext p{
    margin-left: 10px; 
  }


.main.entiredashboard.maincustomerdash .col-lg-3.col-sm-3:before {
    background: #449FD6;  
}

.main.entiredashboard.maincustomerdash .col-lg-3.col-sm-3 p:before { 
    left: -71px;
}

.main.entiredashboard.maincustomerdash .col-lg-3.col-sm-3 p  { 
    margin-left: 4px;
}